import { useContext, useEffect, useId, useRef, useState } from "react";
import { PropertysViewContext } from "../contexts/PropertysMainContext";
import InputView from "../generalComps/InputView";
import { PropertysContext } from "../contexts/PropertysContext";
import PopUp from "../generalComps/PopUp";
import ImageSelectionView from "../generalComps/ImageSelectionView";
import { AlertObj, Property, PropertyAddress, PropertyListType } from "../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchAddress, fetchAddressAll, postAddAddress } from "../methods/http.address.methods";
import { emptyProperty } from "../methods/constants";
import { postAddProperty, postUpdateProperty } from "../methods/http.property.methods";

type Props = {
    handleCancel: () => void;
    handleSave: (type: string) => void;
}

type CheckIcon = {
    name: string;
    color: string;
    description: string;
}

const PropertyAddEditView = ( { handleCancel, handleSave }:Props ) => {

    const queryClient = useQueryClient();

    const { propertys } = useContext(PropertysContext);
    const { selectedProperty, setSelectedProperty, propertyListType } = useContext(PropertysViewContext);

    const [checkIcon, setCheckIcon] = useState<CheckIcon>( { name: 'report', color: 'red', description: 'Immobilien-Name bereits vergeben' } );

    const [isVisiblePropertyTypePopUpView, setIsVisiblePropertyTypePopUpView] = useState(false);
    const [isVisiblePropertyLevelPopUpView, setIsVisiblePropertyLevelPopUpView] = useState(false);
    const [isVisiblePropertyAddressPopUpView, setIsVisiblePropertyAddressPopUpView] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const newPropertyRef = useRef( emptyProperty );
    const propertyAddressRef = useRef<Array<PropertyAddress>>( [] );

    const inputNameRef = useRef<HTMLInputElement | null>(null);
    const inputTypeRef = useRef<HTMLInputElement | null>(null);
    const inputLevelRef = useRef<HTMLInputElement | null>(null);

    const inputStreetRef = useRef<HTMLInputElement | null>(null);
    const inputZipRef = useRef<HTMLInputElement | null>(null);
    const inputCityRef = useRef<HTMLInputElement | null>(null);
    const inputCountryRef = useRef<HTMLInputElement | null>(null);

    const inputSquaremetersRef = useRef<HTMLInputElement | null>(null);
    const inputLevelsRef = useRef<HTMLInputElement | null>(null);
    const inputLivingroomRef = useRef<HTMLInputElement | null>(null);
    const inputBedroomRef = useRef<HTMLInputElement | null>(null);

    const inputKitchenRef = useRef<HTMLInputElement | null>(null);
    const inputBathroomRef = useRef<HTMLInputElement | null>(null);
    const inputBedsRef = useRef<HTMLInputElement | null>(null);
    const inputBuildRef = useRef<HTMLInputElement | null>(null);

    useEffect( () => { checkPropertyName() }, []);

    const checkPropertyName = () => {

        const newPropertyName = inputNameRef.current!.value;

        const foundProperty = propertys.array.find( prop => prop.property_name.toLowerCase() === newPropertyName.toLowerCase() );

        if ( ( foundProperty || newPropertyName.length === 0) && ( selectedProperty.property_id === -1 || ( selectedProperty.property_id !== -1 && newPropertyName.toLowerCase() !== selectedProperty.property_name.toLowerCase() ) ) ) {

            setCheckIcon( { name: 'report', color: 'red', description: 'Immobilien-Name bereits vergeben' } );

        } else {

            setCheckIcon( { name: 'spellcheck', color: 'green', description: 'Immobilien-Name einmalig' } );

        }

    }

    const closePopUpViews = () => {

        setIsVisiblePropertyTypePopUpView(false);
        setIsVisiblePropertyLevelPopUpView(false);
        setIsVisiblePropertyAddressPopUpView(false);

    }

    const PropertyTypePopUp = () => {

        const propertyTypes = ['Ferienwohnung', 'Ferienhaus', 'Monteurswohnung', 'Monteurszimmer', 'Zimmer', 'Apartment'];

        return (
            <PopUp style={{ height: 'auto', marginTop: '60px' }} handleClosePopUp={closePopUpViews}>
            {propertyTypes.map((propType, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleSetPropertyType(e, propType) }
                    key={index}
                >
                    <div className="lbl">{propType}</div>
                    {propType === inputTypeRef.current!.value && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleSetPropertyType = ( e: React.SyntheticEvent, propertyType: string) => {

        inputTypeRef.current!.value = propertyType;

        setIsVisiblePropertyTypePopUpView(false);

        e.stopPropagation();

    }

    const PropertyLevelPopUp = () => {

        const propertyLevels = ['Untergeschoß', 'Erdgeschoß', 'Obergeschoß', '1. Obergeschoß', '2. Obergeschoß', 'Dachgeschoß', '1. Etage', '2. Etage', '3. Etage', '4. Etage', '5. Etage'];

        return (
            <PopUp style={{ height: 'auto', marginTop: '60px' }} handleClosePopUp={closePopUpViews}>
            {propertyLevels.map((level, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleSetPropertyLevel(e, level) }
                    key={index}
                >
                    <div className="lbl">{level}</div>
                    {level === inputLevelRef.current!.value && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleSetPropertyLevel = ( e: React.SyntheticEvent, level: string) => {

        inputLevelRef.current!.value = level;

        setIsVisiblePropertyLevelPopUpView(false);

        e.stopPropagation();

    }

    const { mutate: fetchAddressAllMutation } = useMutation({
        mutationFn: () => fetchAddressAll(),
        onSuccess: (data) => {

            if (data.obj) {
                propertyAddressRef.current = data.obj;
            }

            if (propertyAddressRef.current.length === 0) {

                setAlertObj( new AlertObj( true, 'Keine Adressen', 'Es sind bisher keine Adressen hinterlegt.', standardAlertButton ) );

            } else {

                setIsVisiblePropertyAddressPopUpView(true);

            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const PropertyAddressPopUp = () => {

        return (
            <PopUp style={{ height: 'auto', marginTop: '60px' }} handleClosePopUp={closePopUpViews}>
            {propertyAddressRef.current.map((address) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleSetPropertyAddress(e, address) }
                    key={address.address_id}
                >
                    <div className="lbl">
                        <div>{address.address_street}</div>
                        <div>{address.address_zip} {address.address_city}</div>
                        <div>{address.address_country}</div>
                    </div>
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleSetPropertyAddress = ( e: React.SyntheticEvent, address: PropertyAddress) => {

        inputStreetRef.current!.value = address.address_street;
        inputZipRef.current!.value = address.address_zip;
        inputCityRef.current!.value = address.address_city;
        inputCountryRef.current!.value = address.address_country;

        setIsVisiblePropertyAddressPopUpView(false);

        e.stopPropagation();

    }

    const { mutate: fetchAddressMutation } = useMutation({
        mutationFn: () => fetchAddress( { address_street: inputStreetRef.current!.value, address_zip: inputZipRef.current!.value, address_city: inputCityRef.current!.value, address_country: inputCountryRef.current!.value, address_id: -1 } ),
        onSuccess: (data) => {

            const address: PropertyAddress = data.obj;

            if (address) {

                newPropertyRef.current.property_address = address.address_id;

                if (newPropertyRef.current.property_id === -1) {
                    postAddPropertyMutation();
                } else {
                    postUpdatePropertyMutation();
                }

            } else {
                postAddAddressMutation();
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postAddAddressMutation } = useMutation({
        mutationFn: () => postAddAddress( { address_street: inputStreetRef.current!.value, address_zip: inputZipRef.current!.value, address_city: inputCityRef.current!.value, address_country: inputCountryRef.current!.value, address_id: -1 } ),
        onSuccess: (data) => {

            newPropertyRef.current.property_address = Number(data.address_id);

            if (newPropertyRef.current.property_id === -1) {
                postAddPropertyMutation();
            } else {
                postUpdatePropertyMutation();
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postAddPropertyMutation } = useMutation({
        mutationFn: () => postAddProperty( newPropertyRef.current ),
        onSuccess: () => {

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

            handleSave('new');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdatePropertyMutation } = useMutation({
        mutationFn: () => postUpdateProperty( newPropertyRef.current ),
        onSuccess: () => {

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

            handleSave('update');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSaveOnClick = () => {

        if (inputNameRef.current!.value.length == 0 || inputTypeRef.current!.value.length == 0 || inputLevelRef.current!.value.length == 0 || inputStreetRef.current!.value.length == 0 || inputZipRef.current!.value.length == 0 || inputCityRef.current!.value.length == 0 || inputCountryRef.current!.value.length == 0) {
        
            setAlertObj( new AlertObj( true, 'Daten unvollständig', 'Bitte fülle alle notwendigen Daten aus.', standardAlertButton ) );
            
        } else if (checkIcon.name === 'report') {
    
            setAlertObj( new AlertObj( true, 'Name vergeben', `Die Bezeichnung '${inputNameRef.current!.value}' ist schon vergeben. Bitte wähle einen anderen Namen.`, standardAlertButton ) );
            
        } else {

            const pm_json_obj = { property_tax: false,
                                    property_storno: false,
                                    property_deposit: false,
                                    property_kaution: false,
                                    property_storno1: true,
                                    property_storno2: false,
                                    property_storno3: false,
                                    property_currency: "EUR",
                                    property_deposit_min: 0,
                                    property_tax_percent: 7,
                                    property_deposit_days: 7,
                                    property_invoice_days: 7,
                                    property_mahnung_days: 7,
                                    property_storno1_days: 60,
                                    property_storno2_days: 30,
                                    property_storno3_days: 7,
                                    property_currency_long: "Euro",
                                    property_kaution_value: 0,
                                    property_reminder_days: 7,
                                    property_deposit_percent: 20,
                                    property_storno1_percent: 100,
                                    property_storno2_percent: 50,
                                    property_storno3_percent: 100,
                                    property_deposit_pauschal: false,
                                    property_deposit_pauschal_value: 0 };

            //Quasi Template, welches beim erstmaligen anlegen einer Immobilie in das Service Feld geschrieben wird
            const serviceArr = [{service: 'Zimmerservice', status: false, icon: 'room_service'},
                                {service: 'Klimaanlage', status: false, icon: 'ac_unit'},
                                {service: 'Schuttle-Service', status: false, icon: 'airport_shuttle'},
                                {service: 'All Inclusive', status: false, icon: 'all_inclusive'},
                                {service: 'Strand', status: false, icon: 'beach_access'},
                                {service: 'Kinderfreundlich', status: false, icon: 'child_friendly'},
                                {service: 'Fitness', status: false, icon: 'fitness_center'},
                                {service: 'Frühstück', status: false, icon: 'free_breakfast'},
                                {service: 'Pool', status: false, icon: 'pool'},
                                {service: 'Spa', status: false, icon: 'spa'},
                                {service: 'Nichtraucherzimmer', status: false, icon: 'smoke_free'},
                                {service: 'Hundefreundlich', status: false, icon: 'pets'},
                                {service: 'WiFi', status: false, icon: 'wifi'},
                                {service: 'Tv', status: false, icon: 'tv'},
                                {service: 'Wandern', status: false, icon: 'terrain'},
                                {service: 'Stellplatz', status: false, icon: 'local_parking'}];

            const newProperty: Property = { ...selectedProperty,
                property_name: inputNameRef.current!.value,
                property_art: inputTypeRef.current!.value,
                property_level: inputLevelRef.current!.value,
                address_street: inputStreetRef.current!.value,
                address_zip: inputZipRef.current!.value,
                address_city: inputCityRef.current!.value,
                address_country: inputCountryRef.current!.value,
                property_squaremeters: Number(inputSquaremetersRef.current!.value),
                property_levels: Number(inputLevelsRef.current!.value),
                property_livingroom: Number(inputLivingroomRef.current!.value),
                property_bedroom: Number(inputBedroomRef.current!.value),
                property_kitchen: Number(inputKitchenRef.current!.value),
                property_bathroom: Number(inputBathroomRef.current!.value),
                property_beds: Number(inputBedsRef.current!.value),
                property_build: Number(inputBuildRef.current!.value),
                property_pm: JSON.stringify( pm_json_obj ),
                property_service: JSON.stringify( serviceArr ) };

            newPropertyRef.current = newProperty;

            fetchAddressMutation();

        }

    }

    return ( 

        <>
        <div className="navigation-view-bar">
          <div onClick={handleCancel} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>cancel</div>
          <div className="main-title">{ `Immobilie ${ selectedProperty.property_id === -1 ? 'anlegen' : 'bearbeiten' }` }</div>
          <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px', }}>check_circle</div>
        </div>
        <div className="section">
            <div className="header-wrapper">
                <ImageSelectionView handleImgData={ (imgData) => setSelectedProperty( prevProp => ( {...prevProp, property_img: imgData } ) ) } urlContentString={ ( selectedProperty.property_id === -1 || selectedProperty.property_img_uploaded === 0 ) ? 'ef_std_img.jpg' : `https://eazyac-dev.de/user_data/${selectedProperty.user_folder!}/prop_images/${selectedProperty.property_id}.png'` }/>
                <div className="property-data">
                    <div className="top-wrapper">
                        <InputView title="Immobilien-Name">
                            <div className="default-icon" title={ checkIcon.description } style={{ position: 'absolute', fontSize: '1.2em', right: '0', top: '3px', color: checkIcon.color }}>{ checkIcon.name }</div>
                            <input ref={inputNameRef} onChange={ checkPropertyName } type="text" placeholder="z. B. Fewo Sonnenschein" defaultValue={ selectedProperty.property_name }/>
                        </InputView>
                        <InputView title="Immobilien-Art">
                            <div onClick={ (e) => { closePopUpViews(); setIsVisiblePropertyTypePopUpView(prev => !prev); e.stopPropagation(); } } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.2em', right: '0', top: '3px' }}>toc</div>
                            <input ref={inputTypeRef} type="text" placeholder="z. B. Ferienwohnung" defaultValue={ selectedProperty.property_art }/>
                            { isVisiblePropertyTypePopUpView && <PropertyTypePopUp /> }
                        </InputView>
                        
                    </div>
                    <InputView title="Etage">
                        <div onClick={ (e) => { closePopUpViews(); setIsVisiblePropertyLevelPopUpView(prev => !prev); e.stopPropagation(); } } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.2em', right: '0', top: '3px' }}>toc</div>
                        <input ref={inputLevelRef} type="text" placeholder="z. B. Erdgeschoß" defaultValue={ selectedProperty.property_level }/>
                        { isVisiblePropertyLevelPopUpView && <PropertyLevelPopUp /> }
                    </InputView>
                </div>
            </div>
            <div className="address-wrapper">
                <InputView title="Straße & Hausnummer">
                    <div onClick={ (e) => { closePopUpViews(); fetchAddressAllMutation(); e.stopPropagation(); } } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.2em', right: '0', top: '3px' }}>toc</div>
                    <input ref={inputStreetRef} type="text" defaultValue={ selectedProperty.address_street } placeholder="z. B. Musterweg 7" />
                    { isVisiblePropertyAddressPopUpView && <PropertyAddressPopUp /> }
                </InputView>
                <div className="flex center-y w100prc" style={{ gap: '10px', padding: '3px 0' }}>
                    <InputView title="Postleitzahl" style={{ width: '30%' }}><input ref={inputZipRef} type="text" defaultValue={ selectedProperty.address_zip } placeholder="z. B. 88630" /></InputView>
                    <InputView title="Wohnort" style={{ width: '70%' }}><input ref={inputCityRef} type="text" defaultValue={ selectedProperty.address_city } placeholder="z. B. Musterstadt" /></InputView>
                </div>
                <InputView title="Land"><input ref={inputCountryRef} type="text" defaultValue={ selectedProperty.address_country } placeholder="z. B. Deutschland" /></InputView>
            </div>
        </div>
        <div className="bottom-section">
            <InputView title="Fläche in m²"><input ref={inputSquaremetersRef} type="number" defaultValue={ selectedProperty.property_squaremeters } style={{ textAlign: 'center' }} /></InputView>
            <InputView title="Anzahl Etagen"><input ref={inputLevelsRef} type="number" defaultValue={ selectedProperty.property_levels } style={{ textAlign: 'center' }} /></InputView>
            <InputView title="Anzahl Wohnzimmer"><input ref={inputLivingroomRef} type="number" defaultValue={ selectedProperty.property_livingroom } style={{ textAlign: 'center' }} /></InputView>
            <InputView title="Anzahl Schlafzimmer"><input ref={inputBedroomRef} type="number" defaultValue={ selectedProperty.property_bedroom } style={{ textAlign: 'center' }} /></InputView>
        </div>
        <div className="bottom-section margin-10">
            <InputView title="Anzahl Küchen"><input ref={inputKitchenRef} type="number" defaultValue={ selectedProperty.property_kitchen } style={{ textAlign: 'center' }} /></InputView>
            <InputView title="Anzahl Badezimmer"><input ref={inputBathroomRef} type="number" defaultValue={ selectedProperty.property_bathroom } style={{ textAlign: 'center' }} /></InputView>
            <InputView title="Anzahl Schlafgelegenheiten"><input ref={inputBedsRef} type="number" defaultValue={ selectedProperty.property_beds } style={{ textAlign: 'center' }} /></InputView>
            <InputView title="Baujahr"><input ref={inputBuildRef} type="number" defaultValue={ selectedProperty.property_build } style={{ textAlign: 'center' }} /></InputView>
        </div>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default PropertyAddEditView;