import { useState } from 'react';
import '../css/weekdatebar.css';

type Props = {
    selectedDate: {weekDay: string, dateStringShort: string, dateStringFull: string},
    setSelectedDate: any
}

const WeekDateBar = ( { selectedDate, setSelectedDate }: Props ) => {

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayString = today.toLocaleDateString('fr-ca');

    const dates: Array<{weekDay: string, dateStringShort: string, dateStringFull: string}> = [];
        
    for (let i = 1; i < 7; i++) {

        const date = new Date(today.getFullYear(), today.getMonth(), today.getDate() + i);

        const dateStringFull = date.toLocaleDateString('fr-ca');
        const weekDay = date.toLocaleDateString('de-DE', { weekday:"short"});
        const dateStringShort = date.toLocaleDateString('de-DE', { month:"numeric", day:"numeric"});

        dates.push( {weekDay, dateStringShort, dateStringFull} );
        
    }

    return ( 
        <div id="weekBar" className="flex-container center w100prc" style={{padding: '0 0 10px 0'}}>
            <div onClick={ () => setSelectedDate( {weekDay: '', dateStringShort: '', dateStringFull: todayString} ) } className={ `flex-container center${ (selectedDate.dateStringFull == todayString) ? ' selected' : '' }` }>HEUTE</div>
            { dates.map( ( date:{weekDay: string, dateStringShort: string, dateStringFull: string} ) => { 
           
                return <div onClick={ () => setSelectedDate(date) } className={ `flex-container center${ (selectedDate.dateStringFull == date.dateStringFull) ? ' selected' : '' }` } style = {{fontSize: '0.8rem'}} key={date.dateStringFull}>{date.weekDay}<br/>{date.dateStringShort}</div> 
                
                } ) }
        </div>
     );
}
 
export default WeekDateBar;