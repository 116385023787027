import { useQuery } from "@tanstack/react-query";
import { useEffect, ReactElement, useState, useRef, useContext } from "react";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import { fetchMails, fetchNewMails } from "../methods/http.korres.methods";
import { fetchStaysSectionMails } from "../methods/http.stays.methods";
import { createMailSectionArray } from "../methods/standard.methods";
import { ConversationObj, MessageListType } from "../methods/types";
import SectionCard from "./SectionCard";

type Props = {
    searchString: string;
}

const MessagesList = ( { searchString }: Props ) => {

    const { messageListType, setMessageListType } = useContext(MessageDetailViewContext);

    const mailSectionArrayRef = useRef<{[key:string]: Array<ConversationObj>} | null>( null);
    const [mailSectionArray, setMailSectionArray] = useState<{[key:string]: Array<ConversationObj>} | null>( null );

    const filteredSectionsRef = useRef<Array<string>>( [] );

    const fetchMailsQuery = useQuery({
        queryKey: ["fetchMails"],
        queryFn: () => fetchMails(),
        refetchOnWindowFocus: false,
        enabled: messageListType === MessageListType.All,
    });

    const fetchNewMailsQuery = useQuery({
        queryKey: ["fetchNewMails"],
        queryFn: () => fetchNewMails(),
        refetchOnWindowFocus: false,
        enabled: messageListType === MessageListType.Unread,
    });

    const fetchStaysSectionQuery = useQuery({
        queryKey: ["fetchStaysSection"],
        queryFn: () => fetchStaysSectionMails(),
        refetchOnWindowFocus: false,
    });

    useEffect( () => {

        if ( messageListType === MessageListType.All && fetchMailsQuery.isSuccess) {

            if (fetchStaysSectionQuery.isSuccess) {
    
                mailSectionArrayRef.current = createMailSectionArray( fetchStaysSectionQuery.data.obj, fetchMailsQuery.data.obj );
                
            }
    
        } else if ( messageListType === MessageListType.Unread && fetchNewMailsQuery.isSuccess) {
    
            if (fetchStaysSectionQuery.isSuccess) {
    
                mailSectionArrayRef.current = createMailSectionArray( fetchStaysSectionQuery.data.obj, fetchNewMailsQuery.data.obj );
    
            }
    
        }

        setMailSectionArray( mailSectionArrayRef.current );

    }, [messageListType, fetchNewMailsQuery.data?.obj, fetchMailsQuery.data?.obj])

    const NoMessages = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">{ `Keine ${ messageListType === MessageListType.Unread ? 'neuen ' : '' }Nachrichten vorhanden` }</div>;
    }

    filteredSectionsRef.current = mailSectionArray === null ? [] : Object.keys(mailSectionArray).filter( ( section ) => {

        return section.toLowerCase().indexOf(searchString.toLowerCase()) !== -1;

    }); 

    const handleIconOnClick = (e: React.SyntheticEvent, listType: MessageListType) => {

        setMessageListType(listType);

        e.stopPropagation();

    }
    
    return ( 
        <>
        <div className="shadowBox w100prc">
            <div title="Ungelesene Nachrichten" onClick={ (e) => handleIconOnClick(e, MessageListType.Unread) } className={ `clickable-icon flex center w100prc${ messageListType === MessageListType.Unread ? ' selected' : '' }` } style={{ fontSize: '1.5em' }}>mark_email_unread</div>
            <div title="Alle Nachrichten" onClick={ (e) => handleIconOnClick(e, MessageListType.All) } className={ `clickable-icon flex center w100prc${ messageListType === MessageListType.All ? ' selected' : '' }` } style={{ fontSize: '1.5em' }}>inbox</div>
        </div>
        <div className="flex center w100prc h100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', overflowX: 'hidden', overflowY: 'scroll', padding: '0 3px' }}>
        { ( fetchMailsQuery.isFetching || fetchNewMailsQuery.isFetching ) ? 

                <div className="shadowBox flex center w100prc">Lade Aufenthalte...</div> :

                ( filteredSectionsRef.current.length === 0 && ( fetchMailsQuery.isSuccess || fetchNewMailsQuery.isSuccess ) ) ? 

                <NoMessages /> :

                filteredSectionsRef.current.map( (section, index) => {

                    return (
        
                        <SectionCard key={index} sectionName={section} sectionArr={mailSectionArray![section]} index={index} />
        
                    );
    
                }) 
                
        }

        </div>
        </>

     );
}
 
export default MessagesList;