import { ReactElement, useContext, useState } from "react";
import "../css/statistics.css";
import { monthsLong } from "../methods/constants";
import PopUp from "../generalComps/PopUp";
import Card from "../generalComps/Card";
import { fetchStatisticsSmallData } from "../methods/http.statistics.methods";
import { useQuery } from "@tanstack/react-query";
import ProgressBar from "../generalComps/ProgressBar";
import { PropertysContext } from "../contexts/PropertysContext";

const StatisticsSmall = () => {

  const propertysContext = useContext(PropertysContext);
  const propertys = propertysContext.propertys.array;
  const currency = propertysContext.propertys.currencyShort;

  const [monthNum, setMonthNum] = useState(new Date().getMonth());

  const [isVisibleMonthPopUp, setIsVisibleMonthPopUp] = useState(false);

  const year = new Date().getFullYear().toString();

  let propertyIds = JSON.stringify(
    propertys.map((prop) => prop.property_id)
  );
  let tileValue3 = "0";
  let progressBarValue = 0;

  const MonthPopUp = () => {
    return (
      <PopUp style={{marginTop:'40px'}}>
        {monthsLong.map((month, index) => {
          return (
            <div
              className="lbl-container"
              onClick={(e) => handleMonthClick(e, index)}
              key={index}
            >
              <div className="lbl">{month}</div>
              {index === monthNum && <div className="default-icon">check</div>}
            </div>
          );
        })}
      </PopUp>
    );
  };

  const handleMonthClick = (e: React.SyntheticEvent, index: number) => {
    setMonthNum(index);
    setIsVisibleMonthPopUp(false);

    e.stopPropagation();
  };

  const icon = (iconName: string): ReactElement => {
    return (
      <div
        className="default-icon"
        style={{ fontSize: "2.0rem", padding: "0" }}
      >
        {iconName}
      </div>
    );
  };

  const { error, data } = useQuery({
    queryKey: ["statisticsSmallData", propertyIds, monthNum, year],
    queryFn: () =>
      fetchStatisticsSmallData(
        propertyIds,
        monthNum.toString(),
        monthNum.toString(),
        year
      ),
    enabled: propertyIds.length !== 0,
    refetchOnWindowFocus: false,
  });

  if (data) {
    
    let daysInMonth = new Date(new Date().getFullYear(), monthNum, 0).getDate();

    const propertysCount = propertys.length ? propertys.length : 0;

    daysInMonth = daysInMonth * propertysCount;

    progressBarValue = (data.days * 100) / daysInMonth;

    progressBarValue = progressBarValue ? progressBarValue : 0;

    if (data.guests != 0 && data.stays != 0) {
      tileValue3 = (Number(data.guests) / parseInt(data.stays)).toFixed(0);
    }

  }

  return (
    <div className="shadowBox statistics-small">
      <div
        id="month-lbl"
        onClick={() => setIsVisibleMonthPopUp(true)}
        className="flex-container center-y cursor-pointer w100prc"
        style={{ paddingBottom: "10px" }}
      >
        {("Statistik für " + monthsLong[monthNum]).toUpperCase()}
        {isVisibleMonthPopUp && <MonthPopUp />}
      </div>
      <div
        className="flex-container center w100prc"
        style={{ gap: "10px", overflow: "visible" }}
      >
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("door_front")}
          topTitle=""
          valueTitle={data ? data.stays.toString() : "0"}
          btmTitle={data && data.stays === 1 ? "Aufenthalt" : "Aufenthalte"}
          isClickable={false}
          isSelected={false}
        />
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("euro")}
          topTitle=""
          valueTitle={data ? (data?.revenue).toFixed(0) + " " + currency : "0"}
          btmTitle="Umsatz"
          isClickable={false}
          isSelected={false}
        />
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("groups")}
          topTitle=""
          valueTitle={tileValue3}
          btmTitle={`${
            data && data.guests === 1 ? "Gast" : "Gäste"
          } pro Aufenthalt ø`}
          isClickable={false}
          isSelected={false}
        />
      </div>
      <ProgressBar value={progressBarValue} />
    </div>
  );
};

export default StatisticsSmall;
