import { ReactElement, useContext, useEffect, useId, useState } from "react";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import { AlertObj, InfoWidgetObj, PaymentsViewType, PriceObj, PropertyListType, ViewState } from "../../../methods/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchPrices, postDeletePrice } from "../../../methods/http.price.methods";
import PriceCard from "./PriceCard";
import { postUpdatePropertySingleValue } from "../../../methods/http.property.methods";
import InfoWidget from "../../../generalComps/InfoWidget";
import ActionAlert from "../../../generalComps/ActionAlert";
import PriceAddEditView from "./PriceAddEditView";
import { emptyPrice } from "../../../methods/constants";
import ModalView from "../../../generalComps/ModalView";

type Props = {
    handleClose: () => void;
}

const PriceList = ( { handleClose }: Props ) => {

    const queryClient = useQueryClient();

    const { setPaymentsViewType, selectedProperty, setSelectedProperty, propertyListType, isVisiblePriceAddEditView, setIsVisiblePriceAddEditView, setSelectedPrice } = useContext( PropertysViewContext );

    const [prices, setPrices] = useState<Array<PriceObj>>([]);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const { mutate: fetchPricesMutation } = useMutation({
        mutationFn: () => fetchPrices(),
        onSuccess: (data) => {

            if (data.success) {
                setPrices( data.obj );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => fetchPricesMutation(), [] );

    // const { isFetching, isSuccess, data } = useQuery({
    //     queryKey: ["fetchPrices"],
    //     queryFn: () => fetchPrices(),
    //     refetchOnWindowFocus: false,
    // });

    // useEffect( () => {

    //     if ( isSuccess) {

    //         setPrices( data.obj ? data.obj! : [] );
    
    //     } 

    // }, [data?.obj])

    const NoData = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Preise vorhanden</div>;
    }

    const handlePriceCardClick = (state: ViewState, price: PriceObj) => {

        if (state === ViewState.Details) {

            const propertyPriceIds: Array<{price_id: number}> = selectedProperty.property_price ? JSON.parse( selectedProperty.property_price ) : [];

            if ( propertyPriceIds.find( pId => pId.price_id === price.price_id ) !== undefined ) {

                propertyPriceIds.splice( propertyPriceIds.findIndex( obj => obj.price_id === price.price_id ), 1 );

            } else {

                propertyPriceIds.push( {price_id: price.price_id} );

            }

            postUpdatePropertySingleMutation( JSON.stringify( propertyPriceIds ) );

        } else if (state === ViewState.Delete) {

            const alertButtons = [ {title: 'Löschen', handler: () => postDeletePriceMutation(price.price_id) }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Preis löschen?', `Soll dieser Preis wirklich gelöscht werden?`, alertButtons ) );

        } else if (state === ViewState.Edit) {

            setSelectedPrice( price );

            setIsVisiblePriceAddEditView(true);

        }

    }

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: (priceIdString: string) => postUpdatePropertySingleValue(selectedProperty.property_id, 'property_price', priceIdString),
        onSuccess: (_, variables) => {

            setSelectedProperty( prev => ( {...prev, property_price: variables } ) );

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postDeletePriceMutation } = useMutation({
        mutationFn: (priceId: number) => postDeletePrice(priceId),
        onSuccess: () => {

            //queryClient.invalidateQueries( { queryKey: ['fetchPrices'] } );
            fetchPricesMutation();

            setAlertObj( new AlertObj() );

            setInfoWidgetObj( new InfoWidgetObj( true, 'Preis gelöscht.') )

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSavePrice = (type: string, newPriceId?: number | undefined) => {

        //queryClient.invalidateQueries( { queryKey: ['fetchPrices'] } );
        fetchPricesMutation();

        setIsVisiblePriceAddEditView( false );
        setSelectedPrice( emptyPrice );

        setInfoWidgetObj( new InfoWidgetObj(true, `Preis erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

        if (type === 'new' && newPriceId !== undefined && newPriceId !== null ) {

            const alertButtons = [ {title: 'Verknüpfen', handler: () => {

                const propertyPriceIds: Array<{price_id: number}> = selectedProperty.property_price ? JSON.parse( selectedProperty.property_price ) : [];
                propertyPriceIds.push( {price_id: newPriceId} );
                postUpdatePropertySingleMutation( JSON.stringify( propertyPriceIds ) );
                
                setAlertObj( new AlertObj() );

            } }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Preis verknüpfen?', `Den soeben angelegten Preis mit dieser Immobilie verknüpfen?`, alertButtons ) );

        }

    }

    return ( 

        <>
        <ModalView modalViewId="priceListView" style={{ width: '650px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ () => setPaymentsViewType( PaymentsViewType.MainMenu ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
                <div className="main-title">Preise</div>
                <div onClick={ () => { setSelectedPrice(emptyPrice); setIsVisiblePriceAddEditView(true); } } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
            </div>
            <div className="flex center w100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: '3px', marginTop: '10px' }}>
            { 

                prices.length === 0 ?

                <NoData />

                :

                prices.map( ( price ) => {

                    return <PriceCard key={price.price_id} price={price} handleOnClick={ (state) => handlePriceCardClick(state, price) } />;
        
                }) 

            }
            </div>
        </ModalView>
        { isVisiblePriceAddEditView && <PriceAddEditView handleCancel={ () => setIsVisiblePriceAddEditView(false) } handleSave={ handleSavePrice } /> }
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>
     );
}
 
export default PriceList;