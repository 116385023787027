import { memo, useContext, useMemo } from "react";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import { monthsLong } from "../../methods/constants";
import { getDateObj } from "../../methods/standard.methods";
import SingleListCalendarDatesTopBar from "./SingleListCalendarDatesTopBar";
import SingleListCalendarDatesRow from "./SingleListCalendarDatesRow";

const SingleListCalendarDates = () => {

    const { year, startMonth, endMonth } = useContext(OccupancyContext);

    const handleScrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        
        const element = document.getElementById('singleListCalendarMonthTable');

        if (element) {
            element.scrollTop = e.currentTarget.scrollTop;
        }

    };

    const filteredMonths = useMemo( () => {

        return monthsLong.filter( ( _, index ) => { return index >= startMonth && index <= endMonth } );

    }, [startMonth, endMonth]);

    return ( 

        <div onScroll={ handleScrollEvent } id="singleListCalendarContentTable" className="shadowBox">
            {
                filteredMonths.map( (month, index) => {

                    const startDateObj = getDateObj( new Date( year, index + startMonth, 1 ), 0 );
                    const endDateObj = getDateObj( new Date( year, ( index + startMonth ) + 1, 0 ), 0 );

                    return(

                        <div key={month}>
                            <SingleListCalendarDatesTopBar index={index} startDateObj={startDateObj} endDateObj={endDateObj} />
                            <SingleListCalendarDatesRow index={index} startDateObj={startDateObj} endDateObj={endDateObj} />
                        </div>

                    );

                })
            }
        </div>

     );
}
 
export default memo(SingleListCalendarDates);