import { ReactElement, useContext, useState } from "react";
import { KtObj, ViewState } from "../../../methods/types";
import { PropertysContext } from "../../../contexts/PropertysContext";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import { getDateObj } from "../../../methods/standard.methods";

type Props = {
    spaTax: KtObj;
    handleOnClick: (state: ViewState) => void;
}

const SpaTaxCard = ( { spaTax, handleOnClick }: Props ) => {

    const { propertys } = useContext(PropertysContext);
    const { selectedProperty } = useContext(PropertysViewContext);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const propertySpaTaxIds: Array<{kt_id: number}> = selectedProperty.property_kt ? JSON.parse( selectedProperty.property_kt ) : [];

    let ktLbl = '';
    let saisonLbl = '';

    if ( spaTax.kt_tax === 1 ) {
        ktLbl += ' inkl. ' + spaTax.kt_tax_percent + ' % Ust.';
    }

    if ( (spaTax.kt_start && spaTax.kt_start.length > 0) && (spaTax.kt_end  && spaTax.kt_end.length > 0)) {
        saisonLbl = `Gültig vom ${getDateObj( spaTax.kt_start, 0 ).dateStringDE} - ${getDateObj( spaTax.kt_end, 0 ).dateStringDE}`;
    }

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(
            
            <div className="flex center" onClick={ (e) => handleButtonClick(e, ViewState.Details) }>
                <div className="default-icon" style={{ width: '50px', fontSize: '1.5em' }}>{ propertySpaTaxIds.find( dId => dId.kt_id === spaTax.kt_id ) !== undefined ? 'check' : '' }</div>
                <div className="flex center w100prc column">
                    <div className="flex center-y w100prc">{ spaTax.kt_name }</div>
                    <div className="flex center-y w100prc" style={{ padding: '2px 0' }}>
                    {
                        spaTax.kt_art === undefined ?

                        `${spaTax.kt_price.toFixed(2)} ${propertys.currencyShort}` :

                        `${spaTax.kt_price.toFixed(2)} ${propertys.currencyShort} für ${ spaTax.kt_art === 0 ? 'Erwachsene' : 'Kinder' } ${ktLbl}`

                    }
                    </div>
                    <div className="flex center-y w100prc" style={{ padding: '2px 0' }}>{ saisonLbl }</div>
                </div>
            </div>
            
        );
    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 
        <div className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: '20px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>
     );
}
 
export default SpaTaxCard;