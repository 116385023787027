import { DefaultFetchResult, Landlord } from "./types";

export const fetchLandlords = async () => {

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=vermieter&do=select_all`,
        {
        method: "GET", 
       'credentials': 'include',
        }
    )
      
    const content: DefaultFetchResult = await result.json();
    return content;

}

export const fetchLandlord = async (landlordId: number) => {

    const data = new URLSearchParams();

    data.append('vermieter_id', landlordId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=vermieter&do=select`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
      
    const content: DefaultFetchResult = await result.json();
    return content;

}

export const postAddLandlord = async (landlord: Landlord) => {

    const data = new URLSearchParams();
   
    data.append('vermieter_title', landlord.vermieter_title);
    data.append('vermieter_lastname', landlord.vermieter_lastname);
    data.append('vermieter_firstname', landlord.vermieter_firstname);
    data.append('vermieter_company', landlord.vermieter_company);
    data.append('vermieter_taxnumber', landlord.vermieter_taxnumber);
    data.append('vermieter_taxval', landlord.vermieter_taxval.toString());
    data.append('vermieter_img', landlord.vermieter_img);
    data.append('vermieter_street', landlord.vermieter_street);
    data.append('vermieter_zip', landlord.vermieter_zip);
    data.append('vermieter_city', landlord.vermieter_city);
    data.append('vermieter_country', landlord.vermieter_country);
    data.append('vermieter_website', landlord.vermieter_website);
    data.append('vermieter_contact', landlord.vermieter_contact);
  
    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=vermieter&do=insert`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postUpdateLandlord = async (landlord: Landlord) => {

    const data = new URLSearchParams();
   
    data.append('vermieter_id', landlord.vermieter_id.toString());
    data.append('vermieter_title', landlord.vermieter_title);
    data.append('vermieter_lastname', landlord.vermieter_lastname);
    data.append('vermieter_firstname', landlord.vermieter_firstname);
    data.append('vermieter_company', landlord.vermieter_company);
    data.append('vermieter_taxnumber', landlord.vermieter_taxnumber);
    data.append('vermieter_taxval', landlord.vermieter_taxval.toString());
    data.append('vermieter_img', landlord.vermieter_img);
    data.append('vermieter_street', landlord.vermieter_street);
    data.append('vermieter_zip', landlord.vermieter_zip);
    data.append('vermieter_city', landlord.vermieter_city);
    data.append('vermieter_country', landlord.vermieter_country);
    data.append('vermieter_website', landlord.vermieter_website);
    data.append('vermieter_contact', landlord.vermieter_contact);
  
    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=vermieter&do=update`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postDeleteLandlord = async (landlordId: number) => {

    const data = new URLSearchParams();
  
    data.append('vermieter_id', landlordId.toString());
  
      const result = await fetch(
          `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=vermieter&do=delete`,
          {
          method: "POST", 
          body: data,
          'credentials': 'include',
          }
        )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}