import '../css/propdatespanbar.css';
import { useState } from "react";
import { emptyProperty, monthsShort } from "../methods/constants";

import PopUp from "./PopUp";
import { Property } from "../methods/types";

type Props = {

    propertys: Array<Property>;
    property: string;
    propertyId: string;
    setPropertyAndId: (property: string, propertyId: string) => void;
    startMonth: string;
    setMonth: (index: number, monthType: MonthType) => void;
    endMonth: string;
    year: string;
    setYear: (year: string) => void;
    style: any;

}

export enum MonthType {
    start,
    end
}

const PropDateSpanBar = ( { propertys, property, setPropertyAndId, propertyId, startMonth, setMonth, endMonth, year, setYear, style }: Props ) => {

    const [isVisiblePropertysPopUp, setisVisiblePropertysPopUp] = useState(false);
    const [isVisibleStartMonthPopUp, setIsVisibleStartMonthPopUp] = useState(false);
    const [isVisibleEndMonthPopUp, setIsVisibleEndMonthPopUp] = useState(false);
    const [isVisibleYearPopUp, setIsVisibleYearPopUp] = useState(false);

    const PropertyPopUp = ( { propertyId }: {propertyId: string} ) => {

        const newPropertys = [...propertys];
        newPropertys.unshift( {...emptyProperty, property_name: 'Alle Immobilien' } );

        return (
          <PopUp style={{ marginTop: '30px' }} handleClosePopUp={closePopUpViews}>
            {newPropertys.map((property) => {
              return (
                <div
                  className="lbl-container"
                  onClick={(e) => { handleSetPropertyAndId(e, property.property_name!, property.property_id.toString()) } }
                  key={property.property_id}
                >
                  <div className="lbl">{property.property_name!}</div>
                  {property.property_id == Number(propertyId) && <div className="default-icon">check</div>}
                </div>
              );
            })}
          </PopUp>
        );
      };

    const handleSetPropertyAndId = (e: React.SyntheticEvent, property: string, propertyId: string) => {

        setPropertyAndId(property, propertyId);

        closePopUpViews();

        e.stopPropagation();

    }

    const MonthPopUp = ( { monthType, monthNum }: { monthType: MonthType, monthNum: number } ) => {
    return (
        <PopUp style={{ marginTop: '30px' }} handleClosePopUp={closePopUpViews}>
        {monthsShort.map((month, index) => {
            return (
            <div
                className="lbl-container"
                onClick={(e) => handleSetMonth(e, index, monthType) }
                key={index}
            >
                <div className="lbl">{month}</div>
                {index === monthNum && <div className="default-icon">check</div>}
            </div>
            );
        })}
        </PopUp>
    );
    };

    const handleSetMonth = (e: React.SyntheticEvent, index: number, monthType: MonthType) => {
        
        let givenMonth = index;

        if ( monthType === MonthType.start && Number(endMonth) < givenMonth ) {

            givenMonth = Number(endMonth);
    
        }else if ( monthType === MonthType.end && givenMonth < Number(startMonth) ) {

            givenMonth = Number(startMonth);
    
        }

        setMonth(givenMonth, monthType);

        closePopUpViews();

        e.stopPropagation();

    }

    const YearPopUp = ( { year }: { year: string } ) => {

        const start_year = 2018;
        const yearArr = Array<Number>();

        for (let i = 0; i <= 40; i++) {
            
            yearArr.push(start_year + i);
        }

        return (
            <PopUp style={{ marginTop: '30px' }} handleClosePopUp={closePopUpViews}>
            {yearArr.map((yyear) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleSetYear(e, yyear.toString()) }
                    key={yyear.toString()}
                >
                    <div className="lbl">{yyear.toString()}</div>
                    {yyear.toString() == year && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleSetYear = (e: React.SyntheticEvent, year: string) => {

        setYear(year);

        closePopUpViews();

        e.stopPropagation();

    }

    const closePopUpViews = () => {

        setisVisiblePropertysPopUp(false);
        setIsVisibleStartMonthPopUp(false);
        setIsVisibleEndMonthPopUp(false);
        setIsVisibleYearPopUp(false);

    }

    const openPropertyPopUp = (e: React.SyntheticEvent) => {

        closePopUpViews();

        setisVisiblePropertysPopUp(true);

        e.stopPropagation();

    }

    const openStartMonthPopUp = (e: React.SyntheticEvent) => {

        closePopUpViews();

        setIsVisibleStartMonthPopUp(true);

        e.stopPropagation();

    }

    const openEndMonthPopUp = (e: React.SyntheticEvent) => {

        closePopUpViews();

        setIsVisibleEndMonthPopUp(true);

        e.stopPropagation();

    }

    const openYearPopUp = (e: React.SyntheticEvent) => {

        closePopUpViews();

        setIsVisibleYearPopUp(true);

        e.stopPropagation();

    }

    return ( 
        <div className="shadowBox spanbar-wrapper" style={ style }>

            <div className="property-wrapper">
                <div className="default-icon">location_city</div>
                <div onClick={openPropertyPopUp} className="clickable-popUpButton w100prc">
                    {property}
                    { isVisiblePropertysPopUp && <PropertyPopUp propertyId={propertyId} /> }
                </div>
            </div>

            <div className="timespan-wrapper">
                <div className="default-icon">date_range</div>
                <div onClick={openStartMonthPopUp} className="clickable-popUpButton">
                    { monthsShort[Number(startMonth)] }
                    { isVisibleStartMonthPopUp && <MonthPopUp monthType={MonthType.start} monthNum={Number(startMonth)} /> }
                </div>
                <div>bis</div>
                <div onClick={openEndMonthPopUp} className="clickable-popUpButton">
                    { monthsShort[Number(endMonth)] }
                    { isVisibleEndMonthPopUp && <MonthPopUp monthType={MonthType.end} monthNum={Number(endMonth)} /> }
                </div>

                <div onClick={openYearPopUp} className="clickable-popUpButton w100prc">
                    {year}
                    { isVisibleYearPopUp && <YearPopUp year={year} /> }
                </div>
            </div>

        </div>
     );
}
 
export default PropDateSpanBar;