import { useMutation } from "@tanstack/react-query";
import * as React from "react";
import { useContext, useRef, ReactElement, useState, useId } from "react";
import { createPortal } from "react-dom";
import { StayConversationContext } from "../../contexts/StayConversationContext";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import ActionAlert from "../../generalComps/ActionAlert";
import InputView from "../../generalComps/InputView";
import ModalView from "../../generalComps/ModalView";
import TextareaView from "../../generalComps/TextareaView";
import { fetchPdfUrl } from "../../methods/http.korres.methods";
import { getDateObj, getFullname, getVermieterFullName } from "../../methods/standard.methods";
import { AlertObj, ConversationViewType, GuestGroupObj, TemplatesObj } from "../../methods/types";
import GroupDataSelectionView from "./GroupDataSelectionView";
import TemplatesPopUpView from "./TemplatesPopUpView";

type Props = {
    
    handleClose: () => void;

}

const EditPdfView = ( { handleClose }:Props ) => {

    const { setConversationViewType, conversationTypeRef, pdfContentRef, invoiceNumberRef, templatesArrRef, conversationTypeStringRef, setPdfUrl } = useContext(StayConversationContext);
    const { stay, pmSettingsObj } = useContext(StayDetailViewContext);

    const [isVisibleTemplatesPopUp, setIsVisibleTemplatesPopUp] = useState(false);
    const [isVisibleGroupDataSelectionView, setIsVisibleGroupDataSelectionView] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const invoiceNumberTitleRef = useRef('Nummer');
    const invoiceInputRef = useRef<HTMLInputElement>(null);
    const dateInputRef = useRef<HTMLInputElement>(null);
    const dateTargetInputRef = useRef<HTMLInputElement>(null);
    const paymentMethodInputRef = useRef<HTMLInputElement>(null);

    const senderTextareaRef = useRef<HTMLTextAreaElement>(null);
    const recipientTextareaRef = useRef<HTMLTextAreaElement>(null);
    const messageTextareaRef = useRef<HTMLTextAreaElement>(null);

    const dateString = getDateObj( new Date(), 0 ).dateStringDE;
    const dateTargetString = getDateObj( new Date(), pmSettingsObj!.property_invoice_days ).dateStringDE;

    if (conversationTypeRef.current >= 3 && conversationTypeRef.current <= 5) {
        invoiceNumberTitleRef.current = 'Rechnungsnummer';
    }

    let absender = getVermieterFullName(stay.vermieter_title!, stay.vermieter_firstname!, stay.vermieter_lastname!, stay.vermieter_company!) + '\n';
        
        absender += (stay.vermieter_street) ? stay.vermieter_street + '\n' : '';
        absender += ((stay.vermieter_zip) ? stay.vermieter_zip + ' ' : '') + ((stay.vermieter_city) ? stay.vermieter_city + '\n' : '');
        absender += (stay.vermieter_country) ? stay.vermieter_country + '\n\n' : '';

        if (stay.vermieter_taxnumber) {

            if ( stay.vermieter_taxnumber!.length) {

                if ( stay.vermieter_taxval! === 0 ) {

                    absender += 'St.-Nr.: ' + stay.vermieter_taxnumber;

                } else {

                    absender += 'USt-IdNr.: ' + stay.vermieter_taxnumber;

                }

            }

        }

    const guestFullnameObj = getFullname(stay.guest_title!, stay.guest_firstname!, stay.guest_lastname!, stay.guest_company!);
    
    let guest_fullname = (guestFullnameObj.company.length !== 0) ? guestFullnameObj.company + "\n" : "";
    guest_fullname += guestFullnameObj.fullname;

    let empfaenger = guest_fullname + ((stay.guest_title! === 'Firma') ? '\n' : '');
        empfaenger += stay.guest_street + '\n';
        empfaenger += stay.guest_zip! + stay.guest_city! + '\n';
        empfaenger += stay.guest_country + '\n\n';

    const standardTemplate = templatesArrRef.current.find( tempObj => {
        return tempObj.template_type === 1 && tempObj.template_isStandardFor === conversationTypeRef.current;
    });

    const groupData: Array<GuestGroupObj> = stay.stay_group ? JSON.parse( stay.stay_group ) : [];

    const handleSaveEditedData = (e: React.SyntheticEvent) => {

        invoiceNumberRef.current = invoiceInputRef.current!.value;

        pdfContentRef.current.number = invoiceInputRef.current!.value;
        pdfContentRef.current.date = dateInputRef.current!.value;

        if (conversationTypeRef.current >= 3 && conversationTypeRef.current <= 5) {
            
            pdfContentRef.current.date_target = dateTargetInputRef.current!.value;
            pdfContentRef.current.zahlungsform = paymentMethodInputRef.current!.value;

        }

        pdfContentRef.current.absender = senderTextareaRef.current!.value;
        pdfContentRef.current.empfaenger = recipientTextareaRef.current!.value;
        pdfContentRef.current.message = messageTextareaRef.current!.value;

        fetchPdfUrlMutation();

        e.stopPropagation();

    }

    const { mutate: fetchPdfUrlMutation } = useMutation({
        mutationFn: () => fetchPdfUrl(stay.stay_id, pdfContentRef.current, conversationTypeRef.current, conversationTypeStringRef.current ),
        onSuccess: (data) => {
            
            setPdfUrl( {url: data.pdf_name!} );

            setConversationViewType(ConversationViewType.PdfView);

            handleClose();

        },
    })

    const handleTemplatesPopUpOnClick = (e: React.SyntheticEvent) => {

        if (templatesArrRef.current.length === 0) {

            setAlertObj( new AlertObj(true, 'Vorlagen', 'Du hast bisher keine Vorlagen angelegt.', standardAlertButton) )

        } else {
            setIsVisibleTemplatesPopUp(true);
        }

        e.stopPropagation();

    }

    const handleSelectedTemplate = (e: React.SyntheticEvent, template: TemplatesObj) => {

        messageTextareaRef.current!.value = template.template_message;

        setIsVisibleTemplatesPopUp(false);

        e.stopPropagation();

    }

    const handleSelectedGroupData = (groupData: Array<string>) => {

        if (groupData.length > 0) {

            const guestString = 'Gäste:\n\n' + groupData.join(', ');

            if (messageTextareaRef.current!.value.length === 0) {

                messageTextareaRef.current!.value = guestString;
    
            } else {
    
                messageTextareaRef.current!.value = messageTextareaRef.current!.value + '\n\n' + guestString;
                
            }

        }

        setIsVisibleGroupDataSelectionView(false);

    }

    const MsgTextareaIcons = (): ReactElement => {

        return(
            <>
            <div onClick={handleTemplatesPopUpOnClick} className="flex center-y clickable h100prc">
                <div className="default-icon" style={{width: '30px'}}>topic</div>
                <div className="flex center w100prc color-lightblack">Vorlage wählen</div>
            </div>
            { groupData.length > 0 &&
                <div onClick={() => setIsVisibleGroupDataSelectionView(true) } className="flex center-y clickable h100prc">
                    <div className="default-icon" style={{width: '30px'}}>group</div>
                    <div className="flex center w100prc color-lightblack">Gästedaten</div>
                </div>
            }
            { isVisibleTemplatesPopUp && <TemplatesPopUpView templatesArr={templatesArrRef.current.filter( obj => obj.template_type === 1 )} handleSelectedTemplate={handleSelectedTemplate} handleClose={() => setIsVisibleTemplatesPopUp(false) } /> }
            </>
        );

    }

    return (
        <>
        <ModalView modalViewId="editPdfView" handleClose={handleClose}>
            <div className="navigation-view-bar">
                <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">PDF-Daten bearbeiten</div>
                <div onClick={ handleSaveEditedData } className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <InputView style={{ marginTop: '20px' }} title={invoiceNumberTitleRef.current}><input ref={invoiceInputRef} defaultValue={pdfContentRef.current.number.length === 0 ? invoiceNumberRef.current : pdfContentRef.current.number} type='text' placeholder={invoiceNumberTitleRef.current}/></InputView>
            <InputView style={{ marginTop: '10px' }} title='Ausstellungsdatum'><input ref={dateInputRef} defaultValue={pdfContentRef.current.date.length === 0 ? dateString : pdfContentRef.current.date} type='text' placeholder='Ausstellungsdatum'/></InputView>
            { (conversationTypeRef.current >= 3 && conversationTypeRef.current <= 5) &&
                <>
                <InputView style={{ marginTop: '10px' }} title='Zahlungsziel'><input ref={dateTargetInputRef} defaultValue={pdfContentRef.current.date_target.length === 0 ? dateTargetString : pdfContentRef.current.date_target} type='text' placeholder='Zahlungsziel'/></InputView>
                <InputView style={{ marginTop: '10px' }} title='Zahlungsform'><input ref={paymentMethodInputRef} defaultValue={pdfContentRef.current.zahlungsform.length === 0 ? 'Überweisung' : pdfContentRef.current.zahlungsform} type='text' placeholder='Zahlungsform'/></InputView>
                </>
            }
            <TextareaView style={{ height: '190px', marginTop: '30px' }} title='Absender'><textarea ref={senderTextareaRef} placeholder='Absender' defaultValue={pdfContentRef.current.absender.length === 0 ? absender : pdfContentRef.current.absender}/></TextareaView>
            <TextareaView style={{ height: '190px', marginTop: '10px' }} title='Empfänger'><textarea ref={recipientTextareaRef} placeholder='Empfänger' defaultValue={pdfContentRef.current.empfaenger.length === 0 ? empfaenger : pdfContentRef.current.empfaenger}/></TextareaView>
            <TextareaView style={{ height: '190px', marginTop: '10px' }} title='Nachricht' popUps={<MsgTextareaIcons />}><textarea ref={messageTextareaRef} placeholder='Nachricht' defaultValue={pdfContentRef.current.message.length === 0 ? standardTemplate?.template_message : pdfContentRef.current.message}/></TextareaView>
        </ModalView>
        { isVisibleGroupDataSelectionView && createPortal( <GroupDataSelectionView handleSelectedGroupData={handleSelectedGroupData} handleClose={ () => setIsVisibleGroupDataSelectionView(false) } />, document.body ) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default EditPdfView;