import { Doughnut } from "react-chartjs-2";
import ChartView from "./ChartView";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Property } from "../methods/types";
import { useQuery } from "@tanstack/react-query";
import { fetchChartOccuAll } from "../methods/http.statistics.methods";
import { mapPropertyAndId } from "../methods/standard.methods";

type Props = {
    propertys: Array<Property>;
    title: string;
    dateSpanObj: { startMonth: string, endMonth: string, year: string };
}

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
  );

const ChartAllOccu = ( { propertys, title, dateSpanObj }: Props ) => {

    const propertyAndIds = mapPropertyAndId(propertys);

    const { data } = useQuery({
        queryKey: ["chartAllOccu", propertys, dateSpanObj],
        queryFn: () =>
          fetchChartOccuAll( JSON.stringify( propertyAndIds.names ), JSON.stringify( propertyAndIds.ids ), dateSpanObj.startMonth, dateSpanObj.endMonth, dateSpanObj.year),
        enabled: !!propertys,
        refetchOnWindowFocus: false,
    });

    const cData = {

        labels: data ? data[2] : [],
        datasets: [{
            backgroundColor: data ? data[1] : [],
            borderColor: 'black',
            borderWidth: 0,
            data: data ? data[0] : [],
        }],

    }

    return ( 

        <ChartView title={title} style={{ height: '280px', flexDirection: 'column' }}>
            <div className="flex-container center w100prc h100prc" style={{ padding: '10px' }}>
                <Doughnut data={ cData } />
            </div>
        </ChartView>

     );
}
 
export default ChartAllOccu;