import { ReactElement, useContext } from "react";
import { StayConversationContext } from "../../contexts/StayConversationContext";
import ModalView from "../../generalComps/ModalView";
import MailCreationView from "../../messageComps/MailCreationView";
import { ConversationViewType } from "../../methods/types";
import ConversationView from "./ConversationView";
import PdfView from "./PdfView";

type Props = {
    
    handleClose: () => void;

}

const StayConversationWrapperView = ( { handleClose }:Props ) => {

    const { conversationViewType } = useContext(StayConversationContext);

    const ContentView = (): ReactElement => {

        switch (conversationViewType) {

            case ConversationViewType.ConversationView:
                return <ConversationView handleClose={handleClose} />

            case ConversationViewType.PdfView:
                return <PdfView handleClose={handleClose} />

            case ConversationViewType.MailView:
                return <MailCreationView handleClose={ handleClose } />
        
            default:
                return <div>Fehler</div>

        }

    }

    return ( 
        <ModalView modalViewId="stayConversationWrapperView" handleClose={handleClose}>
            <ContentView />
        </ModalView>
     );
}
 
export default StayConversationWrapperView;