import { useContext, useEffect, useState } from "react";
import { SettingsViewContext } from "../contexts/SettingsContext";
import { PropertysContext } from "../contexts/PropertysContext";
import PopUp from "../generalComps/PopUp";
import { InfoWidgetObj, Property } from "../methods/types";
import { useMutation } from "@tanstack/react-query";
import { fetchExportUrlByProperty } from "../methods/http.calendar.methods";
import { copyToClipboard } from "../methods/standard.methods";
import InfoWidget from "../generalComps/InfoWidget";

type Props = {
    isModal: boolean;
    handleClose?: () => void | undefined;
    givenProperty?: Property | undefined;
}

const CalendarExport = ( {isModal, handleClose, givenProperty }: Props ) => {

    const { propertys } = useContext(PropertysContext);
    const { setSettingsViewType, lastSettingsViewTypeRef } = useContext(SettingsViewContext);

    const [selectedProperty, setSelectedProperty] = useState<Property>( givenProperty === undefined ? propertys.array[0] : givenProperty );

    const [isVisibleTypePopUp, setIsVisibleTypePopUp] = useState(false);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const { isPending, data, mutate: fetchExportUrlByPropertyMutation } = useMutation( {

        mutationFn: (propertyId: number) => fetchExportUrlByProperty( propertyId ),

    } );

    useEffect( () => fetchExportUrlByPropertyMutation(propertys.array[0].property_id), [] );

    const TypePopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px', height: 'auto' }} handleClosePopUp={ () => setIsVisibleTypePopUp(false) }>
            {propertys.array.map( (property) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => {

                        setSelectedProperty( property );

                        fetchExportUrlByPropertyMutation( property.property_id );

                        setIsVisibleTypePopUp(false);

                        e.stopPropagation();

                    } }
                    key={property.property_id}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{property.property_name}</div>
                    {property.property_id === selectedProperty!.property_id && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleCopyToClipboard = (text: string) => {

        copyToClipboard(text);

        setInfoWidgetObj( new InfoWidgetObj( true, 'Link in Zwischenablage kopiert' ));

    }

    return ( 

        <>
        <div className="flex center-y w100prc">
            { isModal ?
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                :
                <div onClick={ () => setSettingsViewType( lastSettingsViewTypeRef.current ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
            }
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem', paddingRight: '40px'}}>Belegungsplan - Export</div>
        </div>
        <div className="shadowBox" style={{ marginTop: '10px' }}>Mit folgendem Link werden alle Aufenthalte der ausgewählten Immobilie in einen anderen Kalender exportiert. Der Kalender muss hierzu das iCal-Format unterstützen. Dies können alle gängigen Kalender und Portale. Übertragen werden abgesehen vom Zeitraum noch der Immobilienname und der Status. Füge diesen Link in das Import-Feld des entsprechenden Kalenders ein:</div>
        <div onClick={ () => setIsVisibleTypePopUp(true) } className="clickable-popUpButton" style={{ marginTop: '10px' }}>
            { selectedProperty.property_name }
            { isVisibleTypePopUp && <TypePopUp  /> }
        </div>
        <div title="Klicken zum Kopieren" onClick={ () => handleCopyToClipboard(data ? data.obj.url : 'Fehler beim Kopieren') } className="flex center w100prc cursor-pointer standard-fontsize" style={{ marginTop: '10px', padding: '5px 10px', border: '1px solid var(--line-color)', borderRadius: '5px', fontStyle: 'italic', }}>
            { isPending ? 'Lade Export-URL...' : data ? data.obj.url : 'Fehler beim Laden...' }
        </div>
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        </>
     );
}
 
export default CalendarExport;