import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState, useMemo, SyntheticEvent } from "react";
import "../../css/dateselectpopup.css";
import { monthsLong } from "../../methods/constants";
import { getCalendarWeeks } from "../../methods/date.methods";
import { fetchGermanHolidays } from "../../methods/http.holidayapi.methods";
import { getDateObj, getFullname } from "../../methods/standard.methods";
import { DateObj, Stay } from "../../methods/types";
import { DateSelectType } from "./DateSelect";
import PopUp from "../PopUp";
import { fetchStaysByProperty } from "../../methods/http.stays.methods";

type Props = {

    dateSelectType: DateSelectType;
    startDateObj: DateObj;
    endDateObj?: DateObj | undefined;
    style?: React.CSSProperties | undefined;
    propertyId?: number | undefined;
    handleSelectedDateObj: (e: React.SyntheticEvent, dateObj: DateObj) => void;
    handleClosePopUp: () => void;

}

const DateSelectPopUp = ( {dateSelectType, startDateObj, endDateObj, style, propertyId, handleSelectedDateObj, handleClosePopUp}:Props ) => {

    const [mainDate, setMainDate] = useState( endDateObj && dateSelectType === DateSelectType.CheckOut ? endDateObj.date : startDateObj.date );

    const [isVisibleMonthPopUp, setIsVisibleMonthPopUp] = useState(false);
    const [isVisibleYearPopUp, setIsVisibleYearPopUp] = useState(false);

    const [holidays, setHolidays] = useState<{ [key: string]: string; } | null>(null);
    const [stays, setStays] = useState<Array<Stay>>([]);
    //let holidays: { [key: string]: string; } | null = null;
    //let stays = Array<Stay>();

    const today = new Date();
    today.setHours(0,0,0,0);
    
    const [calendarWeeks, datesArr] = useMemo(() => getCalendarWeeks(mainDate), [mainDate] );

    const { mutate: fetchHolidaysMutation } = useMutation({
        mutationFn: () => fetchGermanHolidays(mainDate.getFullYear()),
        onSuccess: (data) => {

            setHolidays(data);

        },
    });

    useEffect(() => { fetchHolidaysMutation(); }, []);

    const { mutate: fetchStaysByPropertyMutation } = useMutation({
        mutationFn: () => fetchStaysByProperty(propertyId!.toString(), datesArr[0].dateStringUS, datesArr[datesArr.length-1].dateStringUS, -1),
        onSuccess: (data) => {

            if (data.success) {
                setStays(data.obj);
            }

        },
    });

    useEffect(() => { 
        
        if (propertyId !== -1) {
            fetchStaysByPropertyMutation(); 
        }
    
    }, [datesArr]);

    const handleSelectedDate = (e: React.SyntheticEvent, dateObj: DateObj) => {

        handleSelectedDateObj(e, dateObj);

        e.stopPropagation();

    }

    const YearPopUp = ( { year }: { year: string } ) => {

        const start_year = 1930;
        const yearArr = Array<Number>();

        for (let i = 0; i <= 150; i++) {
            
            yearArr.push(start_year + i);
        }

        useEffect(() => { 
            
            const element = document.getElementById('yearPopUp')!; 
   
            element.scroll({ 
                top: element.scrollHeight / 1.7 + 200,
        });
        
        }, []);

        return (
            <PopUp idTag="yearPopUp" style={{ marginTop: '30px' }}>
            {yearArr.map((yyear) => {
                return (
                <div
                    id={year}
                    className="lbl-container"
                    onClick={(e) => handleSetYear(e, yyear.toString()) }
                    key={yyear.toString()}
                >
                    <div className="lbl">{yyear.toString()}</div>
                    {yyear.toString() == year && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const openYearPopUp = (e: React.SyntheticEvent) => {

        closePopUpViews();

        setIsVisibleYearPopUp(true);

        e.stopPropagation();

    }

    const handleSetYear = (e: React.SyntheticEvent, year: string) => {

        const dateObj = getDateObj( new Date( Number(year), mainDate.getMonth(), mainDate.getDate(), 0, 0, 0, 0 ), 0 );

        setMainDate( dateObj.date );

        closePopUpViews();

        e.stopPropagation();

    }

    const MonthPopUp = ( { monthNum }: { monthNum: number } ) => {
        return (
            <PopUp style={{ marginTop: '30px' }}>
            {monthsLong.map((month, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleSetMonth(e, index) }
                    key={index}
                >
                    <div className="lbl">{month}</div>
                    {index === monthNum && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const openMonthPopUp = (e: React.SyntheticEvent) => {

        closePopUpViews();

        setIsVisibleMonthPopUp(true);

        e.stopPropagation();

    }

    const handleSetMonth = (e: React.SyntheticEvent, monthNum: number) => {

        const dateObj = getDateObj( new Date( mainDate.getFullYear(), monthNum, mainDate.getDate(), 0, 0, 0, 0 ), 0 );

        setMainDate( dateObj.date );

        closePopUpViews();

        e.stopPropagation();

    }

    const closePopUpViews = () => {

        setIsVisibleMonthPopUp(false);
        setIsVisibleYearPopUp(false);

    }

    return ( 

        <PopUp style={style} /*handleClosePopUp={ handleClosePopUp }*/>
            <div className="flex center w100prc" style={{gap: '20px', height: '50px', padding: '0 10px', overflow: 'visible', }}>
                <div onClick={openMonthPopUp} className="clickable-popUpButton w100prc">
                    { monthsLong[mainDate.getMonth()] }
                    { isVisibleMonthPopUp && <MonthPopUp monthNum={mainDate.getMonth()} /> }
                </div>
                <div onClick={openYearPopUp} className="clickable-popUpButton w100prc">
                {mainDate.getFullYear()}
                { isVisibleYearPopUp && <YearPopUp year={mainDate.getFullYear().toString()} /> }
            </div>
            </div>
            <div className="flex center-y w100prc color-black border-bottom-strong" style={{height: '40px'}}>
                <div className="flex center w100prc">KW</div>
                <div className="flex center w100prc">Mo.</div>
                <div className="flex center w100prc">Di.</div>
                <div className="flex center w100prc">Mi.</div>
                <div className="flex center w100prc">Do.</div>
                <div className="flex center w100prc">Fr.</div>
                <div className="flex center w100prc">Sa.</div>
                <div className="flex center w100prc">So.</div>
            </div>
            <div className="flex w100prc h100prc">
                <div className="flex h100prc" style={{flexDirection: 'column', justifyContent: 'flex-start', width: 'calc(100% / 8)'}}>
                    { calendarWeeks.map( (cw) => {
                        
                        return <div key={cw.toString()} className="flex center w100prc h100prc color-lightblack border-right-strong">{cw.toString()}</div>;
                    } ) }
                </div>
                <div className="flex wrap h100prc" style={{width: 'calc(100% - (100% / 8))'}}>
                    { datesArr.map( (dateObj) => {
                        
                        const opa = mainDate.getMonth() === dateObj.date.getMonth() ? '1.0' : '0.2';

                        let className = 'date-default';
                        let title = '';

                        if (dateObj.date.getDay() === 0 || dateObj.date.getDay() === 6) {
                            className += ' weekend';
                        }
                        
                        if (dateObj.date.getTime() === today.getTime()) {
                            className = 'date-default today';
                            title = 'Heute';
                        }
                        
                        if (dateObj.date.getTime() === startDateObj.date.getTime() || dateObj.date.getTime() === endDateObj?.date.getTime()) {
                            className = 'date-default selected-start-end';
                        }

                        if (dateObj.date.getTime() > startDateObj.date.getTime() && (endDateObj && dateObj.date.getTime() < endDateObj.date.getTime())) {
                            className = 'date-default selected';
                        }

                        if (holidays && Object.values(holidays).includes(dateObj.dateStringUS)) {
                            className += ' holiday';
                            title = `<${Object.keys(holidays).find(key => holidays![key] === dateObj.dateStringUS)!}>`;
                        }

                        const filteredStay = stays?.filter( (stay) => {
                            
                            const ciDate = new Date(stay.stay_checkIn!);
                            ciDate.setHours(0,0,0,0);

                            const coDate = new Date(stay.stay_checkOut!);
                            ciDate.setHours(0,0,0,0);

                            const compDate = new Date(dateObj.date);
                            compDate.setHours(0,0,0,0);
                           
                            return compDate.getTime() >= ciDate.getTime() && compDate.getTime() <= coDate.getTime();
                        
                        } );
                        
                        if (stays && filteredStay!.length > 0 ) {
                            
                            if (filteredStay![0].stay_status! < 5) {

                                className += ' booked';
                                title += `<${getFullname(filteredStay![0].guest_title!, filteredStay![0].guest_firstname!, filteredStay![0].guest_lastname!, filteredStay![0].guest_company!).fullname}>`;

                            } else if (filteredStay![0].stay_status! === 5) {

                                className += ' blocked';
                                title = `<${filteredStay![0].stay_block_title!}>`;
                            }
                        }

                        return (
                        
                        <div onClick={(e) => handleSelectedDate(e, dateObj)} key={dateObj.dateStringUS} className="weekday-backview" style={{opacity: opa}} title={title}>
                            <div className={className}>{dateObj.date.getDate()}</div>
                        </div>
                        
                        );

                    } ) }
                </div>
            </div>
        </PopUp>

     );
}
 
export default DateSelectPopUp;