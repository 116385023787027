import { DefaultFetchResult, PdfDesignMainObj } from "./types";

export const fetchPdfDesignByType = async (korresType: number) => {

    const data = new URLSearchParams();
  
    data.append('pdf_art', korresType.toString());
  
    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=pdf_design&do=select',
        {
        method: 'POST', 
        body: data,
       'credentials': 'include',
        }
      )
  
    const content: DefaultFetchResult = await result.json();
  
    return content;
  
}

export const postAddPdfDesign = async (obj: PdfDesignMainObj) => {

  const data = new URLSearchParams();

  data.append('pdf_art', obj.pdf_art.toString());
  data.append('pdf_image', obj.pdf_image ? obj.pdf_image : '');
  data.append('pdf_image_visible', obj.pdf_image_visible.toString());
  data.append('pdf_design', obj.pdf_design);

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=pdf_design&do=insert',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const postUpdatePdfDesign = async (obj: PdfDesignMainObj) => {

  const data = new URLSearchParams();

  data.append('pdf_id', obj.pdf_id.toString());
  data.append('pdf_art', obj.pdf_art.toString());
  data.append('pdf_image', obj.pdf_image ? obj.pdf_image : '');
  data.append('pdf_image_visible', obj.pdf_image_visible.toString());
  data.append('pdf_design', obj.pdf_design);

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=pdf_design&do=update',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const postDeletePdfDesing = async (pdf_id: number) => {

  const data = new URLSearchParams();

  data.append('pdf_id', pdf_id.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=pdf_design&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}