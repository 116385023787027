import { useContext, useState } from "react";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import MultiCalendarPropertysCard from "./MultiCalendarPropertysCard";
import PopUp from "../../generalComps/PopUp";
import MenuButton from "../../generalComps/MenuButton";
import { createPortal } from "react-dom";
import ModalView from "../../generalComps/ModalView";
import CalendarShareList from "../../settingsComps/calendarShareComps/CalendarShareList";
import PropertysViewContextProvider from "../../contexts/PropertysMainContext";

const MultiCalendarPropertys = () => {

    const { filteredPropertys, year } = useContext(OccupancyContext);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);
    const [isVisibleShareView, setIsVisibleShareView] = useState(false);

    const handleScrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        
        const element = document.getElementById('multiCalendarContentTable');

        if (element) {
            element.scrollTop = e.currentTarget.scrollTop;
        }

    };

    return ( 

        <>
        <div id="multiCalendarPropertysTable" onScroll={ handleScrollEvent } className="shadowBox">
            <div className="flex center w100prc border-bottom-strong" style={{ position: 'relative', height: '40px', fontSize: '1.2em', overflow: 'visible' }}>
                <div onClick={ (e) => { setIsVisibleMenu( prev => !prev ); e.stopPropagation(); } } className="flex center h100prc clickable-icon" style={{ width: '50px', fontSize: '1.5em' }}>more_horiz</div>
                <div className="flex" style={{ width: 'calc( 100% - 50px )' }}></div>
                <div id="yearLbl" className="flex center" style={{ fontSize: '1.2em', width: '120px' }}>Jahr {year}</div>
            </div>
            
            {
                filteredPropertys.map( (property, index) => {

                    return (

                        <PropertysViewContextProvider key={property.property_id}>
                            <MultiCalendarPropertysCard key={property.property_id} property={property} style={{ height: 'auto', position: 'relative', padding: '5px', background: index % 2 === 0 ? 'var(--tile-bg-hover)' : 'var(--alert-btn-hover)', overflow: 'visible' }} />
                        </PropertysViewContextProvider>

                    );

                })
            }
            { isVisibleMenu && 
            <PopUp idTag="propertyDetailMenu" style={{ gap: '10px', minWidth: '200px', height: 'auto', fontSize: '1.0rem', marginTop: '55px', marginLeft: '5px'  }} handleClosePopUp={ () => setIsVisibleMenu(false) } >
                <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => setIsVisibleShareView(true) } leftIcon="share" buttonTitle="Belegungsplan freigeben" />
            </PopUp>
            }
        </div>
        { isVisibleShareView && createPortal(
            <ModalView modalViewId="calendarShareView" style={{ width: '700px', overflow: 'visible' }} handleClose={ () => setIsVisibleShareView(false) }>
                <CalendarShareList isModal={true} handleClose={ () => setIsVisibleShareView(false) }/>
            </ModalView>, document.body
        ) }
        </>

     );
}
 
export default MultiCalendarPropertys;