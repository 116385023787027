import { memo, useContext, useEffect, useMemo } from "react";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import { amountOfPaymentListArr, paymentIconVisibility } from "../methods/price.calculation.methods";
import { PaymentAmountType } from "../methods/types";

type Props = {
    handlePaymentsClick: (paymentType: PaymentAmountType, title: string, artikel: string) => void;
}

const StayInvoiceCancellationView = ( {handlePaymentsClick}: Props) => {

    const { pmSettingsObj, paymentArr, invoicePriceObj } = useContext(StayDetailViewContext);

    const aopla = useMemo( () => amountOfPaymentListArr(paymentArr), [paymentArr]);

    const currency = pmSettingsObj!.property_currency;

    let allSum_net = invoicePriceObj.cancellationFeeAmount;

    let tax = 0;

    if ( pmSettingsObj!.property_tax ) {

        tax = ( pmSettingsObj!.property_tax_percent * invoicePriceObj.cancellationFeeAmount ) / (100 + pmSettingsObj!.property_tax_percent);

        allSum_net -= tax;

    }
   
    return ( 

        <>
        <div className="flex center-y w100prc" style={{fontSize: '1.2em'}}>Kostenaufstellung</div>

        <div className="flex center-y w100prc standard-fontsize" style={{padding: '10px 5px 0 5px'}}>
            <div className="flex center-y" style={{width: '70%'}}>Stornogebühr</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right', paddingRight: '30px'}}>{`${allSum_net.toFixed(2)} ${currency}`}</div>
        </div>

        { pmSettingsObj!.property_tax &&

            <>
            <div className="flex center-y w100prc standard-fontsize" style={{padding: '10px 5px'}}>
                <div className="flex center-y" style={{width: '70%', fontWeight: 'bold'}}>Nettobetrag</div>
                <div className="flex" style={{width: '30%', justifyContent: 'right', paddingRight: '30px'}}>{`${allSum_net.toFixed(2)} ${currency}`}</div>
            </div>

            <div className="flex center-y w100prc standard-fontsize" style={{padding: '0 5px'}}>
                <div className="flex center-y" style={{width: '70%'}}>{`${pmSettingsObj!.property_tax_percent} % Umsatzsteuer`}</div>
                <div className="flex" style={{width: '30%', justifyContent: 'right', paddingRight: '30px'}}>{`${tax.toFixed(2)} ${currency}`}</div>
            </div>

            <div className="flex center-y w100prc standard-fontsize" style={{padding: '10px 5px'}}>
                <div className="flex center-y" style={{width: '70%', fontWeight: 'bold'}}>Rechnungsbetrag</div>
                <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.cancellationFeeAmount.toFixed(2)} ${currency}`}</div>
                <div onClick={() => handlePaymentsClick(PaymentAmountType.Cancellation, 'Stornobetrag', 'der') } className="flex center clickable-icon" style={{ width: '30px', visibility: paymentIconVisibility( invoicePriceObj.cancellationFeeAmount, paymentArr, aopla, invoicePriceObj.cancellationFeeAmount ) }}>payments</div>
            </div>
            </>

        }

        </>

     );
}
 
export default memo( StayInvoiceCancellationView );