import { useContext, useState } from "react";
import MultiCalendarPropertysCard from "./MultiCalendarPropertysCard";
import { OccupancyModulContext } from "../../../contexts/OccupancyModulContext";

const MultiCalendarPropertys = () => {

    const { filteredPropertys, year } = useContext(OccupancyModulContext);

    const handleScrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        
        const element = document.getElementById('multiCalendarContentTable');

        if (element) {
            element.scrollTop = e.currentTarget.scrollTop;
        }

    };

    return ( 

        <>
        <div id="multiCalendarPropertysTable" onScroll={ handleScrollEvent } className="shadowBox">
            <div className="flex center w100prc border-bottom-strong" style={{ position: 'relative', height: '40px', fontSize: '1.2em', overflow: 'visible' }}>
                <div className="flex" style={{ width: 'calc( 100% - 50px )' }}></div>
                <div id="yearLbl" className="flex center" style={{ fontSize: '1.2em', width: '120px' }}>Jahr {year}</div>
            </div>
            
            {
                filteredPropertys.map( (property, index) => {

                    return (

                        <MultiCalendarPropertysCard key={property.property_id} property={property} style={{ height: 'auto', position: 'relative', padding: '5px', background: index % 2 === 0 ? 'var(--tile-bg-hover)' : 'var(--alert-btn-hover)', overflow: 'visible' }} />

                    );

                })
            }
        </div>
        </>

     );
}
 
export default MultiCalendarPropertys;