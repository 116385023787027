import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useRef, useState, useId, useEffect, useMemo } from "react";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import ActionAlert from "../generalComps/ActionAlert";
import ModalView from "../generalComps/ModalView";
import { postUpdateStaySingleValue } from "../methods/http.stays.methods";
import { AlertObj, GuestGroupObj, ViewState } from "../methods/types";
import StayGroupAddEditView from "./StayGroupAddEditView";
import StayGroupCard from "./StayGroupCard";
import { OccupancyContext } from "../contexts/OccupancyContext";

type Props = {

    handleClose: () => void;

}

const StayGroupView = ( { handleClose }:Props ) => {

    const queryClient = useQueryClient();

    const { stay, setStay } = useContext(StayDetailViewContext);

    const { reloadStayData } = useContext(OccupancyContext);

    //const groupArrRef = useRef<Array<GuestGroupObj>>(JSON.parse( stay.stay_group ));
    const groupArr: Array<GuestGroupObj> = useMemo( () => JSON.parse( stay.stay_group ), [stay.stay_group])

    const [isVisibleAddEditView, setIsVisibleAddEditView] = useState(false);

    const personObjRef = useRef<GuestGroupObj | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();

    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    // useEffect(() => {

    //     groupArrRef.current = JSON.parse( stay.stay_group );

    // }, [stay.stay_group]);

    const handleOnClickAddIcon = (e: React.SyntheticEvent, personObj: GuestGroupObj | null) => {

        personObjRef.current = personObj;

        setIsVisibleAddEditView(true);

        if( groupArr.length === (stay.stay_adults + stay.stay_children)  ) {

            setAlertObj( new AlertObj(true, 'Achtung', `Du hast bereits Daten für ${groupArr.length} Gäste erfasst. Jede weitere Erfassung übersteigt die angegebene Personenzahl des Aufenthalts. Du kannst dennoch fortfahren, sofern dieser Umstand beabsichtigt ist.`, standardAlertButton) );
        
        }

        e.stopPropagation();

    }

    const handleOnClickCard = (personObj: GuestGroupObj, state: ViewState) => {

        if (state === ViewState.Edit) {

            personObjRef.current = personObj;

            setIsVisibleAddEditView(true);

        } else if (state === ViewState.Delete) {

            const index = groupArr.findIndex(obj => obj.title === personObj.title && obj.firstname === personObj.firstname && obj.lastname === personObj.lastname && obj.birthday === personObj.birthday && obj.nationality === personObj.nationality && obj.identification === personObj.identification );

            groupArr.splice(index, 1);

            addStayGroupObjMutation();

        }

    }

    const { mutate: addStayGroupObjMutation } = useMutation({
        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, 'stay_group', JSON.stringify(groupArr)),
        onSuccess: () => {

            setStay( prevStay => ({ ...prevStay, stay_group: JSON.stringify(groupArr)}) );

            setTimeout(() => {
                reloadStayData();
            }, 1000);

            queryClient.invalidateQueries( { queryKey: ['fetchStays'] } );

        },
    })

    return ( 

        <>
        <ModalView modalViewId="stayGroupView" style={{width: '600px'}} handleClose={handleClose}>
            <div className="navigation-view-bar">
                <div onClick={handleClose} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
                <div className="main-title">Begleitpersonen</div>
                <div onClick={(e) => handleOnClickAddIcon(e, null) } className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>add_circle</div>
            </div>
            <div className="shadowBox center" style={{marginTop: '20px'}}>Hinterlege hier bei Bedarf die persönlichen Daten zu den Gästen. Diese Daten (oder ein Teil davon) kannst du dann z. B. in der Rechnung aufführen lassen.</div>
            { groupArr.length === 0 && <div className="shadowBox center color-lightblack" style={{marginTop: '10px'}}>Keine Begleitpersonen angelegt</div> }
            {
                groupArr.map( (person, i) => {

                    return <StayGroupCard key={i} handleOnClick={ handleOnClickCard } personObj={person} index={i} />

                })
            }
        </ModalView>
        { isVisibleAddEditView && <StayGroupAddEditView handleClose={() => setIsVisibleAddEditView(false) } personObj={personObjRef.current} /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default StayGroupView;