import { ReactElement, useContext, useEffect, useId, useState } from "react";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import { AlertObj, InfoWidgetObj, PaymentsViewType, PropertyListType, ViewState, ZkObj, ZlObj } from "../../../methods/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../../methods/http.property.methods";
import InfoWidget from "../../../generalComps/InfoWidget";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { emptyBankAccount } from "../../../methods/constants";
import { fetchBankAccounts, postDeleteBankAccount } from "../../../methods/http.bankaccount.methods";
import BankAccountCard from "./BankAccountCard";
import BankAccountAddEditView from "./BankAccountAddEditView";

type Props = {
    handleClose: () => void;
}

const BankAccountListView = ( { handleClose }: Props ) => {

    const queryClient = useQueryClient();

    const { setPaymentsViewType, selectedProperty, setSelectedProperty, propertyListType, setSelectedBankAcc } = useContext( PropertysViewContext );

    const [isVisibleBankAccAddEditView, setIsVisibleBankAccAddEditView] = useState(false);

    const [bankAccs, setBankAccs] = useState<Array<ZkObj>>([]);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const { mutate: fetchBankAccsMutation } = useMutation({
        mutationFn: () => fetchBankAccounts(),
        onSuccess: (data) => {

            if (data.success) {
                setBankAccs( data.obj );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => fetchBankAccsMutation(), [] );

    const NoData = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Konten vorhanden</div>;
    }

    const handleBankAccCardClick = (state: ViewState, bankAcc: ZkObj) => {

        if (state === ViewState.Details) {

            postUpdatePropertySingleMutation( bankAcc.zk_id );

        } else if (state === ViewState.Delete) {

            const alertButtons = [ {title: 'Löschen', handler: () => postDeleteBankAccMutation(bankAcc.zk_id) }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Konto löschen?', `Soll das Konto '${ bankAcc.zk_paypal.length === 0 ? bankAcc.zk_name : bankAcc.zk_paypal}' wirklich gelöscht werden?`, alertButtons ) );

        } else if (state === ViewState.Edit) {

            setSelectedBankAcc( bankAcc );

            setIsVisibleBankAccAddEditView(true);

        }

    }

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: (bankAccId: number) => postUpdatePropertySingleValue( selectedProperty.property_id, 'property_zk', bankAccId.toString() ),
        onSuccess: (_, variables) => {

            setSelectedProperty( prev => ( {...prev, property_zk: variables } ) );

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postDeleteBankAccMutation } = useMutation({
        mutationFn: (bankAccId: number) => postDeleteBankAccount(bankAccId),
        onSuccess: () => {

            fetchBankAccsMutation();

            setAlertObj( new AlertObj() );

            setInfoWidgetObj( new InfoWidgetObj( true, 'Konto gelöscht.') )

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSaveBankAcc = (type: string, newBankAccId?: number | undefined) => {

        fetchBankAccsMutation();

        setIsVisibleBankAccAddEditView( false );
        setSelectedBankAcc( emptyBankAccount );

        setInfoWidgetObj( new InfoWidgetObj(true, `Konto erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

        if (type === 'new' && newBankAccId !== undefined && newBankAccId !== null ) {

            const alertButtons = [ {title: 'Verknüpfen', handler: () => {

                postUpdatePropertySingleMutation( newBankAccId );
                
                setAlertObj( new AlertObj() );

            } }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Konto verknüpfen?', `Das soeben angelegte Konto mit dieser Immobilie verknüpfen?`, alertButtons ) );

        }

    }

    return ( 

        <>
        <ModalView modalViewId="bankAccListView" style={{ width: '650px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ () => setPaymentsViewType( PaymentsViewType.MainMenu ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
                <div className="main-title">Konten</div>
                <div onClick={ () => { setSelectedBankAcc(emptyBankAccount); setIsVisibleBankAccAddEditView(true); } } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
            </div>
            <div className="flex center w100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: '3px', marginTop: '10px' }}>
            { 

                bankAccs.length === 0 ?

                <NoData />

                :

                bankAccs.map( ( bankAcc ) => {

                    return <BankAccountCard key={bankAcc.zk_id} bankAcc={bankAcc} handleOnClick={ (state) => handleBankAccCardClick(state, bankAcc) } />;
        
                }) 

            }
            </div>
        </ModalView>
        { isVisibleBankAccAddEditView && <BankAccountAddEditView handleCancel={ () => setIsVisibleBankAccAddEditView(false) } handleSave={ handleSaveBankAcc } /> }
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>
     );
}
 
export default BankAccountListView;