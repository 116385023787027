export class DefaultFetchResult {
    success: boolean = false;
    obj?: any = '';
    info?: string = '';
    response_title?: string = '';
    response_text?: string = '';
    pdf_name?: string = '';
    session?: string = '';
    guest_id?: string = '';
    stay_id?: string = '';
    pdf_id?: string = '';
    id?: string = '';
    address_id?: string = '';
    vermieter_id?: string = '';
    price_id?: string = '';
    abz_id?: string = '';
    zl_id?: string = '';
    kt_id?: string = '';
    zk_id?: string = '';
    blob_id?: string = '';
    verification?: boolean = false;
}

export class AlertObj {
    isVisible: boolean;
    title: string;
    msg: string;
    object?: any;
  
    constructor(isVisible: boolean = false, title: string = '', msg: string = '', object: any = Array({title: 'Ok', handler: ()=>{}})) {
      this.isVisible = isVisible;
      this.title = title;
      this.msg = msg;
      this.object = object;

    }
  
}

export class InfoWidgetObj {
    isVisible: boolean;
    msg: string;
  
    constructor(isVisible: boolean = false, msg: string = '') {
      this.isVisible = isVisible;
      this.msg = msg;

    }
  
}

export type Property = {

    address_city?: string;
    address_country?: string;
    address_street?: string;
    address_zip?: string;
    property_abz?: string;
    property_address?: number;
    property_art: string;
    property_bathroom: number;
    property_bedroom: number;
    property_beds: number;
    property_build: number;
    property_checkIn_time?: string;
    property_checkOut_time?: string;
    property_id: number;
    property_img_uploaded?: number;
    property_import_links?: string;
    property_kitchen: number;
    property_tp?: string;
    property_kt?: string;
    property_level: string;
    property_levels: number;
    property_livingroom: number;
    property_name: string;
    property_pm?: string;
    property_price?: string;
    property_service?: string;
    property_squaremeters: number;
    property_vermieter?: number;
    property_zk?: number;
    property_zl?: string;
    property_img?: string;
    user_folder?: string;
    vermieter_city?: string;
    vermieter_country?: string;
    vermieter_firstname?: string;
    vermieter_img_uploaded: number;
    vermieter_lastname?: string;
    vermieter_street?: string;
    vermieter_title?: string;
    vermieter_zip?: string;
    vermieter_id?: number;

}

export type Stay = {

    stay_id: number;
    stay_adults: number;
    stay_block_title?: string;
    stay_checkIn: string;
    stay_checkIn_time?: string;
    stay_checkOut: string;
    stay_checkOut_time?: string;
    stay_children: number;
    stay_group: string;
    stay_guest?: number;
    stay_import_portal?: string;
    stay_invoiceList?: string;
    stay_note?: string;
    stay_paymentList?: string;
    stay_pets: number;
    stay_priceList?: string;
    stay_property: number;
    stay_special_price: number;
    stay_status: number;
    stay_storno_val: number;
    guest_blacklist?: number;
    guest_company?: string;
    guest_firstname?: string;
    guest_lastname?: string;
    guest_rate?: number;
    guest_title?: string;
    property_art?: string;
    property_img_uploaded?: number;
    property_kt?: string;
    property_name?: string;
    property_pm?: string;
    property_zl?: string;
    user_folder?: string;
    daysTillCI?: number;
    daysTillCO?: number;
    amountOfNights?: number;
    stay_korresList?: string;
    property_level?: string;
    property_livingroom?: number;
    property_kitchen?: number;
    property_bedroom?: number;
    property_bathroom?: number;
    property_squaremeters?: number;
    property_levels?: number;
    property_beds?: number;
    property_build?: string;
    property_address?: number;
    address_street?: string;
    address_zip?: string;
    address_city?: string;
    address_country?: string;
    guest_street?: string;
    guest_zip?: string;
    guest_city?: string;
    guest_country?: string;
    guest_contact?: string;
    vermieter_title?: string;
    vermieter_firstname?: string;
    vermieter_lastname?: string;
    vermieter_img_uploaded?: number;
    vermieter_street?: string;
    vermieter_zip?: string;
    vermieter_city?: string;
    vermieter_country?: string;
    vermieter_website?: string;
    vermieter_contact?: string;
    vermieter_company?: string;
    vermieter_taxnumber?: string;
    vermieter_taxval?: number;
    zk_name?: string;
    zk_iban?: string;
    zk_bic?: string;
    zk_paypal?: string;
    ical_name?: string;

}

export type Guest = {

    guest_id: number;
    guest_title: string;
    guest_firstname?: string;
    guest_lastname: string;
    guest_company?: string;
    guest_birthday?: string;
    guest_birthday_reminder: number;
    guest_rate: number;
    guest_street?: string;
    guest_zip?: string;
    guest_city?: string;
    guest_country?: string;
    guest_contact?: string;
    guest_note?: string;
    guest_blacklist: number;
    guest_stay_amount?: number;

}

export type Landlord = {

    vermieter_id: number;
    vermieter_title: string;
    vermieter_firstname: string;
    vermieter_lastname: string;
    vermieter_company: string;
    vermieter_taxval: number;
    vermieter_taxnumber: string;
    vermieter_street: string;
    vermieter_zip: string;
    vermieter_city: string;
    vermieter_country: string;
    vermieter_contact: string;
    vermieter_img_uploaded: number;
    vermieter_website: string;
    vermieter_img: string;
    user_folder: string;

}

export type DateObj = {

    date: Date;
    dateStringDE: string;
    dateStringUS: string;
    dayStringShort: string;
    dayStringLong: string;

}

export type Contact = {

    contact_art: string;
    contact_value: string;
    contact_key: string;
    contact_view: number;

}

export type Note = {

    note_art: string;
    note_val: string;
    note_key: string;
    note_data: string;
    note_view: number;

}

export type ChartData = {

    data: Array<Number>;
    color: Array<String>;
    labels: Array<String>;

}

export enum ViewState {
    New,
    Edit,
    Details,
    Delete,
    Cancellation,
    ActivateDeactivate,
}

export enum CNViewType {
    MailAndPhone = 'Kontaktinformationen',
    NoteAndAttachment = 'Notizen & Anlagen',
}

export enum ContactKey {

    Mail = 'Email',
    Phone = 'Telefonnummer',
    Note = 'Notiz',
    Attachment = 'Anlage',

}

export enum PhoneType {

    Mobile = 'Mobil',
    Home = 'Heim',
    Work = 'Arbeit',

}

export enum NoteType {

    General = 'Allgemein',
    People = 'Personen',
    Animals = 'Tiere',
    Behavior = 'Verhalten',
    Like = 'Vorlieben',
    Important = 'Wichtig',
    Random = 'Sonstiges',

}

export enum AttachmentType {

    Identification = 'Ausweis',
    Passport = 'Reisepass',
    License = 'Führerschein',
    Contract = 'Mietvertrag',
    Random = 'Sonstiges',

}

export enum MailType {

    Private = 'Email, Privat',
    Work = 'Email, Arbeit',

}

export enum Salutation {

    Herr = 0,
    Frau = 1,
    Familie = 2,
    Firma = 3,

}

export enum SalutationWoFam{

    Herr = 0,
    Frau = 1,
    Firma = 2,

}

export enum ViewComponent {

    Stay = 'Aufenthalt',
    Guest = 'Gast',
    Property = 'Immobilie',
    Landlord = 'Vermieter',

}

export enum PriceListType {
    DefaultPrice = 0,
    AdditionalService = 1,
    CityTax = 2,
}

export enum TaxType {
    Tax = 0,
    NoTax = 1,
}

export type PriceListObj = {
    
    date?: Date;
    price: number;
    originalPrice?: number;
    taxVal: number;
    originalTaxVal?: number;
    taxPercent: number;
    additional?: boolean;
    persons?: number;
    art: number;
    zlID?: string;
    discount?: DiscountObj;
    text?: string;
    amount?: number;
    ktID?: number;
    kt_art?: number;
    active?: boolean;
    uid?: number;

}

export type DiscountObj = {

    abz_id: number;
    abz_name: string;
    abz_json?: string;

}

export type DiscountJsonObj = {

    val: number;
    percentage: number;

}

export type PriceObj = {

    price_id: number;
    price_value: number;
    price_additional: number;
    price_additional_value: number;
    price_start?: string;
    price_end?: string;
    price_to: number;
    price_from: number;

}

export type PropertyPaymentObj = {
    
    property_tax: boolean;
    property_storno: boolean;
    property_deposit: boolean;
    property_kaution: boolean;
    property_storno1: boolean;
    property_storno2: boolean;
    property_storno3: boolean;
    property_currency: string;
    property_deposit_min: number;
    property_tax_percent: number;
    property_deposit_days: number;
    property_invoice_days: number;
    property_mahnung_days: number;
    property_storno1_days: number;
    property_storno2_days: number;
    property_storno3_days: number;
    property_currency_long: string;
    property_kaution_value: number;
    property_reminder_days: number;
    property_deposit_percent: number;
    property_storno1_percent: number;
    property_storno2_percent: number;
    property_storno3_percent: number;
    property_deposit_pauschal: boolean;
    property_deposit_pauschal_value: number;

}

export type PaymentObj = {
    
    date: string;
    price: number;
    paymentMethod: string;
    art: string;
    currency: string;

}

export type CancellationObj = {

    property_storno: boolean;
    property_storno_days: number;
    property_storno_percent: number;

}

export enum BlockType {
    Pre,
    Post,
}

export type KtObj = {

    kt_id: number;
    kt_name: string;
    kt_price: number;
    kt_start?: string;
    kt_end?: string;
    kt_art?: number;
    kt_tax: number;
    kt_tax_percent: number;

}

export type ZlObj = {

    zl_id: number;
    zl_name: string;
    zl_price: number;
    zl_art: number;
    zl_tax: number;
    zl_tax_percent: number;

}

export type ZkObj = {

    zk_id: number;
    zk_name: string;
    zk_iban: string;
    zk_bic: string;
    zk_paypal: string;

}

export type AbzObj = {

    abz_id: number;
    abz_name: string;
    abz_json: string;

}

export type BenefitsObj = {
    
    abz_id: number;
    abz_name: string;

}

export type InvoiceObj = {

    nights: number;
    price: number;
    fDate?: string;
    lDate?: string;
    taxPercent: number;
    sum: number;
    art: number;
    discount?: DiscountObj;

}

export enum PaymentSelectionType {
    AmountTypeView,
    PaymentTypeView,
    PartTypeView,
}

export enum PaymentAmountType {
    OnlyNights, //Betrag der Übernachtungen ohne Kosten für zusätzliche Personen
    OnlyAdditionalPeople, //Betrag der zusätzlichen Personen ohne Übernachtungskosten
    Full, //Gesamtpreis des Aufenthaltes mit Steuer, Kurtaxe, Zusatzleistunen etc.
    Part, //Teilzahlung
    Net, //Nettobetrag des Aufenthaltes ohne Steuer, Kurtaxe, Zusatzleistungen
    AdditionalService, //Betrag Zusatzleistungen
    CityTax, //Betrag Kurtaxe
    Deposit, //Betrag Anzahlung
    Cancellation //Betrag Stornierung
}

export enum PaymentMethod {

    Cash = 'Barzahlung',
    Transfer = 'Überweisung',
    Paypal = 'Paypal',
    CreditCard = 'Kreditkarte',
    Check = 'Scheck',

}

export type GuestGroupObj = {
    
    title: string;
    firstname: string;
    lastname: string;
    birthday: string;
    nationality: string;
    identification: string;

}

export enum ConversationViewType {
    ConversationView,
    PdfView,
    MailView,
}

export type ConversationMailObj = {
    text: string;
    art: number;
}

export type ConversationObj = {

    korres_id: number;
    korres_stay: number;
    korres_art: number;
    korres_to: string;
    korres_cc: string;
    korres_from: string;
    korres_invoicenr: string;
    korres_subject?: string;
    korres_body: string;
    korres_seen: number;
    korres_guest_data: string;
    guest_id: number;
    guest_title: string;
    guest_firstname: string;
    guest_lastname: string;
    guest_company: string;
    guest_street?: string;
    guest_zip?: string;
    guest_city?: string;
    guest_country?: string;
    guest_contact?: string;
    guest_note?: string;
    creation_timestamp: string;
    files_count: number;

}

export type PdfContentObj = {
    
    number: string;
    date: string;
    date_target: string;
    zahlungsform: string;
    absender: string;
    empfaenger: string;
    message: string;

}

export enum PdfType {
    Expose,
    Transferprotocol
}

export type TemplatesObj = {

    template_id: number;
    template_title: string;
    template_subject: string; 
    template_message: string;
    template_language: string; 
    template_language_code: string;
    template_mailArt: number;
    template_isStandardFor: number;
    template_type: number;

}

export type PdfDesignMainObj = {

    pdf_id: number;
    pdf_art: number;
    pdf_image: string | null;
    pdf_image_visible: number;
    pdf_design: string;

}

export type PdfDesignObj = {

    header_bg: Array<number>;
    header_fc: Array<number>;
    mheader_bg: Array<number>;
    mheader_fc: Array<number>;
    sheader_bg?: Array<number>;
    sheader_fc?: Array<number>;
    pdf_fc: Array<number>;
    header_lbls: Array<PdfDesignLblObj>;

}

export type PdfDesignLblObj = {

    art: string;
    fontsize: number;
    x: number;
    y: number;
    width?: number;
    height?: number;
    visible: boolean;
}

export enum MessageListType {
    Unread,
    All,
}

export enum PropertyListType {
    Activated,
    Deactivated,
}

export enum MailCreationType {
    Reply,
    ReplyAll,
    Forward,
}

export enum SettingsViewType {
    MainMenu,
    ConversationTemplates,
    UserData,
    Subscriptions,
    Support,
    CalendarShare,
    CalendarExport,
    CalendarImport,
}

export enum PaymentsViewType {
    MainMenu,
    General,
    Prices,
    Discounts,
    AdditionalServices,
    CityTaxes,
    Accounts,
    Expose,
    Handoverprotocol,
}

export type HandoverProtocolObj = {

    text1: string;
    text2: string;
    amount: number;
    img: number;

}

export type UserData = {

    user_id: number;
    user_title: number;
    user_firstname: string;
    user_lastname: string;
    user_company: string;
    user_birthday: string;
    user_email: string;
    user_mobile: string;
    user_address: string;
    user_password: string;
    registration_timestamp: string;

}

export type UserAddress = {

    street: string;
    zip: string;
    city: string;
    country: string;

}

export type PropertyAddress = {

    address_id: number;
    address_street: string;
    address_zip: string;
    address_city: string;
    address_country: string;

}

export type StoreObj = {

    store_id: number;
    store_json_description: string;
    store_json_price_items: string;
    store_title: string;

}

export type StoreDescriptionObj = {

    text: string;
    text_size: string;
    icon: string;
    icon_size: string;

}

export type StoreItemObj = {

    id: string;
    plan_id: string;
    title: string;
    description: string;
    price: number;
    currency: string;
    button_title: string;

}

export type StorePaymentObj = {

    body: string;
    creation_timestamp: string | null;
    creation_timestamp_frmd: string | null;
    expiry_date: string | null;
    expiry_date_frmd: string | null;
    item_title: string;
    payment_status: string;
    store: string;
    transaction_id: string | null;

}

export type CurrencyObj = {

    currencyShort: string;
    currencyLong: string;

}

export type ExposeObj = {

    ex_id: number;
    ex_art: string;
    ex_img: string | null;
    ex_json: string;

}

export type ExposeMainImgObj = {

    main_title: string;
    additional_text: string;
    toggle: string;
    position: string;

}

export type ExposeMainDataObj = {

    vermieter: string;
    address: string;
    property: string;
    service: string;
    price: string;
    zl: string;
    kt: string;
    storno: string;

}

export type ExposeDescriptionObj = {

    text: string;
    toggle: string;

}

export type ExposeImgCellObj = {

    img_title: string;

}

export type OfferObject = {

    property_art: string;
    property_name: string;
    property_img_uploaded: number;
    property_pm: string;
    stay_checkIn: string;
    stay_checkOut: string;
    stay_checkIn_time: string;
    stay_checkOut_time: string;
    stay_adults: number;
    stay_children: number;
    stay_pets: number;
    stay_guest: number;
    stay_property: number;
    stay_priceList?: string;
    stay_paymentList?: string;
    guest_title: string;
    guest_firstname: string;
    guest_lastname: string;
    guest_company: string;
    guest_street: string;
    guest_zip: string;
    guest_city: string;
    guest_country: string;
    guest_contact: string;
    guest_note: string;
    guest_website: string;
    korres_id: number;
    korres_art: number;
    korres_expired: number;
    korres_invoicenr: string;
    korres_stay: number;
    pdf_url: string;
    user_folder: string;
    user_id: number;
    creation_timestamp: string;

}

export type OfferSendObj = {

    korres_id: number;
    korres_art: number;
    korres_guest_data: string;
    korres_stay: number;
    korres_subject: string;
    korres_body: string;
    korres_from: string;
    user_id: number;

}

export type ImportUrlObj = {

    name: string;
    link: string;
    toggle: string;
    toggleBool: boolean;

}

export type CalendarShareObj = {

    share_id: number;
    share_property: number;
    share_from: number;
    share_to: number;
    share_year: number;
    share_pwd: number;
    share_pwd_value: string;
    share_link: string;
    share_toggle: number;
    share_duration: number;
    share_expiry_date: string;

}