import { truncateString } from "../methods/standard.methods";

type Props = {
    iconName: string;
    titleLabel: string;
    subLabel: string;
    index: number;
    onClick: (index: number) => void;
}

const ContactCard = ( { iconName, titleLabel, subLabel, index, onClick, }:Props ) => {
    return ( 
        <div title={subLabel} onClick={ () => onClick(index) } className="cn-data-view">
            <div className="flex center w100prc">
                <div className="titleLabel flex center-y w100prc">{titleLabel}</div>
                <div className="default-icon" style={{width: '40px',}}>{iconName}</div>
            </div>
            <div className="subLabel">{truncateString(subLabel, 30)}</div>
        </div>
     );
}
 
export default ContactCard;