import { Stay, ViewState } from "../methods/types";
import { getDateObj, daysBetweenDates, getFullname } from "../methods/standard.methods";
import RateBar from "../generalComps/RateBar";
import { ReactElement, useState } from "react";

type Props = {
    stay: Stay;
    handleOnClick: (state: ViewState) => void;
}

const StayCard = ( { stay, handleOnClick }: Props ) => {

    //const imageUrl = `https://eazyac-dev.de/user_data/${stay.user_folder!}/prop_images/${stay.stay_property!}.png`;
    const {fullname, company} = getFullname(stay.guest_title!, stay.guest_firstname!, stay.guest_lastname!, stay.guest_company!);

    const dateCi = getDateObj(stay.stay_checkIn!, 0);

    const dateCo = getDateObj(stay.stay_checkOut!, 0);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Cancellation) } title="Stornieren" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>event_busy</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(
            <>
            <div onClick={() => handleOnClick(ViewState.Details)} style={{opacity: stay.stay_storno_val! > 0 ? '0.2' : '1.0',}}>
                <div className="flex center-y w100prc">
                    <div className="title truncate-text">{ `${stay.property_name} | ${stay.property_art}` }</div>
                </div>
                <div className="flex center-y w100prc" style={{padding: '10px 0 10px 0'}}>
                    {/*<div className="flex center h100prc" style={{width: '50px'}}><ImageView src={imageUrl} width={50} height={50} alt={stay.property_name!} /></div>*/}
                    <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 0px', width: '40%'}}>
                        <div className="flex center-y color-lightblack">{ stay.guest_title }</div>
                        <div className="flex center-y truncate-text">{ fullname }</div>
                        <div className="flex center-y color-lightblack">{ company }</div>
                    </div>
                    <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 10px', width: '50%'}}>
                        <div className="flex center-y" style={{padding: '5px 0'}}>
                            <div className="flex center" style={{justifyContent: 'flex-end', width: '50%', paddingRight: '10px',}}>
                                <div className="default-icon">people</div>
                                <div className="flex center">{stay.stay_adults! + stay.stay_children!}</div>
                            </div>
                            <div className="flex center" style={{justifyContent: 'flex-start', width: '50%', paddingLeft: '10px', }}>
                                <div className="default-icon">brightness_2</div>
                                <div className="flex center">{ daysBetweenDates(dateCi.date, dateCo.date) }</div>
                            </div>
                        </div>
                        <RateBar ratevalue={stay.guest_rate!} style={{}} isClickable={false} handleRate={()=>{}}/>
                    </div>
                </div>
                <div className="flex center color-lightblack" style={{ position: 'absolute', bottom: '5px', }}>{ `${dateCi.dateStringDE} - ${dateCo.dateStringDE}` }</div>
            </div>
            { stay.stay_storno_val! > 0 && <div onClick={() => handleOnClick(ViewState.Details)} className="flex center h100prc" style={{position: 'absolute', top: '0', left: '0', width: 'calc(100% - 40px)', color: 'red', fontSize: '2.0em' }}>Storniert</div> }
            </>
            
        );
    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 
        <div data-checkin = { stay.stay_checkIn } className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: isVisibleMenu ? '20px' : '10px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>
     );
}
 
export default StayCard;