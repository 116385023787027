import { CSSProperties, useMemo } from "react";
import { Property } from "../../../methods/types";
import ImageView from "../../../generalComps/ImageView";

type Props = {
    property: Property;
    style?: CSSProperties | undefined;
}

const MultiCalendarPropertysCard = ( { property, style }:Props ) => {

    const imageUrl = useMemo( () => `https://eazyac-dev.de/user_data/${property.user_folder!}/prop_images/${property.property_id}.png`, [property.property_id]);

    const imageSize = 40;
    const fontSize = '1.0em';

    return ( 

        <div title={ property.property_name } className="propertycard border-bottom-strong" style={style}>
            <ImageView src={imageUrl} width={ imageSize } height={ imageSize } alt={property.property_name} />
            <div id="propertyName" className="flex column" style={{ marginLeft: '10px', width: '70%', }}>
                <div className="color-lightblack" style={{ fontSize: fontSize }}>{property.property_art}</div>
                <div style={{ fontSize: fontSize }}>{property.property_name}</div>
            </div>
        </div>

     );
}
 
export default MultiCalendarPropertysCard;