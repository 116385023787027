import { useMutation } from "@tanstack/react-query";
import { useContext, useId, useRef } from "react";
import { MessageDetailViewContext } from "../../contexts/MessageDetailViewContext";
import { StayConversationContext } from "../../contexts/StayConversationContext";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import ActionAlert from "../../generalComps/ActionAlert";
import { conversationMailTypeArr } from "../../methods/constants";
import { fetchInvoiceNumber } from "../../methods/http.invoice.methods";
import { deleteAllFiles, fetchPdfExTpUrl, fetchPdfUrl } from "../../methods/http.korres.methods";
import { fetchPrefilledTemplateByType } from "../../methods/http.templates.methods";
import { getDateObj } from "../../methods/standard.methods";
import { Contact, ConversationObj, ConversationViewType, PdfType } from "../../methods/types";

type Props = {
    conversationArr: Array<ConversationObj>;
    handleClose: () => void;
}

const ConversationActionAlertView = ( { conversationArr, handleClose }:Props ) => {

    const { stay, pmSettingsObj } = useContext(StayDetailViewContext);
    const { setConversationViewType, invoiceNumberRef, conversationTypeRef, conversationTypeStringRef, conversationTypeStringForTitleRef, setPdfUrl, pdfContentRef } = useContext(StayConversationContext);
    const messageContext = useContext(MessageDetailViewContext);

    const actionAlertId = useId();

    const alertButtonsRef = useRef<Array<{title: string, handler: () => void}>>([]);

    const { mutate: postDeleteFolderFilesMutation } = useMutation({
        mutationFn: () => deleteAllFiles(),
        onSuccess: () => {

            fetchPrefilledTemplateByTypeMutation();

        },
    })

    alertButtonsRef.current = [];

    conversationMailTypeArr.forEach( obj => {

        if (obj.art === 4 || obj.art === 5) {

            alertButtonsRef.current.push( {title: obj.text, handler: () => {

                conversationTypeRef.current = 3;
                conversationTypeStringRef.current = conversationMailTypeArr[3].text;
                conversationTypeStringForTitleRef.current = conversationMailTypeArr[obj.art].text;

                invoiceNumberRef.current = conversationArr[0].korres_invoicenr;
                pdfContentRef.current.number = conversationArr[0].korres_invoicenr;
                pdfContentRef.current.date = getDateObj( conversationArr[0].creation_timestamp, 0 ).dateStringDE;
                pdfContentRef.current.date_target = getDateObj( conversationArr[0].creation_timestamp, pmSettingsObj!.property_invoice_days ).dateStringDE;

                deleteAllFilesMutation();

            } } );

        } else if (obj.art === 8) {

            alertButtonsRef.current.push( {title: obj.text, handler: postDeleteFolderFilesMutation } );

        } else {

            alertButtonsRef.current.push( {title: obj.text, handler: () => {

                pdfContentRef.current = {number: '', date: '', date_target: '', zahlungsform: '', absender: '', empfaenger: '', message: ''};

                conversationTypeRef.current = obj.art;
                conversationTypeStringRef.current = obj.text;
                conversationTypeStringForTitleRef.current = obj.text;

                deleteAllFilesMutation();

            } } );

        }

    })

    if ( stay.stay_storno_val > 0 ) {

        alertButtonsRef.current.splice(0, 2);

    }

    if ( conversationArr.length === 0 ) {
        
        alertButtonsRef.current.splice(4, 2);

    }

    const { mutate: deleteAllFilesMutation } = useMutation({
        mutationFn: () => deleteAllFiles(),
        onSuccess: () => {
            
            fetchInvoiceNumberMutation();

        },
    })

    const { mutate: fetchInvoiceNumberMutation } = useMutation({
        mutationFn: () => fetchInvoiceNumber(conversationTypeRef.current),
        onSuccess: (data) => {

            invoiceNumberRef.current = data.obj;
            
            if (conversationTypeRef.current <= 5) {

                fetchPdfUrlMutation();

            } else if ( conversationTypeRef.current === 6) {

                fetchPdfExUrlMutation();

            } else if ( conversationTypeRef.current === 7) {
                
                fetchPdfTpUrlMutation();

            }

        },
    })

    const { mutate: fetchPdfUrlMutation } = useMutation({
        mutationFn: () => fetchPdfUrl(stay.stay_id, pdfContentRef.current, conversationTypeRef.current, conversationTypeStringRef.current ),
        onSuccess: (data) => {
            
            setPdfUrl( {url: data.pdf_name!} );

            setConversationViewType(ConversationViewType.PdfView);

            handleClose();

        },
    })

    const { mutate: fetchPdfExUrlMutation } = useMutation({
        mutationFn: () => fetchPdfExTpUrl( stay.stay_property, stay.stay_guest!, PdfType.Expose ),
        onSuccess: (data) => {
            
            setPdfUrl( {url: data.pdf_name!} );

            setConversationViewType(ConversationViewType.PdfView);

            handleClose();

        },
    })

    const { mutate: fetchPdfTpUrlMutation } = useMutation({
        mutationFn: () => fetchPdfExTpUrl( stay.stay_property, stay.stay_guest!, PdfType.Transferprotocol ),
        onSuccess: (data) => {
            
            setPdfUrl( {url: data.pdf_name!} );

            setConversationViewType(ConversationViewType.PdfView);

            handleClose();

        },
    })

    const { mutate: fetchPrefilledTemplateByTypeMutation } = useMutation({
        mutationFn: () => fetchPrefilledTemplateByType(stay.stay_id, 8),
        onSuccess: (data) => {

            messageContext.templatesArrRef.current = data.obj ? data.obj : [];
            messageContext.conversationTypeRef.current = 8;
            messageContext.stayIdRef.current = stay.stay_id;

            const contactArr: Array<Contact> = JSON.parse( stay.guest_contact ? stay.guest_contact : '[]' );

            const mails = contactArr.filter( contact => contact.contact_key === 'mail' ).map( contact => {

                return contact.contact_value;

            } )

            messageContext.mailContentObjRef.current.to = mails;

            setConversationViewType(ConversationViewType.MailView);

        },
    })

    return ( 
        <ActionAlert actionAlertId={actionAlertId} title='Korrespondenz' message='Welche E-Mail möchtest du schreiben?' buttons={alertButtonsRef.current} handleClose={handleClose} />
     );
}
 
export default ConversationActionAlertView;