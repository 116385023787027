import { CalendarShareObj, DefaultFetchResult } from "./types";

export const fetchExportUrlByProperty = async (propertyId: number) => {

    const data = new URLSearchParams();
  
    data.append('property_id', propertyId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=ical&do=get_export_url`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const fetchIcalStays = async (startDate: string, endDate: string) => {

    const data = new URLSearchParams();
  
    data.append('start_date', startDate);
    data.append('end_date', endDate);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=ical&do=select`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const fetchCalendarShareData = async () => {

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=share&do=select`,
        {
        method: "GET",
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postInsertShare = async (shareObj: CalendarShareObj) => {

    const data = new URLSearchParams();
  
    data.append('share_property', shareObj.share_property.toString());
    data.append('share_from', shareObj.share_from.toString());
    data.append('share_to', shareObj.share_to.toString());
    data.append('share_year', shareObj.share_year.toString());
    data.append('share_pwd', shareObj.share_pwd.toString());
    data.append('share_pwd_value', shareObj.share_pwd_value);
    data.append('share_link', shareObj.share_link);
    data.append('share_toggle', shareObj.share_toggle.toString());
    data.append('share_duration', shareObj.share_duration.toString());
    data.append('share_expiry_date', shareObj.share_expiry_date);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=share&do=insert`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postUpdateShare = async (shareObj: CalendarShareObj) => {

    const data = new URLSearchParams();
  
    data.append('share_id', shareObj.share_id.toString());
    data.append('share_property', shareObj.share_property.toString());
    data.append('share_from', shareObj.share_from.toString());
    data.append('share_to', shareObj.share_to.toString());
    data.append('share_year', shareObj.share_year.toString());
    data.append('share_pwd', shareObj.share_pwd.toString());
    data.append('share_pwd_value', shareObj.share_pwd_value);
    data.append('share_link', shareObj.share_link);
    data.append('share_toggle', shareObj.share_toggle.toString());
    data.append('share_duration', shareObj.share_duration.toString());
    data.append('share_expiry_date', shareObj.share_expiry_date);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=share&do=update`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postShareDelete = async (shareId: number) => {

    const data = new URLSearchParams();
  
    data.append('share_id', shareId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=share&do=delete`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}