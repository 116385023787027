import { ReactElement, useContext, useEffect, useState } from "react";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import ModalView from "../../generalComps/ModalView";
import { PaymentAmountType, PaymentSelectionType } from "../../methods/types";
import AmountTypeSelectionView from "./AmountTypeSelectionView";
import PartAmountView from "./PartAmountView";
import PaymentMethodSelectionView from "./PaymentMethodSelectionView";

type Props = {
    
    handleClose: () => void;

}

const StayPaymentSelectWrapperView = ( { handleClose }:Props ) => {

    //const [selectionViewType, setSelectionViewType] = useState(viewType);

    const { paymentViewType, setPaymentViewType, paymentDataObj, setPaymentDataObj, invoicePriceObj } = useContext(StayDetailViewContext);
    
    const handlePaymentViewType = (viewType: PaymentSelectionType) => {

        //setSelectionViewType(viewType);
        setPaymentViewType(viewType);

    }

    const ContentView = (): ReactElement => {

        switch (paymentViewType) {
            case PaymentSelectionType.AmountTypeView:
                return <AmountTypeSelectionView handleClose={handleClose} handlePaymentViewType={ handlePaymentViewType } />

            case PaymentSelectionType.PaymentTypeView:
                return <PaymentMethodSelectionView handleClose={handleClose} />

            case PaymentSelectionType.PartTypeView:
                return <PartAmountView handleClose={handleClose} handlePaymentViewType={ handlePaymentViewType } />    
        
            default:
                return <PaymentMethodSelectionView handleClose={handleClose} />
        }

    }

    useEffect(() => {

        let amount = 0;

        switch (paymentDataObj.amountType) {
            case PaymentAmountType.OnlyNights:
                amount = invoicePriceObj.nightsOnlyAmount;
                break;
            case PaymentAmountType.OnlyAdditionalPeople:
                amount = invoicePriceObj.addNightsOnlyAmount;
                break;
            case PaymentAmountType.AdditionalService:
                amount = invoicePriceObj.additionalServiceAmount;
                break;
            case PaymentAmountType.CityTax:
                amount = invoicePriceObj.cityTaxAmount;
                break;
            case PaymentAmountType.Full:
                amount = invoicePriceObj.fullAmount;
                break;
            case PaymentAmountType.Deposit:
                amount = invoicePriceObj.depositAmount;
                break;
            case PaymentAmountType.Cancellation:
                amount = invoicePriceObj.cancellationFeeAmount;
                break;
        }

        setPaymentDataObj( prevObj => ({...prevObj, amount: amount}));

    }, [paymentDataObj.amountType])

    return ( 

        <ModalView modalViewId="stayPaymentSelectWrapper" handleClose={handleClose} style={{width: '600px'}}>
            <ContentView />
        </ModalView>

     );
}
 
export default StayPaymentSelectWrapperView;