const Footer = () => {

    return (

        <div className="footer">
            <div className="footerInfoSector">
                <br />Mit der Nutzung von Eazyac erklären Sie sich mit unseren Nutzungsbedingungen einverstanden.
                <br />© Copyright 2024 Andreas Rolgaiser. Alle Rechte vorbehalten.
                <div style={{padding: "10px", cursor: "pointer"}}>
                    <a href="/impressum">Impressum</a> | <a href="/agb">AGB</a> | <a href="/datenschutz">Datenschutz</a>
                </div>
            </div>
        </div>

    );

}

export default Footer;