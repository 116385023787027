import { useContext, useId, useState } from "react";
import DateSelect, { DateSelectFormat, DateSelectType } from "../generalComps/dateSelectComps/DateSelect";
import { OccupancyContext } from "../contexts/OccupancyContext";
import { getDateObj } from "../methods/standard.methods";
import { AlertObj, DateObj } from "../methods/types";
import { createPortal } from "react-dom";
import BlockAddView from "./BlockAddView";
import ActionAlert from "../generalComps/ActionAlert";

type Props = {
    handleAddStayClick: () => void;
}

const OccupancyDateSelectionBar = ( { handleAddStayClick }:Props ) => {

    const { selectedStartDate, selectedEndDate, setSelectedStartDate, setSelectedEndDate, } = useContext(OccupancyContext);

    const [isVisibleBlockAddView, setIsVisibleBlockAddView] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const handleSelectedDateObj = (e: React.SyntheticEvent, dateSelectType: DateSelectType, dateObj: DateObj) => {

        if (dateSelectType === DateSelectType.CheckIn) {

            setSelectedStartDate( dateObj );

        } else {

            setSelectedEndDate( dateObj );

        }

        e.stopPropagation();

    }

    const handleClose = () => {

        setSelectedStartDate( null );
        setSelectedEndDate( null );

    }

    const checkDates = (type: string) => {

        if ( selectedStartDate!.date.getTime() > selectedEndDate!.date.getTime() ) {

            setAlertObj( new AlertObj( true, 'Achtung', 'CheckIn-Datum darf nicht größer als CheckOut-Datum sein', standardAlertButton ) );

        } else {

            if ( type === 'stay' ) {
                
                handleAddStayClick();

            } else {

                setIsVisibleBlockAddView(true);

            }

        }

    }

    return ( 

        <>
        <div className="shadowBox center-y w100prc" style={{ gap: '10px' }}>
            <div onClick={ handleClose } className="flex center clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>close</div>
            <div className="flex center" style={{ gap: '10px', width: '50%', overflow: 'visible' }}>
                <DateSelect dateSelectType={DateSelectType.CheckIn} dateSelectFormat={DateSelectFormat.Calendar} startDateObj={ selectedStartDate ? selectedStartDate : getDateObj( new Date(), 0)} endDateObj={ selectedEndDate ? selectedEndDate : getDateObj( new Date(), 0)} handleSelectedDateObj={handleSelectedDateObj}/>
                <DateSelect dateSelectType={DateSelectType.CheckOut} dateSelectFormat={DateSelectFormat.Calendar} startDateObj={ selectedStartDate ? selectedStartDate : getDateObj( new Date(), 0)} endDateObj={ selectedEndDate ? selectedEndDate : getDateObj( new Date(), 0)} handleSelectedDateObj={handleSelectedDateObj}/>
            </div>
            {
                ( selectedStartDate && selectedEndDate ) &&
                <div className="shadowBox" style={{ gap: '10px',     marginLeft: '40px' }}>
                    <div onClick={ () => checkDates('stay') } className="flex center-y cursor-pointer" style={{ border: '1px solid var(--lbl-color-cyan)', borderRadius: '5px', padding: '5px 10px 5px 5px' }}>
                        <div className="default-icon" style={{ width: '40px' }}>nights_stay</div>
                        <div className="flex center">Aufenthalt anlegen</div>
                    </div>
                    <div onClick={ () => checkDates('block') } className="flex center-y cursor-pointer" style={{ border: '1px solid red', borderRadius: '5px', padding: '5px 10px 5px 5px' }}>
                        <div className="default-icon" style={{ width: '40px', color: 'red' }}>block</div>
                        <div className="flex center">Zeitraum blockieren</div>
                    </div>
                </div>
            }
        </div>
        { isVisibleBlockAddView && createPortal( <BlockAddView handleClose={ () => setIsVisibleBlockAddView(false) } />, document.body ) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default OccupancyDateSelectionBar;