import { ReactElement, useEffect, useId, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { AlertObj, OfferObject } from "../../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import { useMutation } from "@tanstack/react-query";
import { fetchOffer } from "../../methods/http.offer.methods";
import { daysBetweenDates, getDateObj, getFullname } from "../../methods/standard.methods";
import ImageView from "../../generalComps/ImageView";
import DateSelect, { DateSelectFormat, DateSelectType } from "../../generalComps/dateSelectComps/DateSelect";
import OfferInvoiceView from "./OfferInvoiceView";
import OfferCancelView from "./OfferCancelView";
import OfferAcceptanceView from "./OfferAcceptanceView";

const OfferView = () => {

    const params = useParams();

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [offerData, setOfferData] = useState<OfferObject | null>(null);
    const [isVisibleOfferCancelView, setIsVisibleOfferCancelView] = useState(false);
    const [isVisibleOfferAcceptanceView, setIsVisibleOfferAcceptanceView] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);

    const creationDate = getDateObj( offerData ? offerData.creation_timestamp : new Date(), 1 );
    const creationTime = offerData ? new Date(offerData.creation_timestamp) : new Date();

    const imageUrl = offerData === null ? '' : `https://eazyac-dev.de/user_data/${offerData.user_folder}/prop_images/${offerData.stay_property}.png`;
    const {fullname, company} = offerData === null ? {fullname: '', company: ''} : getFullname( offerData.guest_title , offerData.guest_firstname, offerData.guest_lastname, offerData.guest_company);

    const dateCi = getDateObj( offerData === null ? new Date() : offerData.stay_checkIn, 0);
    const dateCo = getDateObj( offerData === null ? new Date() : offerData.stay_checkOut, 0);

    const nights = daysBetweenDates( dateCi.date, dateCo.date );

    const { mutate: fetchOfferMutation } = useMutation({
        mutationFn: () => fetchOffer(params.token ? params.token : ''),
        onSuccess: (data) => {

            if (data.success !== false && data.obj !== false) {
                setOfferData( data.obj );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => { 
        
        fetchOfferMutation(); 

        window.document.title = 'Eazyac - Angebot';
    
    }, []);

    const ErrorView = ():ReactElement => {

        return(

            <div className="shadowBox">
                <div className="default-icon">notification_important</div>
                Dieser Vorgang ist entweder abgeschlossen oder ungültig. Wenden Sie sich im Zweifel an den Versender.
            </div>

        );

    }

    const CompletedView = ():ReactElement => {

        return(

            <div className="shadowBox">
                <div className="default-icon">check</div>
                Vielen Dank für Ihre Rückmeldung! Antwort wurde erfolgreich versendet.
            </div>

        );

    }

    const handleNewTimeValue = (newTimeValue: string, selectType: DateSelectType) => {

        if (selectType === DateSelectType.CheckIn) {

            setOfferData( prev => ( { ...prev!, stay_checkIn_time: newTimeValue } ) );

        } else {

            setOfferData( prev => ( { ...prev!, stay_checkOut_time: newTimeValue } ) );

        }

    }

    return ( 
        <>
        {
            isCompleted ? 

            <CompletedView />

            :

            offerData === null ?

            <ErrorView />

            :

            <>
            <div className="shadowBox column" style={{ width: '700px' }}>
                <div className="column shadowBox w100prc">
                    <div className="flex center-y w100prc" style={{fontSize: '1.2em'}}>
                        <div className="title truncate-text">{ `${offerData.property_name} | ${offerData.property_art}` }</div>
                    </div>
                    <div className="flex center-y w100prc" style={{padding: '10px 0 10px 0'}}>
                        <div className="flex center h100prc" style={{width: '50px', marginRight: '10px'}}><ImageView src={imageUrl} width={50} height={50} alt={offerData.property_name!} /></div>
                        <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 0px', width: '40%'}}>
                            <div className="flex center-y color-lightblack">{ offerData.guest_title }</div>
                            <div className="flex center-y truncate-text">{ fullname }</div>
                            <div className="flex center-y color-lightblack">{ company }</div>
                        </div>
                        <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 10px', width: '50%'}}>
                            <div className="flex center-y" style={{padding: '5px 0'}}>
                                <div title="Übernachtungen" className="flex center w100prc" >
                                    <div className="default-icon" style={{fontSize: '1.5em'}}>brightness_2</div>
                                    <div className="flex center" style={{padding: '0 5px'}}>{nights}</div>
                                </div>
                                <div title="Erwachsene" className="flex center w100prc" >
                                    <div className="default-icon" style={{fontSize: '1.5em'}}>person</div>
                                    <div className="flex center" style={{padding: '0 5px'}}>{offerData.stay_adults}</div>
                                </div>
                                <div title="Kinder" className="flex center w100prc" >
                                    <div className="default-icon" style={{fontSize: '1.5em'}}>child_care</div>
                                    <div className="flex center" style={{padding: '0 5px'}}>{ offerData.stay_children }</div>
                                </div>
                                { offerData.stay_pets > 0 &&
                                <div title="Hunde" className="flex center w100prc" >
                                    <div className="default-icon" style={{fontSize: '1.5em'}}>pets</div>
                                    <div className="flex center" style={{padding: '0 5px'}}>{ offerData.stay_pets }</div>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shadowBox w100prc" style={{ gap: '10px', marginTop: '10px' }}>
                    <DateSelect dateSelectType={DateSelectType.CheckIn} dateSelectFormat={DateSelectFormat.Time} startDateObj={getDateObj(offerData.stay_checkIn, 0)} endDateObj={getDateObj(offerData.stay_checkOut, 0)} propertyId={offerData.stay_property} handleSelectedDateObj={()=>{}} givenTime={offerData.stay_checkIn_time} handleNewTimeValue={(newTimeValue) => handleNewTimeValue(newTimeValue, DateSelectType.CheckIn) }/>
                    <DateSelect dateSelectType={DateSelectType.CheckOut} dateSelectFormat={DateSelectFormat.Time} startDateObj={getDateObj(offerData.stay_checkIn, 0)} endDateObj={getDateObj(offerData.stay_checkOut, 0)} propertyId={offerData.stay_property} handleSelectedDateObj={()=>{}} givenTime={offerData.stay_checkOut_time} handleNewTimeValue={(newTimeValue) => handleNewTimeValue(newTimeValue, DateSelectType.CheckOut) }/>
                </div>
                <div className="shadowBox column w100prc" style={{ gap: '10px', marginTop: '10px' }}>
                    <OfferInvoiceView offerData={offerData} nights={nights} />
                </div>
                <div className="shadowBox" style={{ marginTop: '10px' }}>
                    Prüfen Sie bitte das Angebot. Anschließend können Sie es annehmen oder ablehnen.
                    <br/><br/>Mit einem Klick auf das Anreise- bzw. Abreise-Datum können Sie bei Bedarf eine gewünschte Zeit für den CheckIn bzw. CheckOut vergeben. Sofern diese ausserhalb der Standard-Zeiten liegen, erhalten Sie separat eine Benachrichtigung über die Realisierbarkeit. 
                </div>
                <div className="shadowBox w100prc" style={{ gap: '10px', marginTop: '10px' }}>
                    <div className="flex center w100prc" style={{ color: 'red' }}>
                        <div className="default-icon" style={{ width: '40px', color: 'red' }}>cancel</div>
                        <div onClick={ () => setIsVisibleOfferCancelView(true) } className="flex center cursor-pointer">Ablehnen</div>
                    </div>
                    <div className="flex center color-cyan w100prc">
                        <div className="default-icon" style={{ width: '40px' }}>check_circle</div>
                        <div onClick={ () => setIsVisibleOfferAcceptanceView(true) } className="flex center cursor-pointer">Annehmen</div>
                    </div>
                </div>
            </div>
            <div className="flex center w100prc color-lightblack" style={{ paddingTop: '20px' }}>Dieser Aufruf ist gültig bis {creationDate.dayStringLong}, {creationDate.dateStringDE} {creationTime.getHours()}:{creationTime.getMinutes()} Uhr.</div>
            </>

        }
        { isVisibleOfferCancelView && createPortal( <OfferCancelView offerData={offerData!} style={{ width: '600px' }} handleCompletion={ () => setIsCompleted(true) } handleClose={ () => setIsVisibleOfferCancelView(false) }/>, document.body ) }
        { isVisibleOfferAcceptanceView && createPortal( <OfferAcceptanceView offerData={offerData!} style={{ width: '600px' }} handleCompletion={ () => setIsCompleted(true) } handleClose={ () => setIsVisibleOfferAcceptanceView(false) }/>, document.body ) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default OfferView;