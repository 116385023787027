import { ReactElement, useContext, useMemo, useState } from "react";
import { CalendarShareObj, InfoWidgetObj, ViewState } from "../../methods/types";
import { ImportUrlObj } from "../../methods/types";
import { PropertysContext } from "../../contexts/PropertysContext";
import { copyToClipboard, getDateObj } from "../../methods/standard.methods";
import { monthsLong } from "../../methods/constants";
import InfoWidget from "../../generalComps/InfoWidget";

type Props = {
    shareObj: CalendarShareObj;
    handleOnClick: (state: ViewState) => void;
}

const CalendarShareCard = ( { shareObj, handleOnClick }: Props ) => {

    const { propertys } = useContext(PropertysContext);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const sharePropertyName = useMemo( () => {

        const property = propertys.array.find( prop => prop.property_id === shareObj.share_property );

        if (property) {
            return property.property_name;
        } else {
            return 'Alle Immobilien';
        }

    }, [shareObj.share_property] );

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const durationString = useMemo( () => {

        if (shareObj.share_duration > 0) {

            return `${shareObj.share_duration} ${ shareObj.share_duration === 1 ? 'Stunde' : 'Stunden' }`;
    
        } else {
            return 'Unbegrenzt';
        } 

    }, [shareObj.share_duration]);

    const expiryDate = useMemo( () => {

        if (shareObj.share_toggle !== 0) {

            if (shareObj.share_duration === 0) {
    
                return 'unbegrenzt';
    
            } else {
    
                const date = new Date( shareObj.share_expiry_date );
                const dateObj = getDateObj( new Date( shareObj.share_expiry_date ), 0 );

                return `${dateObj.dateStringDE}, ${date.getHours()}:${date.getMinutes()} Uhr`;
    
            }
    
        } else {

            return 'deaktiviert';

        }

    }, [shareObj.share_toggle]);

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    const handleCopyToClipboard = (text: string) => {

        copyToClipboard(text);

        setInfoWidgetObj( new InfoWidgetObj( true, 'Link in Zwischenablage kopiert' ));

    }

    return ( 
        <>
        <div className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: '20px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? 
                <Menu />
                :
                <div className="flex center">
                <div className="flex center w100prc column" style={{ paddingRight: '10px' }}>
                    <div className="flex center-y w100prc" style={{ fontWeight: 'bold'}}>
                        { sharePropertyName }
                    </div>
                    <div className="flex center-y w100prc" style={{ padding: '2px 0' }}>Freigabedauer: {durationString}</div>
                    <div className="flex center-y w100prc" style={{ padding: '2px 0' }}>Zeitraum: {monthsLong[shareObj.share_from]} - {monthsLong[shareObj.share_to]} {shareObj.share_year}</div>
                    <div className="flex center-y w100prc" style={{ padding: '2px 0' }}>Passwortgeschützt: { shareObj.share_pwd === 0 ? 'Nein' : 'Ja'}</div>
                    <div title="Klicken zum Kopieren" onClick={ () => handleCopyToClipboard(shareObj.share_toggle === 0 ? 'Aktiviere die Freigabe, um einen Link zu generieren' : shareObj.share_link) } className="flex center w100prc cursor-pointer standard-fontsize" style={{ margin: '10px 0', padding: '5px 10px', border: '1px solid var(--line-color)', borderRadius: '5px', fontStyle: 'italic', }}>
                        { shareObj.share_toggle === 0 ? 'Aktiviere die Freigabe, um einen Link zu generieren' : shareObj.share_link }
                    </div>
                    <div className="flex center-y w100prc" style={{ padding: '2px 0' }}>URL gültig bis: {expiryDate
                    }</div>
                </div>
                <div onClick={ (e) => handleButtonClick(e, ViewState.Details) } className="clickable-icon flex center" style={{ fontSize: '2.0em', paddingTop: '5px' }}>{ shareObj.share_toggle === 0 ? 'toggle_off' : 'toggle_on' }</div>
            </div> }
        </div>
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        </>
     );
}
 
export default CalendarShareCard;