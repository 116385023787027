import { useId, useRef, useState } from "react";
import ModalView from "../../generalComps/ModalView";
import TextareaView from "../../generalComps/TextareaView";
import { AlertObj, CNViewType, Contact, Guest, Note, OfferObject, OfferSendObj, SalutationWoFam } from "../../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import { useMutation } from "@tanstack/react-query";
import { addOffer } from "../../methods/http.offer.methods";
import InputView from "../../generalComps/InputView";
import ContactNoteView from "../../generalComps/ContactNoteView";
import { emptyGuest } from "../../methods/constants";
import SalutationSelect from "../../generalComps/SalutationSelect";

type Props = {
    offerData: OfferObject;
    handleCompletion: () => void;
    handleClose: () => void;
    style?: React.CSSProperties | undefined;
}

const OfferAcceptanceView = ( { offerData, style, handleCompletion, handleClose }: Props ) => {

    const inputFirstnameRef = useRef<HTMLInputElement>(null);
    const inputLastnameRef = useRef<HTMLInputElement>(null);
    const inputBdayRef = useRef<HTMLInputElement>(null);
    const inputCompanyRef = useRef<HTMLInputElement>(null);

    const inputStreetRef = useRef<HTMLInputElement>(null);
    const inputZipRef = useRef<HTMLInputElement>(null);
    const inputCityRef = useRef<HTMLInputElement>(null);
    const inputCountryRef = useRef<HTMLInputElement>(null);

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [guest, setGuest] = useState<Guest>( { guest_id: -1,
                                                    guest_title: offerData.guest_title,
                                                    guest_firstname: offerData.guest_firstname,
                                                    guest_lastname: offerData.guest_lastname,
                                                    guest_company: offerData.guest_company,
                                                    guest_birthday: '0000-00-00',
                                                    guest_street: offerData.guest_street,
                                                    guest_zip: offerData.guest_zip,
                                                    guest_city: offerData.guest_city,
                                                    guest_country: offerData.guest_country,
                                                    guest_birthday_reminder: 0,
                                                    guest_rate: 2,
                                                    guest_blacklist: 0 } );

    const [contactArr, setContactArr] = useState<Array<Contact | Note>>( offerData.guest_contact ? JSON.parse( offerData.guest_contact ) : [] );

    const bdayValue = guest.guest_birthday! === '0000-00-00' || guest.guest_birthday! === '1500-01-01' ? new Date().toLocaleDateString('fr-ca') : new Date(guest.guest_birthday!).toLocaleDateString('fr-ca');
    const bdayToggle = () => {

        if (guest.guest_birthday! && guest.guest_birthday! !== '0000-00-00' && guest.guest_birthday! !== '1500-01-01') {
            return true;
        } else {
            return false;
        }

    }
    const handleBdayValue = () => {

        if (bdayToggle()) {
            setGuest( ( prevGuest => ({ ...prevGuest, guest_birthday: '0000-00-00' }) ) );
        } else {
            setGuest( ( prevGuest => ({ ...prevGuest, guest_birthday: new Date().toLocaleDateString('fr-ca') }) ) );
        }

    }

    const { mutate: fetchOfferMutation } = useMutation({
        mutationFn: (newOfferData: OfferSendObj) => addOffer(newOfferData),
        onSuccess: () => {

            handleCompletion();
            handleClose();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSave = () => {


        if ( SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] < 2 && (inputFirstnameRef.current!.value.length === 0 || inputLastnameRef.current!.value.length === 0) ) {

            setAlertObj( new AlertObj( true, 'Name unvollständig', 'Bitte gebe deinen vollständigen Namen an: Vorname, Name.', standardAlertButton) );

        } else if ( SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] === 2 && inputCompanyRef.current!.value.length === 0 ) {

            setAlertObj( new AlertObj( true, 'Firma', 'Bitte gebe den Firmennamen an.', standardAlertButton) );

        } else if ( inputStreetRef.current!.value.length === 0 || inputZipRef.current!.value.length === 0 || inputCityRef.current!.value.length === 0 || inputCountryRef.current!.value.length === 0 ) {

            setAlertObj( new AlertObj( true, 'Adresse unvollständig', 'Bitte gebe deine vollständige Adresse an: Straße und Hausnummer, Postleitzahl, Wohnort, Land.', standardAlertButton) );

        } else {

            let korres_from = 'Gast';

            if (offerData.guest_title === 'Herr' || offerData.guest_title === 'Frau') {

                korres_from = offerData.guest_firstname + ' ' + offerData.guest_lastname;

            } else if (offerData.guest_title === 'Familie') {

                korres_from = 'Familie ' + offerData.guest_lastname;

            } else if (offerData.guest_title === 'Firma') {

                korres_from = offerData.guest_company;

            }

            let msg = 'Dein Angebot wurde angenommen.';

            if (textareaRef.current!.value.length > 0) {

                msg += ' Folgende Mitteilung wurde hinterlassen: ';
                msg += textareaRef.current!.value;

            }

            const newGuest = { guest_title: guest.guest_title,
                                guest_firstname: inputFirstnameRef.current!.value,
                                guest_lastname: inputLastnameRef.current!.value,
                                guest_birthday: bdayToggle() ? inputBdayRef.current!.value : '0000-00-00',
                                guest_company: inputCompanyRef.current!.value,
                                guest_street: inputStreetRef.current!.value,
                                guest_zip: inputZipRef.current!.value,
                                guest_city: inputCityRef.current!.value,
                                guest_country: inputCityRef.current!.value,
                                guest_contact: JSON.stringify( contactArr ), }

            const newOfferData: OfferSendObj = { korres_id: offerData.korres_id,
                                                korres_art: 11,
                                                korres_guest_data: JSON.stringify( newGuest ),
                                                korres_stay: offerData.korres_stay,
                                                korres_subject: 'Angebot angenommen',
                                                korres_body: msg,
                                                korres_from: korres_from,
                                                user_id: offerData.user_id };

            fetchOfferMutation(newOfferData);

        }

    }

    const handleUpdateCNData = (updatedArr: Array<Contact | Note>, type: CNViewType) => {
        
        setContactArr( updatedArr );
        setGuest( ( prevGuest => ({ ...prevGuest, guest_contact: JSON.stringify( updatedArr ) }) ) );

    }

    return ( 

        <>
        <ModalView modalViewId="offerAcceptanceView"  style={style} handleClose={handleClose}>
            <div className="flex center-y" style={{ width: "100%" }}>
                <div onClick={handleClose} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Angebot annehmen</div>
                <div onClick={handleSave} className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>check_circle</div>
            </div>
            <div className="shadowBox" style={{ marginTop: '10px' }}>
                Schön, dass Ihnen das Angebot zusagt. Bitte übersenden Sie uns noch folgende Daten, damit wir Ihre Buchung abschließend anlegen können.
            </div>
            <SalutationSelect selection={["Herr", "Frau", "Firma"]} selectedItem={SalutationWoFam[guest.guest_title! as keyof typeof SalutationWoFam]} handleSelection={(index) => { setGuest( prevGuest => ({...prevGuest, guest_title: SalutationWoFam[index]}) ) } } />

            <div className="flex center-y w100prc" style={{ gap: '10px', padding: '3px 0' }}>
                <InputView title="Vorname" style={{ width: '37.5%', opacity: (SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] === 2 ? '0.5' : '1.0') }}><input ref={inputFirstnameRef} type="text" defaultValue={guest.guest_firstname} placeholder="z. B. Max" disabled={ (SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] >= 2 ? true : false) }/></InputView>
                <InputView title="Nachname" style={{ width: '37.5%', opacity: (SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] === 2 ? '0.5' : '1.0') }}><input ref={inputLastnameRef} type="text" defaultValue={guest.guest_lastname} placeholder="z. B. Mustermann" disabled={ (SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] > 2 ? true : false) }/></InputView>
                <InputView title="Geburtsdatum" style={{ width: '25%', opacity: (SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] === 2 ? '0.5' : '1.0') }}>
                    {
                        SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] !== 2  &&
                        <div onClick={ handleBdayValue } className="clickable-icon" style={{ position: 'absolute', right: '0', top: '0', padding: '5px'}}>{ bdayToggle() ? 'toggle_on' : 'toggle_off' }</div>
                    }
                    <input ref={inputBdayRef} type="date" defaultValue={ bdayValue } max={ new Date().toLocaleDateString('fr-ca') } placeholder="DD.MM.YYYY" disabled={ !bdayToggle() || SalutationWoFam[guest.guest_title as keyof typeof SalutationWoFam] === 2 }/>
                </InputView>
            </div>

            <InputView title="Firmenname" style={{ marginTop: '10px' }}><input ref={inputCompanyRef} type="text" defaultValue={guest.guest_company} placeholder="z. B. Unreal GmbH" /></InputView>

            <InputView title="Straße & Hausnummer" style={{ marginTop: '20px' }}><input ref={inputStreetRef} type="text" defaultValue={guest.guest_street} placeholder="z. B. Musterweg 7" /></InputView>
            <div className="flex center-y w100prc" style={{ marginTop: '10px', gap: '20px', padding: '3px 0' }}>
                <InputView title="Postleitzahl" style={{ width: '30%' }}><input ref={inputZipRef} type="text" defaultValue={guest.guest_zip} placeholder="z. B. 88630" /></InputView>
                <InputView title="Wohnort" style={{ width: '70%' }}><input ref={inputCityRef} type="text" defaultValue={guest.guest_city} placeholder="z. B. Musterstadt" /></InputView>
            </div>
            <InputView title="Land" style={{ marginTop: '10px' }}><input ref={inputCountryRef} type="text" defaultValue={guest.guest_country} placeholder="z. B. Deutschland" /></InputView>

            <div className="flex center-y w100prc" style={{ marginTop: '20px', gap: '10px', padding: '3px 0', overflow: 'visible' }}>
                <ContactNoteView type={CNViewType.MailAndPhone} contentArr={contactArr} style={{ height: '90px' }} updateData={handleUpdateCNData} />
            </div>
            <TextareaView style={{ height: '170px', marginTop: '20px' }} title='Mitteilung'>
                <textarea ref={textareaRef} placeholder='Ihre Mitteilung (optional)'/>
            </TextareaView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default OfferAcceptanceView;