import { InvoiceObj } from "../contexts/SettingsContext";
import { DefaultFetchResult } from "./types";

export const createSubscriptionId = async (transactionId: string) => {

    const data = new URLSearchParams();
  
    data.append('transaction_id', transactionId);
  
      const result = await fetch(
          `https://eazyac-dev.de/cgi-bin/eazyact/paypal/class_paypal.php?do=create`,
          {
          method: "POST", 
          body: data,
          'credentials': 'include',
          }
        )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postCancelSubscription = async (transactionId: string) => {

  const data = new URLSearchParams();

  data.append('transaction_id', transactionId);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/paypal/class_paypal.php?do=cancel`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateDatabase = async (transactionId: string, invoiceData: InvoiceObj) => {

  const payer_website = { user_title: (invoiceData.user_title === 0) ? 'Herr' : 'Frau',
                        user_firstname: invoiceData.user_firstname,
                        user_lastname: invoiceData.user_lastname,
                        user_company: invoiceData.user_company,
                        street: invoiceData.street,
                        zip: invoiceData.zip,
                        city: invoiceData.city,
                        country: invoiceData.country };

  const data = new URLSearchParams();

  data.append('transaction_id', transactionId);
  data.append('payer_website', JSON.stringify( payer_website ) );

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/paypal/class_paypal.php?do=update_database`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}