import { DefaultFetchResult, Property } from "./types";

export const fetchPropertys = async () => {

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=property&do=select_all`,
        {
        method: "GET", 
       'credentials': 'include',
        }
      )
      console.log('fetchinPropertys');
    const content: DefaultFetchResult = await result.json();
    return content;

}

export const fetchProperty = async (propertyId: number) => {

  const data = new URLSearchParams();

  data.append('property_id', propertyId.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=property&do=select`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const fetchDeactivatedPropertys = async () => {

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=property&do=select_deactivated`,
      {
      method: "GET", 
     'credentials': 'include',
      }
    )
    console.log('fetchinPropertys');
  const content: DefaultFetchResult = await result.json();
  return content;

}

export const postUpdatePropertySingleValue = async (propertyId: number, field: string, fieldValue: string) => {

  const data = new URLSearchParams();

  data.append('property_id', propertyId.toString());
  data.append('field', field);
  data.append('field_value', fieldValue);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=property&do=update_single`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postAddProperty = async (property: Property) => {

  const data = new URLSearchParams();

  data.append('property_art', property.property_art);
  data.append('property_name', property.property_name);
  data.append('property_img', property.property_img!);
  data.append('property_address', property.property_address!.toString());
  data.append('property_livingroom', property.property_livingroom!.toString());
  data.append('property_bedroom', property.property_bedroom!.toString());
  data.append('property_kitchen', property.property_kitchen!.toString());
  data.append('property_bathroom', property.property_bathroom!.toString());
  data.append('property_squaremeters', property.property_squaremeters!.toString());
  data.append('property_levels', property.property_levels!.toString());
  data.append('property_level', property.property_level!.toString());
  data.append('property_beds', property.property_beds!.toString());
  data.append('property_build', property.property_build!.toString());
  data.append('property_service', property.property_service!.toString());
  data.append('property_pm', property.property_pm!.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=property&do=insert`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateProperty = async (property: Property) => {

  const data = new URLSearchParams();
  
  data.append('property_id', property.property_id.toString());
  data.append('property_art', property.property_art);
  data.append('property_name', property.property_name);
  data.append('property_img', property.property_img!);
  data.append('property_address', property.property_address!.toString());
  data.append('property_livingroom', property.property_livingroom!.toString());
  data.append('property_bedroom', property.property_bedroom!.toString());
  data.append('property_kitchen', property.property_kitchen!.toString());
  data.append('property_bathroom', property.property_bathroom!.toString());
  data.append('property_squaremeters', property.property_squaremeters!.toString());
  data.append('property_levels', property.property_levels!.toString());
  data.append('property_level', property.property_level!.toString());
  data.append('property_beds', property.property_beds!.toString());
  data.append('property_build', property.property_build!.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=property&do=update`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteProperty = async (propertyId: number) => {

  const data = new URLSearchParams();

  data.append('property_id', propertyId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=property&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}