import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { postLogout } from "../methods/http.auth.methods";

const Logout = () => {

    const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(["PHPSESSID"]);


    const { mutate: postLogoutMutation } = useMutation( {

        mutationFn: () => postLogout(),
        onSuccess: () => {

            setCookie('PHPSESSID', '', { path: "/" });
            navigate('login');

        },
        onError: (error) => console.log(error),
    } );

    useEffect( () => {

        setTimeout(() => {
            postLogoutMutation();
        }, 1000);

    }, []);

    return ( 

        <div id="infoBox" className="shadowBox center wrap" style={{width: '40%', margin: '100px 0 0 0'}}>
        <div className="flex center color-cyan" style={{width: '100%', padding: '0 0 10px 0', fontSize: '1.5rem'}}>Abmelden</div>
        <div className="flex center" style={{width: '100%'}}>
            <div className="default-icon flex center" style={{width: '30%', fontSize: '5.0rem'}}>exit_to_app</div>
            <div className="flex center-y" style={{width: '70%'}}>Du wirst von Eazyac abgemeldet...</div>
        </div>
        </div>

     );
}
 
export default Logout;