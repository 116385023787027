import '../css/statistics.css'

type Props = {
    value: number;
}

const ProgressBar = ({ value }: Props) => {

    const stringValue = value.toFixed(0);

    return ( 
        <div className="flex-container center-y wrap w100prc" style={{padding: '10px 0'}}>
            <div id="valueLbl" className="flex-container center w100prc" style={{fontSize: '0.8rem'}}>{ `${stringValue} % belegt` }</div>
            <div className="progress-bar">
                <div className="value-bar" style={{width: `${stringValue}%`}}></div>
            </div>
        </div>
     );
}
 
export default ProgressBar;