import { useMutation } from "@tanstack/react-query";
import { postDeleteFile } from "../methods/http.korres.methods";
import ModalView from "./ModalView";

type Props = {

    fileUrl: string;
    fileName: string;
    style?: React.CSSProperties | undefined;
    handleClose: () => void;
    reloadFunc?: () => void;

}

const IFrameView = ( { fileUrl, fileName, style, handleClose, reloadFunc }: Props ) => {

    const srcUrl = `https://eazyac-dev.de/${fileUrl}${fileName}`;

    const { mutate: postDeleteFileMutation } = useMutation({
        mutationFn: () => postDeleteFile(fileUrl, fileName),
        onSuccess: () => {

            if (reloadFunc) {
                reloadFunc();
            }

            handleClose();

        },
        //onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 

        <ModalView modalViewId="iFrameView" style={style} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex w100prc"></div>
                <div onClick={ () => postDeleteFileMutation() } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>delete</div>
            </div>
            <iframe title={ srcUrl } src={ srcUrl } className="standard-iframe"></iframe>
        </ModalView>

     );
}
 
export default IFrameView;