import { useContext, useId, useRef, useState } from "react";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { AlertObj, ZlObj } from "../../../methods/types";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import InputView from "../../../generalComps/InputView";
import { useMutation } from "@tanstack/react-query";
import { postAddAddiService, postUpdateAddiService } from "../../../methods/http.addiservice.methods";
import PopUp from "../../../generalComps/PopUp";

type Props = {
    handleCancel: () => void;
    handleSave: (type: string, newAddiServiceId?: number | undefined) => void;
}

const AddiServiceAddEditView = ( { handleCancel, handleSave }:Props ) => {

    const { selectedAddiService, setSelectedAddiService } = useContext(PropertysViewContext);

    const addiServiceTypes = ['Einmalig', 'Pro Nacht', 'Pro Stück', 'Pro Nacht & Stück'];

    const [isVisibleTypePopUp, setIsVisibleTypePopUp] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputTitleRef = useRef<HTMLInputElement | null>(null);
    const inputValueRef = useRef<HTMLInputElement | null>(null);

    const handleValueChange = () => {

        if ( Number(inputValueRef.current!.value) <= 0) {

            inputValueRef.current!.value = '0';

        }

    }

    const handleSaveOnClick = () => {
        
        if ( Number(inputTitleRef.current!.value.length) === 0 ) {
        
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte vergebe eine Bezeichnung.', standardAlertButton ) );
            
        } else if ( Number(inputValueRef.current!.value) <= 0 ) {
    
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte trage einen Wert größer 0 ein.', standardAlertButton ) );
            
        } else {

            const newAddiService: ZlObj = { ...selectedAddiService,
                                            zl_name: inputTitleRef.current!.value,
                                            zl_price: Number(inputValueRef.current!.value) };

            if (newAddiService.zl_id === -1) {
                postAddAddiServiceMutation(newAddiService);
            } else {
                postUpdateAddiServiceMutation(newAddiService);
            }

        }

    }

    const { mutate: postAddAddiServiceMutation } = useMutation({
        mutationFn: (newAddiService: ZlObj) => postAddAddiService( newAddiService ),
        onSuccess: (data) => {

            setSelectedAddiService( prev => ( {...prev, zl_id: Number(data.zl_id!) } ));

            handleSave('new', Number(data.zl_id!));

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdateAddiServiceMutation } = useMutation({
        mutationFn: (newAddiService: ZlObj) => postUpdateAddiService( newAddiService ),
        onSuccess: () => {

            handleSave('update');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const TypePopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px', height: 'auto' }} handleClosePopUp={ () => setIsVisibleTypePopUp(false) }>
            {addiServiceTypes.map( (type, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => {

                        setSelectedAddiService( prev => ( {...prev, zl_art: index} ) );

                        setIsVisibleTypePopUp(false);

                        e.stopPropagation();

                    } }
                    key={type}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{type}</div>
                    {index === selectedAddiService.zl_art && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    return ( 

        <>
        <ModalView modalViewId="addiServiceAddEditView" style={{ width: '550px', overflow: 'visible' }} handleClose={ handleCancel }>
            <div className="navigation-view-bar">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">{ selectedAddiService.zl_id === -1 ? 'Neue Zusatzleistung' : 'Zusatzleistung bearbeiten' }</div>
                <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <div className="shadowBox" style={{ marginTop: '10px' }}>
            Wähle die Art, den Namen und einen Wert für deine Zusatzleistung.
            Lege außerdem fest, ob eine Umsatzsteuer berechnet werden soll.<br/><br/>
            Einmalig: Wird einmalig berechnet, z. B. Endreinigung.<br/>Pro Nacht: Wird pro Nacht berechnet, z. B. Parkplatz.<br/>Pro Stück: Wird anhand einer Stückzahl berechnet, z. B. Handtücher.<br/>Pro Nacht & Stück: Wird anhand einer Stückzahl berechnet und dann mit der Anzahl der Übernachtungen multipliziert, z. B. Kaffeekapseln.
            </div>
            <div onClick={ () => setIsVisibleTypePopUp(true) } className="clickable-popUpButton" style={{ marginTop: '10px' }}>
                { addiServiceTypes[selectedAddiService.zl_art] }
                { isVisibleTypePopUp && <TypePopUp  /> }
            </div>
            <InputView title="Bezeichnung" style={{ marginTop: '10px' }}><input ref={inputTitleRef} type="text" defaultValue={ selectedAddiService.zl_name } placeholder="z. B. Endreinigung" /></InputView>
            <InputView title="Preis" style={{ marginTop: '10px' }}><input ref={inputValueRef} onChange={ handleValueChange } type="number" defaultValue={ selectedAddiService.zl_price } placeholder="z. B. 50" style={{ textAlign: 'center' }} /></InputView>
            <div className="flex center-y" style={{ paddingTop: '10px' }}>
                <div className="default-icon" style={{ width: '40px' }}>account_balance</div>
                <div className="flex center-y" style={{ width: '40%' }}>Umsatzsteuer { selectedAddiService.zl_tax ? 'aktiviert' : 'deaktiviert' }: { selectedAddiService.zl_tax_percent } %</div>
                <div className="flex center" style={{ width: '60%' }}>
                    <div onClick={ () => { if (selectedAddiService.zl_tax_percent > 1) { setSelectedAddiService( prev => ( {...prev, zl_tax_percent: prev.zl_tax_percent -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: selectedAddiService.zl_tax_percent === 1 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                    <div onClick={ () => setSelectedAddiService( prev => ( {...prev, zl_tax_percent: prev.zl_tax_percent +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                </div>
                <div onClick={ () => setSelectedAddiService( prev => ( {...prev, zl_tax: prev.zl_tax === 0 ? 1 : 0 } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ selectedAddiService.zl_tax ? 'toggle_on' : 'toggle_off' }</div>
            </div>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default AddiServiceAddEditView;