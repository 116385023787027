import { ReactElement, useState, useRef, useContext } from "react";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import InputView from "../generalComps/InputView";
import MailAddressAddEditView from "./MailAddressAddEditView";
import { createPortal } from "react-dom";

type Props = {

    addressArr: Array<string>;
    title: string;
    style?: React.CSSProperties | undefined;

}

const MailAddressWrapper = ( { title, addressArr, style }: Props ) => {

    const { mailContentObjRef } = useContext( MessageDetailViewContext);

    const [mailAddressArr, setMailAddressArr] = useState( addressArr );
    const [isVisibleAddEditView, setIsVisibleAddEditView] = useState(false);

    const selectedAddressRef = useRef<{address: string, index: number}>({address: '', index: -1});

    const MailAddressElement = ( {address }:{address: string} ):ReactElement => {

        return(

            <div onClick={ () => handleAddEditAddress(address) } className="mail-address-style">
                <div className="flex center-y w100prc h100prc">{address}</div>
                <div onClick={ (e) => handleDeleteAddress(e, address) } className="clickable-icon flex center h100prc" style={{ color: 'red', fontSize: '0.8em', width: '20px' }}>close</div>
            </div>

        )

    }

    const handleDeleteAddress = (e: React.SyntheticEvent, address: string) => {

        const index = mailAddressArr.indexOf( address );
        const tempArr = [...mailAddressArr];
        tempArr.splice( index, 1 );

        if ( title === 'An') {
            mailContentObjRef.current.to = tempArr;
        } else {
            mailContentObjRef.current.cc = tempArr;
        }

        setMailAddressArr( tempArr );

        e.stopPropagation();

    }

    const handleAddEditAddress = (address: string) => {

        selectedAddressRef.current.address = address;
        selectedAddressRef.current.index = mailAddressArr.indexOf( address );

        setIsVisibleAddEditView(true);

    }

    const handleMailAddress = (newAddress: string, isNew: boolean) => {

        const tempArr = [...mailAddressArr];

        if (isNew) {

            tempArr.push( newAddress );

        } else {

            tempArr[selectedAddressRef.current.index] = newAddress;

        }

        if ( title === 'An') {
            mailContentObjRef.current.to = tempArr;
        } else {
            mailContentObjRef.current.cc = tempArr;
        }

        setMailAddressArr( tempArr );
        setIsVisibleAddEditView(false);

    }

    return ( 

        <InputView title={ title } style={ style }>
            <div onClick={ () => handleAddEditAddress('') } className="clickable-icon" style={{ position: 'absolute', top: '2px', right: '5px', fontSize: '1.5em' }}>add_circle</div>
            <div className="flex center-y w100prc" style={{ gap: '5px' }}>
            {
                mailAddressArr.length > 0 ?
                mailAddressArr.map( address => {

                    return(
                        <MailAddressElement key={address} address={ address } />
                    )

                }) :
                <div className="flex center w100prc color-lightblack">keine E-Mail-Adressen</div>
            }
            </div>
            { isVisibleAddEditView && createPortal( <MailAddressAddEditView address={ selectedAddressRef.current.address } handleSuccessfullSave={ handleMailAddress } handleClose={ () => setIsVisibleAddEditView(false) } />, document.body ) }
        </InputView>

     );
}
 
export default MailAddressWrapper;