import { ReactElement, useState } from "react";

type Props = {
    title: string;
    children: ReactElement;
    style: any;
}

const ChartView = ( { title, children, style }: Props ) => {

    const [isExpanded, setIsExpanded] = useState(false);

    return ( 

        <div className={ `shadowBox w100prc${isExpanded ? ' expand-chartview' : ''}` } style={ style }>
            <div className="flex-container center-y w100prc">
                <div className="flex-container center-y w100prc">{ title.toUpperCase() }</div>
                <div onClick={() => setIsExpanded(!isExpanded) } className="clickable-icon">{ isExpanded ? 'close_fullscreen' : 'open_in_full' }</div>
            </div>
            { children }
        </div>

     );
}
 
export default ChartView;