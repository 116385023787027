import { DefaultFetchResult, PdfContentObj, PdfType } from "./types";

export const fetchKorres = async (stayId: number) => {

  const data = new URLSearchParams();

  data.append('korres_stay', stayId.toString());

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=mail_korres&do=select',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const fetchKorresByType = async (stayId: number, korresType: number) => {

    const data = new URLSearchParams();
  
    data.append('korres_stay', stayId.toString());
    data.append('korres_art', korresType.toString());
  
    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=mail_korres&do=select_by_art',
        {
        method: 'POST', 
        body: data,
       'credentials': 'include',
        }
      )
  
    const content: DefaultFetchResult = await result.json();
  
    return content;
  
}

export const postKorresSetReaded = async (korresId: number) => {

  const data = new URLSearchParams();

  data.append('korres_id', korresId.toString());

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=mail_korres&do=set_readed',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const deleteAllFiles = async () => {

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=folder&do=delete_all_files',
      {
      method: 'GET',
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const fetchPdfUrl = async (stayId: number, pdfContent: PdfContentObj, korresType: number, korresTypeString: string) => {

  const data = new URLSearchParams();

  data.append('stay_id', stayId.toString());
  data.append('pdf_content', JSON.stringify( pdfContent ) );
  data.append('pdf_art_text', korresTypeString);
  data.append('pdf_art', korresType.toString());

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/pdf/class_korres_pdf.php',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const fetchPdfExTpUrl = async (propertyId: number, guestId: number | undefined, pdfType: PdfType) => {

  const data = new URLSearchParams();

  data.append('property_id', propertyId.toString());

  if (guestId) {
    data.append('guest_id', guestId.toString() );
  }

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/pdf/${ pdfType === PdfType.Expose ? 'class_ex_pdf.php' : 'class_tp_pdf.php' }`,
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const fetchMails = async () => {

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=mail_korres&do=select_all',
      {
      method: 'GET',
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const fetchNewMails = async () => {

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=mail_korres&do=select_new_mails',
      {
      method: 'GET',
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const postMailCreateServerFiles = async (korresId: number) => {

  const data = new URLSearchParams();

  data.append('korres_id', korresId.toString());

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=mail_korres&do=create_files',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const postCopyFilesToMailAttachment = async (korresId: number) => {

  const data = new URLSearchParams();

  data.append('korres_id', korresId.toString());

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=folder&do=copy_files_to_attachment',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const postDeleteKorres = async (korresId: number) => {

  const data = new URLSearchParams();

  data.append('korres_id', korresId.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=mail_korres&do=delete`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postSendMail = async (to: Array<string>, cc: Array<string>, subject: string, message: string, stayId: number, korresType: number, invoiceNumber: string, isOnlineOffer: boolean) => {

  const data = new URLSearchParams();

  data.append('mail_to', to.join(';'));
  data.append('mail_cc', cc.join(';'));
  data.append('mail_subject', subject);
  data.append('mail_body', message);
  data.append('korres_stay', stayId.toString());
  data.append('korres_art', korresType.toString());
  data.append('invoice_number', invoiceNumber);
  data.append('is_online_offer', isOnlineOffer.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=mail&do=send`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const fetchAllFiles = async () => {

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=folder&do=select_all_files',
      {
      method: 'GET',
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const postUploadFile = async (fileData: string, fileName: string) => {

  const data = new URLSearchParams();

  data.append('file_data', fileData);
  data.append('file_name', fileName);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=folder&do=upload_file`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteFile = async (fileUrl: string, fileName: string) => {

  const data = new URLSearchParams();

  data.append('file_url', fileUrl);
  data.append('file_name', fileName);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=folder&do=delete_file`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}