import { CSSProperties, useContext, useMemo, useState } from "react";
import ImageView from "../../generalComps/ImageView";
import { Property } from "../../methods/types";
import PopUp from "../../generalComps/PopUp";
import { createPortal } from "react-dom";
import ModalView from "../../generalComps/ModalView";
import CalendarExport from "../../settingsComps/CalendarExport";
import CalendarImport from "../../settingsComps/calendarImportComps/CalendarImport";
import MenuButton from "../../generalComps/MenuButton";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import PropertyDetailView from "../../propertyComps/PropertyDetailView";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import { useMutation } from "@tanstack/react-query";
import { fetchProperty } from "../../methods/http.property.methods";

type Props = {
    property: Property;
    style?: CSSProperties | undefined;
}

const MultiCalendarPropertysCard = ( { property, style }:Props ) => {

    const { setSelectedProperty } = useContext(PropertysViewContext);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);
    const [isVisibleExportView, setIsVisibleExportView] = useState(false);
    const [isVisibleImportView, setIsVisibleImportView] = useState(false);
    const [isVisiblePropertyDetailView, setIsVisiblePropertyDetailView] = useState(false);

    const imageUrl = useMemo( () => `https://eazyac-dev.de/user_data/${property.user_folder!}/prop_images/${property.property_id}.png`, [property.property_id]);

    const imageSize = 40;
    const fontSize = '1.0em';

    const { mutate: fetchPropertyMutation } = useMutation({
        mutationFn: (propertyId: number) => fetchProperty(propertyId),
        onSuccess: (data) => {

            setSelectedProperty( data.obj )

            setIsVisiblePropertyDetailView(true);

        },
        onError: (error) => { console.log(error) },
    });

    return ( 

        <div title={ property.property_name } className="propertycard border-bottom-strong" style={style}>
            <ImageView src={imageUrl} width={ imageSize } height={ imageSize } alt={property.property_name} />
            <div id="propertyName" className="flex column" style={{ marginLeft: '10px', width: '70%', }}>
                <div className="color-lightblack" style={{ fontSize: fontSize }}>{property.property_art}</div>
                <div style={{ fontSize: fontSize }}>{property.property_name}</div>
            </div>
            <div id="menuBtn" onClick={ (e) => { setIsVisibleMenu( value => !value); e.stopPropagation(); } } className="flex center h100prc clickable-icon" style={{ width: '40px', fontSize: fontSize }}>more_horiz</div>
            { isVisibleMenu && 
                <PopUp idTag="propertyDetailMenu" style={{ gap: '10px', minWidth: '200px', height: 'auto', fontSize: '1.0rem', marginTop: '30px', left: 'auto', right: '0' }} handleClosePopUp={ () => setIsVisibleMenu(false) } >
                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => setIsVisibleExportView(true) } leftIcon="share" buttonTitle="Belegungsplan exportieren" />
                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => setIsVisibleImportView(true) } leftIcon="share" buttonTitle="Belegungsplan importieren" />
                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => fetchPropertyMutation( property.property_id ) } leftIcon="house" buttonTitle="Immobilien-Details" />
                </PopUp>
            }
            { isVisibleExportView && createPortal(
                <ModalView modalViewId="calendarExportView" style={{ width: '600px', overflow: 'visible' }} handleClose={ () => setIsVisibleExportView(false) }>
                    <CalendarExport isModal={true} givenProperty={property} handleClose={ () => setIsVisibleExportView(false) }/>
                </ModalView>, document.body
            ) }
            { isVisibleImportView && createPortal(
                <ModalView modalViewId="calendarImportView" style={{ width: '600px', overflow: 'visible' }} handleClose={ () => setIsVisibleImportView(false) }>
                    <CalendarImport isModal={true} givenProperty={property} handleClose={ () => setIsVisibleImportView(false) }/>
                </ModalView>, document.body
            ) }
            { isVisiblePropertyDetailView && createPortal(
                <ModalView modalViewId="propertyDetailView" style={{ overflow: 'visible' }} handleClose={ () => setIsVisiblePropertyDetailView(false) }>
                    <PropertyDetailView handleCancel={ () => setIsVisiblePropertyDetailView(false) } />
                </ModalView>, document.body
            ) }
        </div>

     );
}
 
export default MultiCalendarPropertysCard;