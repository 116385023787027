import { getDateObj } from "./standard.methods";
import { DateObj } from "./types";

declare global {
    interface Date  {
        getWeek: () => number;
    }
}

export class CalendarDates {

    startDate: Date;
    endDate: Date;
    numberOfMonths: number;

    constructor(startDate: Date, endDate: Date) {

        this.startDate = startDate;
        this.endDate = endDate;
        this.numberOfMonths = this.monthDiff(startDate, endDate);

    }

    monthDiff(d1: Date, d2: Date) {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    }

    getStartDateString() {
        
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

        return this.startDate.toLocaleDateString('de-DE', options);

    }

    getEndDateString() {
        
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

        return this.endDate.toLocaleDateString('de-DE', options);

    }

    getMonthString(date: Date) {

        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long' };

        return date.toLocaleDateString('de-DE', options);

    }

}

export const getCalendarWeeks = (date: Date):[Array<Number>, Array<DateObj>] => {

    const monthArr = Array<number>();
    const datesArr = Array<DateObj>();

    for (let i = 0; i <= 5; i++) {

        const newDate = new Date(date.getFullYear(), date.getMonth(), 1 + (i * 7), 0, 0, 0);

        monthArr.push( newDate.getWeek() );

        const firstDateOfWeek = getFirstDateOfWeek(newDate);
        
        for(let ii = 0; ii < 7; ii++) {

            datesArr.push( getDateObj( new Date(firstDateOfWeek.getTime()), ii) );

        }

    }

    return [monthArr, datesArr];

}

export const getMonthDays = (date: Date) => {

    const arr = Array<DateObj>();

    const fdom = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
    let fday = getDateOfISOWeek((fdom.getDate() == 1 && fdom.getMonth() == 0) ? 1 : fdom.getWeek(), fdom.getFullYear());

    if (fday.getMonth() == 0) {

        fday = new Date(fday.getFullYear(), fday.getMonth(), fday.getDate() - 7);

    }
    console.log(fday);
    for (let i = 0; i <= 41; i++) {

        const newDateObj = getDateObj(new Date(fday.getFullYear(), fday.getMonth(), fday.getDate(), 0, 0, 0), i);

        arr.push(newDateObj);

    }

    return arr;

}

const getDateOfISOWeek = (w: number, y: number) => {

    var simple = new Date(y, 0, 1 + (w - 1) * 7);
    
    var dow = simple.getDay();
    var ISOweekStart = simple;
    if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    return ISOweekStart;

}

const getFirstDateOfWeek = (d: Date) => {
    
    d = new Date(d);
    var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));

};

// export const getDateRangeOfWeek = (weekNo: number) => {
//     var d1 = new Date();
//     let numOfdaysPastSinceLastMonday = d1.getDay() - 1;
//     d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
//     var weekNoToday = d1.getWeek();
//     var weeksInTheFuture = weekNo - weekNoToday;
//     d1.setDate(d1.getDate() + 7 * weeksInTheFuture );
//     var rangeIsFrom = d1.getMonth()+1 +"/" + d1.getDate() + "/" + d1.getFullYear();
//     d1.setDate(d1.getDate() + 6);
//     var rangeIsTo = d1.getMonth()+1 +"/" + d1.getDate() + "/" + d1.getFullYear() ;
//     return rangeIsFrom + " to "+rangeIsTo;
// };

Date.prototype.getWeek = function() {

    var date = new Date(this.getTime());
     date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
  
  }