import { useContext, useMemo } from "react";
import { OccupancyModulContext } from "../../../contexts/OccupancyModulContext";
import { StayDetailViewContext } from "../../../contexts/StayDetailViewContext";
import ImageView from "../../../generalComps/ImageView";
import { daysBetweenDates, getDateObj, getFullname } from "../../../methods/standard.methods";
import { emptyProperty } from "../../../methods/constants";

const MultiCalendarStayDetailsView = () => {

    const { setIsVisibleStayDetailsView, filteredPropertys } = useContext(OccupancyModulContext);
    const { stay } = useContext(StayDetailViewContext);

    const property = useMemo( () => {

        const foundProperty = filteredPropertys.find( prop => prop.property_id === stay.stay_property );

        if (foundProperty) {

            return foundProperty;

        } else {

            return emptyProperty;
            
        }

    }, [stay]);

    const imageUrl = property.user_folder === undefined ? '' : `https://eazyac-dev.de/user_data/${property.user_folder!}/prop_images/${stay.stay_property!}.png`;
    const {fullname, company} = useMemo( () => getFullname(stay.guest_title!, stay.guest_firstname!, stay.guest_lastname!, stay.guest_company!), [stay.guest_title, stay.guest_firstname, stay.guest_lastname, stay.guest_company]);

    const dateCi = getDateObj(stay.stay_checkIn!, 0);
    const dateCo = getDateObj(stay.stay_checkOut!, 0);

    const nights = useMemo( () => daysBetweenDates( dateCi.date, dateCo.date ), [stay.stay_checkIn, stay.stay_checkOut] );

    return ( 

        <div id="multiCalendarStayDetailsView" className="shadowBox">
            <div className="navigation-view-bar">
                <div onClick={ () => setIsVisibleStayDetailsView(false) } className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
            </div>
            <div className="flex column shadowBox" style={{marginTop: '10px'}}>
                <div className="flex center-y w100prc" style={{fontSize: '1.2em'}}>
                    <div className="title truncate-text">{ `${stay.property_name} | ${stay.property_art}` }</div>
                </div>
                <div className="flex center-y w100prc" style={{padding: '10px 0 10px 0'}}>
                    <div className="flex center h100prc" style={{width: '50px', marginRight: '10px'}}><ImageView src={imageUrl} width={50} height={50} alt={stay.property_name!} /></div>
                    <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 0px', width: '40%'}}>
                        <div className="flex center-y color-lightblack standard-fontsize">{ stay.guest_title }</div>
                        <div className="flex center-y truncate-text standard-fontsize">{ fullname }</div>
                        <div className="flex center-y color-lightblack standard-fontsize">{ company }</div>
                    </div>
                    <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 10px', width: '50%'}}>
                        <div className="flex center-y" style={{padding: '5px 0'}}>
                            <div title="Übernachtungen" className="flex center w100prc" >
                                <div className="default-icon" style={{fontSize: '1.5em'}}>brightness_2</div>
                                <div className="flex center" style={{padding: '0 5px'}}>{nights}</div>
                            </div>
                            <div title="Erwachsene" className="flex center w100prc" >
                                <div className="default-icon" style={{fontSize: '1.5em'}}>person</div>
                                <div className="flex center" style={{padding: '0 5px'}}>{stay.stay_adults!}</div>
                            </div>
                            <div title="Kinder" className="flex center w100prc" >
                                <div className="default-icon" style={{fontSize: '1.5em'}}>child_care</div>
                                <div className="flex center" style={{padding: '0 5px'}}>{ stay.stay_children! }</div>
                            </div>
                            { stay.stay_pets! > 0 &&
                            <div title="Hunde" className="flex center w100prc" >
                                <div className="default-icon" style={{fontSize: '1.5em'}}>pets</div>
                                <div className="flex center" style={{padding: '0 5px'}}>{ stay.stay_pets! }</div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

     );
}
 
export default MultiCalendarStayDetailsView;