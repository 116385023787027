import { useContext } from "react";
import MultiCalendarDates from "./MultiCalendarDates";
import MultiCalendarPropertys from "./MultiCalendarPropertys";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import MultiCalendarStayDetailsView from "./MultiCalendarStayDetailsView";
import MessageDetailViewContextProvider from "../../contexts/MessageDetailViewContext";

const MultiCalendar = () => {

    const { isVisibleStayDetailsView } = useContext(OccupancyContext);

    return ( 

            <>
            <div className="h100prc w100prc" style={{ display: 'block', marginTop: '10px', overflow: 'visible' }}>
                <MultiCalendarPropertys />
                <MultiCalendarDates />
            </div>
            { isVisibleStayDetailsView && <MessageDetailViewContextProvider><MultiCalendarStayDetailsView /></MessageDetailViewContextProvider> }
            </>

     );
}
 
export default MultiCalendar;