import { DefaultFetchResult, Guest } from "./types";

export const fetchGuestsAll = async () => {

    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=guest&do=select_all',
        {
        method: 'GET', 
       'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content;

}

export const fetchGuestById = async (guestId: number) => {

  const data = new URLSearchParams();

  data.append('guest_id', guestId.toString());

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=guest&do=select',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  return content;

}

export const postAddGuest = async (guest: Guest) => {

  const data = new URLSearchParams();

  data.append('guest_title', guest.guest_title!);
  data.append('guest_lastname', guest.guest_lastname!);
  data.append('guest_firstname', guest.guest_firstname!);
  data.append('guest_company', guest.guest_company!);
  data.append('guest_birthday', guest.guest_birthday!);
  data.append('guest_birthday_reminder', guest.guest_birthday_reminder.toString());
  data.append('guest_rate', guest.guest_rate.toString());
  data.append('guest_street', guest.guest_street!);
  data.append('guest_zip', guest.guest_zip!);
  data.append('guest_city', guest.guest_city!);
  data.append('guest_country', guest.guest_country!);
  data.append('guest_website', '');
  data.append('guest_blacklist', guest.guest_blacklist.toString());
  data.append('guest_contact', guest.guest_contact!);
  data.append('guest_note', guest.guest_note!);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=guest&do=insert`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateGuest = async (guest: Guest) => {

  const data = new URLSearchParams();

  data.append('guest_id', guest.guest_id.toString());
  data.append('guest_title', guest.guest_title!);
  data.append('guest_lastname', guest.guest_lastname!);
  data.append('guest_firstname', guest.guest_firstname!);
  data.append('guest_company', guest.guest_company!);
  data.append('guest_birthday', guest.guest_birthday!);
  data.append('guest_birthday_reminder', guest.guest_birthday_reminder.toString());
  data.append('guest_rate', guest.guest_rate.toString());
  data.append('guest_street', guest.guest_street!);
  data.append('guest_zip', guest.guest_zip!);
  data.append('guest_city', guest.guest_city!);
  data.append('guest_country', guest.guest_country!);
  data.append('guest_website', '');
  data.append('guest_blacklist', guest.guest_blacklist.toString());
  data.append('guest_contact', guest.guest_contact!);
  data.append('guest_note', guest.guest_note!);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=guest&do=update`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postImportGuest = async (guest: Guest) => {

  const data = new URLSearchParams();

  data.append('guest_id', guest.guest_id.toString());
  data.append('guest_title', guest.guest_title!);
  data.append('guest_lastname', guest.guest_lastname!);
  data.append('guest_firstname', guest.guest_firstname!);
  data.append('guest_company', guest.guest_company!);
  data.append('guest_birthday', guest.guest_birthday!);
  data.append('guest_street', guest.guest_street!);
  data.append('guest_zip', guest.guest_zip!);
  data.append('guest_city', guest.guest_city!);
  data.append('guest_country', guest.guest_country!);
  data.append('guest_contact', guest.guest_contact!);
  data.append('guest_note', guest.guest_note!);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=guest&do=import`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteGuest = async (guest_id: number) => {

  const data = new URLSearchParams();

  data.append('guest_id', guest_id.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=guest&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}