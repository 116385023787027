import { useMutation } from "@tanstack/react-query";
import { useContext, useId, useState } from "react";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import ModalView from "../generalComps/ModalView";
import { AlertObj } from "../methods/types";
import { postDeleteUserAccount } from "../methods/http.user.methods";
import { useNavigate } from "react-router-dom";
import { SettingsViewContext } from "../contexts/SettingsContext";

type Props = {
    handleClose: () => void;
}

const UserAccountDeletionView = ( { handleClose }:Props ) => {

    const navigate = useNavigate();

    const { setIsVisibleSettingsView } = useContext(SettingsViewContext);

    const [isActiveToggleOne, setIsActiveToggleOne] = useState(false);
    const [isActiveToggleTwo, setIsActiveToggleTwo] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const { mutate: postDeleteUserAccountMutation } = useMutation({
        mutationFn: () => postDeleteUserAccount(),
        onSuccess: (data) => {

            if (data.success) {

                navigate('login');

                setIsVisibleSettingsView(false);

            } else {
                setAlertObj( new AlertObj( true, data.response_title, data.response_text, standardAlertButton ) );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const handleDelete = () => {

        if (isActiveToggleOne && isActiveToggleTwo) {

            const alertBtns = [{ title: 'Löschen', handler: () => postDeleteUserAccountMutation() }, {...standardAlertButton[0], title: 'Abbrechen'}];

            setAlertObj( new AlertObj( true, 'Letzte Nachfrage!', 'Bist du sicher, dass du alle Daten unwiderruflich löschen möchtest?', alertBtns ) );

        }

    }

    return ( 
        <>
        <ModalView modalViewId="userMobileAddEditView" style={{ width: '700px', overflow: 'visible' }} handleClose={ handleClose }>
            <div className="flex center-y w100prc">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Konto löschen</div>
                <div onClick={ handleDelete } className={`clickable-icon${ ( isActiveToggleOne && isActiveToggleTwo ) ? '' : ' not-allowed' }`} style={{ fontSize: '1.5em', width: '40px', color: 'red', opacity: ( isActiveToggleOne && isActiveToggleTwo ) ? '1.0' : '0.2' }}>delete</div>
            </div>
            <div className="default-icon flex center w100prc" style={{ fontSize: '10.0em', color: 'red' }}>no_accounts</div>
            <div className="shadowBox" style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{__html: 'Durch betätigen der Schaltfläche Konto löschen werden dein Benutzerkonto, sowie alle damit verbundenen Daten unwiderruflich gelöscht:</br></br>- Benutzerdaten</br>- Immobilien und alle damit verbundenen Einstellungen (Vermieter, Preise, Zusatzleistungen, Zahlungskonten, etc.)</br>- Aufenthalte und alle damit verbundenen Daten (Korrespondenz, Zahlungen, etc.)</br>- Gäste</br></br>Solltest du ein aktives Abo haben, so kündige dieses bitte vor dem Löschen der Daten innerhalb der App unter Abonnements oder jederzeit in deinem Paypal-Konto bzw. Apple-Konto.</br></br>Aktiviere unten stehende Schaltflächen, um den Löschen-Button zu aktivieren.'}}></div>
            <div className="flex center w100prc" style={{ paddingTop: '10px' }}>
                <div onClick={ () => setIsActiveToggleOne( prev => !prev ) } className="clickable-icon flex center w100prc" style={{ fontSize: '3.0em' }}>{ isActiveToggleOne ? 'toggle_on' : 'toggle_off' }</div>
                <div onClick={ () => setIsActiveToggleTwo( prev => !prev ) } className="clickable-icon flex center w100prc" style={{ fontSize: '3.0em' }}>{ isActiveToggleTwo ? 'toggle_on' : 'toggle_off' }</div>
            </div>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default UserAccountDeletionView;