import { useMutation } from "@tanstack/react-query";
import { useContext } from "react";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import { fetchKorres, postKorresSetReaded } from "../methods/http.korres.methods";
import { createMailSeperatorArray } from "../methods/standard.methods";
import { ConversationObj } from "../methods/types";

type Props = {
    obj: ConversationObj;
}

const MailPreviewCard = ( { obj }:Props ) => {

    const { setIsVisibleMessageBackview, setSeperatorObj } = useContext(MessageDetailViewContext);

    const options: Intl.DateTimeFormatOptions = { weekday: 'short', year: '2-digit', month: 'short', day: 'numeric' };
    const date  = new Date( obj.creation_timestamp );
    date.setHours( 0, 0, 0, 0);

    const fromRegexed = obj.korres_from.match(/<(\S*)>/);
    let from = '';

    if (obj.korres_art === 10 || obj.korres_art === 11) {

        from = obj.korres_from;

    } else {

        if (obj.korres_stay === 9999999 ) {

            from = fromRegexed ? fromRegexed[1] : '';
    
        } else {
    
            from = fromRegexed ? obj.korres_from.replace(fromRegexed[0], '') : '';
    
        }

    }

    const { mutate: postKorresSetReadedMutation } = useMutation({
        mutationFn: () => postKorresSetReaded(obj.korres_id),
        onSuccess: () => {

            fetchKorresMutation();

        },
    })

    const { mutate: fetchKorresMutation } = useMutation({
        mutationFn: () => fetchKorres(obj.korres_stay),
        onSuccess: (data) => {

            setSeperatorObj( createMailSeperatorArray(data.obj) );

            setIsVisibleMessageBackview(true);

        },
    });

    const handleOnClick = () => {

        if (obj.korres_seen === 1) {

            fetchKorresMutation();

        } else {

            postKorresSetReadedMutation();

        }

    }

    return ( 

        <div onClick={ handleOnClick } className="flex mail-preview-card">
            <div className="flex center default-icon" style={{ fontSize: '2.0em', width: '60px' }}>{ obj.korres_art < 10 ? 'outbox' : 'move_to_inbox' }</div>
            <div className="flex column w100prc">
                <div className="flex w100prc">
                    { obj.korres_seen === 0 && <div className="flex center" style={{ color: 'red', fontWeight: 'bold', fontSize: '0.7em', width: '40px', paddingRight: '2px' }}>NEU</div> }
                    <div className="flex center-y w100prc">{ from }</div>
                    <div className="flex center" style={{ fontSize: '0.7em', width: '100px' }}>{ date.toLocaleDateString('de-DE', options) }</div>
                </div>
                <div className="flex center-y w100prc color-lightblack truncate-text" style={{ padding: '5px 0' }}>{ obj.korres_subject === null ? '< kein Betreff verfügbar >' : obj.korres_subject }</div>
            </div>
        </div>
     );
}
 
export default MailPreviewCard;