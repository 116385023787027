import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { DateObj, Property, Stay } from "../methods/types";
import { getDateObj, initDateArray } from "../methods/standard.methods";
import { useMutation } from "@tanstack/react-query";
import { fetchOccupancyShareData } from "../methods/http.offer.methods";
import { useParams } from "react-router-dom";

type Props = {
    children: ReactNode;
}

type ContextType = {
    //selectedProperty: Property,
    //setSelectedProperty: React.Dispatch<React.SetStateAction<Property>>,
    startMonth: number,
    setStartMonth: React.Dispatch<React.SetStateAction<number>>,
    endMonth: number,
    setEndMonth: React.Dispatch<React.SetStateAction<number>>,
    year: number,
    setYear: React.Dispatch<React.SetStateAction<number>>,
    filteredPropertys: Array<Property>,
    setFilteredPropertys: React.Dispatch<React.SetStateAction<Array<Property>>>,
    dates: Array<DateObj>,
    setDates: React.Dispatch<React.SetStateAction<Array<DateObj>>>,
    stays: Array<Stay>,
    isVisibleStayDetailsView: boolean,
    setIsVisibleStayDetailsView: React.Dispatch<React.SetStateAction<boolean>>,
    isVisibleVerification: boolean,
    setIsVisibleVerification: React.Dispatch<React.SetStateAction<boolean>>,
    loadData: (pwd: string) => void,
    isPending: boolean, };
    

export const OccupancyModulContext = createContext<ContextType>( { 
    //selectedProperty: emptyProperty,
    //setSelectedProperty: () => {},
    startMonth: 0,
    setStartMonth: () => {},
    endMonth: 11,
    setEndMonth: () => {},
    year: new Date().getFullYear(),
    setYear: () => {},
    filteredPropertys: [],
    setFilteredPropertys: () => {},
    dates: [],
    setDates: () => {},
    stays: [],
    isVisibleStayDetailsView: false,
    setIsVisibleStayDetailsView: () => {},
    isVisibleVerification: false,
    setIsVisibleVerification: () => {},
    loadData: (pwd: string) => {},
    isPending: true,

 } );

const OccupancyModulContextProvider = ({ children }: Props) => {

    const params = useParams();

    //const [selectedProperty, setSelectedProperty]  = useState( emptyProperty );
    const [startMonth, setStartMonth]  = useState( 0 );
    const [endMonth, setEndMonth]  = useState( 11 );
    const [year, setYear]  = useState( new Date().getFullYear() );
    const [filteredPropertys, setFilteredPropertys] = useState< Property[] >([]);
    const [dates, setDates] = useState<Array<DateObj>>([]);
    const [stays, setStays] = useState< Stay[] >([]);
    const [isVisibleStayDetailsView, setIsVisibleStayDetailsView] = useState(false);
    const [isVisibleVerification, setIsVisibleVerification] = useState(false);

    const startDateRef = useRef( getDateObj( new Date( year, startMonth, 1, 0, 0, 0, 0 ), 0 ) );
    const endDateRef = useRef( getDateObj( new Date( year, endMonth + 1, 0, 0, 0, 0, 0), 0 ) );

    const { isPending, mutate: fetchOccupancyShareDataMutation } = useMutation({
        mutationFn: (pwd: string) => fetchOccupancyShareData( params.token ? params.token : '', pwd),
        onSuccess: (data) => {

            if (data.success) {

                startDateRef.current = getDateObj( new Date( data.obj.dates.year, data.obj.dates.from , 1, 0, 0, 0, 0 ), 0 );
                endDateRef.current = getDateObj( new Date( data.obj.dates.year, data.obj.dates.to  + 1, 0, 0, 0, 0, 0), 0 );

                setDates( initDateArray(startDateRef.current, endDateRef.current) );

                setStartMonth( data.obj.dates.from );
                setEndMonth( data.obj.dates.to );
                setYear( data.obj.dates.year );

                setFilteredPropertys( data.obj.property_arr );
                setStays( data.obj.stay_arr );

                setIsVisibleVerification(false);

            } else if (data.verification) {

                setIsVisibleVerification(true);

            }

        },
        onError: (error) => {
            console.log(error);
        },
    });

    const loadData = (pwd: string) => {

        fetchOccupancyShareDataMutation(pwd);

    }

    return ( 
        <OccupancyModulContext.Provider value={ { //selectedProperty, 
                                            //setSelectedProperty, 
                                            startMonth, 
                                            setStartMonth, 
                                            endMonth, 
                                            setEndMonth, 
                                            year, 
                                            setYear, 
                                            filteredPropertys, 
                                            setFilteredPropertys, 
                                            dates, 
                                            setDates, 
                                            stays, 
                                            isVisibleStayDetailsView, 
                                            setIsVisibleStayDetailsView,
                                            isVisibleVerification, 
                                            setIsVisibleVerification,
                                            loadData,
                                            isPending } }>
            {children}
        </OccupancyModulContext.Provider>
     );
}
 
export default OccupancyModulContextProvider;