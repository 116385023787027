import { Property, PropertyListType, ViewState } from "../methods/types";
import { ReactElement, useContext, useState } from "react";
import ImageView from "../generalComps/ImageView";
import { PropertysViewContext } from "../contexts/PropertysMainContext";

type Props = {
    property: Property;
    handleOnClick: (state: ViewState) => void;
}

const PropertyCard = ( { property, handleOnClick }: Props ) => {

    const imageUrl = `https://eazyac-dev.de/user_data/${property.user_folder!}/prop_images/${property.property_id}.png`;

    const { propertyListType } = useContext(PropertysViewContext);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.ActivateDeactivate) } title={ propertyListType === PropertyListType.Deactivated ? 'Aktivieren' : 'Deaktivieren' } className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>{ propertyListType === PropertyListType.Deactivated ? 'visibility' : 'visibility_off' }</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(
            
            <div onClick={() => handleOnClick(ViewState.Details)}>
                <div className="flex center-y w100prc">
                    <div className="title truncate-text">{ `${property.property_name} | ${property.property_art}` }</div>
                </div>
                <div className="flex center-y w100prc" style={{padding: '10px 0 10px 0'}}>
                    <div className="flex center h100prc" style={{width: '50px'}}><ImageView src={imageUrl} width={50} height={50} alt={property.property_name} /></div>
                    <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 10px', width: '40%'}}>
                        <div className="flex center-y">{ property.address_street! }</div>
                        <div className="flex center-y">{ property.address_zip } { property.address_city! }</div>
                        <div className="flex center-y color-lightblack" style={{ marginTop: '5px' }}>{ property.address_country }</div>
                    </div>
                    <div className="flex center column" style={{ paddingLeft: '20px' }}>
                        <div className="flex center w100prc">
                            <div className="default-icon" style={{ width: '40px' }}>meeting_room</div>
                            <div className="flex center-y w100prc">{ property.property_bedroom + property.property_livingroom }</div>
                        </div>
                        <div className="flex center w100prc">
                            <div className="default-icon" style={{ width: '40px' }}>texture</div>
                            <div className="flex center-y w100prc">{ property.property_squaremeters } m²</div>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 
        <div className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: isVisibleMenu ? '20px' : '10px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>
     );
}
 
export default PropertyCard;