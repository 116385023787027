import { ReactElement, useContext, useState } from "react";
import { PriceObj, ViewState } from "../../../methods/types";
import { PropertysContext } from "../../../contexts/PropertysContext";
import { getDateObj } from "../../../methods/standard.methods";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";

type Props = {
    price: PriceObj;
    handleOnClick: (state: ViewState) => void;
}

const PriceCard = ( { price, handleOnClick }: Props ) => {

    const { propertys } = useContext(PropertysContext);
    const { selectedProperty } = useContext(PropertysViewContext);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const propertyPriceIds: Array<{price_id: number}> = selectedProperty.property_price ? JSON.parse( selectedProperty.property_price ) : [];

    let priceLbl = 'Preis: ' + price.price_value.toFixed(2) + ' ' + propertys.currencyShort;

    if (price.price_additional === 1) {
        priceLbl += ' | Aufpreis: ' + price.price_additional_value.toFixed(2) + ' ' + propertys.currencyShort;
    }

    let personsLbl = 'Personen: ';

    if (price.price_from > 0 && price.price_to > 0) {

        personsLbl += price.price_from + ' - ' + price.price_to;

    } else if (price.price_from > 0 && price.price_to === 0) {

        personsLbl += 'ab ' + price.price_from;

    } else if (price.price_from === 0 && price.price_to > 0) {

        personsLbl += 'bis ' + price.price_to;

    } else if (price.price_from === 0 && price.price_to === 0) {

        personsLbl += '< keine Auswahl >';

    }

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(
            
            <div className="flex center" onClick={ (e) => handleButtonClick(e, ViewState.Details) }>
                <div className="default-icon" style={{ width: '50px', fontSize: '1.5em' }}>{ propertyPriceIds.find( pId => pId.price_id === price.price_id ) !== undefined ? 'check' : '' }</div>
                <div className="flex center w100prc column">
                    <div className="flex center-y w100prc">{ priceLbl }</div>
                    <div className="flex center-y w100prc" style={{ padding: '2px 0' }}>{ personsLbl }</div>
                    <div className="flex center-y w100prc">Saison: { getDateObj( price.price_start!, 0 ).dateStringDE } - { getDateObj( price.price_end!, 0 ).dateStringDE } </div>
                </div>
            </div>
            
        );
    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 
        <div className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: '20px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>
     );
}
 
export default PriceCard;