import { useMutation } from "@tanstack/react-query";
import { memo, useContext, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import MailCreationBackView from "../messageComps/MailCreationBackView";
import { deleteAllFiles } from "../methods/http.korres.methods";
import { fetchPrefilledTemplateByType } from "../methods/http.templates.methods";
import { amountOfPaymentListArr, amountOfPriceListArr } from "../methods/price.calculation.methods";
import { getDateObj } from "../methods/standard.methods";
import { Contact, PaymentObj, PriceListType, TaxType } from "../methods/types";

const StayPaymentView = () => {

    const {stay, paymentArr, priceListArr, pmSettingsObj} = useContext(StayDetailViewContext);
    const { templatesArrRef, conversationTypeRef, stayIdRef, mailContentObjRef } = useContext(MessageDetailViewContext);

    const currency = pmSettingsObj!.property_currency;

    const [isVisibleMailCreationView, setIsVisibleMailCreationView] = useState(false);

    const messageRef = useRef('');

    let allSum = (amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr) + 
                    amountOfPriceListArr(PriceListType.AdditionalService, TaxType.Tax, priceListArr) + 
                    amountOfPriceListArr(PriceListType.CityTax, TaxType.Tax, priceListArr)) - 
                    amountOfPaymentListArr(paymentArr);

    if (stay.stay_storno_val !== 0) {

        allSum = stay.stay_storno_val - amountOfPaymentListArr(paymentArr);

    }

    const { mutate: postDeleteFolderFilesMutation } = useMutation({
        mutationFn: () => deleteAllFiles(),
        onSuccess: () => {

            fetchPrefilledTemplateByTypeMutation();

        },
    })

    const { mutate: fetchPrefilledTemplateByTypeMutation } = useMutation({
        mutationFn: () => fetchPrefilledTemplateByType(stay.stay_id, 9),
        onSuccess: (data) => {

            templatesArrRef.current = data.obj ? data.obj : [];
            conversationTypeRef.current = 9;
            stayIdRef.current = stay.stay_id;

            const contactArr: Array<Contact> = JSON.parse( stay.guest_contact ? stay.guest_contact : '[]' );

            const mails = contactArr.filter( contact => contact.contact_key === 'mail' ).map( contact => {

                return contact.contact_value;

            } )

            mailContentObjRef.current.to = mails;
            mailContentObjRef.current.body = messageRef.current;

            setIsVisibleMailCreationView(true);

        },
    })

    const handleSendMailOnClick = (obj: PaymentObj) => {

        messageRef.current = `${obj.art} von ${obj.price.toFixed(2)} ${obj.currency} dankend erhalten.`;

        postDeleteFolderFilesMutation();

    }

    return ( 

        <>
        <div className="flex center-y w100prc" style={{fontSize: '1.2em', padding: '15px 0'}}>Zahlungen</div>

        { paymentArr.map( (obj, i) => {

        const dString = getDateObj( obj.date, 0).dateStringDE;

        const topLblText = `${obj.art} geleistet am ${dString} per ${obj.paymentMethod}`;

            return(

                <div key={i} className="flex center-y w100prc standard-fontsize" style={{padding: '0 5px'}}>
                    <div className="flex center clickable-icon" style={{width: '25px'}}>edit_calendar</div>
                    <div className="flex center-y" style={{width: '70%'}}>{topLblText}</div>
                    <div className="flex" style={{width: '30%', justifyContent: 'right',}}>{`${obj.price.toFixed(2)} ${obj.currency}`}</div>
                    <div onClick={ () => handleSendMailOnClick(obj) } className="flex center clickable-icon" style={{ width: '30px'}} title='Zahlungsbenachrichtigung senden'>mail</div>
                </div>

            );

            })

        }

        <div className="flex center-y w100prc standard-fontsize" style={{padding: '10px 5px'}}>
            <div className="flex center-y" style={{width: '70%', fontWeight: 'bold'}}>zu zahlender Betrag</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right', paddingRight: '30px'}}>{`${allSum.toFixed(2)} ${currency}`}</div>
        </div>
        { isVisibleMailCreationView && createPortal( <MailCreationBackView style={{ width: '800px', height: '880px', maxHeight: 'none' }} handleClose={ () => setIsVisibleMailCreationView(false) } />, document.body ) }
        </>
     );
}
 
export default memo(StayPaymentView);