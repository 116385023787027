import { DefaultFetchResult } from "./types";

export const fetchUserData = async () => {

    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=select',
        {
        method: 'GET', 
       'credentials': 'include',
        }
      )
  
    const content: DefaultFetchResult = await result.json();
    return content;
  
}

export const postUpdateUserName = async (title: number, firstName: string, lastName: string, company: string) => {

    const data = new URLSearchParams();
  
    data.append('user_title', title.toString());
    data.append('user_firstname', firstName);
    data.append('user_lastname', lastName);
    data.append('user_company', company);
  
      const result = await fetch(
          `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=update_name`,
          {
          method: "POST", 
          body: data,
          'credentials': 'include',
          }
        )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postUpdateUserMail = async (email: string) => {

  const data = new URLSearchParams();

  data.append('user_email', email);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=update_mail`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateUserMobile = async (mobile: string) => {

  const data = new URLSearchParams();

  data.append('user_mobile', mobile);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=update_mobile`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateUserPassword = async (passwordOld: string, passwordNew: string, passwordCheck: string) => {

  const data = new URLSearchParams();

  data.append('user_password_old', passwordOld);
  data.append('user_password1', passwordNew);
  data.append('user_password2', passwordCheck);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=update_password`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateUserAddress = async (userAddress: string) => {

  const data = new URLSearchParams();

  data.append('user_address', userAddress);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=update_address`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteUserAccount = async () => {

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=delete_account`,
        {
        method: "GET",
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const fetchStoreData = async () => {

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=select_store`,
      {
      method: "GET",
      'credentials': 'include',
      }
    )

const content: DefaultFetchResult = await result.json();

return content;

}

export const fetchPayment = async () => {

  const result = await fetch(
    `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=user&do=select_payment`,
      {
      method: "GET",
      'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}