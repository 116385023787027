import { useMutation } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { createPortal } from "react-dom";
import { StayConversationContext } from "../../contexts/StayConversationContext";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import { fetchPrefilledTemplateByType } from "../../methods/http.templates.methods";
import EditPdfView from "./EditPdfView";
import TunePdfView from "./TunePdfView";
import { fetchPdfDesignByType } from "../../methods/http.pdf.methods";
import { Contact, ConversationViewType } from "../../methods/types";
import { deleteAllFiles } from "../../methods/http.korres.methods";
import { MessageDetailViewContext } from "../../contexts/MessageDetailViewContext";
import { truncateString } from "../../methods/standard.methods";

type Props = {
    handleClose: () => void;
}

const PdfView = ( { handleClose }:Props ) => {

    const { stay } = useContext(StayDetailViewContext);
    const { conversationTypeRef, conversationTypeStringForTitleRef, pdfUrl, templatesArrRef, pdfDesignObjRef, setConversationViewType, invoiceNumberRef } = useContext(StayConversationContext);
    const messageContext = useContext(MessageDetailViewContext);

    const [isVisiblePdfEditView, setIsVisiblePdfEditView] = useState(false);
    const [isVisibleTunePdfView, setIsVisibleTunePdfView] = useState(false);

    const { mutate: fetchPrefilledTemplateByTypeMutation } = useMutation({
        mutationFn: (newMail: boolean) => fetchPrefilledTemplateByType(stay.stay_id, conversationTypeRef.current),
        onSuccess: (data, variables) => {

            templatesArrRef.current = data.obj ? data.obj : [];
            messageContext.templatesArrRef.current = templatesArrRef.current.filter( obj => obj.template_type === 0 );
            messageContext.conversationTypeRef.current = conversationTypeRef.current;
            messageContext.invoiceNumberRef.current = invoiceNumberRef.current;
            messageContext.stayIdRef.current = stay.stay_id;
            
            if (variables) {

                const contactArr: Array<Contact> = JSON.parse( stay.guest_contact ? stay.guest_contact : '[]' );

                const mails = contactArr.filter( contact => contact.contact_key === 'mail' ).map( contact => {

                    return contact.contact_value;

                } )

                messageContext.mailContentObjRef.current.to = mails;

                setConversationViewType(ConversationViewType.MailView);

            } else {
                setIsVisiblePdfEditView(true);
            }

        },
    })

    const { mutate: fetchPdfDesignByTypeMutation } = useMutation({
        mutationFn: () => fetchPdfDesignByType(conversationTypeRef.current),
        onSuccess: (data) => {

            pdfDesignObjRef.current = (data.obj && data.obj.length > 0 ) ? data.obj[0] : null;
            
            setIsVisibleTunePdfView(true);

        },
    })

    const handleOpenMailCreationView = () => {

        if (conversationTypeRef.current < 8) {
            fetchPrefilledTemplateByTypeMutation(true);
        } else {
            postDeleteFolderFilesMutation();
        }

    }

    const { mutate: postDeleteFolderFilesMutation } = useMutation({
        mutationFn: () => deleteAllFiles(),
        onSuccess: () => {

            fetchPrefilledTemplateByTypeMutation(true);

        },
    })

    return ( 

        <>
        <div className="navigation-view-bar standard-fontsize">
            <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '60px' }}>cancel</div>
            { conversationTypeRef.current < 6 && <div onClick={ () => fetchPrefilledTemplateByTypeMutation(false) } className="clickable-icon flex center" style={{ fontSize: '1.5em', width: '60px' }}>edit</div> }
            <div onClick={ () => fetchPdfDesignByTypeMutation() } className="clickable-icon flex center" style={{ fontSize: '1.5em', width: '60px' }}>tune</div>
            <div className="main-title" style={{paddingRight: '60px'}}>{ truncateString( `Korrespondenz - ${conversationTypeStringForTitleRef.current}`, window.innerWidth > 759 ? 200 : 22 ) }</div>
            <div onClick={ handleOpenMailCreationView } className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '30px' }}>task</div>
        </div>
        <iframe className="pdf-iframe" src={`https://eazyac-dev.de/user_data/${pdfUrl.url}`}></iframe>
        { isVisiblePdfEditView && createPortal( <EditPdfView handleClose={ () => setIsVisiblePdfEditView(false) } />, document.body ) }
        { isVisibleTunePdfView && createPortal( <TunePdfView handleClose={ () => setIsVisibleTunePdfView(false) } />, document.body ) }
        </>
     );
}
 
export default PdfView;