import { useContext, useId, useRef, useState } from "react";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { AlertObj, DateObj, PriceObj } from "../../../methods/types";
import { createPortal } from "react-dom";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import InputView from "../../../generalComps/InputView";
import DateSelect, { DateSelectFormat, DateSelectType } from "../../../generalComps/dateSelectComps/DateSelect";
import { getDateObj } from "../../../methods/standard.methods";
import { PropertysContext } from "../../../contexts/PropertysContext";
import { useMutation } from "@tanstack/react-query";
import { postAddPrice, postUpdatePrice } from "../../../methods/http.price.methods";

type Props = {
    handleCancel: () => void;
    handleSave: (type: string, newPriceId?: number | undefined) => void;
}

const PriceAddEditView = ( { handleCancel, handleSave }:Props ) => {

    const { selectedPrice, setSelectedPrice } = useContext(PropertysViewContext);
    const { propertys } = useContext(PropertysContext);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputPriceValueRef = useRef<HTMLInputElement | null>(null);
    const inputFromValueRef = useRef<HTMLInputElement | null>(null);
    const inputToValueRef = useRef<HTMLInputElement | null>(null);
    const inputAdditionalPriceValueRef = useRef<HTMLInputElement | null>(null);

    const handleSelectedDateObj = (e: React.SyntheticEvent, dateSelectType: DateSelectType, dateObj: DateObj) => {

        if (dateSelectType === DateSelectType.CheckIn) {
            
            if (dateObj.date.getTime() >= getDateObj( selectedPrice.price_end!, 0 ).date.getTime()) {
                setSelectedPrice( prev => ( {...prev, price_start: dateObj.dateStringUS, price_end: getDateObj(dateObj.dateStringUS, 1).dateStringUS } ) );
            } else {
                setSelectedPrice( prev => ( {...prev, price_start: dateObj.dateStringUS } ) );
            }

        } else {
            
            if (dateObj.date.getTime() <= getDateObj( selectedPrice.price_start!, 0 ).date.getTime()) {
                setSelectedPrice( prev => ( {...prev, price_end: dateObj.dateStringUS, price_start: getDateObj(dateObj.dateStringUS, -1).dateStringUS } ) );
            } else {
                setSelectedPrice( prev => ( {...prev, price_end: dateObj.dateStringUS } ) );
            }

        }

        e.stopPropagation();

    }

    const handlePriceValueChange = () => {

        if ( Number(inputPriceValueRef.current!.value) <= 0) {

            inputPriceValueRef.current!.value = '0';

        }

    }

    const handleFromValueChange = () => {

        if ( Number(inputFromValueRef.current!.value) <= 0) {

            inputFromValueRef.current!.value = '0';

        }

        checkFromToValues();

    }

    const handleToValueChange = () => {

        if ( Number(inputToValueRef.current!.value) <= 0) {

            inputToValueRef.current!.value = '0';

        }

        checkFromToValues();

    }

    const checkFromToValues = () => {

        if ( Number(inputFromValueRef.current!.value) > Number(inputToValueRef.current!.value) && Number(inputToValueRef.current!.value) !== 0) {
            inputFromValueRef.current!.value = inputToValueRef.current!.value;
        }

    }

    const handleAdditionalPriceValueChange = () => {

        if ( Number(inputAdditionalPriceValueRef.current!.value) <= 0) {

            inputAdditionalPriceValueRef.current!.value = '0';

        }

    }

    const handleToggleClick = () => {

        setSelectedPrice( prev => ( {...prev, price_value: Number(inputPriceValueRef.current!.value),
                                                price_additional_value: Number(inputAdditionalPriceValueRef.current!.value),
                                                price_from: Number(inputFromValueRef.current!.value),
                                                price_to: Number(inputToValueRef.current!.value),
                                                price_additional: prev.price_additional === 0 ? 1 : 0 } ) );

    }

    const handleSaveOnClick = () => {
        
        if ( Number(inputPriceValueRef.current!.value) === 0 ) {
        
            setAlertObj( new AlertObj( true, 'Achtung', `Bitte trage einen Preis größer 0.00 ${propertys.currencyShort} ein.`, standardAlertButton ) );
            
        } else if ( selectedPrice.price_additional === 1 && Number(inputAdditionalPriceValueRef.current!.value) === 0 ) {
    
            setAlertObj( new AlertObj( true, 'Achtung', `Bitte trage einen Aufpreis größer 0.00 ${propertys.currencyShort} ein.`, standardAlertButton ) );
            
        } else {

            const newPrice: PriceObj = { ...selectedPrice,
                                            price_value: Number(inputPriceValueRef.current!.value),
                                            price_additional_value: Number(inputAdditionalPriceValueRef.current!.value),
                                            price_from: Number(inputFromValueRef.current!.value),
                                            price_to: Number(inputToValueRef.current!.value), };

            //newLandlordRef.current = newLandlord;

            if (newPrice.price_id === -1) {
                postAddPriceMutation(newPrice);
            } else {
                postUpdatePriceMutation(newPrice);
            }

        }

    }

    const { mutate: postAddPriceMutation } = useMutation({
        mutationFn: (newPrice: PriceObj) => postAddPrice( newPrice ),
        onSuccess: (data) => {

            setSelectedPrice( prev => ( {...prev, price_id: Number(data.price_id!) } ));

            handleSave('new', Number(data.price_id!));

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdatePriceMutation } = useMutation({
        mutationFn: (newPrice: PriceObj) => postUpdatePrice( newPrice ),
        onSuccess: () => {

            handleSave('update');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    return ( 

        <>
        <ModalView modalViewId="priceAddEditView" style={{ width: '550px', overflow: 'visible' }} handleClose={ handleCancel }>
            <div className="navigation-view-bar">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">{ selectedPrice.price_id === -1 ? 'Neuer Preis' : 'Preis bearbeiten' }</div>
                <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <div className="shadowBox color-lightblack" style={{ marginTop: '10px' }}>
                Gebe hier einen Zeitraum, den Preis und die gültige Personenzahl an.<br/><br/>
                Beispiel Personenanzahl: Personen 0 - 4 berechnet diesen Preis bei einem Aufenthalt BIS 4 Personen. Dagegen wird Personen 1 - 0 den Preis AB einer Person anwenden. Personen 2 - 4 hingegen grenzt den Preis nur für diesen Bereich ein.<br/><br/>
                Wenn du einen Wert bei Personen - Bis eingetragen hast und den Aufpreis aktivierst, so wird der dort festgelegte Betrag dem Preis aufgeschlagen, sobald der Wert in Personen - Bis überschritten wird.
            </div>
            <div className="date-wrapper">
                <DateSelect dateSelectType={DateSelectType.CheckIn} dateSelectFormat={DateSelectFormat.Calendar} startDateObj={getDateObj(selectedPrice.price_start!, 0)} endDateObj={getDateObj(selectedPrice.price_end!, 0)} handleSelectedDateObj={ handleSelectedDateObj }/>
                <DateSelect dateSelectType={DateSelectType.CheckOut} dateSelectFormat={DateSelectFormat.Calendar} startDateObj={getDateObj(selectedPrice.price_start!, 0)} endDateObj={getDateObj(selectedPrice.price_end!, 0)} handleSelectedDateObj={ handleSelectedDateObj }/>
            </div>
            <InputView title="Preis pro Nacht" style={{ marginTop: '10px' }}><input ref={inputPriceValueRef} onChange={ handlePriceValueChange } type="number" defaultValue={ selectedPrice.price_value } placeholder="z. B. 100" style={{ textAlign: 'center' }}/></InputView>
            <div className="flex center w100prc" style={{ gap: '10px', marginTop: '10px' }}>
                <InputView title="Personen - Ab"><input ref={inputFromValueRef} onChange={ handleFromValueChange } type="number" defaultValue={ selectedPrice.price_from } placeholder="z. B. 1" style={{ textAlign: 'center' }} /></InputView>
                <InputView title="Personen - Bis"><input ref={inputToValueRef} onChange={ handleToValueChange } type="number" defaultValue={ selectedPrice.price_to } placeholder="z. B. 4" style={{ textAlign: 'center' }} /></InputView>
            </div>
            <InputView title="Aufpreis für jede weitere Person" style={{ marginTop: '10px' }}>
                <div onClick={ handleToggleClick } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.2em', right: '0', top: '3px' }}>{ selectedPrice.price_additional === 0 ? 'toggle_off' : 'toggle_on' }</div>
                <input ref={inputAdditionalPriceValueRef} onChange={ handleAdditionalPriceValueChange } type="number" defaultValue={ selectedPrice.price_additional_value } placeholder="z. B. 5" disabled={ selectedPrice.price_additional === 0 ? true : false } style={{ textAlign: 'center' }} />
            </InputView>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default PriceAddEditView;