import { DefaultFetchResult, ZkObj } from "./types";

export const fetchBankAccounts = async () => {

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zk&do=select_all`,
      {
      method: "GET", 
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postAddBankAccount = async (bankAcc: ZkObj) => {

  const data = new URLSearchParams();
 
  data.append('zk_name', bankAcc.zk_name);
  data.append('zk_iban', bankAcc.zk_iban);
  data.append('zk_bic', bankAcc.zk_bic);
  data.append('zk_paypal', bankAcc.zk_paypal!);
  
  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zk&do=insert`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateBankAccount = async (bankAcc: ZkObj) => {

  const data = new URLSearchParams();
  
  data.append('zk_id', bankAcc.zk_id.toString());
  data.append('zk_name', bankAcc.zk_name);
  data.append('zk_iban', bankAcc.zk_iban);
  data.append('zk_bic', bankAcc.zk_bic);
  data.append('zk_paypal', bankAcc.zk_paypal!);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zk&do=update`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteBankAccount = async (bankAccId: number) => {

  const data = new URLSearchParams();

  data.append('zk_id', bankAccId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zk&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

