import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, ReactNode, useState, useContext, memo, useMemo, useEffect, useRef } from "react";
import { emptyInvoicePriceObj, emptyPaymentDataObj, emptyProperty, emptyStay } from "../methods/constants";
import { fetchSpaTaxById } from "../methods/http.spatax.methods";
import { postUpdateStaySingleValue } from "../methods/http.stays.methods";
import { amountOfPriceListArr, getInvoiceData } from "../methods/price.calculation.methods";
import { initSpatax } from "../methods/spa.calculation.methods";
import { daysBetweenDates, generateAbzIdsString, generateKtIdsString, generateZlIdsString, getDateObj } from "../methods/standard.methods";
import { AbzObj, InvoiceObj, KtObj, PaymentAmountType, PaymentMethod, PaymentObj, PaymentSelectionType, PriceListObj, PriceListType, PriceObj, Property, PropertyPaymentObj, Stay, TaxType, ViewState, ZlObj } from "../methods/types";
import { PropertysContext } from "./PropertysContext";
import { fetchDiscountsById } from "../methods/http.discount.methods";
import { fetchAddiServiceById } from "../methods/http.addiservice.methods";
import StayAddEditView from "../stayComps/StayAddEditView";
import { fetchPricesById } from "../methods/http.price.methods";

export type PaymentDataObj = {
    viewTitle: {title: string, artikel: string};
    amountType: PaymentAmountType;
    amount: number;
    paymentMethod: PaymentMethod;
    stayStatus: number;
}

export type InvoicePriceObj = {

    fullAmount: number;
    netAmount: number;
    nightsOnlyAmount: number;
    addNightsOnlyAmount: number;
    additionalServiceAmount: number;
    additionalServiceAmountNoTax: number;
    cityTaxAmount: number;
    cityTaxAmountNoTax: number;
    depositAmount: number;
    cancellationFeeAmount: number;

}

type Props = {
    children: ReactNode;
}

type ContextType = {stay: Stay,
    setStay: React.Dispatch<React.SetStateAction<Stay>>,
    selectedAddEditStay: Stay,
    setSelectedAddEditStay: React.Dispatch<React.SetStateAction<Stay>>,
    selectedAddEditProperty: Property,
    setSelectedAddEditProperty: React.Dispatch<React.SetStateAction<Property>>,
    property: Property,
    fullArr: Array<InvoiceObj>,
    priceArr: Array<InvoiceObj>,
    taxArr: Array<InvoiceObj>,
    zlArr: Array<ZlObj>,
    ktArr: Array<KtObj>,
    mAbzArr: Array<AbzObj>, 
    priceListArr: Array<PriceListObj>,
    paymentArr: Array<PaymentObj>,
    pmSettingsObj: PropertyPaymentObj | null,
    paymentViewType: PaymentSelectionType,
    setPaymentViewType: React.Dispatch<React.SetStateAction<PaymentSelectionType>>,
    paymentDataObj: PaymentDataObj,
    setPaymentDataObj: React.Dispatch<React.SetStateAction<PaymentDataObj>>,
    invoicePriceObj: InvoicePriceObj,
    setInvoicePriceObj: React.Dispatch<React.SetStateAction<InvoicePriceObj>>,
    isOldPriceListArr: () => boolean,
    // calculatedPriceObj: {stayPrice: number, currency: string, priceListArrString: string},
    // setCalculatedPriceObj: React.Dispatch<React.SetStateAction<{stayPrice: number, currency: string, priceListArrString: string}>>
    handleSelectedStay: {state: ViewState, stay: Stay},
    setHandleSelectedStay:  React.Dispatch<React.SetStateAction<{state: ViewState, stay: Stay}>>,
    calculateStayPrice: () => { stayPrice: number, currency: string, priceListArrString: string }, };

export const StayDetailViewContext = createContext<ContextType>( {
    stay: emptyStay,
    setStay: ()=>{}, 
    selectedAddEditStay: emptyStay, 
    setSelectedAddEditStay: ()=>{},
    selectedAddEditProperty: emptyProperty, 
    setSelectedAddEditProperty: ()=>{}, 
    property: emptyProperty, 
    fullArr: Array<InvoiceObj>(), 
    priceArr: Array<InvoiceObj>(), 
    taxArr: Array<InvoiceObj>(), 
    zlArr: Array<ZlObj>(), 
    mAbzArr: Array<AbzObj>(), 
    ktArr: Array<KtObj>(), 
    priceListArr: Array<PriceListObj>(), 
    paymentArr: Array<PaymentObj>(), 
    pmSettingsObj: null, 
    paymentViewType: PaymentSelectionType.PaymentTypeView, 
    setPaymentViewType: () => PaymentSelectionType.AmountTypeView, 
    paymentDataObj: emptyPaymentDataObj, 
    setPaymentDataObj: () => {}, invoicePriceObj: emptyInvoicePriceObj, 
    setInvoicePriceObj: () => {},
    isOldPriceListArr: () => { return true; },
    // calculatedPriceObj: { stayPrice: 0, currency: '', priceListArrString: '' },
    // setCalculatedPriceObj: () => {},
    handleSelectedStay: { state: ViewState.New, stay: emptyStay},
    setHandleSelectedStay: () => {},
    calculateStayPrice: () => { return { stayPrice: 0, currency: '', priceListArrString: '' } }, });

const StayDetailViewContextProvider = ({ children }: Props) => {

    const queryClient = useQueryClient();

    const [stay, setStay] = useState(emptyStay);
    const [selectedAddEditStay, setSelectedAddEditStay] = useState(emptyStay);
    const [selectedAddEditProperty, setSelectedAddEditProperty] = useState(emptyProperty);
    const [paymentViewType, setPaymentViewType] = useState(PaymentSelectionType.PaymentTypeView);
    const [paymentDataObj, setPaymentDataObj] = useState(emptyPaymentDataObj);
    const [invoicePriceObj, setInvoicePriceObj] = useState(emptyInvoicePriceObj);
    const [ktArr, setKtArr] = useState(Array<KtObj>());
    const [zlArr, setZlArr] = useState(Array<ZlObj>());
    const [mAbzArr, setMabzArr] = useState<Array<AbzObj>>([]);
    //const [calculatedPriceObj, setCalculatedPriceObj ] = useState<{stayPrice: number, currency: string, priceListArrString: string}>( { stayPrice: 0, currency: '', priceListArrString: '' } );
    const [handleSelectedStay, setHandleSelectedStay] = useState( {state: ViewState.New, stay: emptyStay } );

    const nights = useMemo( () => daysBetweenDates( getDateObj( stay.stay_checkIn, 0).date, getDateObj( stay.stay_checkOut, 0).date ), [stay.stay_checkIn, stay.stay_checkOut] );
    
    const {propertys} = useContext(PropertysContext);
    const currency = propertys.currencyShort;

    const foundProperty: Property | undefined = propertys.array.find( prop => { return prop.property_id === stay.stay_property } );
    const property = foundProperty === undefined ? emptyProperty : foundProperty;
    const pmSettingsObj: PropertyPaymentObj = JSON.parse( property.property_pm ? property.property_pm : '[]' );

    const priceListArr: Array<PriceListObj> = JSON.parse( ( stay.stay_priceList && stay.stay_priceList !== 'null' && stay.stay_priceList !== 'undefined' ) ? stay.stay_priceList! : '[]' );
    const paymentArr: Array<PaymentObj> = JSON.parse( stay.stay_paymentList ? stay.stay_paymentList! : '[]' );

    const [fullArr, priceArr, taxArr] = useMemo( () => getInvoiceData(priceListArr ? priceListArr : []), [priceListArr] );

    const invoiceListString = JSON.stringify( fullArr );

    const priceIdArr: Array<{price_id: number}> = useMemo( () => selectedAddEditProperty.property_price ? JSON.parse( selectedAddEditProperty.property_price! ) : [], [selectedAddEditProperty.property_price] );

    const fetchedPricesRef = useRef<Array<PriceObj>>([]);

    const isOldPriceListArr = useMemo( () => ():boolean => {

        const obj = priceListArr.find( entry => entry.art === 0);

        if (obj) {

            return obj.originalPrice ? false : true;

        } else {
            return true;
        }

    }, [priceListArr] );
    
    //const abzIdString = generateAbzIdsString( property.property_abz ? property.property_abz : '[]');

    useEffect( 
        () => { 

            const sumZL = amountOfPriceListArr(PriceListType.AdditionalService, TaxType.NoTax, priceListArr);
            const sumZLForPayment = amountOfPriceListArr(PriceListType.AdditionalService, TaxType.Tax, priceListArr);

            const sumKT = amountOfPriceListArr(PriceListType.CityTax, TaxType.NoTax, priceListArr);
            const sumKtForPayment =amountOfPriceListArr(PriceListType.CityTax, TaxType.Tax, priceListArr);

            const netPrice = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.NoTax, priceListArr) + sumZL + sumKT;
            const allSum = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr) +
                            amountOfPriceListArr(PriceListType.AdditionalService, TaxType.Tax, priceListArr) +
                            amountOfPriceListArr(PriceListType.CityTax, TaxType.Tax, priceListArr);

            const priceNightsOnly = priceArr[0] ? ( (100 + priceArr[0].taxPercent) * priceArr[0].sum ) / 100 : 0;
            const additionalNightsPriceOnly = priceArr[1] ? ( (100 + priceArr[1].taxPercent) * priceArr[1].sum ) / 100 : 0;

            let depositPrice = 0;

            if( pmSettingsObj!.property_deposit ) {

                if (!pmSettingsObj!.property_deposit_pauschal){
        
                    depositPrice = (pmSettingsObj!.property_deposit_percent * allSum) / 100;
        
                    if (depositPrice != 0 && (depositPrice < pmSettingsObj!.property_deposit_min)) {
                        depositPrice = pmSettingsObj!.property_deposit_min;
                    } 
        
                } else {
        
                    depositPrice = pmSettingsObj!.property_deposit_pauschal_value;
        
                }
        
            }
            
            setInvoicePriceObj({fullAmount: allSum, netAmount: netPrice, nightsOnlyAmount: priceNightsOnly, addNightsOnlyAmount: additionalNightsPriceOnly, additionalServiceAmountNoTax: sumZL, additionalServiceAmount: sumZLForPayment, cityTaxAmountNoTax: sumKT, cityTaxAmount: sumKtForPayment, depositAmount: depositPrice, cancellationFeeAmount: stay.stay_storno_val}); 
        
        },
        [stay.stay_id, stay.stay_priceList, stay.stay_storno_val]
    );

    const { mutate: postNewPriceListArrMutation } = useMutation( {

        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, 'stay_priceList', stay.stay_priceList!),

    } );

    useEffect( () => { 
        
        postNewPriceListArrMutation();

    }, [stay.stay_priceList]);

    const { mutate: postNewInvoiceListArrMutation } = useMutation( {

        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, 'stay_invoiceList', invoiceListString),

    } );

    useEffect( () => { 
        
        if (stay.stay_invoiceList! !== invoiceListString) {
            postNewInvoiceListArrMutation();
        }
    
    }, [stay.stay_invoiceList!, stay.stay_id]);

    const { mutate: postNewPaymentListArrMutation } = useMutation( {

        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, 'stay_paymentList', stay.stay_paymentList!.toString()),

    } );

    useEffect( () => { 
        
        postNewPaymentListArrMutation();

    }, [stay.stay_paymentList!, stay.stay_id]);

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['fetchStays'] });
    }, [stay.stay_priceList, invoiceListString, stay.stay_paymentList])

    const { mutate: fetchSpaTaxByIdMutation } = useMutation( {

        mutationFn: () => fetchSpaTaxById(generateKtIdsString( stay.property_kt! )),
        onSuccess: (data) => {

            const tempKtArr = data.obj;

            setKtArr( tempKtArr )

            const checkKT = priceListArr.filter((obj) => {
                return obj.art === 2;
            });
        
            if (checkKT.length === 0) {
                const tempPriceListArr = initSpatax(tempKtArr, stay.stay_checkIn, nights, stay.stay_adults, stay.stay_children, currency);
   
                setStay( prevStay => ({ ...prevStay, stay_priceList: JSON.stringify( priceListArr.concat( tempPriceListArr! ) ) }) );
                
            }

        },

    } );

    // useEffect( () => { 
        
    //     if ( stay.property_kt !== undefined ) {
    //         fetchSpaTaxByIdMutation();
    //     }

    // }, [stay.stay_id]);

    const { mutate: fetchAddiServiceByIdMutation } = useMutation( {

        mutationFn: () => fetchAddiServiceById(generateZlIdsString( stay.property_zl! )),
        onSuccess: (data) => {

            setZlArr(data.obj!);

        },

    } );

    useEffect( () => { 
        
        if ( stay.property_kt !== undefined ) {
            fetchSpaTaxByIdMutation();
        }

        if ( stay.property_zl !== undefined ) {
            fetchAddiServiceByIdMutation();
        }

    }, [stay.stay_id]);

    const { mutate: fetchDiscountsByIdMutation } = useMutation( {

        mutationFn: () => fetchDiscountsById(generateAbzIdsString( property.property_abz ? property.property_abz : '[]')),
        onSuccess: (data) => {

            const tempArr: Array<AbzObj> = [{abz_id: -1, abz_name: 'Standardpreis', abz_json: ''}];

            setMabzArr( tempArr.concat(data.obj) );

        },

    } );

    useEffect( () => { 
        
        if ( property.property_abz !== undefined ) {
            fetchDiscountsByIdMutation();
        }

    }, [property.property_abz]);

    const { mutate: fetchPricesByIdMutation } = useMutation( {
        mutationFn: () => fetchPricesById( priceIdArr.map( (obj:{price_id: Number}) => obj.price_id ).join(',') ),
        onSuccess: (data) => {

            if (data.success) {
                fetchedPricesRef.current = data.obj;
            }
            
        },
        onError: (error) => console.log(error),
    } );

    useEffect( () => {

        if ( priceIdArr.length !== 0 ) {
            fetchPricesByIdMutation();
        }

    }, [selectedAddEditProperty]);

    const calculateStayPrice = useMemo( () => (): { stayPrice: number, currency: string, priceListArrString: string } => {

        let price = 0;
        let currency = '';

        const specialPrice = selectedAddEditStay.stay_special_price ? selectedAddEditStay.stay_special_price : 0;
        const persons = selectedAddEditStay.stay_adults! + selectedAddEditStay.stay_children!;
        const days = daysBetweenDates(getDateObj(selectedAddEditStay.stay_checkIn!, 0).date, getDateObj(selectedAddEditStay.stay_checkOut!, 0).date);
    
        const priceListArr: Array<PriceListObj> = JSON.parse( selectedAddEditStay.stay_priceList ? selectedAddEditStay.stay_priceList : '[]' );
        let priceListArrString = '[]';

        if (persons > 0) {

            if (specialPrice === 0 && selectedAddEditStay.stay_priceList === '[]') {
                
                const property_pm: PropertyPaymentObj = JSON.parse( selectedAddEditProperty.property_pm ? selectedAddEditProperty.property_pm! : '[]' ); //Payment Details

                currency = property_pm.property_currency;

                fetchedPricesRef.current.forEach(priceObj => {
                
                    const startD = new Date( priceObj.price_start! );
                    startD.setHours(0,0,0,0);

                    const endD = new Date( priceObj.price_end! );
                    endD.setHours(0,0,0,0);

                    for (let i = 0; i < days; i++) { 
                        
                        const dd = getDateObj( selectedAddEditStay.stay_checkIn!, i).date;

                        if (dd.getTime() >= startD.getTime() && dd.getTime() <= endD.getTime()) {
                            
                            let taxVal = 0;

                            if (property_pm.property_tax) {

                                taxVal = (property_pm.property_tax_percent * priceObj.price_value) / (100 + property_pm.property_tax_percent);

                            }

                            const priceListObj = {date: dd, price: priceObj.price_value, originalPrice: priceObj.price_value, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: false, persons: 0, art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
                            priceListArr.push(priceListObj);
                                
                            //}

                            taxVal = 0;

                            //----für jede zusätzliche Person wird der additionalPrice aufgeschlagen----//
                            if (persons > priceObj.price_to && priceObj.price_additional) {

                                if (property_pm.property_tax) {

                                    taxVal = (property_pm.property_tax_percent * (priceObj.price_additional_value * (persons - priceObj.price_to))) / (100 + property_pm.property_tax_percent);

                                }
                                
                                const price = priceObj.price_additional_value * (persons - priceObj.price_to);

                                const priceListObj = {date: dd, price: price, originalPrice: price, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: true, persons: (persons - priceObj.price_to), art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
                                priceListArr.push(priceListObj);

                            }

                        }

                    }

                });

                priceListArrString = JSON.stringify( priceListArr );
                price = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr);

        
            } else if (specialPrice !== 0) {
                
                const spDayPrice = specialPrice / days;
    
                if (selectedAddEditProperty.property_id !== -1) {
    
                    const property_pm: PropertyPaymentObj = JSON.parse( selectedAddEditProperty.property_pm ? selectedAddEditProperty.property_pm! : '[]' ); //Payment Details
    
                    currency = property_pm.property_currency;
    
                    for (let i = 1; i <= days; i++) {
    
                        const dd = getDateObj( selectedAddEditStay.stay_checkIn!, i).date;
        
                        let taxVal = 0;
        
                        if (property_pm.property_tax) {
        
                            taxVal = (property_pm.property_tax_percent * spDayPrice) / (100 + property_pm.property_tax_percent);
        
                        }
        
                        const priceListObj = {date: dd, price: spDayPrice, originalPrice: spDayPrice, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: false, persons: 0, art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
                        priceListArr.push(priceListObj);
        
                    }
    
                }
    
                priceListArrString = '[]';
                price = specialPrice;
        
            } else if (selectedAddEditStay.stay_priceList !== '[]') {
                
                if (selectedAddEditProperty.property_id !== -1) {
    
                    const property_pm: PropertyPaymentObj = JSON.parse( selectedAddEditProperty.property_pm ? selectedAddEditProperty.property_pm! : '[]' ); //Payment Details
    
                    currency = property_pm.property_currency;
    
                }
    
                priceListArrString = JSON.stringify( priceListArr );
                price = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr );
    
            }
    
        }

        return { stayPrice: price, currency: currency, priceListArrString: priceListArrString };

    }, [selectedAddEditStay.stay_checkIn, selectedAddEditStay.stay_checkOut, selectedAddEditStay.stay_adults, selectedAddEditStay.stay_children, selectedAddEditStay.stay_special_price, selectedAddEditStay.stay_priceList, selectedAddEditProperty] );

    return ( 
        <StayDetailViewContext.Provider value={{
                                                stay, 
                                                setStay, 
                                                selectedAddEditStay, 
                                                setSelectedAddEditStay,
                                                selectedAddEditProperty,
                                                setSelectedAddEditProperty, 
                                                property, 
                                                fullArr, 
                                                priceArr, 
                                                taxArr, 
                                                zlArr, 
                                                ktArr, 
                                                mAbzArr, 
                                                priceListArr, 
                                                paymentArr, 
                                                pmSettingsObj, 
                                                paymentViewType, 
                                                setPaymentViewType, 
                                                paymentDataObj, 
                                                setPaymentDataObj, 
                                                invoicePriceObj, 
                                                setInvoicePriceObj, 
                                                isOldPriceListArr,
                                                // calculatedPriceObj,
                                                // setCalculatedPriceObj,
                                                handleSelectedStay,
                                                setHandleSelectedStay,
                                                calculateStayPrice,

        }}>
            {children}
        </StayDetailViewContext.Provider>
     );
}
 
export default memo(StayDetailViewContextProvider);