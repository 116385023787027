export const validatePassword = (val: string) => {
        
    let filter: RegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
        
    return filter.test(val);
        
}

export const validateMail = (val: string) => {
        
    const filter: RegExp = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    
    return filter.test(val);
        
}

export const validatePhone = (val: string) => {
        
    const filter: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
        
    return filter.test(val);
        
}


