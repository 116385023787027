import { CSSProperties, ReactElement, useContext, useState } from "react";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import ImageView from "../../generalComps/ImageView";
import LandlordListView from "./LandlordListView";
import { createPortal } from "react-dom";

type Props = {
    style?: CSSProperties | undefined;
}

const Landlord = ( { style }: Props ) => {

    const { selectedProperty } = useContext(PropertysViewContext);

    const [isVisibleLandlordListView, setIsVisibleLandlordListView] = useState(false);

    let title = 'VERMIETER';

    if (selectedProperty.vermieter_id) {

        if (selectedProperty.vermieter_title == 'Familie') {
            title = `${selectedProperty.vermieter_title} ${selectedProperty.vermieter_lastname}`;
        } else {
            title = `${selectedProperty.vermieter_title} ${selectedProperty.vermieter_firstname} ${selectedProperty.vermieter_lastname}`;
        }

    }

    const LandlordDetailView = (): ReactElement => {

        const imageUrl = selectedProperty.user_folder === undefined ? '' : `https://eazyac-dev.de/user_data/${selectedProperty.user_folder!}/vermieter_images/${selectedProperty.vermieter_id!}.png`;

        return(

            <>
            <div className="flex center h100prc" style={{width: '50px', marginRight: '10px'}}><ImageView src={imageUrl} width={50} height={50} alt="Vermieter" /></div>
            <div className="flex h100prc standard-fontsize" style={{flexDirection: 'column', padding: '0 0px', width: '40%'}}>
                <div className="flex center-y">{ selectedProperty.vermieter_street }</div>
                <div className="flex center-y">{ selectedProperty.vermieter_zip } { selectedProperty.vermieter_city }</div>
                <div className="flex center-y color-lightblack">{ selectedProperty.vermieter_country }</div>
            </div>
            </>

        );

    }

    return ( 

        <>
        <div className="flex column shadowBox" style={style}>
            <div className="flex center-y w100prc">
                <div className="flex w100prc" style={{fontSize: selectedProperty.vermieter_id ? '1.2em' : '1.0em'}}>{ title }</div>
                <div onClick={ () => setIsVisibleLandlordListView(true) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>menu_open</div>
            </div>
            <div className="flex center-y w100prc" style={{marginTop: '10px'}}>
                { selectedProperty.vermieter_id ?
                    <LandlordDetailView />
                    :
                    <div className="flex center w100prc color-lightblack">Kein Vermieter zugewiesen</div>
                }
            </div>
        </div>
        { isVisibleLandlordListView && createPortal( <LandlordListView handleClose={ () => setIsVisibleLandlordListView(false) } />, document.body ) }
        </>

     );
}
 
export default Landlord;