import { ReactElement, useEffect, useId, useState } from "react";
import { HandoverProtocolObj, ViewState } from "../../methods/types";
import ImageView from "../../generalComps/ImageView";
import { useMutation } from "@tanstack/react-query";
import { fetchHandoverImageBlob } from "../../methods/http.blob.methods";

type Props = {
    handover: HandoverProtocolObj;
    handleOnClick: (state: ViewState) => void;
}

const HandoverCard = ( { handover, handleOnClick }: Props ) => {

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const [imageUrl, setImageUrl] = useState('ef_std_img.jpg');

    const { mutate: fetchImageBlobMutation } = useMutation({
        mutationFn: () => fetchHandoverImageBlob(handover.img),
        onSuccess: (data) => {

            const blob: {blob_data: string, creation_timestamp: string} = data.obj;

            if (blob.blob_data) {

                if ( blob.blob_data.substring(0, 4) === 'data' ) {
                    setImageUrl( blob.blob_data );
                } else if( blob.blob_data.substring(0, 5) === '/user' ) {
                    setImageUrl( `https://eazyac-dev.de${blob.blob_data}` );
                }
        
            }

        },
        onError: (error) => { console.log(error) },
    });

    useEffect( fetchImageBlobMutation, []);

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(

            <>
            <div className="flex center-y w100prc h100prc">
                <div className="flex center h100prc" style={{width: '60px', marginRight: '10px'}}><ImageView src={ imageUrl } width={60} height={60} alt="HandoverImage" /></div>
                <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 0px', width: '40%'}}>
                    <div className="flex center-y">{ handover.text1.length === 0 ? 'Kein Titel vergeben' : handover.text1 }</div>
                    <div className="flex center-y">{ handover.text2.length === 0 ? 'Kein Untertitel vergeben' : handover.text2 }</div>
                    <div className="flex center-y color-lightblack">Anzahl: { handover.amount }</div>
                </div>
            </div>
            </>
            
        );
    }

    return ( 

        <div onClick={ handleMenu } className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: isVisibleMenu ? '20px' : '10px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>

     );
}
 
export default HandoverCard;