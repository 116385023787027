import { getDateObj } from "./standard.methods";
import { KtObj, PriceListObj } from "./types";

export const initSpatax = (ktArr: Array<KtObj>, ciDateString: string | undefined = undefined, days = 0, adults = 0, children = 0, currency = '') => {

    if (!ciDateString) {
        return;
    }

    let priceListArr = Array<PriceListObj>();

    const adultArr = spataxArray(ktArr, 0, ciDateString, days, currency); //Erwachsene    

    for (let i = 0; i < adults; i++) {

        adultArr.forEach(obj => {
            
            const cloneObj = Object.assign({}, obj);
            cloneObj.uid = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
            priceListArr.push(cloneObj);

        });
        

    }
    
    const childrenArr = spataxArray(ktArr, 1, ciDateString, days, currency); //Kinder

    for (let i = 0; i < children; i++) {
        
        childrenArr.forEach(obj => {
            
            const cloneObj = Object.assign({}, obj);
            cloneObj.uid = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));
            priceListArr.push(cloneObj);

        });

    }

    const defaultArr = spataxOldArray(ktArr, days, currency); //Alte Kurtaxe-Einträge

    if (defaultArr.length > 0){
        priceListArr = priceListArr.concat(defaultArr);
    }

    return priceListArr;

}

export const spataxArray = (ktArr: Array<KtObj>, ktArt: number, ciDateString: string, days: number, currency: string) => {

    //const ciDate = new Date( new Date(ciDateString).setHours(0,0,0,0) );
    //const ciDate = getDateObj( ciDateString, 0 ).date;
    
    let tempArr = Array<PriceListObj>();
    let priceListObj: PriceListObj = {price: 0, taxPercent: 0, taxVal: 0, text: '', amount: 0, ktID: undefined, art: 2, kt_art: ktArt, active: true, uid: undefined};

    let nights = 0;
    let price = 0;
    let textString = '';

    let taxVal = 0;

    let preKtId: number | undefined = undefined;

    for (let i = 0; i < days; i++) { 
                    
        const checkingDateTime = getDateObj( ciDateString, i).date.getTime();

        const ktObj = ktArr.find(kt => {

            const startDate = new Date( new Date(kt.kt_start!).setHours(0,0,0,0) );
            const endDate = new Date( new Date(kt.kt_end!).setHours(0,0,0,0) );

            return kt.kt_art == ktArt && checkingDateTime >= startDate.getTime() && checkingDateTime <= endDate.getTime();

        });
        
        if (ktObj) {

            if (preKtId && preKtId != ktObj.kt_id) {

                if (priceListObj.text!.length > 0) {
                    tempArr.push(priceListObj);
                }

                nights = 0;
                price = 0;
                textString = '';

                taxVal = 0;

                priceListObj = {price: 0, taxPercent: 0, taxVal: 0, text: '', amount: 0, ktID: undefined, art: 2, kt_art: ktObj.kt_art, active: true, uid: undefined};

            }
            
            ++nights;

            if (ktObj.kt_tax) {

                taxVal = (ktObj.kt_tax_percent * ( ktObj.kt_price * nights ) ) / (100 + ktObj.kt_tax_percent);

            }

            price = ( ktObj.kt_price * nights ) /*- adultTaxVal*/;

            taxVal = Number( taxVal.toFixed(2) );
            price = Number( price.toFixed(2) );

            //textString = ktObj.kt_name + ': ' + price + ' ' + pm.property_currency + ' (' + nights + ' ' + day + ' x ' + (price / nights).toFixed(2) + ' ' + pm.property_currency + ')';
            textString = ktObj.kt_name + ': {price} ' + currency + ' ({nights} x {dayPrice} ' + currency + ')';

            priceListObj.price = price;
            priceListObj.taxPercent = ktObj.kt_tax_percent;
            priceListObj.taxVal = taxVal;
            priceListObj.text = textString;
            priceListObj.amount = nights;
            priceListObj.ktID = ktObj.kt_id;
            priceListObj.kt_art = ktObj.kt_art;

            preKtId = ktObj.kt_id;

        }

    }

    if (priceListObj.text!.length > 0) {
        tempArr.push(priceListObj);
    }

    return tempArr;

}

export const spataxOldArray = (ktArr: Array<KtObj>, nights: number, currency: string) => {

    let tempArr = Array<PriceListObj>();

    const oldKtArr = ktArr.filter(kt => {

        return kt.kt_art == null && kt.kt_start == null && kt.kt_end == null;

    });

    oldKtArr.forEach(ktObj => {
        
        let price = ktObj.kt_price * nights;

        price = Number( price.toFixed(2) );

        //textString = ktObj.kt_name + ': ' + price + ' ' + pm.property_currency + ' (' + nights + ' ' + day + ' x ' + (price / nights).toFixed(2) + ' ' + pm.property_currency + ')';
        const textString = ktObj.kt_name + ': {price} ' + currency + ' ({nights} x {dayPrice} ' + currency + ')';

        const uid = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()));

        const priceListObj = {price: price, taxPercent: 0, taxVal: 0, text: textString, amount: nights, ktID: ktObj.kt_id, art: 2, kt_art: 2, active: false, uid: uid};

        tempArr.push(priceListObj);

    });

    return tempArr;

}

export const replacePlaceholderKurtaxeText = (text: string, price: number, nights: number, ktArt: number) => {

    let day = 'Tage';

    if (nights === 1) {
        day = 'Tag';
    }

    let ktText = text;

    if (ktArt == 2) {
        ktText = ktText.split(':')[1];
    }

    ktText = ktText.replace('{price}', price.toFixed(2));
    ktText = ktText.replace('{nights}', nights + ' ' + day);
    ktText = ktText.replace('{dayPrice}', (price / nights).toFixed(2));

    return ktText;

}