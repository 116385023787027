import InputView from "../generalComps/InputView";
import SalutationSelect from "../generalComps/SalutationSelect";
import { setCss } from "../methods/animation.methods";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import { validateMail, validatePassword } from "../methods/validation.methods";
import { LoginResult, postRegister } from "../methods/http.auth.methods";
import { AlertObj } from "../methods/types";
import ModalView from "../generalComps/ModalView";

interface Props {
    handleCloseModalView: () => void
}

const Register = ({handleCloseModalView}:Props) => {

    const navigate = useNavigate();

    const [alertObj, setAlertObj] = useState(new AlertObj());

    const [selectedItem, setSelectedItem] = useState(0);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState('');

    const [registerResult, setRegisterResult] = useState(new LoginResult());

    const handleCancelClick = (buttonHandler: () => void) => {
        setCss("0");
    
        buttonHandler();
      };

    const handleRegistration = () => {

      if (firstName.length <= 1 || lastName.length <= 1) {

        setAlertObj( new AlertObj(true, 'Unvollständiger Name', 'Bitte fülle Vor- und Nachname aus.') );
    
      } else {

        if (!validateMail(email)) {

          setAlertObj( new AlertObj(true, 'E-Mail-Adresse ungültig', 'Bitte prüfe die Länge und/oder das Format deiner E-Mail-Adresse.') );

        } else if (!validatePassword(pwd)) {

          setAlertObj( new AlertObj(true, 'Passwort ungültig', 'Passwort entspricht nicht den Mindestangaben: mind. 8 Zeichen | mind. 1 Zahl | mind. 1 Großbuchstabe | mind. 1 Kleinbuchstabe.') );

        } else {

          postRegister(selectedItem, firstName, lastName, email, pwd)
          .then((content) => {
            
            setRegisterResult(content);

            if (!content.login_success) {

              setAlertObj( new AlertObj(true, content.response_title, content.response_text) );

            } else {

              navigate('/eazyac/registrierung');

            }

          });

        }

      }

    }

    return ( 
      <>
      <ModalView modalViewId='registerModalView' style={{ width: "600px", maxHeight: "600px", overflowX: "hidden" }} handleClose={handleCloseModalView}>
        <div className="flex-container center-y" style={{ width: "100%" }}>
          <div onClick={() => handleCancelClick(handleCloseModalView)} className="clickable-icon" style={{ width: "100%", fontSize: '1.5em' }}>cancel</div>
          <div onClick={handleRegistration} className="clickable-icon" style={{ width: "100%", textAlign: "right", fontSize: '1.5em' }}>check_circle</div>
        </div>

        <div className="flex-container center color-cyan" style={{width: '100%', fontSize: '1.5rem'}}>Bei Eazyac registrieren</div>
        
        <SalutationSelect selection={["Herr", "Frau"]} selectedItem={selectedItem} handleSelection={(index) => setSelectedItem(index)} />

        <div className="flex-container center" style={{width: '100%', gap: '10px'}}>
          <InputView title="Vorname" style={{ marginTop: '10px' }} ><input onChange={(e) => setFirstName(e.target.value)} type="text" name="firstname" placeholder="Vorname" value={firstName}/></InputView>
          <InputView title="Nachname" style={{ marginTop: '10px' }} ><input onChange={(e) => setLastName(e.target.value)} type="text" name="lastname" placeholder="Nachname" value={lastName}/></InputView>
        </div>

        <InputView title="E-Mail-Adresse" style={{ marginTop: '20px' }} ><input onChange={(e) => setEmail(e.target.value)} type="text" name="email" placeholder="E-Mail-Adresse" value={email}/></InputView>
        <InputView title="Passwort" style={{ marginTop: '10px' }} ><input onChange={(e) => setPwd(e.target.value)} type="password" name="pwd" placeholder="Passwort" value={pwd}/></InputView>

        <div className="shadowBox wrap" style={{width: '98%', marginTop: '30px', marginBottom: '20px', marginLeft: '1%'}}>
        Mit deiner Anmeldung erklärst du dich mit unseren Allgemeinen Geschäftsbedingungen einverstanden. Bitte lese unsere Datenschutzerklärung und unseren Hinweis zu Cookies.
        
        <div className="flex-container center" style={{width: '100%', padding: '10px 0 0 0'}}>
          <Link className="flex-container center color-lightblack" style={{width: '100%', textDecoration: 'none'}} target="_blank" to="/agb">Allgemeinen Geschäftsbedingungen</Link> 
          <Link className="flex-container center color-lightblack" style={{width: '100%', textDecoration: 'none'}} target="_blank" to="/datenschutz">Datenschutzerklärung</Link>
        </div>

        </div>
      </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId='registerActionAlert' title = {alertObj.title} message = {alertObj.msg} buttons = {[{title: 'Ok', handler: () => setAlertObj(new AlertObj()) }]} handleClose={() => setAlertObj(new AlertObj())}/>, document.body ) }
        </>
     );
}
 
export default Register;