import { useEffect, useRef, useState } from "react";
import ImageSelectionView from "../../generalComps/ImageSelectionView";
import { ExposeImgCellObj, ExposeObj } from "../../methods/types";

type Props = {
    image: ExposeObj;
    handleNewImage: (imgData: string) => void;
    handleDeleteClick: () => void;
    handleSaveTitle: (newTitle: string) => void;
}

const ExposeImageCard = ( { image, handleNewImage, handleSaveTitle, handleDeleteClick }:Props ) => {

    const inputTitleRef = useRef<HTMLInputElement>(null);

    const parsedJson: ExposeImgCellObj = JSON.parse( image.ex_json );

    const [isEditable, setIsEditable] = useState(false);

    const originalTitle = parsedJson.img_title;

    let urlString = 'ef_std_img.jpg';

    if (image.ex_img) {

        if ( image.ex_img.substring(0, 4) === 'data' ) {
            urlString = image.ex_img;
        } else if( image.ex_img.substring(0, 5) === '/user' ) {
            urlString = `https://eazyac-dev.de${image.ex_img}`;
        }

    }

    const handleEditSaveClick = () => {
        
        const newTitle = inputTitleRef.current!.value;

        setIsEditable( prev => !prev );

        if (isEditable && newTitle !== originalTitle) {
            handleSaveTitle(newTitle);
        }

    }

    useEffect( () => inputTitleRef.current!.focus(), [isEditable]);

    return ( 
        <div className="flex center column" style={{ minWidth: '180px', height: '100%', overflowX: 'clip', border: '1px solid var(--line-color)', borderRadius: '5px' }}>
            <ImageSelectionView handleImgData={ handleNewImage } urlContentString={ urlString } style={ { width: '100%', height: '70%', overflow: 'clip', border: 'none', borderRadius: '0' } }/>
           <div className="flex center w100prc" style={{ marginTop: '20px', padding: '0 5px' }}>
                <input className="w100prc" style={{ padding: '5px', border: '1px solid var(--line-color)', borderRadius: '5px', marginRight: '5px'  }} ref={inputTitleRef} type="text" defaultValue={ parsedJson.img_title } placeholder="z. B. Schlafzimmer" disabled={ !isEditable } />
                <div onClick={ handleEditSaveClick } className="flex center clickable-icon h100prc" style={{ width: '25px', fontSize: '1.3em' }}>{ isEditable ? 'save' : 'edit' }</div>
                <div onClick={ handleDeleteClick } className="flex center clickable-icon h100prc" style={{ width: '25px', fontSize: '1.3em' }}>delete</div>
           </div>
        </div>
     );
}
 
export default ExposeImageCard;