import '../css/propertys.css';
import SearchBar from "../generalComps/SearchBar";
import { useContext, useDeferredValue, useEffect, useId, useState } from "react";
import { AlertObj, InfoWidgetObj, ViewState, Property, PropertyListType, ViewComponent } from "../methods/types";
import InfoWidget from "../generalComps/InfoWidget";
import ActionAlert from "../generalComps/ActionAlert";
import { emptyProperty } from "../methods/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createPortal } from "react-dom";
import PropertysList from "./PropertysList";
import { fetchProperty, postDeleteProperty, postUpdatePropertySingleValue } from "../methods/http.property.methods";
import { PropertysViewContext } from "../contexts/PropertysMainContext";
import PropertyBackview from "./PropertyBackview";
import PropertyAddEditView from "./PropertyAddEditView";
import BigAddIcon from "../generalComps/BigAddIcon";
import PropertyDetailView from "./PropertyDetailView";
import { PropertysContext } from "../contexts/PropertysContext";

const Propertys = () => {

    const { reloadPropertys } = useContext(PropertysContext);

    useEffect( () => { 
        
        reloadPropertys(); 

        window.document.title = 'Eazyac Immobilien';
    
    }, []);

    const { propertyListType, setSelectedProperty } = useContext(PropertysViewContext);

    const queryClient = useQueryClient();

    const searchBarHeight = '150px';

    const actionAlertId = useId();

    const [isVisiblePropertyBackview, setIsVisiblePropertyBackview] = useState( {isVisible: false, state: ViewState.New} );
    const [searchString, setSearchString] = useState('');
    const deferredSearchString = useDeferredValue(searchString);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const handleAddProperty = () => {

        setSelectedProperty( emptyProperty );
        setIsVisiblePropertyBackview( {isVisible: true, state: ViewState.New} );

    }

    const handleSelectedProperty = (state: ViewState, property: Property) => {

        setIsVisiblePropertyBackview( {isVisible: false, state: ViewState.New} );
        setSelectedProperty( property );

        setTimeout(() => {
            
            if (state === ViewState.Edit) {

                setIsVisiblePropertyBackview( {isVisible: true, state: state} );
    
            } else if (state === ViewState.Details) {

                fetchPropertyMutation(property.property_id);
    
            } else if (state === ViewState.Delete) {

                const alertButtons = [ {title: 'Löschen', handler: () => postDeletePropertyMutation(property.property_id) }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

                setAlertObj( new AlertObj( true, 'Immobilie löschen?', `Soll die Immobilie '${property.property_name}' wirklich gelöscht werden?`, alertButtons ) );

            } else if (state === ViewState.ActivateDeactivate) {
                
                let title = 'Deaktivieren';
                let alertText = 'Soll die Immobilie "' + property.property_name + '" wirklich deaktiviert werden?';
                let field_value = 0;

                if ( propertyListType === PropertyListType.Deactivated ) {

                    title = 'Aktivieren';
                    alertText = 'Soll die Immobilie "' + property.property_name + '" wieder aktiviert werden?';
                    field_value = 1;

                }

                const alertButtons = [ {title: title, handler: () => postUpdatePropertySingleMutation( {property: property, isActive: field_value} ) }, { ...standardAlertButton[0], title: 'Abbrechen' } ];

                setAlertObj( new AlertObj( true, `${title}?`, alertText, alertButtons ) );

            } 

        }, 100);

    }

    const { mutate: fetchPropertyMutation } = useMutation({
        mutationFn: (propertyId: number) => fetchProperty(propertyId),
        onSuccess: (data) => {

            setSelectedProperty( data.obj )

            setIsVisiblePropertyBackview( {isVisible: true, state: ViewState.Details} );

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: (givenData: { property: Property, isActive: number }) => postUpdatePropertySingleValue(givenData.property.property_id, 'property_isActive', givenData.isActive.toString()),
        onSuccess: (_, variables) => {

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
                setInfoWidgetObj( new InfoWidgetObj( true, `${variables.property.property_name} deaktiviert.` ) );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
                setInfoWidgetObj( new InfoWidgetObj( true, `${variables.property.property_name} aktiviert.` ) );
            }

            setAlertObj( new AlertObj() );

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postDeletePropertyMutation } = useMutation({
        mutationFn: (propertyId: number) => postDeleteProperty(propertyId),
        onSuccess: () => {

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

            setAlertObj( new AlertObj() );

            setInfoWidgetObj( new InfoWidgetObj( true, 'Immobilie wurde gelöscht.' ) );

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSaveProperty = (type: string) => {

        setIsVisiblePropertyBackview( {isVisible: false, state: ViewState.New} );
        setSelectedProperty( emptyProperty );

        setInfoWidgetObj( new InfoWidgetObj(true, `Immobilie erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

    }

    const handleCancelAddEditView = () => {

        setSelectedProperty( emptyProperty );
        setIsVisiblePropertyBackview( {isVisible: false, state: ViewState.New} );

    }

    return ( 

        <div className="content-backview">
            <div className="list-column">
                <div className="flex-container center w100prc" style={{ overflow: 'visible', position: 'relative', height: searchBarHeight, padding: '10px', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px', }}>
                    <SearchBar iconName1="list_alt_add" title="Immobilien" iconName2="" iconName3="" inputPlaceholder="Suche" searchString={searchString} setSearchString={ (searchString) => setSearchString(searchString)} handleClick1={ handleAddProperty } handleClick2={() => {}} handleClick3={() => {}}/>
                </div>
                <div className="flex-container center-y column w100prc" style={{ padding: '10px 10px 10px 10px', height: `calc(100% - ${searchBarHeight})`, justifyContent: 'flex-start', overflow: 'visible', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px' }}>
                    <PropertysList searchString={deferredSearchString} handleSelectedProperty={handleSelectedProperty}/>
                </div>
            </div>
            {
                window.innerWidth > 759 ?

                <div className="flex-container center-y h100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', width: 'calc(100% - 420px)', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px', }}>
                    { isVisiblePropertyBackview.isVisible ? 
                        <PropertyBackview style={{height: 'auto', width: isVisiblePropertyBackview.state === ViewState.Details ? '60%' : '90%', overflowY: 'visible'}}>
                            { (isVisiblePropertyBackview.state === ViewState.New || isVisiblePropertyBackview.state === ViewState.Edit) ? 
                                <PropertyAddEditView handleCancel={handleCancelAddEditView} handleSave={handleSaveProperty}/>
                            : 
                            <PropertyDetailView handleCancel={() => setIsVisiblePropertyBackview({isVisible: false, state: ViewState.New})} />
                            }
                        </PropertyBackview> : <BigAddIcon viewComponent={ViewComponent.Property} handleAddClick={handleAddProperty} /> }
                </div>
                :
                isVisiblePropertyBackview.isVisible &&
                <div className="w100prc" style={{ position: 'absolute', height: 'calc(100% - 70px)', padding: '5px', backgroundColor: 'var(--bg-color-white)', overflowY: 'scroll' }}>
                    { (isVisiblePropertyBackview.state === ViewState.New || isVisiblePropertyBackview.state === ViewState.Edit) ? 
                        <PropertyAddEditView handleCancel={handleCancelAddEditView} handleSave={handleSaveProperty}/>
                    : 
                    <PropertyDetailView handleCancel={() => setIsVisiblePropertyBackview({isVisible: false, state: ViewState.New})} />
                    }
                </div>

            }
            { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body ) }
            { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </div>

     );
}
 
export default Propertys;