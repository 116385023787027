import { useContext, useMemo, useState } from "react";
import MultiCalendarStayDetailsView from "../multiCalendarComps/MultiCalendarStayDetailsView";
import SingleListCalendarPropertys from "./SingleListCalendarPropertys";
import SingleListCalendarDates from "./SingleListCalendarDates";
import { OccupancyModulContext } from "../../../contexts/OccupancyModulContext";
import ImageView from "../../../generalComps/ImageView";

const SingleListCalendar = () => {

    const { isVisibleStayDetailsView, filteredPropertys } = useContext(OccupancyModulContext);

    const property = useMemo( () => filteredPropertys[0], [filteredPropertys]);

    const imageUrl = useMemo( () => `https://eazyac-dev.de/user_data/${property.user_folder!}/prop_images/${property.property_id}.png`, [property.property_id]);

    const imageSize = 50;
    const fontSize = '1.0em';

    return ( 

            <>
            <div className="h100prc w100prc" style={{ display: 'block', marginTop: '10px', overflow: 'visible' }}>
                <div className="shadowBox center-y" style={{ position: 'relative', height: '80px', fontSize: '1.2em', overflow: 'visible', }}>
                    <ImageView src={imageUrl} width={ imageSize } height={ imageSize } alt={property.property_name} />
                    <div className="flex column w100prc" style={{ marginLeft: '10px', width: '70%', }}>
                        <div className="color-lightblack" style={{ fontSize: fontSize }}>{property.property_art}</div>
                        <div style={{ fontSize: fontSize }}>{property.property_name}</div>
                    </div>
                </div>
                <SingleListCalendarPropertys />
                <SingleListCalendarDates />
            </div>
            { isVisibleStayDetailsView && <MultiCalendarStayDetailsView /> }
            </>

     );
}
 
export default SingleListCalendar;