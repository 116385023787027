import { DefaultFetchResult, PriceObj } from "./types";

export const fetchPrices = async () => {

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=price&do=select_all`,
      {
      method: "GET", 
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const fetchPricesById = async (priceIds: string) => {

    const data = new URLSearchParams();
  
    data.append('price_ids', priceIds);
  
      const result = await fetch(
          `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=price&do=select_by_ids`,
          {
          method: "POST", 
          body: data,
          'credentials': 'include',
          }
        )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postAddPrice = async (price: PriceObj) => {

  const data = new URLSearchParams();
  
  data.append('price_value', price.price_value.toString());
  data.append('price_start', price.price_start!);
  data.append('price_end', price.price_end!);
  data.append('price_from', price.price_from.toString());
  data.append('price_to', price.price_to.toString());
  data.append('price_additional', price.price_additional.toString());
  data.append('price_additional_value', price.price_additional_value.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=price&do=insert`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdatePrice = async (price: PriceObj) => {

  const data = new URLSearchParams();
  
  data.append('price_id', price.price_id.toString());
  data.append('price_value', price.price_value.toString());
  data.append('price_start', price.price_start!);
  data.append('price_end', price.price_end!);
  data.append('price_from', price.price_from.toString());
  data.append('price_to', price.price_to.toString());
  data.append('price_additional', price.price_additional.toString());
  data.append('price_additional_value', price.price_additional_value.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=price&do=update`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeletePrice = async (priceId: number) => {

  const data = new URLSearchParams();

  data.append('price_id', priceId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=price&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}