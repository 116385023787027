import { ReactElement, SyntheticEvent, memo, useContext, useMemo, useRef } from "react";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import { DateObj, Stay } from "../../methods/types";
import { daysBetweenDates, getDateObj, initDateArray } from "../../methods/standard.methods";
import SingleListCalendarDates from "./SingleListCalendarDates";
import MultiCalendarBarCard from "../multiCalendarComps/MultiCalendarBarCard";

type Props = {
    index: number;
    startDateObj: DateObj;
    endDateObj: DateObj;
}

const SingleListCalendarDatesRow = ( { index, startDateObj, endDateObj }: Props ) => {

    const { filteredPropertys, stays, selectedStartDate, setSelectedStartDate, selectedEndDate, setSelectedEndDate, selectedDatesRow, setSelectedDatesRow } = useContext(OccupancyContext);

    const filteredStays = stays.filter( stay => stay.stay_property === filteredPropertys[0].property_id );

    const usedStays: Array<number> = [];

    const dates = useMemo( () => {

        let newStartDateObj = startDateObj;

        if ( startDateObj.date.getDay() !== 1 ) {

            let subDays = 0;

            if ( startDateObj.date.getDay() === 0 ) {

                subDays = 6;
                
            } else {

                subDays = startDateObj.date.getDay() - 1;

            }

            newStartDateObj = getDateObj( new Date( startDateObj.date.getFullYear(), startDateObj.date.getMonth(), startDateObj.date.getDate() - subDays ), 0 );

        }

        return initDateArray( newStartDateObj, endDateObj );

    }, [startDateObj, endDateObj]);

    const handleSelectedDate = (e: SyntheticEvent, selectedDate: DateObj) => {

        if ( selectedStartDate === null) {

            setSelectedStartDate( selectedDate );

        } else {

            setSelectedEndDate( selectedDate );
        }

        e.stopPropagation();

    }

    return ( 

        <div className="border-bottom-strong" style={{ overflowX: 'clip', borderBottomWidth: '6px', width: (dates.length * 60) + 'px', height: '51px', background: index % 2 === 0 ? 'var(--tile-bg-hover)' : 'var(--alert-btn-hover)' }}>
        {
            dates.map( date => {

                const filteredStaysByDate = filteredStays.filter( stay => stay.stay_checkIn === date.dateStringUS || stay.stay_checkOut === date.dateStringUS );

                let monthBlock: ReactElement | null = null;

                if ( date.dateStringUS === dates[0].dateStringUS ) {

                    const stay = filteredStays.find( stay => getDateObj( stay.stay_checkIn, 0 ).date.getTime() < date.date.getTime() && getDateObj( stay.stay_checkOut, 0 ).date.getTime() > dates[dates.length-1].date.getTime() );

                    if ( stay ) {
                        monthBlock = <MultiCalendarBarCard key={stay.stay_id} stay={stay} style={{ width: `${ ( dates.length + 2 ) * 60}px`, left: '-60px' }}/>
                    }

                }

                return(

                    <div key={date.dateStringUS} onClick={ (e) => handleSelectedDate(e, date) } className="flex center multi-calendar-dates-card" style={{ backgroundColor: ( date.dayStringShort === 'Sa' || date.dayStringShort === 'So' ) ? 'var(--date-weekend-bg)' : 'transparent' }}>
                        { ( selectedStartDate?.dateStringUS === date.dateStringUS || selectedEndDate?.dateStringUS === date.dateStringUS ) && 
                            <div className="flex center default-icon" style={{ color: date.dateStringUS === selectedStartDate?.dateStringUS ? 'green' : 'red' }}>{ date.dateStringUS === selectedStartDate?.dateStringUS ? 'chevron_right' : 'chevron_left' }</div>
                        }
                        { ( selectedStartDate !== null && selectedEndDate !== null && date.date.getTime() > selectedStartDate!.date.getTime() && date.date.getTime() < selectedEndDate!.date.getTime() ) && 
                            <div className="flex center default-icon">nights_stay</div>
                        }
                        { monthBlock }
                        {
                            filteredStaysByDate.map( stay => {

                                if ( !usedStays.find( stayId => stayId === stay.stay_id ) ) {

                                    const width = ( daysBetweenDates( getDateObj( stay.stay_checkIn, 0 ).date, getDateObj( stay.stay_checkOut, 0 ).date ) * 60 ) - 5;

                                    usedStays.push( stay.stay_id );
                                    
                                    return <MultiCalendarBarCard key={stay.stay_id} stay={stay} style={{ width: `${width}px`, left: stay.stay_checkOut === date.dateStringUS ? `-${width - 27.5}px` : '32.5px' }}/>;

                                } else {
                                    return null;
                                }

                            })
                        }
                    </div>

                )
    
            })
        }
        </div>

     );
}
 
export default memo(SingleListCalendarDatesRow);