import { useIsFetching } from "@tanstack/react-query";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "./generalComps/Footer";
import { createPortal } from "react-dom";
import LoadingIndicator from "./generalComps/LoadingIndicator";
import NavigationBarTop from "./generalComps/NavigationBarTop";
import { useEffect } from "react";

const Webseite = () => {

    const navigate = useNavigate();

    useEffect( () => { navigate('/start'); }, []);

    const isFetching = useIsFetching();
  
    return (
      <>
        <NavigationBarTop type="website"/>
        <div className="firstSector" style={{ paddingTop: '70px', overflow: 'scroll', }}>
            <Outlet />
        </div>
       <Footer />
       { isFetching ? createPortal( <LoadingIndicator />, document.body ) : '' }
      </>
    );
  }
  
  export default Webseite;