import { useState } from "react";
import { getDateObj } from "../methods/standard.methods";
import { GuestGroupObj, ViewState } from "../methods/types";

type Props = {
    personObj: GuestGroupObj;
    index: number;
    handleOnClick: (personObj: GuestGroupObj, state: ViewState) => void;
}

const StayGroupCard = ( { personObj, index, handleOnClick }:Props ) => {

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    let dataString = '';

    if (personObj.birthday.length > 0 && personObj.birthday != '0000-00-00') {
        dataString += ', geb: ' + getDateObj( personObj.birthday, 0).dateStringDE;
    }

    if (personObj.nationality.length > 0) {
        dataString += ', StA: ' + personObj.nationality;
    }

    if (personObj.identification.length > 0) {
        dataString += ', ID: ' + personObj.identification;
    }

    const Menu = (): React.ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, personObj, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '1.2rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, personObj, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '1.2rem' }}>delete</div>
        </div>

        );

    }

    const handleButtonClick = (e: React.SyntheticEvent, personObj: GuestGroupObj, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(personObj, state);

        e.stopPropagation();

    }

    return ( 

        <div className="shadowBox list-card" style={{ flexDirection: 'row' }}>
            { !isVisibleMenu ?
                <div className="w100prc">
                    <span className="color-lightblack" style={{ padding: '0 5px' }}>{`${++index}. ${personObj.title}`}</span>
                    {`${personObj.firstname} ${personObj.lastname}${dataString}`}
                </div>
                :
                <Menu />
            }
            <div onClick={() => setIsVisibleMenu( prevVal => !prevVal) } className="clickable-icon">more_horiz</div>
        </div>

     );
}
 
export default StayGroupCard;