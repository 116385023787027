import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext, useRef } from "react";
import {StayDetailViewContext} from "../../contexts/StayDetailViewContext";
import { timeSelectArr } from "../../methods/constants";
import { postUpdateStaySingleValue } from "../../methods/http.stays.methods";
import PopUp from "../PopUp";
import { DateSelectType } from "./DateSelect";
import { OccupancyContext } from "../../contexts/OccupancyContext";

type Props = {

    dateSelectType: DateSelectType;
    style?: React.CSSProperties | undefined;
    givenTime?: string;
    handleNewTimeValue?: (newTimeValue: string) => void;
    handleClosePopUp: () => void;

}

const TimeSelectPopUp = ( { dateSelectType, style, givenTime, handleNewTimeValue, handleClosePopUp }:Props ) => {

    const queryClient = useQueryClient();

    const { stay, setStay } = useContext(StayDetailViewContext);
    const { reloadStayData } = useContext(OccupancyContext);

    const checkInOutTime = givenTime === undefined ? dateSelectType === DateSelectType.CheckIn ? stay.stay_checkIn_time : stay.stay_checkOut_time : givenTime;

    const selectedTime = checkInOutTime ? checkInOutTime : '---';

    const timeValueRef = useRef('0');

    const { mutate: saveNewTimeMutation } = useMutation({
        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, dateSelectType === DateSelectType.CheckIn ? 'stay_checkIn_time' : 'stay_checkOut_time', timeValueRef.current),
        onSuccess: () => {

            setTimeout(() => {
                reloadStayData();
            }, 1000);
            
            queryClient.invalidateQueries( {queryKey: ['fetchStays']} );

            if (dateSelectType === DateSelectType.CheckIn) {
                setStay( prevStay => ({ ...prevStay, stay_checkIn_time: timeValueRef.current }));
            } else {
                setStay( prevStay => ({ ...prevStay, stay_checkOut_time: timeValueRef.current }));
            }
            
        },
    })

    const handleOnClick = (timeValue: string) => {

        timeValueRef.current = timeValue;

        if (handleNewTimeValue) {

            handleNewTimeValue(timeValue);

        } else {

            saveNewTimeMutation();

        }

    }

    return ( 
        
        <PopUp style={style} handleClosePopUp={handleClosePopUp}>
            {timeSelectArr.map((time) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => { handleOnClick(time) } }
                    key={time}
                >
                    <div className="lbl">{`${time} ${ time === '---' ? '' : ' Uhr' }`}</div>
                    {time === selectedTime && <div className="default-icon">check</div>}
                </div>
                );
            })}
        </PopUp>
     );
}
 
export default TimeSelectPopUp;