import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Property, PropertyListType, Stay, ViewState } from "../methods/types";
import { useState, useEffect, ReactElement, useContext } from "react";
import { fetchStaysAll } from "../methods/http.stays.methods";
import { fetchDeactivatedPropertys, fetchPropertys } from "../methods/http.property.methods";
import PropertyCard from "./PropertyCard";
import { PropertysViewContext } from "../contexts/PropertysMainContext";

type Props = {
    searchString: string;
    handleSelectedProperty: (state: ViewState, property: Property) => void;
}

const PropertysList = ( { searchString, handleSelectedProperty }: Props ) => {

    const { propertyListType, setPropertyListType } = useContext(PropertysViewContext);

    const [propertys, setPropertys] = useState<Array<Property>>([]);

    const fetchPropertysQuery = useQuery({
        queryKey: ["fetchPropertys"],
        queryFn: () => fetchPropertys(),
        refetchOnWindowFocus: false,
        enabled: propertyListType === PropertyListType.Activated,
    });

    const fetchDeactivatedPropertysQuery = useQuery({
        queryKey: ["fetchDeactivatedPropertys"],
        queryFn: () => fetchDeactivatedPropertys(),
        refetchOnWindowFocus: false,
        enabled: propertyListType === PropertyListType.Deactivated,
    });

    useEffect( () => {

        if ( propertyListType === PropertyListType.Activated && fetchPropertysQuery.isSuccess) {

            setPropertys( fetchPropertysQuery.data.obj ? fetchPropertysQuery.data.obj! : [] );
    
        } else if ( propertyListType === PropertyListType.Deactivated && fetchDeactivatedPropertysQuery.isSuccess) {
    
            setPropertys( fetchDeactivatedPropertysQuery.data.obj ? fetchDeactivatedPropertysQuery.data.obj! : []);
    
        }

    }, [propertyListType, fetchPropertysQuery.data?.obj, fetchDeactivatedPropertysQuery.data?.obj])

    const NoData = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Immobilien vorhanden</div>;
    }

    const filteredPropertys = propertys.filter( ( property ) => {

        if ( searchString.length > 0 ) {

            const propertyName = property.property_name ? property.property_name! : '';
            const propertyType = property.property_art ? property.property_art! : '';
            const propertyStreet = property.address_street ? property.address_street! : '';
            const propertyCity = property.address_city ? property.address_city! : '';
    
            return (propertyName.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || propertyType.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || propertyStreet.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || propertyCity.toLowerCase().indexOf(searchString.toLowerCase()) != -1);

        } else {

            return property;

        }

    });
    
    return (
        <>
        <div className="shadowBox w100prc">
            <div title="Aktiviert" onClick={ () => setPropertyListType( PropertyListType.Activated ) } className={ `clickable-icon flex center w100prc${ propertyListType === PropertyListType.Activated ? ' selected' : '' }` } style={{ fontSize: '1.5em' }}>visibility</div>
            <div title="Deaktiviert" onClick={ () => setPropertyListType( PropertyListType.Deactivated ) } className={ `clickable-icon flex center w100prc${ propertyListType === PropertyListType.Deactivated ? ' selected' : '' }` } style={{ fontSize: '1.5em' }}>visibility_off</div>
        </div>
        <div className="flex center w100prc h100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', overflowX: 'hidden', overflowY: 'scroll', padding: '3px' }}>
        { ( filteredPropertys.length === 0 && (fetchPropertysQuery.isFetching || fetchDeactivatedPropertysQuery.isFetching) ) &&  <div className="shadowBox flex center w100prc">Lade Immobilien...</div>}
        { ( filteredPropertys.length === 0 && (fetchPropertysQuery.isSuccess || fetchDeactivatedPropertysQuery.isSuccess) ) &&  <NoData /> }
        { filteredPropertys.map( ( property ) => {

            return <PropertyCard handleOnClick={ (state) => handleSelectedProperty(state, property) } key={property.property_id} property={property}/>;

            }) 
        }

        </div>
        </>

     );
}
 
export default PropertysList;