import { CSSProperties, SyntheticEvent, memo, useContext, useEffect, useMemo, useState } from "react";
import { OccupancyModulContext } from "../../../contexts/OccupancyModulContext";
import { StayDetailViewContext } from "../../../contexts/StayDetailViewContext";
import { getFullname } from "../../../methods/standard.methods";
import { booking_cell_color } from "../../../methods/constants";
import { Stay } from "../../../methods/types";

type Props = {
    stay: Stay;
    style: CSSProperties;
}

const MultiCalendarBarCard = ( { stay, style }:Props ) => {

    //const [isVisibleDetails, setIsVisibleDetails] = useState(false);
    const { isVisibleStayDetailsView, setIsVisibleStayDetailsView, stays } = useContext(OccupancyModulContext);
    const stayDetailViewContext = useContext(StayDetailViewContext);

    const fullnameObj = useMemo( () => getFullname( stay.guest_title!, stay.guest_firstname ? stay.guest_firstname : '', stay.guest_lastname ? stay.guest_lastname : '', stay.guest_company ? stay.guest_company : ''),
    [stay.guest_title, stay.guest_firstname, stay.guest_lastname, stay.guest_company] );

    const barTitle = useMemo( () => {

        if ( stay.stay_status === 5 ) {
            
            return stay.stay_block_title!; //Wenn Aufenthalt ein Block

        } else if ( stay.stay_status === 6 ) {
            
            return `${stay.ical_name!} -> ${stay.stay_block_title!}`; //Wenn Aufenthalt ein Import

        } else {
            return fullnameObj.fullname === ' Firma' ? fullnameObj.company : fullnameObj.fullname.replace('Frau ', '').replace('Herr ', '');
        }

    }, [stays])

    const handleOpenStay = (e: SyntheticEvent) => {

        if( stay.stay_status < 5 ) {

            stayDetailViewContext.setStay( stay ); 

            setIsVisibleStayDetailsView( true ); 

        }
        
        e.stopPropagation();

    }

    return ( 

        <>
        <div onClick={ handleOpenStay } title={barTitle} style={style} key={stay.stay_id} className={`bar${booking_cell_color[stay.stay_status]}${ ( isVisibleStayDetailsView && stay.stay_id === stayDetailViewContext.stay.stay_id ) ? ' clicked' : '' }`}>{ barTitle }</div>
        </>

     );
}
 
export default memo(MultiCalendarBarCard);