import { createPortal } from "react-dom";
import ModalView from "../generalComps/ModalView";
import { AlertObj, Contact, Guest, Note } from "../methods/types";
import { getFullname, truncateString } from "../methods/standard.methods";
import RateBar from "../generalComps/RateBar";
import { useId, useState } from "react";
import ActionAlert from "../generalComps/ActionAlert";
import { useNavigate } from "react-router-dom";

type Props = {
    guest: Guest;
    handleClose: () => void;
    style?: React.CSSProperties | undefined;
    handleEditClick: () => void;
}

const GuestDetailView = ( { guest, style, handleClose, handleEditClick }: Props ) => {

    const guestName = getFullname(guest.guest_title!, guest.guest_firstname!, guest.guest_lastname!, guest.guest_company!);
    const contactArr: Array<Contact> = JSON.parse( guest.guest_contact ? guest.guest_contact! : '[]' );
    const noteArr: Array<Note> = JSON.parse( guest.guest_note ? guest.guest_note! : '[]' );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();

    const navigate = useNavigate();

    let stay_amount_string = 'Diesem Gast ist kein Aufenthalt zugewiesen.';

    if (guest.guest_stay_amount! == 1) {

        stay_amount_string = 'Diesem Gast ist ' + guest.guest_stay_amount! + ' Aufenthalt zugewiesen.';

    } else if (guest.guest_stay_amount! > 1) {

        stay_amount_string = 'Diesem Gast sind ' + guest.guest_stay_amount! + ' Aufenthalte zugewiesen.';

    }

    const handleCNClick = (cnObj: {cnKey: string, cnType: string, cnValue: string, cnData: string }) => {

        const cancelButton = {title: 'Abbrechen', handler: () => setAlertObj(new AlertObj() )};

        if (cnObj.cnKey === 'phone') {
            
            const buttons = Array( {title: 'Anrufen', handler: () => {

                window.location.href=`tel:${cnObj.cnValue}`;

                setAlertObj( new AlertObj() );

            }},
            cancelButton, );

            setAlertObj( new AlertObj(true, 'Anruf', `Möchtest du ${cnObj.cnValue} anrufen?`, buttons) );

        } else if (cnObj.cnKey === 'mail') {
            
            const buttons = Array( {title: 'Schreiben', handler: () => {

                window.location.href=`mailto:${cnObj.cnValue}`;

                setAlertObj( new AlertObj() );

            }},
            cancelButton, );

            setAlertObj( new AlertObj(true, 'Schreiben', `Möchtest du ${cnObj.cnValue} schreiben?`, buttons) );

        } else if (cnObj.cnKey === 'attachment') {
            
            const buttons = Array( {title: 'Öffnen', handler: () => {

                const win = window.open();
                win!.document.write('<iframe src="' + cnObj.cnData  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');


                setAlertObj( new AlertObj() );

            }},
            cancelButton, );

            setAlertObj( new AlertObj(true, 'Öffnen', `Möchtest du die Anlage ${cnObj.cnValue} öffnen?`, buttons) );

        }

    }

    const handleStayClick = () => {

        navigate(`aufenthalte/${guestName.fullname}`);

        handleClose();

    }

    return ( 
        <>
        <ModalView modalViewId="guestDetailView"  style={style} handleClose={handleClose}>
            <div className="flex center w100prc">
                <div className="flex center w100prc border-bottom-strong" style={{ padding: '0 0 5px 0', fontSize: '1.2rem'}}>{truncateString( `${guest.guest_title!} ${guestName.fullname}`.toUpperCase(), 40 )}</div>
                <div onClick={handleEditClick} className="clickable-icon flex center" style={{ width: '40px' }}>edit</div>
            </div>
            <RateBar ratevalue={guest.guest_rate} style={{padding: '10px 0'}} isClickable={false}/>
            <div className="flex center w100prc color-lightblack">{guest.guest_title!}</div>
            <div className="flex center w100prc">{guestName.fullname}</div>
            <div className="flex center w100prc color-lightblack">{guestName.company}</div>
            <div className="flex center w100prc" style={{marginTop: '10px'}}>{guest.guest_street!}</div>
            <div className="flex center w100prc">{`${guest.guest_zip!} ${guest.guest_city!}`}</div>
            <div className="flex center w100prc">{guest.guest_country!}</div>

            { guest.guest_birthday && guest.guest_birthday !== '0000-00-00' && guest.guest_birthday !== '1500-01-01' && 
                <div className="flex center w100prc color-lightblack" style={{ marginTop: '10px' }}>
                    { new Date(guest.guest_birthday!).toLocaleDateString('de-DE') }
                    <div className="default-icon">cake</div>
                </div>
            }

            { contactArr.map( (contact, i) => { 
            
            return(
                <div onClick={() => handleCNClick({cnKey: contact.contact_key, cnType: contact.contact_art, cnValue: contact.contact_value, cnData: ''})} key={i} className="flex center cursor-pointer border-bottom-strong" style={{padding: '5px 0', marginTop: i === 0 ? '10px' : '0', width: '80%', marginLeft: '10%'}}>
                    <div className="flex center default-icon" style={{fontSize: '0.8rem', width: '40px'}}>{ contact.contact_key === 'phone' ? 'contact_phone' : 'contact_mail' }</div>
                    <div className="flex center color-lightblack" style={{ padding: '0 5px 0 0', fontSize: '0.8rem', width: '40%' }}>{ contact.contact_art }</div>
                    <div className="flex center" style={{ width: '60%', fontSize: '0.9rem' }}>{ contact.contact_value }</div>
                </div> 
            );
            
            } ) }

            { noteArr.map( (note, i) => { 
            
            return(
                <div onClick={() => handleCNClick({cnKey: note.note_key, cnType: note.note_art, cnValue: note.note_val, cnData: note.note_data})} key={i} className={`flex center border-bottom-strong${note.note_key === 'note' ? '' : ' cursor-pointer'}`} style={{padding: '5px 0', marginTop: ( contactArr.length === 0 && i === 0) ? '10px' : '0', width: '80%', marginLeft: '10%'}}>
                    <div className="flex center default-icon" style={{fontSize: '0.8rem', width: '40px'}}>{ note.note_key === 'note' ? 'short_text' : 'attachment' }</div>
                    <div className="flex center color-lightblack" style={{ padding: '0 5px 0 0', fontSize: '0.8rem', width: '40%' }}>{ note.note_art }</div>
                    <div className="flex center" style={{ width: '60%', fontSize: '0.9rem' }}>{ note.note_val }</div>
                </div> 
            );
            
            } ) }

            <div className="flex center w100prx color-lightblack" style={{ marginTop: '20px', padding: '5px 0'}}>
                {stay_amount_string}
                { guest.guest_stay_amount! > 0 && <div onClick={ handleStayClick } className="clickable-icon">launch</div> }
            </div>

        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object} handleClose={()=>{}} />, document.body ) }
        </>
     );
}
 
export default GuestDetailView;