import { ReactElement, useContext, useEffect, useMemo, useRef, useState } from "react";
import "../css/statistics.css";
import Card from "../generalComps/Card";
import { fetchStatisticsSmallData } from "../methods/http.statistics.methods";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Property } from "../methods/types";
import { daysBetweenDates } from "../methods/standard.methods";
import ModalView from "../generalComps/ModalView";
import { createPortal } from "react-dom";
import InputView from "../generalComps/InputView";
import { setCss } from "../methods/animation.methods";
import { PropertysContext } from "../contexts/PropertysContext";

interface Props {
  dateSpanObj: { propertyId: string, startMonth: string, endMonth: string, year: string };
}

const StatisticsBig = ({ dateSpanObj }: Props) => {

  const propertysContext = useContext(PropertysContext);
  const propertys = propertysContext.propertys.array;
  const currency = propertysContext.propertys.currencyShort;

  const year = new Date().getFullYear();

  const cleaningFeeRef = useRef<HTMLInputElement>(null);
  const [isVisibleFeeView, setIsVisibleFeeView] = useState(false);

  const propertyIds = useMemo( () => {

    if ( dateSpanObj.propertyId === "-1" ) {

      return JSON.stringify(propertys.map((prop) => prop.property_id));

    } else {

      return `[${dateSpanObj.propertyId}]`;

    }

  }, [dateSpanObj.propertyId]);

  const [tileValue3, setTileValue3] = useState("0");

  const startDate = new Date(year, Number(dateSpanObj.startMonth), 1, 0, 0, 0);
  const endDate = new Date(year, Number(dateSpanObj.endMonth), 0, 0, 0, 0);

  const days = useMemo( () => daysBetweenDates(startDate, endDate), [startDate, endDate]);

  const monthsCount = ( Number(dateSpanObj.endMonth) - Number(dateSpanObj.startMonth) ) + 1;

  const icon = (iconName1: string, iconName2: string): ReactElement => {
    if (iconName1.length > 0 && iconName2.length === 0) {
      return (
        <div
          className="default-icon"
          style={{ fontSize: "2.0rem", padding: "0", textAlign: "center" }}
        >
          {iconName1}
        </div>
      );
    } else if (iconName1.length > 0 && iconName2.length > 0) {
      return (
        <div className="flex-container center w100prc">
          <div
            className="default-icon"
            style={{ fontSize: "1.5rem", padding: "0", textAlign: "center" }}
          >
            {iconName1}
          </div>
          <div
            className="default-icon"
            style={{ fontSize: "1.5rem", padding: "0", textAlign: "center" }}
          >
            {iconName2}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  // const { data } = useQuery({
  //   queryKey: ["statisticsBigData", propertyIds, dateSpanObj],
  //   queryFn: () =>
  //     fetchStatisticsSmallData(propertyIds, dateSpanObj.startMonth, dateSpanObj.endMonth, dateSpanObj.year),
  //   enabled: !!propertyIds,
  //   refetchOnWindowFocus: false,
  // });

  // if (data) {
  //   if (data.guests != 0 && data.stays != 0) {
  //     setTileValue3( (Number(data.guests) / parseInt(data.stays)).toFixed(0) );
  //   }
  // }

  const { data, mutate: fetchStatisticsBigDataMutation } = useMutation( {

    mutationFn: () => fetchStatisticsSmallData( propertyIds, dateSpanObj.startMonth, dateSpanObj.endMonth, dateSpanObj.year ),
    onSuccess: (data) => {

      if (data) {
        if (data.guests != 0 && data.stays != 0) {
          setTileValue3( (Number(data.guests) / parseInt(data.stays)).toFixed(0) );
        }
      }

    },

  } );

  useEffect( fetchStatisticsBigDataMutation, [propertyIds, dateSpanObj] );

  const handleCancelCleaningFee = () => {

    setIsVisibleFeeView(false);

    setCss('0');

  }

  const handleSaveCleaningFee = () => {

    localStorage.cleaningfee = cleaningFeeRef.current ? cleaningFeeRef.current.value : 0;

    setIsVisibleFeeView(false);

    setCss('0');

  }

  const CleaningFeeValueView = (): ReactElement => {

    return(

        <ModalView modalViewId="cleaningFeeValueView" style={{ width: '500px', maxHeight: '300px', overflowX: 'hidden' }} handleClose={handleCancelCleaningFee}>
            <div className="flex-container center-y" style={{ width: "100%" }}>
                <div onClick={ handleCancelCleaningFee } className="clickable-icon" style={{ width: "100%", fontSize: '1.5em' }}>cancel</div>
                <div onClick={ handleSaveCleaningFee } className="clickable-icon" style={{ width: "100%", textAlign: "right", fontSize: '1.5em' }}>check_circle</div>
            </div>
            <div className="flex-container center color-cyan" style={{width: '100%', fontSize: '1.5rem'}}>Einstellungen Reinigungsaufwand</div>
            <div className="shadowBox" style={{ width: '98%', margin: '20px 0 20px 1%' }}>Gebe hier den genauen oder ungefähren Reinigungsbetrag für einen Aufenthalt ein, um einen Reinigungsaufwand für den gewählten Zeitraum zu errechnen.</div>
            <InputView title="Reinigungsbetrag" style={{ }}>
                <input ref={cleaningFeeRef} style={{ textAlign: 'center' }} type="number" defaultValue={ localStorage.cleaningfee ? localStorage.cleaningfee : 0 } placeholder="z. B. 50 EUR" />
            </InputView>
        </ModalView>

    );

  }

  return (
    <>
    <div className="shadowBox statistics-small w100prc">
      <div
        className="flex-container center-y w100prc"
        style={{ paddingBottom: "10px" }}
      >
        STATISTIK
      </div>
      <div
        className="flex-container center w100prc"
        style={{ gap: "10px", padding: "2px" }}
      >
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("door_front", "")}
          topTitle=""
          valueTitle={data ? data.stays.toString() : "0"}
          btmTitle={data && data.stays === 1 ? "Aufenthalt" : "Aufenthalte"}
          isClickable={false}
          isSelected={false}
        />
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("euro", "")}
          topTitle=""
          valueTitle={data ? (data?.revenue).toFixed(0) + " " + currency : "0"}
          btmTitle="Umsatz"
          isClickable={false}
          isSelected={false}
        />
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("groups", "")}
          topTitle=""
          valueTitle={tileValue3}
          btmTitle={`${
            data && data.guests === 1 ? "Gast" : "Gäste"
          } pro Aufenthalt ø`}
          isClickable={false}
          isSelected={false}
        />
      </div>
      <div
        className="flex-container center w100prc"
        style={{ marginTop: "10px", gap: "10px", padding: "2px" }}
      >
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("euro", "house")}
          topTitle=""
          valueTitle={`${
            data ? (data.revenue / propertys.length).toFixed(0) : "0"
          } ${currency}`}
          btmTitle="ø Umsatz pro Immobilie"
          isClickable={false}
          isSelected={false}
        />
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("euro", "calendar_month")}
          topTitle=""
          valueTitle={`${
            data ? (data.revenue / monthsCount).toFixed(0) : "0"
          } ${currency}`}
          btmTitle="ø Umsatz pro Monat"
          isClickable={false}
          isSelected={false}
        />
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("euro", "calendar_month")}
          topTitle=""
          valueTitle={`${
            data ? (data.revenue / days).toFixed(0) : "0"
          } ${currency}`}
          btmTitle="ø Umsatz pro Tag"
          isClickable={false}
          isSelected={false}
        />
      </div>
      <div
        className="flex-container center w100prc"
        style={{ marginTop: "10px", gap: "10px", padding: "2px" }}
      >
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("euro", "door_front")}
          topTitle=""
          valueTitle={`${
            data ? ( data.revenue == 0 && data.stays == 0 ? 0 : (data.revenue / data.stays).toFixed(0) ) : "0"
          } ${currency}`}
          btmTitle="ø Umsatz pro Aufenthalt"
          isClickable={false}
          isSelected={false}
        />
        <Card
          setType={() => {}}
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("euro", "groups")}
          topTitle=""
          valueTitle={`${
            data ? ( data.revenue == 0 && data.guests == 0 ? 0 : (data.revenue / data.guests).toFixed(0) ) : "0"
          } ${currency}`}
          btmTitle="ø Umsatz pro Gast"
          isClickable={false}
          isSelected={false}
        />
        <Card
          setType={() => setIsVisibleFeeView(true) }
          tileStyle={{ width: "calc(100% / 3)", height: "180px" }}
          icon={icon("euro", "cleaning_services")}
          topTitle=""
          valueTitle={`${
            (!localStorage.cleaningfee || localStorage.cleaningfee === 0) ? 'Hier klicken' : data ? localStorage.cleaningfee * data.stays + ' ' + currency : "-"
          } `}
          btmTitle="Erwarteter Reinigungsaufwand"
          isClickable={true}
          isSelected={false}
        />
      </div>
    </div>
    { isVisibleFeeView && createPortal( <CleaningFeeValueView />, document.body ) }
    </>
  );
};

export default StatisticsBig;
