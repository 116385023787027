import { useRef } from "react";
import ModalView from "../generalComps/ModalView";
import { PriceListObj, Property, PropertyPaymentObj } from "../methods/types";
import StayPriceListCard from "./StayPriceListCard";

type Props = {
    priceListArr: Array<PriceListObj>;
    property: Property;
    handleClose: () => void;
    handleNewPriceListArr: (priceListString: string) => void;
}

const StayPriceListView = ( { priceListArr, property, handleClose, handleNewPriceListArr }: Props ) => {

    let az = 0;

    const priceListArrRef = useRef(priceListArr);

    const handleValueChange = (newValue: number, index: number) => {

        const propertyPm: PropertyPaymentObj = JSON.parse( property.property_pm! );

        let taxVal = 0;
    
        if (propertyPm.property_tax) {

            taxVal = (propertyPm.property_tax_percent * newValue) / (100 + propertyPm.property_tax_percent);

        }

        priceListArrRef.current[index].price = newValue;
        priceListArrRef.current[index].originalPrice = newValue;
        priceListArrRef.current[index].taxVal = taxVal;
        priceListArrRef.current[index].originalTaxVal = taxVal;
        priceListArrRef.current[index].taxPercent = propertyPm.property_tax_percent;

    }

    const handleSave = () => {

        handleNewPriceListArr( JSON.stringify( priceListArrRef.current ) );
        handleClose();

    }

    return ( 

        <ModalView modalViewId="stayPriceListView" style={{width: '400px', maxHeight: '620px'}} handleClose={handleClose}>
            <div className="flex center-y" style={{ width: "100%" }}>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.2rem'}}>Einzelauflistung Kosten</div>
                <div onClick={handleSave} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>check_circle</div>
            </div>
            <div className="flex center w100prc h100prc" style={{flexDirection: 'column', justifyContent: 'flex-start', gap: '10px', padding: '20px'}}>
                { priceListArrRef.current.map( (priceListObj, i) => {

                    return <StayPriceListCard key={i} priceListObj={priceListObj} rowNumber={++az} handleValueChange={(newValue) => handleValueChange(newValue, i) } />;

                } ) }
            </div>
        </ModalView>

     );
}
 
export default StayPriceListView;