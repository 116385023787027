import { useIsFetching } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import Footer from "./generalComps/Footer";
import { createPortal } from "react-dom";
import LoadingIndicator from "./generalComps/LoadingIndicator";
import NavigationBarTop from "./generalComps/NavigationBarTop";

const Gaestebereich = () => {

    const isFetching = useIsFetching();
  
    return (
      <>
        <NavigationBarTop type="blank"/>
        <div className="firstSector">
            <Outlet />
        </div>
       <Footer />
       { isFetching ? createPortal( <LoadingIndicator />, document.body ) : '' }
      </>
    );
  }
  
  export default Gaestebereich;