import * as React from "react";
import { memo, useContext} from "react";
import {StayDetailViewContext} from "../contexts/StayDetailViewContext";
import PopUp from "../generalComps/PopUp";
import { AbzObj } from "../methods/types";
import { OccupancyContext } from "../contexts/OccupancyContext";

type Props = {
    selectedId: number;
    handleClosePopUp: () => void;
}

const StayBenefitsPopUpView = ( { selectedId, handleClosePopUp}:Props ) => {

    const { setStay, mAbzArr, priceListArr } = useContext(StayDetailViewContext);
    const { reloadStayData } = useContext(OccupancyContext);

    const handleOnClick = (e: React.SyntheticEvent, abzObj: AbzObj) => {

        priceListArr.forEach(obj => {
                
            if (obj.art == 0) {
                
                if (!obj.additional) {

                    if (abzObj.abz_id === -1) {

                        obj.price = obj.originalPrice!;
                        obj.taxVal = obj.originalTaxVal!;

                    } else {

                        const json_obj = JSON.parse(abzObj.abz_json!);
                        const abz_val = Number( json_obj.val );

                        const price = obj.originalPrice!;

                        if ( json_obj.percentage ) {

                            const new_val = (abz_val * price) / 100;

                            obj.price = price - new_val;

                            const new_taxVal = (obj.taxPercent * obj.price) / (100 + obj.taxPercent);
                            obj.taxVal = new_taxVal;

                        } else {

                            const new_val = price - abz_val;
                            const new_taxVal = (obj.taxPercent * new_val) / (100 + obj.taxPercent);

                            obj.price = new_val;
                            obj.taxVal = new_taxVal;

                        }

                    }

                } 

                obj.discount = {abz_id: abzObj.abz_id, abz_name: abzObj.abz_name};

            }

        });

        setStay( prevStay => ({...prevStay, stay_priceList: JSON.stringify( priceListArr ) }) );

        setTimeout(() => {
            reloadStayData();
        }, 1000);

        handleClosePopUp();

        e.stopPropagation();

    }

    return (
        <PopUp style={{ marginTop: '30px', height: 'auto' }} handleClosePopUp={handleClosePopUp}>
        {mAbzArr.map((obj) => {
            return (
            <div
                className="lbl-container"
                onClick={(e) => handleOnClick(e, obj) }
                key={obj.abz_id}
            >
                <div className="lbl">{obj.abz_name}</div>
                {obj.abz_id === selectedId && <div className="default-icon">check</div>}
            </div>
            );
        })}
        </PopUp>
    );

}
 
export default memo( StayBenefitsPopUpView );