import { ReactElement, SyntheticEvent, useContext, useEffect, useId, useRef, useState } from "react";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import { AlertObj, CurrencyObj, PaymentsViewType, PropertyListType, PropertyPaymentObj } from "../../methods/types";
import PopUp from "../../generalComps/PopUp";
import { currencyArr } from "../../methods/constants";
import { isEqual } from "lodash";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../methods/http.property.methods";
import ModalView from "../../generalComps/ModalView";

type Props = {
    handleClose: () => void;
}

const PaymentsGeneralView = ( { handleClose }: Props ) => {

    const queryClient = useQueryClient();

    const { setPaymentsViewType, selectedProperty, setSelectedProperty, propertyListType } = useContext(PropertysViewContext);

    const [paymentDetails, setPaymentDetails] = useState<PropertyPaymentObj>( JSON.parse( selectedProperty.property_pm! )  );

    const comparePaymentDetailsRef = useRef<PropertyPaymentObj | null>(null);

    const [isVisibleCurrencyPopUp, setIsVisibleCurrencyPopUp] = useState(false);

    const inputPauschalValueRef = useRef<HTMLInputElement | null>(null);
    const inputDepositMinValueRef = useRef<HTMLInputElement | null>(null);
    const inputKautionValueRef = useRef<HTMLInputElement | null>(null);

    const inputStorno1DaysRef = useRef<HTMLInputElement | null>(null);
    const inputStorno1PercentRef = useRef<HTMLInputElement | null>(null);

    const inputStorno2DaysRef = useRef<HTMLInputElement | null>(null);
    const inputStorno2PercentRef = useRef<HTMLInputElement | null>(null);

    const inputStorno3DaysRef = useRef<HTMLInputElement | null>(null);
    const inputStorno3PercentRef = useRef<HTMLInputElement | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    useEffect( () => { comparePaymentDetailsRef.current = paymentDetails }, []);

    const CurrencyPopUpView = (): ReactElement => {

        return(

            <PopUp style={{ marginTop: '30px' }} handleClosePopUp={ () => setIsVisibleCurrencyPopUp( false ) }>
                {currencyArr.map( currency => {
                    return (
                    <div
                        className="lbl-container"
                        onClick={(e) => { handleNewCurrency(e, currency) } }
                        key={currency.currencyShort}
                    >
                        <div className="lbl">{ currency.currencyLong } ({ currency.currencyShort })</div>
                        {paymentDetails.property_currency === currency.currencyShort && <div className="default-icon">check</div>}
                    </div>
                    );
                })}
            </PopUp>

        )

    }

    const handleNewCurrency = ( e: SyntheticEvent, currency: CurrencyObj ) => {

        setPaymentDetails( prev => ( {...prev, property_currency: currency.currencyShort, property_currency_long: currency.currencyLong } ) );

        setIsVisibleCurrencyPopUp( false );

        e.stopPropagation();

    }

    const handlePauschalValueChange = () => {

        if ( Number(inputPauschalValueRef.current!.value) <= 0) {

            inputPauschalValueRef.current!.value = '1';

        }

    }

    const handleMinValueChange = () => {

        if ( Number(inputDepositMinValueRef.current!.value) <= 0) {

            inputDepositMinValueRef.current!.value = '1';

        }

    }

    const handleKautionValueChange = () => {

        if ( Number(inputKautionValueRef.current!.value) <= 0) {

            inputKautionValueRef.current!.value = '1';

        }

    }

    const handleStorno1DaysChange = () => {

        if ( Number(inputStorno1DaysRef.current!.value) <= 0) {

            inputStorno1DaysRef.current!.value = '1';

        }

        setPaymentDetails( prev => ( {...prev, property_storno1_days: Number(inputStorno1DaysRef.current!.value) } ) );

    }

    const handleStorno1PercentChange = () => {

        if ( Number(inputStorno1PercentRef.current!.value) <= 0) {

            inputStorno1PercentRef.current!.value = '1';

        }

        setPaymentDetails( prev => ( {...prev, property_storno1_percent: Number(inputStorno1PercentRef.current!.value) } ) );

    }

    const handleStorno2DaysChange = () => {

        if ( Number(inputStorno2DaysRef.current!.value) <= 0) {

            inputStorno2DaysRef.current!.value = '1';

        }

        setPaymentDetails( prev => ( {...prev, property_storno2_days: Number(inputStorno2DaysRef.current!.value) } ) );

    }

    const handleStorno2PercentChange = () => {

        if ( Number(inputStorno2PercentRef.current!.value) <= 0) {

            inputStorno2PercentRef.current!.value = '1';

        }

        setPaymentDetails( prev => ( {...prev, property_storno2_percent: Number(inputStorno2PercentRef.current!.value) } ) );

    }

    const handleStorno3DaysChange = () => {

        if ( Number(inputStorno3DaysRef.current!.value) <= 0) {

            inputStorno3DaysRef.current!.value = '1';

        }

        setPaymentDetails( prev => ( {...prev, property_storno3_days: Number(inputStorno3DaysRef.current!.value) } ) );

    }

    const handleStorno3PercentChange = () => {

        if ( Number(inputStorno3PercentRef.current!.value) <= 0) {

            inputStorno3PercentRef.current!.value = '1';

        }

        setPaymentDetails( prev => ( {...prev, property_storno3_percent: Number(inputStorno3PercentRef.current!.value) } ) );

    }

    const handleCancel = () => {

        if ( isEqual(paymentDetails, comparePaymentDetailsRef.current) ) {
            setPaymentsViewType( PaymentsViewType.MainMenu );
        } else {
            
            const alertBtns = [{ title: 'Speichern', handler: postUpdatePropertySingleMutation }, { title: 'Fortfahren', handler: () => { setPaymentsViewType( PaymentsViewType.MainMenu ); } }];

            setAlertObj( new AlertObj( true, 'Geänderte Daten', 'Du hast die Daten geändert. Möchtest du dennoch ohne zu speichern fortfahren?', alertBtns ) );

        }

    }

    const handleSave = () => {

        if ( isEqual(paymentDetails, comparePaymentDetailsRef.current) ) {
            setPaymentsViewType( PaymentsViewType.MainMenu );
        } else {

            postUpdatePropertySingleMutation();

        }

        // const pm: PropertyPaymentObj = { property_tax: true, property_tax_percent: 7, property_currency: 'EUR', property_currency_long: 'EURO',
        //                                 property_deposit: false, property_deposit_days: 7, property_deposit_min: 250, property_deposit_pauschal: false, property_deposit_pauschal_value: 200,
        //                                 property_deposit_percent: 20, property_invoice_days: 7, property_kaution: false, property_kaution_value: 500, property_mahnung_days: 7, property_reminder_days: 7,
        //                                 property_storno: true, property_storno1: true, property_storno1_days: 50, property_storno1_percent: 50, property_storno2: false, property_storno2_days: 30, 
        //                                 property_storno2_percent: 70, property_storno3: false, property_storno3_days: 7, property_storno3_percent: 100 };


    }

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: () => postUpdatePropertySingleValue( selectedProperty.property_id, 'property_pm', JSON.stringify( paymentDetails ) ),
        onSuccess: () => {

            setSelectedProperty( prev => ( {...prev, property_pm: JSON.stringify( paymentDetails ) } ) );

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

            setPaymentsViewType( PaymentsViewType.MainMenu );

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    return ( 

        <>
        <ModalView modalViewId="paymentsGeneralView" style={{ width: '650px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
                <div className="main-title">Allgemein</div>
                <div onClick={ handleSave } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>

            <div onClick={ (e) => { setIsVisibleCurrencyPopUp(true); e.stopPropagation(); } } className="flex center-y w100prc border-bottom-strong cursor-pointer standard-fontsize" style={{ position: 'relative', padding: '5px', marginTop: '20px', overflowY: 'visible' }}>
                <div className="default-icon" style={{ width: '40px' }}>euro_symbol</div>
                <div className="flex center-y" style={{ width: '40%' }}>Aktuelle Währung für diese Immobilie:</div>
                <div className="flex center" style={{ fontWeight: 'bold', justifyContent: 'right', width: '60%' }}>{ paymentDetails.property_currency_long } ({ paymentDetails.property_currency })</div>
                <div className="clickable-icon" style={{ width: '40px' }}>chevron_right</div>
                { isVisibleCurrencyPopUp && <CurrencyPopUpView /> }
            </div>

            <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                <div className="default-icon" style={{ width: '40px' }}>account_balance</div>
                <div className="flex center-y" style={{ width: '40%' }}>Umsatzsteuer { paymentDetails.property_tax ? 'aktiviert' : 'deaktiviert' }: { paymentDetails.property_tax_percent } %</div>
                <div className="flex center" style={{ width: '60%' }}>
                    <div onClick={ () => { if (paymentDetails.property_tax_percent > 1) { setPaymentDetails( prev => ( {...prev, property_tax_percent: prev.property_tax_percent -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: paymentDetails.property_tax_percent === 1 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                    <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_tax_percent: prev.property_tax_percent +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                </div>
                <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_tax: !prev.property_tax } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ paymentDetails.property_tax ? 'toggle_on' : 'toggle_off' }</div>
            </div>

            <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                <div className="default-icon" style={{ width: '40px' }}>account_balance</div>
                <div className="flex center-y" style={{ width: '40%' }}>Rechnung zahlbar innerhalb { paymentDetails.property_invoice_days } Tagen</div>
                <div className="flex center" style={{ width: '60%', paddingRight: '50px' }}>
                    <div onClick={ () => { if (paymentDetails.property_invoice_days > 2) { setPaymentDetails( prev => ( {...prev, property_invoice_days: prev.property_invoice_days -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: paymentDetails.property_invoice_days === 2 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                    <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_invoice_days: prev.property_invoice_days +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                </div>
            </div>

            <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                <div className="default-icon" style={{ width: '40px' }}>account_balance</div>
                <div className="flex center-y" style={{ width: '40%' }}>Erinnerung zahlbar innerhalb { paymentDetails.property_reminder_days } Tagen</div>
                <div className="flex center" style={{ width: '60%', paddingRight: '50px' }}>
                    <div onClick={ () => { if (paymentDetails.property_reminder_days > 2) { setPaymentDetails( prev => ( {...prev, property_reminder_days: prev.property_reminder_days -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: paymentDetails.property_reminder_days === 2 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                    <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_reminder_days: prev.property_reminder_days +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                </div>
            </div>

            <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                <div className="default-icon" style={{ width: '40px' }}>account_balance</div>
                <div className="flex center-y" style={{ width: '40%' }}>Mahnung zahlbar innerhalb { paymentDetails.property_mahnung_days } Tagen</div>
                <div className="flex center" style={{ width: '60%', paddingRight: '50px' }}>
                    <div onClick={ () => { if (paymentDetails.property_mahnung_days > 2) { setPaymentDetails( prev => ( {...prev, property_mahnung_days: prev.property_mahnung_days -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: paymentDetails.property_mahnung_days === 2 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                    <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_mahnung_days: prev.property_mahnung_days +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                </div>
            </div>

            <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                <div className="default-icon" style={{ width: '40px' }}>account_balance</div>
                <div className="flex center-y" style={{ width: '40%' }}>Anzahlung { paymentDetails.property_deposit ? 'aktiviert' : 'deaktiviert' }: { !paymentDetails.property_deposit_pauschal ? `${ paymentDetails.property_deposit_percent } %` : `${ paymentDetails.property_deposit_pauschal_value.toFixed(2) } ${ paymentDetails.property_currency } Pauschal` }</div>
                <div className="flex center" style={{ width: '60%' }}>
                    {
                        !paymentDetails.property_deposit_pauschal &&
                        <>
                        <div onClick={ () => { if (paymentDetails.property_deposit_percent > 1) { setPaymentDetails( prev => ( {...prev, property_deposit_percent: prev.property_deposit_percent -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: paymentDetails.property_deposit_percent === 1 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                        <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_deposit_percent: prev.property_deposit_percent +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                        </>
                    }
                </div>
                <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_deposit: !prev.property_deposit } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ paymentDetails.property_deposit ? 'toggle_on' : 'toggle_off' }</div>
            </div>

            {

                paymentDetails.property_deposit &&

                <>
                <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                    <div className="default-icon" style={{ width: '40px' }}></div>
                    <div className="flex center-y" style={{ width: '40%' }}>Anzahlung zahlbar innerhalb { paymentDetails.property_deposit_days } Tagen</div>
                    <div className="flex center" style={{ width: '60%', paddingRight: '50px' }}>
                        <div onClick={ () => { if (paymentDetails.property_deposit_days > 2) { setPaymentDetails( prev => ( {...prev, property_deposit_days: prev.property_deposit_days -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: paymentDetails.property_deposit_days === 2 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                        <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_deposit_days: prev.property_deposit_days +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                    </div>
                </div>

                <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                    <div className="default-icon" style={{ width: '40px' }}></div>
                    <div className="flex center-y" style={{ width: '40%' }}>Pauschalpreis { paymentDetails.property_deposit_pauschal ? 'aktiviert' : 'deaktiviert' }:</div>
                    <div className="flex center" style={{ width: '60%' }}><input ref={inputPauschalValueRef} onChange={ handlePauschalValueChange } style={{ width: '100px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_deposit_pauschal_value } disabled={ !paymentDetails.property_deposit_pauschal }/></div>
                    <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_deposit_pauschal: !prev.property_deposit_pauschal } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ paymentDetails.property_deposit_pauschal ? 'toggle_on' : 'toggle_off' }</div>
                </div>

                { !paymentDetails.property_deposit_pauschal &&
                
                <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                    <div className="default-icon" style={{ width: '40px' }}></div>
                    <div className="flex center-y" style={{ width: '40%' }}>Mindestpreis Anzahlung:</div>
                    <div className="flex center" style={{ width: '60%' }}><input ref={inputDepositMinValueRef} onChange={ handleMinValueChange } style={{ width: '100px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_deposit_min } /></div>
                    <div className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}></div>
                </div>
            
                }
                </>

            }

            <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                <div className="default-icon" style={{ width: '40px' }}>save_alt</div>
                <div className="flex center-y" style={{ width: '40%' }}>Kaution { paymentDetails.property_kaution ? 'aktiviert' : 'deaktiviert' }:</div>
                <div className="flex center" style={{ width: '60%' }}><input ref={inputKautionValueRef} onChange={ handleKautionValueChange } style={{ width: '100px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_kaution_value } disabled={ !paymentDetails.property_kaution }/></div>
                <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_kaution: !prev.property_kaution } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ paymentDetails.property_kaution ? 'toggle_on' : 'toggle_off' }</div>
            </div>

            <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                <div className="default-icon" style={{ width: '40px' }}>cancel</div>
                <div className="flex center-y" style={{ width: '80%' }}>Stornogebühren: Es werden{ paymentDetails.property_storno ? '' : ' keine' } Stornogebühren berechnet.</div>
                <div className="flex center" style={{ width: '20%' }}></div>
                <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_storno: !prev.property_storno } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ paymentDetails.property_storno ? 'toggle_on' : 'toggle_off' }</div>
            </div>

            {
                paymentDetails.property_storno &&

                <>
                <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                    <div className="default-icon" style={{ width: '40px' }}></div>
                    <div className="flex center-y" style={{ width: '40%' }}>Stornierung { paymentDetails.property_storno1_days } { paymentDetails.property_storno1_days === 1 ? 'Tag' : 'Tage' } vor Anreise: { paymentDetails.property_storno1_percent } % vom Gesamtpreis.</div>
                    <div className="flex center" style={{ width: '60%' }}>
                        <input ref={inputStorno1DaysRef} onChange={ handleStorno1DaysChange } style={{ width: '70px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_storno1_days }/>
                        <input ref={inputStorno1PercentRef} onChange={ handleStorno1PercentChange } style={{ width: '80px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_storno1_percent }/>
                    </div>
                    <div className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}></div>
                </div>

                <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                    <div className="default-icon" style={{ width: '40px' }}></div>
                    <div className="flex center-y" style={{ width: '40%', textDecoration: paymentDetails.property_storno2 ? 'none' : 'line-through' }}>Stornierung { paymentDetails.property_storno2_days } { paymentDetails.property_storno2_days === 1 ? 'Tag' : 'Tage' } vor Anreise: { paymentDetails.property_storno2_percent } % vom Gesamtpreis.</div>
                    <div className="flex center" style={{ width: '60%' }}>
                        <input ref={inputStorno2DaysRef} onChange={ handleStorno2DaysChange } style={{ width: '70px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_storno2_days } disabled={ !paymentDetails.property_storno2 }/>
                        <input ref={inputStorno2PercentRef} onChange={ handleStorno2PercentChange } style={{ width: '80px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_storno2_percent } disabled={ !paymentDetails.property_storno2 }/>
                    </div>
                    <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_storno2: !prev.property_storno2 } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ paymentDetails.property_storno2 ? 'toggle_on' : 'toggle_off' }</div>
                </div>

                <div className="flex center-y w100prc border-bottom-strong standard-fontsize" style={{ padding: '5px' }}>
                    <div className="default-icon" style={{ width: '40px' }}></div>
                    <div className="flex center-y" style={{ width: '40%', textDecoration: paymentDetails.property_storno3 ? 'none' : 'line-through' }}>Stornierung { paymentDetails.property_storno3_days } { paymentDetails.property_storno3_days === 1 ? 'Tag' : 'Tage' } vor Anreise: { paymentDetails.property_storno3_percent } % vom Gesamtpreis.</div>
                    <div className="flex center" style={{ width: '60%' }}>
                        <input ref={inputStorno3DaysRef} onChange={ handleStorno3DaysChange } style={{ width: '70px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_storno3_days } disabled={ !paymentDetails.property_storno3 }/>
                        <input ref={inputStorno3PercentRef} onChange={ handleStorno3PercentChange } style={{ width: '80px', textAlign: 'center' }} className="input-style" type="number" min="0.00" step="1.00" defaultValue={ paymentDetails.property_storno3_percent } disabled={ !paymentDetails.property_storno3 }/>
                    </div>
                    <div onClick={ () => setPaymentDetails( prev => ( {...prev, property_storno3: !prev.property_storno3 } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ paymentDetails.property_storno3 ? 'toggle_on' : 'toggle_off' }</div>
                </div>
                </>
            }
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default PaymentsGeneralView;