import { createContext, ReactNode, useState } from "react";
import { DiscountObj, HandoverProtocolObj, KtObj, Landlord, PaymentsViewType, PriceObj, Property, PropertyListType, ZkObj, ZlObj } from "../methods/types";
import { emptyAddiService, emptyBankAccount, emptyDiscount, emptyHandoverProtocolObj, emptyLandlord, emptyPrice, emptyProperty, emptySpaTax } from "../methods/constants";
import { useMutation } from "@tanstack/react-query";
import { fetchProperty } from "../methods/http.property.methods";

type Props = {
    children: ReactNode;
}

type ContextType = {
    propertyListType: PropertyListType,
    setPropertyListType: React.Dispatch<React.SetStateAction<PropertyListType>>,
    selectedProperty: Property,
    setSelectedProperty: React.Dispatch<React.SetStateAction<Property>>,
    reloadProperty: () => void,
    selectedLandlord: Landlord,
    setSelectedLandlord: React.Dispatch<React.SetStateAction<Landlord>>,
    paymentsViewType: PaymentsViewType,
    setPaymentsViewType: React.Dispatch<React.SetStateAction<PaymentsViewType>>,
    selectedPrice: PriceObj,
    setSelectedPrice: React.Dispatch<React.SetStateAction<PriceObj>>,
    selectedDiscount: DiscountObj,
    setSelectedDiscount: React.Dispatch<React.SetStateAction<DiscountObj>>,
    selectedAddiService: ZlObj,
    setSelectedAddiService: React.Dispatch<React.SetStateAction<ZlObj>>,
    selectedSpaTax: KtObj,
    setSelectedSpaTax: React.Dispatch<React.SetStateAction<KtObj>>,
    selectedBankAcc: ZkObj,
    setSelectedBankAcc: React.Dispatch<React.SetStateAction<ZkObj>>,
    selectedHandover: HandoverProtocolObj,
    setSelectedHandover: React.Dispatch<React.SetStateAction<HandoverProtocolObj>>,
    isVisiblePriceAddEditView: boolean,
    setIsVisiblePriceAddEditView: React.Dispatch<React.SetStateAction<boolean>>,
};

export const PropertysViewContext = createContext<ContextType>( { 
    propertyListType: PropertyListType.Activated,
    setPropertyListType: () => {},
    selectedProperty: emptyProperty,
    setSelectedProperty: () => {},
    reloadProperty: () => {},
    selectedLandlord: emptyLandlord,
    setSelectedLandlord: () => {},
    paymentsViewType: PaymentsViewType.MainMenu,
    setPaymentsViewType: () => {},
    selectedPrice: emptyPrice,
    setSelectedPrice: () => {},
    selectedDiscount: emptyDiscount,
    setSelectedDiscount: () => {},
    selectedAddiService: emptyAddiService,
    setSelectedAddiService: () => {},
    selectedSpaTax: emptySpaTax,
    setSelectedSpaTax: () => {},
    selectedBankAcc: emptyBankAccount,
    setSelectedBankAcc: () => {},
    selectedHandover: emptyHandoverProtocolObj,
    setSelectedHandover: () => {},
    isVisiblePriceAddEditView: false,
    setIsVisiblePriceAddEditView: () => {},
} );

const PropertysViewContextProvider = ({ children }: Props) => {

    const [propertyListType, setPropertyListType] = useState( PropertyListType.Activated );
    const [selectedProperty, setSelectedProperty] = useState( emptyProperty );
    const [selectedLandlord, setSelectedLandlord] = useState( emptyLandlord );
    const [paymentsViewType, setPaymentsViewType] = useState( PaymentsViewType.MainMenu );
    const [selectedPrice, setSelectedPrice] = useState( emptyPrice );
    const [isVisiblePriceAddEditView, setIsVisiblePriceAddEditView] = useState(false);
    const [selectedDiscount, setSelectedDiscount] = useState( emptyDiscount );
    const [selectedAddiService, setSelectedAddiService] = useState( emptyAddiService );
    const [selectedSpaTax, setSelectedSpaTax] = useState( emptySpaTax );
    const [selectedBankAcc, setSelectedBankAcc] = useState( emptyBankAccount );
    const [selectedHandover, setSelectedHandover] = useState( emptyHandoverProtocolObj );

    const { mutate: reloadPropertyMutation } = useMutation({
        mutationFn: () => fetchProperty(selectedProperty.property_id),
        onSuccess: (data) => {

            setSelectedProperty( data.obj )

        },
        onError: (error) => { console.log(error) },
    });

    const reloadProperty = () => {

        if (selectedProperty && selectedProperty.property_id !== -1) {
            reloadPropertyMutation();
        }

    }

    return ( 
        <PropertysViewContext.Provider value={ { propertyListType, setPropertyListType, selectedProperty, setSelectedProperty, reloadProperty, selectedLandlord, setSelectedLandlord, paymentsViewType, setPaymentsViewType, selectedPrice, setSelectedPrice, isVisiblePriceAddEditView, setIsVisiblePriceAddEditView, selectedDiscount, setSelectedDiscount, selectedAddiService, setSelectedAddiService, selectedSpaTax, setSelectedSpaTax, selectedBankAcc, setSelectedBankAcc, selectedHandover, setSelectedHandover } }>
            {children}
        </PropertysViewContext.Provider>
     );
}
 
export default PropertysViewContextProvider;