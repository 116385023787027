import { Bar } from "react-chartjs-2";
import ChartView from "./ChartView";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  } from 'chart.js';
import { Property } from "../methods/types";
import { useQuery } from "@tanstack/react-query";
import { fetchChartRevProp } from "../methods/http.statistics.methods";
import { mapPropertyAndId } from "../methods/standard.methods";

type Props = {
    propertys: Array<Property>;
    title: string;
    dateSpanObj: { startMonth: string, endMonth: string, year: string };
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

const ChartAllRevProp = ( { propertys, title, dateSpanObj }: Props ) => {
    
    const propertyAndIds = mapPropertyAndId(propertys);

    const { data } = useQuery({
        queryKey: ["chartAllRevProp", propertyAndIds, dateSpanObj],
        queryFn: () =>
          fetchChartRevProp( JSON.stringify( propertyAndIds.names ), JSON.stringify( propertyAndIds.ids ), dateSpanObj.startMonth, dateSpanObj.endMonth, dateSpanObj.year),
        enabled: !!propertys,
        refetchOnWindowFocus: false,
    });

    // useEffect( () => {
    //     propertyAndIds = mapPropertyAndId(propertys);
    // }, [propertyId] );

    const options = {

        indexAxis: 'y' as const,
        elements: {
          bar: {
            borderWidth: 0,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'right' as const,
          },
          title: {
            display: false,
          },
        },
        
    };

    const cData = {

        labels: data ? data[2] : [],
        datasets: [{
            backgroundColor: data ? data[1] : [],
            borderColor: 'black',
            borderWidth: 0,
            data: data ? data[0] : [],
            lineTension: 0.4,
            fill: true
        }],

    }

    return ( 

        <ChartView title={title} style={{ height: '280px', flexDirection: 'column' }}>
            <div className="flex-container center w100prc h100prc" style={{ padding: '10px' }}>
                <Bar data={ cData }
                    options={ options } />
            </div>
        </ChartView>

     );
}
 
export default ChartAllRevProp;