import { useEffect } from "react";
import "../../css/privacy.css";

const GeneralTerms = () => {

    useEffect( () => {

        window.document.title = 'Eazyac - Allgemeine Geschäftsbedingungen';

    }, []);

    return ( 
        <>
        <div className="firstSector-web fsBackground" id="id_firstSector">
            <div className="firstSectorTitleFont">AGB</div>
        </div>

        <div className="secondSector">
            <div className="impressum">
                <h1 className="efColor">Allgemeine Geschäftsbedingungen</h1>
                <h3>Allgemeine Geschäftsbedingungen für die Nutzung der auf eazyac.pro angebotenen Dienste und Leistungen</h3>
                <p>Andreas Rolgaiser (im Folgenden „Anbieter“) stellt über seine Internetseiten und als Mobile Application in den einschlägigen App Stores einen Onlinedienst zur Verfügung („Eazyac“), mit dessen Hilfe Vermieter (im Folgenden „Nutzer“) von Ferienwohnungen, Pensionen und ähnlichem (im Folgenden zusammen „Immobilien“) das jeweilige Objekt verwalten, managen und gestalten können und Mieter (im Folgenden „Mieter“ genannt) bestimmte Informationen über Ihren Aufenthalt erhalten können. Die nachfolgenden Bedingungen regeln vorwiegend die entgeltliche Nutzung von Eazyac durch den Nutzer, aber auch die unentgeltliche Nutzung durch die Mieter.</p>
                <h3>1. Geltungsbereich</h3>
                <p><strong>1.1</strong> Eazyac stellt über die üblichen Webbrowser oder in den App Stores eine Online-Plattform zur Verfügung, die es Nutzern ermöglicht, ihre Immobilien über die dort zur Verfügung gestellten Tools zu verwalten, zu organisieren und inhaltlich zu gestalten. Den Mietern wird ermöglicht über einen Link auf diverse Informationen zum Aufenthalt zuzugreifen und diese bei Bedarf teilweise zu bearbeiten.</p>
                <p><strong>1.2</strong> Diesen AGB entgegenstehende oder von diesen abweichende Allgemeine Geschäfts- oder Vertragsbedingungen der Nutzer erkennt Eazyac nicht an, es sei denn der Geltung wird ausdrücklich schriftlich zugestimmt. Die AGB gelten auch dann, wenn Eazyac in Kenntnis entgegenstehender oder von diesen Bedingungen abweichender Bedingungen des Nutzers die Dienstleistung gegenüber dem Nutzer vorbehaltlos ausführt.</p>
            
                <h3>2. Einverständniserklärung</h3>
                <p><strong>2.1</strong> Der Nutzer erklärt sich mit den nachfolgenden AGB, den Allgemeinen Geschäftsbedingungen von Eazyac, abrufbar unter <strong><a href = "/web/about/agb.html">http://eazyac.pro/web/about/agb.html</a></strong>, sowie den Datenschutzbestimmungen, abrufbar unter <strong><a href = "/web/about/datenschutz.html">http://eazyac.pro/web/about/datenschutz.html</a></strong>, bei der Registrierung und Einrichtung des Eazyac-Accounts einverstanden, indem er die Erklärung: „Ich habe die AGB, die Allgemeinen Geschäftsbedingungen und die Datenschutzbestimmungen von Eazyac gelesen“ durch Klicken auf den Button “Ja“ bei der Registrierung bestätigt.</p>
                <p><strong>2.2</strong> Der Nutzer erklärt sich mit Nutzung der Eazyac-Plattform  und der App mit der Geltung der AGB einverstanden.</p>
                <p><strong>2.3</strong> Eazyac kann im Rahmen der gesetzlichen Bestimmungen die AGB jederzeit ändern oder ergänzen. Bei Änderungen der AGB erhält der Nutzer einen Hinweis, der ihn zur Zustimmung auffordert. Im Falle eines Widerspruchs kann von beiden Seiten das Vertragsverhältnis sofort beendet werden.</p>

                <h3>3. Registrierung</h3>
                <p><strong>3.1</strong> Voraussetzung für die Nutzung der Eazyac-Plattform durch den Nutzer ist eine zunächst für den Nutzer kostenlose Registrierung auf der Plattform unter <a href = "/eazyact/de/login/"><strong>http://eazyac.pro/eazyact/de/login/</strong></a>. Der Mieter kann die mieterbezogenen Features der Plattform auch ohne eine Registrierung unter Anerkennung der AGB nutzen.</p>
                <p><strong>3.2</strong> Mit der Registrierung und der Einrichtung des Eazyac-Accounts hat der Nutzer die Möglichkeit die Plattform zunächst kostenlos zu testen („Testzugang“) und sämtliche Anwendungen der Software zu nutzen. Der genaue Zeitraum der kostenlosen Nutzung ist bei der Registrierung ersichtlich. Der Testzugang wird automatisch beendet. Kurz vor Ablauf der kostenlosen Testphase unterbreitet Eazyac dem Nutzer ein schriftliches Angebot, dass die weitere nunmehr entgeltliche Nutzung der Plattform zu den in der Preisliste des Angebotes benannten Preisen anbietet. Mit Annahme des Angebotes durch den Nutzer, kommt es zum Abschluss des Vertrages („Lizenzvertrag“) über die Nutzung von Eazyac.</p>
                <p><strong>3.3</strong> Jeder registrierte Nutzer erhält zeitlich unbefristet einen eigenen Eazyac-Account. Die Nutzung der Plattform richtet sich nach dem unter Ziffer 5 festgelegten Lizenzbedingungen.</p>

                <h3>4. Vertragspflichten</h3>
                <p><strong>4.1</strong> Mit Abschluss des Lizenzvertrages verpflichtet sich der Anbieter für die Dauer des Vertrages den Zugang zur Plattform bereitzustellen und aufrechtzuerhalten.</p>
                <p><strong>4.2</strong> Eazyac bietet dem Nutzer darüber hinaus die Möglichkeit, seine eigenen Inhalte hochzuladen. Dabei kann es sich um Daten aller Art handeln, insbesondere Informationen, Texte, Dateien und mehr.</p>
                <p><strong>4.3</strong> Der Nutzer verpflichtet sich bis spätestens 14 Tage nach Abschluss des Lizenzvertrages den Kaufpreis für die Nutzung der Plattform zu begleichen.</p>
            
                <h3>5. Rechteeinräumung</h3>
                <p><strong>5.1</strong> Eazyac räumt dem Nutzer mit der Registrierung zunächst für eine befristete Dauer das nicht exklusive, unentgeltliche, widerrufliche und nicht übertragbare Recht ein, Eazyac bis zum Ablauf des Testzuganges zu nutzen. Mit Zustandekommen des Lizenzvertrages kann der Nutzer die Plattform im Rahmen des Lizenzvertrages weiternutzen.</p>
            
                <h3>6. Nutzungseinschränkungen</h3>
                <p><strong>6.1</strong> Mit Ausnahme der hier im Rahmen der AGB ausdrücklich eingeräumten Rechte, ist es dem Nutzer ohne schriftliche Zustimmung vom Anbieter nicht erlaubt, den durch Eazyac zur Verfügung gestellten Zugang zum Verkauf anzubieten, auf Dritte zu übertragen, Unterlizenzen zu erteilen, zu bearbeiten und zu verändern.</p>
                <p><strong>6.2</strong> Es ist weiter nicht erlaubt Eazyac im Zusammenhang mit der Benutzung von Produkten, Dienstleistungen oder Materialien zu verwenden, welche Nachfolgendes unterstützen, dafür genutzt werden oder dafür eingerichtet sind:</p>
                <p>
                    <li>für Spyware, Adware und/oder andere schädliche Programme oder Codes; nachgemachte Waren; unerwünschte Versendung von Massen-E-Mails („spam“);</li>
                    <li>Handlungen, die darauf abzielen Suchmaschinen dahingehend irrezuführen, dass bestimmte Seiten höher gelistet werden, als sie üblicherweise gelistet wären („web spam“);</li>
                    <li>für illegale mehrstufige Vertriebsangebote, illegalen Direktvertrieb und/oder Telefonvertrieb;</li>
                    <li>Hassinhalte; beleidigende, diffamierende, ausfällige oder anderweitig anzügliche Inhalte;</li>
                    <li>Prostitution; gestohlene Produkte und/ oder Gegenstände, die im Zusammenhang mit Straftaten genutzt werden; Hacker-, Überwachungs-, Abhörungs- oder Entschlüsselungseinrichtungen; illegales Glücksspiel;</li>
                </p>
                <p>Der Eazyac-Content darf in keiner Weise und für keine Zwecke verwendet werden, die gegen geltendes Recht verstoßen.</p>
                <p>Der Eazyac-Content darf nur derart verwendet werden, dass dadurch keine Beeinträchtigungen und Störungen an den Internetseiten eazyac.pro“, dem Eazyac-Server und der Eazyac-Daten entstehen.</p>
            
                <h3>7. Gewährleistung</h3>
                <p><strong>7.1</strong> Dem Nutzer ist bekannt, dass nach dem Stand der Technik Fehler in Softwareprogrammen und in der dazugehörigen Dokumentation nicht ausgeschlossen werden können und dass es nicht möglich ist, Datenverarbeitungsprogramme so zu entwickeln, dass sie für alle Anwendungsbedingungen und alle Anforderungen des Nutzers fehlerfrei sind bzw. fehlerfrei mit allen Programmen und Hardware Dritter zusammenarbeiten.</p>
                <p><strong>7.2</strong> Der Veranstalter hatte in der Testphase ausreichend Gelegenheiten die Funktionen der Plattform zu testen. Ungeachtet dessen gewährleistet der Anbieter einen Leistungszeitraum der Plattform von 24 Stunden pro Tag und sieben Tage die Woche. Die Software ist einsatzfähig mit einer Verfügbarkeit von 96 % im Jahresmittel. Ausgenommen von dieser Zeit sind Ausfallzeiten durch Software-Updates, welche überwiegend in den Abendstunden von 18.00 Uhr bis 24.00 Uhr vorgenommen werden.</p>
                <p><strong>7.3</strong> Weiterhin ausgenommen von dieser Zeit sind Zeiten, in denen der Dienst aufgrund von technischen oder sonstigen Problemen, die nicht im Einfluss des Anbieters liegen nicht möglich ist. Dies betrifft zum Beispiel technische Störungen an den Endgeräten, der Internetabdeckung oder höhere Gewalt oder das Verschulden Dritter.</p>
                <p><strong>7.4</strong> Eazyac wird auf den gängigsten mobilen Geräten unterstützt. Die genaue Auflistung dieser Geräte erhalten Sie jederzeit von uns. Eazyac kann, zu jedem Zeitpunkt und ohne Ankündigung die Unterstützung von verschiedenen Geräten oder Betriebssystemen einstellen oder erweitern. Existierende Apps werden hiervon nicht berührt.</p>

                <h3>8. Freistellung</h3>
                <p><strong>8.1</strong> Die Nutzung von Eazyac geschieht auf eigene Gefahr. Der Nutzer stellt den Anbieter von allen Ansprüchen Dritter (einschließlich der anfallenden Rechtsverfolgungskosten) frei, die aus der Nutzung der Plattform resultieren, sei es durch eigene Nutzung, sei es, dass Dritte das Passwort oder den Account mit oder ohne Kenntnis des Nutzers verwenden. Die Freistellung gilt insbesondere für Verluste, die der Anbieter oder Dritten aufgrund der nicht vertragsgemäßen Nutzung der Plattform, des Accounts oder Passwortes durch einen Dritten entstehen.</p>
                <p><strong>8.2</strong> Der Nutzer verpflichtet sich den Anbieter unverzüglich über jeden nicht-autorisierten, durch Hacking, Password-Mining oder andere Mittel erreichten Zugang zu anderen Accounts, Computersystemen oder Netzwerken, die mit einem Eazyac-Server verbunden sind, oder zu anderen Diensten, zu informieren.</p>

                <h3>9. Haftung</h3>
                <p><strong>9.1</strong> Schadensersatzansprüche gegen den Anbieter, durch die oder im Zusammenhang mit der Verwendung der Plattform, der Zurverfügungstellung von oder Ausbleiben  des Zurverfügungtellens von Diensten oder durch über Dienste zugängliche Informationen entstanden sind, insbesondere ein Ersatz von indirekten Schäden, Folgeschäden oder sonstige Schäden, die aus Nutzungsausfall, Verlust von Daten oder entgangenem Gewinn resultieren, können vom Nutzer nur geltend gemacht werden bei:</p>
                <p>(i) grobem Verschulden des Anbieters, ihrer gesetzlichen Vertreter oder Erfüllungsgehilfen;</p>
                <p>(ii) der schuldhaften Verletzung wesentlicher Vertragspflichten, soweit die Erreichung des Vertragszwecks hierdurch gefährdet wird, hinsichtlich des vertragstypischen, voraussehbaren Schadens, in der Schadenshöhe jedoch begrenzt auf die Höhe des</p>
                <p>(iii) Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die auf einer fahrlässigen Pflichtverletzung des Anbieters oder einer vorsätzlichen oder fahrlässigen Pflichtverletzung eines gesetzlichen Vertreters oder Erfüllungsgehilfen beruhen oder</p>
                <p>(iv) Haftung des Anbieters nach dem Produkthaftungsgesetz für Personenschäden oder Sachschäden an privat genutzten Gegenständen.</p>
                <p><strong>9.2</strong>Die vorstehenden Haftungsbeschränkungen und Freistellungen gelten auch für Ansprüche gegen Angestellte, Arbeitnehmer, Mitarbeiter, Vertreter und Erfüllungsgehilfen von Eazyac.</p>

                <h3>10. Beendigung</h3>
                <p><strong>10.1</strong> Der Nutzer kann seinen Eazyac-Account jederzeit kündigen. Die Kündigung befreit den Nutzer nicht von seiner Zahlungsverpflichtung.</p>
                <p><strong>10.2</strong> Der Eazyac-Account des Nutzers ist durch den Anbieter jederzeit aus wichtigem Grund (u.a. Verstoß gegen die AGB) kündbar.</p>
                <p><strong>10.3</strong> Der Nutzer wird ausdrücklich darauf hingewiesen, dass mit Beendigung dieses Vertrages sämtliche durch den Anbieter eingeräumten Lizenzen im Rahmen dieser Bedingungen erlöschen.</p>
                <p><strong>10.4</strong> Bei Beendigung ist der Nutzer unverzüglich verpflichtet, alle Daten, die er im Zusammenhang mit der Nutzung der Plattform erlangt hat, auf allen mit diesen Daten bearbeiteten Websites, Scripts, Widgets, Applications und anderer Software zu entfernen und diese zu löschen.</p>

                <h3>11. Schlussbestimmungen</h3>
                <p><strong>11.1</strong> Sollte eine Bestimmung nichtig sein oder werden, bleiben die übrigen Bestimmungen gültig. An die Stelle der unwirksamen Bestimmung tritt eine wirksame, wirtschaftlich möglichst gleichartige Bestimmung.</p>
                <p><strong>11.2</strong> Besondere Vereinbarungen und Nebenabreden bedürfen zu ihrer Wirksamkeit der Schriftform. Von dieser Schriftformklausel kann nur durch schriftliche Vereinbarung abgewichen werden. Änderungen und Ergänzungen der vorliegenden Bedingungen sind nur wirksam, wenn sie von uns schriftlich bestätigt werden sind.</p>
                <p><strong>11.3</strong> Für alle Rechtsbeziehungen zwischen dem Anbieter und dem Nutzer gilt, auch wenn dieser seinen Sitz im Ausland hat, ausschließlich deutsches Recht unter Ausschluss des UN-Kaufrechts.</p>
                <p><strong>11.4</strong> Gerichtsstand ist der Sitz von Eazyac.</p>


            </div>
        
        </div>
        
        </>

     );
}
 
export default GeneralTerms;