import * as $ from 'jquery';

export const setCss = (blur: string) => {
  $(".firstSector").css("filter", "blur(" + blur + "px)");
}

export const animateModalView = (modalViewId: string) => {
    setTimeout(() => {
        $("#" + modalViewId + " #innerWrapper").addClass('open');
    }, 200);
}
