import { useQuery } from "@tanstack/react-query";
import { Stay, ViewState } from "../methods/types";
import { useState, useEffect, ReactElement, useContext } from "react";
import StayCard from "./StayCard";
import { fetchStaysAll } from "../methods/http.stays.methods";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import { getDateObj } from "../methods/standard.methods";

type Props = {
    searchString: string;
    showBlacklist: boolean;
    showCancellation: boolean;
    rateValue: number;
    //reFetch: boolean;
    //doneFetching: () => void;
    //handleSelectedStay: (state: ViewState, stay: Stay) => void;
}

const StaysList = ( { searchString, showBlacklist, showCancellation, rateValue, }: Props ) => {

    const { setHandleSelectedStay } = useContext(StayDetailViewContext);

    const [stays, setStays] = useState<Array<Stay>>([]);

    const today = new Date();
    today.setHours(0,0,0,0);

    const { isSuccess, isFetching, data } = useQuery({
        queryKey: ["fetchStays"],
        queryFn: () => fetchStaysAll(),
        refetchOnWindowFocus: false,
    });

    useEffect(() => { 
        
        if (isSuccess && data.success) {

            setStays(data.obj!);

        } 
    
    }, [data, isSuccess]);

    const NoStays = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Aufenthalte vorhanden</div>;
    }

    const filteredStays = stays.filter( ( stay ) => {

        //const guestName = getFullname(stay.guest_title!, stay.guest_firstname!, stay.guest_lastname!, stay.guest_company!);

        const blacklist = stay.guest_blacklist === 0 ? false : true;

        const ciDate = new Date(stay.stay_checkIn!);
        ciDate.setHours(0,0,0,0);
        const coDate = new Date(stay.stay_checkOut!);
        coDate.setHours(0,0,0,0);

       if (showBlacklist) {

            return blacklist === showBlacklist;

       } else if(showCancellation) {

            return stay.stay_storno_val! > 0;

       } else if (rateValue !== -1) {

            return stay.guest_rate == rateValue && !blacklist;

       } else {

            if ( searchString.length === 0 ) {

                //return coDate.getTime() >= today.getTime();
                return stays;

            } else {

                    const lastName = stay.guest_lastname ? stay.guest_lastname! : '';
                    const firstName = stay.guest_firstname ? stay.guest_firstname! : '';
                    const company = stay.guest_company ? stay.guest_company! : '';
                    const fullName = firstName + ' ' + lastName;
                    const propertyName = stay.property_name ? stay.property_name! : '';
                    const stayId = stay.stay_id.toString();
            
                    return (propertyName.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || stayId.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || lastName.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || firstName.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || company.toLowerCase().indexOf(searchString.toLowerCase()) != -1 || fullName.toLowerCase().indexOf(searchString.toLowerCase()) != -1);

            }

       }

    });

    useEffect( () => {

        const today = getDateObj( new Date(), 0 );

        const element = document.getElementById('staysList');

        const card = element?.querySelectorAll('[data-checkin="' + today.dateStringUS + '"]');
        
        if (element && card) {

            if (card.length === 0) {

                element.scrollTop = element.scrollHeight;

            } else {

                element.scrollTop = card[0].getBoundingClientRect().top - (( 2 * card[0].getBoundingClientRect().height) + 30 );

            }

        }

    }, [filteredStays]);
    
    return ( 
        <div id = "staysList" className="flex center w100prc h100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', overflowX: 'hidden', overflowY: 'scroll', padding: '3px' }}>
        { ( filteredStays.length === 0 && isFetching ) &&  <div className="shadowBox flex center w100prc">Lade Aufenthalte...</div>}
        { ( filteredStays.length === 0 && isSuccess ) &&  <NoStays /> }
        { filteredStays.map( ( stay ) => {

            return <StayCard handleOnClick={ (state) => setHandleSelectedStay({state: state, stay: stay}) } key={stay.stay_id} stay={stay}/>;

            }) 
        }

        </div>

     );
}
 
export default StaysList;