import "../css/occupancy.css";
import { useSearchParams } from "react-router-dom";
import { AlertObj, InfoWidgetObj, Property, PropertyPaymentObj, Stay, ViewState } from "../methods/types";
import PropDateSpanBar, { MonthType } from "../generalComps/PropDateSpanBar";
import { ReactElement, memo, useContext, useEffect, useId, useMemo, useState } from "react";
import { PropertysContext } from "../contexts/PropertysContext";
import MultiCalendar from "./multiCalendarComps/MultiCalendar";
import { OccupancyContext } from "../contexts/OccupancyContext";
import { emptyProperty, emptyStay } from "../methods/constants";
import OccupancyDateSelectionBar from "./OccupancyDateSelectionBar";
import { createPortal } from "react-dom";
import ModalView from "../generalComps/ModalView";
import StayAddEditView from "../stayComps/StayAddEditView";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import InfoWidget from "../generalComps/InfoWidget";
import { daysBetweenDates, getDateObj, getFullname } from "../methods/standard.methods";
import ActionAlert from "../generalComps/ActionAlert";
import { useMutation } from "@tanstack/react-query";
import { fetchStay, postDeleteStay, postUpdateStaySingleValue } from "../methods/http.stays.methods";
import StayCancellationView from "../stayComps/StayCancellationView";
import { getCancellationObject } from "../methods/price.calculation.methods";
import SingleListCalendar from "./singleListCalendarComps/SingleListCalendar";
import PropertysViewContextProvider from "../contexts/PropertysMainContext";
import NoPropertyComp from "../generalComps/NoPropertyComp";

const Occupancy = () => {

    const { propertys, reloadPropertys, } = useContext(PropertysContext);

    const { selectedProperty, 
            setSelectedProperty,
            startMonth,
            setStartMonth,
            endMonth,
            setEndMonth,
            year,
            setYear,
            selectedStartDate,
            setSelectedStartDate,
            selectedEndDate,
            setSelectedEndDate,
            selectedDatesRow,
            setSelectedDatesRow,
            reloadStayData,
            filteredPropertys,
            setIsVisibleStayDetailsView,
            isVisibleStayAddEditView,
            setIsVisibleStayAddEditView } = useContext(OccupancyContext);

    const { stay, setStay, setSelectedAddEditStay, setSelectedAddEditProperty, handleSelectedStay } = useContext(StayDetailViewContext);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const [isVisibleCancellationView, setIsVisibleCancellationView] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const nights = useMemo( () => daysBetweenDates( getDateObj( stay.stay_checkIn!, 0 ).date, getDateObj( stay.stay_checkOut!, 0 ).date ), [stay.stay_checkIn, stay.stay_checkOut]);
    const priceDataObj = {priceListString: stay.stay_priceList!, specialPrice: stay.stay_special_price!, nights: nights, currency: propertys.currencyShort};

    useMemo( () => {

        setIsVisibleStayDetailsView( false );
        setStay(handleSelectedStay.stay);

        setTimeout(() => {
            
            if (handleSelectedStay.state === ViewState.Edit) {

                setSelectedAddEditStay( handleSelectedStay.stay );
                setIsVisibleStayAddEditView(true);
    
            } else if (handleSelectedStay.state === ViewState.Delete) {

                const alertButtons = [ {title: 'Löschen', handler: () => handleDeleteStay() }, {title: 'Abbrechen', handler: () => setAlertObj(new AlertObj())} ];

                const fullNameObj = getFullname(handleSelectedStay.stay.guest_title!, handleSelectedStay.stay.guest_firstname!, handleSelectedStay.stay.guest_lastname!, handleSelectedStay.stay.guest_company!);
                
                setAlertObj( new AlertObj( true, 'Aufenthalt löschen?', `Soll der Aufenthalt von ${ fullNameObj.fullname } im Objekt "${handleSelectedStay.stay.property_name!}" wirklich gelöscht werden?`, alertButtons ) );

            } else if (handleSelectedStay.state === ViewState.Cancellation) {
                
                if (!handleSelectedStay.stay.stay_storno_val || handleSelectedStay.stay.stay_storno_val! === 0) {
                    setIsVisibleCancellationView(true);
                } else {

                    setAlertObj( new AlertObj( true, 'Stornierung aufheben?', `Aufenthalt ist bereits storniert. Stornogebühr: ${handleSelectedStay.stay.stay_storno_val!.toFixed(2)} ${propertys.currencyShort}. Stornierung aufheben?`, Array({title: 'Aufheben', handler: postUpdateStornoValueMutation }, { ...standardAlertButton[0], title: 'Abbrechen' }) ) );

                }

            } else if (handleSelectedStay.state === ViewState.Details) {
                
                fetchStayMutation(handleSelectedStay.stay.stay_id);

            }

        }, 100);

    }, [handleSelectedStay]);

    useEffect( () => { 
        
        reloadPropertys();

        window.document.title = 'Eazyac Belegungsplan';

        let property: Property | undefined = emptyProperty;

        if (searchParams.get('property')) {

            property = propertys.array.find( property => property.property_name === searchParams.get('property') );

        }

        setSelectedProperty( property ? property : emptyProperty );
        setStartMonth( searchParams.get('startMonth') ? Number(searchParams.get('startMonth')!) : 0 );
        setEndMonth( searchParams.get('endMonth') ? Number(searchParams.get('endMonth')!) : 11 );
        setYear( searchParams.get('year') ? Number(searchParams.get('year')!) : new Date().getFullYear() );
    
    }, []);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect( () => {
        setSearchParams( { 'property': selectedProperty.property_name, 'propertyId': selectedProperty.property_id.toString(), 'startMonth': startMonth.toString(), 'endMonth': endMonth.toString(), 'year': year.toString()} );
    }, [selectedProperty, startMonth, endMonth, year]);

    const handleDeleteStay = () => {

        postDeleteStayMutation();

        setAlertObj(new AlertObj());

    }

    const { mutate: postDeleteStayMutation } = useMutation({
        mutationFn: () => postDeleteStay(stay.stay_id),
        onSuccess: () => {

            reloadStayData();

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Fehler', 'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.', standardAlertButton,) );
        },
    })

    const { mutate: fetchStayMutation } = useMutation({
        mutationFn: (stayId: number) => fetchStay(stayId),
        onSuccess: (data) => {

            if (data.success) {
                setStay(data.obj);
                setIsVisibleStayDetailsView( true );
            }

        }
    });

    const { mutate: postUpdateStornoValueMutation } = useMutation({
        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, 'stay_storno_val', '0'),
        onSuccess: () => {

            reloadStayData();

            setAlertObj( new AlertObj() );

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
        },
    });

    const handleSetMonth = (index: number, monthType: MonthType) => {

        if (monthType == MonthType.start) {
            setStartMonth( index );
        } else {
            setEndMonth(index );
        }

    }

    const handleSetPropertyAndId = (property: string, propertyId: string) => {

        let tempProperty: Property | undefined = emptyProperty;

        tempProperty = propertys.array.find( property => property.property_id === Number(propertyId) );

        setSelectedProperty( tempProperty ? tempProperty : emptyProperty );

    }

    const handleSetYear = (year: string) => {

        setYear( Number(year) );

    }

    const handleSaveStay = (type: string) => {

        setSelectedStartDate(null);
        setSelectedEndDate(null);
        setSelectedDatesRow(null);

        setSelectedAddEditStay( emptyStay );

        setInfoWidgetObj( new InfoWidgetObj(true, `Aufenthalt erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

        setIsVisibleStayAddEditView( false );

        setTimeout(() => {
            reloadStayData();
        }, 1000);

    }

    const handleAddStayClick = () => {

        const selectedProperty = filteredPropertys.length === 1 ? filteredPropertys[0] : filteredPropertys[selectedDatesRow!];

        const newStay: Stay = {

            ...emptyStay,
            stay_property: selectedProperty.property_id,
            stay_checkIn: selectedStartDate!.dateStringUS,
            stay_checkOut: selectedEndDate!.dateStringUS,
            stay_checkIn_time: selectedProperty.property_checkIn_time,
            stay_checkOut_time: selectedProperty.property_checkOut_time,

        }

        setSelectedAddEditProperty( selectedProperty );
        setSelectedAddEditStay( newStay );
        setIsVisibleStayAddEditView( true );

    }

    const getPaymentObj = () => {

        const property = propertys.array.filter( prop => { return prop.property_id === stay.stay_property!; } )[0];

        const paymentObj: PropertyPaymentObj = property ? JSON.parse( property.property_pm! ) : null;

        return paymentObj;

    }

    return (
        <>
        { propertys.array.length === 0 ?
            
            <NoPropertyComp />

            :
            
            <div className="flex column w100prc h100prc" style={{ padding: '10px' }}>
                <PropDateSpanBar propertys={propertys.array} property={selectedProperty.property_id === -1 ? 'Alle Immobilien' : selectedProperty.property_name} propertyId={selectedProperty.property_id.toString()} setPropertyAndId={ handleSetPropertyAndId } startMonth={startMonth.toString()} setMonth={ handleSetMonth } endMonth={endMonth.toString()} year={year.toString()} setYear={ handleSetYear } style={{ gap: '10px' }}/>
                { (selectedStartDate || selectedEndDate) && <OccupancyDateSelectionBar handleAddStayClick={handleAddStayClick} />}
                { filteredPropertys.length === 1 ? <PropertysViewContextProvider><SingleListCalendar /></PropertysViewContextProvider> : <MultiCalendar /> }
            </div>
            
         }
        { isVisibleStayAddEditView && createPortal( 
            <ModalView modalViewId="stayAddEditView"  style={{ width: '900px', overflow: 'visible' }} handleClose={ () => setIsVisibleStayAddEditView(false) }>
                <StayAddEditView handleCancel={ () => setIsVisibleStayAddEditView(false) } handleSave={ handleSaveStay }/>
            </ModalView>
            , document.body ) }
        { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body ) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        { isVisibleCancellationView && createPortal( <StayCancellationView stayId={stay.stay_id} cancellationObj={ getCancellationObject( getPaymentObj(), stay.stay_checkIn!) } priceData={priceDataObj} handleClose={() => { setIsVisibleCancellationView(false); reloadStayData(); } } />, document.body) }
        </>
    );

}
 
export default memo(Occupancy);