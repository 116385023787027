import { ReactNode, useEffect } from 'react';
import '../css/stay.css'

type Props = {
    children: ReactNode;
    style?: React.CSSProperties | undefined;
}

const StayBackview = ( { style, children }: Props ) => {

    useEffect(() => {

        setTimeout(() => {
            
            document.getElementById('stayBackview')?.classList.add('opened');

        }, 200);

    }, []);

    return ( 

        <div id='stayBackview' className="shadowBox stay-backview" style={style}>
           {children}
        </div>

     );
}
 
export default StayBackview;