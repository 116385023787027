import { ReactElement, useId, useRef, useState } from "react";
import ModalView from "../generalComps/ModalView";
import SalutationSelect from "../generalComps/SalutationSelect";
import InputView from "../generalComps/InputView";
import TextareaView from "../generalComps/TextareaView";
import PopUp from "../generalComps/PopUp";
import { defaultArr, languages, linkArr, placeholderArr } from "../methods/constants";
import { useMutation } from "@tanstack/react-query";
import { fetchTemplateByType, postAddTemplate, postUpdateTemplate, postUpdateTemplateSingleValue } from "../methods/http.templates.methods";
import { AlertObj, TemplatesObj } from "../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";

type Props = {
    givenTemplate: TemplatesObj;
    reloadFunc: () => void;
    handleClose: () => void;
}

const ConversationTemplatesAddEditView = ( { givenTemplate, reloadFunc, handleClose }:Props ) => {

    const [selectedConversationType, setSelectedConversationType] = useState(givenTemplate.template_type);
    const [selectedLanguage, setSelectedLanguage] = useState<{code: string, name: string}>( languages.find( obj => obj.code === givenTemplate.template_language_code )! );
    const [selectedLink, setSelectedLink] = useState<{name: string, art: number}>( linkArr.find( obj => obj.art === givenTemplate.template_mailArt )! );
    const [selectedDefault, setSelectedDefault] = useState<{name: string, art: number}>( defaultArr.find( obj => obj.art === givenTemplate.template_isStandardFor )! );

    const inputTitleRef = useRef<HTMLInputElement | null>(null);
    const inputSubjectRef = useRef<HTMLInputElement | null>(null);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const [isVisibleLanguagePopUp, setIsVisibleLanguagePopUp] = useState(false);
    const [isVisibleLinkPopUp, setIsVisibleLinkPopUp] = useState(false);
    const [isVisibleDefaultPopUp, setIsVisibleDefaultPopUp] = useState(false);
    const [isVisiblePlaceholderPopUp, setIsVisiblePlaceholderPopUp] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const MsgTextareaIcons = (): ReactElement => {

        return(
            <>
            <div onClick={() => { closePopUpViews(); setIsVisibleLanguagePopUp(true); } } className="flex center-y clickable h100prc standard-fontsize">
                <div className="default-icon" style={{width: '30px'}}>language</div>
                <div className="flex center w100prc color-lightblack">{selectedLanguage.name}</div>
            </div>
            <div onClick={() => { closePopUpViews(); setIsVisibleLinkPopUp(true); } } className="flex center-y clickable h100prc standard-fontsize">
                <div className="default-icon" style={{width: '30px'}}>link</div>
                <div className="flex center w100prc color-lightblack">{selectedLink.name}</div>
            </div>
            <div onClick={() => { closePopUpViews(); setIsVisibleDefaultPopUp(true); } } className="flex center-y clickable h100prc standard-fontsize">
                <div className="default-icon" style={{width: '30px'}}>push_pin</div>
                <div className="flex center w100prc color-lightblack">{selectedDefault.name}</div>
            </div>
            <div onClick={() => { closePopUpViews(); setIsVisiblePlaceholderPopUp(true); } } className="flex center-y clickable h100prc standard-fontsize">
                <div className="default-icon" style={{width: '30px'}}>data_object</div>
                <div className="flex center w100prc color-lightblack">Platzhalter</div>
            </div>
            { isVisibleLanguagePopUp && <LanguagePopUp /> }
            { isVisibleLinkPopUp && <LinkPopUp /> }
            { isVisibleDefaultPopUp && <DefaultPopUp /> }
            { isVisiblePlaceholderPopUp && <PlaceholderPopUp /> }
            </>
        );

    }

    const closePopUpViews = () => {

        setIsVisibleLanguagePopUp(false);
        setIsVisibleLinkPopUp(false);
        setIsVisibleDefaultPopUp(false);
        setIsVisiblePlaceholderPopUp(false);

    }

    const LanguagePopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px' }} handleClosePopUp={closePopUpViews}>
            {languages.map((language) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleLanguageOnClick(e, language) }
                    key={language.code}
                >
                    <div className="lbl">{language.name}</div>
                    {selectedLanguage.code === language.code && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleLanguageOnClick = (e: React.SyntheticEvent, language: {name: string, code: string}) => {

        setSelectedLanguage( language );

        closePopUpViews();

        e.stopPropagation();

    }

    const LinkPopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px', width: '300px' }} handleClosePopUp={closePopUpViews}>
            {linkArr.map((link) => {

                if (selectedConversationType === 1 && link.art > 5 && link.art !== 100) {
                    return;
                }

                let infoText = '';

                if (link.art === 8) {

                    infoText = 'Nachrichtenvorlage wird in der Korrespondenz nur beim Erstellen einer nicht themenbezogenen Email wählbar.';

                } else if (link.art === 9) {

                    infoText = 'Nachrichtenvorlage wird nur beim Erstellen einer Zahlungsbestätigung wählbar.';

                } else if (link.art === 100) {

                    infoText = 'Nachrichtenvorlage wird in allen Korrespondenzen wählbar.';

                } else {

                    infoText = 'Nachrichtenvorlage wird in der Korrespondenz nur bei ' + link.name + ' wählbar.';

                }

                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleLinkOnClick(e, link) }
                    key={link.art}
                >
                    <div className="lbl">
                        <div className="flex center-y w100prc">{link.name}</div>
                        <div className="flex center-y sub-lbl w100prc color-lightblack">{infoText}</div>
                    </div>
                    {selectedLink.art === link.art && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleLinkOnClick = (e: React.SyntheticEvent, link: {name: string, art: number}) => {

        setSelectedLink( link );

        if (link.name !== 'Alle' && selectedDefault.name !== link.name && selectedDefault.name !== 'undefiniert') {
            setSelectedDefault( {name: 'undefiniert', art: 101} );
        }

        closePopUpViews();

        e.stopPropagation();

    }

    const DefaultPopUp = () => {

        let tempDefaultArr = [...defaultArr];

        if (selectedLink.name !== 'Alle') {

            tempDefaultArr = defaultArr.filter(obj => { return obj.name === selectedLink.name || obj.name === 'undefiniert' });
    
        }

        return (
            <PopUp style={{ marginTop: '30px', width: '300px' }} handleClosePopUp={closePopUpViews}>
            {tempDefaultArr.map((obj) => {

                if (selectedConversationType === 1 && obj.art > 5 && obj.art !== 101) {
                    return;
                }

                let infoText = '';

                if (obj.art === 8) {

                    infoText = 'Beim Erstellen einer nicht themenbezogenen Korrespondenz wird das Nachrichtenfeld automatisch mit dieser Vorlage befüllt.';
    
                } else if (obj.art === 101) {
    
                    infoText = 'Nachricht ist nicht als Standardvorlage definiert.';
    
                } else {
    
                    infoText = 'Beim Erstellen einer Korrespondenz der Art "' + obj.name + '" wird das Nachrichtenfeld automatisch mit dieser Vorlage befüllt.';
    
                }

                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleDefaultOnClick(e, obj) }
                    key={obj.art}
                >
                    <div className="lbl">
                        <div className="flex center-y w100prc">{obj.name}</div>
                        <div className="flex center-y sub-lbl w100prc color-lightblack">{infoText}</div>
                    </div>
                    {selectedDefault.art === obj.art && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleDefaultOnClick = (e: React.SyntheticEvent, obj: {name: string, art: number}) => {

        setSelectedDefault( obj );

        closePopUpViews();

        e.stopPropagation();

    }

    const PlaceholderPopUp = () => {

        return (
            <PopUp style={{ marginTop: '30px', width: '300px' }} handleClosePopUp={closePopUpViews}>
            {placeholderArr.map((obj) => {

                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handlePlaceholderOnClick(e, obj) }
                    key={obj.placeholder}
                >
                    <div className="lbl">
                        <div className="flex center-y w100prc">{obj.placeholder}</div>
                        <div className="flex center-y sub-lbl w100prc color-lightblack">{obj.description}</div>
                    </div>
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handlePlaceholderOnClick = (e: React.SyntheticEvent, obj: {placeholder: string, description: string}) => {

        const textareaText = textareaRef.current!.value;

        const curPos = textareaRef.current!.selectionStart;

        textareaRef.current!.value = textareaText.slice(0, curPos)+obj.placeholder+textareaText.slice(curPos);

        closePopUpViews();

        e.stopPropagation();

    }

    const { mutate: fetchTemplateByTypeMutation } = useMutation({
        mutationFn: () => fetchTemplateByType(selectedLink.art),
        onSuccess: (data) => {

            if (data.obj.length > 0 && selectedDefault.art !== 101) {

                const defaultObj: TemplatesObj = data.obj.find( (templateObj: TemplatesObj) => {
                    return templateObj.template_isStandardFor === selectedDefault.art && templateObj.template_type === selectedConversationType;
                });
  
                if (defaultObj) {

                    const alertBtns = [{title: 'Standardvorlage', handler: () => postUpdateTemplateSingleValueMutation(defaultObj.template_id) }, { ...standardAlertButton[0], title: 'Abbrechen' }]

                    setAlertObj( new AlertObj( true, 'Achtung', `Für die Korrespondenzart ${selectedLink.name} ist bereits eine Standardvorlage vorhanden. Soll diese Vorlage der neue Standard werden?`, alertBtns ) );
    
                } else {
                    addUpdateTemplate();
                }
    
            } else {
                addUpdateTemplate();
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postUpdateTemplateSingleValueMutation } = useMutation({
        mutationFn: (templateId: number) => postUpdateTemplateSingleValue( templateId, 'template_isStandardFor', String(101) ),
        onSuccess: () => {

            addUpdateTemplate();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtungg', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postAddTemplateMutation } = useMutation({
        mutationFn: (template: TemplatesObj) => postAddTemplate(template),
        onSuccess: () => {

            handleCloseAfterSave();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postUpdateTemplateMutation } = useMutation({
        mutationFn: (template: TemplatesObj) => postUpdateTemplate(template),
        onSuccess: () => {

            handleCloseAfterSave();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const addUpdateTemplate = () => {

        let subject = '';

        if (inputSubjectRef.current !== null) {
            subject = inputSubjectRef.current.value;
        }


        if (givenTemplate.template_id === -1) {

            postAddTemplateMutation( {...givenTemplate, template_title: inputTitleRef.current!.value,
                                                        template_subject: subject,
                                                        template_message: textareaRef.current!.value,
                                                        template_isStandardFor: selectedDefault.art,
                                                        template_language: selectedLanguage.name,
                                                        template_language_code: selectedLanguage.code,
                                                        template_mailArt: selectedLink.art,
                                                        template_type: selectedConversationType} );

        } else {
            
            postUpdateTemplateMutation( {...givenTemplate, template_title: inputTitleRef.current!.value,
                                                            template_subject: subject,
                                                            template_message: textareaRef.current!.value,
                                                            template_isStandardFor: selectedDefault.art,
                                                            template_language: selectedLanguage.name,
                                                            template_language_code: selectedLanguage.code,
                                                            template_mailArt: selectedLink.art,
                                                            template_id: givenTemplate.template_id} );

        }

    }

    const handleCloseAfterSave = () => {

        reloadFunc();

        handleClose();

    }

    const handleSaveTemplate = () => {

        if (inputTitleRef.current!.value.length === 0) {

            setAlertObj( new AlertObj( true, 'Titel', 'Bitte vergebe der Vorlage einen Titel.', standardAlertButton ) );

        } else if (textareaRef.current!.value.length === 0) {

            setAlertObj( new AlertObj( true, 'Nachricht', 'Bitte verfasse eine Nachricht für diese Vorlage.', standardAlertButton ) );

        } else {

            fetchTemplateByTypeMutation();

        }

    }

    return ( 

        <>
        <ModalView modalViewId="conversationTemplatesAddEditView" style={{ width: '700px', overflow: 'visible' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">Vorlage { givenTemplate.template_id === -1 ? 'erstellen' : 'bearbeiten' }</div>
                <div onClick={ handleSaveTemplate } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            { givenTemplate.template_id === -1 && <SalutationSelect selection={["Email-Vorlage", "PDF-Vorlage"]} selectedItem={selectedConversationType} handleSelection={(index) => setSelectedConversationType(index)} /> }
            <InputView title="Name für die Vorlage" style={{ marginBottom: '30px', marginTop: givenTemplate.template_id === -1 ? '0' : '20px' }}><input ref={inputTitleRef} type="text" placeholder="z. B. CheckIn-Anweisungen" defaultValue={givenTemplate.template_title} /></InputView>
            { selectedConversationType === 0 && <InputView title="Betreff"><input ref={inputSubjectRef} type="text" placeholder="z. B. statischer Text und/oder beliebiger Platzhalter" defaultValue={givenTemplate.template_subject} /></InputView> }
            <TextareaView style={{ height: '300px', marginTop: '10px' }} title='Nachricht' popUps={<MsgTextareaIcons />}><textarea ref={textareaRef} placeholder='Nachricht' defaultValue={givenTemplate.template_message}/></TextareaView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default ConversationTemplatesAddEditView;