import { useEffect } from "react";
import { createPortal } from "react-dom";

type Props = {
    iconName?: string;
    message: string;
    handleClose: (isVisible: boolean) => void;
}

const InfoWidget = ( { iconName, message, handleClose, }:Props ) => {
    
    useEffect(() => {

        setTimeout(() => {
            handleClose(false);
        }, 6000);

    }, []);

    return ( 

        <div id="infoWidget">
            <div className="default-icon" style={{ width: '40px' }}>{ iconName ? iconName : 'notifications' }</div>
            <div className="flex center w100prc">{message}</div>
        </div>

     );
}
 
export default InfoWidget;