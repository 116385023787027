import { createContext, MutableRefObject, ReactNode, useRef, useState } from "react";
import { ConversationObj, MessageListType, TemplatesObj } from "../methods/types";

type Props = {
    children: ReactNode;
}

export type MailContentObj = {
    
    to: Array<string>;
    cc: Array<string>;
    subject: string;
    body: string;

}

type ContextType = { isVisibleMessageBackview: boolean,
    setIsVisibleMessageBackview: React.Dispatch<React.SetStateAction<boolean>>,
    seperatorObj: {[key:string]: Array<ConversationObj>} | null,
    setSeperatorObj: React.Dispatch<React.SetStateAction<{[key:string]: Array<ConversationObj>} | null>>,
    messageListType: MessageListType, setMessageListType: React.Dispatch<React.SetStateAction<MessageListType>>,
    mailContentObjRef: MutableRefObject<MailContentObj>, templatesArrRef: MutableRefObject<Array<TemplatesObj>>,
    conversationTypeRef: MutableRefObject<number>, stayIdRef: MutableRefObject<number>,
    invoiceNumberRef: MutableRefObject<string>, 

};

export const MessageDetailViewContext = createContext<ContextType>( { isVisibleMessageBackview: false,
    setIsVisibleMessageBackview: () => {},
    seperatorObj: null,
    setSeperatorObj: () => {},
    messageListType: MessageListType.Unread,
    setMessageListType: () => {},
    mailContentObjRef: { current: {to: [], cc: [], subject: '', body: ''} },
    templatesArrRef: { current: [] },
    conversationTypeRef: { current: 8 },
    stayIdRef: { current: -1 },
    invoiceNumberRef: { current: '' },

} );

const MessageDetailViewContextProvider = ({ children }: Props) => {

    //const [conversationArr, setConversationArr] = useState<Array<ConversationObj>>( [] );
    const [isVisibleMessageBackview, setIsVisibleMessageBackview] = useState(false);
    //const seperatorObjRef = useRef<{[key:string]: Array<ConversationObj>} | null>( null );
    const [seperatorObj, setSeperatorObj] = useState<{[key:string]: Array<ConversationObj>} | null>( null );
    const [messageListType, setMessageListType] = useState( MessageListType.Unread );
    const mailContentObjRef = useRef<MailContentObj>( {to: [], cc: [], subject: '', body: ''} );
    const templatesArrRef = useRef<Array<TemplatesObj>>([]);
    const conversationTypeRef = useRef(8);
    const stayIdRef = useRef(-1);
    const invoiceNumberRef = useRef('');

    return ( 
        <MessageDetailViewContext.Provider value={ { isVisibleMessageBackview, setIsVisibleMessageBackview, seperatorObj, setSeperatorObj, messageListType, setMessageListType, mailContentObjRef, templatesArrRef, conversationTypeRef, stayIdRef, invoiceNumberRef } }>
            {children}
        </MessageDetailViewContext.Provider>
     );
}
 
export default MessageDetailViewContextProvider;