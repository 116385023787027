import { SyntheticEvent, useContext, useId, useRef, useState } from "react";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import ModalView from "../../generalComps/ModalView";
import ImageSelectionView from "../../generalComps/ImageSelectionView";
import SalutationSelect from "../../generalComps/SalutationSelect";
import { AlertObj, CNViewType, Contact, Landlord, Note, Salutation } from "../../methods/types";
import InputView from "../../generalComps/InputView";
import ContactNoteView from "../../generalComps/ContactNoteView";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import { emptyLandlord } from "../../methods/constants";
import { useMutation } from "@tanstack/react-query";
import { postAddLandlord, postUpdateLandlord } from "../../methods/http.landlord.methods";

type Props = {

    handleSave: (type: string, newLandlordId?: number | undefined) => void;
    handleClose: () => void;

}

const LandlordAddEditView = ( { handleSave, handleClose }: Props ) => {

    const { selectedLandlord, setSelectedLandlord } = useContext( PropertysViewContext );

    const [contactArr, setContactArr] = useState( JSON.parse(selectedLandlord.vermieter_contact) );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const newLandlordRef = useRef( emptyLandlord );

    const inputFirstnameRef = useRef<HTMLInputElement | null>(null);
    const inputLastnameRef = useRef<HTMLInputElement | null>(null);

    const inputCompanyRef = useRef<HTMLInputElement | null>(null);
    const inputTaxRef = useRef<HTMLInputElement | null>(null);

    const inputStreetRef = useRef<HTMLInputElement | null>(null);
    const inputZipRef = useRef<HTMLInputElement | null>(null);
    const inputCityRef = useRef<HTMLInputElement | null>(null);
    const inputCountryRef = useRef<HTMLInputElement | null>(null);

    const inputWebsiteRef = useRef<HTMLInputElement | null>(null);

    const handleSwapOnClick = (e: SyntheticEvent) => {

        const newTaxVal = selectedLandlord.vermieter_taxval === 0 ? 1 : 0;

        setSelectedLandlord( prevLandl => ( {...prevLandl, vermieter_taxval: newTaxVal } ) );

        e.stopPropagation();

    }

    const handleUpdateCNData = (updatedArr: Array<Contact | Note>, type: CNViewType) => {

        setContactArr( updatedArr );
        setSelectedLandlord( ( prevLandl => ({ ...prevLandl, vermieter_contact: JSON.stringify( updatedArr ) }) ) );

    }

    const handleSaveOnClick = () => {
        
        if ( selectedLandlord.vermieter_title !== 'Familie' && (inputFirstnameRef.current!.value.length === 0 || inputLastnameRef.current!.value.length === 0) ) {
        
            setAlertObj( new AlertObj( true, 'Daten unvollständig', 'Bitte trage einen Vor- und Nachnamen ein.', standardAlertButton ) );
            
        } else if ( selectedLandlord.vermieter_title === 'Familie' && inputLastnameRef.current!.value.length === 0 ) {
    
            setAlertObj( new AlertObj( true, 'Daten unvollständig', 'Bitte trage einen Nachnamen ein.', standardAlertButton ) );
            
        } else {

            const newLandlord: Landlord = { ...selectedLandlord,
                vermieter_firstname: inputFirstnameRef.current!.value,
                vermieter_lastname: inputLastnameRef.current!.value,
                vermieter_company: inputCompanyRef.current!.value,
                vermieter_taxnumber: inputTaxRef.current!.value,
                vermieter_street: inputStreetRef.current!.value,
                vermieter_zip: inputZipRef.current!.value,
                vermieter_city: inputCityRef.current!.value,
                vermieter_country: inputCountryRef.current!.value,
                vermieter_website: inputWebsiteRef.current!.value };

            newLandlordRef.current = newLandlord;

            if (newLandlord.vermieter_id === -1) {
                postAddLandlordMutation();
            } else {
                postUpdateLandlordMutation();
            }

        }

    }

    const { mutate: postAddLandlordMutation } = useMutation({
        mutationFn: () => postAddLandlord( newLandlordRef.current ),
        onSuccess: (data) => {

            setSelectedLandlord( prevLandl => ( {...prevLandl, vermieter_id: Number(data.vermieter_id!) } ));

            handleSave('new', Number(data.vermieter_id!));

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdateLandlordMutation } = useMutation({
        mutationFn: () => postUpdateLandlord( newLandlordRef.current ),
        onSuccess: () => {

            handleSave('update');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    return ( 

        <>
        <ModalView modalViewId="landlordAddEditView" style={{ width: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={handleClose} className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>cancel</div>
                <div className="main-title">{ `Vermieter ${ selectedLandlord.vermieter_id === -1 ? 'anlegen' : 'bearbeiten' }` }</div>
                <div onClick={handleSaveOnClick} className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>check_circle</div>
            </div>
            <div className="shadowBox color-lightblack" style={{ marginTop: '20px' }}>Wenn du mehrere Immobilien hast, die vom selben Vermieter verwaltet werden, so brauchst du diesen Vermieter nur einmal anlegen. Du kannst ihn anschließend allen anderen Immobilien zuweisen.</div>
            <ImageSelectionView handleImgData={ (imgData) => setSelectedLandlord( prevLandl => ( {...prevLandl, vermieter_img: imgData } ) ) } urlContentString={ ( selectedLandlord.vermieter_id === -1 || selectedLandlord.vermieter_img_uploaded === 0 ) ? 'ef_std_img.jpg' : `https://eazyac-dev.de/user_data/${selectedLandlord.user_folder!}/vermieter_images/${selectedLandlord.vermieter_id}.png?no-cache'` } style={ { marginTop: '10px', width: '100px' } }/>
            <SalutationSelect selection={["Herr", "Frau", "Familie"]} selectedItem={Salutation[selectedLandlord.vermieter_title as keyof typeof Salutation]} handleSelection={(index) => { setSelectedLandlord( prevLandl => ({...prevLandl, vermieter_title: Salutation[index]}) ) } } />
            <div className="input-wrapper">
                <InputView title="Vorname" style={{ width: '50%', opacity: (Salutation[selectedLandlord.vermieter_title as keyof typeof Salutation] === 2 ? '0.5' : '1.0') }}><input ref={inputFirstnameRef} defaultValue={ selectedLandlord.vermieter_firstname } type="text" placeholder="z. B. Max" disabled={ (Salutation[selectedLandlord.vermieter_title as keyof typeof Salutation] === 2 ? true : false) } /></InputView>
                <InputView title="Nachname" style={{ width: '50%' }}><input ref={inputLastnameRef} defaultValue={ selectedLandlord.vermieter_lastname } type="text" placeholder="z. B. Mustermann" /></InputView>
            </div>
            <div className="input-wrapper margin-top-10">
                <InputView title="Firmenname" style={{ width: '50%' }}><input ref={inputCompanyRef} defaultValue={ selectedLandlord.vermieter_company } type="text" placeholder="z. B. Unreal GmbH" /></InputView>
                <InputView title={ selectedLandlord.vermieter_taxval === 0 ? 'Steuernummer' : 'Umsatzsteuer-Identifikationsnummer' } style={{ width: '50%' }}>
                    <div onClick={ handleSwapOnClick } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.2em', right: '0', top: '3px' }}>swap_horiz</div>
                    <input ref={inputTaxRef} defaultValue={ selectedLandlord.vermieter_taxnumber } type="text" placeholder={ selectedLandlord.vermieter_taxval === 0 ? 'XXXXXXXXXXX' : 'DEXXXXXXXXX' } />
                </InputView>
            </div>
            <ContactNoteView type={CNViewType.MailAndPhone} contentArr={contactArr} style={{ marginTop: '20px', height: '90px' }} updateData={handleUpdateCNData} />
            <InputView title="Straße & Hausnummer" style={{ marginTop: '20px' }}><input ref={inputStreetRef} defaultValue={ selectedLandlord.vermieter_street } type="text" placeholder="z. B. Musterweg 7" /></InputView>
            <div className="flex center-y w100prc" style={{ marginTop: '10px', gap: '10px', padding: '3px 0' }}>
                <InputView title="Postleitzahl" style={{ width: '30%' }}><input ref={inputZipRef} defaultValue={ selectedLandlord.vermieter_zip } type="text" placeholder="z. B. 88630" /></InputView>
                <InputView title="Wohnort" style={{ width: '70%' }}><input ref={inputCityRef} defaultValue={ selectedLandlord.vermieter_city } type="text" placeholder="z. B. Musterstadt" /></InputView>
            </div>
            <InputView title="Land" style={{ marginTop: '10px' }}><input ref={inputCountryRef} defaultValue={ selectedLandlord.vermieter_country } type="text" placeholder="z. B. Deutschland" /></InputView>
            <InputView title="Webseite" style={{ marginTop: '20px' }}><input ref={inputWebsiteRef} defaultValue={ selectedLandlord.vermieter_website } type="text" placeholder="z. B. www.meine-webseite.de" /></InputView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default LandlordAddEditView;