import { useContext, useEffect, useId, useRef, useState } from "react";
import ModalView from "../../generalComps/ModalView";
import { AlertObj, ExposeDescriptionObj, ExposeImgCellObj, ExposeMainDataObj, ExposeMainImgObj, ExposeObj, PaymentsViewType, PdfType } from "../../methods/types";
import ActionAlert from "../../generalComps/ActionAlert";
import ImageSelectionView from "../../generalComps/ImageSelectionView";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import { emptyExposeDescLocationObj, emptyExposeDescOtherObj, emptyExposeDescPropertyObj, emptyExposeDescReminderObj, emptyExposeMainDataObj, emptyExposeMainImgObj } from "../../methods/constants";
import InputView from "../../generalComps/InputView";
import TextareaView from "../../generalComps/TextareaView";
import { useMutation } from "@tanstack/react-query";
import { fetchExpose, postAddExpose, postDeleteExpose, postUpdateExposeSingleValue } from "../../methods/http.expose.methods";
import ExposeImageCard from "./ExposeImageCard";
import { isEqual } from "lodash";
import { fetchPdfExTpUrl } from "../../methods/http.korres.methods";

type Props = {
    handleClose: () => void;
}

const ExposeView = ( { handleClose }:Props ) => {

    const { selectedProperty, setPaymentsViewType } = useContext(PropertysViewContext);

    const [exposeMainImg, setExposeMainImg] = useState( emptyExposeMainImgObj );
    const [mainImgParsedJson, setMainImgParsedJson] = useState<ExposeMainImgObj>( JSON.parse( exposeMainImg.ex_json ) );
    const compareExposeMainImgRef = useRef(emptyExposeMainImgObj);

    const showPopUpInfo = useRef(true);

    let mainImgUrlString = 'ef_std_img.jpg';

    if (exposeMainImg.ex_img) {

        if ( exposeMainImg.ex_img.substring(0, 4) === 'data' ) {
            mainImgUrlString = exposeMainImg.ex_img;
        } else if( exposeMainImg.ex_img.substring(0, 5) === '/user' ) {
            mainImgUrlString = `https://eazyac-dev.de${exposeMainImg.ex_img}`;
        }

    }

    const [images, setImages] = useState<Array<ExposeObj>>([]);

    const [exposeMainData, setExposeMainData] = useState( emptyExposeMainDataObj );
    const [mainDataParsedJson, setMainDataParsedJson] = useState<ExposeMainDataObj>( JSON.parse( exposeMainData.ex_json ) );
    const compareExposeMainDataRef = useRef(JSON.parse( exposeMainData.ex_json ));

    const [exposeReminder, setExposeReminder] = useState( emptyExposeDescReminderObj );
    const [reminderParsedJson, setReminderParsedJson] = useState<ExposeDescriptionObj>( JSON.parse( exposeReminder.ex_json ) );
    const compareExposeReminderRef = useRef(JSON.parse( exposeReminder.ex_json ));

    const [exposeProperty, setExposeProperty] = useState( emptyExposeDescPropertyObj );
    const [propertyParsedJson, setPropertyParsedJson] = useState<ExposeDescriptionObj>( JSON.parse( exposeProperty.ex_json ) );
    const compareExposePropertyRef = useRef(JSON.parse( exposeProperty.ex_json ));

    const [exposeLocation, setExposeLocation] = useState( emptyExposeDescLocationObj );
    const [locationParsedJson, setLocationParsedJson] = useState<ExposeDescriptionObj>( JSON.parse( exposeLocation.ex_json ) );
    const compareExposeLocationRef = useRef(JSON.parse( exposeLocation.ex_json ));

    const [exposeOther, setExposeOther] = useState( emptyExposeDescOtherObj );
    const [otherParsedJson, setOtherParsedJson] = useState<ExposeDescriptionObj>( JSON.parse( exposeOther.ex_json ) );
    const compareExposeOtherRef = useRef(JSON.parse( exposeOther.ex_json ));

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputTitleRef = useRef<HTMLInputElement>(null);
    const inputSubtitleRef = useRef<HTMLInputElement>(null);
    const inputPositionRef = useRef<HTMLInputElement>(null);

    const textareaReminderRef = useRef<HTMLTextAreaElement>(null);
    const textareaPropertyRef = useRef<HTMLTextAreaElement>(null);
    const textareaLocationRef = useRef<HTMLTextAreaElement>(null);
    const textareaOtherRef = useRef<HTMLTextAreaElement>(null);

    const { mutate: fetchExposeMutation } = useMutation({
        mutationFn: () => fetchExpose(selectedProperty.property_id),
        onSuccess: (data) => {

            const exposeDataArr: Array<ExposeObj> = data.obj;

            const mainImgObj = exposeDataArr.find( obj => obj.ex_art === 'main_img' );
            const mainDataObj = exposeDataArr.find( obj => obj.ex_art === 'main_data' );
            const reminderObj = exposeDataArr.find( obj => obj.ex_art === 'hinweis' );
            const propertyObj = exposeDataArr.find( obj => obj.ex_art === 'objekt' );
            const locationObj = exposeDataArr.find( obj => obj.ex_art === 'lage' );
            const otherObj = exposeDataArr.find( obj => obj.ex_art === 'sonstiges' );

            const imagesArr: Array<ExposeObj> = exposeDataArr.filter( obj => obj.ex_art === 'image' );

            if (mainImgObj) {
                setExposeMainImg( mainImgObj );
                setMainImgParsedJson( JSON.parse( mainImgObj.ex_json ) );
                compareExposeMainImgRef.current = mainImgObj;
            }

            if (mainDataObj) {
                setExposeMainData( mainDataObj );
                setMainDataParsedJson( JSON.parse( mainDataObj.ex_json ) );
                compareExposeMainDataRef.current = JSON.parse( mainDataObj.ex_json );
            }

            if (reminderObj) {
                setExposeReminder( reminderObj );
                setReminderParsedJson( JSON.parse( reminderObj.ex_json ) );
                compareExposeReminderRef.current = JSON.parse( reminderObj.ex_json );
            }

            if (propertyObj) {
                setExposeProperty( propertyObj );
                setPropertyParsedJson( JSON.parse( propertyObj.ex_json ) );
                compareExposePropertyRef.current = JSON.parse( propertyObj.ex_json );
            }

            if (locationObj) {
                setExposeLocation( locationObj );
                setLocationParsedJson( JSON.parse( locationObj.ex_json ) );
                compareExposeLocationRef.current = JSON.parse( locationObj.ex_json );
            }

            if (otherObj) {
                setExposeOther( otherObj );
                setOtherParsedJson( JSON.parse( otherObj.ex_json ) );
                compareExposeOtherRef.current = JSON.parse( otherObj.ex_json );
            }

            if (imagesArr.length > 0) {
                setImages( imagesArr );
            }


        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => fetchExposeMutation(), [] );

    const { mutate: postAddExposeMutation } = useMutation({
        mutationFn: () => postAddExpose( {ex_id: -1, ex_art: 'image', ex_json: '{"img_title": ""}', ex_img: ''}, selectedProperty.property_id ),
        onSuccess: () => {

            fetchExposeMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postDeleteExposeMutation } = useMutation({
        mutationFn: (image: ExposeObj) => postDeleteExpose( image, selectedProperty.property_id ),
        onSuccess: () => {

            fetchExposeMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdateSingleExposeMutation } = useMutation({
        mutationFn: ( variables: { exId: number, field: string, value: string } ) => postUpdateExposeSingleValue( variables.exId, selectedProperty.property_id, variables.field, variables.value ),
        onSuccess: () => {

            fetchExposeMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: fetchPdfExposeUrlMutation } = useMutation({
        mutationFn: () => fetchPdfExTpUrl(selectedProperty.property_id, -1, PdfType.Expose),
        onSuccess: (data) => {

            window.open(`https://eazyac-dev.de/user_data/${data.pdf_name}`, '_blank');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const isDataEqual = ():{ mainImg: boolean, mainData: boolean, reminder: boolean, property: boolean, location: boolean, other: boolean, mainImgObj: ExposeObj } => {

        let equal = { mainImg: false, mainData: false, reminder: false, property: false, location: false, other: false, mainImgObj: emptyExposeMainImgObj };

        const newMainImgJson = { ...mainImgParsedJson, main_title: inputTitleRef.current!.value, additional_text: inputSubtitleRef.current!.value, position: inputPositionRef.current!.value };
        const newMainImgObj = { ...exposeMainImg, ex_json: JSON.stringify( newMainImgJson ) };

        if ( isEqual(newMainImgObj, compareExposeMainImgRef.current) ) {
            equal.mainImg = true;
        } else {
            equal.mainImg = false;
            equal.mainImgObj = newMainImgObj;
        }

        if ( isEqual(mainDataParsedJson, compareExposeMainDataRef.current) ) {
            equal.mainData = true;
        } else {
            equal.mainData = false;
        }

        const newExposeReminder = { ...reminderParsedJson, text: textareaReminderRef.current!.value };

        if ( isEqual(newExposeReminder, compareExposeReminderRef.current) ) {
            equal.reminder = true;
        } else {
            equal.reminder = false;
        }

        const newExposeProperty = { ...propertyParsedJson, text: textareaPropertyRef.current!.value };

        if ( isEqual(newExposeProperty, compareExposePropertyRef.current) ) {
            equal.property = true;
        } else {
            equal.property = false;
        }

        const newExposeLocation = { ...locationParsedJson, text: textareaLocationRef.current!.value };

        if ( isEqual(newExposeLocation, compareExposeLocationRef.current) ) {
            equal.location = true;
        } else {
            equal.location = false;
        }

        const newExposeOther = { ...otherParsedJson, text: textareaOtherRef.current!.value };

        if ( isEqual(newExposeOther, compareExposeOtherRef.current) ) {
            equal.other = true;
        } else {
            equal.other = false;
        }

        return equal;

    }

    const handleCancel = () => {

        const { mainImg, mainData, reminder, property, location, other, mainImgObj } = isDataEqual();

        if ( mainImg && mainData && reminder && property && location && other ) {
            setPaymentsViewType( PaymentsViewType.MainMenu );
        } else {
            
            const alertBtns = [{ title: 'Speichern', handler: () => saveUnequalData( { mainImg, mainData, reminder, property, location, other, mainImgObj }, false ) }, { title: 'Fortfahren', handler: () => { setPaymentsViewType( PaymentsViewType.MainMenu ); } }];

            setAlertObj( new AlertObj( true, 'Geänderte Daten', 'Du hast die Daten geändert. Möchtest du dennoch ohne zu speichern fortfahren?', alertBtns ) );

        }

    }

    const handleSave = () => {

        const { mainImg, mainData, reminder, property, location, other, mainImgObj } = isDataEqual();

        if ( mainImg && mainData && reminder && property && location && other ) {
            
            fetchPdfExposeUrlMutation();

            if (showPopUpInfo.current) {

                setAlertObj( new AlertObj( true, 'Exposè', 'Exposè wird in Kürze geöffnet. Sollte das Exposè nicht automatisch angezeigt werden, prüfe bitte, ob ein Pop-Up-Blocker aktiviert ist.', standardAlertButton ) );
                showPopUpInfo.current = false;
            }

        } else {

            saveUnequalData( { mainImg, mainData, reminder, property, location, other, mainImgObj }, true );

        }

    }

    const saveUnequalData = ( isEqual: { mainImg: boolean, mainData: boolean, reminder: boolean, property: boolean, location: boolean, other: boolean, mainImgObj: ExposeObj }, showPdf: boolean ) => {
        
        if (!isEqual.mainImg) {

            if( isEqual.mainImgObj.ex_img && isEqual.mainImgObj.ex_img.substring(0, 5) !== '/user' ) {
                postUpdateSingleExposeMutation( { exId: isEqual.mainImgObj.ex_id, field: 'ex_img', value: isEqual.mainImgObj.ex_img ? isEqual.mainImgObj.ex_img : '' } );
            }
            
            postUpdateSingleExposeMutation( { exId: isEqual.mainImgObj.ex_id, field: 'ex_json', value: isEqual.mainImgObj.ex_json } );

        }

        if (!isEqual.mainData) {
            postUpdateSingleExposeMutation( { exId: exposeMainData.ex_id, field: 'ex_json', value: JSON.stringify( mainDataParsedJson ) } );
        }

        if (!isEqual.reminder) {
            postUpdateSingleExposeMutation( { exId: exposeReminder.ex_id, field: 'ex_json', value: JSON.stringify( { ...reminderParsedJson, text: textareaReminderRef.current!.value } ) } );
        }

        if (!isEqual.property) {
            postUpdateSingleExposeMutation( { exId: exposeProperty.ex_id, field: 'ex_json', value: JSON.stringify( { ...propertyParsedJson, text: textareaPropertyRef.current!.value } ) } );
        }
        
        if (!isEqual.location) {
            postUpdateSingleExposeMutation( { exId: exposeLocation.ex_id, field: 'ex_json', value: JSON.stringify( { ...locationParsedJson, text: textareaLocationRef.current!.value } ) } );
        }

        if (!isEqual.other) {
            postUpdateSingleExposeMutation( { exId: exposeOther.ex_id, field: 'ex_json', value: JSON.stringify( { ...otherParsedJson, text: textareaOtherRef.current!.value } ) } );
        }

        if (showPdf) {
            
            if (showPopUpInfo.current) {

                setAlertObj( new AlertObj( true, 'Exposè', 'Exposè wird in Kürze geöffnet. Sollte das Exposè nicht automatisch angezeigt werden, prüfe bitte, ob ein Pop-Up-Blocker aktiviert ist.', standardAlertButton ) );
                showPopUpInfo.current = false;
            }

            setTimeout(() => {
                fetchPdfExposeUrlMutation();
            }, 1000);

        } else {
            setPaymentsViewType( PaymentsViewType.MainMenu );
        }

    }

    const handlePositionValueChange = () => {

        if ( Number(inputPositionRef.current!.value) < 0 ) {
            inputPositionRef.current!.value = '0';
        } else if ( Number(inputPositionRef.current!.value) > 180 ) {
            inputPositionRef.current!.value = '180';
        }

    }

    const handleNewImage = (imgData: string, index: number) => {

        postUpdateSingleExposeMutation( { exId: images[index].ex_id, field: 'ex_img', value: imgData } );

    }

    const handleNewTitle = (newTitle: string, index: number) => {

        postUpdateSingleExposeMutation( { exId: images[index].ex_id, field: 'ex_json', value: JSON.stringify( {img_title: newTitle} ) } );

    }

    const handleLandlordToggle = () => {

        if ( selectedProperty.property_vermieter !== undefined && selectedProperty.property_vermieter !== null ) {

            setMainDataParsedJson( prev => ( {...prev, vermieter: prev.vermieter === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) );

        } else {

            setAlertObj( new AlertObj( true, 'Achtung', 'Dieser Immobilie ist noch kein Vermieter zugewiesen.', standardAlertButton ) );

            setMainDataParsedJson( prev => ( {...prev, vermieter: 'toggle_off' } ) );

        }

    }

    const handleServiceToggle = () => {

        const services: Array<{service: string, status: boolean, icon: string}> = selectedProperty.property_service ?  JSON.parse( selectedProperty.property_service ) : [];

        const filtered = services.filter( service => {
            return service.status === true;
        });

        if ( filtered.length > 0 ) {

            setMainDataParsedJson( prev => ( {...prev, service: prev.service === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) );

        } else {

            setAlertObj( new AlertObj( true, 'Achtung', 'Es ist noch keine Serviceleistung und/oder Ausstattung aktiviert.', standardAlertButton ) );

            setMainDataParsedJson( prev => ( {...prev, service: 'toggle_off' } ) );

        }

    }

    const handlePriceToggle = () => {

        if ( selectedProperty.property_price !== undefined && selectedProperty.property_price !== null && JSON.parse( selectedProperty.property_price ).length > 0 ) {

            setMainDataParsedJson( prev => ( {...prev, price: prev.price === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) );

        } else {

            setAlertObj( new AlertObj( true, 'Achtung', 'Dieser Immobilie ist kein Preis zugewiesen.', standardAlertButton ) );

            setMainDataParsedJson( prev => ( {...prev, price: 'toggle_off' } ) );

        }

    }

    const handleAddiServiceToggle = () => {

        if ( selectedProperty.property_zl !== undefined && selectedProperty.property_zl !== null && JSON.parse( selectedProperty.property_zl ).length > 0 ) {

            setMainDataParsedJson( prev => ( {...prev, zl: prev.zl === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) );

        } else {

            setAlertObj( new AlertObj( true, 'Achtung', 'Dieser Immobilie ist keine Zusatzleistung zugewiesen.', standardAlertButton ) );

            setMainDataParsedJson( prev => ( {...prev, zl: 'toggle_off' } ) );

        }

    }

    const handleCityTaxToggle = () => {

        if ( selectedProperty.property_kt !== undefined && selectedProperty.property_kt !== null && JSON.parse( selectedProperty.property_kt ).length > 0 ) {

            setMainDataParsedJson( prev => ( {...prev, kt: prev.kt === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) )

        } else {

            setAlertObj( new AlertObj( true, 'Achtung', 'Dieser Immobilie ist kein Gästebeitrag zugewiesen.', standardAlertButton ) );

            setMainDataParsedJson( prev => ( {...prev, kt: 'toggle_off' } ) );

        }

    }

    return ( 

        <>
        <ModalView modalViewId="exposeView" style={{ width: '800px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
                <div className="main-title">Exposè</div>
                <div onClick={ handleSave } title="Vorschau Exposè" className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>preview</div>
            </div>
            <div className="shadowBox color-lightblack" style={{ marginTop: '10px' }}>
                Das ist die Titelseite deines Exposes. Wähle ein Titelbild, einen Haupttitel und einen Untertitel. Haupt- und Untertitel können auf der vertikalen Achse frei positioniert werden. Du kannst diesen Abschnitt auch deaktivieren, wenn keine Titelseite im Expose angezeigt werden soll.    
            </div>
            <div className="expose-header-section border-bottom-strong">
                <ImageSelectionView handleImgData={ (imgData) => setExposeMainImg( prev => ( {...prev, ex_img: imgData } ) ) } urlContentString={ mainImgUrlString } style={ { marginTop: '10px', width: '150px', height: '150px' } }/>
                <div className="input-wrapper">
                    <InputView title="Titel"><input ref={inputTitleRef} type="text" defaultValue={ mainImgParsedJson.main_title } placeholder="z. B. Fewo am Wald" /></InputView>
                    <div className="bottom-wrapper">
                        <InputView title="Untertitel" style={{ width: '60%' }}><input ref={inputSubtitleRef} type="text" defaultValue={ mainImgParsedJson.additional_text } placeholder="z. B. Entspannen im Grünen" /></InputView>
                        <InputView title="Pos. Y-Achse" style={{ width: '28%' }}><input onChange={ handlePositionValueChange } style={{ textAlign: 'center' }} min="0" max="180" ref={inputPositionRef} type="number" defaultValue={ mainImgParsedJson.position } placeholder="0" /></InputView>
                        <div onClick={ () => setMainImgParsedJson( prev => ( {...prev, toggle: prev.toggle === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) ) } className="clickable-icon flex center" style={{ width: '10%', fontSize: '2.0em' }}>{ mainImgParsedJson.toggle }</div>
                    </div>
                </div>
            </div>
            <div className="shadowBox color-lightblack" style={{ marginTop: '10px' }}>
                Füge hier deinem Expose max. 15 Bilder hinzu. Verpasse jedem Bild einen Titel, der dieses kurz und knapp beschreibt.
            </div>
            <div className="flex center w100prc">
                { images.length < 15 && <div onClick={ () => postAddExposeMutation() } className="flex center clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>add_circle</div> }
                <div className="flex center-y w100prc" style={{ marginTop: '10px', height: '200px', gap: '10px', overflowX: 'scroll' }}>
                    {
                        images.map( (image, index) => {

                            return(
                                <ExposeImageCard key={image.ex_id} image={image} handleNewImage={ (imgData) => handleNewImage(imgData, index) } handleSaveTitle={ (newTitle) => handleNewTitle(newTitle, index) } handleDeleteClick={ () => postDeleteExposeMutation(image) } />
                            )

                        })
                    }
                </div>
            </div>
            <div className="shadowBox color-lightblack" style={{ marginTop: '10px' }}>
                Aktiviere in diesem Abschnitt die Daten, die in deinem Expose angezeigt werden sollen.
            </div>
            <div className="flex center w100prc" style={{ gap: '10px', paddingTop: '10px' }}>
                <div className="flex center column w100prc">
                    <div className="flex center border-bottom-strong">Vermieter</div>
                    <div onClick={ handleLandlordToggle  } className="clickable-icon flex center" style={{ fontSize: '1.5em', opacity: mainDataParsedJson.vermieter === 'toggle_off' ? '0.3'  : '1.0', }}>{ mainDataParsedJson.vermieter }</div>
                </div>
                <div className="flex center column w100prc">
                    <div className="flex center border-bottom-strong">Adresse</div>
                    <div onClick={ () => setMainDataParsedJson( prev => ( {...prev, address: prev.address === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) ) } className="clickable-icon flex center" style={{ fontSize: '1.5em', opacity: mainDataParsedJson.address === 'toggle_off' ? '0.3'  : '1.0', }}>{ mainDataParsedJson.address }</div>
                </div>
                <div className="flex center column w100prc">
                    <div className="flex center border-bottom-strong">Immobiliendaten</div>
                    <div onClick={ () => setMainDataParsedJson( prev => ( {...prev, property: prev.property === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) ) } className="clickable-icon flex center" style={{ fontSize: '1.5em', opacity: mainDataParsedJson.property === 'toggle_off' ? '0.3'  : '1.0', }}>{ mainDataParsedJson.property }</div>
                </div>
                <div className="flex center column w100prc">
                    <div className="flex center border-bottom-strong">Ausstattung</div>
                    <div onClick={ handleServiceToggle } className="clickable-icon flex center" style={{ fontSize: '1.5em', opacity: mainDataParsedJson.service === 'toggle_off' ? '0.3'  : '1.0', }}>{ mainDataParsedJson.service }</div>
                </div>
            </div>
            <div className="flex center w100prc border-bottom-strong" style={{ gap: '10px', padding: '10px 0' }}>
                <div className="flex center column w100prc">
                    <div className="flex center border-bottom-strong">Preise</div>
                    <div onClick={ handlePriceToggle } className="clickable-icon flex center" style={{ fontSize: '1.5em', opacity: mainDataParsedJson.price === 'toggle_off' ? '0.3'  : '1.0', }}>{ mainDataParsedJson.price }</div>
                </div>
                <div className="flex center column w100prc">
                    <div className="flex center border-bottom-strong">Zusatzleistungen</div>
                    <div onClick={ handleAddiServiceToggle } className="clickable-icon flex center" style={{ fontSize: '1.5em', opacity: mainDataParsedJson.zl === 'toggle_off' ? '0.3'  : '1.0', }}>{ mainDataParsedJson.zl }</div>
                </div>
                <div className="flex center column w100prc">
                    <div className="flex center border-bottom-strong">Gästebeitrag</div>
                    <div onClick={ handleCityTaxToggle } className="clickable-icon flex center" style={{ fontSize: '1.5em', opacity: mainDataParsedJson.kt === 'toggle_off' ? '0.3'  : '1.0', }}>{ mainDataParsedJson.kt }</div>
                </div>
                <div className="flex center column w100prc">
                    <div className="flex center border-bottom-strong">Stornobedingungen</div>
                    <div onClick={ () => setMainDataParsedJson( prev => ( {...prev, storno: prev.storno === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) ) } className="clickable-icon flex center" style={{ fontSize: '1.5em', opacity: mainDataParsedJson.storno === 'toggle_off' ? '0.3'  : '1.0', }}>{ mainDataParsedJson.storno }</div>
                </div>
            </div>
            <TextareaView style={{ height: '170px', marginTop: '10px' }} title='Hinweistext'>
                <div onClick={ () => setReminderParsedJson( prev => ( {...prev, toggle: prev.toggle === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) ) } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.5em', top: '5px', right: '5px' }}>{ reminderParsedJson.toggle }</div>
                <textarea ref={textareaReminderRef} placeholder='Hier kann ein Hinweis zum Expose stehen.' defaultValue={reminderParsedJson.text}/>
            </TextareaView>
            <TextareaView style={{ height: '170px', marginTop: '10px' }} title='Objektbeschreibung'>
                <div onClick={ () => setPropertyParsedJson( prev => ( {...prev, toggle: prev.toggle === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) ) } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.5em', top: '5px', right: '5px' }}>{ propertyParsedJson.toggle }</div>
                <textarea ref={textareaPropertyRef} placeholder='Die Objektbeschreibung.' defaultValue={propertyParsedJson.text}/>
            </TextareaView>
            <TextareaView style={{ height: '170px', marginTop: '10px' }} title='Lagebeschreibung'>
                <div onClick={ () => setLocationParsedJson( prev => ( {...prev, toggle: prev.toggle === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) ) } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.5em', top: '5px', right: '5px' }}>{ locationParsedJson.toggle }</div>
                <textarea ref={textareaLocationRef} placeholder='Informationen zur Lage.' defaultValue={locationParsedJson.text}/>
            </TextareaView>
            <TextareaView style={{ height: '170px', marginTop: '10px' }} title='Sonstiges'>
                <div onClick={ () => setOtherParsedJson( prev => ( {...prev, toggle: prev.toggle === 'toggle_off' ? 'toggle_on' : 'toggle_off' } ) ) } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.5em', top: '5px', right: '5px' }}>{ otherParsedJson.toggle }</div>
                <textarea ref={textareaOtherRef} placeholder='Sonstige Informationen.' defaultValue={otherParsedJson.text}/>
            </TextareaView>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default ExposeView;