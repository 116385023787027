import { useEffect, useId, useRef, useState } from "react";
import "../../css/privacy.css";
import { useMutation } from "@tanstack/react-query";
import { postContactMail } from "../../methods/http.website.methods";
import { AlertObj } from "../../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import { validateMail } from "../../methods/validation.methods";

const Support = () => {

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();

    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const nameRef = useRef<HTMLInputElement | null>(null);
    const mailRef = useRef<HTMLInputElement | null>(null);
    const subjectRef = useRef<HTMLSelectElement | null>(null);
    const messageRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect( () => {

        window.document.title = 'Eazyac - Kontakt';

        window.document.getElementsByClassName('firstSector')[0].scroll(0,0);

    }, []);

    const { mutate: postContactMailMutation } = useMutation( {
        mutationFn: () => postContactMail( nameRef!.current!.value, mailRef!.current!.value, subjectRef!.current!.value, messageRef!.current!.value),
        onSuccess: (data) => {

            if (data.success) {

                setAlertObj( new AlertObj( true, 'Nachricht versendet', data.info!, Array({title: 'Verstanden', handler: () => {

                    window.document.getElementsByClassName('firstSector')[0].scroll(0,0);
                    
                    nameRef!.current!.value = '';
                    mailRef!.current!.value = '';
                    subjectRef!.current!.value = 'Allgemein';
                    messageRef!.current!.value = '';

                    setAlertObj( new AlertObj() );

                } },) ) );

            } else {

                setAlertObj( new AlertObj( true, 'Achtung', data.info!, standardAlertButton ) );

            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    } );

    const sendMail = () => {

        if ( window.navigator.onLine ) {

            if ( nameRef!.current!.value.length === 0 && mailRef!.current!.value.length === 0 && messageRef!.current!.value.length === 0 ) {

                setAlertObj( new AlertObj( true, 'Achtung', 'Bitte fülle alle Felder aus.', standardAlertButton ) );

            } else {

                if ( validateMail( mailRef!.current!.value ) ) {

                    postContactMailMutation();

                } else {

                    setAlertObj( new AlertObj( true, 'E-Mail-Adresse', 'Es scheint sich um keine gültige E-Mail-Adresse zu handeln.', standardAlertButton ) );

                }

            }

        } else {

            setAlertObj( new AlertObj( true, 'Internetverbindung', 'Es schein keine Internetverbindung zu bestehen. Versuchen Sie es später noch einmal.', standardAlertButton ) );

        }

    }

    return ( 
        <>
        <div className="firstSector-web supportBackground" id="id_firstSector">
            <div className="mainText" style={{ marginLeft: '35%' }}>
                <span className="headlines">Kontakt</span><br/><br/><span className="headlinesText">Du hast Verbesserungsvorschläge, einen Fehler entdeckt oder einfach noch offene Fragen? Lass es mich wissen. Ich antworte dir so bald wie möglich.</span>
            </div>
        </div>

        <div className="secondSector">
    
            <form id="mail-form" method="post">
            <div><h1 className="efColor">Kontakt</h1>
                <p className="lbls">IHR NAME</p>
                <input ref={ nameRef } type="text" id="name" name="name" placeholder="Max Mustermann" />
                <p className="lbls">IHRE EMAIL</p>
                <input ref={ mailRef } type="email" id="email" name="email" placeholder="max@mustermann.com" />
                <p className="lbls">IHR ANLIEGEN</p>
                <select ref={ subjectRef } id="select" name="sub" aria-required="true">
                    <option value="Allgemein">Allgemein</option>
                    <option value="Fragen zu Eazyac">Fragen zu Eazyac</option>
                    <option value="Verbesserungsvorschläge für Eazyac">Verbesserungsvorschläge für Eazyac</option>
                    <option value="Fehlermeldung für Eazyac">Fehlermeldung für Eazyac</option>
                </select>
                <p className="lbls">IHRE NACHRICHT</p>
                <textarea ref={ messageRef } rows={ 10 } cols={ 50 } name="msg"></textarea>
                <div onClick={ sendMail } className="customButtonSend" >Abschicken</div>
                <br/><br/><br/><br/>
                
            </div>
            </form>
        
        </div>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default Support;