import { ReactElement, SyntheticEvent, useContext, useState } from "react";
import { DateSelectType } from "../generalComps/dateSelectComps/DateSelect";
import { PropertysViewContext } from "../contexts/PropertysMainContext";
import PopUp from "../generalComps/PopUp";
import { timeSelectArr } from "../methods/constants";
import { useMutation } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../methods/http.property.methods";

type Props = {

    selectionType: DateSelectType;

}

const PropertyTimeSelection = ( { selectionType }: Props ) => {

    const { selectedProperty, setSelectedProperty } = useContext(PropertysViewContext);

    const title = selectionType === DateSelectType.CheckIn ? 'Standard CheckIn-Zeit' : 'Standard CheckOut-Zeit';
    const iconName = selectionType === DateSelectType.CheckIn ? 'chevron_right' : 'chevron_left';
    const iconColor = selectionType === DateSelectType.CheckIn ? 'green' : 'red';
    const timeValue = selectionType === DateSelectType.CheckIn ? (selectedProperty.property_checkIn_time ? selectedProperty.property_checkIn_time! : '---') : ( selectedProperty.property_checkOut_time ? selectedProperty.property_checkOut_time! : '---' );

    const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);

    const TimePopUpView = (): ReactElement => {

        return(

            <PopUp style={{ marginTop: '60px' }} handleClosePopUp={ () => setIsVisiblePopUp( false ) }>
                {timeSelectArr.map((time) => {
                    return (
                    <div
                        className="lbl-container"
                        onClick={() => { saveNewTimeMutation(time) } }
                        key={time}
                    >
                        <div className="lbl">{`${time} ${ time === '---' ? '' : ' Uhr' }`}</div>
                        {time === timeValue && <div className="default-icon">check</div>}
                    </div>
                    );
                })}
            </PopUp>

        )

    }

    const openPopUp = (e: SyntheticEvent) => {

        setIsVisiblePopUp( prev => !prev );

        e.stopPropagation();

    }

    const { mutate: saveNewTimeMutation } = useMutation({
        mutationFn: (timeValue: string) => postUpdatePropertySingleValue(selectedProperty.property_id, selectionType === DateSelectType.CheckIn ? 'property_checkIn_time' : 'property_checkOut_time', timeValue),
        onSuccess: (_, variables) => {

            if (selectionType === DateSelectType.CheckIn) {
                setSelectedProperty( prev => ({ ...prev, property_checkIn_time: variables }));
            } else {
                setSelectedProperty( prev => ({ ...prev, property_checkOut_time: variables }));
            }
            
        },
    })

    return ( 

        <div onClick={ openPopUp } className="input-template-style cursor-pointer" style={{ position: 'relative', overflow: 'visible'}}>
            <div className="flex center color-darkcyan" style={{position: 'absolute', top: '-10px', padding: '0 5px', backgroundColor: 'var(--bg-color-white)', }}>{ title }</div>
            <div className = "flex w100prc h100prc color-black">
                <div className="flex center w100prc"><span className="flex center default-icon h100prc" style={{fontSize: '1.5em', color: iconColor}}>{iconName}</span>{timeValue} Uhr</div>
            </div>
            { isVisiblePopUp && <TimePopUpView /> }
        </div>

     );
}
 
export default PropertyTimeSelection;