import { useSearchParams } from "react-router-dom";
import { Property } from "../methods/types";
import StatisticsBig from "./StatisticsBig";
import PropDateSpanBar, { MonthType } from "../generalComps/PropDateSpanBar";
import { useContext, useEffect, useMemo, useState } from "react";
import ChartAllRevProp from "./ChartAllRevProp";
import ChartAllRevCat from "./ChartAllRevCat";
import ChartAllOccuProp from "./ChartAllOccuProp";
import ChartAllOccu from "./ChartAllOccu";
import ChartAllOccuMonth from "./ChartAllOccuMonth";
import ChartAllRevMonth from "./ChartAllRevMonth";
import { PropertysContext } from "../contexts/PropertysContext";
import NoPropertyComp from "../generalComps/NoPropertyComp";

const Statistics = () => {
    
    let filteredPropertys: Array<Property> = new Array<Property>();

    const propertysContext = useContext(PropertysContext);
    const propertys = propertysContext.propertys.array;

    useEffect( () => { 
        
        propertysContext.reloadPropertys();
    
        window.document.title = 'Eazyac Statistik';

    }, []);

    const [searchParams, setSearchParams] = useSearchParams();

    const [property, setProperty]  = useState( searchParams.get('property') ? searchParams.get('property')! : 'Alle Immobilien' );
    const [propertyId, setPropertyId] = useState( searchParams.get('propertyId') ? searchParams.get('propertyId')! : '-1' );
    const [startMonth, setStartMonth]  = useState( searchParams.get('startMonth') ? searchParams.get('startMonth')! : '0' );
    const [endMonth, setEndMonth]  = useState( searchParams.get('endMonth') ? searchParams.get('endMonth')! : '11' );
    const [year, setYear]  = useState( searchParams.get('year') ? searchParams.get('year')! : new Date().getFullYear().toString() );

    useEffect( () => {
        setSearchParams( { 'property': property, 'propertyId': propertyId, 'startMonth': startMonth, 'endMonth': endMonth, 'year': year} );
    }, [property, propertyId, startMonth, endMonth, year]);

    if (Number(propertyId) === -1) {

        filteredPropertys = [...propertys];

    } else {

        filteredPropertys = propertys.filter( (property) => property.property_id == Number( propertyId ) );

    }

    const handleSetMonth = (index: number, monthType: MonthType) => {

        if (monthType == MonthType.start) {
            setStartMonth(index.toString() );
        } else {
            setEndMonth(index.toString() );
        }

    }

    const handleSetPropertyAndId = (property: string, propertyId: string) => {

        setProperty( property );
        setPropertyId( propertyId );

    }

    const handleSetYear = (year: string) => {

        setYear( year );

    }

    const dateSpanObj = useMemo( () => { return { propertyId: propertyId, startMonth: startMonth, endMonth: endMonth, year: year }; }, [propertyId, startMonth, endMonth, year]);

    return (
        
        propertys.length === 0 ?

        <NoPropertyComp />

        :

        <div className="main-div">
            <div className="left-column">
                <ChartAllRevProp propertys={filteredPropertys} title={propertyId == '-1' ? "Einnahmen je Immobilie" : `Einnahmen ${property}`} dateSpanObj={ dateSpanObj }/>
                <ChartAllRevCat propertys={filteredPropertys} title={propertyId == '-1' ? "Einnahmen je Kategorie" : `Einnahmen je Kategorie ${property}`} dateSpanObj={ dateSpanObj }/>
                <ChartAllOccuProp propertys={filteredPropertys} title={propertyId == '-1' ? "Auslastung je Immobilie in %" : `Auslastung ${property} in %`} dateSpanObj={ dateSpanObj } />
            </div>
            <div className="middle-column">
                <PropDateSpanBar propertys={propertys} property={property} propertyId={propertyId} setPropertyAndId={ handleSetPropertyAndId } startMonth={startMonth} setMonth={ handleSetMonth } endMonth={endMonth} year={year} setYear={ handleSetYear } style={{ gap: '10px' }}/>
                <StatisticsBig dateSpanObj={ dateSpanObj }/>
            </div>
            <div className="right-column">
                <ChartAllOccu propertys={filteredPropertys} title={propertyId == '-1' ? "Gesamtauslastung aller Immobilien in %" : `Gesamtauslastung ${property} in %`} dateSpanObj={ dateSpanObj } />
                <ChartAllOccuMonth propertys={filteredPropertys} title={propertyId == '-1' ? `Monatliche Auslastung aller Immobilien ${dateSpanObj.year} in %` : `Monatliche Auslastung ${property} ${dateSpanObj.year} in %`} dateSpanObj={ dateSpanObj } />
                <ChartAllRevMonth propertys={filteredPropertys} title={propertyId == '-1' ? "Monatliche Einnahmen" : `Monatliche Einnahmen ${property}`} dateSpanObj={ dateSpanObj } />
            </div>
        </div>

    );

}
 
export default Statistics;