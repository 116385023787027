import { useQuery } from "@tanstack/react-query";
import { fetchGuestsAll } from "../methods/http.guests.methods";
import { useNavigate } from "react-router-dom";
import { Guest, ViewState } from "../methods/types";
import { useState, useEffect, ReactElement } from "react";
import GuestCard from "./GuestCard";
import { filterGuests, getFullname } from "../methods/standard.methods";

type Props = {
    selectedChar: string;
    searchString: string;
    showBlacklist: boolean;
    rateValue: number;
    //reFetch: boolean;
    //doneFetching: () => void;
    handleSelectedGuest: (state: ViewState, guest: Guest) => void;
}

const GuestsList = ( { selectedChar, searchString, showBlacklist, rateValue, handleSelectedGuest }: Props ) => {
    
    const navigate = useNavigate();
    const [guests, setGuests] = useState<Array<Guest>>([]);

    const { isSuccess, isFetching, data } = useQuery({
        queryKey: ["fetchGuests"],
        queryFn: () => fetchGuestsAll(),
        refetchOnWindowFocus: false,
    });

      useEffect(() => { 
        
        if (data && data.success) {
            setGuests(data.obj!);
            //doneFetching();
        } 
    
    }, [data]);

    if (isSuccess && data && data?.session === "expired") {
        navigate("login");
    } 

    const NoGuests = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Gäste vorhanden</div>;
    }

    const filteredGuests = filterGuests(guests, searchString, selectedChar, showBlacklist, rateValue);
    
    return ( 
        <div className="flex center w100prc h100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', overflowX: 'hidden', overflowY: 'scroll', padding: '0 3px' }}>
        { ( filteredGuests.length == 0 && isFetching ) &&  <div className="shadowBox flex center w100prc">Lade Gäste...</div>}
        { ( filteredGuests.length == 0 && isSuccess ) &&  <NoGuests /> }
        { filteredGuests.map( ( guest ) => {

            return <GuestCard handleOnClick={ (state) => handleSelectedGuest(state, guest) } key={guest.guest_id} guest={guest}/>;

            }) 
        }

        </div>

     );
}
 
export default GuestsList;