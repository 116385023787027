import { useContext, useId, useRef, useState } from "react";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { AlertObj, DiscountJsonObj, DiscountObj } from "../../../methods/types";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import InputView from "../../../generalComps/InputView";
import { useMutation } from "@tanstack/react-query";
import { postAddDiscount, postUpdateDiscount } from "../../../methods/http.discount.methods";
import SalutationSelect from "../../../generalComps/SalutationSelect";

type Props = {
    handleCancel: () => void;
    handleSave: (type: string, newDiscountId?: number | undefined) => void;
}

const DiscountAddEditView = ( { handleCancel, handleSave }:Props ) => {

    const { selectedDiscount, setSelectedDiscount } = useContext(PropertysViewContext);

    const [discountJsonObj, setDiscountJsonObj] = useState<DiscountJsonObj>( JSON.parse( selectedDiscount.abz_json! ) );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputTitleRef = useRef<HTMLInputElement | null>(null);
    const inputValueRef = useRef<HTMLInputElement | null>(null);

    const handleValueChange = () => {

        if ( Number(inputValueRef.current!.value) <= 0) {

            inputValueRef.current!.value = '1';

        } else if ( discountJsonObj.percentage === 1 && Number(inputValueRef.current!.value) >= 100) {

            inputValueRef.current!.value = '100';

        }

    }

    const handleSaveOnClick = () => {
        
        if ( Number(inputTitleRef.current!.value.length) === 0 ) {
        
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte vergebe eine Bezeichnung.', standardAlertButton ) );
            
        } else if ( Number(inputValueRef.current!.value) <= 0 ) {
    
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte trage einen Wert größer 0 ein.', standardAlertButton ) );
            
        } else {

            const newDiscountJsonObj: DiscountJsonObj = { ...discountJsonObj, val: Number(inputValueRef.current!.value) };

            const newDiscount: DiscountObj = { ...selectedDiscount,
                                            abz_name: inputTitleRef.current!.value,
                                            abz_json: JSON.stringify( newDiscountJsonObj ) };

            //newLandlordRef.current = newLandlord;

            if (newDiscount.abz_id === -1) {
                postAddDiscountMutation(newDiscount);
            } else {
                postUpdateDiscountMutation(newDiscount);
            }

        }

    }

    const { mutate: postAddDiscountMutation } = useMutation({
        mutationFn: (newDiscount: DiscountObj) => postAddDiscount( newDiscount ),
        onSuccess: (data) => {

            setSelectedDiscount( prev => ( {...prev, abz_id: Number(data.abz_id!) } ));

            handleSave('new', Number(data.abz_id!));

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdateDiscountMutation } = useMutation({
        mutationFn: (newDiscount: DiscountObj) => postUpdateDiscount( newDiscount ),
        onSuccess: () => {

            handleSave('update');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    return ( 

        <>
        <ModalView modalViewId="priceAddEditView" style={{ width: '550px', overflow: 'visible' }} handleClose={ handleCancel }>
            <div className="navigation-view-bar">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">{ selectedDiscount.abz_id === -1 ? 'Neuer Rabatt' : 'Rabatt bearbeiten' }</div>
                <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <div className="shadowBox color-lightblack" style={{ marginTop: '10px' }}>
                Vergebe einen Namen und einen Wert für deinen Rabatt.<br/>
                Dabei kann der Rabatt als pauschaler Betrag oder prozentualer Wert angegeben werden.
            </div>
            <SalutationSelect selection={["Pauschal", "Prozentual"]} selectedItem={ discountJsonObj.percentage } handleSelection={(index) => { setDiscountJsonObj( prev => ({...prev, percentage: index}) ); } } />
            <InputView title="Bezeichnung"><input ref={inputTitleRef} type="text" defaultValue={ selectedDiscount.abz_name } placeholder="z. B. 10% Rabatt" /></InputView>
            <InputView title={`${ discountJsonObj.percentage === 1 ? 'Prozentualer' : 'Pauschaler' } Rabatt`} style={{ marginTop: '10px' }}>
                <input ref={inputValueRef} onChange={ handleValueChange } type="number" max={ discountJsonObj.percentage === 0 ? 1000000 : 100 } defaultValue={ discountJsonObj.val } placeholder="z. B. 15" style={{ textAlign: 'center' }} />
            </InputView>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default DiscountAddEditView;