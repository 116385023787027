import { memo, ReactNode } from "react";
import '../css/inputview.css';

type Props = {
    title: string;
    style?: React.CSSProperties | undefined;
    children: ReactNode;
    errorClass?: string;
}

const InputView = ( { title, style, children, errorClass }:Props ) => {

    errorClass = !errorClass ? '' : errorClass;

    return ( 
        <div className={ `input-template-style${errorClass}` } style={style}>
            <div className="flex wrap" style={{ width: '100%', height: '20px', overflow: 'visible', }}>
                <div id="title">{ title }</div>
            </div>
            <div className = "flex center w100prc h100prc" style = {{gap: '10px', overflowX: 'scroll', overflowY: 'clip', padding: '2px 5px', }}>
                {children}
            </div>
        </div>
     );
}
 
export default memo(InputView);