import { getDateObj } from "./standard.methods";
import { BlockType, DefaultFetchResult, Property, Stay } from "./types";

export const fetchStaysAll = async () => {

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=select_all',
      {
      method: 'GET', 
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  return content;

}

export const fetchStaysSectionMails = async () => {

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=select_stay_section_mails',
      {
      method: 'GET', 
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  return content;

}

export const fetchStay = async (stayId: number) => {

  const data = new URLSearchParams();

  data.append('stay_id', stayId.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=select`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
 
  return content;

}

export const fetchStaysWithBlockAll = async (startDate: string, endDate: string) => {

  const data = new URLSearchParams();

  data.append('start_date', startDate);
  data.append('end_date', endDate);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=select_with_block_all`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const fetchStaysByProperty = async (property_id: string, startDate: string, endDate: string, status: number) => {

  const data = new URLSearchParams();

  data.append('property_id', property_id);
  data.append('start_date', startDate);
  data.append('end_date', endDate);
  data.append('status', status.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=select_all_property`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const fetchDashboardStays = async () => {

    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=select_dashboard',
        {
        method: 'GET', 
       'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content;

}

export const fetchCanBlockDate = async (property_id: string, startDate: string, endDate: string) => {

  const data = new URLSearchParams();

  data.append('stay_property', property_id);
  data.append('start_date', startDate);
  data.append('end_date', endDate);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=is_bookable`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postAddStay = async (stay: Stay, property: Property, guestId: number) => {

  const data = new URLSearchParams();

  data.append('stay_guest', guestId.toString());
  data.append('stay_property', property.property_id.toString());
  data.append('stay_status', stay.stay_status!.toString());
  data.append('stay_checkIn', stay.stay_checkIn!);
  data.append('stay_checkOut', stay.stay_checkOut!);
  data.append('stay_checkIn_time', stay.stay_checkIn_time ? stay.stay_checkIn_time : '---');
  data.append('stay_checkOut_time', stay.stay_checkOut_time ? stay.stay_checkOut_time : '---');
  data.append('stay_adults', stay.stay_adults!.toString());
  data.append('stay_children', stay.stay_children!.toString());
  data.append('stay_pets', stay.stay_pets!.toString());
  data.append('stay_priceList', stay.stay_priceList!);
  data.append('stay_special_price', stay.stay_special_price!.toString());
  data.append('stay_import_portal', stay.stay_import_portal!);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=insert`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postManuallyAddBlock = async (stay: Stay) => {

  const data = new URLSearchParams();

  data.append('stay_property', stay.stay_property.toString());
  data.append('stay_checkIn', stay.stay_checkIn);
  data.append('stay_checkOut', stay.stay_checkOut);
  data.append('stay_status', '5');
  data.append('stay_block_title', stay.stay_block_title!);


    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=insert_block`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postAddBlock = async (stay: Stay, propertyId: number, blockType: BlockType) => {

  const data = new URLSearchParams();

  let checkInDateString = '';
  let checkOutDateString = '';

  if (blockType === BlockType.Pre) {

    checkInDateString = getDateObj( stay.stay_checkIn!, -1 ).dateStringUS;
    checkOutDateString = stay.stay_checkIn!;

  } else {

    checkInDateString = stay.stay_checkOut!;
    checkOutDateString = getDateObj( stay.stay_checkOut!, +1 ).dateStringUS;

  }

  data.append('stay_property', propertyId.toString());
  data.append('stay_checkIn', checkInDateString);
  data.append('stay_checkOut', checkOutDateString);
  data.append('stay_status', '5');
  data.append('stay_block_title', 'Reinigung');


    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=insert_block`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateStay = async (stay: Stay, propertyId: number, guestId: number) => {

  const data = new URLSearchParams();

  data.append('stay_id', stay.stay_id.toString());
  data.append('stay_guest', guestId.toString());
  data.append('stay_property', propertyId.toString());
  data.append('stay_checkIn', stay.stay_checkIn!);
  data.append('stay_checkOut', stay.stay_checkOut!);
  data.append('stay_adults', stay.stay_adults!.toString());
  data.append('stay_children', stay.stay_children!.toString());
  data.append('stay_pets', stay.stay_pets!.toString());
  data.append('stay_priceList', stay.stay_priceList!);
  data.append('stay_special_price', stay.stay_special_price!.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=update`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateStaySingleValue = async (stayId: number, field: string, fieldValue: string) => {

  const data = new URLSearchParams();

  data.append('stay_id', stayId.toString());
  data.append('field', field);
  data.append('field_value', fieldValue);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=update_single`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteStay = async (stay_id: number) => {

  const data = new URLSearchParams();

  data.append('stay_id', stay_id.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteStaysByGuest = async (guest_id: number) => {

  const data = new URLSearchParams();

  data.append('guest_id', guest_id.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=stay&do=delete_by_guest`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}