import { useContext, useId, useRef, useState } from "react";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { AlertObj, DiscountJsonObj, DiscountObj, ZkObj } from "../../../methods/types";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import InputView from "../../../generalComps/InputView";
import { useMutation } from "@tanstack/react-query";
import { postAddDiscount, postUpdateDiscount } from "../../../methods/http.discount.methods";
import SalutationSelect from "../../../generalComps/SalutationSelect";
import { postAddBankAccount, postUpdateBankAccount } from "../../../methods/http.bankaccount.methods";
import PopUp from "../../../generalComps/PopUp";
import { validateMail } from "../../../methods/validation.methods";

type Props = {
    handleCancel: () => void;
    handleSave: (type: string, newBankAccId?: number | undefined) => void;
}

const BankAccountAddEditView = ( { handleCancel, handleSave }:Props ) => {

    const { selectedBankAcc, setSelectedBankAcc } = useContext(PropertysViewContext);

    const [selectedType, setSelectedType] = useState( selectedBankAcc.zk_paypal.length === 0 ? 0 : 1);
    const bankAccTypes = ['Bankkonto', 'Paypal'];

    const [isVisibleTypePopUp, setIsVisibleTypePopUp] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputTitleRef = useRef<HTMLInputElement | null>(null);
    const inputIbanRef = useRef<HTMLInputElement | null>(null);
    const inputBicRef = useRef<HTMLInputElement | null>(null);
    const inputPaypalRef = useRef<HTMLInputElement | null>(null);

    const handleSaveOnClick = () => {
        
        if ( selectedType === 0 && ( inputTitleRef.current!.value.length === 0 || inputIbanRef.current!.value.length === 0 || inputBicRef.current!.value.length === 0 ) ) {
        
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte vergebe eine Bezeichnung, eine Iban und Bic.', standardAlertButton ) );
            
        } else if ( selectedType === 1 && !validateMail( inputPaypalRef.current!.value ) ) {
    
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte trage ein gültiges Paypal-Konto ein.', standardAlertButton ) );
            
        } else {

            const newBankAcc: ZkObj = Object.assign({}, selectedBankAcc);

            if ( selectedType === 0) {

                newBankAcc.zk_name = inputTitleRef.current!.value;
                newBankAcc.zk_iban = inputIbanRef.current!.value;
                newBankAcc.zk_bic = inputBicRef.current!.value;
                newBankAcc.zk_paypal = '';

            } else {

                newBankAcc.zk_name = '';
                newBankAcc.zk_iban = '';
                newBankAcc.zk_bic = '';
                newBankAcc.zk_paypal = inputPaypalRef.current!.value;

            }

            if (newBankAcc.zk_id === -1) {
                postAddBankAccMutation(newBankAcc);
            } else {
                postUpdateBankAccMutation(newBankAcc);
            }

        }

    }

    const { mutate: postAddBankAccMutation } = useMutation({
        mutationFn: (newBankAcc: ZkObj) => postAddBankAccount( newBankAcc ),
        onSuccess: (data) => {

            setSelectedBankAcc( prev => ( {...prev, zk_id: Number(data.zk_id!) } ));

            handleSave('new', Number(data.zk_id!));

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdateBankAccMutation } = useMutation({
        mutationFn: (newBankAcc: ZkObj) => postUpdateBankAccount( newBankAcc ),
        onSuccess: () => {

            handleSave('update');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const TypePopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px', height: 'auto' }} handleClosePopUp={ () => setIsVisibleTypePopUp(false) }>
            {bankAccTypes.map( (type, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => {

                        setSelectedType( index );

                        setIsVisibleTypePopUp(false);

                        e.stopPropagation();

                    } }
                    key={type}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{type}</div>
                    {index === selectedType && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    return ( 

        <>
        <ModalView modalViewId="bankAccAddEditView" style={{ width: '550px', overflow: 'visible' }} handleClose={ handleCancel }>
            <div className="navigation-view-bar">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">{ selectedBankAcc.zk_id === -1 ? 'Neues Konto' : 'Konto bearbeiten' }</div>
                <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <div onClick={ () => setIsVisibleTypePopUp(true) } className="clickable-popUpButton" style={{ marginTop: '10px' }}>
                { bankAccTypes[selectedType] }
                { isVisibleTypePopUp && <TypePopUp  /> }
            </div>
            {
                selectedType === 0 ?

                <>
                <InputView title="Bank" style={{ marginTop: '10px' }}><input ref={inputTitleRef} type="text" defaultValue={ selectedBankAcc.zk_name } placeholder="z. B. Deutsche Bank" /></InputView>
                <InputView title="IBAN" style={{ marginTop: '20px' }}><input ref={inputIbanRef} type="text" defaultValue={ selectedBankAcc.zk_iban } placeholder="IBAN" /></InputView>
                <InputView title="BIC" style={{ marginTop: '10px' }}><input ref={inputBicRef} type="text" defaultValue={ selectedBankAcc.zk_bic } placeholder="BIC" /></InputView>
                </>
                :
                <InputView title="Paypal-Konto" style={{ marginTop: '10px' }}><input ref={inputPaypalRef} type="text" defaultValue={ selectedBankAcc.zk_paypal } placeholder="E-Mail" /></InputView>

            }
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default BankAccountAddEditView;