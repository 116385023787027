import { useContext, useId, useState } from "react";
import { AlertObj } from "../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import { PaypalInvoiceViewType, SettingsViewContext } from "../contexts/SettingsContext";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { OnApproveActions, OnApproveData } from "@paypal/paypal-js";
import { useMutation } from "@tanstack/react-query";
import { postUpdateDatabase } from "../methods/http.paypal.methods";
import { useNavigate } from "react-router-dom";

const PaypalInvoiceOverView = () => {

    const { invoiceObjRef, subscriptionObjRef, setInvoiceViewType, setIsVisibleSettingsView, setIsVisiblePaypalInvoiceView } = useContext(SettingsViewContext);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const navigate = useNavigate();

    const createSubscription = (data: any, actions: any) => {

        return new Promise<string>((resolve) => resolve(subscriptionObjRef.current.subscription_id) );

    }

    const onApprove = (data: OnApproveData, actions: OnApproveActions) => {

        return new Promise<void>( (resolve) => {

            resolve( postUpdateDatabaseMutation(data.subscriptionID!) );

        } );

    }

    const onCancel = (data: any, actions: any) => {

        setAlertObj( new AlertObj(true, 'Bestellvorgang abgebrochen', 'Du hast den Bestellvorgang abgebrochen.', standardAlertButton) );

    }

    const onError = (err: any) => {

        setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );

        console.log(err);

    }

    const { mutate: postUpdateDatabaseMutation } = useMutation({
        mutationFn: (subscriptionID: string) => postUpdateDatabase(subscriptionID, invoiceObjRef.current),
        onSuccess: (data) => {

            if (data.success) {

                const alertBtn = [{ title: 'Verstanden', handler: () => { 
                    
                    navigate('dashboard');

                    setInvoiceViewType( PaypalInvoiceViewType.InvoiceInputView );
                    setIsVisiblePaypalInvoiceView(false);
                    setIsVisibleSettingsView(false);
                
                } }];

                setAlertObj( new AlertObj( true, 'Bestellvorgang abgeschloßen', 'Danke für deinen Einkauf. Der Bestellvorgang wurde erfolgreich abgeschloßen!', alertBtn ) );

            } else {

                const alertBtns = [{title: 'Support', handler: () => window.location.href = 'https://eazyac.pro/web/contact/contact.html' }, { ...standardAlertButton[0], title: 'Verstanden' }];

                setAlertObj( new AlertObj( true, 'Bestellvorgang fehlerhaft', 'Sorry. Scheinbar ist etwas schief gelaufen. Bitte prüfe deine PayPal-Transaktionen und kontaktiere im Bedarfsfall den Support. Danke.', alertBtns ) );

            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 
        <>
        <div className="flex center-y w100prc">
            <div onClick={ () => setInvoiceViewType( PaypalInvoiceViewType.InvoiceInputView ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem', paddingRight: '40px'}}>{subscriptionObjRef.current.item_name} für {subscriptionObjRef.current.item_price.toFixed(2)} {subscriptionObjRef.current.item_currency}</div>
        </div>
        <div className="flex center-y w100prc color-lightblack" style={{ marginTop: '20px'}}>Rechnungsinformationen / <span className="color-black" style={{ paddingLeft: '3px', textDecoration: 'underline'}}>Überprüfen & Bestellen</span></div>
        <div className="flex center-y w100prc" style={{ marginLeft: '10px', marginTop: '30px' }}>{invoiceObjRef.current.user_title === 0 ? 'Herr' : 'Frau'} {invoiceObjRef.current.user_firstname} {invoiceObjRef.current.user_lastname}{invoiceObjRef.current.user_company.length > 0 ? `, ${invoiceObjRef.current.user_company}` : ''}</div>
        <div className="flex center-y w100prc" style={{ marginLeft: '10px', marginTop: '3px', marginBottom: '20px' }}>{invoiceObjRef.current.street}, {invoiceObjRef.current.zip} {invoiceObjRef.current.city}, {invoiceObjRef.current.country}</div>
        <PayPalButtons createSubscription={ createSubscription } onApprove={ onApprove } onCancel={ onCancel } onError={ onError }/>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default PaypalInvoiceOverView;