import { ReactElement, useContext, useState } from "react";
import { DiscountJsonObj, DiscountObj, ViewState } from "../../../methods/types";
import { PropertysContext } from "../../../contexts/PropertysContext";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";

type Props = {
    discount: DiscountObj;
    handleOnClick: (state: ViewState) => void;
}

const DiscountCard = ( { discount, handleOnClick }: Props ) => {

    const { propertys } = useContext(PropertysContext);
    const { selectedProperty } = useContext(PropertysViewContext);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const propertyDiscountIds: Array<{abz_id: number}> = selectedProperty.property_abz ? JSON.parse( selectedProperty.property_abz ) : [];

    const discountJsonObj: DiscountJsonObj = JSON.parse( discount.abz_json! );

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(
            
            <div className="flex center" onClick={ (e) => handleButtonClick(e, ViewState.Details) }>
                <div className="default-icon" style={{ width: '50px', fontSize: '1.5em' }}>{ propertyDiscountIds.find( dId => dId.abz_id === discount.abz_id ) !== undefined ? 'check' : '' }</div>
                <div className="flex center w100prc column">
                    <div className="flex center-y w100prc">{ discount.abz_name }</div>
                    <div className="flex center-y w100prc" style={{ paddingTop: '2px' }}>{ discountJsonObj.percentage ? 'Prozentualer' : 'Pauschaler' } Abzug von { discountJsonObj.val } { discountJsonObj.percentage ? '%' : propertys.currencyShort }</div>
                </div>
            </div>
            
        );
    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 
        <div className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: '20px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>
     );
}
 
export default DiscountCard;