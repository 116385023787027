import { useContext } from "react";
import StayDetailView from "../../stayComps/StayDetailView";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import BlockDetailView from "./BlockDetailView";
import ImportDetailView from "./ImportDetailView";

const MultiCalendarStayDetailsView = () => {

    const { setIsVisibleStayDetailsView } = useContext(OccupancyContext);
    const { stay } = useContext(StayDetailViewContext);

    return ( 

        <div id="multiCalendarStayDetailsView" className="shadowBox" style={{ width: stay.stay_status < 5 ? '600px' : '300px' }}>
            { stay.stay_status < 5 && <StayDetailView handleCancel={ () => setIsVisibleStayDetailsView(false) } /> }
            { stay.stay_status === 5 && <BlockDetailView /> }
            { stay.stay_status === 6 && <ImportDetailView /> }
        </div>

     );
}
 
export default MultiCalendarStayDetailsView;