import { useState } from "react";
import { AlertObj, CNViewType, Contact, ContactKey, InfoWidgetObj, Note } from "../methods/types";
import InputView from "./InputView";
import { createPortal } from "react-dom";
import ContactAddEditView from "./ContactAddEditView";
import ContactCard from "./ContactCard";
import InfoWidget from "./InfoWidget";
import ActionAlert from "./ActionAlert";

type Props = {

    type: CNViewType;
    contentArr: Array<Contact | Note>;
    style: any;
    updateData: (updatedArr: Array<Contact | Note>, type: CNViewType) => void;

}

const ContactNoteView = ( { type, contentArr, style, updateData }: Props ) => {

    const [isVisibleInfoWidget, setIsVisibleInfoWidget] = useState( new InfoWidgetObj() );

    const [isVisibleAddEditView, setIsVisibleContactAddEditView] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() )

    const contactPopUpArr: {'Telefonnummer': Array<string>, 'Email': Array<string>} = { 'Telefonnummer': ['Mobil', 'Heim', 'Arbeit'], 'Email': ['Email, privat', 'Email, Arbeit'] };
    const notePopUpArr: {'Notiz': Array<string>, 'Anlage': Array<string>} = { 'Notiz': ['Allgemein', 'Personen', 'Tiere', 'Verhalten', 'Vorlieben', 'Wichtig', 'Sonstiges'], 'Anlage': ['Ausweis', 'Reisepass', 'Führerschein', 'Mietvertrag', 'Sonstiges'] };

    //const mainPopUpData = (type == CNViewType.mailAndPhone) ? contactPopUpArr : notePopUpArr;
    const [mainPopUpData, setMainPopUpData] = useState( (type == CNViewType.MailAndPhone) ? contactPopUpArr : notePopUpArr );

    const [cnViewData, setCnViewData] = useState( type === CNViewType.MailAndPhone ? {cnKey: ContactKey.Phone, cnType:'Mobil', cnValue: '', cnData: '', index: -1, } : {cnKey: ContactKey.Note, cnType:'Allgemein', cnValue: '', cnData: '', index: -1, } );

    const showInfoWidget = (message: string) => {

        setIsVisibleInfoWidget( new InfoWidgetObj(true, message) );

    }

    const handleIsVisibleCAEView = (index: number | null) => {

        const emptyContactObj = {cnKey: ContactKey.Phone, cnType:'Mobil', cnValue: '', cnData: '', index: -1, };
        const emptyNoteObj = {cnKey: ContactKey.Note, cnType:'Allgemein', cnValue: '', cnData: '', index: -1, };

        let cnKey = '';
        let cnType = '';
        let cnValue = '';
        let cnDataString = '';

        if (index != null && index != -1) {

            let cnKeyEnum = ContactKey.Phone;

            if (type === CNViewType.MailAndPhone) {

                if ((contentArr[index] as Contact ).contact_key === 'phone') {
                    cnKeyEnum = ContactKey.Phone;
                } else if ((contentArr[index] as Contact ).contact_key === 'mail') {
                    cnKeyEnum = ContactKey.Mail;
                }

            } else {

                if ((contentArr[index] as Note ).note_key === 'note') {
                    cnKeyEnum = ContactKey.Note;
                } else if ((contentArr[index] as Note ).note_key === 'attachment') {
                    cnKeyEnum = ContactKey.Attachment;
                }

            }


            cnKey = cnKeyEnum;
            cnType = type === CNViewType.MailAndPhone ? ( contentArr[index] as Contact ).contact_art : ( contentArr[index] as Note ).note_art;
            cnValue = type === CNViewType.MailAndPhone ? ( contentArr[index] as Contact ).contact_value : ( contentArr[index] as Note ).note_val;
            cnDataString = type === CNViewType.MailAndPhone ? '' : ( contentArr[index] as Note ).note_data;

        }

        setCnViewData( index == null || index === -1 ? ( type === CNViewType.MailAndPhone ? emptyContactObj : emptyNoteObj ) : {cnKey: cnKey as ContactKey, cnType: cnType, cnValue: cnValue, cnData: cnDataString, index: index,} )

        if (cnDataString.length === 0) {

            setIsVisibleContactAddEditView(true);

        } else {

            setAlertObj( new AlertObj(true, 'Anhang', 'Anhang öffnen oder bearbeiten?', {data: cnDataString}) );

        }

    }

    const handleSaveData = () => {

        const tempArr = [...contentArr];

        let contact_key = '';

        if (cnViewData.cnKey === ContactKey.Phone) {
            contact_key = 'phone';
        } else if (cnViewData.cnKey === ContactKey.Mail) {
            contact_key = 'mail';
        } else if (cnViewData.cnKey === ContactKey.Note) {
            contact_key = 'note';
        } else if (cnViewData.cnKey === ContactKey.Attachment) {
            contact_key = 'attachment';
        }

        if (cnViewData.index === -1) {
            
            if (type === CNViewType.MailAndPhone) {
                tempArr.push( { contact_art: cnViewData.cnType, contact_key: contact_key, contact_value: cnViewData.cnValue, contact_view: 0, } );
            } else {
                tempArr.push( { note_art: cnViewData.cnType, note_key: contact_key, note_val: cnViewData.cnValue, note_data: cnViewData.cnData, note_view: 0, } );
            }

        } else {

            if (type === CNViewType.MailAndPhone) {
                tempArr[cnViewData.index] = { contact_art: cnViewData.cnType, contact_key: contact_key, contact_value: cnViewData.cnValue, contact_view: 0, } ;
            } else {
                tempArr[cnViewData.index] = { note_art: cnViewData.cnType, note_key: contact_key, note_val: cnViewData.cnValue, note_data: cnViewData.cnData, note_view: 0, } ;
            }

        }

        updateData(tempArr, type);

        showInfoWidget('Datensatz hinzugefügt.')

    }

    const handleDeleteData = () => {

        const tempArr = [...contentArr];

        tempArr.splice(cnViewData.index, 1);

        showInfoWidget('Datensatz gelöscht.');

        updateData(tempArr, type);

    }

    const handleOpenAEViewFromAlert = () => {

        setAlertObj( new AlertObj() );

        setIsVisibleContactAddEditView(true);

    }

    const handleOpenFile = (obj: any) => {

        const win = window.open();
        win!.document.write('<iframe src="' + obj.data  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');

        setAlertObj( new AlertObj() );

    }

    const getIconName = (cnKey: string) => {

        if (cnKey === 'phone') {
            return 'contact_phone';
        } else if (cnKey === 'mail') {
            return 'contact_mail';
        } else if (cnKey === 'note') {
            return 'short_text';
        } else if (cnKey === 'attachment') {
            return 'attachment';
        } else {
            return '';
        }

    }

    return ( 

        <>
        <InputView title={type} style={style}>
            <div onClick={ ()=> handleIsVisibleCAEView(null) } className="clickable-icon" style={{ position: 'absolute', right: '0', top: '0', padding: '5px'}}>add_circle</div>
            
            { contentArr.length === 0 ? <div className="flex center w100prc h100prc color-lightblack">{ type == CNViewType.MailAndPhone ? 'Kein Kontakt vorhanden' : 'Keine Notizen/Anlagen vorhanden' }</div> :
            <div className="flex center-y w100prc h100prc" style={{overflow: 'visible', gap: '10px' }}>
            { contentArr.map( (obj, index) => <ContactCard key={index} index={index} onClick={ handleIsVisibleCAEView } iconName={getIconName( type == CNViewType.MailAndPhone ? (obj as Contact).contact_key : (obj as Note).note_key )} titleLabel={ type == CNViewType.MailAndPhone ? (obj as Contact).contact_art : (obj as Note).note_art } subLabel={ type == CNViewType.MailAndPhone ? (obj as Contact).contact_value : (obj as Note).note_val }/> ) }
            </div>
            }
        </InputView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId="cnalert" title={alertObj.title} message={alertObj.msg} buttons={ [{title: 'Öffnen', handler: ()=>{ handleOpenFile(alertObj.object!) }}, {title: 'Bearbeiten', handler: handleOpenAEViewFromAlert }] } handleClose={() => setAlertObj(new AlertObj())} />, document.body) }
        { isVisibleInfoWidget.isVisible && createPortal( <InfoWidget message={isVisibleInfoWidget.msg} handleClose={(isVisible) => setIsVisibleInfoWidget( new InfoWidgetObj(isVisible) )} />, document.body) }
        { isVisibleAddEditView && createPortal( <ContactAddEditView type={type} mainData={mainPopUpData} cnData={cnViewData} updateCnData={(obj)=>{ setCnViewData( obj ) }} isVisibleAddEditView={isVisibleAddEditView} setIsVisibleContactAddEditView={ (val) => setIsVisibleContactAddEditView(val) } saveData={handleSaveData} deleteData={handleDeleteData}/>, document.body ) }
        </>

     );
}
 
export default ContactNoteView;