import { DefaultFetchResult } from "./types";

export const fetchStatisticsSmallData = async (property_id: string,startMonth: string, endMonth: string, year: string) => {

    const data = new URLSearchParams();

    data.append('property_id', property_id);
    data.append('start_month', startMonth);
    data.append('end_month', endMonth);
    data.append('year', year);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=chart&do=dashboard_statistics`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content.obj;

}

export const fetchChartRevProp = async (property: string, propertyId: string, startMonth: string, endMonth: string, year: string) => {

    const data = new URLSearchParams();

    data.append('property_id', propertyId);
    data.append('property_name', property);
    data.append('start_month', startMonth);
    data.append('end_month', endMonth);
    data.append('year', year);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=chart&do=einnahmen_je_immobilie`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content.obj;

}

export const fetchChartRevCat = async (property: string, propertyId: string, startMonth: string, endMonth: string, year: string) => {

    const data = new URLSearchParams();

    data.append('property_id', propertyId);
    data.append('property_name', property);
    data.append('start_month', startMonth);
    data.append('end_month', endMonth);
    data.append('year', year);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=chart&do=einnahmen_nach_kategorie`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content.obj;

}

export const fetchChartOccuProp = async (property: string, propertyId: string, startMonth: string, endMonth: string, year: string) => {

    const data = new URLSearchParams();

    data.append('property_id', propertyId);
    data.append('property_name', property);
    data.append('start_month', startMonth);
    data.append('end_month', endMonth);
    data.append('year', year);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=chart&do=auslastung_je_immobilie`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content.obj;

}

export const fetchChartOccuAll = async (property: string, propertyId: string, startMonth: string, endMonth: string, year: string) => {

    const data = new URLSearchParams();

    data.append('property_id', propertyId);
    data.append('property_name', property);
    data.append('start_month', startMonth);
    data.append('end_month', endMonth);
    data.append('year', year);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=chart&do=gesamt_auslastung`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content.obj;

}

export const fetchChartOccuAllMonth = async (property: string, propertyId: string, startMonth: string, endMonth: string, year: string) => {

    const data = new URLSearchParams();

    data.append('property_id', propertyId);
    data.append('property_name', property);
    data.append('start_month', startMonth);
    data.append('end_month', endMonth);
    data.append('year', year);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=chart&do=monatliche_auslastung_aller_immobilien`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content.obj;

}

export const fetchChartRevMonth = async (property: string, propertyId: string, startMonth: string, endMonth: string, year: string) => {

    const data = new URLSearchParams();

    data.append('property_id', propertyId);
    data.append('property_name', property);
    data.append('start_month', startMonth);
    data.append('end_month', endMonth);
    data.append('year', year);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=chart&do=monatliche_einnahmen`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content.obj;

}