import { DefaultFetchResult } from "./types";

export const fetchGermanHolidays = async (year: number) => {

    const result = await fetch(
        `https://feiertage-api.de/api/?jahr=${year}&nur_daten=1`,
        {
        method: 'GET',
        }
      )

    const content = await result.json();
    return content;

}