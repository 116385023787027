import { useContext, useId, useState } from "react";
import ModalView from "../generalComps/ModalView";
import { AlertObj, Contact, ConversationObj, Guest, MessageListType, OfferObject } from "../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import { getFullname } from "../methods/standard.methods";
import { emptyGuest } from "../methods/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postImportGuest } from "../methods/http.guests.methods";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";

type Props = {
    conversationObj: ConversationObj;
    handleClose: () => void;
    style?: React.CSSProperties | undefined;
}

const MailGuestDataView = ( { conversationObj, style, handleClose }: Props ) => {

    const { messageListType } = useContext(MessageDetailViewContext);

    const queryClient = useQueryClient();

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const oldGuest: Guest = { guest_id: conversationObj.guest_id,
        guest_title: conversationObj.guest_title,
        guest_firstname: conversationObj.guest_firstname,
        guest_lastname: conversationObj.guest_lastname,
        guest_company: conversationObj.guest_company,
        guest_street: conversationObj.guest_street,
        guest_zip: conversationObj.guest_zip,
        guest_city: conversationObj.guest_city,
        guest_country: conversationObj.guest_country,
        guest_contact: conversationObj.guest_contact,
        guest_birthday_reminder: 0,
        guest_rate: 2,
        guest_blacklist: 0  };

    const oldContactArr: Array<Contact> = oldGuest.guest_contact ? JSON.parse( oldGuest.guest_contact ) : [];
    const oldGuestName = getFullname(oldGuest.guest_title!, oldGuest.guest_firstname!, oldGuest.guest_lastname!, oldGuest.guest_company!);

    const [guest, setGuest] = useState<Guest>( JSON.parse( conversationObj.korres_guest_data ) );

    const contactArr: Array<Contact> = guest.guest_contact ? JSON.parse( guest.guest_contact ) : [];

    const guestName = getFullname(guest.guest_title!, guest.guest_firstname!, guest.guest_lastname!, guest.guest_company!);

    const { mutate: postImportGuestMutation } = useMutation({
        mutationFn: ( newGuest: Guest ) => postImportGuest( newGuest ),
        onSuccess: () => {

            if (messageListType === MessageListType.Unread) {
                queryClient.invalidateQueries( { queryKey: ['fetchNewMails'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchMails'] } );
            }

            handleClose();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSave = () => {

        const alertBtns = [{ title: 'Importieren', handler: () => {

            const newContactArr = contactArr.concat( oldContactArr );

            const newGuest = { ...guest, 
                                    guest_id: conversationObj.guest_id,
                                    guest_note: conversationObj.guest_note,
                                    guest_contact: JSON.stringify( newContactArr ), };

            postImportGuestMutation( newGuest );

        } }, { ...standardAlertButton[0], title: 'Abbrechen' }];

        setAlertObj( new AlertObj( true, 'Achtung', 'Die bisherigen Gästedaten werden mit diesen Daten überschrieben!', alertBtns ) );

    }

    const handleCNClick = (cnObj: {cnKey: string, cnType: string, cnValue: string, cnData: string }) => {

        const cancelButton = {title: 'Abbrechen', handler: () => setAlertObj(new AlertObj() )};

        if (cnObj.cnKey === 'phone') {
            
            const buttons = Array( {title: 'Anrufen', handler: () => {

                window.location.href=`tel:${cnObj.cnValue}`;

                setAlertObj( new AlertObj() );

            }},
            cancelButton, );

            setAlertObj( new AlertObj(true, 'Anruf', `Möchtest du ${cnObj.cnValue} anrufen?`, buttons) );

        } else if (cnObj.cnKey === 'mail') {
            
            const buttons = Array( {title: 'Schreiben', handler: () => {

                window.location.href=`mailto:${cnObj.cnValue}`;

                setAlertObj( new AlertObj() );

            }},
            cancelButton, );

            setAlertObj( new AlertObj(true, 'Schreiben', `Möchtest du ${cnObj.cnValue} schreiben?`, buttons) );

        } 

    }

    return ( 

        <>
        <ModalView modalViewId="mailGuestDataView"  style={style} handleClose={handleClose}>
            <div className="flex center-y" style={{ width: "100%" }}>
                <div onClick={handleClose} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Gästedaten</div>
                <div title="Daten importieren" onClick={handleSave} className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>published_with_changes</div>
            </div>
            <div className="shadowBox" style={{ marginTop: '10px' }}>
                Folgende Daten wurden durch den Gast bei Annahme des Angebots übermittelt. Durch Importieren werden die bisherigen Gästedaten mit diesen überschrieben.
            </div>
            <div className="flex center-y" style={{ marginTop: '10px' }}>ÜBERMITTELTE DATEN</div>
            <div className="shadowBox column" style={{ marginTop: '10px' }}>
                <div className="flex center w100prc color-lightblack">{guest.guest_title!}</div>
                <div className="flex center w100prc">{guestName.fullname}</div>
                <div className="flex center w100prc color-lightblack">{guestName.company}</div>
                <div className="flex center w100prc" style={{marginTop: '10px'}}>{guest.guest_street!}</div>
                <div className="flex center w100prc">{`${guest.guest_zip!} ${guest.guest_city!}`}</div>
                <div className="flex center w100prc">{guest.guest_country!}</div>

                { guest.guest_birthday && guest.guest_birthday !== '0000-00-00' && guest.guest_birthday !== '1500-01-01' && 
                    <div className="flex center w100prc color-lightblack" style={{ marginTop: '10px' }}>
                        { new Date(guest.guest_birthday!).toLocaleDateString('de-DE') }
                        <div className="default-icon">cake</div>
                    </div>
                }
            </div>

            {
                contactArr.length > 0 &&

                <div className="shadowBox column" style={{ marginTop: '10px' }}>
                    { contactArr.map( (contact, i) => { 
                    
                    return(
                        <div onClick={() => handleCNClick({cnKey: contact.contact_key, cnType: contact.contact_art, cnValue: contact.contact_value, cnData: ''})} key={i} className="flex center cursor-pointer border-bottom-strong" style={{padding: '5px 0', marginTop: i === 0 ? '10px' : '0', width: '80%', marginLeft: '10%'}}>
                            <div className="flex center default-icon" style={{fontSize: '0.8rem', width: '40px'}}>{ contact.contact_key === 'phone' ? 'contact_phone' : 'contact_mail' }</div>
                            <div className="flex center color-lightblack" style={{ padding: '0 5px 0 0', fontSize: '0.8rem', width: '40%' }}>{ contact.contact_art }</div>
                            <div className="flex center" style={{ width: '60%', fontSize: '0.9rem' }}>{ contact.contact_value }</div>
                        </div> 
                    );

                    } ) }
                </div>

            }

        <div className="flex center-y" style={{ marginTop: '10px' }}>BESTEHENDE DATEN</div>
            <div className="shadowBox column" style={{ marginTop: '10px' }}>
                <div className="flex center w100prc color-lightblack">{oldGuest.guest_title!}</div>
                <div className="flex center w100prc">{oldGuestName.fullname}</div>
                <div className="flex center w100prc color-lightblack">{oldGuestName.company}</div>
                <div className="flex center w100prc" style={{marginTop: '10px'}}>{oldGuest.guest_street!}</div>
                <div className="flex center w100prc">{`${oldGuest.guest_zip!} ${oldGuest.guest_city!}`}</div>
                <div className="flex center w100prc">{oldGuest.guest_country!}</div>

                { oldGuest.guest_birthday && oldGuest.guest_birthday !== '0000-00-00' && oldGuest.guest_birthday !== '1500-01-01' && 
                    <div className="flex center w100prc color-lightblack" style={{ marginTop: '10px' }}>
                        { new Date(oldGuest.guest_birthday!).toLocaleDateString('de-DE') }
                        <div className="default-icon">cake</div>
                    </div>
                }
            </div>

            {
                oldContactArr.length > 0 &&

                <div className="shadowBox column" style={{ marginTop: '10px' }}>
                    { oldContactArr.map( (contact, i) => { 
                    
                    return(
                        <div onClick={() => handleCNClick({cnKey: contact.contact_key, cnType: contact.contact_art, cnValue: contact.contact_value, cnData: ''})} key={i} className="flex center cursor-pointer border-bottom-strong" style={{padding: '5px 0', marginTop: i === 0 ? '10px' : '0', width: '80%', marginLeft: '10%'}}>
                            <div className="flex center default-icon" style={{fontSize: '0.8rem', width: '40px'}}>{ contact.contact_key === 'phone' ? 'contact_phone' : 'contact_mail' }</div>
                            <div className="flex center color-lightblack" style={{ padding: '0 5px 0 0', fontSize: '0.8rem', width: '40%' }}>{ contact.contact_art }</div>
                            <div className="flex center" style={{ width: '60%', fontSize: '0.9rem' }}>{ contact.contact_value }</div>
                        </div> 
                    );

                    } ) }
                </div>

            }
            
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default MailGuestDataView;