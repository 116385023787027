import { useMutation } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import PopUp from "../generalComps/PopUp";
import { fetchGuestsAll } from "../methods/http.guests.methods";
import { filterGuests, getFullname } from "../methods/standard.methods";
import { Guest } from "../methods/types";

type Props = {
    handleOnClick: (e: React.SyntheticEvent, guest: Guest) => void;
    handleClosePopUp: () => void;
}
const GuestsSelectPopUpView = ( { handleOnClick, handleClosePopUp }: Props ) => {

    const [guests, setGuests] = useState<Array<Guest>>([]);

    const [searchString, setSearchString] = useState('');

    const { isPending, mutate: fetchGuestsForSelectPopUpMutation } = useMutation( {
        mutationFn: () => fetchGuestsAll(),
        onSuccess: (data) => {

            if (data.success) {
                setGuests(data.obj);
            }

        },
        onError: (error) => console.log(error),
    } );

    useEffect( () => { 

        fetchGuestsForSelectPopUpMutation();
    
    }, [] );

    const filteredGuests = useMemo( () => filterGuests(guests, searchString, '', false, -1), [guests, searchString]);
    
    return (
        <PopUp style={{ marginTop: '60px', height: 'auto' }} handleClosePopUp={handleClosePopUp}>

            <div className="flex w100prc h100prc" style={{flexDirection: 'column', justifyContent: 'flex-start'}}>

                <div className="flex w100prc input-template-style" style={{ flexDirection: 'row', flexWrap: 'nowrap', padding: '10px 0', height: '50px' }}>
                    <div className="default-icon flex center h100prc" style={{ width: '40px', padding: '0 0 0 15px' }}>search</div>
                    <input onChange={ (e) => setSearchString(e.target.value) } value={searchString} className="w100prc h100prc" type="text" placeholder='Suche'/>
                </div>

                { isPending && <div className="flex center w100prc color-lightblack" style={{padding: '20px'}}>Lade Gäste...</div> }
                
                <div className="flex w100prc" style={{flexDirection: 'column', overflowY: 'scroll', maxHeight: '500px'}}>
                { filteredGuests.map((guest) => {
                    
                    const guestName = getFullname(guest.guest_title!, guest.guest_firstname!, guest.guest_lastname!, guest.guest_company!);

                    return (

                    <div
                        className="lbl-container"
                        onClick={(e) => { handleOnClick(e, guest) } }
                        key={guest.guest_id}
                    >
                        <div className="flex w100prc" style={{flexDirection: 'column', justifyContent: 'flex-start'}}>
                            <div className="flex w100prc color-lightblack">{guest.guest_title!}</div>
                            <div className="flex truncate-text" style={{width: '170px'}}>{guestName.fullname}</div>
                            <div className="color-lightblack" style={{fontSize: '0.8em', paddingTop: '2px'}}>{guestName.company}</div>
                        </div>
                    </div>
                    );
                })}
                </div>
            </div>
        
        </PopUp>
    );

}
 
export default GuestsSelectPopUpView;