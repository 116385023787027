import { CSSProperties, useContext, useId, useState } from "react";
import { PropertysViewContext } from "../contexts/PropertysMainContext";
import { AlertObj } from "../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import { useMutation } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../methods/http.property.methods";

type Props = {
    style?: CSSProperties | undefined;
}

const PropertyDetailViewServices = ( { style }: Props ) => {

    const { selectedProperty, setSelectedProperty } = useContext(PropertysViewContext);

    const [serviceArr, setServiceArr] = useState<Array<{service: string, status: boolean, icon: string}>>( selectedProperty.property_service ? JSON.parse( selectedProperty.property_service  ) : [] );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const handleToggleOnClick = (index: number) => {

        const newObj = { ...serviceArr[index], status: !serviceArr[index].status };

        const newArr = [...serviceArr];
        newArr[index] = newObj;

        postUpdatePropertySingleMutation( newArr );

    }

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: (serviceArr: Array<{service: string, status: boolean, icon: string}>) => postUpdatePropertySingleValue(selectedProperty.property_id, 'property_service', JSON.stringify( serviceArr )),
        onSuccess: (_, variables) => {

            setServiceArr( variables );
            setSelectedProperty( prev => ( {...prev, property_service: JSON.stringify( variables ) } ) );

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    return ( 

        <>
        <div className="flex column shadowBox" style={style}>
            <div className="flex center-y w100prc">
                SERVICELEISTUNGEN & AUSSTATTUNG
            </div>
            <div className="services-wrapper">
                {
                    serviceArr.map( ( obj, index ) => {

                        return(
                            <div key={obj.service} className="service border-bottom-strong">
                                <div className="default-icon" style={{ width: '40px' }}>{ obj.icon }</div>
                                <div className="flex w100prc" style={{ textDecoration: obj.status ? 'none' : 'line-through', opacity: obj.status ? '1' : '0.3' }}>{ obj.service }</div>
                                <div onClick={ () => handleToggleOnClick(index) } className="clickable-icon" style={{ width: '40px', fontSize: '1.2em' }}>{ obj.status ? 'toggle_on' : 'toggle_off' }</div>
                            </div>
                        )

                    } )
                }
            </div>
        </div>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default PropertyDetailViewServices;