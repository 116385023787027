import { DefaultFetchResult } from "./types";

export const fetchInvoiceNumber = async (invoiceType: number) => {

    const data = new URLSearchParams();
  
    data.append('invoice_art', invoiceType.toString());
  
    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=invoice&do=generate_number',
        {
        method: 'POST', 
        body: data,
       'credentials': 'include',
        }
      )
  
    const content: DefaultFetchResult = await result.json();
  
    return content;
  
  }