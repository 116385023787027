import { ReactElement, useState } from "react";
import { ViewState } from "../../methods/types";
import { ImportUrlObj } from "../../methods/types";

type Props = {
    importObj: ImportUrlObj;
    handleOnClick: (state: ViewState) => void;
}

const CalendarImportCard = ( { importObj, handleOnClick }: Props ) => {

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(
            
            <div className="flex center" onClick={ (e) => handleButtonClick(e, ViewState.Details) }>
                <div className="flex center w100prc column" style={{ paddingRight: '10px' }}>
                    <div className="flex center-y w100prc">
                        { importObj.name }
                        { importObj.toggle === 'toggle_off' && <span style={{ padding: '0 5px', fontSize: '0.7em', color: 'red' }}>IMPORT DEAKTIVIERT</span> }
                    </div>
                    <div className="flex center-y w100prc standard-fontsize" style={{ padding: '2px 0' }}>{ importObj.link }</div>
                </div>
                <div className="clickable-icon flex center" style={{ fontSize: '2.0em', paddingTop: '5px' }}>{ importObj.toggle }</div>
            </div>
            
        );
    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 
        <div className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: '20px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>
     );
}
 
export default CalendarImportCard;