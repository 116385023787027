import { createContext, MutableRefObject, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { ConversationViewType, DateObj, PdfContentObj, PdfDesignMainObj, Property, Stay, TemplatesObj } from "../methods/types";
import { emptyProperty } from "../methods/constants";
import { PropertysContext } from "./PropertysContext";
import { getDateObj, initDateArray } from "../methods/standard.methods";
import { useMutation } from "@tanstack/react-query";
import { fetchStaysWithBlockAll } from "../methods/http.stays.methods";
import { fetchIcalStays } from "../methods/http.calendar.methods";

type Props = {
    children: ReactNode;
}

type ContextType = {
    selectedProperty: Property,
    setSelectedProperty: React.Dispatch<React.SetStateAction<Property>>,
    startMonth: number,
    setStartMonth: React.Dispatch<React.SetStateAction<number>>,
    endMonth: number,
    setEndMonth: React.Dispatch<React.SetStateAction<number>>,
    year: number,
    setYear: React.Dispatch<React.SetStateAction<number>>,
    filteredPropertys: Array<Property>,
    setFilteredPropertys: React.Dispatch<React.SetStateAction<Array<Property>>>,
    dates: Array<DateObj>,
    setDates: React.Dispatch<React.SetStateAction<Array<DateObj>>>,
    stays: Array<Stay>,
    isVisibleStayDetailsView: boolean,
    setIsVisibleStayDetailsView: React.Dispatch<React.SetStateAction<boolean>>,
    isVisibleStayAddEditView: boolean,
    setIsVisibleStayAddEditView: React.Dispatch<React.SetStateAction<boolean>>,
    selectedStartDate: DateObj | null,
    setSelectedStartDate: React.Dispatch<React.SetStateAction<DateObj | null>>,
    selectedEndDate: DateObj | null,
    setSelectedEndDate: React.Dispatch<React.SetStateAction<DateObj | null>>,
    selectedDatesRow: number | null,
    setSelectedDatesRow: React.Dispatch<React.SetStateAction<number | null>>,
    reloadStayData: () => void, };
    

export const OccupancyContext = createContext<ContextType>( { 
    selectedProperty: emptyProperty,
    setSelectedProperty: () => {},
    startMonth: 0,
    setStartMonth: () => {},
    endMonth: 11,
    setEndMonth: () => {},
    year: new Date().getFullYear(),
    setYear: () => {},
    filteredPropertys: [],
    setFilteredPropertys: () => {},
    dates: [],
    setDates: () => {},
    stays: [],
    isVisibleStayDetailsView: false,
    setIsVisibleStayDetailsView: () => {},
    isVisibleStayAddEditView: false,
    setIsVisibleStayAddEditView: () => {},
    selectedStartDate: null,
    setSelectedStartDate: () => {},
    selectedEndDate: null,
    setSelectedEndDate: () => {},
    selectedDatesRow: null,
    setSelectedDatesRow: () => {},
    reloadStayData: () => {},
 } );

const OccupancyContextProvider = ({ children }: Props) => {

    const { propertys } = useContext(PropertysContext);

    const [selectedProperty, setSelectedProperty]  = useState( emptyProperty );
    const [startMonth, setStartMonth]  = useState( 0 );
    const [endMonth, setEndMonth]  = useState( 11 );
    const [year, setYear]  = useState( new Date().getFullYear() );
    const [filteredPropertys, setFilteredPropertys] = useState<Array<Property>>([]);
    const [dates, setDates] = useState<Array<DateObj>>([]);
    const [stays, setStays] = useState<Array<Stay>>([]);
    const [isVisibleStayDetailsView, setIsVisibleStayDetailsView] = useState(false);
    const [isVisibleStayAddEditView, setIsVisibleStayAddEditView] = useState(false);
    const [selectedStartDate, setSelectedStartDate] = useState< DateObj | null >(null);
    const [selectedEndDate, setSelectedEndDate] = useState< DateObj | null >(null);
    const [selectedDatesRow, setSelectedDatesRow] = useState< number | null >(null);

    const startDateRef = useRef( getDateObj( new Date( year, startMonth, 1, 0, 0, 0, 0 ), 0 ) );
    const endDateRef = useRef( getDateObj( new Date( year, endMonth + 1, 0, 0, 0, 0, 0), 0 ) );

    const { mutate: fetchStaysWithBlockAllMutation } = useMutation({
        mutationFn: () => fetchStaysWithBlockAll( startDateRef.current.dateStringUS, endDateRef.current.dateStringUS ),
        onSuccess: (data) => {

            const fetchedStays: Array<Stay> = data.obj ? data.obj : [];

            fetchIcalStaysMutation( fetchedStays );

        },

    });

    const { mutate: fetchIcalStaysMutation } = useMutation({
        mutationFn: () => fetchIcalStays( startDateRef.current.dateStringUS, endDateRef.current.dateStringUS ),
        onSuccess: (data, eazyacStays: Array<Stay>) => {

            const fetchedIcalStays: Array<Stay> = data.obj ? data.obj : [];

            const concatedStays = fetchedIcalStays.concat( eazyacStays );

            if ( selectedProperty.property_id === -1 ) {

                setStays( concatedStays );

            } else {

                setStays( concatedStays.filter( stay => stay.stay_property === selectedProperty.property_id ) );

            }

        },

    });

    const reloadStayData = () => {

        setSelectedStartDate(null);
        setSelectedEndDate(null);
        setSelectedDatesRow(null);
        
        fetchStaysWithBlockAllMutation();
    }

    useEffect( () => {

        // const startDate = getDateObj( new Date( year, startMonth, 1, 0, 0, 0, 0 ), 0 );
        // const endDate = getDateObj( new Date( year, endMonth + 1, 0, 0, 0, 0, 0), 0 );
        startDateRef.current = getDateObj( new Date( year, startMonth, 1, 0, 0, 0, 0 ), 0 );
        endDateRef.current = getDateObj( new Date( year, endMonth + 1, 0, 0, 0, 0, 0), 0 );

        setDates( initDateArray(startDateRef.current, endDateRef.current) );

        fetchStaysWithBlockAllMutation();

    }, [startMonth, endMonth, year]);

    useEffect( () => {

        if (propertys.array.length > 0) {

            if (selectedProperty.property_id === -1) {

                setFilteredPropertys([...propertys.array]);
        
            } else {
        
                setFilteredPropertys( propertys.array.filter( (property) => property.property_id === selectedProperty.property_id ) );
        
            }

            fetchStaysWithBlockAllMutation();

        }

    }, [selectedProperty, propertys]);

    return ( 
        <OccupancyContext.Provider value={ { selectedProperty, 
                                            setSelectedProperty, 
                                            startMonth, 
                                            setStartMonth, 
                                            endMonth, 
                                            setEndMonth, 
                                            year, 
                                            setYear, 
                                            filteredPropertys, 
                                            setFilteredPropertys, 
                                            dates, 
                                            setDates, 
                                            stays, 
                                            isVisibleStayDetailsView, 
                                            setIsVisibleStayDetailsView,
                                            isVisibleStayAddEditView, 
                                            setIsVisibleStayAddEditView,
                                            selectedStartDate,
                                            setSelectedStartDate,
                                            selectedEndDate,
                                            setSelectedEndDate,
                                            selectedDatesRow,
                                            setSelectedDatesRow, 
                                            reloadStayData, } }>
            {children}
        </OccupancyContext.Provider>
     );
}
 
export default OccupancyContextProvider;