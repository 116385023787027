import { Link } from "react-router-dom";

const ErrorPage = () => {

  return (

    <div id="infoBox" className="shadowBox center wrap" style={{width: '40%', margin: '100px 0 0 0'}}>
    <div className="flex-container center color-cyan" style={{width: '100%', padding: '0 0 10px 0', fontSize: '1.5rem'}}>Upss...das hat nicht funktioniert.</div>
    <div className="flex-container center" style={{width: '100%'}}>
        <div className="default-icon flex-container center" style={{width: '30%', fontSize: '5.0rem', color: 'red'}}>error</div>
        <div className="flex-container center" style={{width: '100%'}}>Die angeforderte Seite existiert nicht.</div>
        <Link to='/dashboard' className="flex-container center" style={{width: '25%'}}><div title="Zum Dashboard" className="clickable-icon" style={{fontSize: '2.0rem'}}>dashboard</div></Link>
    </div>
    </div>

  );
}

export default ErrorPage;