import { useContext } from "react";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import MailCard from "./MailCard";

const MessageDetailView = () => {

    const { seperatorObj } = useContext(MessageDetailViewContext);

    return ( 

        <div className="w100prc h100prc" style={{ overflowX: 'hidden', overflowY: 'scroll', }}>
            { ( seperatorObj === null || Object.keys(seperatorObj).length === 0) && <div className="shadowBox center color-lightblack" style={{marginTop: '20px'}}>Bisher sind keine Nachrichten verfügbar.</div> }
            { Object.keys(seperatorObj!).map( seperatorText => {

                return(

                    <div key={seperatorText} className="flex column w100prc" style={{ margin: '10px 0 0 0', padding: '10px', overflow: 'clip' }}>
                        <div className="text-seperator">{ seperatorText }</div>

                        {
                            seperatorObj![seperatorText].map( obj => {

                                return(

                                    <MailCard key={obj.korres_id} obj={obj} />

                                )

                            })
                        }

                    </div>

                )

            }) }
        </div>
    
     );
}
 
export default MessageDetailView;