import '../css/navigation-bar.css';
import logo from '../images/icon50x50.png';
import { NavLink, useNavigate } from 'react-router-dom';
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import SettingsWrapper from '../settingsComps/SettingsWrapper';
import { SettingsViewContext } from '../contexts/SettingsContext';
import PopUp from './PopUp';
import MenuButton from './MenuButton';
import { useCookies } from 'react-cookie';

type Props = {

    type?: string;

}

function NavigationBarTop( { type }: Props ) {

    const { isVisibleSettingsView, setIsVisibleSettingsView } = useContext(SettingsViewContext);

    const [isVisibleDarkModeView, setIsVisibleDarkModeView] = useState(false);
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const [cookies, setCookie] = useCookies(["theme"]);

    const navigate = useNavigate();

    const darkModeTitle = useMemo( () => {

        const mode = cookies.theme;

        if ( mode === 'dark' ) {
            return 'Dunkelmodus';
        } else if ( mode === 'light' ) {
            return 'Hellmodus';
        } else if ( mode === 'auto' ) {
            return 'Automatisch';
        } else {
            return 'Automatisch';
        }

    }, [cookies.theme]);

    const darkModeIcon = useMemo( () => {

        const mode = cookies.theme;

        if ( mode === 'dark' ) {
            return 'dark_mode';
        } else if ( mode === 'light' ) {
            return 'light_mode';
        } else if ( mode === 'auto' ) {
            return 'contrast';
        } else {
            return 'contrast';
        }

    }, [cookies.theme]);

    useEffect( () => {

        const mode = cookies.theme;
        
        if ( mode === 'dark' ) {
            document.documentElement.classList.remove("light");
            document.documentElement.classList.add("dark");
        } else if ( mode === 'light' ) {
            document.documentElement.classList.remove("dark");
            document.documentElement.classList.add("light");
        } else if ( mode === 'auto' ) {
            document.documentElement.classList.remove("dark");
            document.documentElement.classList.remove("light");
        } else {
            document.documentElement.classList.remove("dark");
            document.documentElement.classList.remove("light");
        }

    }, [cookies.theme]);

    const handleDarkmodeClick = ( e: SyntheticEvent, mode: string ) => {

        setCookie('theme', mode);

        setIsVisibleDarkModeView(false);

        e.stopPropagation();

    }

    const handleNavigate = (e: SyntheticEvent, to: string) => {

        navigate(to);

        setIsVisibleMenu(false);

        e.stopPropagation();

    }

    return(
        <nav>
            <div className="flex-container center-y w100prc h100prc" style={{ overflow: 'visible' }}>
                <img onClick={ () => navigate('/start') } alt="Logo" src={logo} className="appIcon cursor-pointer"></img>
                {
                    type === 'eazyac' ?
                    <>
                    <ul>
                        { window.innerWidth > 759 ?
                        <>
                        <NavLink to="" style={{width: '20%'}}></NavLink>
                        <NavLink to="dashboard" style={{textDecoration: 'none'}}><div className="clickable-icon icon">dashboard</div><div className="title">Dashboard</div></NavLink>
                        <NavLink to="nachrichten" style={{textDecoration: 'none'}}><div className="clickable-icon icon">mail</div><div className="title">Nachrichten</div></NavLink>
                        <NavLink to="belegungsplan" style={{textDecoration: 'none'}}><div className="clickable-icon icon">date_range</div><div className="title">Belegungsplan</div></NavLink>
                        <NavLink to="statistik" style={{textDecoration: 'none'}}><div className="clickable-icon icon">bar_chart</div><div className="title">Statistik</div></NavLink>
                        <NavLink to="aufenthalte" style={{textDecoration: 'none'}}><div className="clickable-icon icon">nights_stay</div><div className="title">Aufenthalte</div></NavLink>
                        <NavLink to="gaeste" style={{textDecoration: 'none'}}><div className="clickable-icon icon">import_contacts</div><div className="title">Gäste</div></NavLink>
                        <NavLink to="immobilien" style={{textDecoration: 'none'}}><div className="clickable-icon icon">house</div><div className="title">Immobilien</div></NavLink>
                        <a onClick={ () => setIsVisibleSettingsView( prevVal => !prevVal ) } className="" style={{textDecoration: 'none'}}><div className="clickable-icon icon">settings</div><div className="title">Einstellungen</div></a>
                        <a onClick={ (e) => { setIsVisibleDarkModeView( prevVal => !prevVal ); e.stopPropagation(); } } className="" style={{ position: 'relative', textDecoration: 'none', width: '20%'}}>
                            <div className="clickable-icon icon">{ darkModeIcon }</div><div className="title">{ darkModeTitle }</div>
                            { isVisibleDarkModeView && 
                                <PopUp idTag="propertyDetailMenu" style={{ gap: '10px', minWidth: '200px', height: 'auto', fontSize: '1.0rem', marginTop: '70px', left: 'auto', right: '50px' }} handleClosePopUp={ () => setIsVisibleDarkModeView(false) } >
                                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'dark') } } leftIcon="dark_mode" buttonTitle="Dunkelmodus" />
                                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'light') } } leftIcon="light_mode" buttonTitle="Hellmodus" />
                                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'auto') } } leftIcon="contrast" buttonTitle="Automatisch" />
                                </PopUp>
                            }
                        </a>
                        <NavLink to="logout" style={{textDecoration: 'none'}}><div className="clickable-icon icon">exit_to_app</div><div className="title">Abmelden</div></NavLink>
                        </>
                        :
                        <div onClick={ () => setIsVisibleMenu(prev => !prev) } className="clickable-icon flex w100prc cursor-pointer" style={{ fontSize: '2.0em', justifyContent: 'flex-end' }}>menu</div>
                     }
                    </ul>
                    { isVisibleMenu && 
                        createPortal( 

                            <div className="nav-menu">
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'dashboard'); } } leftIcon="dashboard" rightIcon="chevron_right" buttonTitle="Dashboard" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'nachrichten'); } } leftIcon="mail" rightIcon="chevron_right" buttonTitle="Nachrichten" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'belegungsplan'); } } leftIcon="date_range" rightIcon="chevron_right" buttonTitle="Belegungsplan" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'statistik'); } } leftIcon="bar_chart" rightIcon="chevron_right" buttonTitle="Statistik" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'aufenthalte'); } } leftIcon="nights_stay" rightIcon="chevron_right" buttonTitle="Aufenthalte" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'gaeste'); } } leftIcon="import_contacts" rightIcon="chevron_right" buttonTitle="Gäste" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'immobilien'); } } leftIcon="house" rightIcon="chevron_right" buttonTitle="Immobilien" />
                                <MenuButton onClick={ () => { setIsVisibleSettingsView( prev => !prev ); setIsVisibleMenu(false); } } leftIcon="settings" rightIcon="chevron_right" buttonTitle="Einstellungen" />

                                <MenuButton style={{ marginTop: '20px'}} onClick={ (e) => { handleDarkmodeClick(e, 'dark') } } leftIcon="dark_mode" rightIcon="chevron_right" buttonTitle="Dunkelmodus" />
                                <MenuButton onClick={ (e) => { handleDarkmodeClick(e, 'light') } } leftIcon="light_mode" rightIcon="chevron_right" buttonTitle="Hellmodus" />
                                <MenuButton onClick={ (e) => { handleDarkmodeClick(e, 'auto') } } leftIcon="contrast" rightIcon="chevron_right" buttonTitle="Automatisch" />

                                <MenuButton style={{ marginTop: '20px'}} onClick={ (e) => { handleNavigate(e, '/logout'); } } leftIcon="exit_to_app" rightIcon="chevron_right" buttonTitle="Abmelden" />
                            </div>, document.body 

                        )
                    }
                    </>
                    :
                    
                    type === 'website' ?

                    <>
                    <ul>
                        { window.innerWidth > 759 ?
                        <>
                        <NavLink to="" style={{width: '20%'}}></NavLink>
                        <NavLink to="start" style={{textDecoration: 'none'}}><div className="clickable-icon icon">apps</div><div className="title">Funktionen</div></NavLink>
                        <NavLink to="preise" style={{textDecoration: 'none'}}><div className="clickable-icon icon">euro</div><div className="title">Preise</div></NavLink>
                        <NavLink to="kontakt" style={{textDecoration: 'none'}}><div className="clickable-icon icon">contact_support</div><div className="title">Kontakt</div></NavLink>
                        
                        <a onClick={ (e) => { setIsVisibleDarkModeView( prevVal => !prevVal ); e.stopPropagation(); } } className="" style={{ position: 'relative', textDecoration: 'none', width: '20%'}}>
                            <div className="clickable-icon icon">{ darkModeIcon }</div><div className="title">{ darkModeTitle }</div>
                            { isVisibleDarkModeView && 
                                <PopUp idTag="propertyDetailMenu" style={{ gap: '10px', minWidth: '200px', height: 'auto', fontSize: '1.0rem', marginTop: '70px', left: 'auto', right: '50px' }} handleClosePopUp={ () => setIsVisibleDarkModeView(false) } >
                                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'dark') } } leftIcon="dark_mode" buttonTitle="Dunkelmodus" />
                                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'light') } } leftIcon="light_mode" buttonTitle="Hellmodus" />
                                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'auto') } } leftIcon="contrast" buttonTitle="Automatisch" />
                                </PopUp>
                            }
                        </a>
                        <NavLink to="/eazyac/login" style={{textDecoration: 'none'}}><div className="clickable-icon icon">login</div><div className="title">Einloggen</div></NavLink>
                        </>
                        :
                        <div onClick={ () => setIsVisibleMenu(prev => !prev) } className="clickable-icon flex w100prc cursor-pointer" style={{ fontSize: '2.0em', justifyContent: 'flex-end' }}>menu</div>
                     }
                    </ul>
                    { isVisibleMenu && 
                        createPortal( 

                            <div className="nav-menu">
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'dashboard'); } } leftIcon="dashboard" rightIcon="chevron_right" buttonTitle="Dashboard" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'nachrichten'); } } leftIcon="mail" rightIcon="chevron_right" buttonTitle="Nachrichten" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'belegungsplan'); } } leftIcon="date_range" rightIcon="chevron_right" buttonTitle="Belegungsplan" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'statistik'); } } leftIcon="bar_chart" rightIcon="chevron_right" buttonTitle="Statistik" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'aufenthalte'); } } leftIcon="nights_stay" rightIcon="chevron_right" buttonTitle="Aufenthalte" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'gaeste'); } } leftIcon="import_contacts" rightIcon="chevron_right" buttonTitle="Gäste" />
                                <MenuButton onClick={ (e) => { handleNavigate(e, 'immobilien'); } } leftIcon="house" rightIcon="chevron_right" buttonTitle="Immobilien" />
                                <MenuButton onClick={ () => { setIsVisibleSettingsView( prev => !prev ); setIsVisibleMenu(false); } } leftIcon="settings" rightIcon="chevron_right" buttonTitle="Einstellungen" />

                                <MenuButton style={{ marginTop: '20px'}} onClick={ (e) => { handleDarkmodeClick(e, 'dark') } } leftIcon="dark_mode" rightIcon="chevron_right" buttonTitle="Dunkelmodus" />
                                <MenuButton onClick={ (e) => { handleDarkmodeClick(e, 'light') } } leftIcon="light_mode" rightIcon="chevron_right" buttonTitle="Hellmodus" />
                                <MenuButton onClick={ (e) => { handleDarkmodeClick(e, 'auto') } } leftIcon="contrast" rightIcon="chevron_right" buttonTitle="Automatisch" />

                                <MenuButton style={{ marginTop: '20px'}} onClick={ (e) => { handleNavigate(e, '/logout'); } } leftIcon="exit_to_app" rightIcon="chevron_right" buttonTitle="Abmelden" />
                            </div>, document.body 

                        )
                    }
                    </>

                    :

                    <ul>
                        <div className="flex center-y w100prc" style={{ justifyContent: 'flex-end', paddingRight: '30px', overflow: 'visible' }}>
                            <a onClick={ (e) => { setIsVisibleDarkModeView( prevVal => !prevVal ); e.stopPropagation(); } } className="" style={{ position: 'relative', textDecoration: 'none', width: '20%'}}>
                                <div className="clickable-icon icon">{ darkModeIcon }</div><div className="title">{ darkModeTitle }</div>
                                { isVisibleDarkModeView && 
                                    <PopUp idTag="propertyDetailMenu" style={{ gap: '10px', minWidth: '200px', height: 'auto', fontSize: '1.0rem', marginTop: '70px', left: 'auto', right: '50px' }} handleClosePopUp={ () => setIsVisibleDarkModeView(false) } >
                                        <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'dark') } } leftIcon="dark_mode" buttonTitle="Dunkelmodus" />
                                        <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'light') } } leftIcon="light_mode" buttonTitle="Hellmodus" />
                                        <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ (e) => { handleDarkmodeClick(e, 'auto') } } leftIcon="contrast" buttonTitle="Automatisch" />
                                    </PopUp>
                                }
                            </a>
                        </div>
                    </ul>

                }
            </div>
            { isVisibleSettingsView && createPortal( <SettingsWrapper handleClose={ () => setIsVisibleSettingsView(false) } />, document.body ) }
        </nav>
    );
}

export default NavigationBarTop;