import { useContext, useId, useRef, useState } from "react";
import InputView from "../../generalComps/InputView";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import { useMutation } from "@tanstack/react-query";
import { postDeleteStay, postUpdateStaySingleValue } from "../../methods/http.stays.methods";
import { AlertObj } from "../../methods/types";
import ActionAlert from "../../generalComps/ActionAlert";
import { createPortal } from "react-dom";

const BlockDetailView = () => {

    const { stay } = useContext(StayDetailViewContext);
    const { setIsVisibleStayDetailsView, reloadStayData } = useContext(OccupancyContext);

    const inputTitleRef = useRef<HTMLInputElement | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const { mutate: postUpdateTitleMutation } = useMutation({
        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, 'stay_block_title', inputTitleRef.current!.value),
        onSuccess: () => {

            reloadStayData();

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
        },
    });

    const { mutate: postDeleteBlockMutation } = useMutation({
        mutationFn: () => postDeleteStay(stay.stay_id),
        onSuccess: () => {

            reloadStayData();

            setAlertObj( new AlertObj() );

            setIsVisibleStayDetailsView(false);

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
        },
    });

    const handleSave = () => {

        if (inputTitleRef.current!.value.length === 0) {

            setAlertObj( new AlertObj(true, 'Fehlende Bezeichnung', 'Du hast keine Bezeichnung für diese Blockierung vergeben.', standardAlertButton) );

        } else {

            if ( inputTitleRef.current!.value !== stay.stay_block_title ) {

                postUpdateTitleMutation();

            } 

        }

    }

    const handleDelete = () => {

        const alertBtns = [{ title: 'Löschen', handler: postDeleteBlockMutation }, { ...standardAlertButton[0], title: 'Abbrechen'}]

        setAlertObj( new AlertObj(true, 'Löschen', `Soll die Blockierung '${stay.stay_block_title}' wirklich gelöscht werden?`, alertBtns) );

    }

    return ( 

        <>
        <div className="flex center-y w100prc" style={{ width: "100%" }}>
            <div onClick={ () => setIsVisibleStayDetailsView(false) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
            {/* <div onClick={()=>{}} className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>more_horiz</div> */}
        </div>
        <div className="flex center column" style={{ marginTop: '10px' }}>
            <InputView title="Bezeichnung">
                <div onClick={ handleSave } className="clickable-icon" style={{ position: 'absolute', fontSize: '1.2em', right: '0', top: '3px' }}>save</div>
                <input ref={inputTitleRef} type="text" placeholder="z. B. Reinigung" defaultValue={ stay.stay_block_title }/>
            </InputView>
            <div className="shadowBox center column" style={{ width: 'calc(100% - 4px)', marginTop: '20px', padding: '0 5px 15px 5px' }}>
                <div className="flex center" style={{ marginTop: '20px'}}>Blockierung '{ stay.stay_block_title }' löschen?</div>
                <div onClick={ handleDelete } className="clickable-icon" style={{ fontSize: '1.5em', marginTop: '10px'}}>delete</div>
            </div>
        </div>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default BlockDetailView;