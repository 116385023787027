import { SyntheticEvent, memo, useContext, useRef } from "react";
import MultiCalendarBarCard from "./MultiCalendarBarCard";
import { OccupancyModulContext } from "../../../contexts/OccupancyModulContext";
import { daysBetweenDates, getDateObj } from "../../../methods/standard.methods";

type Props = {
    index: number;
}

const MultiCalendarDatesTopBar = ( { index }: Props ) => {

    const { dates, filteredPropertys, stays } = useContext(OccupancyModulContext);

    const filteredStays = stays.filter( stay => stay.stay_property === filteredPropertys[index].property_id );
    //const usedStaysRef = useRef<Array<number>>([]);

    const usedStays: Array<number> = [];

    return ( 

        <div className="border-bottom-strong" style={{ overflowX: 'clip', width: (dates.length * 60) + 'px', height: '51px', background: index % 2 === 0 ? 'var(--tile-bg-hover)' : 'var(--alert-btn-hover)' }}>
        {
            dates.map( date => {

                const filteredStaysByDate = filteredStays.filter( stay => stay.stay_checkIn === date.dateStringUS || stay.stay_checkOut === date.dateStringUS );

                return(

                    <div key={date.dateStringUS} className="flex center multi-calendar-dates-card" style={{ backgroundColor: ( date.dayStringShort === 'Sa' || date.dayStringShort === 'So' ) ? 'var(--date-weekend-bg)' : 'transparent' }}>
                        {
                            filteredStaysByDate.map( stay => {

                                if ( !usedStays.find( stayId => stayId === stay.stay_id ) ) {

                                    const width = ( daysBetweenDates( getDateObj( stay.stay_checkIn, 0 ).date, getDateObj( stay.stay_checkOut, 0 ).date ) * 60 ) - 5;

                                    usedStays.push( stay.stay_id );
                                    
                                    return <MultiCalendarBarCard key={stay.stay_id} stay={stay} style={{ width: `${width}px`, left: stay.stay_checkOut === date.dateStringUS ? `-${width - 27.5}px` : '32.5px' }}/>;

                                } else {
                                    return null;
                                }

                            })
                        }
                    </div>

                )
    
            })
        }
        </div>

     );
}
 
export default memo(MultiCalendarDatesTopBar);