import { setCss } from "../methods/animation.methods";
import { ReactNode, useEffect } from "react";

type Props = {
    modalViewId: string;
    style?: React.CSSProperties | undefined;
    children: ReactNode;
    handleClose: (e: React.SyntheticEvent) => void;
}

const ModalView = ({ modalViewId, style, children, handleClose }:Props) => {
    
  useEffect(() => {

    setCss("7");

    return(() => {
      setCss("0");
    });

  }, []); 

  const handleCloseView = (e: React.SyntheticEvent) => {

    if (e.currentTarget == e.target) {
      handleClose(e);
    } 

    e.stopPropagation();

  }

  return (
    
    <div onClick={(e) => handleCloseView(e)} id={modalViewId} className="backView">
    <div
      id="innerWrapper"
      className="open"
      style={style}
    >
      <div className="flex" style={{ flexDirection: 'column', justifyContent: 'flex-start', overflow: 'visible' }}>{children}</div>
    </div>
    </div>

  );
};

export default ModalView;
