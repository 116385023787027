import { DefaultFetchResult, OfferSendObj } from "./types";

export const fetchOffer = async (token: string) => {

    const data = new URLSearchParams();

    data.append('token', token);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/modules/booking.php`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
      
    const content: DefaultFetchResult = await result.json();
    return content;

}

export const addOffer = async (newOfferData: OfferSendObj ) => {

    const data = new URLSearchParams();

    data.append('korres_id', newOfferData.korres_id.toString());
    data.append('korres_art', newOfferData.korres_art.toString());
    data.append('korres_guest_data', newOfferData.korres_guest_data);
    data.append('korres_stay', newOfferData.korres_stay.toString());
    data.append('korres_subject', newOfferData.korres_subject);
    data.append('korres_body', newOfferData.korres_body);
    data.append('korres_from', newOfferData.korres_from);
    data.append('user_id', newOfferData.user_id.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/modules/booking_con.php`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
      
    const content: DefaultFetchResult = await result.json();
    return content;

}

export const fetchOccupancyShareData = async (token: string, pwd: string) => {

    const data = new URLSearchParams();

    data.append('token', token);
    data.append('pwd', pwd);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/modules/share.php`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
      
    const content: DefaultFetchResult = await result.json();
    return content;

}