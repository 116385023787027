import { useContext, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {StayDetailViewContext} from "../../contexts/StayDetailViewContext";
import InfoWidget from "../../generalComps/InfoWidget";
import InputView from "../../generalComps/InputView";
import { InfoWidgetObj, PaymentSelectionType } from "../../methods/types";

type Props = {
    handlePaymentViewType: (viewType: PaymentSelectionType) => void;
    handleClose: () => void;
}

const PartAmountView = ( { handlePaymentViewType, handleClose }: Props ) => {

    const { paymentDataObj, setPaymentDataObj } = useContext(StayDetailViewContext);

    const helpBoxText = "Gebe hier den beglichenen Teilbetrag ein.";

    const inputRef = useRef<HTMLInputElement>(null);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const handleSave = () => {

        if (inputRef.current !== null) {
        
            const inputValue = Number(inputRef.current.value);

            if (inputValue <= 0) {

                inputRef.current.value = '1';

                setInfoWidgetObj( {isVisible: true, msg: 'Betrag kann nicht kleiner 1 sein.' })

            } else if (inputValue > paymentDataObj.amount) {

                inputRef.current.value = `${paymentDataObj.amount}`;

                setInfoWidgetObj( {isVisible: true, msg: 'Betrag kann nicht gröper dem noch offenen Restbetrag sein.' })

            } else {

                setPaymentDataObj( prevObj => ({...prevObj, amount: inputValue}));
                handlePaymentViewType(PaymentSelectionType.PaymentTypeView);

            }

        }

    }

    return ( 

        <>
        <div className="flex center-y w100prc">
            <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>cancel</div>
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.2rem', }}>Zahlungsart - Teilbetrag</div>
            <div onClick={handleSave} className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px' }}>check_circle</div>
        </div>
        <div className="shadowBox center"style={{margin: '20px 0'}}>{helpBoxText}</div>

        <InputView title="Teilbetrag"><input ref={inputRef} style={{textAlign: 'center'}} type='number' defaultValue={paymentDataObj.amount}></input></InputView>
        { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body ) }
        </>

     );
}
 
export default PartAmountView;