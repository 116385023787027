import { ReactNode, useEffect } from "react";
import Card from "../generalComps/Card";
import WeekDateBar from "../generalComps/WeekDateBar";
import { useState } from "react";
import { fetchDashboardStays } from "../methods/http.stays.methods";
import { useQuery } from "@tanstack/react-query";
import { Stay } from "../methods/types";
import DashboardStaysDetail from "./DashboardStaysDetail";

export enum StayType {
    arrival = 'arrival',
    departure = 'departure',
    current = 'current',
    none = 'none'
}

const DashboardStays = () => {

    enum IconType {
        checkIn,
        checkOut,
        default
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const todayString = today.toLocaleDateString('fr-ca');
    const [selectedDate, setSelectedDate] = useState( {weekDay: '', dateStringShort: '', dateStringFull: todayString} );

    let arrivalArr: Array<Stay> = [];
    let departureArr: Array<Stay> = [];
    let currentArr: Array<Stay> = [];

    // const [arrivalArr, setArrivalArr] = useState<Array<Stay>>([]);
    // const [departureArr, setDepartureArr] = useState<Array<Stay>>([]);
    // const [currentArr, setCurrentArr] = useState<Array<Stay>>([]);

    const [stayType, setStayType] = useState(StayType.none);

    const [selectedStaysArr, setSelectedStaysArr] = useState<Array<Stay>>([]);

    const icon = (iconName1: string, iconName2: string, type: IconType): ReactNode => {

        if (iconName1.length > 0 && iconName2.length == 0) {
            return <div className="default-icon" style={{fontSize: '2.0rem', padding: '0'}}>{iconName1}</div>;
        } else {

            return <div className="default-icon" style={{position: 'relative', fontSize: '2.0rem', padding: '0'}}>
                    <div style={{ transform: 'scaleX(-1)' }}>{iconName1}</div>
                    <div style={{ position: 'absolute', top: '7px', left: '-7px', color: type === IconType.checkIn ? 'green' : 'red', fontSize: '0.6em', transform: `scaleX(${ type === IconType.checkIn ? '1' : '-1' })` }}>{iconName2}</div>
                </div> 

        }

    }

    const topTitleString = selectedDate.dateStringFull == todayString ? 'Heute' : selectedDate.weekDay + ' ' + selectedDate.dateStringShort;

    const { data } = useQuery({
        queryKey: ['dashboardStays'],
        queryFn: () => fetchDashboardStays(),
        refetchOnWindowFocus: false,
    });

    if (data && data.success) {

        const staysArr: Array<Stay> = data.obj;

        arrivalArr = staysArr.filter( (stay) => {
            return stay.stay_checkIn == selectedDate.dateStringFull;
        } );

        departureArr = staysArr.filter( (stay) => {
            return stay.stay_checkOut == selectedDate.dateStringFull;
        } );

        currentArr = staysArr.filter( (stay: Stay) => {
            
            const selDate = new Date(selectedDate.dateStringFull);
            selDate.setHours(0,0,0,0);

            const ciDate = new Date(stay.stay_checkIn!);
            ciDate.setHours(0,0,0,0);

            const coDate = new Date(stay.stay_checkOut!);
            coDate.setHours(0,0,0,0);

            return selDate.getTime() > ciDate.getTime() && selDate.getTime() < coDate.getTime();

        } );

    }

    useEffect( () => {

        if (stayType === StayType.arrival) {
            setSelectedStaysArr(arrivalArr);
        } else if (stayType === StayType.departure) {
            setSelectedStaysArr(departureArr);
        } else if (stayType === StayType.current) {
            setSelectedStaysArr(currentArr);
        } else {
            setSelectedStaysArr([]);
        }

    }, [data])

    const setType = ( type: StayType ) => {

        setStayType( type === stayType ? StayType.none : type );

        if (type === StayType.arrival) {
            setSelectedStaysArr(arrivalArr);
        } else if (type === StayType.departure) {
            setSelectedStaysArr(departureArr);
        } else if (type === StayType.current) {
            setSelectedStaysArr(currentArr);
        } else {
            setSelectedStaysArr([]);
        }

    }

    return ( 
        <>
        <div className = "shadowBox statistics-small" style={{marginTop: '30px'}}>
            <div className = "flex-container center-y w100prc" style = {{paddingBottom: '10px'}}>AUFENTHALTE</div>
            <WeekDateBar selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
            <div className="flex-container center w100prc" style={{gap: '10px', overflow: 'visible'}}>
                <Card setType={ () => setType(StayType.arrival) } tileStyle={{width: 'calc(100% / 3)', height: '180px'}} icon={ icon('meeting_room', 'forward', IconType.checkIn) } topTitle={ topTitleString } valueTitle={ arrivalArr.length.toString() } btmTitle={ arrivalArr.length === 1 ? 'Anreise' : 'Anreisen' } isClickable={true} isSelected={ stayType === StayType.arrival ? true : false }/>
                <Card setType={ () => setType(StayType.departure) } tileStyle={{width: 'calc(100% / 3)', height: '180px'}} icon={ icon('meeting_room', 'forward', IconType.checkOut) } topTitle={ topTitleString } valueTitle={ departureArr.length.toString() } btmTitle={ departureArr.length === 1 ? 'Abreise' : 'Abreisen' } isClickable={true} isSelected={ stayType === StayType.departure ? true : false }/>
                <Card setType={ () => setType(StayType.current) } tileStyle={{width: 'calc(100% / 3)', height: '180px'}} icon={ icon('door_front', '', IconType.default) } topTitle='Aktuell' valueTitle={ currentArr.length.toString() } btmTitle={ currentArr.length === 1 ? 'Aufenthalt' : 'Aufenthalte' } isClickable={true} isSelected={ stayType === StayType.current ? true : false }/>
            </div>
        </div>
        { stayType != StayType.none && <DashboardStaysDetail stayType={ stayType } stays={selectedStaysArr} selectedDate={selectedDate}/> }
        </>
     );
}
 
export default DashboardStays;