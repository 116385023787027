import { useRef, useState } from "react";
import InputView from "../generalComps/InputView";
import { getDateObj } from "../methods/standard.methods";
import { PriceListObj, PriceListType } from "../methods/types";

type Props = {

    priceListObj: PriceListObj;
    rowNumber: number;
    handleValueChange: (newValue: number) => void;

}

const StayPriceListCard = ( { priceListObj, rowNumber, handleValueChange }: Props ) => {
    
    const [isEditable, setIsEditable] = useState(false);

    const inputValueRef = useRef<HTMLInputElement>(null);

    const handleEditSaveClick = () => {

        setIsEditable( prev => !prev );

        if (isEditable) {

            inputValueRef.current!.value = Number(inputValueRef.current!.value) <= 0 ? '0' : inputValueRef.current!.value;

            handleValueChange( Number(inputValueRef.current!.value) );

        }

    }

    if (priceListObj.art === PriceListType.DefaultPrice) {

        const dateObj = getDateObj(priceListObj.date!, 0);

        if (!priceListObj.additional!) {

            return(

                <InputView title={`${rowNumber}. Übernachtungspreis für den ${dateObj.dateStringDE}.`}>
                    <div onClick={ handleEditSaveClick } className="clickable-icon" style={{ position: 'absolute', top: '5px', right: '0' }}>{ !isEditable ? 'edit' : 'save' }</div>
                    <input ref={inputValueRef} type="number" defaultValue={priceListObj.price} style={{textAlign: 'center'}} disabled={!isEditable} />
                </InputView>

            );

        } else {

            return(

                <InputView title={`+ Aufschlag für ${priceListObj.persons!} ${priceListObj.persons! === 1 ? 'weitere Person' : 'weitere Personen'}.`}>
                    <div onClick={ handleEditSaveClick } className="clickable-icon" style={{ position: 'absolute', top: '5px', right: '0' }}>{ !isEditable ? 'edit' : 'save' }</div>
                    <input ref={inputValueRef} type="number"  defaultValue={priceListObj.price} style={{textAlign: 'center'}} disabled={!isEditable} />
                </InputView>

            );

        }

    } else {
        return null;
    }

}
 
export default StayPriceListCard;