import { ReactElement, useContext, useEffect, useId, useState } from "react";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import { AlertObj, InfoWidgetObj, KtObj, PaymentsViewType, PropertyListType, ViewState } from "../../../methods/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../../methods/http.property.methods";
import InfoWidget from "../../../generalComps/InfoWidget";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { emptySpaTax } from "../../../methods/constants";
import { fetchSpaTaxes, postDeleteSpaTax } from "../../../methods/http.spatax.methods";
import SpaTaxCard from "./SpaTaxCard";
import SpaTaxAddEditView from "./SpaTaxAddEditView";

type Props = {
    handleClose: () => void;
}

const SpaTaxListView = ( { handleClose }: Props ) => {

    const queryClient = useQueryClient();

    const { setPaymentsViewType, selectedProperty, setSelectedProperty, propertyListType, setSelectedSpaTax } = useContext( PropertysViewContext );

    const [isVisibleSpaTaxAddEditView, setIsVisibleSpaTaxAddEditView] = useState(false);

    const [spaTaxes, setSpaTaxes] = useState<Array<KtObj>>([]);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const { mutate: fetchSpaTaxesMutation } = useMutation({
        mutationFn: () => fetchSpaTaxes(),
        onSuccess: (data) => {

            if (data.success) {
                setSpaTaxes( data.obj );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => fetchSpaTaxesMutation(), [] );

    const NoData = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Gästebeiträge vorhanden</div>;
    }

    const handleSpaTaxCardClick = (state: ViewState, spaTax: KtObj) => {

        if (state === ViewState.Details) {

            const propertySpaTaxIds: Array<{kt_id: number}> = selectedProperty.property_kt ? JSON.parse( selectedProperty.property_kt ) : [];

            if ( propertySpaTaxIds.find( dId => dId.kt_id === spaTax.kt_id ) !== undefined ) {

                propertySpaTaxIds.splice( propertySpaTaxIds.findIndex( obj => obj.kt_id === spaTax.kt_id ), 1 );

            } else {

                propertySpaTaxIds.push( {kt_id: spaTax.kt_id} );

            }

            postUpdatePropertySingleMutation( JSON.stringify( propertySpaTaxIds ) );

        } else if (state === ViewState.Delete) {

            const alertButtons = [ {title: 'Löschen', handler: () => postDeleteSpaTaxMutation(spaTax.kt_id) }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Gästebeitrag löschen?', `Soll dieser Gästebeitrag wirklich gelöscht werden?`, alertButtons ) );

        } else if (state === ViewState.Edit) {

            setSelectedSpaTax( spaTax );

            setIsVisibleSpaTaxAddEditView(true);

        }

    }

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: (spaTaxIdString: string) => postUpdatePropertySingleValue(selectedProperty.property_id, 'property_kt', spaTaxIdString),
        onSuccess: (_, variables) => {

            setSelectedProperty( prev => ( {...prev, property_kt: variables } ) );

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postDeleteSpaTaxMutation } = useMutation({
        mutationFn: (spaTaxId: number) => postDeleteSpaTax(spaTaxId),
        onSuccess: () => {

            fetchSpaTaxesMutation();

            setAlertObj( new AlertObj() );

            setInfoWidgetObj( new InfoWidgetObj( true, 'Gästebeitrag gelöscht.') )

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSaveSpaTax = (type: string, newSpaTaxId?: number | undefined) => {

        fetchSpaTaxesMutation();

        setIsVisibleSpaTaxAddEditView( false );
        setSelectedSpaTax( emptySpaTax );

        setInfoWidgetObj( new InfoWidgetObj(true, `Gästebeitrag erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

        if (type === 'new' && newSpaTaxId !== undefined && newSpaTaxId !== null ) {

            const alertButtons = [ {title: 'Verknüpfen', handler: () => {

                const propertySpaTaxIds: Array<{kt_id: number}> = selectedProperty.property_kt ? JSON.parse( selectedProperty.property_kt ) : [];
                propertySpaTaxIds.push( {kt_id: newSpaTaxId} );
                postUpdatePropertySingleMutation( JSON.stringify( propertySpaTaxIds ) );
                
                setAlertObj( new AlertObj() );

            } }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Gästebeitrag verknüpfen?', `Den soeben angelegten Gästebeitrag mit dieser Immobilie verknüpfen?`, alertButtons ) );

        }

    }

    return ( 

        <>
        <ModalView modalViewId="discountListView" style={{ width: '650px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ () => setPaymentsViewType( PaymentsViewType.MainMenu ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
                <div className="main-title">Gästebeiträge</div>
                <div onClick={ () => { setSelectedSpaTax(emptySpaTax); setIsVisibleSpaTaxAddEditView(true); } } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
            </div>
            <div className="flex center w100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: '3px', marginTop: '10px' }}>
            { 

                spaTaxes.length === 0 ?

                <NoData />

                :

                spaTaxes.map( ( spaTax ) => {

                    return <SpaTaxCard key={spaTax.kt_id} spaTax={spaTax} handleOnClick={ (state) => handleSpaTaxCardClick(state, spaTax) } />;
        
                }) 

            }
            </div>
        </ModalView>
        { isVisibleSpaTaxAddEditView && <SpaTaxAddEditView handleCancel={ () => setIsVisibleSpaTaxAddEditView(false) } handleSave={ handleSaveSpaTax } /> }
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>
     );
}
 
export default SpaTaxListView;