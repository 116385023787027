import { useEffect } from "react";
import "../../css/privacy.css";
import { useNavigate } from "react-router-dom";

const Start = () => {

    const navigate = useNavigate();

    useEffect( () => {

        window.document.title = 'Eazyac';

    }, []);

    return ( 
        <>
        <div className="firstSector-web startBackground" id="id_firstSector">
            <div className="mainText">
                <span className="headlines">Eazyac</span><br/><br/>
                <div className="flex center">
                    <div className = "flex center w100prc">
                        <div className="flex center default-icon color-cyan" style={{ fontSize: '4.0em', width: '60%' }}>touch_app</div>
                        <div className="flex color-white" style={{ width: '40%', fontSize: '2.0em' }}>Einfach</div>
                    </div>
                    <div className = "flex center w100prc">
                        <div className="flex center default-icon color-cyan" style={{ fontSize: '4.0em', width: '60%' }}>attach_money</div>
                        <div className="flex color-white" style={{ width: '40%', fontSize: '2.0em' }}>Günstig</div>
                    </div>
                    <div className = "flex center w100prc">
                        <div className="flex center default-icon color-cyan" style={{ fontSize: '4.0em', width: '60%' }}>opacity</div>
                        <div className="flex color-white" style={{ width: '40%', fontSize: '2.0em' }}>Transparent</div>
                    </div>
                </div>
                <h1 className="headlinesText" style={ { marginLeft: '0' } }>... wo die Verwaltung von Ferienwohnungen Spaß macht!</h1>
                <div onClick={ () => navigate('/preise') } className="customButtonMore"><div className="innerText">Kostenlos testen</div></div>
            </div>
        </div>

        <div id="info" className="infoSector-web">

            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>apps</div>
                <div className="infoBox-headline">Ein Preis, volles Paket</div>
                <div className="infoBox-text">Du bezahlst einen Preis und erhälst das volle Paket ohne Limitierungen, so wie alle zukünftigen Updates und Neuerungen.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>receipt</div>
                <div className="infoBox-headline">Umfangreiche Korrespondenz</div>
                <div className="infoBox-text">Erstelle Angebote, Buchungsbestätigungen, Rechnungen und vieles mehr mit nur einem Klick im PDF-Format. Profitiere von dem flexiblen Design, den du nach deinen Bedürfnissen anpassen kannst.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>forum</div>
                <div className="infoBox-headline">Mailsystem</div>
                <div className="infoBox-text">Über das integrierte Mailsystem hast du die gesamte Korrespondenz an einem Ort.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>thumbs_up_down</div>
                <div className="infoBox-headline">Online Zu-/Absage</div>
                <div className="infoBox-text">Lass deine Gäste ein Angebot online annehmen oder ablehnen. Hast du deine Gäste überzeugt, so können diese bei der Gelegenheit gleich ihre Adress- und Kontaktdaten ergänzen, sowie bei Bedarf Anlagen und Notizen übermitteln.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>description</div>
                <div className="infoBox-headline">Expose</div>
                <div className="infoBox-text">Lege für eine Immobilie einmalig ein Expose an und verschicke dieses bei Bedarf an unentschlossene Interessenten. Den Inhalt kannst du flexibel nach deinen Wünschen gestalten.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>description</div>
                <div className="infoBox-headline">Übergabeprotokoll</div>
                <div className="infoBox-text">Lege das Inventar deiner Immobilie - bei Wunsch auch bildlich - einmalig an und dokumentiere deine Wohnungsübergaben ab sofort professionel. Bei Unstimmigkeiten bist du damit in Zukunft auf der sicheren Seite.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>date_range</div>
                <div className="infoBox-headline">Belegungskalender</div>
                <div className="infoBox-text">Verwalte deine Aufenthalte bequem über den Belegungskalender.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>sync</div>
                <div className="infoBox-headline">Kalender Import/Export</div>
                <div className="infoBox-text">Verbinde die Kalender aller gängigen Online-Portale rein- und rauswärts über das iCal-Format. Da es sich hierbei um den gängigen Standard handelt bist du bestens aufgestellt.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>settings_applications</div>
                <div className="infoBox-headline">Preise, Zusatzleistungen etc.</div>
                <div className="infoBox-text">Preise, Zusatzleistungen, Kurtaxen und vieles mehr lässt sich für jede Immobilie flexibel anlegen und jedem Aufenthalt bei Bedarf individuell hinzufügen.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>history</div>
                <div className="infoBox-headline">Zahlungshistorie</div>
                <div className="infoBox-text">Halte die Zahlungshistorie deiner Gäste fest. Egal ob Gesamt- oder Teilzahlung, Bar, Überweisung oder anderes Zahlungsmittel.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>insert_chart_outlined</div>
                <div className="infoBox-headline">Statistik</div>
                <div className="infoBox-text">Behalte mit der Statistik die Entwicklung deiner Unternehmung stets im Auge. Auslastung, Einnahmen und vieles mehr.</div>
            </div>
            <div className="infoBox">
                <div className="flex center default-icon w100prc" style={{ fontSize: '4.0em' }}>phonelink</div>
                <div className="infoBox-headline">Geräte</div>
                <div className="infoBox-text">Eazyac kannst du auf allen Geräten nutzen, die einen aktuellen Browser verwenden. Somit bist du unabhängig vom Betriebssystem und der Geräteart.</div>
            </div>

        </div>

        <div id="trial" className="trialSector-web">

            <span className="boxTitleFont">Starte jetzt...</span><br/><br/><span className="boxTitleFontSmall">Teste Eazyac uneingeschränkt für 30 Tage. Anschließend hast du die Möglichkeit Eazyac monatlich (8,99 EUR) oder jährlich (89,99 EUR) zu abonnieren.</span><br/><br/><br/>
            <div onClick={ () => navigate('/preise') } className="customButtonContact"><div className="innerText">Jetzt testen</div></div><br/>
            <span className="boxTitleFontSmall" style = {{ marginTop: '50px' }}>oder</span><p>
            <div onClick={ () => navigate('/kontakt') } className="customButtonContact"><div className="innerText">Kontakt</div></div></p>
        </div>
        
        </>

     );
}
 
export default Start;