const RegisterSuccess = () => {
    return ( 

        <div className="shadowBox center wrap" style={{width: '40%', margin: '100px 0 0 0'}}>
            <div className="flex-container center color-cyan" style={{width: '100%', padding: '0 0 10px 0', fontSize: '1.5rem'}}>Registrierung erfolgreich</div>
            <div className="flex-container center" style={{width: '100%'}}>
                <div className="default-icon flex-container center" style={{width: '30%', fontSize: '5.0rem'}}>how_to_reg</div>
                Deine Registrierung war erfolgreich. Wir haben dir eine Email mit der Bitte um Bestätigung gesendet. Sobald du das erledigt hast, kannst du dich auch schon anmelden.
            </div>
        </div>

     );
}
 
export default RegisterSuccess;