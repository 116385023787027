import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReactElement, useContext, useId, useRef, useState, memo, useEffect } from "react";
import { createPortal } from "react-dom";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import ActionAlert from "../generalComps/ActionAlert";
import IFrameView from "../generalComps/IFrameView";
import InputView from "../generalComps/InputView";
import TextareaView from "../generalComps/TextareaView";
import { deleteAllFiles, fetchAllFiles, fetchKorres, postSendMail, postUploadFile } from "../methods/http.korres.methods";
import { createMailSeperatorArray } from "../methods/standard.methods";
import { AlertObj, TemplatesObj } from "../methods/types";
import TemplatesPopUpView from "../stayComps/stayConversationComps/TemplatesPopUpView";
import MailAddressWrapper from "./MailAddressWrapper";

type Props = {

    handleClose: () => void;

}

const MailCreationView = ( { handleClose }: Props ) => {

    const { mailContentObjRef, templatesArrRef, conversationTypeRef, stayIdRef, setSeperatorObj, invoiceNumberRef } = useContext( MessageDetailViewContext );

    const queryClient = useQueryClient();

    const mailSubjectInputRef = useRef<HTMLInputElement | null>(null);
    const mailSubjectDefaultValueRef = useRef( mailContentObjRef.current.subject );

    const mailMessageTextareaRef = useRef<HTMLTextAreaElement | null>(null);
    const mailMessageDefaultValueRef = useRef( mailContentObjRef.current.body );

    const [isVisibleTemplatesPopUp, setIsVisibleTemplatesPopUp] = useState(false);

    const [isOnlineOffer, setIsOnlineOffer] = useState(true);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const [isVisibleAttachmentView, setIsVisibleAttachmentView] = useState(false);
    const attachmentSrcUrlRef = useRef<{file_name: string, file_url: string}>( {file_name: '', file_url: ''} );

    const defaultTemplate = templatesArrRef.current.find( template => template.template_type === 0 && template.template_isStandardFor === conversationTypeRef.current );

    const [filesArr, setFilesArr] = useState<Array<{file_name: string, file_url: string}>>( [] );

    if ( mailSubjectDefaultValueRef.current.length === 0 && defaultTemplate ) {

        mailSubjectDefaultValueRef.current = defaultTemplate.template_subject;

    }

    if ( conversationTypeRef.current !== 9 && mailMessageDefaultValueRef.current.length === 0 && defaultTemplate ) {

        mailMessageDefaultValueRef.current = defaultTemplate.template_message;

    } else if ( conversationTypeRef.current === 9 && mailMessageDefaultValueRef.current.length > 0 && defaultTemplate ) {

        mailMessageDefaultValueRef.current = defaultTemplate.template_message + '\n\n' + mailMessageDefaultValueRef.current;

    }

    // const fetchFilesQuery = useQuery({
    //     queryKey: ["fetchFiles"],
    //     queryFn: () => fetchAllFiles(),
    //     refetchOnWindowFocus: false,
    // });

    const { mutate: fetchFilesMutation } = useMutation({
        mutationFn: () => fetchAllFiles(),
        onSuccess: (data) => {

            setFilesArr( data.obj );

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    useEffect(() => fetchFilesMutation(), []);

    const MsgTextareaIcons = (): ReactElement => {

        return(
            <>
            <div onClick={handleTemplatesPopUpOnClick} className="flex center-y clickable h100prc">
                <div className="default-icon" style={{width: '30px'}}>topic</div>
                <div className="flex center w100prc color-lightblack">Vorlage wählen</div>
            </div>
            { isVisibleTemplatesPopUp && <TemplatesPopUpView templatesArr={templatesArrRef.current.filter( obj => obj.template_type === 0 )} handleSelectedTemplate={handleSelectedTemplate} handleClose={() => setIsVisibleTemplatesPopUp(false) } /> }
            </>
        );

    }

    const handleTemplatesPopUpOnClick = (e: React.SyntheticEvent) => {

        if (templatesArrRef.current.length === 0) {

            setAlertObj( new AlertObj(true, 'Vorlagen', 'Du hast bisher keine Vorlagen angelegt.', standardAlertButton) )

        } else {
            setIsVisibleTemplatesPopUp(true);
        }

        e.stopPropagation();

    }

    const handleSelectedTemplate = (e: React.SyntheticEvent, template: TemplatesObj) => {

        mailSubjectInputRef.current!.value = template.template_subject;
        mailMessageTextareaRef.current!.value = template.template_message;

        setIsVisibleTemplatesPopUp(false);

        e.stopPropagation();

    }

    const handleSendMail = (e: React.SyntheticEvent) => {

        if ( mailContentObjRef.current.to.length === 0 && mailContentObjRef.current.cc.length === 0) {

            setAlertObj( new AlertObj( true, 'E-Mail-Empfänger', 'Bitte trage mindestens einen E-Mail-Empfänger ein.', standardAlertButton ) );

        } else {

            if ( mailSubjectInputRef.current!.value.length === 0 || mailMessageTextareaRef.current!.value.length === 0 ) {

                const buttonsArr = [ { title: 'Senden', handler: postSendMailMutation }, { title: 'Abbrechen', handler: () => setAlertObj( new AlertObj() ) } ];

                if ( mailSubjectInputRef.current!.value.length === 0 && mailMessageTextareaRef.current!.value.length > 0 ) {

                    setAlertObj( new AlertObj( true, 'Betreff', 'E-Mail hat keinen Betreff. Möchtest du trotzdem senden?', buttonsArr ) );

                } else if ( mailMessageTextareaRef.current!.value.length === 0 && mailSubjectInputRef.current!.value.length > 0 ) {

                    setAlertObj( new AlertObj( true, 'Nachricht', 'E-Mail hat keine Nachricht. Möchtest du trotzdem senden?', buttonsArr ) );

                } else {

                    setAlertObj( new AlertObj( true, 'Betreff & Nachricht', 'E-Mail hat keinen Betreff und keine Nachricht. Möchtest du trotzdem senden?', buttonsArr ) );

                }

            } else {

                postSendMailMutation();

            }

        }

        e.stopPropagation();

    }

    const { mutate: postSendMailMutation } = useMutation({
        mutationFn: () => postSendMail( mailContentObjRef.current.to, mailContentObjRef.current.cc, mailSubjectInputRef.current!.value, mailMessageTextareaRef.current!.value, stayIdRef.current, conversationTypeRef.current, invoiceNumberRef.current, isOnlineOffer ),
        onSuccess: () => {

            postDeleteFolderFilesMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: fetchKorresMutation } = useMutation({
        mutationFn: () => fetchKorres(stayIdRef.current),
        onSuccess: (data) => {

            setSeperatorObj( createMailSeperatorArray(data.obj) );

            queryClient.invalidateQueries( { queryKey: ['fetchNewMails'] } );
            queryClient.invalidateQueries( { queryKey: ['fetchMails'] } );

            handleClose();


        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postDeleteFolderFilesMutation } = useMutation({
        mutationFn: () => deleteAllFiles(),
        onSuccess: () => {

            fetchKorresMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let filename = e.target.value.replace(/.*[\/\\]/, '');

            if (e.target.files && e.target.files[0]) {
        
                var reader = new FileReader();
        
                reader.onload = function (e) {
                    
                    //pdfDesignObjRef.current!.pdf_image = e.target?.result as string;
                    postUploadFileMutation({data: e.target?.result as string, name: filename})
                    
                };
        
                reader.readAsDataURL(e.target.files[0]);
            }

    }

    const openFileDialog = () => {

        var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        });

        document.getElementById('file-dialog')?.dispatchEvent(clickEvent);

    }

    const { mutate: postUploadFileMutation } = useMutation({
        mutationFn: (file: {data: string, name: string}) => postUploadFile(file.data, file.name),
        onSuccess: () => {

            //queryClient.invalidateQueries( {queryKey: ['fetchFiles'] } );
            fetchFilesMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const AttachmentElement = ( { fileObj }:{ fileObj: {file_name: string, file_url: string} } ):ReactElement => {

        const handleAttachmentOnClick = () => {

            attachmentSrcUrlRef.current = fileObj;

            setIsVisibleAttachmentView(true);

        }

        return(

            <div onClick={ handleAttachmentOnClick } className="mail-address-style attachment">
                <div className="flex center-y w100prc h100prc">{fileObj.file_name}</div>
            </div>

        )

    }

    return ( 

        <>
        <div className="navigation-view-bar">
            <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
            <div className="main-title">E-Mail</div>
            <div title="E-Mail senden" onClick={ handleSendMail } className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px' }}>send</div>
        </div>
        {
            conversationTypeRef.current === 0 &&

            <div className="shadowBox" style={{ marginTop: '20px' }}>
            <div className="flex center-y w100prc">
                { isOnlineOffer ?
                    'Angebot kann auch online angenommen bzw. abgelehnt werden. Hierzu wird mit der Mail ein entsprechender Link verschickt.' :
                    'Angebot kann nicht online angenommen bzw. abgelehnt werden. Es wird mit der Mail kein Link verschickt.'
                }
            </div>
            <div onClick={ () => setIsOnlineOffer(prev => !prev ) } className="clickable-icon" style={{ fontSize: '2.0em'}}>{ isOnlineOffer ? 'toggle_on' : 'toggle_off' }</div>
        </div>
        }
        <MailAddressWrapper addressArr={ mailContentObjRef.current.to } title="An" style={{ marginTop: '10px' }} />
        <MailAddressWrapper addressArr={ mailContentObjRef.current.cc } title="Kopie" style={{ marginTop: '10px' }} />
        <InputView title="Anlagen" style={{ marginTop: '30px' }}>
            <div onClick={ openFileDialog } className="clickable-icon" style={{ position: 'absolute', top: '2px', right: '5px', fontSize: '1.5em' }}>add_circle</div>
            <div className="flex center-y w100prc" style={{ gap: '5px' }}>
            {
                filesArr.length > 0 ?
                filesArr.map( ( obj ) => {
                    
                    return ( <AttachmentElement key={ obj.file_name + ' ' + obj.file_url } fileObj={obj} /> );

                }) :
                <div className="flex center w100prc color-lightblack">keine Anlagen</div>
            }
            </div>
            <input onChange={(e) => handleFileChange(e)} type="file" accept="*" id="file-dialog" style={{display: 'none'}} />
        </InputView>
        <InputView title="Betreff" style={{ marginTop: '30px' }}><input ref={mailSubjectInputRef} type="text" placeholder="z. B. Rechnung" defaultValue={ mailSubjectDefaultValueRef.current }></input></InputView>
        <TextareaView style={{ height: '500px', marginTop: '20px' }} title='Nachricht' popUps={<MsgTextareaIcons />}>
            <textarea ref={mailMessageTextareaRef} placeholder='Nachricht' defaultValue={ mailMessageDefaultValueRef.current }/>
        </TextareaView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        { isVisibleAttachmentView && createPortal( <IFrameView style={{ width: '800px', height: '800px' }} fileName={ attachmentSrcUrlRef.current.file_name } fileUrl={ attachmentSrcUrlRef.current.file_url } handleClose={ () => setIsVisibleAttachmentView( false ) } reloadFunc={ fetchFilesMutation } />, document.body ) }
        </>

     );
}
 
export default memo(MailCreationView);