import SearchBar from "../generalComps/SearchBar";
import { useContext, useDeferredValue, useEffect, useId, useState } from "react";
import { AlertObj, InfoWidgetObj } from "../methods/types";
import InfoWidget from "../generalComps/InfoWidget";
import ActionAlert from "../generalComps/ActionAlert";
import { createPortal } from "react-dom";
import MessagesList from "./MessagesList";
import '../css/messages.css';
import MessageBackview from "./MessageBackview";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import MessageDetailView from "./MessageDetailView";
import { PropertysContext } from "../contexts/PropertysContext";
import ModalView from "../generalComps/ModalView";

const Messages = () => {

    const { reloadPropertys } = useContext(PropertysContext);

    useEffect( () => { 
        
        reloadPropertys();
    
        window.document.title = 'Eazyac Nachrichten';
    
    }, []);

    const { isVisibleMessageBackview, setIsVisibleMessageBackview } = useContext(MessageDetailViewContext); 

    const searchBarHeight = '150px';

    const actionAlertId = useId();

    const [searchString, setSearchString] = useState('');
    const deferredSearchString = useDeferredValue(searchString);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    //const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    return ( 

        <div className="content-backview">
            <div className="list-column">
                <div className="flex-container center w100prc" style={{ overflow: 'visible', position: 'relative', height: searchBarHeight, padding: '10px', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px', }}>
                    <SearchBar iconName1="" title="Nachrichten" iconName2="" iconName3="" inputPlaceholder="Suche" searchString={searchString} setSearchString={ (searchString) => setSearchString(searchString)} handleClick1={ ()=>{} } handleClick2={() => {} } handleClick3={() => {}}/>
                </div>
                <div className="flex column center-y w100prc" style={{ padding: '10px 10px 10px 10px', height: `calc(100% - ${searchBarHeight})`, justifyContent: 'flex-start', overflow: 'visible', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px' }}>
                    <MessagesList searchString={deferredSearchString} />
                </div>
            </div>
            {
                window.innerWidth > 759 ?

                <div className="flex-container center-y h100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', width: 'calc(100% - 420px)', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px', }}>
                    { isVisibleMessageBackview ?
                    <MessageBackview><MessageDetailView /></MessageBackview> :
                    <div className="default-icon flex center w100prc h100prc" style={{ fontSize: '30.0em', opacity: '0.2' }}>mail</div> }
                </div>

                :

                isVisibleMessageBackview &&
                <div className="flex column w100prc h100prc" style={{ position: 'absolute', backgroundColor: 'var(--bg-color-white)' }}>
                    <div className="flex center-y w100prc" style={{ justifyContent: 'flex-end', padding: '10px 0' }}>
                        <div onClick={ () => setIsVisibleMessageBackview(false) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                    </div>
                    <MessageDetailView />
                </div>
                

            }
            { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body ) }
            { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </div>

     );
}
 
export default Messages;