import { useMutation, useQueryClient } from "@tanstack/react-query";
import {useContext, useEffect, useId, useRef, useState } from "react";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import ActionAlert from "../generalComps/ActionAlert";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import SalutationSelect from "../generalComps/SalutationSelect";
import { postUpdateStaySingleValue } from "../methods/http.stays.methods";
import { AlertObj, GuestGroupObj, Salutation } from "../methods/types";
import { OccupancyContext } from "../contexts/OccupancyContext";

type Props = {

    personObj?: GuestGroupObj | null;
    handleClose: () => void;

}

const StayGroupAddEditView = ( { personObj, handleClose }:Props ) => {

    const { stay, setStay } = useContext(StayDetailViewContext);
    const { reloadStayData } = useContext(OccupancyContext);

    const queryClient = useQueryClient();

    const [selectedSalutation, setSelectedSalutation] = useState(Salutation.Herr)
    const firstNameInputRef = useRef<HTMLInputElement>(null);
    const lastNameInputRef = useRef<HTMLInputElement>(null);
    const bdayInputRef = useRef<HTMLInputElement>(null);
    const [bdayActivated, setBdayActivated] = useState(false);
    const nationalityInputRef = useRef<HTMLInputElement>(null);
    const identificationInputRef = useRef<HTMLInputElement>(null);

    const groupArrRef = useRef<Array<GuestGroupObj>>( JSON.parse( stay.stay_group ) );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();

    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    useEffect(() => {

        if (personObj) {
        
            firstNameInputRef.current!.value = personObj.firstname;
            lastNameInputRef.current!.value = personObj.lastname;
            bdayInputRef.current!.value = personObj.birthday;
            nationalityInputRef.current!.value = personObj.nationality;
            identificationInputRef.current!.value = personObj.identification;

            setSelectedSalutation( personObj.title === 'Herr' ? Salutation.Herr : Salutation.Frau );
            setBdayActivated( (personObj.birthday === '0000-00-00' || personObj.birthday === '') ? false : true );
    
        }

    }, [])

    const saveGroupData = () => {

        if (firstNameInputRef.current!.value.length === 0 || lastNameInputRef.current!.value.length === 0) {

            setAlertObj( new AlertObj(true, 'Achtung', 'Bitte gebe mindestens einen Vor- und Nachnamen ein.', standardAlertButton) );
            
        } else {

            const newPerson: GuestGroupObj = {
                title: Salutation[selectedSalutation],
                firstname: firstNameInputRef.current!.value,
                lastname: lastNameInputRef.current!.value,
                birthday: bdayActivated ? bdayInputRef.current!.value : '0000-00-00',
                nationality: nationalityInputRef.current!.value,
                identification: identificationInputRef.current!.value };

            if (!personObj) {

                groupArrRef.current.push( newPerson );

            } else {

                const index = groupArrRef.current.findIndex(obj => obj.title === personObj.title && obj.firstname === personObj.firstname && obj.lastname === personObj.lastname && obj.birthday === personObj.birthday && obj.nationality === personObj.nationality && obj.identification === personObj.identification );

                groupArrRef.current[index] = newPerson;

            }

            addStayGrouObjMutation();

        }

    }

    const { mutate: addStayGrouObjMutation } = useMutation({
        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, 'stay_group', JSON.stringify(groupArrRef.current)),
        onSuccess: () => {

            setTimeout(() => {
                reloadStayData();
            }, 1000);

            queryClient.invalidateQueries( { queryKey: ['fetchStays'] } );

            setStay( prevStay => ({ ...prevStay, stay_group: JSON.stringify(groupArrRef.current)}) );

            handleClose();

        },
    })

    return(
        <>
        <ModalView modalViewId="stayGroupAddEditView" style={{width: '600px'}} handleClose={handleClose}>
            <div className="navigation-view-bar">
                <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>cancel</div>
                <div className="main-title">{personObj ? 'Begleitperson bearbeiten' : 'Neue Begleitperson'}</div>
                <div onClick={saveGroupData} className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px', }}>check_circle</div>
            </div>
            <div className="shadowBox center" style={{marginTop: '20px'}}>Bitte gebe mindestens die Anrede, sowie Vor- und Nachname der Person ein. Die weiteren Felder sind optional.</div>
            <SalutationSelect selection={["Herr", "Frau"]} selectedItem={selectedSalutation} handleSelection={setSelectedSalutation} />
            <div className="input-wrapper">
                <InputView title="Vorname*" style={{ width: '35%', }}><input ref={firstNameInputRef} type="text" defaultValue={firstNameInputRef.current ? firstNameInputRef.current.value : ''} placeholder="z. B. Max" /></InputView>
                <InputView title="Nachname*" style={{ width: '35%', }}><input ref={lastNameInputRef} type="text" defaultValue={lastNameInputRef.current ? lastNameInputRef.current.value : ''} placeholder="z. B. Mustermann" /></InputView>
                <InputView title="Geburtsdatum" style={{ width: '30%' }}>
                    <div onClick={ () => setBdayActivated( prevVal => !prevVal ) } className="clickable-icon" style={{ position: 'absolute', right: '0', top: '0', padding: '5px'}}>{ bdayActivated ? 'toggle_on' : 'toggle_off' }</div>
                    <input ref={bdayInputRef} type="date" defaultValue={ bdayInputRef.current ? bdayInputRef.current.value : new Date().toLocaleDateString('fr-ca') } max={ new Date().toLocaleDateString('fr-ca') } placeholder="DD.MM.YYYY" disabled={!bdayActivated}/>
                </InputView>
            </div>
            <div className="input-wrapper">
                <InputView title="Staatsangehörigkeit" style={{ width: '50%', }}><input ref={nationalityInputRef} type="text" defaultValue={nationalityInputRef.current ? nationalityInputRef.current.value : ''} placeholder="z. B. Deutsch" /></InputView>
                <InputView title="Ausweisnummer" style={{ width: '50%', }}><input ref={identificationInputRef} type="text" defaultValue={identificationInputRef.current ? identificationInputRef.current.value : ''} placeholder="z. B. 210563488" /></InputView>
            </div>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>
    )

}
 
export default StayGroupAddEditView;