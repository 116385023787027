import NavigationBarTop from './generalComps/NavigationBarTop';
import './css/stylesheet.css';
import { Outlet, useNavigate } from 'react-router-dom';
import { useIsFetching } from '@tanstack/react-query';
import LoadingIndicator from './generalComps/LoadingIndicator';
import { createPortal } from 'react-dom';
import PropertysContextProvider from './contexts/PropertysContext';
import SettingsViewContextProvider from './contexts/SettingsContext';
import { useEffect } from 'react';

const App = () => {

  const isFetching = useIsFetching();

  const navigate = useNavigate();

  //useEffect( () => { navigate('dashboard'); }, []);

  return (
    <>
    <PropertysContextProvider >
    <SettingsViewContextProvider>
     <NavigationBarTop type='eazyac'/>
     </SettingsViewContextProvider>
      
        <div className="firstSector">
          <Outlet />
        </div>
    </PropertysContextProvider>
     {/* <Footer /> */}
     { isFetching ? createPortal( <LoadingIndicator />, document.body ) : '' }
    </>
  );
}

export default App;
