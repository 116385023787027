import SearchBar from "../generalComps/SearchBar";
import { Guest, InfoWidgetObj, ViewState, AlertObj, ViewComponent } from "../methods/types";
import AlphabetBar from "./AlphabetBar";
import GuestBackview from "./GuestBackview";
import GuestsList from "./GuestsList";
import { useState, useDeferredValue, useEffect, useContext } from "react";
import InfoWidget from "../generalComps/InfoWidget";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postDeleteGuest } from "../methods/http.guests.methods";
import { useNavigate } from "react-router-dom";
import ActionAlert from "../generalComps/ActionAlert";
import { getFullname } from "../methods/standard.methods";
import { postDeleteStaysByGuest } from "../methods/http.stays.methods";
import GuestDetailView from "./GuestDetailView";
import { setCss } from "../methods/animation.methods";
import FilterPopUp from "../generalComps/FilterPopUp";
import BigAddIcon from "../generalComps/BigAddIcon";
import { emptyGuest } from "../methods/constants";
import { createPortal } from "react-dom";
import { PropertysContext } from "../contexts/PropertysContext";
import GuestAddEditView from "./GuestAddEditView";

const Guests = () => {

    const { reloadPropertys } = useContext(PropertysContext);

    useEffect( () => { 
        
        reloadPropertys();
    
        window.document.title = 'Eazyac Gäste';
    
    }, []);

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const searchBarHeight = '150px';
    const [selectedChar, setSelectedChar] = useState('A');
    const [searchString, setSearchString] = useState('');
    const deferredSearchString = useDeferredValue(searchString);
    const [isVisibleAddEditView, setIsVisibleAddEditView] = useState(false);
    const [isVisibleFilterPopUp, setIsVisibleFilterPopUp] = useState(false);
    const [showBlacklist, setShowBlacklist] = useState(false);
    const [rateValue, setRateValue] = useState(-1);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const [isVisibleDetailView, setIsVisibleDetailView] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const [guestObj, setGuestObj] = useState(emptyGuest);

    const handleAddGuest = () => {

        setGuestObj(emptyGuest);
        setIsVisibleAddEditView(true);

    }

    const handleSelectedGuest = (state: ViewState, guest: Guest) => {

        setIsVisibleAddEditView(false);
        setGuestObj( guest );

        setTimeout(() => {
            
            if (state === ViewState.Edit) {

                setIsVisibleAddEditView(true);
    
            } else if (state === ViewState.Delete) {

                const alertButtons = Array( {title: 'Löschen', handler: () => handleDeleteGuest(guest) }, {title: 'Abbrechen', handler: () => setAlertObj(new AlertObj())} );

                const fullNameObj = getFullname(guest.guest_title!, guest.guest_firstname!, guest.guest_lastname!, guest.guest_company!);
                
                setAlertObj( new AlertObj( true, 'Gast löschen?', `Soll "${ fullNameObj.fullname }" wirklich gelöscht werden?`, alertButtons ) );

            } else if (state === ViewState.Details) {
                
                setIsVisibleDetailView(true);

            }

        }, 100);

    }

    const handleShowBlacklist = () => {

        setShowBlacklist((prev) => !prev);
        setRateValue(-1);

    }

    const handleDeleteGuest = (guest: Guest) => {
        
        if (guest.guest_stay_amount! === 0) {

            postDeleteGuestMutation();

            setAlertObj(new AlertObj());

        } else {
            
            let msg = 'Diesem Gast ist ein Aufenthalt zugewiesen. Wenn du fortfährst wird dieser Aufenthalt ebenfalls unwiderruflich gelöscht.';

            if (guest.guest_stay_amount! > 1) {

                msg = `Diesem Gast sind ${guest.guest_stay_amount!} Aufenthalte zugewiesen. Wenn du fortfährst werden diese Aufenthalte ebenfalls unwiderruflich gelöscht.`;
                
            }

            const alertButtons = Array( {title: 'Aufenthalte löschen', handler: handleDeleteStays }, {title: 'Abbrechen', handler: () => setAlertObj(new AlertObj())} );

            setAlertObj( new AlertObj( true, 'Achtung', msg, alertButtons ) );

        }

    }

    const handleDeleteStays = () => {

        postDeleteStaysByGuestMutation();

        setAlertObj(new AlertObj());

    }

    const handleSaveGuest = (type: string) => {

        setIsVisibleAddEditView(false);
        setGuestObj( emptyGuest );

        setInfoWidgetObj( new InfoWidgetObj(true, `Gast erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

    }

    const { mutate: postDeleteGuestMutation } = useMutation({
        mutationFn: () => postDeleteGuest(guestObj.guest_id),
        onSuccess: (data) => {

            if (data && data.session === "expired") {
                navigate("login");
            } else if (data && data.success) {

                queryClient.invalidateQueries({queryKey: ['fetchGuests']});

                setInfoWidgetObj( new InfoWidgetObj(true, 'Gast gelöscht.') );
        
            } else {
        
                setAlertObj( new AlertObj(true, 'Fehler', 'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.', standardAlertButton,) );
        
            }

        },
    });

    const { mutate: postDeleteStaysByGuestMutation } = useMutation({
        mutationFn: () => postDeleteStaysByGuest(guestObj.guest_id),
        onSuccess: (data) => {

            if (data && data.success) {
    
                setInfoWidgetObj( new InfoWidgetObj(true, 'Aufenthalt(e) gelöscht.') );

                postDeleteGuestMutation();
        
            } else {
        
                setAlertObj( new AlertObj(true, 'Fehler', 'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.', standardAlertButton,) );
        
            }

        },
    })

    const handleCloseDetailView = () => {

        setIsVisibleDetailView(false);
        setCss('0');

    }

    const handleDetailViewEditClick = () => {

        setIsVisibleDetailView(false);
        setIsVisibleAddEditView(true);

    }

    const handleCancelAddEditView = () => {

        setIsVisibleAddEditView(false);
        setGuestObj( emptyGuest );

    }

    return ( 
        <div className="content-backview">
            <div className="list-column">
                <div className="flex-container center w100prc" style={{ overflow: 'visible', position: 'relative', height: searchBarHeight, padding: '10px', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px', }}>
                    <SearchBar iconName1="person_add" title="Gäste" iconName2="filter_alt" iconName3="" inputPlaceholder="Suche" searchString={searchString} setSearchString={ (searchString) => setSearchString(searchString)} handleClick1={ handleAddGuest } handleClick2={() => setIsVisibleFilterPopUp( (prev) => !prev )} handleClick3={() => {}}/>
                    { isVisibleFilterPopUp && <FilterPopUp showBlacklist={showBlacklist} handleShowBlacklist={handleShowBlacklist} rateValue={rateValue} handleSetRateValue={ (newRateValue) => setRateValue(newRateValue) } /> }
                </div>
                <div className="flex-container center-y w100prc" style={{ padding: '10px 10px 10px 10px', height: `calc(100% - ${searchBarHeight})`, justifyContent: 'flex-start', overflow: 'visible', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px' }}>
                    <AlphabetBar selectedChar={selectedChar} setSelectedChar={ (char) => setSelectedChar(char) }/>
                    <GuestsList selectedChar={selectedChar} searchString={deferredSearchString} showBlacklist={showBlacklist} rateValue={rateValue} /*reFetch={refetchGuestsList} doneFetching={() => setRefetchGuestsList(false)}*/ handleSelectedGuest={handleSelectedGuest}/>
                </div>
            </div>
            {
                window.innerWidth > 759 ?

                <div className="flex-container center-y h100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', width: 'calc(100% - 420px)', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px', }}>
                    { isVisibleAddEditView ? <GuestBackview isVisibleAddEditView={isVisibleAddEditView} guest={ guestObj } handleCancel={ handleCancelAddEditView } handleSave={ handleSaveGuest }/> : <BigAddIcon viewComponent={ViewComponent.Guest} handleAddClick={handleAddGuest} /> }
                </div>
                :
                isVisibleAddEditView &&
                <div className="flex column w100prc" style={{ position: 'absolute', height: 'auto', padding: '5px', backgroundColor: 'var(--bg-color-white)', overflowY: 'scroll' }}>
                    <GuestAddEditView guest={guestObj} handleCancel={handleCancelAddEditView} handleSave={handleSaveGuest}/>
                </div>
            }           
            { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body) }
            { alertObj.isVisible && createPortal( <ActionAlert actionAlertId="guestMainAlert" title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
            { isVisibleDetailView && createPortal( <GuestDetailView guest={guestObj} style={{ width: '350px', paddingBottom: '20px'}} handleClose={handleCloseDetailView} handleEditClick={handleDetailViewEditClick}/>, document.body ) }
        </div>
     );
}
 
export default Guests;