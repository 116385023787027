import * as React from "react";
import { useContext, useState } from "react";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import ModalView from "../generalComps/ModalView"
import { replacePlaceholderKurtaxeText } from "../methods/standard.methods";

type Props = {
    handleClose: () => void;
}

const StayCityTaxView = ( { handleClose }: Props ) => {

    const { stay, setStay, priceListArr } = useContext(StayDetailViewContext)

    const [localPriceListArr, setLocalPriceListArr] = useState(priceListArr);

    const adultKtArr = localPriceListArr.filter( obj => obj.art == 2 && obj.kt_art == 0 );

    const childrenKtArr = localPriceListArr.filter( obj => obj.art == 2 && obj.kt_art == 1 );

    const defaultKtArr = localPriceListArr.filter( obj => obj.art == 2 && obj.kt_art == 2 );

    const remainderAdult = adultKtArr.length / stay.stay_adults;
    const remainderchildren = childrenKtArr.length / stay.stay_children;

    let indexAdult = 0;
    let indexChildren = 0;

    const handleToggleOnClick = (e: React.SyntheticEvent, uid: number) => {

        const tempPriceListArr = [...localPriceListArr];

        const priceListObj = tempPriceListArr.find( obj => obj.uid === uid );

        priceListObj!.active = !priceListObj!.active!;

        setLocalPriceListArr( tempPriceListArr );

        e.stopPropagation();

    }

    const handleArrowOnClick = (e: React.SyntheticEvent, uid: number, val: number) => {

        const tempPriceListArr = [...localPriceListArr];

        const priceListObj = tempPriceListArr.find( obj => obj.uid === uid );

        if (priceListObj !== undefined && (priceListObj.amount! + val) >= 1) {

            const amount = priceListObj.amount;
            const dayPrice = priceListObj.price / amount!;

            priceListObj.amount = priceListObj.amount! + val;
            priceListObj.price = dayPrice * priceListObj.amount!;

            setLocalPriceListArr( tempPriceListArr );

        }

        e.stopPropagation();

    }

    const handleSave = (e: React.SyntheticEvent) => {

        setStay( prevStay => ({...prevStay, stay_priceList: JSON.stringify( localPriceListArr ) }));

    }

    return ( 

        <ModalView modalViewId="stayAdditionalServiceView" handleClose={handleClose} style={{width: '600px'}}>
            <div className="flex center-y w100prc">
                <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.2rem', }}>Kurtaxe</div>
                <div onClick={handleSave} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>check_circle</div>
            </div>

            <div className="flex column w100prc" style={{ padding: '10px 0'}}>

                {
                    adultKtArr.map( (obj, i) => {

                        const r = i % remainderAdult === 0;

                        if (r) {
                            indexAdult++;
                        }

                        return(
                            <div key={obj.uid!} className="flex center column w100prc" style={{padding: '2px 5px'}}>
                               
                               { r && <div className="flex center-y w100prc" style={{ paddingTop: '5px' }}>{`${indexAdult}. Erwachsener`}</div> }

                               <div className="flex center w100prc">
                                    <div className="flex center-y color-lightblack w100prc">{ (obj.active ? replacePlaceholderKurtaxeText(obj.text!, obj.price, obj.amount!) : 'Kurtaxe deaktiviert') }</div>

                                    <div className="flex center" style={{width: '80px', visibility: obj.active ? 'visible' : 'hidden', }}>
                                        <div onClick={(e) => handleArrowOnClick(e, obj.uid!, -1) } className={`clickable-icon${ obj.amount === 1 ? ' disable-pointer-events' : ''}`} style={{fontSize: '1.4em', opacity: obj.amount === 1 ? '0.2' : '1.0'}} >keyboard_arrow_down</div>
                                        <div onClick={(e) => handleArrowOnClick(e, obj.uid!, 1) } className="clickable-icon" style={{fontSize: '1.4em',}}>keyboard_arrow_up</div>
                                    </div>
                                    <div onClick={ (e) => handleToggleOnClick(e, obj.uid!) } className="clickable-icon" style={{ width: '40px', fontSize: '1.4em', }}>{ obj.active ? 'toggle_on' : 'toggle_off' }</div>
                               </div>

                            </div>
                        )

                    })
                }

                {
                    childrenKtArr.map( (obj, i) => {

                        const r = i % remainderchildren === 0;

                        if (r) {
                            indexChildren++;
                        }

                        return(
                            <div key={obj.uid!} className="flex center column w100prc" style={{padding: '2px 5px'}}>
                               
                               { r && <div className="flex center-y w100prc" style={{ paddingTop: '5px' }}>{`${indexChildren}. Kind`}</div> }

                               <div className="flex center w100prc">
                                    <div className="flex center-y color-lightblack w100prc">{ (obj.active ? replacePlaceholderKurtaxeText(obj.text!, obj.price, obj.amount!) : 'Kurtaxe deaktiviert') }</div>

                                    <div className="flex center" style={{width: '80px', visibility: obj.active ? 'visible' : 'hidden', }}>
                                        <div onClick={(e) => handleArrowOnClick(e, obj.uid!, -1) } className={`clickable-icon${ obj.amount === 1 ? ' disable-pointer-events' : ''}`} style={{fontSize: '1.4em', opacity: obj.amount === 1 ? '0.2' : '1.0'}} >keyboard_arrow_down</div>
                                        <div onClick={(e) => handleArrowOnClick(e, obj.uid!, 1) } className="clickable-icon" style={{fontSize: '1.4em',}}>keyboard_arrow_up</div>
                                    </div>
                                    <div onClick={ (e) => handleToggleOnClick(e, obj.uid!) } className="clickable-icon" style={{ width: '40px', fontSize: '1.4em', }}>{ obj.active ? 'toggle_on' : 'toggle_off' }</div>
                               </div>

                            </div>
                        )

                    })
                }

                {
                    defaultKtArr.map( (obj, i) => {

                        const splittedText = obj.text ? obj.text.split(':') : '';
                        const titleText = splittedText[0];
                        const subtitleText = splittedText[1]

                        return(
                            <div key={obj.uid!} className="flex center column w100prc" style={{padding: '2px 5px'}}>
                               
                               <div className="flex center-y w100prc" style={{ paddingTop: '5px' }}>{`${++i}. ${titleText}`}</div>

                               <div className="flex center w100prc">
                                    <div className="flex center-y color-lightblack w100prc">{ (obj.active ? replacePlaceholderKurtaxeText(subtitleText, obj.price, obj.amount!) : 'Kurtaxe deaktiviert') }</div>

                                    <div className="flex center" style={{width: '80px', visibility: obj.active ? 'visible' : 'hidden', }}>
                                        <div onClick={(e) => handleArrowOnClick(e, obj.uid!, -1) } className={`clickable-icon${ obj.amount === 1 ? ' disable-pointer-events' : ''}`} style={{fontSize: '1.4em', opacity: obj.amount === 1 ? '0.2' : '1.0'}} >keyboard_arrow_down</div>
                                        <div onClick={(e) => handleArrowOnClick(e, obj.uid!, 1) } className="clickable-icon" style={{fontSize: '1.4em',}}>keyboard_arrow_up</div>
                                    </div>
                                    <div onClick={ (e) => handleToggleOnClick(e, obj.uid!) } className="clickable-icon" style={{ width: '40px', fontSize: '1.4em', }}>{ obj.active ? 'toggle_on' : 'toggle_off' }</div>
                               </div>

                            </div>
                        )

                    })
                }

            </div>

        </ModalView>

     );
}
 
export default StayCityTaxView;