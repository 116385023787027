import { DefaultFetchResult, PropertyAddress } from "./types";

export const fetchAddressAll = async () => {

    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=address&do=select_all',
        {
        method: 'GET', 
       'credentials': 'include',
        }
      )

    const content: DefaultFetchResult = await result.json();
    return content;

}

export const fetchAddress = async (address: PropertyAddress) => {

  const data = new URLSearchParams();

  data.append('address_street', address.address_street);
  data.append('address_zip', address.address_zip);
  data.append('address_city', address.address_city);
  data.append('address_country', address.address_country);

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=address&do=select',
      {
      method: 'POST',
      body: data, 
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  return content;

}

export const postAddAddress = async (address: PropertyAddress) => {

  const data = new URLSearchParams();

  data.append('address_street', address.address_street);
  data.append('address_zip', address.address_zip);
  data.append('address_city', address.address_city);
  data.append('address_country', address.address_country);

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=address&do=insert',
      {
      method: 'POST',
      body: data, 
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();
  return content;

}