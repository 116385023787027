import { ReactElement, useContext, useEffect, useId, useMemo, useRef, useState } from "react";
import { SettingsViewContext } from "../../contexts/SettingsContext";
import { PropertysContext } from "../../contexts/PropertysContext";
import PopUp from "../../generalComps/PopUp";
import { AlertObj, CalendarShareObj, ImportUrlObj, InfoWidgetObj, Property, ViewState } from "../../methods/types";
import { useMutation } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../methods/http.property.methods";
import InfoWidget from "../../generalComps/InfoWidget";
import ActionAlert from "../../generalComps/ActionAlert";
import { emptyCalendarShareObj, emptyImportUrlObj } from "../../methods/constants";
import CalendarShareCard from "./CalendarShareCard";
import { fetchCalendarShareData, postInsertShare, postShareDelete, postUpdateShare } from "../../methods/http.calendar.methods";
import CalendarShareAddEditView from "./CalendarShareAddEditView";

type Props = {
    isModal: boolean;
    handleClose?: () => void | undefined;
}

const CalendarShareList = ( {isModal, handleClose }: Props ) => {

    const { propertys, reloadPropertys } = useContext(PropertysContext);
    const { setSettingsViewType, lastSettingsViewTypeRef } = useContext(SettingsViewContext);

    const [calendarShareData, setCalendarShareData] = useState<Array<CalendarShareObj>>([]);

    const [isVisibleShareAddEditView, setIsVisibleShareAddEditView] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const selectedShareObjRef = useRef<CalendarShareObj>( emptyCalendarShareObj );

    const { mutate: fetchCalendarShareDataMutation } = useMutation({
        mutationFn: () => fetchCalendarShareData(),
        onSuccess: (data) => {

            if (data.success) {
                setCalendarShareData( data.obj );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postInsertShareMutation } = useMutation({
        mutationFn: ( newShareObj: CalendarShareObj ) => postInsertShare( newShareObj ),
        onSuccess: () => {

            fetchCalendarShareDataMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdateShareMutation } = useMutation({
        mutationFn: ( newShareObj: CalendarShareObj ) => postUpdateShare( newShareObj ),
        onSuccess: () => {

            fetchCalendarShareDataMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postDeleteShareObjMutation } = useMutation({
        mutationFn: ( shareId: number ) => postShareDelete( shareId ),
        onSuccess: () => {

            fetchCalendarShareDataMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( fetchCalendarShareDataMutation, [] );

    const NoData = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Freigabe vorhanden</div>;
    }

    const handleShareCardClick = (state: ViewState, shareObj: CalendarShareObj ) => {

        if (state === ViewState.Details) {

            const tempShareObj = { ...shareObj, share_toggle: shareObj.share_toggle === 0 ? 1 : 0 }; 

            postUpdateShareMutation( tempShareObj );

            setInfoWidgetObj( new InfoWidgetObj( true, `Freigabe ${ shareObj.share_toggle === 0 ? 'aktiviert' : 'deaktiviert' }` ) );

        } else if (state === ViewState.Delete) {

            const alertButtons = [ {title: 'Löschen', handler: () => {

                postDeleteShareObjMutation( shareObj.share_id );

                setAlertObj( new AlertObj() );

                setInfoWidgetObj( new InfoWidgetObj( true, 'Import-URL gelöscht' ) );

            } }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Diese Freigabe löschen?', `Soll die gewählte Freigabe wirklich gelöscht werden?`, alertButtons ) );

        } else if (state === ViewState.Edit) {

            selectedShareObjRef.current = shareObj;
            setIsVisibleShareAddEditView(true);

        }

    }

    const handleSave = ( newShareObj: CalendarShareObj ) => {

        if ( newShareObj.share_id === -1 ) {
            postInsertShareMutation( newShareObj );
        } else {
            postUpdateShareMutation( newShareObj );
        }

        setInfoWidgetObj( new InfoWidgetObj( true, `Freigabe ${ newShareObj.share_id === -1 ? 'hinzugefügt' : 'aktualisiert' }` ) );

        setIsVisibleShareAddEditView(false);

    }

    return ( 

        <>
        <div className="navigation-view-bar">
            { isModal ?
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                :
                <div onClick={ () => setSettingsViewType( lastSettingsViewTypeRef.current ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
            }
            <div className="main-title">Belegungsplan - Freigabe</div>
            <div onClick={ () => { selectedShareObjRef.current = emptyCalendarShareObj; setIsVisibleShareAddEditView(true); } } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
        </div>
        <div className="shadowBox" style={{ marginTop: '10px' }}>Gebe deinen Belegungsplan dauerhaft oder befristet an einen Partner, eine Putzfrau etc. frei. Bei Bedarf kannst du diesen auch mit einem Passwort schützen.</div>
        
        <div className="flex center w100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: '3px', marginTop: '10px' }}>
        { 

            calendarShareData.length === 0 ?

            <NoData />

            :

            calendarShareData.map( ( shareObj ) => {

                return <CalendarShareCard key={shareObj.share_id} shareObj={shareObj} handleOnClick={ (state) => handleShareCardClick(state, shareObj) } />;
    
            }) 

        }
        </div>
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        { isVisibleShareAddEditView && <CalendarShareAddEditView shareObj={selectedShareObjRef.current} handleCancel={ () => setIsVisibleShareAddEditView(false) } handleSave={ handleSave } /> }
        </>
     );
}
 
export default CalendarShareList;