import * as React from "react";
import { memo, useContext, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import { paymentIconVisibility, amountOfPaymentListArr } from "../methods/price.calculation.methods";
import { PaymentAmountType } from "../methods/types";
import StayAdditionalServiceView from "./StayAdditionalServiceView";
import StayBenefitsPopUpView from "./StayBenefitsPopUpView";
import StayCityTaxView from "./StayCityTaxView";

type Props = {
    handlePaymentsClick: (paymentType: PaymentAmountType, title: string, artikel: string) => void;
}

const StayInvoiceView = ( {handlePaymentsClick}:Props ) => {

    const { property, priceArr, taxArr, zlArr, ktArr, invoicePriceObj, paymentArr, pmSettingsObj, isOldPriceListArr } = useContext(StayDetailViewContext);

    const [isVisibleBenefitsPopUp, setIsVisibleBenefitsPopUp] = useState(false);
    const [isVisibleZlView, setIsVisibleZlView] = useState(false);
    const [isVisibleKtView, setIsVisibleKtView] = useState(false);

    const currency = useMemo( () => pmSettingsObj!.property_currency, [pmSettingsObj!.property_currency] );

    const discountArr = JSON.parse( property.property_abz ? property.property_abz! : '[]' );

    const aopla = amountOfPaymentListArr(paymentArr);

    let depositText = 'Anzahlung';

    if( pmSettingsObj!.property_deposit ) {

        if (!pmSettingsObj!.property_deposit_pauschal){

            if ( invoicePriceObj.depositAmount != 0 && (invoicePriceObj.depositAmount <= pmSettingsObj!.property_deposit_min)) {
                depositText += ' (Mindestpreis)';
            } else {
                depositText += ' ' + pmSettingsObj!.property_deposit_percent + ' %';
            }

        } else {

            depositText += ' (pauschal)';

        }

    }

    const handleOnClickBenefitsPopUp = (e: React.SyntheticEvent) => {

        setIsVisibleBenefitsPopUp(true);

        e.stopPropagation();

    }

    const handleCloseBenefitsPopUp = () => {

        setIsVisibleBenefitsPopUp(false);

    }

    const handleZlOnClick = (e: React.SyntheticEvent) => {

        setIsVisibleZlView(true);

        e.stopPropagation()

    }

    const handleKtOnClick = (e: React.SyntheticEvent) => {

        setIsVisibleKtView(true);

        e.stopPropagation()

    }

    return ( 
        <>
        <div className="flex center-y w100prc" style={{fontSize: '1.2em', marginBottom: '10px'}}>Kostenaufstellung</div>
        <div style={{padding: '5px'}}>
        { priceArr.map( (obj, i) => {

                let n = 'Nächte';

                let text = '';

                if (obj.art === 0) {

                    if (obj.nights === 1) {
                        n = 'Nacht';
                    }

                    text = `${obj.nights} ${n} x ${obj.price.toFixed(2)} ${currency}`;

                } else if (obj.art === 1) {

                    text = '+ Aufschläge für weitere Personen';

                }

                return(

                    <div key={i} className="flex center-y w100prc standard-fontsize" style={{overflow: 'visible', position: 'relative'}}>
                        { (obj.art == 0 && discountArr.length > 0 && !isOldPriceListArr()) &&
                        <div onClick={(e) => handleOnClickBenefitsPopUp(e) } className="clickable-icon" style={{ width: '25px', visibility: i === 0 ? 'visible' : 'hidden' }}>unfold_more</div>
                        }
                        <div className="flex center-y" style={{width: '70%'}}>{text + ((obj.art === 0 && obj.discount!.abz_name && obj.discount!.abz_name.length > 0) ? ' | ' + obj.discount!.abz_name : '')}</div>
                        <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${obj.sum.toFixed(2)} ${currency}`}</div>
                        <div onClick={() => handlePaymentsClick(obj.art === 0 ? PaymentAmountType.OnlyNights : PaymentAmountType.OnlyAdditionalPeople, 'Betrag', 'der') } className="flex center clickable-icon" style={{ width: '30px', visibility: paymentIconVisibility(((100 + obj.taxPercent) * obj.sum) / 100, paymentArr, aopla, invoicePriceObj.fullAmount) }}>payments</div>
                        { ( isVisibleBenefitsPopUp && i === 0 ) && <StayBenefitsPopUpView selectedId={obj.discount!.abz_id} handleClosePopUp={handleCloseBenefitsPopUp}/>}
                    </div>

                )

            } ) 
        
        }
        </div>

        { zlArr.length > 0 &&

            <div className="flex center-y w100prc standard-fontsize" style={{padding: '0 5px'}}>
                <div onClick={handleZlOnClick} className="flex center clickable-icon" style={{width: '25px'}}>edit</div>
                <div className="flex center-y" style={{width: '70%'}}>Zusatzleistungen</div>
                <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.additionalServiceAmountNoTax.toFixed(2)} ${currency}`}</div>
                <div onClick={() => handlePaymentsClick(PaymentAmountType.AdditionalService, 'Zusatzleistung', 'die') } className="flex center clickable-icon" style={{ width: '30px', visibility: paymentIconVisibility( invoicePriceObj.additionalServiceAmount, paymentArr, aopla, invoicePriceObj.fullAmount ) }}>payments</div>
            </div>

        }

        { ktArr.length > 0 &&

        <div className="flex center-y w100prc standard-fontsize" style={{padding: '0 5px'}}>
            <div onClick={handleKtOnClick} className="flex center clickable-icon" style={{width: '25px'}}>edit</div>
            <div className="flex center-y" style={{width: '70%'}}>Gästebeitrag</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.cityTaxAmountNoTax.toFixed(2)} ${currency}`}</div>
            <div onClick={() => handlePaymentsClick(PaymentAmountType.CityTax, 'Kurtaxe', 'die') } className="flex center clickable-icon" style={{ width: '30px', visibility: paymentIconVisibility( invoicePriceObj.cityTaxAmount, paymentArr, aopla, invoicePriceObj.fullAmount ) }}>payments</div>
        </div>

        }

        { (invoicePriceObj.netAmount != 0 && taxArr.length != 0) &&

        <div className="flex center-y w100prc standard-fontsize" style={{padding: '10px 5px'}}>
            <div className="flex center-y" style={{width: '70%', fontWeight: 'bold'}}>Nettobetrag</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right', paddingRight: '30px'}}>{`${invoicePriceObj.netAmount.toFixed(2)} ${currency}`}</div>
        </div>

        }

        { taxArr.map( (obj, i) => {

            return(

                <div key={i} className="flex center-y w100prc standard-fontsize" style={{padding: '0 5px'}}>
                    <div className="flex center-y" style={{width: '70%'}}>{`${obj.taxPercent} % Umsatzsteuer`}</div>
                    <div className="flex" style={{width: '30%', justifyContent: 'right', paddingRight: '30px'}}>{`${obj.sum.toFixed(2)} ${currency}`}</div>
                </div>

            );

        })

        }

        <div className="flex center-y w100prc standard-fontsize" style={{padding: '10px 5px'}}>
            <div className="flex center-y" style={{width: '70%', fontWeight: 'bold'}}>Rechnungsbetrag</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.fullAmount.toFixed(2)} ${currency}`}</div>
            <div onClick={() => handlePaymentsClick(PaymentAmountType.Full, 'Gesamtbetrag', 'der') } className="flex center clickable-icon" style={{ width: '30px', visibility: paymentIconVisibility( invoicePriceObj.fullAmount, paymentArr, aopla, invoicePriceObj.fullAmount ) }}>payments</div>
        </div>

        { pmSettingsObj!.property_deposit &&

        <div className="flex center-y w100prc standard-fontsize" style={{padding: '0 5px'}}>
            <div className="flex center-y" style={{width: '70%'}}>{depositText}</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.depositAmount.toFixed(2)} ${currency}`}</div>
            <div onClick={() => handlePaymentsClick(PaymentAmountType.Deposit, 'Anzahlung', 'die') } className="flex center clickable-icon" style={{ width: '30px', visibility: paymentIconVisibility( invoicePriceObj.depositAmount, paymentArr, aopla, invoicePriceObj.fullAmount ) }}>payments</div>
        </div>

        }
        { isVisibleZlView && createPortal(<StayAdditionalServiceView handleClose={ () => setIsVisibleZlView(false) } />, document.body) }
        { isVisibleKtView && createPortal(<StayCityTaxView handleClose={ () => setIsVisibleKtView(false) } />, document.body) }
        </>
     );
}
 
export default memo( StayInvoiceView );