import { DefaultFetchResult, ExposeObj } from "./types";

export const fetchHandoverImageBlob = async (blobId: number) => {

    const data = new URLSearchParams();
  
    data.append('blob_id', blobId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=blob&do=select`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postAddHandoverImageBlob = async (blobData: string, propertyId: number) => {

    const data = new URLSearchParams();
  
    data.append('blob_data', blobData);
    data.append('blob_property', propertyId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=blob&do=insert`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postDeleteHandoverImageBlob = async (blobId: number) => {

    const data = new URLSearchParams();
  
    data.append('blob_id', blobId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=blob&do=delete`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postUpdateHandoverImageBlob = async (blobData: string, blobId: number) => {

    const data = new URLSearchParams();
  
    data.append('blob_data', blobData);
    data.append('blob_id', blobId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=blob&do=update`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}