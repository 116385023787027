import { useNavigate } from "react-router-dom";
import MenuButton from "./MenuButton";

const NoPropertyComp = () => {

    const navigate = useNavigate();

    return ( 

        <div className="shadowBox column center">
            <div className="default-icon" style={{ fontSize: '8.0em', width: 'auto' }}>info</div>
            <div className="shadowBox">Diese Ansicht ist deaktiviert, da bisher keine Immobilie angelegt wurde. Bitte lege eine Immobilie an, um alle Funktionen nutzen zu können.</div>
            <MenuButton style={{ marginTop: '20px' }} buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => { navigate('/eazyac/immobilien'); } } leftIcon="house" buttonTitle="Immobilie anlegen" />
        </div>

     );
}
 
export default NoPropertyComp;