import { AbzObj, ConversationObj, DateObj, Guest, KtObj, Property, Stay, ZlObj } from "./types";

export const getFullname = (title: string, firstName: string, lastName: string, company: string): {fullname: string, company: string} => {

    let fullname = firstName + " " + lastName;
    let tempCompany = company;

    if (title === 'Firma') {

        fullname = company;
        tempCompany = '';

    }

    return {fullname: fullname, company: tempCompany};

}

export const getVermieterFullName = (title: string, firstName: string, lastName: string, company: string) => {

    try {

        if ( company.length !== 0 ) {

            return company;
    
        } else {
    
            if ( title === 'Familie' ) {
    
                return title + ' ' + lastName;
    
            } else {
    
                return title + ' ' + firstName + ' ' + lastName;
    
            }
    
        }

    } catch(e) {

        return '';

    }

}

export const getDateObj = (givenDate: Date | string, addDays: number): DateObj => {

    let date = new Date();

    if (typeof givenDate === 'string') {
        date = new Date(givenDate);
    } else {
        date = givenDate;
    }
    
    date.setHours(0,0,0,0);
    date.setDate( date.getDate() + addDays);

    const dateStringUS = date.toLocaleDateString('fr-ca');
    const dateStringDE = date.toLocaleDateString('de-DE');
    const dayStringShort = date.toLocaleString('de-DE', {  weekday: 'short' });
    const dayStringLong = date.toLocaleString('de-DE', {  weekday: 'long' });

    return { date: date, dateStringDE: dateStringDE, dateStringUS: dateStringUS, dayStringShort: dayStringShort, dayStringLong: dayStringLong };

}

// export const dateObjFromDate = (date: Date, addDays: number): DateObj => {

//     date.setHours(0,0,0,0);
//     date.setDate( date.getDate() + addDays);

//     const dateStringUS = date.toLocaleDateString('fr-ca');
//     const dateStringDE = date.toLocaleDateString('de-DE');
//     const dayStringShort = date.toLocaleString('de-DE', {  weekday: 'short' });
//     const dayStringLong = date.toLocaleString('de-DE', {  weekday: 'long' });

//     return { date: date, dateStringDE: dateStringDE, dateStringUS: dateStringUS, dayStringShort: dayStringShort, dayStringLong: dayStringLong };

// }

export const daysBetweenDates = (startDate: Date, endDate: Date) => {

    const difference = endDate.getTime() - startDate.getTime();

    return Math.ceil(difference / (1000 * 3600 * 24));

}

export const initDateArray = (startDate: DateObj, endData: DateObj) => {

    let dateArr: Array<DateObj> = [];

    let newDate: DateObj = startDate;

    do {

        dateArr.push(newDate);

        newDate = getDateObj( new Date( newDate.date), 1);

    } while (newDate.date.getTime() <= endData.date.getTime())

    return dateArr;

}

export const mapPropertyAndId = ( propertys: Array<Property> ): { names: Array<String>, ids: Array<Number> } => {

    const names = propertys.map( (property) => property.property_name! );
    const ids = propertys.map( (property) => property.property_id );

    return { names: names, ids: ids };

}

export const truncateString = (str: string, length: number) => {
    return str.length > length ? str.substring(0, length - 3) + '...' : str
}

export const filterGuests = (guests: Array<Guest>, searchString: string, selectedChar: string, showBlacklist: boolean, filterBarRateValue: number) => {

    return guests.filter( ( guest ) => {

        const guestName = getFullname(guest.guest_title!, guest.guest_firstname!, guest.guest_lastname!, guest.guest_company!);
    
        const blacklist = guest.guest_blacklist === 0 ? false : true;
    
        if (showBlacklist) {
    
            return blacklist === showBlacklist;
    
        } else if (filterBarRateValue !== -1) {
    
            return guest.guest_rate === filterBarRateValue && !blacklist;
    
        } else {
    
            if ( searchString.length === 0 && selectedChar.length !== 0 ) {
    
                if (guest.guest_title! === 'Firma') {
                    return guestName.fullname.charAt(0).toUpperCase() === selectedChar;
                } else {
                    return guest.guest_lastname!.charAt(0).toUpperCase() === selectedChar;
                }
    
            } else if ( searchString.length !== 0 ) {
    
                    const lastName = guest.guest_lastname!;
                    const firstName = guest.guest_firstname!;
                    const company = guest.guest_company!;
                    const fullName = firstName + ' ' + lastName;
            
                    return (lastName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 || firstName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 || company.toLowerCase().indexOf(searchString.toLowerCase()) !== -1 || fullName.toLowerCase().indexOf(searchString.toLowerCase()) !== -1);
    
            } else {
                return guest;
            }
    
        }
    
    });

}

export const generateKtIdsString = (jsonString: string) => {

    const kt_id_arr: Array<KtObj> = JSON.parse( jsonString );
    
    let kt_ids = kt_id_arr.map( obj => { return obj.kt_id }).join(',');

    if (kt_ids.length === 0) {
        kt_ids = "9999"
    }

    return kt_ids;

}

export const generateZlIdsString = (jsonString: string) => {

    const zl_id_arr: Array<ZlObj> = JSON.parse( jsonString );
    
    let zl_ids = zl_id_arr.map( obj => { return     obj.zl_id }).join(',');

    if (zl_ids.length === 0) {
        zl_ids = "9999"
    }

    return zl_ids;

}

export const generateAbzIdsString = (jsonString: string) => {

    const id_arr: Array<AbzObj> = JSON.parse( jsonString );
    
    let ids = id_arr.map( obj => { return     obj.abz_id }).join(',');

    if (ids.length === 0) {
        ids = "9999"
    }

    return ids;

}

export const replacePlaceholderKurtaxeText = (text: string, price: number, nights: number) => {

    let day = 'Tage';

    if (nights === 1) {
        day = 'Tag';
    }

    let ktText = text;

    ktText = ktText.replace('{price}', price.toFixed(2));
    ktText = ktText.replace('{nights}', nights + ' ' + day);
    ktText = ktText.replace('{dayPrice}', (price / nights).toFixed(2));

    return ktText;

}

export const rgbToHex = (rgbArr: Array<number>) => {

    const [r, g, b] = rgbArr;

    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export const hexToRgb = (hex: string) => {
    
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;

}

export const arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
);

export const createMailSectionArray = (stayArr: Array<Stay>, korresArr: Array<ConversationObj>) => {

    const newKorresArr = korresArr ? korresArr : [];

    return newKorresArr.reduce((acc: {[key:string]: Array<ConversationObj>}, item) => {

        let stay_id = item['korres_stay'];

        const stay = stayArr.find( stay => {
            return stay.stay_id === stay_id;
        });

        let sectionName = 'Fehler';

        if (stay_id === 9999999) {
            sectionName = 'Keinem Aufenthalt zugewiesen';
        } else {
            
            if (stay) {
                sectionName = stay_id + '§' + getFullname(stay.guest_title!, stay.guest_firstname!, stay.guest_lastname!, stay.guest_company!).fullname + ' | ' + getDateObj( stay.stay_checkIn, 0 ).dateStringDE + ' - ' + getDateObj( stay.stay_checkOut, 0 ).dateStringDE;
            } else {
                sectionName = 'Aufenthalt wurde gelöscht';
            }
            
        }

        let sectionNameUpperCase = sectionName.toUpperCase();

        if (!acc[sectionNameUpperCase]) {

        acc[sectionNameUpperCase] = [];

        }

        acc[sectionNameUpperCase].push(item);

        return acc;

    }, {});

}

export const createMailSeperatorArray = (korresArr: Array<ConversationObj>) => {

    const conversationMailTypeArr = [
    
        {text: 'Angebot', art: 0},
        {text: 'Buchung', art: 1},
        {text: 'Quittung', art: 2},
        {text: 'Rechnung', art: 3},
        {text: 'Erinnerung', art: 4},
        {text: 'Mahnung', art: 5},
        {text: 'Exposé', art: 6},
        {text: 'Übergabeprotokoll', art: 7},
        {text: 'Nachricht', art: 8},
        {text: 'Zahlungsbestätigung', art: 9},
    
    ]

    return korresArr.reduce((acc: {[key:string]: Array<ConversationObj>}, obj) => {

        const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        const date  = new Date( obj.creation_timestamp.split(" ")[0] );

        let seperatorText = '';

        if (obj.korres_art <= 7 || obj.korres_art === 9) {

            seperatorText = `${conversationMailTypeArr[obj.korres_art].text} gesendet: ${date.toLocaleDateString('de-DE', options)}`;
        
        } else if (obj.korres_art === 8 || obj.korres_art === 12) {
    
            seperatorText = `Nachrichtenverlauf am ${date.toLocaleDateString('de-DE', options)}`;
    
        } else {
    
            if (obj.korres_subject && obj.korres_subject.length > 0) {
    
                seperatorText = `${obj.korres_subject}:`;
    
            } else {
    
                seperatorText = 'Unknown';
    
            }
    
        }

        if (!acc[seperatorText]) {

        acc[seperatorText] = [];

        }

        acc[seperatorText].push(obj);

        return acc;

    }, {});

}

export const validURL = (str: string) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export const copyToClipboard = (text: string) => {

    navigator.clipboard.writeText(text)
    .then(() => {
        console.log(`Copied text to clipboard: ${text}`);
        //alert(`Copied text to clipboard: ${text}`);
    })
    .catch((error) => {
        console.error(`Could not copy text: ${error}`);
    });
}