import '../css/ratebar.css'

type Props = {
    ratevalue: number;
    style?: React.CSSProperties | undefined;
    isClickable: boolean;
    handleRate?: (newRateValue: number) => void | undefined;
}

const RateBar = ( { ratevalue, style, isClickable, handleRate }: Props ) => {

    const handleNewValue = (value: number) => {

        if (isClickable && handleRate) {
            handleRate(value);
        }

    }
    
    return ( 
        <div className={ `rate-bar${ !isClickable ? ' disable-pointer-events' : '' }` } style={style}>
            <div onClick={ () => handleNewValue(0) } className={`star${ratevalue >= 0 ? " filled" : ""}`}>star</div>
            <div onClick={ () => handleNewValue(1) } className={`star${ratevalue >= 1 ? " filled" : ""}`}>star</div>
            <div onClick={ () => handleNewValue(2) } className={`star${ratevalue >= 2 ? " filled" : ""}`}>star</div>
            <div onClick={ () => handleNewValue(3) } className={`star${ratevalue >= 3 ? " filled" : ""}`}>star</div>
            <div onClick={ () => handleNewValue(4) } className={`star${ratevalue >= 4 ? " filled" : ""}`}>star</div>
        </div>
     );
}
 
export default RateBar;