import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ReactElement, useContext, useState, useMemo, useEffect, useId, memo, useRef, useCallback } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";
import { PropertysContext } from "../contexts/PropertysContext";
import ActionAlert from "../generalComps/ActionAlert";
import BigCustomSelect from "../generalComps/BigCustomSelect";
import DateSelect, { DateSelectFormat, DateSelectType } from "../generalComps/dateSelectComps/DateSelect";
import ImageView from "../generalComps/ImageView";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import PopUp from "../generalComps/PopUp";
import StatusBar, {StatusBarType} from "../generalComps/StatusBar";
import GuestAddEditView from "../guestsComps/GuestAddEditView";
import GuestsSelectPopUpView from "../guestsComps/GuestsSelectPopUpView";
import { emptyGuest, emptyStay } from "../methods/constants";
import { fetchCanBlockDate, postAddBlock, postAddStay, postUpdateStay } from "../methods/http.stays.methods";
import { amountOfPriceListArr } from "../methods/price.calculation.methods";
import { getFullname, getDateObj, daysBetweenDates, truncateString } from "../methods/standard.methods";
import { AlertObj, BlockType, DateObj, DefaultFetchResult, Guest, PriceListType, Property, Stay, TaxType } from "../methods/types";
import StayPriceListView from "./StayPriceListView";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";

type Props = {
    handleCancel: () => void;
    handleSave: (type: string) => void;
}

const StayAddEditView = ( { handleCancel, handleSave }:Props ) => {

    const { selectedAddEditStay, setSelectedAddEditStay, selectedAddEditProperty, setSelectedAddEditProperty, calculateStayPrice } = useContext(StayDetailViewContext)

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    //const [tempStay, setTempStay] = useState(stay);
    const {propertys} = useContext(PropertysContext);
    
    //const [selectedProperty, setSelectedProperty] = useState<Property>( emptyProperty );

    useEffect( () => {

        if (selectedAddEditStay.stay_property !== -1) {
            
            const filteredProperty = propertys.array.find( (prop) => prop.property_id === selectedAddEditStay.stay_property!);
            
            if (filteredProperty) {

                setSelectedAddEditProperty(filteredProperty);

                if (selectedAddEditStay.stay_id === -1) {
                    fetchCanBlockPreDateMutation({checkInString: selectedAddEditStay.stay_checkIn!, propertyId: filteredProperty.property_id});
                    fetchCanBlockPostDateMutation({checkOutString: selectedAddEditStay.stay_checkOut!, propertyId: filteredProperty.property_id});
                }
                
            }
    
        }


    }, [selectedAddEditStay.stay_property]);

    const [selectedGuest, setSelectedGuest] = useState<Guest>( selectedAddEditStay.stay_guest === -1 ? emptyGuest : {...emptyGuest, guest_id: selectedAddEditStay.stay_guest!, guest_title: selectedAddEditStay.guest_title!, guest_firstname: selectedAddEditStay.guest_firstname!, guest_lastname: selectedAddEditStay.guest_lastname!, guest_company: selectedAddEditStay.guest_company, guest_blacklist: selectedAddEditStay.guest_blacklist!, guest_rate: selectedAddEditStay.guest_rate!} )

    const [isVisiblePropertyPopUpView, setIsVisiblePropertyPopUpView] = useState(false);
    const [isVisibleGuestPopUpView, setIsVisibleGuestPopUpView] = useState(false);

    const [isVisibleGuestAddEditView, setIsVisibleGuestAddEditView] = useState(false);

    const [isVisiblePriceListView, setIsVisiblePriceListView] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();

    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const nights = useMemo(() => daysBetweenDates(getDateObj(selectedAddEditStay.stay_checkIn!, 0).date, getDateObj(selectedAddEditStay.stay_checkOut!, 0).date), [selectedAddEditStay.stay_checkIn!, selectedAddEditStay.stay_checkOut!]);

    const [preCheckInIsBlocked, setPreCheckInIsBlocked] = useState(false);
    const [postCheckInIsBlocked, setPostCheckInIsBlocked] = useState(false);

    //const [preCheckInIsClickable, setPreCheckInIsClickable] = useState(false);
    //const [postCheckInIsClickable, setPostCheckInIsClickable] = useState(false);
    const preCheckInIsClickableRef = useRef(false);
    const postCheckInIsClickableRef = useRef(false);

    const stayForSaveRef = useRef(emptyStay);

    const calculatedPriceObj = calculateStayPrice();

    const priceInputTitle = `${nights} ${nights === 1 ? 'Nacht' : 'Nächte'} x ${calculatedPriceObj.stayPrice === 0 ? '0.00' : (calculatedPriceObj.stayPrice / nights).toFixed(2)} ${calculatedPriceObj.currency}`;

    const PropertyPopUp = ({ handleClosePopUp }: {handleClosePopUp: () => void }) => {

        return (
            <PopUp style={{ marginTop: '60px', height: 'auto' }} handleClosePopUp={handleClosePopUp}>
            {propertys.array.map((property) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => { handlePropertyPopUpOnClick(e, property) } }
                    key={property.property_id}
                >
                    <div className="flex center-y w100prc">
                        <ImageView src={`https://eazyac-dev.de/user_data/${property.user_folder!}/prop_images/${property.property_id}.png`} width={25} height={25} alt={property.property_name!} />
                        <div className="flex center-y" style={{padding: '0 5px 0 10px'}}>{property.property_name!}</div>
                        <div className="flex center-y color-lightblack">{`| ${property.property_art!}`}</div>
                        {property.property_id === selectedAddEditProperty.property_id && <div className="default-icon">check</div>}
                    </div>
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handlePropertyPopUpOnClick = (e: React.SyntheticEvent, property: Property) => {

        //setSelectedProperty(property);
        
        setSelectedAddEditStay( prevStay => ({...prevStay, stay_property: property.property_id}) );
        setSelectedAddEditProperty( property );
        setIsVisiblePropertyPopUpView(false);

        if (selectedAddEditStay.stay_id === -1) {
            fetchCanBlockPreDateMutation({checkInString: selectedAddEditStay.stay_checkIn!, propertyId: property.property_id});
            fetchCanBlockPostDateMutation({checkOutString: selectedAddEditStay.stay_checkOut!, propertyId: property.property_id});
        }

        e.stopPropagation();

    }

    const { mutate: fetchCanBlockPreDateMutation } = useMutation( {
        mutationFn: (variables: { checkInString: string, propertyId: number }) => fetchCanBlockDate(variables.propertyId.toString(), getDateObj( variables.checkInString, -1 ).dateStringUS, getDateObj( variables.checkInString, 0 ).dateStringUS ),
        onSuccess: (data) => {

            //setPreCheckInIsClickable( data.success );
            preCheckInIsClickableRef.current = data.success;

            if (!data.success) {
                setPreCheckInIsBlocked(false);
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    } );

    // useEffect( () => { 
        
    //     if (selectedAddEditStay.stay_id === -1) {
    //         fetchCanBlockPreDateMutation({checkInString: selectedAddEditStay.stay_checkIn!, propertyId: selectedAddEditProperty.property_id});
    //     } 
    
    // }, [selectedAddEditProperty]);

    
    const { mutate: fetchCanBlockPostDateMutation } = useMutation( {
        mutationFn: (variables: { checkOutString: string, propertyId: number }) => fetchCanBlockDate(variables.propertyId.toString(), getDateObj( variables.checkOutString, 0 ).dateStringUS, getDateObj( variables.checkOutString, -1 ).dateStringUS ),
        onSuccess: (data) => {

            //setPostCheckInIsClickable( data.success );
            postCheckInIsClickableRef.current = data.success;

            if (!data.success) {
                setPostCheckInIsBlocked(false);
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    } );

    // useEffect( () => { 
        
    //     if (selectedAddEditStay.stay_id === -1) {
    //         fetchCanBlockPostDateMutation(selectedAddEditStay.stay_checkOut!);
    //     } 
    
    // }, [selectedAddEditProperty]);

    const handlePropertyOnClick = (e: React.SyntheticEvent) => {

        setIsVisiblePropertyPopUpView(true);
        setIsVisibleGuestPopUpView(false);

        e.stopPropagation();

    }

    const handleGuestOnClick = (e: React.SyntheticEvent) => {

        setIsVisiblePropertyPopUpView(false);
        setIsVisibleGuestPopUpView(true);

        e.stopPropagation();

    }

    const handleSelectedGuest = (e: React.SyntheticEvent, guest: Guest) => {
        
        setSelectedAddEditStay( prevStay => ( { ...prevStay, 
            stay_guest: guest.guest_id, 
            guest_title: guest.guest_title, 
            guest_firstname: guest.guest_firstname, 
            guest_lastname: guest.guest_lastname,
            guest_company: guest.guest_company,
            guest_blacklist: guest.guest_blacklist,
            guest_rate: guest.guest_rate, } ));
        setSelectedGuest(guest);
        setIsVisibleGuestPopUpView(false);

        e.stopPropagation();

    }

    const handleNewGuest = (type: string, guest?: Guest) => {
        
        if (guest) {
            
            setSelectedGuest(guest);
        }
        
        setIsVisibleGuestAddEditView(false);

    }

    const PropertySelectContent = (): ReactElement => {

        return(

            <div className="flex center w100prc">
                <ImageView src={`https://eazyac-dev.de/user_data/${selectedAddEditProperty.user_folder!}/prop_images/${selectedAddEditProperty.property_id}.png`} width={25} height={25} alt={selectedAddEditProperty.property_name!} />
                <div className="flex center-y" style={{padding: '0 5px 0 10px'}}>{selectedAddEditProperty.property_name!}</div>
                <div className="flex center-y color-lightblack">{`| ${selectedAddEditProperty.property_art!}`}</div>
            </div>

        );

    }

    const GuestSelectContent = (): ReactElement => {

        const guestName = getFullname(selectedGuest.guest_title!, selectedGuest.guest_firstname!, selectedGuest.guest_lastname!, selectedGuest.guest_company!);

        return(

            <div className="flex center w100prc" style={{ padding: '0 10px' }}>
                <div className="flex center-y h100prc color-lightblack" style={{padding: '0 5px'}}>{selectedGuest.guest_title!}</div>
                <div className="flex center-y h100prc" style={{ whiteSpace: 'pre', overflow: 'clip' }}>{guestName.fullname} </div>
                {guestName.company.length > 0 && <div className="color-lightblack h100prc" style={{paddingLeft: '5px'}}>{`| ${guestName.company}`}</div>}
            </div>

        );

    }

    const handleOpenGuestAddEditView = (e: React.SyntheticEvent) => {
        
        setIsVisibleGuestAddEditView(true);

        e.stopPropagation();

    }

    const handleSelectedDateObj = (e: React.SyntheticEvent, dateSelectType: DateSelectType, dateObj: DateObj) => {

        if (dateSelectType === DateSelectType.CheckIn) {
            
            if (dateObj.date.getTime() >= getDateObj( selectedAddEditStay.stay_checkOut!, 0 ).date.getTime()) {
                setSelectedAddEditStay( prevStay => ({...prevStay, stay_checkIn: dateObj.dateStringUS, stay_checkOut: getDateObj(dateObj.dateStringUS, 1).dateStringUS }));
            } else {
                setSelectedAddEditStay( prevStay => ({...prevStay, stay_checkIn: dateObj.dateStringUS}));
            }

            if (selectedAddEditStay.stay_id === -1) {
                fetchCanBlockPreDateMutation({checkInString: dateObj.dateStringUS, propertyId: selectedAddEditProperty.property_id});
            } 

        } else {
            
            if (dateObj.date.getTime() <= getDateObj( selectedAddEditStay.stay_checkIn!, 0 ).date.getTime()) {
                setSelectedAddEditStay( prevStay => ({...prevStay, stay_checkOut: dateObj.dateStringUS, stay_checkIn: getDateObj(dateObj.dateStringUS, -1).dateStringUS }));
            } else {
                setSelectedAddEditStay( prevStay => ({...prevStay, stay_checkOut: dateObj.dateStringUS}));
            }

            if (selectedAddEditStay.stay_id === -1) {
                fetchCanBlockPostDateMutation({checkOutString: dateObj.dateStringUS, propertyId: selectedAddEditProperty.property_id});
            } 

        }

        e.stopPropagation();

    }

    const handleOnChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (Number(e.target.value) !== amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, JSON.parse(selectedAddEditStay.stay_priceList!))) {

            setSelectedAddEditStay( prevStay => ({...prevStay, stay_special_price: Number(e.target.value) }));

        }

    }

    const handleOpenPriceListView = () => {
        
        const priceListArr = JSON.parse( ( calculatedPriceObj.priceListArrString && calculatedPriceObj.priceListArrString.length !== 0 ) ? calculatedPriceObj.priceListArrString : '[]' );

        if ( priceListArr.length === 0 && selectedAddEditStay.stay_special_price === 0) {
            
            setAlertObj( new AlertObj(true, 'Achtung', 'Keine Einzelaufstellung vorhanden. Fülle zuerst alle notwendigen Daten aus.', Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )}) ) );

        } else if (selectedAddEditStay.stay_special_price !== 0) {
            
            setAlertObj(new AlertObj(true, 'Achtung', 'Keine Einzelaufstellung vorhanden, da der Preis manuell geändert wurde (Sonderpreis).', Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )}) ));

        } else {

            setIsVisiblePriceListView(true);

        }

    }

    const handleNewPriceListArr = (priceListString: string) => {

        setSelectedAddEditStay( prevStay => ({ ...prevStay, stay_priceList: priceListString }));

    }

    const handleSaveGuest = () => {

        if ( (selectedAddEditStay.stay_adults! + selectedAddEditStay.stay_children! === 0) || selectedAddEditProperty.property_id === -1 || selectedGuest.guest_id === -1 ) {

            setAlertObj( new AlertObj(true, 'Daten unvollständig', 'Bitte fülle alle notwendigen Daten aus.', standardAlertButton,) );
    
        } else {

            stayForSaveRef.current = { ...selectedAddEditStay, 
                stay_priceList: calculatedPriceObj.priceListArrString, 
                stay_checkIn_time: selectedAddEditProperty.property_checkIn_time, 
                stay_checkOut_time: selectedAddEditProperty.property_checkOut_time };

            if (selectedAddEditStay.stay_id === -1) {

                postAddStayMutation('new');

            } else {

                postUpdateStayMutation('update');

            }

        }

    }

    const handleSaveStay = (data: DefaultFetchResult, variables: any) => {

        if ( data && data.session === "expired" ) {
            navigate("login");
        } else if ( data && data.success ) {

            if (preCheckInIsBlocked) {
                postAddPreBlockMutation();
            }

            if (postCheckInIsBlocked) {
                postAddPostBlockMutation();
            }

            queryClient.invalidateQueries({ queryKey: ['fetchStays'] });

            handleSave(variables);
    
        } else {
    
            setAlertObj( new AlertObj(true, 'Fehler', 'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.', standardAlertButton,) );
    
        }

    }

    const { mutate: postAddStayMutation } = useMutation({
        mutationFn: () => postAddStay(stayForSaveRef.current, selectedAddEditProperty, selectedGuest.guest_id),
        onSuccess: handleSaveStay,
    })

    const { mutate: postUpdateStayMutation } = useMutation({
        mutationFn: () => postUpdateStay(stayForSaveRef.current, selectedAddEditProperty.property_id, selectedGuest.guest_id),
        onSuccess: handleSaveStay,
    })

    const { mutate: postAddPreBlockMutation } = useMutation({
        mutationFn: () => postAddBlock(stayForSaveRef.current, selectedAddEditProperty.property_id, BlockType.Pre),
    })

    const { mutate: postAddPostBlockMutation } = useMutation({
        mutationFn: () => postAddBlock(stayForSaveRef.current, selectedAddEditProperty.property_id, BlockType.Post),
    })

    return ( 

        <>
        <div className="navigation-view-bar">
          <div onClick={handleCancel} className="clickable-icon w100prc" style={{ width: '40px', fontSize: '1.5em' }}>cancel</div>
          <div className="main-title">{ truncateString( `Aufenthalt ${ selectedAddEditStay.stay_id === -1 ? 'anlegen' : 'bearbeiten' }`, 20) }</div>
          <div onClick={handleSaveGuest} className="clickable-icon w100prc" style={{ width: '40px', textAlign: "right", fontSize: '1.5em' }}>check_circle</div>
        </div>

        <div className="stayaddedit-wrapper">

            <div className="section">
                <div className="input-wrapper">
                    <BigCustomSelect onClick={(e) => handlePropertyOnClick(e)} title="Immobilie" selectString="Wähle hier deine Immobilie" popUpView={<PropertyPopUp handleClosePopUp={() => setIsVisiblePropertyPopUpView(false)}/>} isVisiblePopUp={isVisiblePropertyPopUpView} content={selectedAddEditProperty.property_id === -1 ? null : <PropertySelectContent />} />
                    <BigCustomSelect onClick={(e) => handleGuestOnClick(e)} title="Gast" selectString="Wähle hier einen Gast" isVisiblePopUp={isVisibleGuestPopUpView} popUpView={<GuestsSelectPopUpView handleOnClick={handleSelectedGuest} handleClosePopUp={() => setIsVisibleGuestPopUpView(false)}/>} content={selectedGuest.guest_id === -1 ? null : <GuestSelectContent />} addIcon={{iconName: 'person_add', handler: handleOpenGuestAddEditView}}/>
                </div>
                <div className="input-wrapper" style={{ marginTop: '15px', }}>
                    <DateSelect dateSelectType={DateSelectType.CheckIn} dateSelectFormat={DateSelectFormat.Calendar} startDateObj={getDateObj(selectedAddEditStay.stay_checkIn!, 0)} endDateObj={getDateObj(selectedAddEditStay.stay_checkOut!, 0)} propertyId={selectedAddEditProperty.property_id} handleSelectedDateObj={handleSelectedDateObj}/>
                    <DateSelect dateSelectType={DateSelectType.CheckOut} dateSelectFormat={DateSelectFormat.Calendar} startDateObj={getDateObj(selectedAddEditStay.stay_checkIn!, 0)} endDateObj={getDateObj(selectedAddEditStay.stay_checkOut!, 0)} propertyId={selectedAddEditProperty.property_id} handleSelectedDateObj={handleSelectedDateObj}/>
                </div>
                { selectedAddEditStay.stay_id === -1 &&
                <div className="flex center w100prc" style={{gap: '10px', overflow: 'visible', marginTop: '10px', }}>
                    <StatusBar type={StatusBarType.Small} selectedValueType={selectedAddEditStay.stay_status!} handleNewValue={(newValueType) => setSelectedAddEditStay( prevStay => ({ ...prevStay, stay_status: newValueType })) }/>
                </div>
                }
            </div>

            <div className="section">
                <div className="input-wrapper">
                    <InputView title="Erwachsene"><div className="default-icon">person</div><input style={{textAlign: 'center'}} onChange={(e) => setSelectedAddEditStay( prevStay => ({...prevStay, stay_adults: Number(e.target.value)}))} type="number" value={selectedAddEditStay.stay_adults!} placeholder="0" /></InputView>
                    <InputView title="Kinder"><div className="default-icon">child_care</div><input style={{textAlign: 'center'}} onChange={(e) => setSelectedAddEditStay( prevStay => ({...prevStay, stay_children: Number(e.target.value)}))} type="number" value={selectedAddEditStay.stay_children!} placeholder="0" /></InputView>
                    <InputView title="Hunde"><div className="default-icon">pets</div><input style={{textAlign: 'center'}} onChange={(e) => setSelectedAddEditStay( prevStay => ({...prevStay, stay_pets: Number(e.target.value)}))} type="number" value={selectedAddEditStay.stay_pets!} placeholder="0" /></InputView>
                </div>
                <div className="input-wrapper" style={{marginTop: '15px' }}>
                <InputView title={priceInputTitle}>
                    <div className="default-icon">euro</div>
                    <input style={{textAlign: 'center'}} onChange={ handleOnChangePrice } type="number" value={calculatedPriceObj.stayPrice} placeholder="0" />
                    { (selectedAddEditStay.stay_special_price !== 0 || calculatedPriceObj.priceListArrString !== '[]') &&
                    <div title="Preis zurücksetzen" onClick={() => setSelectedAddEditStay( prevStay => ({...prevStay, stay_special_price: 0, stay_priceList: '[]'}) ) } className="clickable-icon">restart_alt</div>
                    }
                    <div onClick={handleOpenPriceListView} className="clickable-icon">list</div>
                </InputView>
                </div>
                { selectedAddEditStay.stay_id === -1 &&
                <div className="input-wrapper" style={{marginTop: '7px' }}>
                    <InputView title={`${ getDateObj( selectedAddEditStay.stay_checkIn!, -1 ).dateStringDE } ${preCheckInIsClickableRef.current ? 'blockieren?' : 'bereits belegt.'}`}><div onClick={() => preCheckInIsClickableRef.current && setPreCheckInIsBlocked(prevVal => !prevVal) } className={`clickable-icon flex center h100prc${ preCheckInIsClickableRef.current ? '' : ' not-allowed'}`} style={{fontSize: '1.5em'}}>{preCheckInIsBlocked ? 'check_box' : 'check_box_outline_blank'}</div></InputView>
                    <InputView title={`${ getDateObj( selectedAddEditStay.stay_checkOut!, 1 ).dateStringDE } ${postCheckInIsClickableRef.current ? 'blockieren?' : 'bereits belegt.'}`}><div onClick={() => postCheckInIsClickableRef.current && setPostCheckInIsBlocked(prevVal => !prevVal) } className={`clickable-icon flex center h100prc${ postCheckInIsClickableRef.current ? '' : ' not-allowed'}`} style={{fontSize: '1.5em'}}>{postCheckInIsBlocked ? 'check_box' : 'check_box_outline_blank'}</div></InputView>
                </div>
                }
            </div>

        </div>
        { isVisibleGuestAddEditView &&
        createPortal( <ModalView modalViewId="guestAddEditView" style={{width: '40%', maxHeight: 'none', }} handleClose={()=>{}}>
                        <GuestAddEditView guest={emptyGuest} handleCancel={()=> setIsVisibleGuestAddEditView(false)} handleSave={handleNewGuest} />
                    </ModalView>, document.body)
        }
        { isVisiblePriceListView && createPortal( <StayPriceListView priceListArr={ JSON.parse(calculatedPriceObj.priceListArrString) } property={selectedAddEditProperty} handleClose={() => setIsVisiblePriceListView(false)} handleNewPriceListArr={handleNewPriceListArr} />, document.body) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default memo(StayAddEditView);