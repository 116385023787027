import { useContext } from "react";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import { amountOfPaymentListArr } from "../../methods/price.calculation.methods";
import { getDateObj } from "../../methods/standard.methods";
import { PaymentMethod, PaymentObj } from "../../methods/types";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import MenuButton from "../../generalComps/MenuButton";

type Props = {
    handleClose: () => void;
}

const PaymentMethodSelectionView = ( { handleClose }:Props ) => {

    const { setStay, paymentDataObj, setPaymentDataObj, invoicePriceObj, pmSettingsObj, paymentArr } = useContext(StayDetailViewContext);
    const { reloadStayData } = useContext(OccupancyContext);

    const arr = [PaymentMethod.Cash, PaymentMethod.Transfer, PaymentMethod.Paypal, PaymentMethod.CreditCard, PaymentMethod.Check];

    const currency = pmSettingsObj ? pmSettingsObj!.property_currency : '';

    let title = `Zahlungsart - ${paymentDataObj.viewTitle.title}`;

    const helpBoxText = `Wähle hier die Zahlungsart, mit welcher ${paymentDataObj.viewTitle.artikel} ${paymentDataObj.viewTitle.title} von ${paymentDataObj.amount.toFixed(2)} ${currency} beglichen wurde.`;

    const handleNewPaymentMethod = (paymentMethod: PaymentMethod) => {

        setPaymentDataObj( prevObj => ({...prevObj, paymentMethod: paymentMethod}));

        const newPaymentObj: PaymentObj = {date: getDateObj( new Date(), 0).dateStringUS, price: paymentDataObj.amount, paymentMethod: paymentMethod, art: paymentDataObj.viewTitle.title, currency: currency }

        const newPaymentArr = [...paymentArr, newPaymentObj];

        let newStatus = paymentDataObj.stayStatus;

        if ( ( amountOfPaymentListArr(paymentArr) + paymentDataObj.amount ) >= invoicePriceObj.fullAmount ) {
            
            newStatus = 4;

        } else if ( invoicePriceObj.depositAmount > 0 && ( amountOfPaymentListArr(paymentArr) + paymentDataObj.amount ) >= invoicePriceObj.depositAmount && ( amountOfPaymentListArr(paymentArr) + paymentDataObj.amount ) < invoicePriceObj.fullAmount ) {

            newStatus = 3;

        } else {

            newStatus = 2;

        }

        setStay( prevStay => ({...prevStay, stay_status: newStatus, stay_paymentList: JSON.stringify(newPaymentArr) }));

        setTimeout(() => {
            reloadStayData();
        }, 1000);

        handleClose();

    }

    return ( 

        <>
        <div className="flex center-y w100prc">
            <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>cancel</div>
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.2rem', paddingRight: '40px', }}>{title}</div>
        </div>
        <div className="shadowBox center"style={{margin: '20px 0'}}>{helpBoxText}</div>

        { arr.map( method => {
            return <MenuButton style={{ marginBottom: '5px'}} key={method} onClick={() => handleNewPaymentMethod(method) } rightIcon="chevron_right" buttonTitle={method} />
        })}
        </>

     );
}
 
export default PaymentMethodSelectionView;