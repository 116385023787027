import { useContext } from "react";
import MultiCalendarDates from "./MultiCalendarDates";
import MultiCalendarPropertys from "./MultiCalendarPropertys";
import MultiCalendarStayDetailsView from "./MultiCalendarStayDetailsView";
import { OccupancyModulContext } from "../../../contexts/OccupancyModulContext";

const MultiCalendar = () => {

    const { isVisibleStayDetailsView } = useContext(OccupancyModulContext);

    return ( 

            <>
            <div className="h100prc w100prc" style={{ display: 'block', marginTop: '10px', overflow: 'visible' }}>
                <MultiCalendarPropertys />
                <MultiCalendarDates />
            </div>
            { isVisibleStayDetailsView && <MultiCalendarStayDetailsView /> }
            </>

     );
}
 
export default MultiCalendar;