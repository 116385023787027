import { useMutation } from "@tanstack/react-query";
import { useId, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import { postUpdateUserAddress } from "../methods/http.user.methods";
import { AlertObj, UserAddress } from "../methods/types";

type Props = {
    address: UserAddress;
    reloadFunc: () => void;
    handleClose: () => void;
}

const UserNameAddEditView = ( { address, reloadFunc, handleClose }:Props ) => {

    const inputStreetRef = useRef<HTMLInputElement | null>(null);
    const inputZipRef = useRef<HTMLInputElement | null>(null);
    const inputCityRef = useRef<HTMLInputElement | null>(null);
    const inputCountryRef = useRef<HTMLInputElement | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const handleSave = () => {

        if ( inputStreetRef.current!.value.length === 0 || inputZipRef.current!.value.length === 0 || inputCityRef.current!.value.length === 0 || inputCountryRef.current!.value.length === 0 ) {
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte fülle alle Felder aus.', standardAlertButton ) );
        } else {
            postUpdateUserAddressMutation();
        }

    }

    const { mutate: postUpdateUserAddressMutation } = useMutation({
        mutationFn: () => postUpdateUserAddress( JSON.stringify( { street: inputStreetRef.current!.value, zip: inputZipRef.current!.value, city: inputCityRef.current!.value, country: inputCountryRef.current!.value } ) ),
        onSuccess: (data) => {

            if (data.success) {

                reloadFunc();

                handleClose();

            } else {
                setAlertObj( new AlertObj( true, data.response_title, data.response_text, standardAlertButton ) );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 
        <>
        <ModalView modalViewId="userAddressAddEditView" style={{ width: '700px', overflow: 'visible' }} handleClose={ handleClose }>
            <div className="flex center-y w100prc">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Rechnungsadresse</div>
                <div onClick={ handleSave } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <InputView title="Straße & Hausnummer" style={{ marginTop: '20px' }}><input ref={inputStreetRef} type="text" defaultValue={ address.street } placeholder="z. B. Musterweg 7" /></InputView>
            <div className="flex center-y w100prc" style={{ marginTop: '10px', gap: '20px', padding: '3px 0' }}>
                <InputView title="Postleitzahl" style={{ width: '30%' }}><input ref={inputZipRef} type="text" defaultValue={ address.zip } placeholder="z. B. 88630" /></InputView>
                <InputView title="Wohnort" style={{ width: '70%' }}><input ref={inputCityRef} type="text" defaultValue={ address.city } placeholder="z. B. Musterstadt" /></InputView>
            </div>
            <InputView title="Land" style={{ marginTop: '10px' }}><input ref={inputCountryRef} type="text" defaultValue={ address.country } placeholder="z. B. Deutschland" /></InputView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default UserNameAddEditView;