interface Props {
    selection: any,
    selectedItem: number,
    handleSelection: (index: number) => void
}

const SalutationSelect = ({ selection, selectedItem, handleSelection }:Props) => {

    // const handleSelection = (index:number) => {

    //     //setSelectedItem(index);

    // }

    return ( 

        <div className="salutation-wrapper" style={{ flexGrow: '0' }}>

            { selection.map( (val: string, index: number) => {
                return <div onClick={() => handleSelection(index)} className={"btn" + (selectedItem == index ? " selected" : "")} key={index}>{val}</div>
            } )
            }

        </div>

     );
}
 
export default SalutationSelect;