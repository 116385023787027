import { useContext, useId, useRef, useState } from "react";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import { AlertObj, Stay } from "../methods/types";
import ActionAlert from "../generalComps/ActionAlert";
import { useMutation } from "@tanstack/react-query";
import { postManuallyAddBlock } from "../methods/http.stays.methods";
import { OccupancyContext } from "../contexts/OccupancyContext";

type Props = {
    handleClose: () => void;
}

const BlockAddView = ( { handleClose }: Props ) => {

    const { reloadStayData, filteredPropertys, selectedDatesRow, selectedStartDate, selectedEndDate } = useContext(OccupancyContext);

    const inputTitleRef = useRef<HTMLInputElement | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const { mutate: postAddBlockMuation } = useMutation({
        mutationFn: ( stay: Stay ) => postManuallyAddBlock(stay),
        onSuccess: () => {

            reloadStayData();
            handleClose();

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
        },
    });

    const handleSave = () => {

        if (inputTitleRef.current!.value.length === 0) {

            setAlertObj( new AlertObj(true, 'Fehlende Bezeichnung', 'Du hast keine Bezeichnung für diese Blockierung vergeben.', standardAlertButton) );

        } else {

            const propertyId = filteredPropertys.length === 1 ? filteredPropertys[0].property_id : filteredPropertys[selectedDatesRow!].property_id;

            const newStay: Stay = {
                stay_id: -1,
                stay_block_title: inputTitleRef.current!.value,
                stay_property: propertyId,
                stay_checkIn: selectedStartDate!.dateStringUS,
                stay_checkOut: selectedEndDate!.dateStringUS,
                stay_status: 5,
                stay_adults: 0,
                stay_children: 0,
                stay_pets: 0,
                stay_group: '[]',
                stay_special_price: 0,
                stay_storno_val: 0,
            }

            postAddBlockMuation( newStay );

        }

    }

    const handleBlockCardClick = (title: string) => {

        inputTitleRef.current!.value = title;

    }

    return ( 

        <>
        <ModalView modalViewId="blockAddView" style={{ width: '350px', overflow: 'visible' }} handleClose={ handleClose }>
            <div className="flex center-y w100prc">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Blockierung</div>
                <div onClick={ handleSave } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <InputView title="Bezeichnung" style={{ marginTop: '20px' }}><input ref={inputTitleRef} type="text" placeholder="z. B. Reinigung" /></InputView>
            <div className="flex center wrap" style={{ gap: '10px', padding: '10px 0' }}>
                <div onClick={ () => handleBlockCardClick('Reinigung') } className="flex center block-title-card">Reinigung</div>
                <div onClick={ () => handleBlockCardClick('Eigennutzung') } className="flex center block-title-card">Eigennutzung</div>
                <div onClick={ () => handleBlockCardClick('Renovierung') } className="flex center block-title-card">Renovierung</div>
                <div onClick={ () => handleBlockCardClick('Familie') } className="flex center block-title-card">Familie</div>
            </div>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default BlockAddView;