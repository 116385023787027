import { ReactElement } from "react";
import { ViewComponent } from "../methods/types";

type Props = {
    viewComponent: ViewComponent;
    handleAddClick: () => void;
}

const BigAddIcon = ( { viewComponent, handleAddClick }:Props ):ReactElement => {

    return (

        <div onClick={ handleAddClick } className="flex center" style={{ flexDirection: 'column', marginTop: '20%', opacity: '0.5', cursor: 'pointer' }}>
            <div className="flex center w100prc color-verylightblack" style={{ fontSize: '4.0rem', padding: '10px' }}>{`${viewComponent} hinzufügen`}</div>
            <div className="flex center w100prc clickable-icon color-verylightblack" style={{ fontSize: '4.0rem' }}>add_circle</div>
        </div>

    );

}

export default BigAddIcon;