import * as React from "react";
import { useContext, useState } from "react";
import {StayDetailViewContext} from "../contexts/StayDetailViewContext";
import ModalView from "../generalComps/ModalView";
import { daysBetweenDates, getDateObj } from "../methods/standard.methods";
import { PriceListObj, ZlObj } from "../methods/types";

type Props = {
    handleClose: () => void;
}

const StayAdditionalServiceView = ( { handleClose }:Props ) => {

    const { stay, setStay, zlArr, priceListArr, pmSettingsObj } = useContext(StayDetailViewContext);

    const [tempPriceListArr, setTempPriceListArr] = useState(priceListArr);

    const nights = daysBetweenDates( getDateObj( stay.stay_checkIn, 0 ).date, getDateObj( stay.stay_checkOut, 0 ).date );

    const currency = pmSettingsObj!.property_currency;

    const handleCheckBoxOnClick = (e: React.SyntheticEvent, obj: ZlObj) => {

        if(obj.zl_art === 0 || obj.zl_art === 1) {
        
            const priceListObj = tempPriceListArr.find( pobj => Number(pobj.zlID) === obj.zl_id);

            if(!priceListObj) {
        
                let price = 0;
                let textString = '';
        
                let taxVal = 0;
                let taxValMultiplied = 0;

                if (obj.zl_tax) {

                    taxVal = ( obj.zl_tax_percent * obj.zl_price ) / (100 + obj.zl_tax_percent );

                }

                if (obj.zl_art == 0) {
        
                    price = obj.zl_price;
                    textString = obj.zl_name + ' (Einmalig ' + price.toFixed(2) + ' ' + currency + ')';
                    taxValMultiplied = taxVal;
        
                } else if (obj.zl_art == 1) {
        
                    price = obj.zl_price * nights;
                    textString = obj.zl_name + ' (' + nights + ' Nächte x ' + obj.zl_price.toFixed(2) + ' ' + currency + ')';
                    taxValMultiplied = taxVal * nights;
        
                } 
        
                const newPriceListObj: PriceListObj = {price: price, taxPercent: obj.zl_tax_percent, taxVal: taxValMultiplied, text: textString, amount: 0, zlID: obj.zl_id.toString(), art: 1};
                
                setTempPriceListArr( prevArr => ([...prevArr, newPriceListObj]));
                
            } else {
                
                const index = tempPriceListArr.findIndex(obj => obj === priceListObj);
                const arr = [...tempPriceListArr];
                
                arr.splice(index, 1);
                
                setTempPriceListArr( arr );

            }

        }

        e.stopPropagation();

    }

    const handleArrowOnClick = (e: React.SyntheticEvent, obj: ZlObj, val: number) => {

        let amount = 0;

        const arr = [...tempPriceListArr];

        const priceListCheckObj = arr.find( pobj => Number(pobj.zlID) === obj.zl_id && (pobj.amount! + val ) === 0 );

        if (!priceListCheckObj) {

            const priceListValObj = arr.find( pobj => Number(pobj.zlID) === obj.zl_id && pobj.amount! > 0 );

            amount = !priceListValObj?.amount ? 0 : priceListValObj.amount!;

            if (amount + val < 0) {
            
                return;
    
            } else {

                let price = 0;
                let textString = '';
                let sum = amount + val;
    
                let taxVal = 0;
                let taxValMultiplied = 0;
    
                if ( obj.zl_tax ) {
    
                    taxVal = ( obj.zl_tax_percent *obj.zl_price ) / (100 + obj.zl_tax_percent );
    
                }
    
                if (obj.zl_art === 2) {
    
                    price = obj.zl_price * sum;
                    textString = obj.zl_name + ' (' + sum + ' x ' + obj.zl_price.toFixed(2) + ' ' + currency + ')';
                    taxValMultiplied = taxVal * sum;
    
                } else if (obj.zl_art == 3) {
    
                    price = (obj.zl_price * sum) * nights;
                    textString = obj.zl_name + ' ((' + sum + ' x ' + obj.zl_price.toFixed(2) + ' ' + currency + ') x ' + nights + ' Nächte)';
                    taxValMultiplied = (taxVal * sum) * nights;
    
                }
                
                if (priceListValObj) {

                    priceListValObj.price = price;
                    priceListValObj.taxVal = taxValMultiplied;
                    priceListValObj.text = textString;
                    priceListValObj.amount = sum;

                } else {

                    const newPriceListObj: PriceListObj = {price: price, taxPercent: obj.zl_tax_percent, taxVal: taxValMultiplied, text: textString, amount: sum, zlID: obj.zl_id.toString(), art: 1};
                    arr.push( newPriceListObj );

                }

            }

        } else {

            const index = arr.findIndex(obj => obj === priceListCheckObj);
            arr.splice(index, 1);

        }

        setTempPriceListArr( arr );

        e.stopPropagation();

    } 

    const handleSave = (e: React.SyntheticEvent) => {

        setStay( prevStay => ({...prevStay, stay_priceList: JSON.stringify(tempPriceListArr) }));

        handleClose();

        e.stopPropagation();

    }

    return ( 
        <ModalView modalViewId="stayAdditionalServiceView" handleClose={handleClose} style={{width: '600px'}}>
            <div className="flex center-y w100prc">
                <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.2rem', }}>Zusatzleistungen</div>
                <div onClick={handleSave} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>check_circle</div>
            </div>
            <div className="shadowBox center"style={{margin: '20px 0'}}>Zum Aktivieren die gewünschte Zusatzleistung anklicken bzw. die Menge erhöhen (bei Zusatzleistung "pro Stück" bzw. "pro Nacht & Stück").</div>

            <div className="flex column w100prc">
            {
                zlArr.map( zlObj => {

                    let name;
                    let downOpa = 1.0;
                    let pointerEvents = '';

                    const foundZL = tempPriceListArr.find( obj => {
                        return obj.art === 1 && Number(obj.zlID) === zlObj.zl_id;
                    });

                    let checkBox = 'check_box_outline_blank';

                    if (foundZL) {
                        checkBox = 'check_box';
                    }

                    let calcPrice = 0;

                    if (zlObj.zl_art === 0) {

                        name = zlObj.zl_name + ' (Einmalig)';
                        calcPrice = zlObj.zl_price;

                    } else if (zlObj.zl_art === 1) {

                        name = zlObj.zl_name + ' (' + nights + ' Nächte x ' + zlObj.zl_price.toFixed(2) + ' ' + currency + ')';
                        calcPrice = zlObj.zl_price * nights;

                    } else if (zlObj.zl_art === 2) {
                        
                        if (!foundZL) {

                            name = zlObj.zl_name + ' (0 x ' + zlObj.zl_price.toFixed(2) + ' ' + currency + ')';
                            downOpa = 0.2;
                            pointerEvents = ' disable-pointer-events';

                        } else {
                            //name = zlObj.name + ' (' + foundZL.amount + ' x ' + parseFloat(zlObj.price).toFixed(2) + ' EUR)';
                            name = foundZL.text;
                            calcPrice = foundZL.price;
                        }

                    } else if (zlObj.zl_art === 3) {
                        
                        if (!foundZL) {
                            name = zlObj.zl_name + ' ((0 x ' + zlObj.zl_price.toFixed(2) + ' ' + currency + ') x ' + nights + ' Nächte)';
                            downOpa = 0.2;
                            pointerEvents = ' disable-pointer-events';
                        } else {
                            
                            name = foundZL.text;
                            calcPrice = foundZL.price;
                        }
                    }

                    return(
                        <div key={zlObj.zl_id} className="flex center w100prc" style={{padding: '2px 5px'}}>
                            <div onClick={(e) => handleCheckBoxOnClick(e, zlObj) } className={ zlObj.zl_art > 1 ? 'default-icon' : 'clickable-icon' } style={{width: '40px', fontSize: '1.4em', }}>{checkBox}</div>
                            <div className="flex center-y" style={{width: '60%'}}>{name}</div>
                            <div className="flex center" style={{width: '80px', visibility: zlObj.zl_art > 1 ? 'visible' : 'hidden', }}>
                                <div onClick={(e) => handleArrowOnClick(e, zlObj, -1) } className={`clickable-icon${pointerEvents}`} style={{fontSize: '1.4em', opacity: downOpa, }} >keyboard_arrow_down</div>
                                <div onClick={(e) => handleArrowOnClick(e, zlObj, 1) } className="clickable-icon" style={{fontSize: '1.4em',}}>keyboard_arrow_up</div>
                            </div>
                            <div className="flex center-y color-lightblack" style={{width: '40%', justifyContent: 'right'}}>{`${calcPrice.toFixed(2)} ${currency}`}</div>
                        </div>
                    )

                })
            }
            </div>
        </ModalView>
     );
}
 
export default StayAdditionalServiceView;