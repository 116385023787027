import { DefaultFetchResult, KtObj } from "./types";

export const fetchSpaTaxes = async () => {

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=kt&do=select_all`,
      {
      method: "GET", 
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const fetchSpaTaxById = async (ktIds: string) => {

    const data = new URLSearchParams();
  
    data.append('kt_ids', ktIds);
  
    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=kt&do=select_by_ids',
        {
        method: 'POST', 
        body: data,
       'credentials': 'include',
        }
      )
  
    const content: DefaultFetchResult = await result.json();
  
    return content;
  
}

export const postAddSpaTax = async (spaTax: KtObj) => {

  const data = new URLSearchParams();
 
  data.append('kt_name', spaTax.kt_name);
  data.append('kt_price', spaTax.kt_price.toString());
  data.append('kt_art', spaTax.kt_art!.toString());
  data.append('kt_start', spaTax.kt_start!);
  data.append('kt_end', spaTax.kt_end!);
  data.append('kt_tax', spaTax.kt_tax.toString());
  data.append('kt_tax_percent', spaTax.kt_tax_percent.toString());
  
  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=kt&do=insert`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateSpaTax = async (spaTax: KtObj) => {

  const data = new URLSearchParams();
  
  data.append('kt_id', spaTax.kt_id.toString());
  data.append('kt_name', spaTax.kt_name);
  data.append('kt_price', spaTax.kt_price.toString());
  data.append('kt_art', spaTax.kt_art!.toString());
  data.append('kt_start', spaTax.kt_start!);
  data.append('kt_end', spaTax.kt_end!);
  data.append('kt_tax', spaTax.kt_tax.toString());
  data.append('kt_tax_percent', spaTax.kt_tax_percent.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=kt&do=update`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteSpaTax = async (spaTaxId: number) => {

  const data = new URLSearchParams();

  data.append('kt_id', spaTaxId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=kt&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

