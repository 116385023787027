import { ReactElement, useContext, useState } from "react";
import { Landlord, ViewState } from "../../methods/types";
import ImageView from "../../generalComps/ImageView";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";

type Props = {
    landlord: Landlord;
    handleOnClick: (state: ViewState) => void;
}

const LandlordCard = ( { landlord, handleOnClick }: Props ) => {

    const { selectedProperty } = useContext(PropertysViewContext);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const imageUrl = landlord.user_folder === undefined ? '' : `https://eazyac-dev.de/user_data/${landlord.user_folder!}/vermieter_images/${landlord.vermieter_id!}.png?no-cache`;

    let fullName = '';

    if (landlord.vermieter_title == 'Familie') {
        fullName = `${landlord.vermieter_title} ${landlord.vermieter_lastname}`;
    } else {
        fullName = `${landlord.vermieter_title} ${landlord.vermieter_firstname} ${landlord.vermieter_lastname}`;
    }

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(

            <>
            <div className="flex w100prc" style={{marginBottom: '5px' }}>{ fullName }</div>
            <div className="flex center-y w100prc h100prc">
                <div className="flex center h100prc" style={{width: '50px', marginRight: '10px'}}><ImageView src={imageUrl} width={50} height={50} alt="Vermieter" /></div>
                <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 0px', width: '40%'}}>
                    <div className="flex center-y">{ landlord.vermieter_street }</div>
                    <div className="flex center-y">{ landlord.vermieter_zip } { landlord.vermieter_city }</div>
                    <div className="flex center-y color-lightblack">{ landlord.vermieter_country }</div>
                </div>
                { selectedProperty.vermieter_id === landlord.vermieter_id && <div title="Dieser Immobilie zugewiesen" className="default-icon" style={{ position: 'absolute', right: '20px', fontSize: '2.0em' }}>check_circle</div> }
            </div>
            </>
            
        );
    }

    return ( 

        <div onClick={ () => handleOnClick(ViewState.Details) } className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: isVisibleMenu ? '20px' : '10px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>

     );
}
 
export default LandlordCard;