import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Gaestebereich from './Gaestebereich';
import Webseite from './Webseite';
import LoginInput from './loginComps/Login';
import Logout from './loginComps/Logout';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from './ErrorPage';
import RegisterSuccess from './loginComps/RegisterSuccess';
import Verification from './loginComps/Verification';
import Dashboard from './dashboardComps/Dashboard';
import Messages from './messageComps/Messages';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Statistics from './statisticsComps/Statistics';
import Guests from './guestsComps/Guests';
import Stays from './stayComps/Stays';
import Propertys from './propertyComps/Propertys';
import MessageDetailViewContextProvider from "./contexts/MessageDetailViewContext";
import PropertysViewContextProvider from './contexts/PropertysMainContext';
import StayDetailViewContextProvider from "./contexts/StayDetailViewContext";
import OfferView from './guestsarea/offerComps/OfferView';
import OccupancyModul from './guestsarea/occupancyModulComps/OccupancyModul';
import Occupancy from './occupancyComps/Occupancy';
import OccupancyContextProvider from './contexts/OccupancyContext';
import OccupancyModulContextProvider from './contexts/OccupancyModulContext';
import PrivacyPolicy from './website/privacyComps/Privacy';
import LegalNotice from './website/legalComps/LegalNotice';
import GeneralTerms from './website/generalTermsComps/GeneralTerms';
import Start from './website/startComps/Start';
import Support from './website/supportComps/Support';
import Pricing from './website/pricingComps/Pricing';

const queryClient = new QueryClient()

const router = createBrowserRouter([
  {
    path: "/",
    element: <Webseite />,
      children: [
        {
          path: "start",
          element: <Start />,
        },
        {
          path: "datenschutz",
          element: <PrivacyPolicy />,
        },
        {
          path: "impressum",
          element: <LegalNotice />,
        },
        {
          path: "agb",
          element: <GeneralTerms />,
        },
        {
          path: "preise",
          element: <Pricing />,
        },
        {
          path: "kontakt",
          element: <Support />,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ]
  },
  {
    path: "/eazyac",
    element: <App />,
      children: [
        {
          path: "login",
          element: <LoginInput />,
        },
        {
          path: "registrierung",
          element: <RegisterSuccess />,
        },
        {
          path: "verifizierung/:token",
          element: <Verification />,
        },
        {
          path: "dashboard",
          element: <Dashboard />,
        },
        {
          path: "nachrichten",
          element: <MessageDetailViewContextProvider><Messages /></MessageDetailViewContextProvider>,
        },
        {
          path: "statistik",
          element: <Statistics />,
        },
        {
          path: "belegungsplan",
          element: <StayDetailViewContextProvider><OccupancyContextProvider><Occupancy /></OccupancyContextProvider></StayDetailViewContextProvider>,
        },
        {
          path: "aufenthalte/",
          element: <StayDetailViewContextProvider><Stays /></StayDetailViewContextProvider>,
          children: [
            {
              path: ":guest",
              element: <StayDetailViewContextProvider><Stays /></StayDetailViewContextProvider>,
            },
          ]
        },
        {
          path: "gaeste",
          element: <Guests />,
        },
        {
          path: "immobilien",
          element: <PropertysViewContextProvider><Propertys /></PropertysViewContextProvider>,
        },
        {
          path: "logout",
          element: <Logout />,
        },
        {
          path: "*",
          element: <ErrorPage />,
        },
      ]
  },
  {
    path: "/modul",
    element: <Gaestebereich />,
    children: [
      {
        path: "angebot/:token",
        element: <OfferView />,
      },
      {
        path: "belegungsplan/:token",
        element: <OccupancyModulContextProvider><OccupancyModul /></OccupancyModulContextProvider>,
      },
    ]
  },
  {
    path: "*",
    element: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

