import { ReactNode, useContext, useEffect } from 'react';
import { MessageDetailViewContext } from '../contexts/MessageDetailViewContext';
import '../css/messages.css'

type Props = {
    children: ReactNode;
    style?: React.CSSProperties | undefined;
}

const MessageBackview = ( { style, children }: Props ) => {

    const { setIsVisibleMessageBackview } = useContext(MessageDetailViewContext);

    useEffect(() => {

        setTimeout(() => {
            
            document.getElementById('messageBackview')?.classList.add('opened');

        }, 200);

    }, []);

    return ( 

        <div id='messageBackview' className="shadowBox message-backview" style={style}>
            <div className="flex center-y w100prc">
                <div onClick={ () => setIsVisibleMessageBackview(false) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
            </div>
           {children}
        </div>

     );
}
 
export default MessageBackview;