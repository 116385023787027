import { useContext } from "react";
import ModalView from "../generalComps/ModalView";
import { PaypalInvoiceViewType, SettingsViewContext } from "../contexts/SettingsContext";
import PaypalInvoiceView from "./PaypalInvoiceView";
import PaypalInvoiceOverView from "./PaypalInvoiceOverView";

type Props = {
    handleClose: () => void;
}

const PaypalInvoiceWrapper = ( { handleClose }:Props ) => {

    const { invoiceViewType } = useContext(SettingsViewContext);

    return ( 
        <ModalView modalViewId="paypalInvoiceWrapper" style={{ width: '700px', overflow: 'visible' }} handleClose={ handleClose }>
            { invoiceViewType === PaypalInvoiceViewType.InvoiceInputView ? <PaypalInvoiceView handleClose={ handleClose } /> : <PaypalInvoiceOverView /> }
        </ModalView>
     );
}
 
export default PaypalInvoiceWrapper;