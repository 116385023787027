import { useState } from "react";
import { ConversationObj } from "../methods/types";
import MailPreviewCard from "./MailPreviewCard";

type Props = {
    sectionName: string;
    sectionArr: Array<ConversationObj>;
    index: number;
}

const SectionCard = ( { sectionName, sectionArr, index }:Props ) => {

    const [isVisibleMails, setIsVisibleMails] = useState( index > 3 ? false : true );

    return ( 

        <div className="w100prc" style={{ padding: '2px' }}>
            <div className="shadowBox center-y w100prc" style={{padding: '5px'}}>
                <div className="center-y w100prc truncate-text">
                    { sectionName.substring(sectionName.indexOf('§')+1, sectionName.length) }
                    { !isVisibleMails && <span><span className="default-icon" style={{ padding: '0 5px 0 10px', fontSize: '0.8em'}}>question_answer</span>{ sectionArr.length }</span> }
                </div>
                <div onClick={ () => setIsVisibleMails( prev => !prev ) } className="clickable-icon center" style={{ width: '30px' }}>{ !isVisibleMails ? 'unfold_more' : 'unfold_less' }</div>
            </div>
            <div style={{ display: isVisibleMails ? 'block' : 'none' }}>
            {
                sectionArr.map( (obj) => {

                    return(
                        <MailPreviewCard key={obj.korres_id} obj={obj} />
                    )

                })
            }
            </div>
        </div>

     );
}
 
export default SectionCard;