import { memo } from "react";
import ModalView from "../generalComps/ModalView";
import MailCreationView from "./MailCreationView";

type Props = {

    style?: React.CSSProperties | undefined;
    handleClose: () => void;

}

const MailCreationBackView = ( { style, handleClose }: Props ) => {

    return ( 

        <ModalView modalViewId="mailCreationView" style={style} handleClose={ handleClose }>
            <MailCreationView handleClose={ handleClose } />
        </ModalView>

     );
}
 
export default memo(MailCreationBackView);