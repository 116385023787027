import { useId, useRef, useState } from "react";
import ModalView from "../../generalComps/ModalView";
import TextareaView from "../../generalComps/TextareaView";
import { AlertObj, OfferObject, OfferSendObj } from "../../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import { useMutation } from "@tanstack/react-query";
import { addOffer } from "../../methods/http.offer.methods";

type Props = {
    offerData: OfferObject;
    handleCompletion: () => void;
    handleClose: () => void;
    style?: React.CSSProperties | undefined;
}

const OfferCancelView = ( { offerData, style, handleCompletion, handleClose }: Props ) => {

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const { mutate: fetchOfferMutation } = useMutation({
        mutationFn: (newOfferData: OfferSendObj) => addOffer(newOfferData),
        onSuccess: () => {

            handleCompletion();
            handleClose();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSave = () => {

        let korres_from = 'Gast';

        if (offerData.guest_title === 'Herr' || offerData.guest_title === 'Frau') {

            korres_from = offerData.guest_firstname + ' ' + offerData.guest_lastname;

        } else if (offerData.guest_title === 'Familie') {

            korres_from = 'Familie ' + offerData.guest_lastname;

        } else if (offerData.guest_title === 'Firma') {

            korres_from = offerData.guest_company;

        }

        let msg = 'Dein Angebot wurde abgelehnt.';

        if (textareaRef.current!.value.length > 0) {

            msg += ' Folgende Mitteilung wurde hinterlassen: ';
            msg += textareaRef.current!.value;

        }

        const newOfferData: OfferSendObj = { korres_id: offerData.korres_id,
                                             korres_art: 10,
                                             korres_guest_data: '',
                                             korres_stay: offerData.korres_stay,
                                             korres_subject: 'Angebot abgelehnt',
                                             korres_body: msg,
                                             korres_from: korres_from,
                                             user_id: offerData.user_id };

        fetchOfferMutation(newOfferData);

    }

    return ( 

        <>
        <ModalView modalViewId="offerCancelView"  style={style} handleClose={handleClose}>
            <div className="flex center-y" style={{ width: "100%" }}>
                <div onClick={handleClose} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Angebot ablehnen</div>
                <div onClick={handleSave} className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>check_circle</div>
            </div>
            <div className="shadowBox" style={{ marginTop: '10px' }}>
                Schade, dass Ihnen das Angebot nicht zusagt. Gerne können Sie uns bei Bedarf noch eine Mitteilung hinterlassen. Durch Senden wird das Angebot abgelehnt und der Vorgang geschlossen.
            </div>
            <TextareaView style={{ height: '170px', marginTop: '10px' }} title='Mitteilung'>
                <textarea ref={textareaRef} placeholder='Ihre Mitteilung (optional)'/>
            </TextareaView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default OfferCancelView;