import * as React from "react";
import { ReactElement } from "react";

type Props = {
    title: string;
    selectString: string;
    style?: React.CSSProperties | undefined;
    obj?: any | undefined;
    popUpView?: ReactElement | undefined;
    isVisiblePopUp: boolean;
    content?: ReactElement | null;
    addIcon?: {iconName: string, handler: (e: React.SyntheticEvent) => void } | undefined;
    onClick: (e: React.SyntheticEvent) => void;
}

const BigCustomSelect = ( { title, selectString, popUpView, isVisiblePopUp, content, onClick, addIcon }: Props ) => {
    
    return ( 

        <div onClick={onClick} className="input-template-style cursor-pointer" style={{overflow: 'visible'}}>
            {addIcon &&  <div onClick={addIcon.handler} className="clickable-icon" style={{position: 'absolute', right: '0', top: '0', padding: '5px'}}>{addIcon.iconName}</div>}
            <div className="flex w100prc" style={{ height: '20px', overflow: 'visible', }}>
                <div id="title">{ title }</div>
            </div>
            <div className = "flex center w100prc color-black">
                { !content ? <div><span className="default-icon">touch_app</span>{selectString}</div> : content }
            </div>
            { (popUpView && isVisiblePopUp) && popUpView }
        </div>

     );
}
 
export default BigCustomSelect;