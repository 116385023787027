import { useMutation } from "@tanstack/react-query";
import { useId, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import { postUpdateUserMobile } from "../methods/http.user.methods";
import { AlertObj } from "../methods/types";

type Props = {
    mobile: string;
    reloadFunc: () => void;
    handleClose: () => void;
}

const UserMobileAddEditView = ( { mobile, reloadFunc, handleClose }:Props ) => {

    const inputMobileRef = useRef<HTMLInputElement | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const { mutate: postUpdateUserMobileMutation } = useMutation({
        mutationFn: () => postUpdateUserMobile(inputMobileRef.current!.value),
        onSuccess: (data) => {

            if (data.success) {

                reloadFunc();

                handleClose();

            } else {
                setAlertObj( new AlertObj( true, data.response_title, data.response_text, standardAlertButton ) );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 
        <>
        <ModalView modalViewId="userMobileAddEditView" style={{ width: '700px', overflow: 'visible' }} handleClose={ handleClose }>
            <div className="flex center-y w100prc">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Mobiltelefon</div>
                <div onClick={ () => postUpdateUserMobileMutation() } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <InputView title="Mobiltelefon" style={{ marginTop: '20px' }}><input ref={inputMobileRef} type="text" defaultValue={ mobile } placeholder="z. B. 0177 12345678" /></InputView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default UserMobileAddEditView;