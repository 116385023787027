import { useContext, useEffect, useId, useState } from "react";
import ModalView from "../../generalComps/ModalView";
import { AlertObj, InfoWidgetObj, Landlord, ViewState } from "../../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import { useMutation } from "@tanstack/react-query";
import { fetchLandlord, fetchLandlords, postDeleteLandlord } from "../../methods/http.landlord.methods";
import LandlordCard from "./LandlordCard";
import { postUpdatePropertySingleValue } from "../../methods/http.property.methods";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import LandlordAddEditView from "./LandlordAddEditView";
import { emptyLandlord } from "../../methods/constants";
import InfoWidget from "../../generalComps/InfoWidget";

type Props = {
    handleClose: () => void;
}

const LandlordListView = ( { handleClose }: Props ) => {

    const { selectedProperty, reloadProperty, setSelectedLandlord, selectedLandlord } = useContext( PropertysViewContext );

    const [landlords, setLandlords] = useState<Array<Landlord>>( [] );

    const [isVisibleLandlordAddEditView, setIsVisibleLandlordAddEditView] = useState(false);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const { mutate: fetchLandlordsMutation } = useMutation({
        mutationFn: () => fetchLandlords(),
        onSuccess: (data) => {

            if (data.success) {
                setLandlords( data.obj );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: fetchLandlordByIdMutation } = useMutation({
        mutationFn: (landlordId: number) => fetchLandlord(landlordId),
        onSuccess: (data) => {

            setSelectedLandlord( data.obj );

            setIsVisibleLandlordAddEditView(true);

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: (newLandlordId: number) => postUpdatePropertySingleValue(selectedProperty.property_id, 'property_vermieter', newLandlordId.toString()),
        onSuccess: () => {

            reloadProperty();

            handleClose();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => { fetchLandlordsMutation(); }, []);

    const handleLandlordCardClick = (state: ViewState, landlord: Landlord) => {

        if (state === ViewState.Details) {

            postUpdatePropertySingleMutation(landlord.vermieter_id);

        } else if (state === ViewState.Edit) {

            fetchLandlordByIdMutation(landlord.vermieter_id);

        } else if (state === ViewState.Delete) {

            let fullName = '';

            if (landlord.vermieter_title === 'Familie') {
                fullName = `${landlord.vermieter_title} ${landlord.vermieter_lastname}`;
            } else {
                fullName = `${landlord.vermieter_title} ${landlord.vermieter_firstname} ${landlord.vermieter_lastname}`;
            }

            const alertButtons = [ {title: 'Löschen', handler: () => postDeleteLandlordMutation(landlord.vermieter_id) }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Vermieter löschen?', `Soll '${fullName}' wirklich gelöscht werden?`, alertButtons ) );

        }

    }

    const handleSaveLandlord = (type: string, newLandlordId?: number | undefined) => {

        fetchLandlordsMutation();

        setIsVisibleLandlordAddEditView( false );
        setSelectedLandlord( emptyLandlord );

        setInfoWidgetObj( new InfoWidgetObj(true, `Vermieter erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

        if (type === 'new' && newLandlordId !== undefined && newLandlordId !== null ) {

            const alertButtons = [ {title: 'Verknüpfen', handler: () => postUpdatePropertySingleMutation(newLandlordId) }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Vermieter verknüpfen?', `Den soeben angelegten Vermieter mit dieser Immobilie verknüpfen?`, alertButtons ) );

        }

    }

    const { mutate: postDeleteLandlordMutation } = useMutation({
        mutationFn: (landlordId: number) => postDeleteLandlord(landlordId),
        onSuccess: () => {

            fetchLandlordsMutation();
            reloadProperty();

            setAlertObj( new AlertObj() );

            setInfoWidgetObj( new InfoWidgetObj( true, 'Vermieter wurde gelöscht.' ) );

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    return ( 

        <>
        <ModalView modalViewId="landlordListView" style={{ width: '500px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">Vermieter</div>
                <div onClick={ () => { setSelectedLandlord( emptyLandlord ); setIsVisibleLandlordAddEditView(true); } } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
            </div>
            <div className="flex center column w100prc standard-fontsize" style={{ marginTop: '20px', padding: '3px' }}>

                {
                    landlords.length === 0 ?
                    <div className="shadowBox center w100prc color-lightblack">Keine Vermieter vorhanden</div>
                    :
                    landlords.map( landlord => {

                        return(

                            <LandlordCard key={landlord.vermieter_id} handleOnClick={ (state) => handleLandlordCardClick(state, landlord) } landlord={landlord} />

                        )

                    })
                }

            </div>
        </ModalView>
        { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body ) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        { isVisibleLandlordAddEditView && createPortal( <LandlordAddEditView handleSave={ handleSaveLandlord } handleClose={ () => setIsVisibleLandlordAddEditView(false) } />, document.body ) }
        </>
     );
}
 
export default LandlordListView;