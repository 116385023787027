import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useId, useRef, useState } from "react";
import { SettingsViewContext } from "../contexts/SettingsContext";
import SalutationSelect from "../generalComps/SalutationSelect";
import { fetchTemplates, postDeleteTemplate } from "../methods/http.templates.methods";
import { AlertObj, TemplatesObj, ViewState } from "../methods/types";
import { createPortal } from "react-dom";
import ConversationTemplatesAddEditView from "./ConversationTemplatesAddEditView";
import ConversationTemplateCard from "./ConversationTemplateCard";
import ActionAlert from "../generalComps/ActionAlert";

const ConversationTemplatesView = () => {

    const { setSettingsViewType, lastSettingsViewTypeRef } = useContext(SettingsViewContext);

    const [selectedConversationType, setSelectedConversationType] = useState(0);

    const [templates, setTemplates] = useState<Array<TemplatesObj>>( [] );
    const [isVisibleConversationTemplatesAddEditView, setIsVisibleConversationTemplatesAddEditView] = useState(false);

    const selectedTemplateRef = useRef<TemplatesObj | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );

    const actionAlertId = useId();

    const { mutate: fetchAllTemplatesMutation } = useMutation({
        mutationFn: () => fetchTemplates(),
        onSuccess: (data) => {

            setTemplates( data.obj );

        },
    })

    useEffect(() => fetchAllTemplatesMutation(), [] );

    const handleAddEditTemplate = (template: TemplatesObj | null) => {

        if (template) {

            selectedTemplateRef.current = template;

        } else {

            selectedTemplateRef.current = { template_id: -1,
                                            template_title: '',
                                            template_subject: '',
                                            template_message: '',
                                            template_language: 'German',
                                            template_language_code: 'de',
                                            template_isStandardFor: 101,
                                            template_mailArt: 100,
                                            template_type: selectedConversationType };

        }

        setIsVisibleConversationTemplatesAddEditView(true);

    }

    const { mutate: postDeleteTemplateMutation } = useMutation({
        mutationFn: (templateId: number) => postDeleteTemplate( templateId ),
        onSuccess: () => {

            setAlertObj( new AlertObj() );

            fetchAllTemplatesMutation();

        },
    })

    const handleSelectedTemplate = (template: TemplatesObj, state: ViewState) => {

        if (state === ViewState.Edit) {

            handleAddEditTemplate(template);

        } else if (state === ViewState.Delete) {

            const alertBtns = [{ title: 'Löschen', handler: () => postDeleteTemplateMutation(template.template_id) }, { title: 'Abbrechen', handler: () => setAlertObj( new AlertObj() ) }]

            setAlertObj( new AlertObj( true, 'Vorlage löschen?', `Möchtest du die Vorlage '${template.template_title}' wirklich löschen?`, alertBtns ) );

        }

    }

    return ( 

        <>
        <div className="navigation-view-bar">
            <div onClick={ () => setSettingsViewType( lastSettingsViewTypeRef.current ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
            <div className="main-title">Vorlagen</div>
            <div onClick={ () => handleAddEditTemplate(null) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>post_add</div>
        </div>
        <SalutationSelect selection={["Email-Vorlagen", "PDF-Vorlagen"]} selectedItem={selectedConversationType} handleSelection={(index) => setSelectedConversationType(index)} />
        {
            templates.length === 0 ?
            <div className="shadowBox center w100prc color-lightblack">Keine Vorlagen vorhanden</div> :
            templates.filter( template => template.template_type === selectedConversationType ).map( template => {

                return(
                    
                    <ConversationTemplateCard template={template} handleOnClick={ handleSelectedTemplate } />

                )

            })

        }
        { isVisibleConversationTemplatesAddEditView && createPortal( <ConversationTemplatesAddEditView givenTemplate={selectedTemplateRef.current!} reloadFunc={ fetchAllTemplatesMutation } handleClose={ () => setIsVisibleConversationTemplatesAddEditView(false) } />, document.body ) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default ConversationTemplatesView;