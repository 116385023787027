import { ReactElement, useContext, useEffect, useId, useState } from "react";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import { AlertObj, InfoWidgetObj, PaymentsViewType, PropertyListType, ViewState, ZlObj } from "../../../methods/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../../methods/http.property.methods";
import InfoWidget from "../../../generalComps/InfoWidget";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { emptyAddiService } from "../../../methods/constants";
import { fetchAddiServices, postDeleteAddiService } from "../../../methods/http.addiservice.methods";
import AddiServiceCard from "./AddiServiceCard";
import AddiServiceAddEditView from "./AddiServiceAddEditView";

type Props = {
    handleClose: () => void;
}

const AddiServiceListView = ( { handleClose }: Props ) => {

    const queryClient = useQueryClient();

    const { setPaymentsViewType, selectedProperty, setSelectedProperty, propertyListType, setSelectedAddiService } = useContext( PropertysViewContext );

    const [isVisibleAddiServiceAddEditView, setIsVisibleAddiServiceAddEditView] = useState(false);

    const [addiServices, setAddiServices] = useState<Array<ZlObj>>([]);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const { mutate: fetchAddiServicesMutation } = useMutation({
        mutationFn: () => fetchAddiServices(),
        onSuccess: (data) => {

            if (data.success) {
                setAddiServices( data.obj );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => fetchAddiServicesMutation(), [] );

    const NoData = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Zusatzleistungen vorhanden</div>;
    }

    const handleAddiServiceCardClick = (state: ViewState, addiService: ZlObj) => {

        if (state === ViewState.Details) {

            const propertyAddiServiceIds: Array<{zl_id: number}> = selectedProperty.property_zl ? JSON.parse( selectedProperty.property_zl ) : [];

            if ( propertyAddiServiceIds.find( asId => asId.zl_id === addiService.zl_id ) !== undefined ) {

                propertyAddiServiceIds.splice( propertyAddiServiceIds.findIndex( obj => obj.zl_id === addiService.zl_id ), 1 );

            } else {

                propertyAddiServiceIds.push( {zl_id: addiService.zl_id} );

            }

            postUpdatePropertySingleMutation( JSON.stringify( propertyAddiServiceIds ) );

        } else if (state === ViewState.Delete) {

            const alertButtons = [ {title: 'Löschen', handler: () => postDeleteAddiServiceMutation(addiService.zl_id) }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Zusatzleistung löschen?', `Soll die Zusatzleistung '${addiService.zl_name}' wirklich gelöscht werden?`, alertButtons ) );

        } else if (state === ViewState.Edit) {

            setSelectedAddiService( addiService );

            setIsVisibleAddiServiceAddEditView(true);

        }

    }

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: (addiServiceIdString: string) => postUpdatePropertySingleValue(selectedProperty.property_id, 'property_zl', addiServiceIdString),
        onSuccess: (_, variables) => {

            setSelectedProperty( prev => ( {...prev, property_zl: variables } ) );

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postDeleteAddiServiceMutation } = useMutation({
        mutationFn: (addiServiceId: number) => postDeleteAddiService(addiServiceId),
        onSuccess: () => {

            fetchAddiServicesMutation();

            setAlertObj( new AlertObj() );

            setInfoWidgetObj( new InfoWidgetObj( true, 'Zusatzleistung gelöscht.') )

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSaveAddiService = (type: string, newAddiServiceId?: number | undefined) => {

        fetchAddiServicesMutation();

        setIsVisibleAddiServiceAddEditView( false );
        setSelectedAddiService( emptyAddiService );

        setInfoWidgetObj( new InfoWidgetObj(true, `Zusatzleistung erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

        if (type === 'new' && newAddiServiceId !== undefined && newAddiServiceId !== null ) {

            const alertButtons = [ {title: 'Verknüpfen', handler: () => {

                const propertyAddiServiceIds: Array<{zl_id: number}> = selectedProperty.property_zl ? JSON.parse( selectedProperty.property_zl ) : [];
                propertyAddiServiceIds.push( {zl_id: newAddiServiceId} );
                postUpdatePropertySingleMutation( JSON.stringify( propertyAddiServiceIds ) );
                
                setAlertObj( new AlertObj() );

            } }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Zusatzleistung verknüpfen?', `Die soeben angelegte Zusatzleistung mit dieser Immobilie verknüpfen?`, alertButtons ) );

        }

    }

    return ( 

        <>
        <ModalView modalViewId="addiServiceListView" style={{ width: '650px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ () => setPaymentsViewType( PaymentsViewType.MainMenu ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
                <div className="main-title">Zusatzleistungen</div>
                <div onClick={ () => { setSelectedAddiService(emptyAddiService); setIsVisibleAddiServiceAddEditView(true); } } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
            </div>
            <div className="flex center w100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: '3px', marginTop: '10px' }}>
            { 

                addiServices.length === 0 ?

                <NoData />

                :

                addiServices.map( ( addiService ) => {

                    return <AddiServiceCard key={addiService.zl_id} addiService={addiService} handleOnClick={ (state) => handleAddiServiceCardClick(state, addiService) } />;
        
                }) 

            }
            </div>
        </ModalView>
        { isVisibleAddiServiceAddEditView && <AddiServiceAddEditView handleCancel={ () => setIsVisibleAddiServiceAddEditView(false) } handleSave={ handleSaveAddiService } /> }
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>
     );
}
 
export default AddiServiceListView;