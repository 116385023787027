import { useEffect } from 'react';
import '../css/guests.css'
import GuestAddEditView from './GuestAddEditView';
import { Guest } from '../methods/types';

type Props = {
    isVisibleAddEditView: boolean;
    guest: Guest;
    handleCancel: () => void;
    handleSave: (type: string) => void;
}

const GuestBackview = ( { isVisibleAddEditView, guest, handleCancel, handleSave }: Props ) => {

    useEffect(() => {

        setTimeout(() => {
            
            isVisibleAddEditView ? document.getElementById('guestBackview')?.classList.add('opened') : document.getElementById('guestBackview')?.classList.remove('opened');

        }, 200);

    }, [isVisibleAddEditView]);

    return ( 

        <div id='guestBackview' className="shadowBox guest-backview">
            <GuestAddEditView guest={guest} handleCancel={handleCancel} handleSave={handleSave}/>
        </div>

     );
}
 
export default GuestBackview;