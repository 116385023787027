import { CSSProperties, SyntheticEvent } from "react";
import '../css/menubutton.css';

type Props = {

    onClick?: (e: SyntheticEvent) => void | undefined;
    leftIcon?: string | undefined;
    rightIcon?: string | undefined;
    buttonTitle: string;
    style?: CSSProperties | undefined;
    buttonTitleSyle?: CSSProperties | undefined;

}

const MenuButton = ( { leftIcon, rightIcon, buttonTitle, onClick, style, buttonTitleSyle }:Props ) => {

    return ( 

        <div onClick={ onClick } className="flex center-y menu-btn" style={style}>
            { leftIcon !== undefined && <div className="default-icon flex center icon" style={{ width: '40px' }}>{ leftIcon }</div> }
            <div className="flex center-y w100prc" style={buttonTitleSyle}>{ buttonTitle }</div>
            { rightIcon !== undefined && <div className="default-icon flex center icon" style={{ width: '40px' }}>{ rightIcon }</div> }
        </div>

     );
}
 
export default MenuButton;