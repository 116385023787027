import ModalView from "../generalComps/ModalView";
import '../css/settings.css'
import { ReactElement, useContext, useId, useState } from "react";
import { SettingsViewContext } from "../contexts/SettingsContext";
import { AlertObj, SettingsViewType } from "../methods/types";
import ConversationTemplatesView from "./ConversationTemplatesView";
import UserView from "./UserView";
import SubscriptionView from "./SubscriptionView";
import { PayPalScriptProvider, ReactPayPalScriptOptions } from "@paypal/react-paypal-js";
import CalendarExport from "./CalendarExport";
import CalendarImport from "./calendarImportComps/CalendarImport";
import MenuButton from "../generalComps/MenuButton";
import CalendarShareList from "./calendarShareComps/CalendarShareList";
import { PropertysContext } from "../contexts/PropertysContext";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";

type Props = {
    handleClose: () => void;
}

const SettingsWrapper = ( { handleClose }: Props ) => {

    const { settingsViewType, setSettingsViewType } = useContext(SettingsViewContext);

    const { propertys } = useContext(PropertysContext);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const initialOptions: ReactPayPalScriptOptions = {
        clientId: "AfhuvtdCGIiKfO2SNOwy7kKGc0FIZIGWbENAsX1TOdY3-EDVJbqBOMIW9bIBegYLutKkcpvYuXnWhp5e",
        currency: "EUR",
        intent: "subscription",
        enableFunding: "venmo",
        vault: true,
        
    };

    //Debug
    // const initialOptions: ReactPayPalScriptOptions = {
    //     clientId: "AVwSRONNJGZYFT7xj7JxgKw6H-oXcvoWvOlJC5IKW-wYLTDmXmVGz00RzVMnVHJAFIBDr-PtbEPYWVmu",
    //     currency: "EUR",
    //     intent: "subscription",
    //     enableFunding: "venmo",
    //     vault: true,
    // };

    // const Content = (): ReactElement => {

    //     switch (settingsViewType) {

    //         case SettingsViewType.MainMenu:
                
    //             return <>
    //             <div className="flex center-y w100prc" style={{ marginBottom: '20px' }}>
    //                 <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
    //                 <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem', paddingRight: '40px'}}>Einstellungen</div>
    //             </div>
    //             <div onClick={ () => { setSettingsViewType( SettingsViewType.ConversationTemplates ) } } icon-name="post_add" className="navigation-cell">Nachrichtenvorlagen</div>
    //             <div onClick={ () => { setSettingsViewType( SettingsViewType.UserData ) } } icon-name="account_circle" className="navigation-cell">Benutzerdaten</div>
    //             <div onClick={ () => { setSettingsViewType( SettingsViewType.Subscriptions ) } } icon-name="shopping_cart" className="navigation-cell">Abonnements</div>
    //             <div onClick={ () => { setSettingsViewType( SettingsViewType.Help ) } } icon-name="contact_support" className="navigation-cell">Hilfe</div>
    //             <div icon-name="exit_to_app" className="navigation-cell">Abmelden</div>
    //             </>

    //         case SettingsViewType.ConversationTemplates:
    //             return <ConversationTemplatesView />

    //         case SettingsViewType.UserData:
    //             return <UserView />

    //         case SettingsViewType.Subscriptions:
    //             return <PayPalScriptProvider options={initialOptions} ><SubscriptionView /></PayPalScriptProvider>

    //         case SettingsViewType.Help:
    //             return <div>Hilfe</div>
        
    //         default:
    //             return <div>Error</div>
    //     }

    // }

    const checkProperty = ( type: SettingsViewType ) => {

        if ( propertys.array.length === 0 ) {

            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte lege zunächst eine Immobilie an, um diese Funktion nutzen zu können.', standardAlertButton ));

        } else {

            setSettingsViewType( type );

        }

    }

    return ( 
        <ModalView modalViewId="settingsView" style={{ width: '700px', }} handleClose={ handleClose }>
            { settingsViewType === SettingsViewType.MainMenu && 
                <>
                <div className="flex center-y w100prc" style={{ marginBottom: '20px' }}>
                    <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                    <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem', paddingRight: '40px'}}>Einstellungen</div>
                </div>
                <div className="flex column" style={{ gap: '5px', padding: '0 50px' }}>
                    <div className="flex center-y color-lightblack" style={{ padding: '5px 0' }}>{'Korrespondenz'.toUpperCase()}</div>
                    <MenuButton leftIcon="post_add" rightIcon="chevron_right" buttonTitle="Nachrichtenvorlage" onClick={ () => { checkProperty( SettingsViewType.ConversationTemplates ); } } />

                    <div className="flex center-y color-lightblack" style={{ marginTop: '15px',padding: '5px 0' }}>{'Belegungsplan'.toUpperCase()}</div>
                    <MenuButton leftIcon="share" rightIcon="chevron_right" buttonTitle="Belegungsplan freigeben" onClick={ () => { checkProperty( SettingsViewType.CalendarShare ); } } />
                    <MenuButton leftIcon="share" rightIcon="chevron_right" buttonTitle="Belegungsplan exportieren" onClick={ () => { checkProperty( SettingsViewType.CalendarExport ); } } />
                    <MenuButton leftIcon="share" rightIcon="chevron_right" buttonTitle="Belegungsplan importieren" onClick={ () => { checkProperty( SettingsViewType.CalendarImport ); } } />

                    <div className="flex center-y color-lightblack" style={{ marginTop: '15px',padding: '5px 0' }}>{'Eazyac'.toUpperCase()}</div>
                    <MenuButton leftIcon="shopping_cart" rightIcon="chevron_right" buttonTitle="Abonnements" onClick={ () => { setSettingsViewType( SettingsViewType.Subscriptions ); } } />

                    <div className="flex center-y color-lightblack" style={{ marginTop: '15px',padding: '5px 0' }}>{'Konto'.toUpperCase()}</div>
                    <MenuButton leftIcon="account_circle" rightIcon="chevron_right" buttonTitle="Benutzerdaten" onClick={ () => { setSettingsViewType( SettingsViewType.UserData ); } } />
                    <MenuButton leftIcon="contact_support" rightIcon="chevron_right" buttonTitle="Hilfe" onClick={ () => { setSettingsViewType( SettingsViewType.Support ); } } />
                   
                </div>
                </>
            }
            { settingsViewType === SettingsViewType.ConversationTemplates && <ConversationTemplatesView /> }
            { settingsViewType === SettingsViewType.CalendarShare && <CalendarShareList isModal={false} /> }
            { settingsViewType === SettingsViewType.CalendarExport && <CalendarExport isModal={false} /> }
            { settingsViewType === SettingsViewType.CalendarImport && <CalendarImport isModal={false} /> }
            { settingsViewType === SettingsViewType.UserData && <UserView /> }
            { settingsViewType === SettingsViewType.Subscriptions && <PayPalScriptProvider options={initialOptions} ><SubscriptionView /></PayPalScriptProvider> }
            { settingsViewType === SettingsViewType.Support && <div>Hilfe</div> }
            { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </ModalView>
     );
}
 
export default SettingsWrapper;