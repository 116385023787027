import ModalView from "./ModalView";
import InputView from "./InputView";
import { setCss } from "../methods/animation.methods";
import PopUp from "./PopUp";
import { validateMail, validatePhone } from "../methods/validation.methods";
import { ReactElement, useState } from "react";
import { CNViewType, Contact, ContactKey, Note } from "../methods/types";

type Props = {
    type: CNViewType;
    mainData: { Telefonnummer: string[], Email: string[], } | { Notiz: string[], Anlage: string[], };
    cnData: { cnKey: ContactKey, cnType: string, cnValue: string, cnData: string, index: number };
    updateCnData: (cnData: { cnKey: ContactKey, cnType: string, cnValue: string, cnData: string, index: number }) => void;
    isVisibleAddEditView: boolean;
    setIsVisibleContactAddEditView: (val: boolean) => void;
    saveData: () => void;
    deleteData: () => void;
}

const ContactAddEditView = ( { type, mainData, cnData, updateCnData, isVisibleAddEditView, setIsVisibleContactAddEditView, saveData, deleteData }:Props ) => {

    const [isVisibleKeyPopUp, setIsVisibleKeyPopUp] = useState(false);
    const [isVisibleTypePopUp, setIsVisibleTypePopUp] = useState(false);
    const [isError, setIsError] = useState(false);

    // const [cnKey, setCnKey] = useState( Object.keys(mainData)[0] as Array<keyof typeof mainData>[0] );
    // const [cnType, setCnType] = useState( mainData[cnKey][0] as string );
    // const [inputValue, setInputValue] = useState('');

    const inputProps = {title: '', placeholder: ''};

    if (cnData.cnKey as string == 'Telefonnummer') {
        inputProps.title = 'Telefonnummer';
        inputProps.placeholder = 'z. B. 0176 12345678';
    } else if (cnData.cnKey as string == 'Email') {
        inputProps.title = 'E-Mail-Adresse';
        inputProps.placeholder = 'z. B. max.mustermann@gmail.com';
    } else if (cnData.cnKey as string == 'Notiz') {
        inputProps.title = 'Kurze Notiz';
        inputProps.placeholder = 'z. B. Babybett aufstellen';
    } else if (cnData.cnKey as string == 'Anlage') {
        inputProps.title = 'Datei';
        inputProps.placeholder = '';
    }

    const CnKeyPopUp = ():ReactElement => {

        return (
            <PopUp style={{ marginTop: '30px', height: 'auto' }}>
            {Object.keys(mainData).map((val) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => { handleSetCnKey(e, val) } }
                    key={val}
                >
                    <div className="lbl" style={{textAlign: 'left'}}>{val}</div>
                    {val == cnData.cnKey && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const CnTypePopUp = () => {

        return (
            <PopUp style={{ marginTop: '30px', height: 'auto' }}>
            {(mainData[cnData.cnKey as keyof typeof mainData] as Array<string>).map((val) => {
                
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => handleSetCnType(e, val) }
                    key={val}
                >
                    <div className="lbl" style={{textAlign: 'left'}}>{val}</div>
                    {val == cnData.cnType && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleCloseCNAEView = (e: React.SyntheticEvent) => {

        setIsVisibleContactAddEditView(false);

        setCss('0');

        e.stopPropagation();

    }

    const handleSaveCNAEView = (e: React.SyntheticEvent) => {

        const keyString = cnData.cnKey as string;

        if ( ( ( (keyString == 'Telefonnummer' && !validatePhone(cnData.cnValue)) || (keyString == 'Email' && !validateMail(cnData.cnValue))  || (keyString == 'Notiz' && cnData.cnValue.length == 0) ) ) || (keyString == 'Anlage' && !cnData.cnData) ) {
            
            setIsError(true);

            setTimeout(() => {
                setIsError(false);
            }, 4000);

        } else {
         
            saveData();

            setIsVisibleContactAddEditView(false);

            setCss('0');

        }

        e.stopPropagation();

    }

    const handleClosAllPopUps = () => {
        setIsVisibleKeyPopUp(false);
        setIsVisibleTypePopUp(false);
    }

    const handleOpenKeyPopUp = (e: React.SyntheticEvent) => {
        
        handleClosAllPopUps();
        setIsVisibleKeyPopUp(true);

        e.stopPropagation()

    }

    const handleOpenTypePopUp = (e: React.SyntheticEvent) => {

        handleClosAllPopUps();
        setIsVisibleTypePopUp(true);

        e.stopPropagation();
    }

    const handleSetCnKey = (e: React.SyntheticEvent, val: string) => {

        const key = val as keyof typeof mainData;

        //setCnKey( key );
        //setCnType( mainData[key][0] as string );

        updateCnData( { ...cnData, cnKey: key, cnType: mainData[key][0], cnValue: '', cnData: '' } );

        setIsVisibleKeyPopUp( false );

        e.stopPropagation();

    }

    const handleSetCnType = (e: React.SyntheticEvent, val: string) => {

        //setCnType( val );

        updateCnData( { ...cnData, cnType: val } );

        setIsVisibleTypePopUp( false );

        e.stopPropagation();

    }

    const handleDeleteCN = (e: React.SyntheticEvent) => {

        deleteData();

        setIsVisibleContactAddEditView(false);

        setCss('0');

        e.stopPropagation();
    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let filename = e.target.value.replace(/.*[\/\\]/, '');

            if (e.target.files && e.target.files[0]) {
        
                var reader = new FileReader();
        
                reader.onload = function (e) {
                    
                    updateCnData( { ...cnData, cnValue: filename, cnData: e.target?.result as string } );
                    
                };
        
                reader.readAsDataURL(e.target.files[0]);
            }

    }

    const openFileDialog = () => {

        var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        });

        document.getElementById('file-dialog')?.dispatchEvent(clickEvent);

    }

    return ( 

        <ModalView modalViewId="contactAEView" style={{ width: '350px', overflow: 'visible' }} handleClose={(e) => handleCloseCNAEView(e)}>

            <div className="flex center-y w100prc">
                <div onClick={(e) => handleCloseCNAEView(e) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                { cnData.index >= 0 && <div onClick={(e) => handleDeleteCN(e) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>delete</div> }
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.2rem'}}>{ type == CNViewType.MailAndPhone ? 'Kontakt' : 'Notiz/Anlage' }</div>
                <div onClick={ (e) => handleSaveCNAEView(e) } className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>

            <div className="flex center-y w100prc" style={{ gap: '20px', marginTop: '20px', padding: '3px', overflow: 'visible' }}>
                <div onClick={(e) => handleOpenKeyPopUp(e) } className="clickable-popUpButton">
                {cnData.cnKey as string}
                { isVisibleKeyPopUp && <CnKeyPopUp /> }
                </div>
                <div onClick={(e) => handleOpenTypePopUp(e)} className="clickable-popUpButton">
                {cnData.cnType}
                { isVisibleTypePopUp && <CnTypePopUp /> }
                </div>
            </div>

            <InputView errorClass={ isError ? ' error' : '' } title={inputProps.title} style={{ marginTop: '20px', width: '100%' }}>
                
                { cnData.cnKey != ContactKey.Attachment && <input onChange={ (e) => updateCnData( {...cnData, cnValue: e.target.value} ) } type="text" value={cnData.cnValue} placeholder={inputProps.placeholder} /> }
                
                { cnData.cnKey == ContactKey.Attachment && <div className="flex center w100prc h100prc">
                    <div className="flex center color-lightblack" style={{width: '50%'}}>{ cnData.cnValue.length === 0 ? 'Keine Datei ausgewählt' : cnData.cnValue }</div>
                    <div onClick={openFileDialog} className="clickable-icon" style={{width: '40px'}}>attachment</div>
                    <input onChange={(e) => handleFileChange(e)} type = "file" id = "file-dialog" style={{display: 'none'}} />
                </div> }

            </InputView>

        </ModalView>

     );
}
 
export default ContactAddEditView;