import { ReactElement, useContext, useState } from "react";
import { DiscountJsonObj, DiscountObj, ViewState, ZkObj, ZlObj } from "../../../methods/types";
import { PropertysContext } from "../../../contexts/PropertysContext";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";

type Props = {
    bankAcc: ZkObj;
    handleOnClick: (state: ViewState) => void;
}

const BankAccountCard = ( { bankAcc, handleOnClick }: Props ) => {

    const { propertys } = useContext(PropertysContext);
    const { selectedProperty } = useContext(PropertysViewContext);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(
            
            <div className="flex center" onClick={ (e) => handleButtonClick(e, ViewState.Details) }>
                <div className="default-icon" style={{ width: '50px', fontSize: '1.5em' }}>{ bankAcc.zk_id === selectedProperty.property_zk ? 'check' : '' }</div>
                <div className="flex center w100prc column">
                    {
                        bankAcc.zk_paypal.length === 0 ?

                        <>
                        <div className="flex center-y w100prc">{ bankAcc.zk_name }</div>
                        <div className="flex center-y w100prc" style={{ padding: '2px 0' }}>{ bankAcc.zk_iban }</div>
                        <div className="flex center-y w100prc">{ bankAcc.zk_bic }</div>
                        </>

                        :

                        <>
                        <div className="flex center-y w100prc">Paypal</div>
                        <div className="flex center-y w100prc">{ bankAcc.zk_paypal }</div>
                        </>

                    }
                </div>
            </div>
            
        );
    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 
        <div className="shadowBox w100prc list-card" style={{ margin: '0 0 10px 0', paddingTop: '20px', }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
        </div>
     );
}
 
export default BankAccountCard;