import SearchBar from "../generalComps/SearchBar";
import { useContext, useDeferredValue, useEffect, useId, useMemo, useState } from "react";
import { AlertObj, InfoWidgetObj, Stay, ViewState, ViewComponent, PropertyPaymentObj } from "../methods/types";
import InfoWidget from "../generalComps/InfoWidget";
import ActionAlert from "../generalComps/ActionAlert";
import FilterPopUp from "../generalComps/FilterPopUp";
import StaysList from "./StaysList";
import StayBackview from "./StayBackview";
import BigAddIcon from "../generalComps/BigAddIcon";
import { emptyProperty, emptyStay } from "../methods/constants";
import { daysBetweenDates, getDateObj, getFullname } from "../methods/standard.methods";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { fetchStay, postDeleteStay, postUpdateStaySingleValue } from "../methods/http.stays.methods";
import StayCancellationView from "./StayCancellationView";
import { PropertysContext } from "../contexts/PropertysContext";
import { getCancellationObject } from "../methods/price.calculation.methods";
import { createPortal } from "react-dom";
import StayAddEditView from "./StayAddEditView";
import StayDetailView from "./StayDetailView";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import MessageDetailViewContextProvider from "../contexts/MessageDetailViewContext";
import NoPropertyComp from "../generalComps/NoPropertyComp";

const Stays = () => {

    const { reloadPropertys, propertys } = useContext(PropertysContext);
    const { setSelectedAddEditStay, setSelectedAddEditProperty, setStay, handleSelectedStay } = useContext(StayDetailViewContext);

    const params = useParams();

    const queryClient = useQueryClient();

    const navigate = useNavigate();

    const searchBarHeight = '150px';

    const actionAlertId = useId();

    const [isVisibleFilterPopUp, setIsVisibleFilterPopUp] = useState(false);
    const [isVisibleStayBackview, setIsVisibleStayBackview] = useState( {isVisible: false, state: ViewState.New} );
    const [isVisibleCancellationView, setIsVisibleCancellationView] = useState(false);
    const [searchString, setSearchString] = useState('');
    const deferredSearchString = useDeferredValue(searchString);
    const [showBlacklist, setShowBlacklist] = useState(false);
    const [showCancellation, setShowCancellation] = useState(false);
    const [rateValue, setRateValue] = useState(-1);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const [stayObj, setStayObj] = useState(emptyStay);

    const nights = daysBetweenDates( getDateObj( stayObj.stay_checkIn!, 0 ).date, getDateObj( stayObj.stay_checkOut!, 0 ).date );
    const priceDataObj = {priceListString: stayObj.stay_priceList!, specialPrice: stayObj.stay_special_price!, nights: nights, currency: propertys.currencyShort};

    useEffect( () => {

        reloadPropertys();

        window.document.title = 'Eazyac Aufenthalte';

    }, []);

    useEffect( () => {

        if( params.guest ) {
            setSearchString(params.guest);
        }

    }, [params]);

    const handleShowBlacklist = () => {

        setShowBlacklist((prev) => !prev);
        setShowCancellation(false);
        setRateValue(-1);

    }

    const handleShowCancellation = () => {

        setShowCancellation((prev) => !prev);
        setShowBlacklist(false);
        setRateValue(-1);

    }

    const handleAddStay = () => {

        //setStayObj( emptyStay );
        setSelectedAddEditProperty( emptyProperty );
        setSelectedAddEditStay( emptyStay );
        setIsVisibleStayBackview( {isVisible: true, state: ViewState.New} );

    }

    useMemo( () => {

        //if (handleSelectedStay.stay.stay_id !== -1) {

            setIsVisibleStayBackview( {isVisible: false, state: ViewState.New} );
            setStayObj( handleSelectedStay.stay );

            setTimeout(() => {
                
                if (handleSelectedStay.state === ViewState.Edit) {

                    setSelectedAddEditStay( handleSelectedStay.stay );
                    setIsVisibleStayBackview( {isVisible: true, state: handleSelectedStay.state} );
        
                } else if (handleSelectedStay.state === ViewState.Delete) {

                    const alertButtons = [ {title: 'Löschen', handler: () => handleDeleteStay() }, {title: 'Abbrechen', handler: () => setAlertObj(new AlertObj())} ];

                    const fullNameObj = getFullname(handleSelectedStay.stay.guest_title!, handleSelectedStay.stay.guest_firstname!, handleSelectedStay.stay.guest_lastname!, handleSelectedStay.stay.guest_company!);
                    
                    setAlertObj( new AlertObj( true, 'Aufenthalt löschen?', `Soll der Aufenthalt von ${ fullNameObj.fullname } im Objekt "${handleSelectedStay.stay.property_name!}" wirklich gelöscht werden?`, alertButtons ) );

                } else if (handleSelectedStay.state === ViewState.Cancellation) {
                    
                    if (!handleSelectedStay.stay.stay_storno_val || handleSelectedStay.stay.stay_storno_val! === 0) {
                        setIsVisibleCancellationView(true);
                    } else {

                        setAlertObj( new AlertObj( true, 'Stornierung aufheben?', `Aufenthalt ist bereits storniert. Stornogebühr: ${handleSelectedStay.stay.stay_storno_val!.toFixed(2)} ${propertys.currencyShort}. Stornierung aufheben?`, Array({title: 'Aufheben', handler: postUpdateStornoValueMutation }, {title: 'Abbrechen', handler: () => { setAlertObj( new AlertObj() ) }}) ) );

                    }

                } else if (handleSelectedStay.state === ViewState.Details) {
                    
                    fetchStayMutation(handleSelectedStay.stay.stay_id);

                }

            }, 100);

        //}

    }, [handleSelectedStay] );

    // const handleSelectedStay = (state: ViewState, stay: Stay) => {

    //     setIsVisibleStayBackview( {isVisible: false, state: ViewState.New} );
    //     setStayObj( stay );

    //     setTimeout(() => {
            
    //         if (state === ViewState.Edit) {

    //             setSelectedAddEditStay( stay );
    //             setIsVisibleStayBackview( {isVisible: true, state: state} );
    
    //         } else if (state === ViewState.Delete) {

    //             const alertButtons = [ {title: 'Löschen', handler: () => handleDeleteStay() }, {title: 'Abbrechen', handler: () => setAlertObj(new AlertObj())} ];

    //             const fullNameObj = getFullname(stay.guest_title!, stay.guest_firstname!, stay.guest_lastname!, stay.guest_company!);
                
    //             setAlertObj( new AlertObj( true, 'Aufenthalt löschen?', `Soll der Aufenthalt von ${ fullNameObj.fullname } im Objekt "${stay.property_name!}" wirklich gelöscht werden?`, alertButtons ) );

    //         } else if (state === ViewState.Cancellation) {
                
    //             if (!stay.stay_storno_val || stay.stay_storno_val! === 0) {
    //                 setIsVisibleCancellationView(true);
    //             } else {

    //                 setAlertObj( new AlertObj( true, 'Stornierung aufheben?', `Aufenthalt ist bereits storniert. Stornogebühr: ${stay.stay_storno_val!.toFixed(2)} ${propertys.currencyShort}. Stornierung aufheben?`, Array({title: 'Aufheben', handler: postUpdateStornoValueMutation }, {title: 'Abbrechen', handler: () => { setAlertObj( new AlertObj() ) }}) ) );

    //             }

    //         } else if (state === ViewState.Details) {
                
    //            fetchStayMutation(stay.stay_id);

    //         }

    //     }, 100);

    // }

    const { mutate: fetchStayMutation } = useMutation({
        mutationFn: (stayId: number) => fetchStay(stayId),
        onSuccess: (data) => {

            setStay( data.obj );
            setIsVisibleStayBackview( {isVisible: true, state: ViewState.Details} );

        }
    })

    const handleDeleteStay = () => {

        postDeleteStayMutation();

        setAlertObj(new AlertObj());

    }

    const handleSaveStay = (type: string) => {

        setIsVisibleStayBackview( {isVisible: false, state: ViewState.New} );
        setStayObj( emptyStay );

        setInfoWidgetObj( new InfoWidgetObj(true, `Aufenthalt erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

    }

    const { mutate: postDeleteStayMutation } = useMutation({
        mutationFn: () => postDeleteStay(stayObj.stay_id),
        onSuccess: (data) => {

            if (data && data.session === "expired") {
                navigate("login");
            } else if (data && data.success) {

                queryClient.invalidateQueries({ queryKey: ['fetchStays'] });

                setInfoWidgetObj( new InfoWidgetObj(true, 'Aufenthalt gelöscht.') );
        
            } else {
        
                setAlertObj( new AlertObj(true, 'Fehler', 'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.', standardAlertButton,) );
        
            }

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Fehler', 'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.', standardAlertButton,) );
        },
    })

    const { mutate: postUpdateStornoValueMutation } = useMutation({
        mutationFn: () => postUpdateStaySingleValue(stayObj.stay_id, 'stay_storno_val', '0'),
        onSuccess: (data) => {

            if (data && data.success) {
            
                queryClient.invalidateQueries({ queryKey: ['fetchStays'] });

                setAlertObj( new AlertObj() );
    
            } else {
                setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
            }

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
        },
    });

    const handleCancelAddEditView = () => {

        setStayObj( emptyStay );
        setIsVisibleStayBackview( {isVisible: false, state: ViewState.New} );

    }

    const getPaymentObj = () => {

        const property = propertys.array.filter( prop => { return prop.property_id === stayObj.stay_property!; } )[0];

        const paymentObj: PropertyPaymentObj = property ? JSON.parse( property.property_pm! ) : null;

        return paymentObj;

    }

    return ( 

        propertys.array.length === 0 ?

        <NoPropertyComp />

        :

        <div className="content-backview">
            <div className="list-column">
                <div className="flex-container center w100prc" style={{ overflow: 'visible', position: 'relative', height: searchBarHeight, padding: '10px', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px', }}>
                    <SearchBar iconName1="list_alt_add" title="Aufenthalte" iconName2="filter_alt" iconName3="" inputPlaceholder="Suche" searchString={searchString} setSearchString={ (searchString) => setSearchString(searchString)} handleClick1={ handleAddStay } handleClick2={() => setIsVisibleFilterPopUp( (prev) => !prev )} handleClick3={() => {}}/>
                    { isVisibleFilterPopUp && <FilterPopUp showBlacklist={showBlacklist} handleShowBlacklist={handleShowBlacklist} showCancellation={showCancellation} handleShowCancellation={handleShowCancellation} rateValue={rateValue} handleSetRateValue={ (newRateValue) => setRateValue(newRateValue) } />  }
                </div>
                <div className="flex-container center-y w100prc" style={{ padding: '10px 10px 10px 10px', height: `calc(100% - ${searchBarHeight})`, justifyContent: 'flex-start', overflow: 'visible', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px' }}>
                    <StaysList searchString={deferredSearchString} showBlacklist={showBlacklist} showCancellation={showCancellation} rateValue={rateValue} />
                </div>
            </div>
            {
                window.innerWidth > 759 ?

                <div className="flex-container center-y h100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', width: 'calc(100% - 420px)', backgroundColor: 'var(--tile-bg-hover)', borderRadius: '10px', }}>
                    { isVisibleStayBackview.isVisible ? 
                        <StayBackview style={{height: 'auto', width: isVisibleStayBackview.state === ViewState.Details ? '60%' : '90%'}}>
                            { (isVisibleStayBackview.state === ViewState.New || isVisibleStayBackview.state === ViewState.Edit) ? 
                                <StayAddEditView handleCancel={handleCancelAddEditView} handleSave={handleSaveStay}/>
                            : 
                            <MessageDetailViewContextProvider>
                                <StayDetailView handleCancel={() => setIsVisibleStayBackview({isVisible: false, state: ViewState.New})} />
                            </MessageDetailViewContextProvider>
                            }
                        </StayBackview> : <BigAddIcon viewComponent={ViewComponent.Stay} handleAddClick={handleAddStay} /> }
                </div>
                :
                isVisibleStayBackview.isVisible &&
                <div className="flex column w100prc" style={{ position: 'absolute', height: 'auto', padding: '5px', backgroundColor: 'var(--bg-color-white)', overflowY: 'scroll' }}>
                    { (isVisibleStayBackview.state === ViewState.New || isVisibleStayBackview.state === ViewState.Edit) ? 
                        <StayAddEditView handleCancel={handleCancelAddEditView} handleSave={handleSaveStay}/>
                    : 
                    <MessageDetailViewContextProvider>
                        <StayDetailView handleCancel={() => setIsVisibleStayBackview({isVisible: false, state: ViewState.New})} />
                    </MessageDetailViewContextProvider>
                    }
                </div>

            }
            { isVisibleCancellationView && createPortal( <StayCancellationView stayId={stayObj.stay_id} cancellationObj={ getCancellationObject( getPaymentObj(), stayObj.stay_checkIn!) } priceData={priceDataObj} handleClose={() => setIsVisibleCancellationView(false)} />, document.body) }
            { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body ) }
            { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </div>

     );
}
 
export default Stays;