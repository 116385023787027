import { useMutation } from "@tanstack/react-query";
import { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { SettingsViewContext } from "../contexts/SettingsContext";
import { fetchUserData } from "../methods/http.user.methods";
import { UserAddress, UserData } from "../methods/types";
import UserNameAddEditView from "./UserNameAddEditView";
import UserEmailAddEditView from "./UserEmailAddEditView";
import UserMobileAddEditView from "./UserMobileAddEditView";
import UserPasswordAddEditView from "./UserPasswordAddEditView";
import UserAddressAddEditView from "./UserAddressAddEditView";
import UserAccountDeletionView from "./UserAccountDeletionView";

const UserView = () => {

    const { setSettingsViewType, lastSettingsViewTypeRef } = useContext(SettingsViewContext);

    const [userData, setUserData] = useState<UserData | null>( null );
    const [userAddress, setUserAddress] = useState<UserAddress>( {street: '', zip: '', city: '', country: ''} );

    const [isVisibleUserNameAddEditView, setIsVisibleUserNameAddEditView] = useState(false);
    const [isVisibleUserMailAddEditView, setIsVisibleUserMailAddEditView] = useState(false);
    const [isVisibleUserMobileAddEditView, setIsVisibleUserMobileAddEditView] = useState(false);
    const [isVisibleUserPasswordAddEditView, setIsVisibleUserPasswordAddEditView] = useState(false);
    const [isVisibleUserAddressAddEditView, setIsVisibleUserAddressAddEditView] = useState(false);
    const [isVisibleUserAccountDeletionView, setIsVisibleUserAccountDeletionView] = useState(false);

    const { mutate: fetchUserDataMutation } = useMutation({
        mutationFn: () => fetchUserData(),
        onSuccess: (data) => {

            setUserData( data.obj[0] );

        },
    })

    useEffect(() => fetchUserDataMutation(), [] );

    useEffect( () => {

        if ( userData !== null && userData.user_address !== null ) {

            setUserAddress( JSON.parse( userData.user_address ) );

        }

    }, [userData])

    return ( 
        <>
        <div className="flex center-y w100prc">
            <div onClick={ () => setSettingsViewType( lastSettingsViewTypeRef.current ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem', paddingRight: '40px'}}>Benutzerdaten</div>
        </div>

        {
            userData === null ?

            <div className="shadowBox center color-lightblack" style={{ marginTop: '20px' }}>Daten werden geladen...</div>
            
            :

            <>
            <div onClick={ () => setIsVisibleUserNameAddEditView(true) } className="shadowBox list-card" style={{ marginTop: '20px' }}>
                <div className="default-icon disable-pointer-events" style={{ position: 'absolute', top: '5px', right: '0' }}>edit</div>
                <div className="flex center-y color-cyan" style={{ fontSize: '1.2em' }}>Benutzer / Firma</div>
                <div className="flex center-y" style={{ padding: '5px 0' }}>{userData.user_title === 0 ? 'Herr' : 'Frau'} {userData.user_firstname} {userData.user_lastname}</div>
                { userData.user_company.length > 0 && <div className="flex center-y color-lightblack" >{userData.user_company}</div> }
                <div className="flex center-y color-lightblack" style={{ fontSize: '0.8em', paddingTop: '5px' }}>Registriert am {userData.registration_timestamp}</div>
            </div>

            <div onClick={ () => setIsVisibleUserMailAddEditView(true) } className="shadowBox list-card" style={{ marginTop: '10px' }}>
                <div className="default-icon disable-pointer-events" style={{ position: 'absolute', top: '5px', right: '0' }}>edit</div>
                <div className="flex center-y color-cyan" style={{ fontSize: '1.2em' }}>E-Mail</div>
                <div className="flex center-y" style={{ paddingTop: '5px' }}>{userData.user_email}</div>
            </div>

            <div onClick={ () => setIsVisibleUserMobileAddEditView(true) } className="shadowBox list-card" style={{ marginTop: '10px' }}>
                <div className="default-icon disable-pointer-events" style={{ position: 'absolute', top: '5px', right: '0' }}>edit</div>
                <div className="flex center-y color-cyan" style={{ fontSize: '1.2em' }}>Mobiltelefon</div>
                <div className="flex center-y" style={{ paddingTop: '5px' }}>{userData.user_mobile}</div>
            </div>

            <div onClick={ () => setIsVisibleUserPasswordAddEditView(true) } className="shadowBox list-card" style={{ marginTop: '10px' }}>
                <div className="default-icon disable-pointer-events" style={{ position: 'absolute', top: '5px', right: '0' }}>edit</div>
                <div className="flex center-y color-cyan" style={{ fontSize: '1.2em' }}>Passwort</div>
                <div className="flex center-y" style={{ paddingTop: '5px' }}>********</div>
            </div>

            <div onClick={ () => setIsVisibleUserAddressAddEditView(true) } className="shadowBox list-card" style={{ marginTop: '10px' }}>
                <div className="default-icon disable-pointer-events" style={{ position: 'absolute', top: '5px', right: '0' }}>edit</div>
                <div className="flex center-y color-cyan" style={{ fontSize: '1.2em' }}>Rechnungsadresse</div>
                <div className="flex center-y" style={{ paddingTop: '5px' }}>{userAddress.street}</div>
                <div className="flex center-y" style={{ paddingTop: '2px' }}>{userAddress.zip} {userAddress.city}</div>
                <div className="flex center-y" style={{ paddingTop: '2px' }}>{userAddress.country}</div>
            </div>

            <div onClick={ () => setIsVisibleUserAccountDeletionView(true) } className="shadowBox center list-card" style={{ marginTop: '30px' }}>
                <div className="flex center w100prc">
                    <div className="default-icon" style={{ color: 'red', fontSize: '1.5em' }}>no_accounts</div>
                    <div className="flex center-y" style={{ color: 'red' }}>Konto löschen</div>
                </div>
            </div>
            </>

        }
        { isVisibleUserNameAddEditView && createPortal( <UserNameAddEditView title={ userData === null ? 0 : Number(userData.user_title) } firstName={ userData === null ? '' : userData.user_firstname } lastName={ userData === null ? '' : userData.user_lastname } company={ userData === null ? '' : userData.user_company } handleClose={ () => setIsVisibleUserNameAddEditView(false) } reloadFunc={ fetchUserDataMutation } />, document.body ) }
        { isVisibleUserMailAddEditView && createPortal( <UserEmailAddEditView email={ userData === null ? '' : userData.user_email } handleClose={ () => setIsVisibleUserMailAddEditView(false) } reloadFunc={ fetchUserDataMutation } />, document.body ) }
        { isVisibleUserMobileAddEditView && createPortal( <UserMobileAddEditView mobile={ userData === null ? '' : userData.user_mobile } handleClose={ () => setIsVisibleUserMobileAddEditView(false) } reloadFunc={ fetchUserDataMutation } />, document.body ) }
        { isVisibleUserPasswordAddEditView && createPortal( <UserPasswordAddEditView handleClose={ () => setIsVisibleUserPasswordAddEditView(false) } />, document.body ) }
        { isVisibleUserAddressAddEditView && createPortal( <UserAddressAddEditView address={ userAddress } handleClose={ () => setIsVisibleUserAddressAddEditView(false) } reloadFunc={ fetchUserDataMutation } />, document.body ) }
        { isVisibleUserAccountDeletionView && createPortal( <UserAccountDeletionView handleClose={ () => setIsVisibleUserAccountDeletionView(false) } />, document.body ) }
        </>
     );
}
 
export default UserView;