import { useContext, useEffect, useId, useRef, useState } from "react";
import InputView from "../../generalComps/InputView";
import ModalView from "../../generalComps/ModalView";
import { AlertObj, ConversationViewType, PdfDesignLblObj, PdfDesignObj, PdfType } from "../../methods/types";
import { StayConversationContext } from "../../contexts/StayConversationContext";
import { arrayRange, hexToRgb, rgbToHex } from "../../methods/standard.methods";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import PopUp from "../../generalComps/PopUp";
import { conversationMailTypeArr } from "../../methods/constants";
import { useMutation } from "@tanstack/react-query";
import { fetchPdfExTpUrl, fetchPdfUrl } from "../../methods/http.korres.methods";
import { postAddPdfDesign, postDeletePdfDesing, postUpdatePdfDesign } from "../../methods/http.pdf.methods";
import { Colors } from "chart.js";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";

type Props = {
    handleClose: () => void;
}

const TunePdfView = ( { handleClose }:Props ) => {

    const { stay } = useContext(StayDetailViewContext);
    const { pdfDesignObjRef, conversationTypeRef, pdfContentRef, setPdfUrl, setConversationViewType, conversationTypeStringRef } = useContext(StayConversationContext);

    const pdfDesignObj: PdfDesignObj | null = pdfDesignObjRef.current ? JSON.parse( pdfDesignObjRef.current!.pdf_design ) : null;
    
    const headerLblArrRef = useRef<Array<PdfDesignLblObj>>( pdfDesignObj === null ? [] : pdfDesignObj.header_lbls );

    const headerBgColorInputRef = useRef<HTMLInputElement>(null);
    const headerBgColorInputDefaultValue = pdfDesignObj === null ? '#ff0000' : rgbToHex( pdfDesignObj.header_bg );

    const headerFontColorInputRef = useRef<HTMLInputElement>(null);
    const headerFontColorInputDefaultValue = pdfDesignObj === null ? '#ff0000' : rgbToHex( pdfDesignObj.header_fc );

    const sectionBgColorInputRef = useRef<HTMLInputElement>(null);
    const sectionBgColorInputDefaultValue = pdfDesignObj === null ? '#ff0000' : rgbToHex( pdfDesignObj.mheader_bg );

    const sectionFontColorInputRef = useRef<HTMLInputElement>(null);
    const sectionFontColorInputDefaultValue = pdfDesignObj === null ? '#ff0000' : rgbToHex( pdfDesignObj.mheader_fc );

    const sheaderBgColorInputRef = useRef<HTMLInputElement>(null);
    const sheaderBgColorInputDefaultValue = pdfDesignObj?.sheader_bg === undefined ? '#ff0000' : rgbToHex( pdfDesignObj!.sheader_bg! );

    const sheaderFontColorInputRef = useRef<HTMLInputElement>(null);
    const sheaderFontColorInputDefaultValue = pdfDesignObj?.sheader_fc=== undefined ? '#ff0000' : rgbToHex( pdfDesignObj!.sheader_fc! );

    const pdfFontColorInputRef = useRef<HTMLInputElement>(null);
    const pdfFontColorInputDefaultValue = pdfDesignObj === null ? '#ff0000' : rgbToHex( pdfDesignObj.pdf_fc ); 

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    // const fileNameRef = useRef('');
    // const fileDataRef = useRef('');

    const popUpIndexRef = useRef(0);

    const fontSize = headerLblArrRef.current.length === 0 ? 25 : headerLblArrRef.current[popUpIndexRef.current].fontsize;
    const x = headerLblArrRef.current.length === 0 ? 10 : headerLblArrRef.current[popUpIndexRef.current].x;
    const y = headerLblArrRef.current.length === 0 ? 17 : headerLblArrRef.current[popUpIndexRef.current].y;
    const w = headerLblArrRef.current.length === 0 ? 10 : headerLblArrRef.current[popUpIndexRef.current].width === undefined ? 10 : headerLblArrRef.current[popUpIndexRef.current].width;
    const h = headerLblArrRef.current.length === 0 ? 10 : headerLblArrRef.current[popUpIndexRef.current].height === undefined ? 10 : headerLblArrRef.current[popUpIndexRef.current].height;
    const visible = headerLblArrRef.current[popUpIndexRef.current]?.visible ? 'Sichtbar' : 'Unsichtbar';

    const [popUpType, setPopUpType] = useState( stay.property_art! );
    const [popUpFontsize, setPopUpFontsize] = useState( fontSize );
    const [popUpX, setPopUpX] = useState( x );
    const [popUpY, setPopUpY] = useState( y );
    const [popUpWidth, setPopUpWidth] = useState( w );
    const [popUpHeight, setPopUpHeight] = useState( h );
    const [popUpVisible, setPopUpVisible] = useState( visible );

    const [isVisiblePopUpType, setIsVisiblePopUpType] = useState(false);
    const [isVisiblePopUpVisible, setIsVisiblePopUpVisible] = useState(false);
    const [isVisiblePopUpFontsize, setIsVisiblePopUpFontsize] = useState(false);
    const [isVisiblePopUpX, setIsVisiblePopUpX] = useState(false);
    const [isVisiblePopUpY, setIsVisiblePopUpY] = useState(false);
    const [isVisiblePopUpWidth, setIsVisiblePopUpWidth] = useState(false);
    const [isVisiblePopUpHeight, setIsVisiblePopUpHeight] = useState(false);

    useEffect( () => {

        setPopUpFontsize( fontSize );
        setPopUpX( x );
        setPopUpY( y );
        setPopUpWidth( w );
        setPopUpHeight( h );
        setPopUpVisible( visible );

    }, [popUpType])

    const closePopUpViews = () => {

        setIsVisiblePopUpType(false);
        setIsVisiblePopUpFontsize(false);
        setIsVisiblePopUpX(false);
        setIsVisiblePopUpY(false);
        setIsVisiblePopUpWidth(false);
        setIsVisiblePopUpHeight(false);
        setIsVisiblePopUpVisible(false);

    }

    const TypePopUp = () => {

        const handleOnClick = (e: React.SyntheticEvent, type: string, index: number) => {

            popUpIndexRef.current = index;

            setPopUpType( type );

            closePopUpViews();

            e.stopPropagation();

        }

        return (
            <PopUp style={{ height: 'auto', marginTop: '30px', textAlign: 'left' }} handleClosePopUp={closePopUpViews}>
                <div className="lbl-container" onClick={(e) => handleOnClick( e, stay.property_art!, 0 ) }>
                    <div className="lbl">{ stay.property_art! }</div>
                    {popUpType === stay.property_art! && <div className="default-icon">check</div>}
                </div>
                <div className="lbl-container" onClick={(e) => handleOnClick( e, stay.property_name!, 1 ) }>
                    <div className="lbl">{ stay.property_name! }</div>
                    {popUpType === stay.property_name! && <div className="default-icon">check</div>}
                </div>
                <div className="lbl-container" onClick={(e) => handleOnClick( e, conversationMailTypeArr[conversationTypeRef.current].text, 2) }>
                    <div className="lbl">{ conversationMailTypeArr[conversationTypeRef.current].text }</div>
                    {popUpType === conversationMailTypeArr[conversationTypeRef.current].text && <div className="default-icon">check</div>}
                </div>
                <div className="lbl-container" onClick={(e) => handleOnClick( e, 'Kontaktdaten', 3) }>
                    <div className="lbl">Kontaktdaten</div>
                    {popUpType === 'Kontaktdaten' && <div className="default-icon">check</div>}
                </div>
                <div className="lbl-container" onClick={(e) => handleOnClick( e, 'Logo', 4 ) }>
                    <div className="lbl">Logo</div>
                    {popUpType === 'Logo' && <div className="default-icon">check</div>}
                </div>
            </PopUp>
        );
    };

    const FontsizePopUp = () => {

        const rangeArr = arrayRange(6, 31, 1);

        const handleOnClick = (e: React.SyntheticEvent, num: number) => {

            headerLblArrRef.current[popUpIndexRef.current].fontsize = num;
            setPopUpFontsize( num );

            closePopUpViews();

            e.stopPropagation();

        }

        return (
            <PopUp style={{ maxHeight: '230px', marginTop: '30px', textAlign: 'left' }} handleClosePopUp={closePopUpViews}>
                {
                    rangeArr.map( num => {

                        return(
                            <div key={num} className="lbl-container" onClick={(e) => handleOnClick( e, num ) }>
                                <div className="lbl">{num}</div>
                                {popUpFontsize === num && <div className="default-icon">check</div>}
                            </div>
                        )

                    })
                }
            </PopUp>
        );
    };

    const XPopUp = () => {

        const rangeArr = arrayRange(1, 200, 1);

        const handleOnClick = (e: React.SyntheticEvent, num: number) => {

            headerLblArrRef.current[popUpIndexRef.current].x = num;
            setPopUpX( num );

            closePopUpViews();

            e.stopPropagation();

        }

        return (
            <PopUp style={{ maxHeight: '230px', marginTop: '30px', textAlign: 'left' }} handleClosePopUp={closePopUpViews}>
                {
                    rangeArr.map( num => {

                        return(
                            <div key={num} className="lbl-container" onClick={(e) => handleOnClick( e, num ) }>
                                <div className="lbl">{num}</div>
                                {popUpX === num && <div className="default-icon">check</div>}
                            </div>
                        )

                    })
                }
            </PopUp>
        );
    };

    const YPopUp = () => {

        const rangeArr = arrayRange(1, 35, 1);

        const handleOnClick = (e: React.SyntheticEvent, num: number) => {

            headerLblArrRef.current[popUpIndexRef.current].y = num;
            setPopUpY( num );

            closePopUpViews();

            e.stopPropagation();

        }

        return (
            <PopUp style={{ maxHeight: '230px', marginTop: '30px', textAlign: 'left' }} handleClosePopUp={closePopUpViews}>
                {
                    rangeArr.map( num => {

                        return(
                            <div key={num} className="lbl-container" onClick={(e) => handleOnClick( e, num ) }>
                                <div className="lbl">{num}</div>
                                {popUpY === num && <div className="default-icon">check</div>}
                            </div>
                        )

                    })
                }
            </PopUp>
        );
    };

    const WidthPopUp = () => {

        const rangeArr = arrayRange(1, 200, 1);

        const handleOnClick = (e: React.SyntheticEvent, num: number) => {

            headerLblArrRef.current[popUpIndexRef.current].width = num;
            setPopUpWidth( num );

            closePopUpViews();

            e.stopPropagation();

        }

        return (
            <PopUp style={{ maxHeight: '230px', marginTop: '30px', textAlign: 'left' }} handleClosePopUp={closePopUpViews}>
                {
                    rangeArr.map( num => {

                        return(
                            <div key={num} className="lbl-container" onClick={(e) => handleOnClick( e, num ) }>
                                <div className="lbl">{num}</div>
                                {popUpWidth === num && <div className="default-icon">check</div>}
                            </div>
                        )

                    })
                }
            </PopUp>
        );
    };

    const HeightPopUp = () => {

        const rangeArr = arrayRange(1, 35, 1);

        const handleOnClick = (e: React.SyntheticEvent, num: number) => {

            headerLblArrRef.current[popUpIndexRef.current].height = num;
            setPopUpHeight( num );

            closePopUpViews();

            e.stopPropagation();

        }

        return (
            <PopUp style={{ maxHeight: '230px', marginTop: '30px', textAlign: 'left' }} handleClosePopUp={closePopUpViews}>
                {
                    rangeArr.map( num => {

                        return(
                            <div key={num} className="lbl-container" onClick={(e) => handleOnClick( e, num ) }>
                                <div className="lbl">{num}</div>
                                {popUpHeight === num && <div className="default-icon">check</div>}
                            </div>
                        )

                    })
                }
            </PopUp>
        );
    };

    const VisiblePopUp = () => {

        const handleOnClick = (e: React.SyntheticEvent, type: string) => {

            headerLblArrRef.current[popUpIndexRef.current].visible = type === 'Sichtbar' ? true : false;
            setPopUpVisible( type );

            closePopUpViews();

            e.stopPropagation();

        }

        return (
            <PopUp style={{ height: 'auto', marginTop: '30px', textAlign: 'left' }} handleClosePopUp={closePopUpViews}>
                <div className="lbl-container" onClick={(e) => handleOnClick( e, 'Sichtbar' ) }>
                    <div className="lbl">Sichtbar</div>
                    {popUpVisible === 'Sichtbar' && <div className="default-icon">check</div>}
                </div>
                <div className="lbl-container" onClick={(e) => handleOnClick( e, 'Unsichtbar' ) }>
                    <div className="lbl">Unsichtbar</div>
                    {popUpVisible === 'Unsichtbar' && <div className="default-icon">check</div>}
                </div>
            </PopUp>
        );
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        //let filename = e.target.value.replace(/.*[\/\\]/, '');

            if (e.target.files && e.target.files[0]) {
        
                var reader = new FileReader();
        
                reader.onload = function (e) {
                    
                    pdfDesignObjRef.current!.pdf_image = e.target?.result as string;
                    headerLblArrRef.current[popUpIndexRef.current].visible = true;
                    setPopUpVisible( 'Sichtbar' );
                    //fileNameRef.current = filename;
                    
                };
        
                reader.readAsDataURL(e.target.files[0]);
            }

    }

    const openFileDialog = () => {

        var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        });

        document.getElementById('file-dialog')?.dispatchEvent(clickEvent);

    }

    const handleSaveOnClick = () => {

        pdfDesignObjRef.current!.pdf_art = conversationTypeRef.current;

        const headerBgColorRgb = hexToRgb( headerBgColorInputRef.current!.value );
        const headerFontColor = hexToRgb( headerFontColorInputRef.current!.value );
        const sectionBgColorRgb = hexToRgb( sectionBgColorInputRef.current!.value );
        const sectionFontColor = hexToRgb( sectionFontColorInputRef.current!.value );
        const sheaderBgColorRgb = hexToRgb( sheaderBgColorInputRef.current === null ? sheaderBgColorInputDefaultValue : sheaderBgColorInputRef.current!.value );
        const sheaderFontColor = hexToRgb( sheaderFontColorInputRef.current === null ? sheaderFontColorInputDefaultValue : sheaderFontColorInputRef.current!.value );
        const pdfFontColor = hexToRgb( pdfFontColorInputRef.current!.value );
        
        const tempPdfDesignObj: PdfDesignObj = { 
            header_bg: [headerBgColorRgb!.r, headerBgColorRgb!.g, headerBgColorRgb!.b],
            header_fc: [headerFontColor!.r, headerFontColor!.g, headerFontColor!.b],
            mheader_bg: [sectionBgColorRgb!.r, sectionBgColorRgb!.g, sectionBgColorRgb!.b],
            mheader_fc: [sectionFontColor!.r, sectionFontColor!.g, sectionFontColor!.b],
            sheader_bg: [sheaderBgColorRgb!.r, sheaderBgColorRgb!.g, sheaderBgColorRgb!.b],
            sheader_fc: [sheaderFontColor!.r, sheaderFontColor!.g, sheaderFontColor!.b],
            pdf_fc: [pdfFontColor!.r, pdfFontColor!.g, pdfFontColor!.b],
            header_lbls: headerLblArrRef.current,
        };

        pdfDesignObjRef.current!.pdf_design = JSON.stringify( tempPdfDesignObj );

        if (pdfDesignObjRef.current?.pdf_id) {
            postUpdateDesignMutation();
        } else {
            postAddDesignMutation();
        }

    }

    const handleResetOnClick = () => {

        const buttons = [
            {title: 'Wiederherstellen', handler: () => postDeleteDesignMutation() },
            {title: 'Abbrechen', handler: () => { setAlertObj( new AlertObj() ) }},
        ]

        setAlertObj( new AlertObj(true, 'Standard wiederherstellen?', 'Möchtest du das Standard-Design wiederherstellen?', buttons) );

    }

    const { mutate: postAddDesignMutation } = useMutation({
        mutationFn: () => postAddPdfDesign(pdfDesignObjRef.current!),
        onSuccess: (data) => {
            
            if (data.pdf_id) {
                pdfDesignObjRef.current!.pdf_id = Number( data.pdf_id );
            }

            if (conversationTypeRef.current <= 5) {
                fetchPdfUrlMutation();
            } else if (conversationTypeRef.current === 6) {
                fetchPdfExUrlMutation();
            } else if (conversationTypeRef.current === 7) {
                fetchPdfTpUrlMutation();
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postUpdateDesignMutation } = useMutation({
        mutationFn: () => postUpdatePdfDesign(pdfDesignObjRef.current!),
        onSuccess: () => {
            
            if (conversationTypeRef.current <= 5) {
                fetchPdfUrlMutation();
            } else if (conversationTypeRef.current === 6) {
                fetchPdfExUrlMutation();
            } else if (conversationTypeRef.current === 7) {
                fetchPdfTpUrlMutation();
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postDeleteDesignMutation } = useMutation({
        mutationFn: () => postDeletePdfDesing(pdfDesignObjRef.current!.pdf_id),
        onSuccess: () => {
            
            if (conversationTypeRef.current <= 5) {
                fetchPdfUrlMutation();
            } else if (conversationTypeRef.current === 6) {
                fetchPdfExUrlMutation();
            } else if (conversationTypeRef.current === 7) {
                fetchPdfTpUrlMutation();
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: fetchPdfUrlMutation } = useMutation({
        mutationFn: () => fetchPdfUrl(stay.stay_id, pdfContentRef.current, conversationTypeRef.current, conversationTypeStringRef.current ),
        onSuccess: (data) => {
            
            setPdfUrl( {url: data.pdf_name!} );

            setConversationViewType(ConversationViewType.PdfView);

            handleClose();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: fetchPdfExUrlMutation } = useMutation({
        mutationFn: () => fetchPdfExTpUrl( stay.stay_property, stay.stay_guest!, PdfType.Expose ),
        onSuccess: (data) => {
            
            setPdfUrl( {url: data.pdf_name!} );

            setConversationViewType(ConversationViewType.PdfView);

            handleClose();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: fetchPdfTpUrlMutation } = useMutation({
        mutationFn: () => fetchPdfExTpUrl( stay.stay_property, stay.stay_guest!, PdfType.Transferprotocol ),
        onSuccess: (data) => {
            
            setPdfUrl( {url: data.pdf_name!} );

            setConversationViewType(ConversationViewType.PdfView);

            handleClose();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 
        <>
        <ModalView modalViewId="editPdfView" handleClose={handleClose} style={{width: '700px'}}>
            <div className="navigation-view-bar">
                <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">PDF-Design</div>
                { pdfDesignObjRef.current?.pdf_id && <div title="Standard wiederherstellen" onClick={ handleResetOnClick } className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px' }}>restart_alt</div> }
                <div title="Speichern" onClick={ handleSaveOnClick } className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <div className="flex center-y w100prc" style={{padding: '20px 0 10px 0', fontSize: '1.3em'}}>Briefkopf</div>
            <div className="flex w100prc" style={{ gap: '10px' }}>
                <InputView title="Hintergrundfarbe"><input ref={headerBgColorInputRef} style={{height: '30px'}} type="color" defaultValue={headerBgColorInputDefaultValue} /></InputView>
                <InputView title="Textfarbe"><input ref={headerFontColorInputRef} style={{height: '30px'}} type="color" defaultValue={headerFontColorInputDefaultValue} /></InputView>
            </div>

            <div className="tune-settings-wrapper" style={{ fontSize: popUpIndexRef.current === 4 ? '0.9em' : '1.0em' }}>
                { popUpIndexRef.current === 4 && 
                    <>
                    <div onClick={openFileDialog} className="flex center clickable-icon" style={{ width: '40px', fontSize: '1.5em', overflow: 'visible' }}>add_photo_alternate</div>
                    <input onChange={(e) => handleFileChange(e)} type="file" accept="image/*" id="file-dialog" style={{display: 'none'}} />
                    </>
                }
                <div onClick={ () => { closePopUpViews(); setIsVisiblePopUpType(true); } } className="clickable-popUpButton w100prc">
                    { popUpType }
                    { isVisiblePopUpType && <TypePopUp /> }
                </div>
                <div onClick={ () => { closePopUpViews(); setIsVisiblePopUpFontsize(true); } } className="clickable-popUpButton w100prc">
                    { `Textg.: ${popUpFontsize}` }
                    { isVisiblePopUpFontsize && <FontsizePopUp /> }
                </div>
                <div onClick={ () => { closePopUpViews(); setIsVisiblePopUpX(true); } } className="clickable-popUpButton w100prc">
                    { `Pos-X: ${popUpX}` }
                    { isVisiblePopUpX && <XPopUp /> }
                </div>
                <div onClick={ () => { closePopUpViews(); setIsVisiblePopUpY(true); } } className="clickable-popUpButton w100prc">
                    { `Pos-Y: ${popUpY}` }
                    { isVisiblePopUpY && <YPopUp /> }
                </div>
                {
                    popUpIndexRef.current === 4 &&
                    <>
                    <div onClick={ () => { closePopUpViews(); setIsVisiblePopUpWidth(true); } } className="clickable-popUpButton w100prc">
                        { `Breite: ${popUpWidth}` }
                        { isVisiblePopUpWidth && <WidthPopUp /> }
                    </div>
                    <div onClick={ () => { closePopUpViews(); setIsVisiblePopUpHeight(true); } } className="clickable-popUpButton w100prc">
                        { `Höhe: ${popUpHeight}` }
                        { isVisiblePopUpHeight && <HeightPopUp /> }
                    </div>
                    </>
                }
                <div onClick={ () => { closePopUpViews(); setIsVisiblePopUpVisible(true); } } className="clickable-popUpButton w100prc">
                    { popUpVisible }
                    { isVisiblePopUpVisible && <VisiblePopUp /> }
                </div>
            </div>

            <div className="flex center-y w100prc" style={{padding: '20px 0 10px 0', fontSize: '1.3em'}}>Trennbalken</div>
            <div className="flex w100prc" style={{ gap: '10px' }}>
                <InputView title="Hintergrundfarbe"><input ref={sectionBgColorInputRef} style={{height: '30px'}} type="color" defaultValue={sectionBgColorInputDefaultValue} /></InputView>
                <InputView title="Textfarbe"><input ref={sectionFontColorInputRef} style={{height: '30px'}} type="color" defaultValue={sectionFontColorInputDefaultValue} /></InputView>
            </div>
            {
                conversationTypeRef.current === 6 &&
                <>
                <div className="flex center-y w100prc" style={{padding: '20px 0 10px 0', fontSize: '1.3em'}}>Seitenbalken</div>
                <div className="flex w100prc" style={{ gap: '10px' }}>
                    <InputView title="Hintergrundfarbe"><input ref={sheaderBgColorInputRef} style={{height: '30px'}} type="color" defaultValue={sheaderBgColorInputDefaultValue} /></InputView>
                    <InputView title="Textfarbe"><input ref={sheaderFontColorInputRef} style={{height: '30px'}} type="color" defaultValue={sheaderFontColorInputDefaultValue} /></InputView>
                </div>
                </>
            }
            <div className="flex center-y w100prc" style={{padding: '20px 0 10px 0', fontSize: '1.3em'}}>PDF Allgemein</div>
            <InputView title="Textfarbe"><input ref={pdfFontColorInputRef} style={{height: '30px'}} type="color" defaultValue={pdfFontColorInputDefaultValue} /></InputView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default TunePdfView;