import { useContext, useEffect, useId, useRef, useState } from "react";
import ActionAlert from "../../generalComps/ActionAlert";
import ModalView from "../../generalComps/ModalView";
import { AlertObj, HandoverProtocolObj } from "../../methods/types";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import InputView from "../../generalComps/InputView";
import { useMutation } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../methods/http.property.methods";
import ImageSelectionView from "../../generalComps/ImageSelectionView";
import { fetchHandoverImageBlob, postAddHandoverImageBlob, postUpdateHandoverImageBlob } from "../../methods/http.blob.methods";

type Props = {
    handleCancel: () => void;
    handleSave: (type: string, json: string, isImageUpdate: boolean) => void;
    index: number;
}

const HandoverAddEditView = ( { handleCancel, handleSave, index }:Props ) => {

    const { selectedProperty, setSelectedProperty, selectedHandover, setSelectedHandover } = useContext(PropertysViewContext);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputTitleRef = useRef<HTMLInputElement | null>(null);
    const inputSubtitleRef = useRef<HTMLInputElement | null>(null);

    const [urlString, setUrlString] = useState('ef_std_img.jpg');

    const handleSaveOnClick = () => {

        if (inputTitleRef.current!.value.length === 0) {

            setAlertObj( new AlertObj( true, 'Fehlender Titel', 'Bitte vergebe dem Inventar einen Titel.', standardAlertButton ) );

        } else {

            const handovers = selectedProperty.property_tp ? JSON.parse( selectedProperty.property_tp ) : [];

            const newHandover: HandoverProtocolObj = { ...selectedHandover,
                                            text1: inputTitleRef.current!.value,
                                            text2: inputSubtitleRef.current!.value, };

            if (index === -1) {

                handovers.push( newHandover );

                if ( urlString === 'ef_std_img.jpg' ) {

                    handleSave('new', JSON.stringify( handovers ), false );

                } else {

                    postAddImageForNewInventarMutation( handovers );

                }

                

            } else {

                handovers[index] = newHandover;

                handleSave('edit', JSON.stringify( handovers ), false );

            }
            
            }


    }

    const { mutate: postAddImageForNewInventarMutation } = useMutation({
        mutationFn: () => postAddHandoverImageBlob( urlString, selectedProperty.property_id ),
        onSuccess: (data, variables: Array<HandoverProtocolObj>) => {

            variables[variables.length-1].img = Number(data.blob_id);

            setSelectedHandover( variables[variables.length-1] );

            handleSave('new', JSON.stringify( variables ), false );

        },
        onError: (error) => { console.log(error) },
    });

    const { mutate: fetchImageBlobMutation } = useMutation({
        mutationFn: () => fetchHandoverImageBlob(selectedHandover.img),
        onSuccess: (data) => {

            const blob: {blob_data: string, creation_timestamp: string} = data.obj;

            if (blob.blob_data) {

                if ( blob.blob_data.substring(0, 4) === 'data' ) {
                    setUrlString( blob.blob_data );
                } else if( blob.blob_data.substring(0, 5) === '/user' ) {
                    setUrlString( `https://eazyac-dev.de${blob.blob_data}` );
                }
        
            }

        },
        onError: (error) => { console.log(error) },
    });

    useEffect( () => {

        if( index !== -1 ) {
            fetchImageBlobMutation();
        }

    }, []);

    const { mutate: postUpdateHandoverImageBlobMutation } = useMutation({
        mutationFn: (variables: { blobData: string, blobId: number }) => postUpdateHandoverImageBlob( variables.blobData, variables.blobId ),
        onError: (error) => { console.log(error) },
    });

    const { mutate: postAddHandoverImageBlobMutation } = useMutation({
        mutationFn: ( blobData: string ) => postAddHandoverImageBlob( blobData, selectedProperty.property_id ),
        onSuccess: (data) => {

            const newHandover: HandoverProtocolObj = { ...selectedHandover,
                img: Number(data.blob_id), };

            setSelectedHandover( newHandover );

            const handovers = selectedProperty.property_tp ? JSON.parse( selectedProperty.property_tp ) : [];
            handovers[index] = newHandover;

            handleSave('edit', JSON.stringify( handovers ), true );

        },
        onError: (error) => { console.log(error) },
    });

    const handleNewImage = (imgData: string) => {

        setUrlString( imgData );

        if (index !== -1) {

            if ( selectedHandover.img === null || selectedHandover.img === undefined || selectedHandover.img === -1) {

                postAddHandoverImageBlobMutation( imgData );

            } else {

                postUpdateHandoverImageBlobMutation( { blobData: imgData, blobId: selectedHandover.img } );

            }

        }

    }

    return ( 

        <>
        <ModalView modalViewId="handoverAddEditView" style={{ width: '550px', overflow: 'visible' }} handleClose={ handleCancel }>
            <div className="navigation-view-bar">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">{ index === -1 ? 'Neues Inventar' : 'Inventar bearbeiten' }</div>
                <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <ImageSelectionView handleImgData={ handleNewImage } urlContentString={ urlString } style={ { marginTop: '10px', width: '100px', height: '100px' } }/>
            <InputView title="Titel" style={{ marginTop: '10px' }}><input ref={inputTitleRef} type="text" defaultValue={ selectedHandover.text1 } placeholder="z. B. Küche" /></InputView>
            <InputView title="Untertitel" style={{ marginTop: '10px' }}><input ref={inputSubtitleRef} type="text" defaultValue={ selectedHandover.text2 } placeholder="z. B. Teller" /></InputView>
            <div className="flex center-y" style={{ paddingTop: '10px' }}>
                <div className="flex center-y" style={{ paddingLeft: '5px' }}>Anzahl: { selectedHandover.amount }</div>
                <div className="flex center" style={{ width: '60%' }}>
                    <div onClick={ () => { if (selectedHandover.amount > 0) { setSelectedHandover( prev => ( {...prev, amount: prev.amount -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: selectedHandover.amount === 0 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                    <div onClick={ () => setSelectedHandover( prev => ( {...prev, amount: prev.amount +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                </div>
            </div>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default HandoverAddEditView;