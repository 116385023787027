import { ReactElement, memo, useContext, useEffect, useId, useRef, useState } from "react";
import "../../css/occupancy.css";
import { AlertObj } from "../../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import { OccupancyModulContext } from "../../contexts/OccupancyModulContext";
import MultiCalendar from "./multiCalendarComps/MultiCalendar";
import SingleListCalendar from "./singleListCalendarComps/SingleListCalendar";
import InputView from "../../generalComps/InputView";
import MenuButton from "../../generalComps/MenuButton";
import StayDetailViewContext from "../../contexts/StayDetailViewContext";

const OccupancyModul = () => {

    const { filteredPropertys, isVisibleVerification, loadData, isPending } = useContext( OccupancyModulContext );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputPwdRef = useRef<HTMLInputElement | null>(null);

    useEffect( () => loadData(''), []);

    const ErrorView = ():ReactElement => {

        return(

            <div className="shadowBox" style={{ marginTop: '20px' }}>
                <div className="default-icon">notification_important</div>
                Dieser Aufruf ist ungültig. Wenden Sie sich im Zweifel an den Versender.
            </div>

        );

    }

    const LoadingView = ():ReactElement => {

        return(

            <div className="shadowBox" style={{ marginTop: '20px' }}>
                <div className="default-icon">notification_important</div>
                Daten werden geladen. Bitte warten...
            </div>

        );

    }

    const VerificationView = ():ReactElement => {

        return(

            <div className="shadowBox column" style={{ marginTop: '20px' }}>
                <div className="shadowBox" style={{ marginBottom: '20px' }}>Dieser Bereich ist Passwortgeschützt. Bitte gebe das Passwort ein.</div>
                <InputView title="Passwort">
                    <input ref={inputPwdRef} type="password" placeholder="xxxxxx"/>
                </InputView>
                <MenuButton style={{ marginTop: '20px' }} buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => { loadData( inputPwdRef.current!.value ); } } rightIcon="chevron_right" buttonTitle="Weiter" />
            </div>

        );

    }

    return (
        <>
        { 
        
            isPending ?

            <LoadingView />

            :

            isVisibleVerification ?

            <VerificationView />

            :

            filteredPropertys.length === 0 ?

            <ErrorView />

            :

            <div className="flex column w100prc h100prc" style={{ padding: '10px' }}>
                <StayDetailViewContext>
                { filteredPropertys.length === 1 ? <SingleListCalendar /> : <MultiCalendar /> }
                </StayDetailViewContext>
            </div>

        }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
    );

}
 
export default memo(OccupancyModul);