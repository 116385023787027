import { useContext } from "react";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import MenuButton from "../../generalComps/MenuButton";
import { Stay } from "../../methods/types";
import { emptyProperty } from "../../methods/constants";

const ImportDetailView = () => {

    const { stay, setSelectedAddEditStay, setSelectedAddEditProperty } = useContext(StayDetailViewContext);
    const { setIsVisibleStayDetailsView, setIsVisibleStayAddEditView, filteredPropertys } = useContext(OccupancyContext);

    // const [alertObj, setAlertObj] = useState( new AlertObj() );
    // const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    // const actionAlertId = useId();

    const handleAddStay = () => {

        let selectedProperty = filteredPropertys.find( prop => prop.property_id === stay.stay_property );

        if ( selectedProperty === undefined ) {
            selectedProperty = emptyProperty;
        }

        const newStay: Stay = {

            ...stay,
            stay_id: -1,
            stay_guest: -1,
            stay_status: 0,
            stay_adults: 0,
            stay_children: 0,
            stay_pets: 0,
            stay_property: selectedProperty.property_id,
            stay_checkIn_time: selectedProperty.property_checkIn_time,
            stay_checkOut_time: selectedProperty.property_checkOut_time,
            stay_group: '[]',
            stay_special_price: 0,
            stay_storno_val: 0,
            guest_firstname: '',
            guest_lastname: '',
            guest_company: '',

        }

        setSelectedAddEditProperty( selectedProperty );
        setSelectedAddEditStay( newStay );
        setIsVisibleStayAddEditView( true );
        setIsVisibleStayDetailsView( false );

    }

    return ( 

        <>
        <div className="flex center-y w100prc" style={{ width: "100%" }}>
            <div onClick={ () => setIsVisibleStayDetailsView(false) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
            {/* <div onClick={()=>{}} className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>more_horiz</div> */}
        </div>
        <div className="shadowBox" style={{ display: 'block', marginTop: '10px', textAlign: 'justify' }}>Dieser Aufenthalt ist aktuell nicht in Eazyac angelegt.<br/><br/>Er wird dir angezeigt, weil dein Belegungsplan mit dem iCal-Kalender <span style={{ fontWeight: 'bold' }}>{stay.ical_name!}</span> verknüpft ist.
        Es handelt sich hierbei lediglich um eine visuelle Darstellung, die sich ansonsten innerhalb von Eazyac nicht auswirkt und du damit auch nicht weiter interagieren kannst.
        <br/><br/>Damit diese Visualisierung aus deinem Belegungsplan verschwindet musst du diesen Zeitraum mit einem Eazyac-Aufenthalt überschreiben. Nutze hierzu die unten stehende Schaltfläche.</div>
        <MenuButton onClick={ handleAddStay } leftIcon="nights_stay" buttonTitle="Aufenthalt anlegen" style={{ marginTop: '10px' }} buttonTitleSyle={{ justifyContent: 'center' }}/>
        {/* { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) } */}
        </>

     );
}
 
export default ImportDetailView;