import { ReactElement, useContext, useEffect, useId, useRef, useState } from "react";
import { AlertObj, ConversationObj, MailCreationType, MessageListType } from "../methods/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteAllFiles, fetchKorres, postCopyFilesToMailAttachment, postDeleteKorres, postMailCreateServerFiles } from "../methods/http.korres.methods";
import IFrameView from "../generalComps/IFrameView";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import { createMailSeperatorArray } from "../methods/standard.methods";
import MailCreationBackView from "./MailCreationBackView";
import { fetchPrefilledTemplateByType } from "../methods/http.templates.methods";
import MailGuestDataView from "./MailGuestDataView";

type Props = {

    obj: ConversationObj;

}

const MailCard = ( { obj }: Props ) => {

    const { setSeperatorObj, messageListType, mailContentObjRef, templatesArrRef, stayIdRef } = useContext(MessageDetailViewContext);

    const queryClient = useQueryClient();

    const textBoxRef = useRef<HTMLDivElement>(null);
    const [isVisibleUnfoldIcon, setIsVisibleUnfoldIcon] = useState(false);
    const [isVisibleGuestDataView, setIsVisibleGuestDataView] = useState(false);
    const [isUnfoldText, setIsUnfoldText] = useState(false);
    const [attachmentArr, setAttachmentArr] = useState<Array<{file_name: string, file_url: string}>>([]);
    const [isVisibleAttachmentView, setIsVisibleAttachmentView] = useState(false);
    const [isVisibleMailCreationView, setIsVisibleMailCreationView] = useState(false);
    const attachmentSrcUrlRef = useRef<{file_name: string, file_url: string}>( {file_name: '', file_url: ''} );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const msg = obj.korres_body;
    let bodyText = '';

    if (msg) {

        if (msg.length === 0) {

            bodyText = 'Es wurde keine weitere Nachricht hinterlassen';

        } else {

            if (obj.korres_art === 11) {
                            
                const splitted = msg.split('|');
                
                if (splitted.length > 1) {
                    bodyText = splitted[1];
                } else {
                    bodyText = msg;
                }
                
            } else {
                
                bodyText = msg
                .replace(/<style([\s\S]*?)<\/style>/gi, ' ')
                .replace(/<script([\s\S]*?)<\/script>/gi, ' ');
                /*.replace(/(?:\r\n|\r|\n)/g, '<br>'); //HtmlToAttributedString*/
                
            }

        }

    } else {

        bodyText = 'Es wurde keine weitere Nachricht hinterlassen';

    }

    const dateTimeParts = obj.creation_timestamp.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
    
    const dateTimePartsNumber = dateTimeParts.map( part => Number(part) );
    dateTimePartsNumber[1]--; // monthIndex begins with 0 for January and ends with 11 for December so we need to decrement by one

    const maildate  = new Date( dateTimePartsNumber[0], dateTimePartsNumber[1], dateTimePartsNumber[2], dateTimePartsNumber[3], dateTimePartsNumber[4], dateTimePartsNumber[5] );
    const options: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
    const options1: Intl.DateTimeFormatOptions = { hour:  "2-digit", minute: "2-digit" };

    let fullname = obj.guest_firstname + " " + obj.guest_lastname;
    let company = obj.guest_company;

    //let bubbleStyle = 'style = "width: 70%; float: left;"';

    if (obj.korres_art < 10) {

        fullname = 'Du';

    } else {

        //bubbleStyle = 'style = "width: 70%; float: right;"';

        if (obj.guest_title === 'Firma') {

            fullname = company;
            company = '';

        } else if (!obj.guest_firstname && !obj.guest_lastname && !obj.guest_company) {

            fullname = obj.korres_from.match(/<(\S*)>/)![1];

        }

    }

    const handleAttachmentOnClick = () => {

        if (attachmentArr.length === 0) {

            postMailCreateServerFilesMutation();

        } else {

            setAttachmentArr( [] );

        }

    }

    useEffect( () => {

        setIsVisibleUnfoldIcon( ( textBoxRef.current !== null && textBoxRef.current.scrollHeight > 160 ) );

    }, [])

    const { mutate: postMailCreateServerFilesMutation } = useMutation({
        mutationFn: () => postMailCreateServerFiles(obj.korres_id),
        onSuccess: (data) => {

            setAttachmentArr( data.obj );
            
        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: fetchPrefilledTemplateByTypeMutation } = useMutation({
        mutationFn: () => fetchPrefilledTemplateByType(obj.korres_stay, 8),
        onSuccess: (data) => {

            templatesArrRef.current = data.obj ? data.obj : [];
            
            setIsVisibleMailCreationView(true);

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const ActionBar = (): ReactElement => {

        return(

            <div className="flex center" style={{ gap: '10px', padding: '10px', width: '70%', marginLeft: obj.korres_art < 10 ? '0' : '30%', marginBottom: '30px' }}>
                { obj.korres_art > 9 &&
                <>
                <div onClick={ () => handleReplyOnClick(obj, MailCreationType.Reply) } title="Antworten" className="clickable-icon" style={{ fontSize: '1.5em' }}>reply</div>
                <div onClick={ () => handleReplyOnClick(obj, MailCreationType.ReplyAll) } title="Allen Antworten" className="clickable-icon" style={{ fontSize: '1.5em' }}>reply_all</div>
                </>
                }
                <div onClick={ () => handleReplyOnClick(obj, MailCreationType.Forward) } title="Weiterleiten" className="clickable-icon" style={{ fontSize: '1.5em' }}>forward</div>
                <div onClick={ handleDeleteMail } title="Löschen" className="clickable-icon" style={{ fontSize: '1.5em' }}>delete</div>
            </div>

        )

    }

    const handleReplyOnClick = (obj: ConversationObj, mailCreationType: MailCreationType) => {

        let to: Array<string> = [];
        let cc: Array<string> = [];
        let subject = '';
        let body = '';

        let copyFiles = false;

        if (mailCreationType === MailCreationType.Reply) {

            subject = `Re: ${obj.korres_subject ? obj.korres_subject : ''}`;
            
            const address = obj.korres_from.match(/<(\S*)>/) ? obj.korres_from.match(/<(\S*)>/)![1] : null;

            if (address !== null) {
                to.push(address);
            }

        } else if (mailCreationType === MailCreationType.ReplyAll) {

            subject = `Re: ${obj.korres_subject ? obj.korres_subject : ''}`;

            const address = obj.korres_from.match(/<(\S*)>/) ? obj.korres_from.match(/<(\S*)>/)![1] : null;

            if (address !== null) {
                to.push(address);
            }

            if (obj.korres_art === 12) {

                const addressArr = JSON.parse(obj.korres_cc);

                addressArr.forEach( (addressObj:{address: string}) => {

                    if (addressObj && addressObj.address.length > 0) {
                        cc.push(addressObj.address)
                    }

                });

            } else {

                const addressArr = obj.korres_cc.split(';');

                addressArr.forEach( address => {
                    cc.push( address );
                });

            }
            
        } else if (mailCreationType === MailCreationType.Forward) {

            subject = `Fwd: ${obj.korres_subject ? obj.korres_subject : ''}`;
            body = textBoxRef.current?.textContent ? textBoxRef.current.textContent : '';

            copyFiles = true;
            
        }

        mailContentObjRef.current.to = to;
        mailContentObjRef.current.cc = cc;
        mailContentObjRef.current.subject = subject;
        mailContentObjRef.current.body = body;

        stayIdRef.current = obj.korres_stay;

        postDeleteFolderFilesMutation(copyFiles);

    }

    const handleDeleteMail = () => {

        const funcArr = [{title: 'Löschen', handler: () => postDeleteKorresMutation() }, {title: 'Abbrechen', handler: () => setAlertObj( new AlertObj() )}]

        setAlertObj( new AlertObj(true, 'Korrespondenz', 'Möchtest du diese Nachricht wirklich löschen?', funcArr) );

    }

    const { mutate: postDeleteKorresMutation } = useMutation({
        mutationFn: () => postDeleteKorres(obj.korres_id),
        onSuccess: () => {

            fetchKorresMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postDeleteFolderFilesMutation } = useMutation({
        mutationFn: (copyFiles: boolean) => deleteAllFiles(),
        onSuccess: (_, variables) => {

            if (variables) {
                postCopyFilesToMailAttachmentMutation();
            } else {
                fetchPrefilledTemplateByTypeMutation();
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postCopyFilesToMailAttachmentMutation } = useMutation({
        mutationFn: () => postCopyFilesToMailAttachment(obj.korres_id),
        onSuccess: () => {

            fetchPrefilledTemplateByTypeMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: fetchKorresMutation } = useMutation({
        mutationFn: () => fetchKorres(obj.korres_stay),
        onSuccess: (data) => {

            setSeperatorObj( createMailSeperatorArray(data.obj) );

            setAlertObj( new AlertObj() );

            if (messageListType === MessageListType.Unread) {
                queryClient.invalidateQueries( { queryKey: ['fetchNewMails'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchMails'] } );
            }


        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 

        <>
        <div className={ `shadowBox mail-card${ obj.korres_art < 10 ? '' : ' answer' }` }>
            <div className="flex center-y w100prc color-black" style={{ height: '30px', fontWeight: 'bold' }}>{ obj.korres_subject === null ? '< kein Betreff verfügbar >' : obj.korres_subject }</div>
            <div ref={textBoxRef} className="flex column w100prc" style={{ padding: '20px 0', maxHeight: !isUnfoldText ? '160px' : 'none' }} dangerouslySetInnerHTML={{__html: bodyText}}></div>
            { isVisibleUnfoldIcon && <div className="flex center w100prc"><div onClick={ () => setIsUnfoldText( prevVal => !prevVal ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>{ isUnfoldText ? 'unfold_less' : 'unfold_more' }</div></div> }
            {
                obj.korres_art === 11 &&
                <div onClick={ () => setIsVisibleGuestDataView(true) } className="flex center cursor-pointer" style={{ marginBottom: '10px', padding: '5px', border: '1px solid gray', borderRadius: '5px' }}>
                    <div className="default-icon">open_in_new</div>
                    <div className="flex center">Gästedaten anzeigen</div>
                </div>
            }
            <div className="flex center-y w100prc color-lightblack" style={{ fontStyle: 'oblique', fontSize: '0.9rem' }}>{fullname}, am {maildate.toLocaleDateString('de-DE', options)} um {maildate.toLocaleTimeString('de-DE', options1)} Uhr</div>
            { obj.files_count > 0 && <div className="flex center-y" style={{ padding: '10px 0', fontSize: '0.8rem' }}><div onClick={ handleAttachmentOnClick } className="clickable-icon" style={{ fontSize: '1.4em' }}>attachment</div>{obj.files_count}</div> }
            <div className="flex center-y w100prc" style={{ gap: '5px' }}>
            { attachmentArr.map( file => {

                return(

                    <div onClick={ () => { attachmentSrcUrlRef.current = file; setIsVisibleAttachmentView( true ); } } key={ `${file.file_url}${file.file_name}` } className="anlage-btn">{ file.file_name }</div>

                )

            }) }
            </div>
        </div>
        <ActionBar />
        { isVisibleAttachmentView && createPortal( <IFrameView style={{ width: '800px', height: '800px' }} fileName={ attachmentSrcUrlRef.current.file_name } fileUrl={ attachmentSrcUrlRef.current.file_url } handleClose={ () => setIsVisibleAttachmentView( false ) } />, document.body ) }
        { isVisibleMailCreationView && createPortal( <MailCreationBackView style={{ width: '800px', height: '880px', maxHeight: 'none' }} handleClose={ () => setIsVisibleMailCreationView( false ) } />, document.body ) }
        { isVisibleGuestDataView && createPortal( <MailGuestDataView conversationObj={obj} style={{ width: '500px' }} handleClose={ () => setIsVisibleGuestDataView(false) } />, document.body ) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default MailCard;