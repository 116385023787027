import { memo, ReactNode } from "react";
import '../css/inputview.css'

type Props = {
    title: string;
    style?: React.CSSProperties | undefined;
    children: ReactNode;
    popUps?: ReactNode | undefined;
    errorClass?: string;
}

const TextareaView = ( { title, style, children, popUps, errorClass }:Props ) => {

    errorClass = !errorClass ? '' : errorClass;

    return ( 
        <div className={ `input-template-style${errorClass}` } style={style}>
            <div className="flex wrap w100prc" style={{ height: '40px', overflow: 'visible', borderBottom: '1px solid black' }}>
                <div id="title" className="h100prc" style={{marginRight: '30px'}}>{ title }</div>
                <div className="flex center" style={{ gap: '20px'}}>{popUps}</div>
            </div>
            <div className = "flex center w100prc h100prc" style = {{gap: '10px', overflowX: 'scroll', overflowY: 'clip', padding: '2px 10px', }}>
                {children}
            </div>
        </div>
     );
}
 
export default memo(TextareaView);