import { ReactElement, useState } from "react";
import { TemplatesObj, ViewState } from "../methods/types";
import { defaultArr, linkArr } from "../methods/constants";

type Props = {
    template: TemplatesObj;
    handleOnClick: (template: TemplatesObj, state: ViewState) => void;
}

const ConversationTemplateCard = ( { template, handleOnClick }:Props ) => {

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const linkName = linkArr.find( link => link.art === Number(template.template_mailArt) ) === undefined ? '' : linkArr.find( link => link.art === Number(template.template_mailArt) )!.name;
        
    let defaultName = defaultArr.find( defaultObj => defaultObj.art === template.template_isStandardFor ) === undefined ? '' : defaultArr.find( defaultObj => defaultObj.art === template.template_isStandardFor )!.name;

    if (defaultName.length !== 0) {
        defaultName = 'Standard für ' + defaultName;
    }

    const Menu = (): ReactElement => {

        return(
 
        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

    );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        handleOnClick( template, state);

        setIsVisibleMenu(false);

        e.stopPropagation();

    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 

        <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } key={template.template_type.toString() + template.template_title + template.template_mailArt} className="shadowBox w100prc list-card standard-fontsize" style={{ maxHeight: 'none', height: 'auto' }}>
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            {
                !isVisibleMenu ?
                <>
                <div className="flex center-y" style={{ fontWeight: 'bold' }}>{template.template_title}</div>
                <div className="flex center-y truncate-text" style={{ padding: '10px 0' }}>{template.template_message}</div>
                <div className="flex center-y w100prc color-lightblack" style={{ fontSize: '0.9em', paddingTop: '10px' }}>
                    <div className="flex center-y" style={{ width: 'calc(100% / 3)' }}>
                        <div className="default-icon flex center" style={{ width: '40px' }}>language</div>
                        <div className="flex center-y w100prc">{template.template_language}</div>
                    </div>
                    <div className="flex center-y" style={{ width: 'calc(100% / 3)' }}>
                        <div className="default-icon flex center" style={{ width: '40px' }}>link</div>
                        <div className="flex center-y w100prc">{linkName}</div>
                    </div>
                    {
                        template.template_isStandardFor !== 101 &&
                        <div className="flex center-y" style={{ width: 'calc(100% / 3)' }}>
                            <div className="default-icon flex center" style={{ width: '40px' }}>push_pin</div>
                            <div className="flex center-y w100prc">{defaultName}</div>
                        </div>
                    }
                </div>
                </> :
                <Menu />
            }
        </div>

     );
}
 
export default ConversationTemplateCard;