import { createContext, MutableRefObject, ReactNode, useRef, useState } from "react";
import { SettingsViewType } from "../methods/types";

type Props = {
    children: ReactNode;
}

type SubscriptionObj = {

    item_name: string;
    item_id: string;
    item_plan_id: string;
    item_price: number;
    item_currency: string;
    subscription_id: string;

}

export type InvoiceObj = {

    user_title: number;
    user_firstname: string;
    user_lastname: string;
    user_company: string;
    street: string;
    zip: string;
    city: string;
    country: string;

}

export enum PaypalInvoiceViewType {
    InvoiceInputView,
    InvoiceOverviewView,
}

type ContextType = {
    isVisibleSettingsView: boolean,
    setIsVisibleSettingsView: React.Dispatch<React.SetStateAction<boolean>>,
    isVisiblePaypalInvoiceView: boolean,
    setIsVisiblePaypalInvoiceView: React.Dispatch<React.SetStateAction<boolean>>, 
    settingsViewType: SettingsViewType,
    setSettingsViewType: React.Dispatch<React.SetStateAction<SettingsViewType>>,
    invoiceViewType: PaypalInvoiceViewType,
    setInvoiceViewType: React.Dispatch<React.SetStateAction<PaypalInvoiceViewType>>,
    lastSettingsViewTypeRef: MutableRefObject<SettingsViewType>,
    subscriptionObjRef: MutableRefObject<SubscriptionObj>,
    invoiceObjRef: MutableRefObject<InvoiceObj>,
};

export const SettingsViewContext = createContext<ContextType>( { 
    isVisibleSettingsView: false,
    setIsVisibleSettingsView: () => {},
    isVisiblePaypalInvoiceView: false,
    setIsVisiblePaypalInvoiceView: () => {},
    settingsViewType: SettingsViewType.MainMenu,
    setSettingsViewType: () => {},
    invoiceViewType: PaypalInvoiceViewType.InvoiceInputView,
    setInvoiceViewType: () => {},
    lastSettingsViewTypeRef: { current: SettingsViewType.MainMenu },
    subscriptionObjRef: { current: {item_currency: '', item_id: '', item_name: '', item_plan_id: '', item_price: 0, subscription_id: ''} },
    invoiceObjRef: { current: {user_title: 0, user_firstname: '', user_lastname: '', user_company: '', street: '', zip: '', city: '', country: ''} },
} );

const SettingsViewContextProvider = ({ children }: Props) => {

    const [isVisibleSettingsView, setIsVisibleSettingsView] = useState(false);
    const [settingsViewType, setSettingsViewType] = useState<SettingsViewType>( SettingsViewType.MainMenu );
    const [invoiceViewType, setInvoiceViewType] = useState<PaypalInvoiceViewType>( PaypalInvoiceViewType.InvoiceInputView );
    const lastSettingsViewTypeRef = useRef<SettingsViewType>( SettingsViewType.MainMenu );
    const subscriptionObjRef = useRef<SubscriptionObj>( {item_currency: '', item_id: '', item_name: '', item_plan_id: '', item_price: 0, subscription_id: ''} );
    const invoiceObjRef = useRef<InvoiceObj>( {user_title: 0, user_firstname: '', user_lastname: '', user_company: '', street: '', zip: '', city: '', country: ''} );
    const [isVisiblePaypalInvoiceView, setIsVisiblePaypalInvoiceView] = useState(false);

    return ( 
        <SettingsViewContext.Provider value={ { isVisibleSettingsView, setIsVisibleSettingsView, isVisiblePaypalInvoiceView, setIsVisiblePaypalInvoiceView, setSettingsViewType, invoiceViewType, setInvoiceViewType, settingsViewType, lastSettingsViewTypeRef, subscriptionObjRef, invoiceObjRef } }>
            {children}
        </SettingsViewContext.Provider>
     );
}
 
export default SettingsViewContextProvider;