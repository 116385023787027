import { useId, useRef, useState } from "react";
import ActionAlert from "../../generalComps/ActionAlert";
import ModalView from "../../generalComps/ModalView";
import { AlertObj, ImportUrlObj } from "../../methods/types";
import InputView from "../../generalComps/InputView";
import { validURL } from "../../methods/standard.methods";

type Props = {
    importUrlObj: { obj: ImportUrlObj, index: number | null };
    handleCancel: () => void;
    handleSave: ( obj: ImportUrlObj, index: number | null ) => void;
}

const CalendarImportAddEditView = ( { importUrlObj, handleCancel, handleSave }:Props ) => {

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputTitleRef = useRef<HTMLInputElement | null>(null);
    const inputLinkRef = useRef<HTMLInputElement | null>(null);

    const handleSaveOnClick = () => {
        
        if ( inputTitleRef.current!.value.length === 0 ) {
        
            setAlertObj( new AlertObj( true, 'Achtung', 'Vergebe bitte eine eindeutige Bezeichnung für den importierten Kalender.', standardAlertButton ) );
            
        } else if ( !validURL( inputLinkRef.current!.value ) ) {
    
            setAlertObj( new AlertObj( true, 'Achtung', 'Es scheint sich um keine gültige URL zu handeln. Bitte überprüfe deine Eingabe.', standardAlertButton ) );
            
        } else {

            const newImportUrlObj = { ...importUrlObj.obj, name: inputTitleRef.current!.value, link: inputLinkRef.current!.value };

            handleSave( newImportUrlObj, importUrlObj.index);

        }

    }

    return ( 

        <>
        <ModalView modalViewId="importUrlAddEditView" style={{ width: '550px', overflow: 'visible' }} handleClose={ handleCancel }>
            <div className="flex center-y w100prc">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Import-URL</div>
                <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <InputView title="Bezeichnung" style={{ marginTop: '10px' }}><input ref={inputTitleRef} type="text" defaultValue={ importUrlObj.obj.name } placeholder="z. B. Booking.com" /></InputView>
            <InputView title="Import-URL (iCal)" style={{ marginTop: '10px' }}><input ref={inputLinkRef} type="text" defaultValue={ importUrlObj.obj.link } placeholder="URL" /></InputView>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default CalendarImportAddEditView;