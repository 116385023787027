import { DefaultFetchResult } from "./types";

export class LoginResult {
    login_success: boolean = false;
    alert?: boolean = false;
    response_title: string = '';
    response_text: string = '';
    session_id?: string = '';
}

export class AuthResult {
    authentificated: boolean = false;
    session_id?: string
}

export const postLogin = async (email: string, pwd: string) => {

    const data = new URLSearchParams();

    data.append('user_email', email);
    data.append('user_password', pwd);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/login/login.php`, {
            method: 'POST',
            body: data,
            'credentials': 'include'
          }
      )

    const content: LoginResult = await result.json();
   
    return content;

}

export const postLogout = async () => {

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/login/logout.php`, {
            method: 'GET',
            'credentials': 'include'
          }
      )

    return await result;

}

export const postRegister = async (title: number, firstname: string, lastname: string, email: string, pwd: string) => {

    const data = new URLSearchParams();

    data.append('title', title.toString());
    data.append('firstname', firstname);
    data.append('lastname', lastname);
    data.append('user_email', email);
    data.append('user_password', pwd);
    data.append('user_birthday', '0000-00-00');

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/login/register.php`, {
            method: 'POST',
            body: data
          }
      )

    const content: LoginResult = await result.json();
    return content;

}

export const postEmailVerification = async (token: string) => {

    const data = new URLSearchParams();

    data.append('token', token);

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/login/verify_email.php`, {
            method: 'POST',
            body: data,
            'credentials': 'include'
          }
      )

    const content: DefaultFetchResult = await result.json();
   
    return content;

}

export const checkAuth = async () => {
    
    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/helper/check_authentification.php`,
        {
        method: "GET", 
       'credentials': 'include',
        }
      )

    const content: AuthResult = await result.json();
    return content;

}