import PopUp from "../../generalComps/PopUp";
import { TemplatesObj } from "../../methods/types";

type Props = {
    templatesArr: Array<TemplatesObj>;
    handleSelectedTemplate: (e: React.SyntheticEvent, template: TemplatesObj) => void;
    handleClose: () => void;
}

const TemplatesPopUpView = ( { templatesArr, handleSelectedTemplate, handleClose }:Props ) => {

    return ( 
        <PopUp style={{ marginTop: '30px', height: 'auto', width: '250px' }} handleClosePopUp={handleClose}>
        {
        templatesArr.map((template, index) => {
            return (
            <div
                className="lbl-container"
                onClick={(e) => handleSelectedTemplate(e, template) }
                key={index}
            >
                <div className="lbl">
                    <div className="flex center-y w100prc">{`${template.template_title!} | ${template.template_language}`}</div>
                    <div className="flex center-y sub-lbl w100prc color-lightblack">{template.template_message}</div>
                </div>
            </div>
            );
        })
        }
        </PopUp>
     );
}
 
export default TemplatesPopUpView;