import { CSSProperties, SyntheticEvent, memo, useContext, useEffect, useMemo, useState } from "react";
import { booking_cell_color } from "../../methods/constants";
import { getFullname } from "../../methods/standard.methods";
import { Stay } from "../../methods/types";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import { useMutation } from "@tanstack/react-query";
import { fetchStay } from "../../methods/http.stays.methods";

type Props = {
    stay: Stay;
    style: CSSProperties;
}

const MultiCalendarBarCard = ( { stay, style }:Props ) => {

    //const [isVisibleDetails, setIsVisibleDetails] = useState(false);
    const { isVisibleStayDetailsView, setIsVisibleStayDetailsView, stays } = useContext(OccupancyContext);
    const stayDetailViewContext = useContext(StayDetailViewContext);

    const fullnameObj = useMemo( () => getFullname( stay.guest_title!, stay.guest_firstname ? stay.guest_firstname : '', stay.guest_lastname ? stay.guest_lastname : '', stay.guest_company ? stay.guest_company : ''),
    [stay.guest_title, stay.guest_firstname, stay.guest_lastname, stay.guest_company] );

    const { mutate: fetchStayMutation } = useMutation({
        mutationFn: () => fetchStay(stay.stay_id),
        onSuccess: (data) => {

            stayDetailViewContext.setStay( data.obj ); 

            setIsVisibleStayDetailsView( true );

        }
    })

    const barTitle = useMemo( () => {

        if ( stay.stay_status === 5 ) {
            
            return stay.stay_block_title!; //Wenn Aufenthalt ein Block

        } else if ( stay.stay_status === 6 ) {
            
            return `${stay.ical_name!} -> ${stay.stay_block_title!}`; //Wenn Aufenthalt ein Import

        } else {
            return fullnameObj.fullname === ' Firma' ? fullnameObj.company : fullnameObj.fullname.replace('Frau ', '').replace('Herr ', '');
        }

    }, [stays])

    const handleOpenStay = (e: SyntheticEvent) => {

        if ( stay.stay_status < 5 ) {

            fetchStayMutation();

        } else {

            stayDetailViewContext.setStay( stay );

            setIsVisibleStayDetailsView( true );

        }
        
        e.stopPropagation();

    }

    return ( 

        <>
        <div onClick={ handleOpenStay } title={barTitle} style={style} key={stay.stay_id} className={`bar${booking_cell_color[stay.stay_status]}${ ( isVisibleStayDetailsView && stay.stay_id === stayDetailViewContext.stay.stay_id ) ? ' clicked' : '' }`}>{ barTitle }</div>
        </>

     );
}
 
export default memo(MultiCalendarBarCard);