import { CSSProperties, useContext } from "react";
import { PropertysViewContext } from "../contexts/PropertysMainContext";
import ImageView from "../generalComps/ImageView";
import PropertyTimeSelection from "./PropertyTimeSelection";
import { DateSelectType } from "../generalComps/dateSelectComps/DateSelect";

type Props = {
    style?: CSSProperties | undefined;
}

const PropertyDetailViewHeader = ( { style }: Props ) => {

    const { selectedProperty } = useContext(PropertysViewContext);

    const imageUrl = selectedProperty.user_folder === undefined ? '' : `https://eazyac-dev.de/user_data/${selectedProperty.user_folder!}/prop_images/${selectedProperty.property_id!}.png`;

    return ( 

        <div className="flex column shadowBox" style={style}>
                <div className="flex center-y w100prc" style={{fontSize: '1.2em'}}>
                    <div className="title truncate-text">{ `${selectedProperty.property_name} | ${selectedProperty.property_art}` }</div>
                </div>
            <div className="flex center-y w100prc standard-fontsize" style={{padding: '10px 0 10px 0'}}>
                <div className="flex center h100prc" style={{width: '50px', marginRight: '10px'}}><ImageView src={imageUrl} width={50} height={50} alt={selectedProperty.property_name} /></div>
                <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 0px', width: '35%'}}>
                    <div className="flex center-y">{ selectedProperty.address_street }</div>
                    <div className="flex center-y">{ selectedProperty.address_zip } { selectedProperty.address_city }</div>
                    <div className="flex center-y color-lightblack">{ selectedProperty.address_country }</div>
                </div>
                <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 10px', width: '65%'}}>
                    <div className="flex center-y standard-fontsize" style={{padding: '5px 0'}}>
                        <div title="Quadratmeter" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>texture</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{ selectedProperty.property_squaremeters }</div>
                        </div>
                        <div title="Anzahl Etagen" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>domain</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{ selectedProperty.property_levels }</div>
                        </div>
                        <div title="Anzahl Zimmer" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>meeting_room</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{ selectedProperty.property_bedroom + selectedProperty.property_livingroom }</div>
                        </div>
                        <div title="Anzahl Küchen" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>kitchen</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{ selectedProperty.property_kitchen }</div>
                        </div>
                        <div title="Anzahl Badezimmer" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>hot_tub</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{ selectedProperty.property_bathroom }</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex center w100prc standard-fontsize" style={{gap: '10px', overflow: 'visible', marginTop: '15px', }}>
                <PropertyTimeSelection selectionType={ DateSelectType.CheckIn } />
                <PropertyTimeSelection selectionType={ DateSelectType.CheckOut } />
            </div>
        </div>

     );
}
 
export default PropertyDetailViewHeader;