import { useState } from "react";
import SalutationSelect from "../generalComps/SalutationSelect";
import { AlertObj, CNViewType, Contact, InfoWidgetObj, Guest, Note, Salutation, DefaultFetchResult } from "../methods/types";
import InputView from "../generalComps/InputView";
import ContactNoteView from "../generalComps/ContactNoteView";
import RateBar from "../generalComps/RateBar";
import ActionAlert from "../generalComps/ActionAlert";
import InfoWidget from "../generalComps/InfoWidget";
import { postAddGuest, postUpdateGuest } from "../methods/http.guests.methods";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";

type Props = {

    guest: Guest;
    handleCancel: () => void;
    handleSave: (type: string, guest?: Guest) => void;

}

const GuestAddEditView = ( { guest, handleCancel, handleSave }: Props ) => {

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const [tempGuest, setTempGuest] = useState(guest);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const [contactArr, setContactArr] = useState( tempGuest.guest_contact ? JSON.parse(tempGuest.guest_contact!) : [] );
    const [noteArr, setNoteArr] = useState( tempGuest.guest_note ? JSON.parse(tempGuest.guest_note!) : [] );

    const bdayValue = tempGuest.guest_birthday! === '0000-00-00' || tempGuest.guest_birthday! === '1500-01-01' ? new Date().toLocaleDateString('fr-ca') : new Date(tempGuest.guest_birthday!).toLocaleDateString('fr-ca');
    const bdayToggle = () => {

        if (tempGuest.guest_birthday! && tempGuest.guest_birthday! !== '0000-00-00' && tempGuest.guest_birthday! !== '1500-01-01') {
            return true;
        } else {
            return false;
        }

    }
    const handleBdayValue = () => {

        if (bdayToggle()) {
            setTempGuest( ( prevGuest => ({ ...prevGuest, guest_birthday: '0000-00-00' }) ) );
        } else {
            setTempGuest( ( prevGuest => ({ ...prevGuest, guest_birthday: new Date().toLocaleDateString('fr-ca') }) ) );
        }

    }

    const handleUpdateCNData = (updatedArr: Array<Contact | Note>, type: CNViewType) => {

        if (type === CNViewType.MailAndPhone) {
            setContactArr( updatedArr );
            setTempGuest( ( prevGuest => ({ ...prevGuest, guest_contact: JSON.stringify( updatedArr ) }) ) );
        } else {
            setNoteArr (updatedArr );
            setTempGuest( ( prevGuest => ({ ...prevGuest, guest_note: JSON.stringify( updatedArr ) }) ) );
        }

    }

    const handleNewRateValue = (newRateValue: number) => {

        setTempGuest( prevGuest => ({ ...prevGuest, guest_rate: newRateValue }));

        if (newRateValue === 0) {
            const alertButtons = Array( {title: 'Blacklist', handler: handleAddBlacklist}, {title: 'Abbrechen', handler: ()=>{ setAlertObj( new AlertObj() ) }}, );
            setAlertObj( new AlertObj(true, 'Blacklist', 'Möchtest du diesen Gast zur Blacklist hinzufügen?', alertButtons, ) );
        } else {
            
            if (tempGuest.guest_blacklist === 1) {

                setTempGuest( ( prevGuest => ({ ...prevGuest, guest_blacklist: 0 }) ) );

                setInfoWidgetObj( new InfoWidgetObj(true, 'Von Blacklist entfernt.') );

            }

        }

    }

    const handleAddBlacklist = () => {

        setTempGuest( ( prevGuest => ({ ...prevGuest, guest_blacklist: 1 }) ) );

        setInfoWidgetObj( new InfoWidgetObj(true, 'Zur Blacklist hinzugefügt.') );

        setAlertObj( new AlertObj() );

    }

    const handleSaveGuest = () => {

        if (( Salutation[tempGuest.guest_title as keyof typeof Salutation] != 2 && Salutation[tempGuest.guest_title as keyof typeof Salutation] != 3) && (tempGuest.guest_firstname?.length == 0 || tempGuest.guest_lastname?.length == 0)) {

            setAlertObj( new AlertObj(true, 'Daten unvollständig', 'Bitte trage einen Vor- und Nachnamen ein.', standardAlertButton,) );
    
        }else if (Salutation[tempGuest.guest_title as keyof typeof Salutation] == 2 && tempGuest.guest_lastname?.length == 0) {
            
            setAlertObj( new AlertObj(true, 'Daten unvollständig', 'Bitte trage einen Nachnamen ein.', standardAlertButton,) );
            
        } else if (Salutation[tempGuest.guest_title as keyof typeof Salutation] == 3 && tempGuest.guest_company?.length == 0) {
            
            setAlertObj( new AlertObj(true, 'Daten unvollständig', 'Bitte trage eine Firma ein.', standardAlertButton,) );
    
        } else {

            if (tempGuest.guest_id === -1) {

                postAddGuestMutation('new');

            } else {

                postUpdateGuestMutation('update');

            }

        }

    }

    const handlePostSuccess = (data: DefaultFetchResult, variables: any) => {

        if ( data && data.session === "expired" ) {
            navigate("login");
        } else if ( data && data.success  ) {

            let newGuest = tempGuest;

            if (variables === 'new') {

                newGuest = {...tempGuest, guest_id: Number(data.guest_id!)}

            }

            queryClient.invalidateQueries({queryKey: ['fetchGuests']});

            handleSave(variables, newGuest);
    
        } else {
    
            setAlertObj( new AlertObj(true, 'Fehler', 'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.', standardAlertButton,) );
    
        }

    }

    const { mutate: postAddGuestMutation } = useMutation({
        mutationFn: () => postAddGuest(tempGuest),
        onSuccess: handlePostSuccess,
    })

    const { mutate: postUpdateGuestMutation } = useMutation({
        mutationFn: () => postUpdateGuest(tempGuest),
        onSuccess: handlePostSuccess,
    })
   
    return ( 
        <>
        <div className="navigation-view-bar" style={{ width: "100%" }}>
          <div onClick={handleCancel} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
          <div className="main-title">Gastdaten</div>
          <div onClick={handleSaveGuest} className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>check_circle</div>
        </div>

        <SalutationSelect selection={["Herr", "Frau", "Familie", "Firma"]} selectedItem={Salutation[tempGuest.guest_title! as keyof typeof Salutation]} handleSelection={(index) => { setTempGuest( prevGuest => ({...prevGuest, guest_title: Salutation[index]}) ) } } />

        <div className="input-wrapper">
            <InputView title="Vorname" style={{ width: '40%', opacity: (Salutation[tempGuest.guest_title as keyof typeof Salutation] >= 2 ? '0.5' : '1.0') }}><input onChange={(e) => setTempGuest( prevGuest => ({...prevGuest, guest_firstname: e.target.value}))} type="text" value={tempGuest.guest_firstname} placeholder="z. B. Max" disabled={ (Salutation[tempGuest.guest_title as keyof typeof Salutation] >= 2 ? true : false) }/></InputView>
            <InputView title="Nachname" style={{ width: '40%', opacity: (Salutation[tempGuest.guest_title as keyof typeof Salutation] > 2 ? '0.5' : '1.0') }}><input onChange={(e) => setTempGuest( prevGuest => ({...prevGuest, guest_lastname: e.target.value}))} type="text" value={tempGuest.guest_lastname} placeholder="z. B. Mustermann" disabled={ (Salutation[tempGuest.guest_title as keyof typeof Salutation] > 2 ? true : false) }/></InputView>
            <InputView title="Geburtsdatum" style={{ width: '20%' }}>
                <div onClick={ handleBdayValue } className="clickable-icon" style={{ position: 'absolute', right: '0', top: '0', padding: '5px'}}>{ bdayToggle() ? 'toggle_on' : 'toggle_off' }</div>
                <input onChange={(e) => setTempGuest( prevGuest => ({...prevGuest, guest_birthday: e.target.value}))} type="date" value={ bdayValue } max={ new Date().toLocaleDateString('fr-ca') } placeholder="DD.MM.YYYY" disabled={!bdayToggle()}/>
            </InputView>
        </div>

        <InputView title="Firmenname" style={{ marginTop: '10px' }}><input onChange={(e) => setTempGuest( prevGuest => ({...prevGuest, guest_company: e.target.value}))} type="text" value={tempGuest.guest_company} placeholder="z. B. Unreal GmbH" /></InputView>

        <InputView title="Straße & Hausnummer" style={{ marginTop: '40px' }}><input onChange={(e) => setTempGuest( prevGuest => ({...prevGuest, guest_street: e.target.value}))} type="text" value={tempGuest.guest_street} placeholder="z. B. Musterweg 7" /></InputView>
        <div className="flex center-y w100prc" style={{ marginTop: '10px', gap: '20px', padding: '3px 0' }}>
            <InputView title="Postleitzahl" style={{ width: '30%' }}><input onChange={(e) => setTempGuest( prevGuest => ({...prevGuest, guest_zip: e.target.value}))} type="text" value={tempGuest.guest_zip} placeholder="z. B. 88630" /></InputView>
            <InputView title="Wohnort" style={{ width: '70%' }}><input onChange={(e) => setTempGuest( prevGuest => ({...prevGuest, guest_city: e.target.value}))} type="text" value={tempGuest.guest_city} placeholder="z. B. Musterstadt" /></InputView>
        </div>
        <InputView title="Land" style={{ marginTop: '10px' }}><input onChange={(e) => setTempGuest( prevGuest => ({...prevGuest, guest_country: e.target.value}))} type="text" value={tempGuest.guest_country} placeholder="z. B. Deutschland" /></InputView>

        <div className="contact-wrapper">
            <ContactNoteView type={CNViewType.MailAndPhone} contentArr={contactArr} style={{ height: '90px', width: '50%' }} updateData={handleUpdateCNData} />
            <ContactNoteView type={CNViewType.NoteAndAttachment} contentArr={noteArr} style={{ height: '90px', width: '50%' }} updateData={handleUpdateCNData}/>
        </div>

        <div className="shadowBox" style={{ display: 'block', marginTop: '40px'}}>Über die Sterne kannst du dem Gast eine Bewertung geben. Dabei ist 5 die beste Bewertung. Vergibst du eine 1, so wirst du außerdem gefragt, ob der Gast zur Blacklist hinzugefügt werden soll. Dadurch wird dieser Gast mit<span className="default-icon" style={{color: 'red', padding: '0 10px'}}>warning_amber</span>markiert.</div>

        <RateBar ratevalue={tempGuest.guest_rate} style={{fontSize: '3.0rem', marginTop: '40px'}} isClickable={true} handleRate={ handleNewRateValue }/>

        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId="guestAddEditAlert" title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body ) }

        </>
     );
}
 
export default GuestAddEditView;