import { useContext, useRef, useState } from "react";
import {StayDetailViewContext} from "../../contexts/StayDetailViewContext";
import ModalView from "../../generalComps/ModalView";
import { GuestGroupObj } from "../../methods/types";

type Props = {
    
    handleSelectedGroupData: (groupData: Array<string>) => void;
    handleClose: () => void;

}

const GroupDataSelectionView = ( { handleSelectedGroupData, handleClose }:Props ) => {

    const { stay } = useContext(StayDetailViewContext);

    const groupData: Array<GuestGroupObj> = stay.stay_group ? JSON.parse( stay.stay_group ) : [];

    const [groupDataSelection, setGroupDataSelection] = useState<Array<string>>([]);

    const handleOnClick = (guest: GuestGroupObj) => {

        const guestName = `${guest.title} ${guest.firstname} ${guest.lastname}`;

        const index = groupDataSelection.indexOf( guestName );

        if (index === -1) {
            setGroupDataSelection( prevArr => ( [...prevArr, guestName] ) );
        } else {

            const tempArr = [...groupDataSelection];
            tempArr.splice(index, 1);

            setGroupDataSelection( tempArr );
        }

    }

    const handleSave = () => {

        handleSelectedGroupData(groupDataSelection);

    }

    return ( 
        <ModalView modalViewId="groupDataSelectionView" handleClose={handleClose} style={{width: '400px'}}>
            <div className="flex center-y" style={{ width: "100%" }}>
                <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Gästedaten</div>
                <div onClick={ handleSave} className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <div className="shadowBox center"style={{margin: '20px 0'}}>Markiere in der unten stehenden Auswahl die Gäste, die in das Textfeld der PDF hinzugefügt werden sollen.</div>
            {
                groupData.map( (guest, index) => {

                    const guestName = `${guest.title} ${guest.firstname} ${guest.lastname}`;

                    const selected = groupDataSelection.find( name => name === guestName );

                    return(
                        <div key={index} onClick={ () => handleOnClick(guest) } className="flex center-y w100prc" style={{ padding: '5px 0' }}>
                            <div className="clickable-icon" style={{ width: '40px' }}>{ !selected ? 'check_box_outline_blank' : 'check_box' }</div>
                            <div className="flex center-y w100prc">{guestName}</div>
                        </div>
                    )

                })
            }
        </ModalView>
     );
}
 
export default GroupDataSelectionView;