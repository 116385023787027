import { DefaultFetchResult } from "./types";

export const postContactMail = async (name: string, mail: string, subject: string, message: string) => {

    const data = new URLSearchParams();
  
    data.append('name', name);
    data.append('mail', mail);
    data.append('subject', subject);
    data.append('message', message);
  
      const result = await fetch(
          `https://eazyac-dev.de/cgi-bin/eazyact/mail/class_contact_form.php`,
          {
          method: "POST", 
          body: data,
          'credentials': 'include',
          }
        )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}