import { useEffect, useState } from "react";
import { setCss } from "../methods/animation.methods"
import { createPortal } from "react-dom";

type Props = {
  actionAlertId: string;
  title: string;
  message: string;
  buttons: Array<{title: string, handler: () => void}>;
  handleClose: (e: React.SyntheticEvent) => void;
  //setIsVisible: (isVisible: boolean) => void;
}

const ActionAlert = ( {actionAlertId, title, message, buttons, handleClose}:Props ) => {

    useEffect(() => {

      setCss("7");

      return( () => {
        setCss("0");
      });

  }, []);

  const buttonItems = buttons.map((button: any) => (
    <div
      onClick={button.handler}
      className="action-alert-btn"
      key={button.title}
    >
      {button.title}
    </div>
  ));

  const handleCloseView = (e: React.SyntheticEvent) => {

    if (e.currentTarget == e.target) {
      handleClose(e);
    } 

    e.stopPropagation();

  }

  return (
    <div onClick={handleCloseView} id={actionAlertId} className="backView">
      <div
        id="innerWrapper"
        style={{ top: "calc(50% - 200px)", paddingBottom: "0", height: "auto" }}
      >
        {title.length > 0 && (
          <div
            className="wrapper-view-title clearfix"
            style={{
              marginBottom: "0",
              width: "95%",
              fontSize: "0.9rem",
              textTransform: "uppercase",
              border: "none",
            }}
          >
            {title}
          </div>
        )}
        {message.length > 0 && (
          <div
            className="shadowBox"
            style={{ top: "10px", marginBottom: "30px", width: "100%" }}
          >
            {message}
          </div>
        )}
        {buttonItems}
      </div>
    </div>
  );
};

export default ActionAlert;
