import { useMemo } from "react";
import { amountOfPaymentListArr } from "../../methods/price.calculation.methods";
import { OfferObject, PaymentObj, PriceListObj, PriceListType, PropertyPaymentObj, TaxType } from "../../methods/types";
import { amountOfPriceListArr, getInvoiceData } from "../../methods/price.calculation.methods";

type Props = {
    offerData: OfferObject;
    nights: number;
}

const OfferInvoiceView = ( { offerData, nights }:Props ) => {

    const pmSettingsObj: PropertyPaymentObj = JSON.parse( offerData.property_pm );

    const currency = useMemo( () => pmSettingsObj.property_currency, [pmSettingsObj.property_currency] );

    const priceListArr: Array<PriceListObj> = JSON.parse( offerData.stay_priceList ? offerData.stay_priceList! : '[]' );
    const paymentArr: Array<PaymentObj> = JSON.parse( offerData.stay_paymentList ? offerData.stay_paymentList! : '[]' );

    const [fullArr, priceArr, taxArr] = getInvoiceData(priceListArr);

    const invoiceListString = JSON.stringify( fullArr );

    const aopla = amountOfPaymentListArr(paymentArr);


    const sumZL = amountOfPriceListArr(PriceListType.AdditionalService, TaxType.NoTax, priceListArr);
    const sumZLForPayment = amountOfPriceListArr(PriceListType.AdditionalService, TaxType.Tax, priceListArr);

    const sumKT = amountOfPriceListArr(PriceListType.CityTax, TaxType.NoTax, priceListArr);
    const sumKtForPayment =amountOfPriceListArr(PriceListType.CityTax, TaxType.Tax, priceListArr);

    const netPrice = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.NoTax, priceListArr) + sumZL + sumKT;
    const allSum = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr) +
                    amountOfPriceListArr(PriceListType.AdditionalService, TaxType.Tax, priceListArr) +
                    amountOfPriceListArr(PriceListType.CityTax, TaxType.Tax, priceListArr);

    const priceNightsOnly = priceArr[0] ? ( (100 + priceArr[0].taxPercent) * priceArr[0].sum ) / 100 : 0;
    const additionalNightsPriceOnly = priceArr[1] ? ( (100 + priceArr[1].taxPercent) * priceArr[1].sum ) / 100 : 0;

    let depositPrice = 0;

    if( pmSettingsObj!.property_deposit ) {

        if (!pmSettingsObj!.property_deposit_pauschal){

            depositPrice = (pmSettingsObj!.property_deposit_percent * allSum) / 100;

            if (depositPrice != 0 && (depositPrice < pmSettingsObj!.property_deposit_min)) {
                depositPrice = pmSettingsObj!.property_deposit_min;
            } 

        } else {

            depositPrice = pmSettingsObj!.property_deposit_pauschal_value;

        }

    }
    
    const invoicePriceObj = {fullAmount: allSum, netAmount: netPrice, nightsOnlyAmount: priceNightsOnly, addNightsOnlyAmount: additionalNightsPriceOnly, additionalServiceAmountNoTax: sumZL, additionalServiceAmount: sumZLForPayment, cityTaxAmountNoTax: sumKT, cityTaxAmount: sumKtForPayment, depositAmount: depositPrice};


    let depositText = 'Anzahlung';

    if( pmSettingsObj!.property_deposit ) {

        if (!pmSettingsObj!.property_deposit_pauschal){

            if ( invoicePriceObj.depositAmount != 0 && (invoicePriceObj.depositAmount < pmSettingsObj!.property_deposit_min)) {
                depositText += ' (Mindestpreis)';
            } else {
                depositText += ' ' + pmSettingsObj!.property_deposit_percent + ' %';
            }

        } else {

            depositText += ' (pauschal)';

        }

    }

    return ( 
        <>
        <div className="flex center-y w100prc" style={{fontSize: '1.2em', marginBottom: '10px'}}>Kostenaufstellung</div>
        <div style={{padding: '5px'}}>
        { priceArr.map( (obj, i) => {

                let n = 'Nächte';

                let text = '';

                if (obj.art === 0) {

                    if (obj.nights === 1) {
                        n = 'Nacht';
                    }

                    text = `${obj.nights} ${n} x ${obj.price.toFixed(2)} ${currency}`;

                } else if (obj.art === 1) {

                    text = '+ Aufschläge für weitere Personen';

                }

                return(

                    <div key={i} className="flex center-y w100prc" style={{overflow: 'visible', position: 'relative'}}>
                        <div className="flex center-y" style={{width: '70%'}}>{text + ((obj.art === 0 && obj.discount!.abz_name && obj.discount!.abz_name.length > 0) ? ' | ' + obj.discount!.abz_name : '')}</div>
                        <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${obj.sum.toFixed(2)} ${currency}`}</div>
                    </div>

                )

            } ) 
        
        }
        </div>

        { invoicePriceObj.additionalServiceAmount > 0 &&

            <div className="flex center-y w100prc" style={{padding: '0 5px'}}>
                <div className="flex center-y" style={{width: '70%'}}>Zusatzleistungen</div>
                <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.additionalServiceAmountNoTax.toFixed(2)} ${currency}`}</div>
            </div>

        }

        { invoicePriceObj.cityTaxAmount > 0 &&

        <div className="flex center-y w100prc" style={{padding: '0 5px'}}>
            <div className="flex center-y" style={{width: '70%'}}>Gästebeitrag</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.cityTaxAmountNoTax.toFixed(2)} ${currency}`}</div>
        </div>

        }

        { (invoicePriceObj.netAmount != 0 && taxArr.length != 0) &&

        <div className="flex center-y w100prc" style={{padding: '10px 5px'}}>
            <div className="flex center-y" style={{width: '70%', fontWeight: 'bold'}}>Nettobetrag</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.netAmount.toFixed(2)} ${currency}`}</div>
        </div>

        }

        { taxArr.map( (obj, i) => {

            return(

                <div key={i} className="flex center-y w100prc" style={{padding: '0 5px'}}>
                    <div className="flex center-y" style={{width: '70%'}}>{`${obj.taxPercent} % Umsatzsteuer`}</div>
                    <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${obj.sum.toFixed(2)} ${currency}`}</div>
                </div>

            );

        })

        }

        <div className="flex center-y w100prc" style={{padding: '10px 5px'}}>
            <div className="flex center-y" style={{width: '70%', fontWeight: 'bold'}}>Rechnungsbetrag</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.fullAmount.toFixed(2)} ${currency}`}</div>
        </div>

        { pmSettingsObj!.property_deposit &&

        <div className="flex center-y w100prc" style={{padding: '0 5px'}}>
            <div className="flex center-y" style={{width: '70%'}}>{depositText}</div>
            <div className="flex" style={{width: '30%', justifyContent: 'right'}}>{`${invoicePriceObj.depositAmount.toFixed(2)} ${currency}`}</div>
        </div>

        }
        </>
     );
}
 
export default OfferInvoiceView;