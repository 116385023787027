import { useContext, useId, useRef, useState } from "react";
import ModalView from "../../generalComps/ModalView";
import { AlertObj, HandoverProtocolObj, InfoWidgetObj, PaymentsViewType, PdfType, ViewState } from "../../methods/types";
import ActionAlert from "../../generalComps/ActionAlert";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import HandoverCard from "./HandoverCard";
import { emptyHandoverProtocolObj } from "../../methods/constants";
import HandoverAddEditView from "./HandoverAddEditView";
import InfoWidget from "../../generalComps/InfoWidget";
import { useMutation } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../methods/http.property.methods";
import { fetchPdfExTpUrl } from "../../methods/http.korres.methods";

type Props = {
    handleClose: () => void;
}

const HandoverView = ( { handleClose }:Props ) => {

    const { selectedProperty, setSelectedProperty, setPaymentsViewType, setSelectedHandover } = useContext(PropertysViewContext);

    const [handovers, setHandovers] = useState<Array<HandoverProtocolObj>>( selectedProperty.property_tp ? JSON.parse( selectedProperty.property_tp ) : [] );

    const [isVisibleHandoverAddEditView, setIsVisibleHandoverAddEditView] = useState( { isVisible: false, index: -1 } );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const showPopUpInfo = useRef(true);

    const handleAdd = () => {

        setSelectedHandover( emptyHandoverProtocolObj );

        setIsVisibleHandoverAddEditView( { isVisible: true, index: -1 } );

    }

    const handleHandoverCardClick = (state: ViewState, index: number) => {

        if ( state === ViewState.Delete ) {

            const alertBtns = [ { title: 'Löschen', handler: () => {

                const newHandovers = [...handovers];
                newHandovers.splice( index, 1 );

                postUpdatePropertySingleMutation( { type: 'deleted', handovers: JSON.stringify( newHandovers ), isImageUpdate: false } );

                setAlertObj( new AlertObj() );

            } }, { ...standardAlertButton[0], title: 'Abbrechen' } ];

            setAlertObj( new AlertObj( true, 'Löschen', 'Möchtest du diesen Eintrag wirklich löschen?', alertBtns ) );

        } else if ( state === ViewState.Edit ) {

            setSelectedHandover( handovers[index] );

            setIsVisibleHandoverAddEditView( { isVisible: true, index: index } );

        }

    }

    const handleSaveHandover = (type: string, json: string, isImageUpdate: boolean) => {

        postUpdatePropertySingleMutation( { type: type, handovers: json, isImageUpdate: isImageUpdate } );

    }

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: ( variables: { type: string, handovers: string, isImageUpdate: boolean } ) => postUpdatePropertySingleValue(selectedProperty.property_id, 'property_tp', variables.handovers ),
        onSuccess: (_, variables) => {

            setSelectedProperty( prev => ( { ...prev, property_tp: variables.handovers } ) );
            setHandovers( JSON.parse( variables.handovers ) );

            if (!variables.isImageUpdate) {

                setIsVisibleHandoverAddEditView( { isVisible: false, index: -1 } );
                setSelectedHandover( emptyHandoverProtocolObj );

                setInfoWidgetObj( new InfoWidgetObj(true, `Inventar erfolgreich ${ variables.type === 'new' ? 'hinzugefügt' : variables.type === 'edit' ? 'aktualisiert' : 'gelöscht' }.`) );

            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: fetchPdfHandoverUrlMutation } = useMutation({
        mutationFn: () => fetchPdfExTpUrl(selectedProperty.property_id, undefined, PdfType.Transferprotocol),
        onSuccess: (data) => {

            window.open(`https://eazyac-dev.de/user_data/${data.pdf_name}`, '_blank');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleShowHandoverPdf = () => {

        fetchPdfHandoverUrlMutation();

        if (showPopUpInfo.current) {

            setAlertObj( new AlertObj( true, 'Übergabeprotokoll', 'Übergabeprotokoll wird geöffnet. Sollte das Übergabeprotokoll nicht automatisch angezeigt werden, prüfe bitte, ob ein Pop-Up-Blocker aktiviert ist.', standardAlertButton ) );
            showPopUpInfo.current = false;
        }

    }

    return ( 

        <>
        <ModalView modalViewId="exposeView" style={{ width: '600px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ () => setPaymentsViewType( PaymentsViewType.MainMenu ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
                <div className="main-title">Übergabeprotokoll</div>
                <div onClick={ handleAdd } title="Zeile hinzufügen" className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
                <div onClick={ handleShowHandoverPdf } title="Vorschau Übergabeprotokoll" className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>preview</div>
            </div>
            <div className="shadowBox" style={{ marginTop: '10px', marginBottom: '10px' }}>
                Füge über das Plus-Zeichen ein neues Inventar zum Übergabeprotokoll hinzu. Diesem Inventar musst du einen Titel vergeben, damit sie im Protokoll angezeigt werden kann. Untertitel, Anzahl und Foto sind hingegen optional.    
            </div>
            { handovers.length === 0 && <div className="shadowBox center color-lightblack" style={{ marginTop: '10px' }}>Bisher wurden keine Daten angelegt</div> }
            { handovers.map( (handover, index) => {

                return(
                    <HandoverCard key={index} handover={ handover } handleOnClick={ (state) => handleHandoverCardClick(state, index) } />
                )

            }) }
        </ModalView>
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        { isVisibleHandoverAddEditView.isVisible && <HandoverAddEditView handleSave={ handleSaveHandover } handleCancel={ () => setIsVisibleHandoverAddEditView( { isVisible: false, index: -1 } ) } index={ isVisibleHandoverAddEditView.index } /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default HandoverView;