import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchPricesById } from "./http.price.methods";
import { daysBetweenDates, getDateObj } from "./standard.methods";
import { CancellationObj, InvoiceObj, PaymentObj, PriceListObj, PriceListType, PriceObj, Property, PropertyPaymentObj, Stay, TaxType } from "./types";
import { useEffect, useMemo, useState } from "react";

// export const useCalculateStayPrice = (stay: Stay, property: Property ): [number, string, string] => {

//     let price = 0;
//     let currency = '';

//     const specialPrice = stay.stay_special_price ? stay.stay_special_price : 0;
//     const persons = stay.stay_adults! + stay.stay_children!;
//     const days = useMemo( () => daysBetweenDates(getDateObj(stay.stay_checkIn!, 0).date, getDateObj(stay.stay_checkOut!, 0).date), [stay.stay_checkIn, stay.stay_checkOut] );
   
//     const priceListArr: Array<PriceListObj> = JSON.parse( stay.stay_priceList ? stay.stay_priceList : '[]' );

//     const [priceObj, setPriceObj ] = useState<{price: number, currency: string, priceListString: string}>( { price: 0, currency: '', priceListString: '' } );

//     useEffect( () => {

//         if (persons > 0) {

//             if (specialPrice === 0 && stay.stay_priceList === '[]') {
    
//                 const priceIdArr = property.property_price ? JSON.parse( property.property_price! ) : Array<{price_id: Number}>();
                
//                 fetchPricesByIdMutation(priceIdArr.map( (obj:{price_id: Number}) => obj.price_id ).join(',') );
        
//             } else if (specialPrice !== 0) {
            
//                 const spDayPrice = specialPrice / days;
    
//                 if (property.property_id !== -1) {
    
//                     const property_pm: PropertyPaymentObj = JSON.parse( property.property_pm ? property.property_pm! : '[]' ); //Payment Details
    
//                     currency = property_pm.property_currency;
    
//                     for (let i = 1; i <= days; i++) {
    
//                         const dd = getDateObj( stay.stay_checkIn!, i).date;
        
//                         let taxVal = 0;
        
//                         if (property_pm.property_tax) {
        
//                             taxVal = (property_pm.property_tax_percent * spDayPrice) / (100 + property_pm.property_tax_percent);
        
//                         }
        
//                         const priceListObj = {date: dd, price: spDayPrice, originalPrice: spDayPrice, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: false, persons: 0, art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
//                         priceListArr.push(priceListObj);
        
//                     }
    
//                 }
    
//                 setPriceObj( { price: specialPrice, currency: currency, priceListString: JSON.stringify( priceListArr ) } );
        
//             } else if (stay.stay_priceList !== '[]') {
                
//                 if (property.property_id !== -1) {
    
//                     const property_pm: PropertyPaymentObj = JSON.parse( property.property_pm ? property.property_pm! : '[]' ); //Payment Details
    
//                     currency = property_pm.property_currency;
    
//                 }
    
//                 price = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr );

//                 setPriceObj( { price: price, currency: currency, priceListString: JSON.stringify( priceListArr ) } );
    
//             }
    
//         }

//     }, []);

//     const { mutate: fetchPricesByIdMutation } = useMutation( {
//         mutationFn: ( priceIdString: string) => fetchPricesById(priceIdString),
//         onSuccess: (data) => {

//             const fetchPricesResult: Array<PriceObj> = data.obj;

//             const property_pm: PropertyPaymentObj = JSON.parse( property.property_pm ? property.property_pm! : '[]' ); //Payment Details

//             currency = property_pm.property_currency;

//             fetchPricesResult.forEach(priceObj => {
            
//                 const startD = new Date( priceObj.price_start! );
//                 startD.setHours(0,0,0,0);

//                 const endD = new Date( priceObj.price_end! );
//                 endD.setHours(0,0,0,0);

//                 for (let i = 0; i < days; i++) { 
                    
//                     const dd = getDateObj( stay.stay_checkIn!, i).date;

//                     if (dd.getTime() >= startD.getTime() && dd.getTime() <= endD.getTime()) {
                        
//                         let taxVal = 0;

//                         if (property_pm.property_tax) {

//                             taxVal = (property_pm.property_tax_percent * priceObj.price_value) / (100 + property_pm.property_tax_percent);

//                         }

//                         const priceListObj = {date: dd, price: priceObj.price_value, originalPrice: priceObj.price_value, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: false, persons: 0, art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
//                         priceListArr.push(priceListObj);
                            
//                         //}

//                         taxVal = 0;

//                         //----für jede zusätzliche Person wird der additionalPrice aufgeschlagen----//
//                         if (persons > priceObj.price_to && priceObj.price_additional) {

//                             if (property_pm.property_tax) {

//                                 taxVal = (property_pm.property_tax_percent * (priceObj.price_additional_value * (persons - priceObj.price_to))) / (100 + property_pm.property_tax_percent);

//                             }
                            
//                             const price = priceObj.price_additional_value * (persons - priceObj.price_to);

//                             const priceListObj = {date: dd, price: price, originalPrice: price, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: true, persons: (persons - priceObj.price_to), art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
//                             priceListArr.push(priceListObj);

//                         }

//                     }

//                 }

//             });

//             price = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr);

//             setPriceObj( { price: price, currency: currency, priceListString: JSON.stringify( priceListArr ) } );

//         },
//         onError: (error) => console.log(error),
//     } );

//     return [ 500, priceObj.currency, priceObj.priceListString ];

// }

// export const useCalculateStayPrice = (stay: Stay, property: Property ): [number, string, string] => {

//     let isEnabled = false;
//     let price = 0;
//     let currency = '';

//     const specialPrice = stay.stay_special_price ? stay.stay_special_price : 0;
//     const persons = stay.stay_adults! + stay.stay_children!;
//     const days = daysBetweenDates(getDateObj(stay.stay_checkIn!, 0).date, getDateObj(stay.stay_checkOut!, 0).date);
   
//     const priceListArr: Array<PriceListObj> = JSON.parse( stay.stay_priceList ? stay.stay_priceList : '[]' );

//     let priceIdString = '';

//     if (persons > 0) {

//         if (specialPrice === 0 && stay.stay_priceList === '[]') {

//             const priceIdArr = property.property_price ? JSON.parse( property.property_price! ) : Array<{price_id: Number}>();
            
//             priceIdString = priceIdArr.map( (obj:{price_id: Number}) => obj.price_id ).join(',');
    
//             isEnabled = true;
    
//         } else if (specialPrice !== 0) {
        
//             const spDayPrice = specialPrice / days;

//             if (property.property_id !== -1) {

//                 const property_pm: PropertyPaymentObj = JSON.parse( property.property_pm ? property.property_pm! : '[]' ); //Payment Details

//                 currency = property_pm.property_currency;

//                 for (let i = 1; i <= days; i++) {

//                     const dd = getDateObj( stay.stay_checkIn!, i).date;
    
//                     let taxVal = 0;
    
//                     if (property_pm.property_tax) {
    
//                         taxVal = (property_pm.property_tax_percent * spDayPrice) / (100 + property_pm.property_tax_percent);
    
//                     }
    
//                     const priceListObj = {date: dd, price: spDayPrice, originalPrice: spDayPrice, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: false, persons: 0, art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
//                     priceListArr.push(priceListObj);
    
//                 }

//             }

//             price = specialPrice;
    
//         } else if (stay.stay_priceList !== '[]') {
            
//             if (property.property_id !== -1) {

//                 const property_pm: PropertyPaymentObj = JSON.parse( property.property_pm ? property.property_pm! : '[]' ); //Payment Details

//                 currency = property_pm.property_currency;

//             }

//             price = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr );

//         }

//     }

//     const { isSuccess, data } = useQuery({
//         queryKey: ["fetchPricesById", stay],
//         queryFn: () => fetchPricesById(priceIdString),
//         enabled: isEnabled,
//         refetchOnWindowFocus: false,
//     });

//     if (isSuccess && data.obj) {

//         const fetchPricesResult: Array<PriceObj> = data.obj;

//         const property_pm: PropertyPaymentObj = JSON.parse( property.property_pm ? property.property_pm! : '[]' ); //Payment Details

//         currency = property_pm.property_currency;

//         fetchPricesResult.forEach(priceObj => {
           
//             const startD = new Date( priceObj.price_start! );
//             startD.setHours(0,0,0,0);

//             const endD = new Date( priceObj.price_end! );
//             endD.setHours(0,0,0,0);

//             for (let i = 0; i < days; i++) { 
                
//                 const dd = getDateObj( stay.stay_checkIn!, i).date;

//                 if (dd.getTime() >= startD.getTime() && dd.getTime() <= endD.getTime()) {
                    
//                     let taxVal = 0;

//                     if (property_pm.property_tax) {

//                         taxVal = (property_pm.property_tax_percent * priceObj.price_value) / (100 + property_pm.property_tax_percent);

//                     }

//                     const priceListObj = {date: dd, price: priceObj.price_value, originalPrice: priceObj.price_value, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: false, persons: 0, art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
//                     priceListArr.push(priceListObj);
                        
//                     //}

//                     taxVal = 0;

//                     //----für jede zusätzliche Person wird der additionalPrice aufgeschlagen----//
//                     if (persons > priceObj.price_to && priceObj.price_additional) {

//                         if (property_pm.property_tax) {

//                             taxVal = (property_pm.property_tax_percent * (priceObj.price_additional_value * (persons - priceObj.price_to))) / (100 + property_pm.property_tax_percent);

//                         }
                        
//                         const price = priceObj.price_additional_value * (persons - priceObj.price_to);

//                         const priceListObj = {date: dd, price: price, originalPrice: price, taxVal: taxVal, originalTaxVal: taxVal, taxPercent: property_pm.property_tax_percent, additional: true, persons: (persons - priceObj.price_to), art: 0, zlID: '', discount: {abz_id: -1, abz_name: 'Standardpreis'}};
//                         priceListArr.push(priceListObj);

//                     }

//                 }

//             }

//         });

//         price = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr);

//     }

//     return [price, currency, JSON.stringify( priceListArr ) ];

// }

export const amountOfPriceListArr = (type: PriceListType, tax: TaxType, priceListArr: Array<PriceListObj>) => {

    let endPrice: number = 0.00;
  
    let taxVal: number = 0;
  
    priceListArr.forEach(priceListObj => {
      
      if (tax === TaxType.NoTax) {
        taxVal = priceListObj.taxVal;
      }
  
      if ( (type != PriceListType.CityTax && priceListObj.art == type) || (type == PriceListType.CityTax && priceListObj.art == type && priceListObj.active == true)) {
          endPrice += priceListObj.price - taxVal;
      }
  
    });

    return endPrice;
  
}

export const amountOfPaymentListArr = (paymentArr: Array<PaymentObj>) => {

    let paymentSum = 0;

    paymentArr.forEach(obj => {

        paymentSum += obj.price;

    });

    return paymentSum;

}

export const paymentIconVisibility = (value: number = 0, paymentArr: Array<PaymentObj>, aopla: number, allSum: number) => {

    /*const aopla = amountOfPaymentListArr(paymentArr);
    const allSum = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr)
                    + amountOfPriceListArr(PriceListType.AdditionalService, TaxType.Tax, priceListArr)
                    + amountOfPriceListArr(PriceListType.CityTax, TaxType.Tax, priceListArr);*/

    const paymentArrCheck = paymentArr.find(obj => {
        return obj.price == value;
    });

    if (aopla == 0 && value > 0) {

        return 'visible';

    } else if (!paymentArrCheck && value > 0 && (aopla + value) <= allSum) {
        
        return 'visible';

    } else {

        return 'hidden';

    }

}

export const getCancellationObject = (pm: PropertyPaymentObj, checkInDateString: string) => {

    if (pm) {

        let storno_arr: Array<CancellationObj> = [{property_storno: pm.property_storno1, property_storno_days: pm.property_storno1_days, property_storno_percent: pm.property_storno1_percent},
            {property_storno: pm.property_storno2, property_storno_days: pm.property_storno2_days, property_storno_percent: pm.property_storno2_percent},
            {property_storno: pm.property_storno3, property_storno_days: pm.property_storno3_days, property_storno_percent: pm.property_storno3_percent}];
    
            storno_arr = storno_arr.filter(obj => {
    
            return obj.property_storno === true;
    
            });
    
            storno_arr = storno_arr.sort(function(obj1, obj2) {
            // Ascending: first age less than the previous
            return obj1.property_storno_days - obj2.property_storno_days;
    
            });
    
            let today = new Date();
            today.setHours(0,0,0,0);
    
            const ciS = checkInDateString.split('-');
            const ciD = new Date(Number(ciS[0]), Number(ciS[1]) - 1, Number(ciS[2]), 0, 0, 0, 0);
    
            const days = daysBetweenDates(today, ciD);
    
            let storno_obj = storno_arr.filter( obj => {
                return days <= obj.property_storno_days;
            })[0];
    
            return storno_obj;

    } else {

        return {property_storno: false, property_storno_days: 0, property_storno_percent: 0};

    }

}

export const getInvoiceData = (priceListArr: Array<PriceListObj>): [fullArr: Array<InvoiceObj>, priceArr: Array<InvoiceObj>, taxArr: Array<InvoiceObj>] => {

    let nights = 0;
    let price = 0;
    let taxValSum = 0;
    let taxPercent = 0;
    let sum = 0;
    let additionalSum = 0;
    let arr = Array<InvoiceObj>();

    const filteredPNArr = priceListArr.filter( obj => {
        return obj.art === 0;
    });

    let fDate = '';
    let lDate = '';

    filteredPNArr.forEach((obj, i) => {

        if (i === 0) {

            fDate = getDateObj( obj.date!, 0 ).dateStringDE;

        }

        if (!obj.additional) {

            nights += 1;
            price = obj.price - obj.taxVal;
            sum += price;
            taxPercent = obj.taxPercent;

        } else {

            additionalSum += obj.price - obj.taxVal;

        }

        taxValSum += obj.taxVal;

        if (i + 1 < filteredPNArr.length) {

            if ((filteredPNArr[i+1].price - filteredPNArr[i+1].taxVal) !== price && !filteredPNArr[i+1].additional) {

                lDate = getDateObj( obj.date!, 0 ).dateStringDE;

                arr.push({nights: nights, price: price, fDate: fDate, lDate: lDate, taxPercent: taxPercent, sum: sum, art: 0, discount: Object.assign({}, obj.discount)});

                nights = 0;
                price = 0;
                sum = 0;

                fDate = getDateObj( filteredPNArr[i+1].date!, 0).dateStringDE;


            } 

        } else if (i === filteredPNArr.length - 1) {

            lDate = getDateObj( obj.date!, 1).dateStringDE;

            arr.push({nights: nights, price: price, fDate: fDate, lDate: lDate, taxPercent: taxPercent, sum: sum, art: 0, discount: Object.assign({}, obj.discount)});

            if (additionalSum !== 0) {
                arr.push({nights: 0, price: 0, taxPercent: taxPercent, sum: additionalSum, art: 1});
            }

            if (taxValSum !== 0) {
                arr.push({nights: 0, price: 0, taxPercent: taxPercent, sum: taxValSum, art: 2});
            }

            nights = 0;
            price = 0;
            sum = 0;

        }

    });

    const filteredArr = arr.filter( obj => {
        return obj.art === 0 || obj.art === 1;
    });

    let filteredTaxArr = Array<InvoiceObj>();

    arr.forEach(obj => {

        if (obj.art === 2) {
            filteredTaxArr.push(Object.assign({}, obj));
        }

    });
    
    //-----------prüfen, ob für Zusatzleistungen und Kurtaxe Umsatzsteuern aktiviert sind--------------------//
    let zlktTaxArr = checkTaxForZLKT(priceListArr);
   
    zlktTaxArr.forEach(obj => {

        const ftaObj = filteredTaxArr.find( fobj => {
            return fobj.art === obj.art && fobj.taxPercent === obj.taxPercent;
        });

        const arrObj = arr.find( aobj => {
            return aobj.art === obj.art && aobj.taxPercent === obj.taxPercent;
        });

        if (!ftaObj) {
            filteredTaxArr.push(obj);
        } else {
            ftaObj.sum += obj.sum;
        }

        if (!arrObj) {
            arr.push(obj);
        } else {
            arrObj.sum += obj.sum;
        }

    });

    return [arr, filteredArr, filteredTaxArr];

}

export const checkTaxForZLKT = (priceListArr: Array<PriceListObj>) => {

    let taxValSum = 0;
    let taxPercent = 0;
    let arr = Array<InvoiceObj>();

    const filteredPNArr = priceListArr.filter( obj => {
        return (obj.art === 1 || (obj.art ===2 && obj.active === true)) && obj.taxVal !== 0;
    });

    filteredPNArr.forEach( (obj, i) => {

        taxPercent = obj.taxPercent;
        taxValSum += obj.taxVal;

        if ((i + 1) < filteredPNArr.length) {
            //-------------wenn Prozentwert sich ändert, Objekt im Array erstellen, Variablen zurücksetzen und weitermachen-----------//
            if (filteredPNArr[i+1].taxPercent != taxPercent) {

                arr.push({nights: 0, price: 0, taxPercent: taxPercent, sum: taxValSum, art: 2});

                taxPercent = 0;
                taxValSum = 0;

            } 

        } else if (i == filteredPNArr.length - 1) {
            //------------wenn letzter Datensatz erreicht, Objekt im Array erstellen-------------//
            arr.push({nights: 0, price: 0, taxPercent: taxPercent, sum: taxValSum, art: 2});

            taxPercent = 0;
            taxValSum = 0;

        }

    });

    return arr;

}