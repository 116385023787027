import { ReactNode } from "react";
import '../css/card.css';

type Props = {
    setType: () => void;
    tileStyle: {};
    icon: ReactNode;
    topTitle: string;
    valueTitle: string;
    btmTitle: string;
    isClickable: boolean;
    isSelected: boolean;
}

const Card = ( { setType, tileStyle, icon, topTitle, valueTitle, btmTitle, isClickable, isSelected }: Props ) => {
    return ( 
        <div onClick={ setType } className={ `shadowBox flex center wrap${ isClickable ? ` tile${ isSelected ? ' selected' : '' }` : '' }` } style={tileStyle}>
            { icon }
            { topTitle.length > 0 && <div className = "flex center color-lightblack" style = {{width: '100%', textAlign: 'center'}}>{topTitle}</div> }
            <div className = "flex center value-title">{valueTitle}</div>
            { btmTitle.length > 0 && <div className = "flex center bottom-title">{btmTitle}</div> }
            { isClickable && <div className="clickable-icon" style={{ position: 'absolute', top: '0', left: '0', padding: '5px', fontSize: '0.9em', color: 'gray' }}>ads_click</div> }
        </div>
     );
}
 
export default Card;