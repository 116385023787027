import { DefaultFetchResult, ExposeObj } from "./types";

export const fetchExpose = async (propertyId: number) => {

    const data = new URLSearchParams();
  
    data.append('property_id', propertyId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=ex&do=select_all`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postAddExpose = async (expose: ExposeObj, propertyId: number) => {

    const data = new URLSearchParams();
  
    data.append('ex_art', expose.ex_art);
    data.append('ex_img', expose.ex_img!);
    data.append('ex_json', expose.ex_json);
    data.append('property_id', propertyId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=ex&do=insert`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postDeleteExpose = async (expose: ExposeObj, propertyId: number) => {

    const data = new URLSearchParams();
  
    data.append('ex_id', expose.ex_id.toString());
    data.append('property_id', propertyId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=ex&do=delete`,
        {
        method: "POST",
        body: data, 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
}

export const postUpdateExposeSingleValue = async (exId: number, propertyId: number, field: string, fieldValue: string) => {

    const data = new URLSearchParams();
  
    data.append('ex_id', exId.toString());
    data.append('property_id', propertyId.toString());
    data.append('field', field);
    data.append('field_value', fieldValue);
  
    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=ex&do=update_single`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
  }