import MenuButton from "./MenuButton";
import PopUp from "./PopUp";
import RateBar from "./RateBar";


type Props = {
    showBlacklist: boolean;
    showCancellation?: boolean;
    rateValue: number;
    handleShowBlacklist: () => void;
    handleShowCancellation?: () => void;
    handleSetRateValue: (newRateValue: number) => void;
}

const FilterPopUp = ( { showBlacklist, handleShowBlacklist, showCancellation, handleShowCancellation, rateValue, handleSetRateValue}: Props ) => {

    return ( 

        <PopUp style={{ height: 'auto', top: '60px', left: 'auto', right: '15px' }}>
            <MenuButton onClick={ handleShowBlacklist } leftIcon="warning_amber" buttonTitle={ `Blacklist-Einträge ${ showBlacklist ? 'ausblenden' : 'filtern' }` } buttonTitleSyle={{ justifyContent: 'center' }} />
            { showCancellation !== undefined &&
            <MenuButton style={{ marginTop: '5px' }} onClick={ handleShowCancellation } leftIcon="event_busy" buttonTitle={ `Stornierte ${ showCancellation ? 'ausblenden' : 'filtern' }` } buttonTitleSyle={{ justifyContent: 'center' }} />
            }
            { 
            !showBlacklist &&
            <div className="flex center w100prc" style={{ padding: '10px' }}>
                <RateBar ratevalue={rateValue} style={{ fontSize: '2.0em' }} isClickable={true} handleRate={ handleSetRateValue } />
                { rateValue != -1 && <div onClick={() => handleSetRateValue(-1)} className="flex center clickable-icon" style={{ width: '40px'}}>filter_alt_off</div> }
            </div>
            }
        </PopUp>

     );
}
 
export default FilterPopUp;