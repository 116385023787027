import { DefaultFetchResult, TemplatesObj } from "./types";

export const fetchTemplates = async () => {

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=templates&do=select_all',
      {
      method: 'GET',
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const fetchTemplateByType = async (conversationType: number) => {

  const data = new URLSearchParams();

  data.append('template_mailArt', conversationType.toString());

  const result = await fetch(
      'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=templates&do=select_by_mailArt',
      {
      method: 'POST', 
      body: data,
     'credentials': 'include',
      }
    )

  const content: DefaultFetchResult = await result.json();

  return content;

}

export const fetchPrefilledTemplateByType = async (stayId: number, conversationType: number) => {

    const data = new URLSearchParams();
  
    data.append('stay_id', stayId.toString());
    data.append('template_mailArt', conversationType.toString());
  
    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=templates&do=select_by_mailArt_prefilled',
        {
        method: 'POST', 
        body: data,
       'credentials': 'include',
        }
      )
  
    const content: DefaultFetchResult = await result.json();
  
    return content;
  
}

export const postAddTemplate = async (template: TemplatesObj,) => {

  const data = new URLSearchParams();

  data.append('template_title', template.template_title);
  data.append('template_subject', template.template_subject);
  data.append('template_message', template.template_message);
  data.append('template_language', template.template_language);
  data.append('template_language_code', template.template_language_code);
  data.append('template_mailArt', template.template_mailArt.toString());
  data.append('template_isStandardFor', template.template_isStandardFor.toString());
  data.append('template_type', template.template_type.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=templates&do=insert`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateTemplate = async (template: TemplatesObj,) => {

  const data = new URLSearchParams();

  data.append('template_id', template.template_id.toString());
  data.append('template_title', template.template_title);
  data.append('template_subject', template.template_subject);
  data.append('template_message', template.template_message);
  data.append('template_language', template.template_language);
  data.append('template_language_code', template.template_language_code);
  data.append('template_mailArt', template.template_mailArt.toString());
  data.append('template_isStandardFor', template.template_isStandardFor.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=templates&do=update`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateTemplateSingleValue = async (templateId: number, field: string, fieldValue: string) => {

  const data = new URLSearchParams();

  data.append('template_id', templateId.toString());
  data.append('field', field);
  data.append('field_value', fieldValue);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=templates&do=update_single`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteTemplate = async (templateId: number) => {

  const data = new URLSearchParams();

  data.append('template_id', templateId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=templates&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}