import { ReactElement, useContext, useId, useMemo, useRef, useState } from "react";
import { SettingsViewContext } from "../../contexts/SettingsContext";
import { PropertysContext } from "../../contexts/PropertysContext";
import PopUp from "../../generalComps/PopUp";
import { AlertObj, ImportUrlObj, InfoWidgetObj, Property, ViewState } from "../../methods/types";
import CalendarImportCard from "./CalendarImportCard";
import { useMutation } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../methods/http.property.methods";
import InfoWidget from "../../generalComps/InfoWidget";
import ActionAlert from "../../generalComps/ActionAlert";
import CalendarImportAddEditView from "./CalendarImportAddEditView";
import { emptyImportUrlObj } from "../../methods/constants";

type Props = {
    isModal: boolean;
    handleClose?: () => void | undefined;
    givenProperty?: Property | undefined;
}

const CalendarImport = ( {isModal, handleClose, givenProperty }: Props ) => {

    const { propertys, reloadPropertys } = useContext(PropertysContext);
    const { setSettingsViewType, lastSettingsViewTypeRef } = useContext(SettingsViewContext);

    const [selectedProperty, setSelectedProperty] = useState<Property>( givenProperty === undefined ? propertys.array[0] : givenProperty );

    const [isVisibleTypePopUp, setIsVisibleTypePopUp] = useState(false);

    const [isVisibleImportAddEditView, setIsVisibleImportAddEditView] = useState(false);

    const importUrlArr: Array<ImportUrlObj> = useMemo( () => { return JSON.parse( selectedProperty.property_import_links ? selectedProperty.property_import_links : '[]' ) }, [selectedProperty])

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const selectedImportUrlObjRef = useRef<{obj: ImportUrlObj, index: number | null}>( { obj: emptyImportUrlObj, index: null } );

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: ( importUrlArrJson: string ) => postUpdatePropertySingleValue( selectedProperty.property_id, 'property_import_links', importUrlArrJson ),
        onSuccess: (_, variables) => {

            setSelectedProperty( prev => ( {...prev, property_import_links: variables } ) );

            reloadPropertys();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const TypePopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px', height: 'auto' }} handleClosePopUp={ () => setIsVisibleTypePopUp(false) }>
            {propertys.array.map( (property) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => {

                        setSelectedProperty( property );

                        setIsVisibleTypePopUp(false);

                        e.stopPropagation();

                    } }
                    key={property.property_id}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{property.property_name}</div>
                    {property.property_id === selectedProperty!.property_id && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const NoData = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Import-URL vorhanden</div>;
    }

    const handleImportCardClick = (state: ViewState, importObj: ImportUrlObj, index: number) => {

        if (state === ViewState.Details) {

            const tempImportObj = importUrlArr[index];
            tempImportObj.toggle = importObj.toggle === 'toggle_off' ? 'toggle_on' : 'toggle_off';
            tempImportObj.toggleBool = importObj.toggle === 'toggle_off' ? true : false;

            const tempImportUrlArr = [...importUrlArr];
            tempImportUrlArr[index] = tempImportObj;

            postUpdatePropertySingleMutation( JSON.stringify( tempImportUrlArr ) );

        } else if (state === ViewState.Delete) {

            const alertButtons = [ {title: 'Löschen', handler: () => {

                const tempImportUrlArr = [...importUrlArr];
                tempImportUrlArr.splice( index, 1);

                postUpdatePropertySingleMutation( JSON.stringify( tempImportUrlArr ) );

                setAlertObj( new AlertObj() );

                setInfoWidgetObj( new InfoWidgetObj( true, 'Import-URL gelöscht' ) );

            } }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Diese URL löschen?', `Soll die URL '${importObj.name}' wirklich gelöscht werden?`, alertButtons ) );

        } else if (state === ViewState.Edit) {

            selectedImportUrlObjRef.current = { obj: importObj, index: index };
            setIsVisibleImportAddEditView(true);

        }

    }

    const handleSave = ( importUrlObj: ImportUrlObj, index: number | null ) => {

        const tempImportUrlArr = [...importUrlArr];

        if (index !== null) {

            tempImportUrlArr[index] = importUrlObj;

            setInfoWidgetObj( new InfoWidgetObj( true, 'Import-URL aktualisiert' ) );

        } else {

            tempImportUrlArr.push( importUrlObj );

            setInfoWidgetObj( new InfoWidgetObj( true, 'Import-URL hinzugefügt' ) );

        }

        postUpdatePropertySingleMutation( JSON.stringify( tempImportUrlArr ) );

        setIsVisibleImportAddEditView(false);

    }

    return ( 

        <>
        <div className="navigation-view-bar">
            { isModal ?
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                :
                <div onClick={ () => setSettingsViewType( lastSettingsViewTypeRef.current ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
            }
            <div className="main-title">Belegungsplan - Import</div>
            <div onClick={ () => { selectedImportUrlObjRef.current = { obj: emptyImportUrlObj, index: null }; setIsVisibleImportAddEditView(true); } } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
        </div>
        <div className="shadowBox" style={{ marginTop: '10px' }}>Importiere hier Kalender anderer Portale. Diese Kalender müssen im iCal-Format sein. Erstelle hierzu mit dem Plus-Icon ein neues Feld, füge dort den iCal-Link ein und aktiviere diesen. Nach dem Speichern werden die importierten Aufenthalte im Belegungsplan angezeigt.</div>
        <div onClick={ () => setIsVisibleTypePopUp(true) } className="clickable-popUpButton" style={{ marginTop: '10px' }}>
            { selectedProperty.property_name }
            { isVisibleTypePopUp && <TypePopUp  /> }
        </div>
        <div className="flex center w100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: '3px', marginTop: '10px' }}>
        { 

            importUrlArr.length === 0 ?

            <NoData />

            :

            importUrlArr.map( ( importObj, index ) => {

                return <CalendarImportCard key={index} importObj={importObj} handleOnClick={ (state) => handleImportCardClick(state, importObj, index) } />;
    
            }) 

        }
        </div>
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        { isVisibleImportAddEditView && <CalendarImportAddEditView importUrlObj={selectedImportUrlObjRef.current} handleCancel={ () => setIsVisibleImportAddEditView(false) } handleSave={ handleSave } /> }
        </>
     );
}
 
export default CalendarImport;