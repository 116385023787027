import * as React from "react";
import { ReactElement, useState } from "react";
import RateBar from "../generalComps/RateBar";
import { getFullname } from "../methods/standard.methods";
import { Guest, ViewState } from "../methods/types";

type Props = {
    guest: Guest;
    handleOnClick: (state: ViewState) => void;
}

const GuestCard = ( { guest, handleOnClick }: Props ) => {
    
    const guestName = getFullname(guest.guest_title!, guest.guest_firstname!, guest.guest_lastname!, guest.guest_company!);

    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const Menu = (): ReactElement => {

       return(

        <div className="flex center w100prc h100prc">
            <div onClick={ (e) => handleButtonClick(e, ViewState.Edit) } title="Bearbeiten" className="flex center clickable-icon w100prc" style={{ fontSize: '2.0rem' }}>edit</div>
            <div onClick={ (e) => handleButtonClick(e, ViewState.Delete) } title="Löschen" className="flex center clickable-icon w100prc color-darkred" style={{ fontSize: '2.0rem' }}>delete</div>
        </div>

       );

    }

    const handleButtonClick = (e: React.SyntheticEvent, state: ViewState) => {

        setIsVisibleMenu(false);

        handleOnClick(state);

        e.stopPropagation();

    }

    const Content = (): ReactElement => {

        return(
            <>
            <div className="flex center-y w100prc">
                <div className="flex center-y w100prc color-lightblack">{ guest.guest_title! }</div>
            </div>
            <div className="flex w100prc h100prc">
                <div className="flex h100prc" style={{flexDirection: 'column', width: '60%'}}>
                    <div className="flex center-y truncate-text" style={{ padding: '5px 0' }}>{ guestName.fullname }</div>
                    <div className="flex center-y color-lightblack">{ guestName.company }</div>
                </div>
                <div className="flex h100prc" style={{flexDirection: 'column', width: '40%'}}>
                    <div className="flex center" style={{ padding: '5px 0' }}>
                        <div className="default-icon">cottage</div>
                        <div className="flex center">{guest.guest_stay_amount}</div>
                    </div>
                    <RateBar ratevalue={guest.guest_rate} style={{}} isClickable={false} handleRate={()=>{}}/>
                </div>
            </div>
            { guest.guest_blacklist == 1 && <div title="Gast ist auf der schwarzen Liste" className="default-icon" style={{ position: 'absolute', left: '0', bottom: '0', padding: '10px', color: 'red' }}>warning_amber</div> }
            </>
        );
    }

    const handleMenu = (e: React.SyntheticEvent) => {

        setIsVisibleMenu(!isVisibleMenu);

        e.stopPropagation();

    }

    return ( 

        <div onClick={ () => handleOnClick(ViewState.Details) } className="shadowBox w100prc list-card">
            <div onClick={handleMenu} className="clickable-icon" style={{position: 'absolute', right: '0', top: '5px', width: '40px', textAlign: 'right'}}>more_horiz</div>
            { isVisibleMenu ? <Menu /> : <Content /> }
            
        </div>

     );
}
 
export default GuestCard;