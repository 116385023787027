/*import { fetchPropertys } from "../methods/http.property.methods";
import { useNavigate } from "react-router-dom";
import { Property } from "../methods/types";
import { useQuery } from "@tanstack/react-query";*/
import '../css/dashboard.css';
import DashboardStays from "./DashboardStays";
import StatisticsSmall from "../statisticsComps/StatisticsSmall";
import { useContext, useEffect } from "react";
import { PropertysContext } from "../contexts/PropertysContext";
import MessageDetailViewContextProvider from "../contexts/MessageDetailViewContext";
import StayDetailViewContextProvider from "../contexts/StayDetailViewContext";

const Dashboard = () => {

  const { reloadPropertys, propertys } = useContext(PropertysContext);

  useEffect( () => {

    reloadPropertys();

    window.document.title = 'Eazyac Dashboard';

  }, []);

  return (
    <>
    { propertys.array.length === 0 &&
      <div className="shadowBox center" style={{ marginBottom: '20px' }}>
        <div className="default-icon flex center" style={{ fontSize: '2.0em', marginRight: '10px' }}>info</div> Bitte lege eine Immobilie an, um alle Funktionen vollständig nutzen zu können.
      </div> }
      <StatisticsSmall />
      <StayDetailViewContextProvider>
        <MessageDetailViewContextProvider>
          <DashboardStays />
        </MessageDetailViewContextProvider>
      </StayDetailViewContextProvider>
    </>
  );
};

export default Dashboard;
