import { ReactNode, useEffect } from 'react';

type Props = {
    children: ReactNode;
    style?: React.CSSProperties | undefined;
}

const PropertyBackview = ( { style, children }: Props ) => {

    useEffect(() => {

        setTimeout(() => {
            
            document.getElementById('propertyBackview')?.classList.add('opened');

        }, 200);

    }, []);

    return ( 

        <div id='propertyBackview' className="shadowBox default-backview" style={style}>
           {children}
        </div>

     );
}
 
export default PropertyBackview;