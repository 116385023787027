import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertObj } from "../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import { useMutation } from "@tanstack/react-query";
import { postEmailVerification } from "../methods/http.auth.methods";

const Verification = () => {

    const navigate = useNavigate();

    const params = useParams();

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const { data, mutate: postEmailVerificationMutation } = useMutation({
        mutationFn: () => postEmailVerification(params.token ? params.token : ''),
        onSuccess: () => {

            setTimeout(() => {
                
                navigate('/eazyac/login');

            }, 5000);

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => { 
        
        postEmailVerificationMutation(); 

        window.document.title = 'Eazyac - Verifizierung';
    
    }, []);

    return ( 
        <>
        
        { data?.success ?
        
        <div className="shadowBox center wrap" style={{width: '40%', margin: '100px 0 0 0'}}>
            <div className="flex-container center color-cyan" style={{width: '100%', padding: '0 0 10px 0', fontSize: '1.5rem'}}>Verifizierung erfolgreich</div>
            <div className="flex-container center" style={{width: '100%'}}>
                <div className="default-icon flex-container center" style={{width: '30%', fontSize: '5.0rem'}}>how_to_reg</div>
                Danke. Deine E-Mail-Adresse wurde erfolgreich bestätigt. Du wirst in wenigen Sekunden automatisch weitergeleitet.
            </div>
        </div>
        :
        <div className="shadowBox center wrap" style={{width: '40%', margin: '100px 0 0 0'}}>
            <div className="flex-container center color-cyan" style={{width: '100%', padding: '0 0 10px 0', fontSize: '1.5rem'}}>Verifizierung fehlgeschlagen</div>
            <div className="flex-container center" style={{width: '100%'}}>
                <div className="default-icon flex-container center" style={{width: '30%', fontSize: '5.0rem'}}>error</div>
                E-Mail-Adresse ist entweder bereits bestätigt oder ein Fehler ist aufgetreten.
            </div>
        </div>
        
        }

        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>

     );
}
 
export default Verification;