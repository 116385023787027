import { InvoicePriceObj, PaymentDataObj } from "../contexts/StayDetailViewContext";
import { getDateObj } from "./standard.methods";
import { CalendarShareObj, ConversationMailObj, CurrencyObj, DiscountObj, ExposeObj, Guest, HandoverProtocolObj, ImportUrlObj, KtObj, Landlord, PaymentAmountType, PaymentMethod, PriceObj, Property, Stay, ZkObj, ZlObj } from "./types";

export const monthsLong = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

export const monthsShort = ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sept', 'Okt', 'Nov', 'Dez'];

export const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'Ä', 'Ö', 'Ü', 'ß'];

export const timeSelectArr = ['---', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];

export const linkArr = [{name: 'Alle', art: 100},
{name: 'Angebot', art: 0},
{name: 'Buchung', art: 1},
{name: 'Quittung', art: 2},
{name: 'Rechnung', art: 3},
{name: 'Erinnerung', art: 4},
{name: 'Mahnung', art: 5},
{name: 'Exposé', art: 6},
{name: 'Übergabeprotokoll', art: 7},
{name: 'E-Mail', art: 8},
{name: 'Zahlungsbestätigung', art: 9}];

export const defaultArr = [{name: 'Angebot', art: 0},
{name: 'Buchung', art: 1},
{name: 'Quittung', art: 2},
{name: 'Rechnung', art: 3},
{name: 'Erinnerung', art: 4},
{name: 'Mahnung', art: 5},
{name: 'Exposé', art: 6},
{name: 'Übergabeprotokoll', art: 7},
{name: 'E-Mail', art: 8},
{name: 'Zahlungsbestätigung', art: 9},
{name: 'undefiniert', art: 101}];

export const placeholderArr = [{placeholder: '{GastAnrede}', description: 'Wird mit der Anrede des Gastes ersetzt. Z. B. Herr.'},
                        {placeholder: '{GastVorname}', description: 'Wird mit dem Vornamen des Gastes ersetzt. Z. B. Max.'},
                        {placeholder: '{GastNachname}', description: 'Wird mit dem Nachnamen des Gastes ersetzt. Z. B. Mustermann.'},
                        {placeholder: '{GastName}', description: 'Wird mit dem vollen Namen des Gastes ersetzt. Z. B. Max Mustermann.'},
                        {placeholder: '{VermieterVorname}', description: 'Wird mit dem Vornamen des Vermieters ersetzt. Z. B. Max.'},
                        {placeholder: '{VermieterNachname}', description: 'Wird mit dem Nachnamen des Vermieters ersetzt. Z. B. Mustermann.'},
                        {placeholder: '{VermieterName}', description: 'Wird mit dem vollen Namen des Vermieters ersetzt. Z. B. Max Mustermann.'},
                        {placeholder: '{CheckInDatum}', description: 'Wird mit dem CheckIn-Datum des Aufenthaltes ersetzt. Z. B. 01.08.2022.'},
                        {placeholder: '{CheckInZeit}', description: 'Wird mit der CheckIn-Zeit des Aufenthaltes ersetzt. Z. B. 16 Uhr.'},
                        {placeholder: '{CheckOutDatum}', description: 'Wird mit dem CheckOut-Datum des Aufenthaltes ersetzt. Z. B. 15.08.2022.'},
                        {placeholder: '{CheckOutZeit}', description: 'Wird mit der CheckOut-Zeit des Aufenthaltes ersetzt. Z. B. 11 Uhr.'},
                        {placeholder: '{AnzahlTageBisCheckIn}', description: 'Wird mit der Anzahl der verbleibenden Tage bis zum CheckIn, gerechnet vom Versanddatum der Email, ersetzt. Z. B. 14 Tage.'},
                        {placeholder: '{AnzahlTageBisCheckOut}', description: 'Wird mit der Anzahl der verbleibenden Tage bis zum CheckOut, gerechnet vom Versanddatum der Email, ersetzt. Z. B. 3 Tage.'},
                        {placeholder: '{AnzahlNaechte}', description: 'Wird mit der Anzahl der Nächte des Aufenthaltes ersetzt. Z. B. 7 Nächte.'},
                        {placeholder: '{Gesamtbetrag}', description: 'Wird mit dem Gesamtbetrag des Aufenthaltes ersetzt. Z. B. 1200 EUR.'},
                        {placeholder: '{BetragOffen}', description: 'Wird mit dem noch offenen Betrag des Aufenthaltes ersetzt. Z. B. 1000 EUR.'},
                        {placeholder: '{BetragBezahlt}', description: 'Wird mit dem bereits bezahlten Betrag des Aufenthaltes ersetzt. Z. B. 200 EUR.'},
                        {placeholder: '{BetragAnzahlung}', description: 'Wird mit dem Betrag der Anzahlung des Aufenthaltes ersetzt. Z. B. 100 EUR.'},
                        {placeholder: '{BetragKurtaxe}', description: 'Wird mit dem Betrag der Kurtaxe des Aufenthaltes ersetzt. Z. B. 35 EUR.'},
                        {placeholder: '{Kaution}', description: 'Wird mit dem Betrag der erhobenen Kaution ersetzt. Z. B. 200 EUR.'},
                        {placeholder: '{AnzahlGaeste}', description: 'Wird mit der Gesamtanzahl Gästen des Aufenthaltes ersetzt. Z. B. 5 Personen.'},
                        {placeholder: '{AnzahlErwachsene}', description: 'Wird mit der Anzahl Erwachsenen des Aufenthaltes ersetzt. Z. B. 3 Erwachsene.'},
                        {placeholder: '{AnzahlKinder}', description: 'Wird mit der Anzahl Kindern des Aufenthaltes ersetzt. Z. B. 2 Kinder.'},
                        {placeholder: '{ImmobilienName}', description: 'Wird mit dem Namen der Immobilie ersetzt. Z. B. Fewo Sonnenschein.'},
                        {placeholder: '{ImmobilienArt}', description: 'Wird mit der Art der Immobilie ersetzt. Z. B. Ferienwohnung.'}];

export const languages = [
    /*{ code : 'ab', name : 'Abkhazian' },
    { code : 'aa', name : 'Afar' },
    { code : 'af', name : 'Afrikaans' },
    { code : 'ak', name : 'Akan' },*/
    { code : 'sq', name : 'Albanian' },
    /*{ code : 'am', name : 'Amharic' },*/
    { code : 'ar', name : 'Arabic' },
    //{ code : 'an', name : 'Aragonese' },
    { code : 'hy', name : 'Armenian' },
    /*{ code : 'as', name : 'Assamese' },
    { code : 'av', name : 'Avaric' },
    { code : 'ae', name : 'Avestan' },
    { code : 'ay', name : 'Aymara' },*/
    { code : 'az', name : 'Azerbaijani' },
    /*{ code : 'bm', name : 'Bambara' },
    { code : 'ba', name : 'Bashkir' },
    { code : 'eu', name : 'Basque' },*/
    { code : 'be', name : 'Belarusian' },
    /*{ code : 'bn', name : 'Bengali' },
    { code : 'bh', name : 'Bihari languages' },
    { code : 'bi', name : 'Bislama' },*/
    { code : 'bs', name : 'Bosnian' },
    //{ code : 'br', name : 'Breton' },
    { code : 'bg', name : 'Bulgarian' },
    /*{ code : 'my', name : 'Burmese' },
    { code : 'ca', name : 'Catalan, Valencian' },
    { code : 'km', name : 'Central Khmer' },
    { code : 'ch', name : 'Chamorro' },
    { code : 'ce', name : 'Chechen' },
    { code : 'ny', name : 'Chichewa, Chewa, Nyanja' },*/
    { code : 'zh', name : 'Chinese' },
    /*{ code : 'cu', name : 'Church Slavonic, Old Bulgarian, Old Church Slavonic' },
    { code : 'cv', name : 'Chuvash' },
    { code : 'kw', name : 'Cornish' },
    { code : 'co', name : 'Corsican' },
    { code : 'cr', name : 'Cree' },*/
    { code : 'hr', name : 'Croatian' },
    { code : 'cs', name : 'Czech' },
    { code : 'da', name : 'Danish' },
    { code : 'dv', name : 'Divehi, Dhivehi, Maldivian' },
    { code : 'nl', name : 'Dutch, Flemish' },
    //{ code : 'dz', name : 'Dzongkha' },
    { code : 'en', name : 'English' },
    //{ code : 'eo', name : 'Esperanto' },
    { code : 'et', name : 'Estonian' },
    /*{ code : 'ee', name : 'Ewe' },
    { code : 'fo', name : 'Faroese' },
    { code : 'fj', name : 'Fijian' },*/
    { code : 'fi', name : 'Finnish' },
    { code : 'fr', name : 'French' },
    /*{ code : 'ff', name : 'Fulah' },
    { code : 'gd', name : 'Gaelic, Scottish Gaelic' },
    { code : 'gl', name : 'Galician' },
    { code : 'lg', name : 'Ganda' },*/
    { code : 'ka', name : 'Georgian' },
    { code : 'de', name : 'German' },
    //{ code : 'ki', name : 'Gikuyu, Kikuyu' },
    { code : 'el', name : 'Greek (Modern)' },
    { code : 'kl', name : 'Greenlandic, Kalaallisut' },
    /*{ code : 'gn', name : 'Guarani' },
    { code : 'gu', name : 'Gujarati' },
    { code : 'ht', name : 'Haitian, Haitian Creole' },
    { code : 'ha', name : 'Hausa' },
    { code : 'he', name : 'Hebrew' },
    { code : 'hz', name : 'Herero' },
    { code : 'hi', name : 'Hindi' },
    { code : 'ho', name : 'Hiri Motu' },*/
    { code : 'hu', name : 'Hungarian' },
    { code : 'is', name : 'Icelandic' },
    /*{ code : 'io', name : 'Ido' },
    { code : 'ig', name : 'Igbo' },*/
    { code : 'id', name : 'Indonesian' },
    /*{ code : 'ia', name : 'Interlingua (International Auxiliary Language Association)' },
    { code : 'ie', name : 'Interlingue' },
    { code : 'iu', name : 'Inuktitut' },
    { code : 'ik', name : 'Inupiaq' },*/
    { code : 'ga', name : 'Irish' },
    { code : 'it', name : 'Italian' },
    { code : 'ja', name : 'Japanese' },
    //{ code : 'jv', name : 'Javanese' },
    //{ code : 'kn', name : 'Kannada' },
    /*{ code : 'kr', name : 'Kanuri' },
    { code : 'ks', name : 'Kashmiri' },
    { code : 'kk', name : 'Kazakh' },
    { code : 'rw', name : 'Kinyarwanda' },
    { code : 'kv', name : 'Komi' },
    { code : 'kg', name : 'Kongo' },*/
    { code : 'ko', name : 'Korean' },
    /*{ code : 'kj', name : 'Kwanyama, Kuanyama' },
    { code : 'ku', name : 'Kurdish' },
    { code : 'ky', name : 'Kyrgyz' },
    { code : 'lo', name : 'Lao' },
    { code : 'la', name : 'Latin' },*/
    { code : 'lv', name : 'Latvian' },
    /*{ code : 'lb', name : 'Letzeburgesch, Luxembourgish' },
    { code : 'li', name : 'Limburgish, Limburgan, Limburger' },
    { code : 'ln', name : 'Lingala' },*/
    { code : 'lt', name : 'Lithuanian' },
    //{ code : 'lu', name : 'Luba-Katanga' },
    { code : 'mk', name : 'Macedonian' },
    { code : 'mg', name : 'Malagasy' },
    /*{ code : 'ms', name : 'Malay' },
    { code : 'ml', name : 'Malayalam' },*/
    { code : 'mt', name : 'Maltese' },
    /*{ code : 'gv', name : 'Manx' },
    { code : 'mi', name : 'Maori' },
    { code : 'mr', name : 'Marathi' },
    { code : 'mh', name : 'Marshallese' },*/
    { code : 'ro', name : 'Moldovan, Moldavian, Romanian' },
    { code : 'mn', name : 'Mongolian' },
    /*{ code : 'na', name : 'Nauru' },
    { code : 'nv', name : 'Navajo, Navaho' },
    { code : 'nd', name : 'Northern Ndebele' },
    { code : 'ng', name : 'Ndonga' },
    { code : 'ne', name : 'Nepali' },
    { code : 'se', name : 'Northern Sami' },*/
    { code : 'no', name : 'Norwegian' },
    { code : 'nb', name : 'Norwegian Bokmål' },
    { code : 'nn', name : 'Norwegian Nynorsk' },
    /*{ code : 'ii', name : 'Nuosu, Sichuan Yi' },
    { code : 'oc', name : 'Occitan (post 1500)' },
    { code : 'oj', name : 'Ojibwa' },
    { code : 'or', name : 'Oriya' },
    { code : 'om', name : 'Oromo' },
    { code : 'os', name : 'Ossetian, Ossetic' },
    { code : 'pi', name : 'Pali' },*/
    { code : 'pa', name : 'Panjabi, Punjabi' },
    //{ code : 'ps', name : 'Pashto, Pushto' },
    //{ code : 'fa', name : 'Persian' },
    { code : 'pl', name : 'Polish' },
    { code : 'pt', name : 'Portuguese' },
    /*{ code : 'qu', name : 'Quechua' },
    { code : 'rm', name : 'Romansh' },
    { code : 'rn', name : 'Rundi' },*/
    { code : 'ru', name : 'Russian' },
    /*{ code : 'sm', name : 'Samoan' },
    { code : 'sg', name : 'Sango' },
    { code : 'sa', name : 'Sanskrit' },*/
    { code : 'sc', name : 'Sardinian' },
    { code : 'sr', name : 'Serbian' },
    /*{ code : 'sn', name : 'Shona' },
    { code : 'sd', name : 'Sindhi' },
    { code : 'si', name : 'Sinhala, Sinhalese' },*/
    { code : 'sk', name : 'Slovak' },
    { code : 'sl', name : 'Slovenian' },
    /*{ code : 'so', name : 'Somali' },
    { code : 'st', name : 'Sotho, Southern' },
    { code : 'nr', name : 'South Ndebele' },*/
    { code : 'es', name : 'Spanish, Castilian' },
    { code : 'su', name : 'Sundanese' },
    /*{ code : 'sw', name : 'Swahili' },
    { code : 'ss', name : 'Swati' },*/
    { code : 'sv', name : 'Swedish' },
    /*{ code : 'tl', name : 'Tagalog' },
    { code : 'ty', name : 'Tahitian' },
    { code : 'tg', name : 'Tajik' },
    { code : 'ta', name : 'Tamil' },
    { code : 'tt', name : 'Tatar' },
    { code : 'te', name : 'Telugu' },*/
    { code : 'th', name : 'Thai' },
    { code : 'bo', name : 'Tibetan' },
    /*{ code : 'ti', name : 'Tigrinya' },
    { code : 'to', name : 'Tonga (Tonga Islands)' },
    { code : 'ts', name : 'Tsonga' },
    { code : 'tn', name : 'Tswana' },*/
    { code : 'tr', name : 'Turkish' },
    { code : 'tk', name : 'Turkmen' },
    /*{ code : 'tw', name : 'Twi' },*/
    { code : 'ug', name : 'Uighur, Uyghur' },
    { code : 'uk', name : 'Ukrainian' },
    //{ code : 'ur', name : 'Urdu' },
    { code : 'uz', name : 'Uzbek' },
    //{ code : 've', name : 'Venda' },
    { code : 'vi', name : 'Vietnamese' },
    /*{ code : 'vo', name : 'Volap_k' },
    { code : 'wa', name : 'Walloon' },*/
    { code : 'cy', name : 'Welsh' },
    { code : 'fy', name : 'Western Frisian' }
    /*{ code : 'wo', name : 'Wolof' },
    { code : 'xh', name : 'Xhosa' },
    { code : 'yi', name : 'Yiddish' },
    { code : 'yo', name : 'Yoruba' },
    { code : 'za', name : 'Zhuang, Chuang' },
    { code : 'zu', name : 'Zulu' }*/
];

export const emptyGuest: Guest = {
    
    guest_id: -1,
    guest_title: 'Herr',
    guest_firstname: '',
    guest_lastname: '',
    guest_company: '',
    guest_birthday_reminder: 0,
    guest_blacklist: 0,
    guest_rate: 2,
    guest_birthday: '0000-00-00',
    guest_street: '',
    guest_zip: '',
    guest_city: '',
    guest_country: '',
    guest_contact: '[]',
    guest_note: '[]',

};

export const emptyStay: Stay = {

    stay_id: -1,
    stay_guest: -1,
    stay_property: -1,
    stay_adults: 0,
    stay_children: 0,
    stay_pets: 0,
    stay_checkIn: getDateObj(new Date(), 0).dateStringUS,
    stay_checkOut: getDateObj(new Date(), 1).dateStringUS,
    stay_special_price: 0,
    stay_status: 0,
    stay_priceList: '[]',
    stay_import_portal: 'Direktbuchung',
    stay_group: '[]',
    stay_storno_val: 0,

}

export const emptyProperty: Property = {

    property_id: -1,
    property_name: ''/*'Not Available'*/,
    property_art: ''/*'Not Available'*/,
    property_bathroom: 0,
    property_bedroom: 0,
    property_beds: 0,
    property_build: 1990,
    property_kitchen: 0,
    property_level: '',
    property_levels: 0,
    property_livingroom: 0,
    property_squaremeters: 0,
    vermieter_img_uploaded: 0,
    property_img: '',

}

export const emptyLandlord: Landlord = {

    vermieter_id: -1,
    vermieter_title: 'Herr',
    vermieter_firstname: '',
    vermieter_lastname: '',
    vermieter_company: '',
    vermieter_taxval: 0,
    vermieter_taxnumber: '',
    vermieter_street: '',
    vermieter_zip: '',
    vermieter_city: '',
    vermieter_country: '',
    vermieter_contact: '[]',
    vermieter_img_uploaded: 0,
    vermieter_website: '',
    vermieter_img: '',
    user_folder: '',

}

export const emptyPrice: PriceObj = {

    price_id: -1,
    price_start: `${new Date().getFullYear()}-01-01`,
    price_end: `${new Date().getFullYear()}-12-31`,
    price_value: 0,
    price_from: 0,
    price_to: 0,
    price_additional: 0,
    price_additional_value: 0,

}

export const emptyDiscount: DiscountObj = {

    abz_id: -1,
    abz_name: '',
    abz_json: '{ "percentage": 1, "val": 0 }',

}

export const emptyInvoicePriceObj: InvoicePriceObj = {

    fullAmount: 0,
    netAmount: 0,
    nightsOnlyAmount: 0,
    addNightsOnlyAmount: 0,
    additionalServiceAmount: 0,
    additionalServiceAmountNoTax: 0,
    cityTaxAmount: 0,
    cityTaxAmountNoTax: 0,
    depositAmount: 0,
    cancellationFeeAmount: 0,

}

export const emptyPaymentDataObj: PaymentDataObj = {

    viewTitle: {title: 'Anzahlung', artikel: 'die'},
    amountType: PaymentAmountType.Deposit,
    amount: 0,
    paymentMethod: PaymentMethod.Cash,
    stayStatus: 0,

}

export const emptyAddiService: ZlObj = {

    zl_id: -1,
    zl_art: 0,
    zl_name: '',
    zl_price: 0,
    zl_tax: 0,
    zl_tax_percent: 7,

}

export const emptySpaTax: KtObj = {

    kt_id: -1,
    kt_art: 0,
    kt_name: '',
    kt_price: 0,
    kt_tax: 0,
    kt_tax_percent: 7,
    kt_start: `${new Date().getFullYear()}-01-01`,
    kt_end: `${new Date().getFullYear()}-12-31`,

}

export const emptyBankAccount: ZkObj = {

    zk_id: -1,
    zk_name: '',
    zk_iban: '',
    zk_bic: '',
    zk_paypal: '',

}

export const emptyExposeMainImgObj: ExposeObj = {

    ex_id: -1,
    ex_art: 'main_img',
    ex_img: null,
    ex_json: '{"main_title":"","additional_text":"","toggle": "toggle_off", "position": ""}',

}

export const emptyExposeMainDataObj: ExposeObj = {

    ex_id: -1,
    ex_art: 'main_data',
    ex_img: null,
    ex_json: '{"vermieter": "toggle_off","address": "toggle_on","property": "toggle_on", "service": "toggle_off", "price": "toggle_off", "zl": "toggle_off", "kt": "toggle_off", "storno": "toggle_off"}',

}

export const emptyExposeDescReminderObj: ExposeObj = {

    ex_id: -1,
    ex_art: 'hinweis',
    ex_img: null,
    ex_json: '{"text": "","toggle": "toggle_on"}',

}

export const emptyExposeDescLocationObj: ExposeObj = {

    ex_id: -1,
    ex_art: 'lage',
    ex_img: null,
    ex_json: '{"text": "","toggle": "toggle_on"}',

}

export const emptyExposeDescPropertyObj: ExposeObj = {

    ex_id: -1,
    ex_art: 'objekt',
    ex_img: null,
    ex_json: '{"text": "","toggle": "toggle_on"}',

}

export const emptyExposeDescOtherObj: ExposeObj = {

    ex_id: -1,
    ex_art: 'sonstiges',
    ex_img: null,
    ex_json: '{"text": "","toggle": "toggle_on"}',

}

export const emptyExposeImgCellObj: ExposeObj = {

    ex_id: -1,
    ex_art: 'image',
    ex_img: null,
    ex_json: '{"img_title": ""}',

}

export const emptyHandoverProtocolObj: HandoverProtocolObj = {

    text1: '',
    text2: '',
    amount: 0,
    img: -1,

}

export const conversationMailTypeArr: Array<ConversationMailObj> = [
    
    {text: 'Angebot', art: 0},
    {text: 'Buchung', art: 1},
    {text: 'Quittung', art: 2},
    {text: 'Rechnung', art: 3},
    {text: 'Erinnerung', art: 4},
    {text: 'Mahnung', art: 5},
    {text: 'Exposé', art: 6},
    {text: 'Übergabeprotokoll', art: 7},
    {text: 'E-Mail schreiben', art: 8}

];

export const emptyImportUrlObj: ImportUrlObj = {

    name: '',
    link: '',
    toggle: 'toggle_off',
    toggleBool: false,

}

export const emptyCalendarShareObj: CalendarShareObj = {

    share_id: -1,
    share_property: -1,
    share_from: 0,
    share_to: 11,
    share_year: new Date().getFullYear(),
    share_pwd: 0,
    share_pwd_value: '',
    share_link: '',
    share_toggle: 0,
    share_duration: 0,
    share_expiry_date: '',

}

export const currencyArr: Array<CurrencyObj> = [{currencyShort: 'AED', currencyLong: 'UAE Dirham'}, {currencyShort: 'AFN', currencyLong: 'Afghani'},
                    {currencyShort: 'ALL', currencyLong: 'Albanische Lek'}, {currencyShort: 'AMD', currencyLong: 'Armenian Dram'},
                    {currencyShort: 'ANG', currencyLong: 'Niederländische Antillen Gulden'}, {currencyShort: 'AOA', currencyLong: 'Kwanza'},
                    {currencyShort: 'ARS', currencyLong: 'Argentinische Pesos'}, {currencyShort: 'AUD', currencyLong: 'Australische Dollar'},
                    {currencyShort: 'AWG', currencyLong: 'Aruban Florin'}, {currencyShort: 'AZN', currencyLong: 'Azerbaijanian Manat'},
                    {currencyShort: 'BAM', currencyLong: 'Bosnische Mark'}, {currencyShort: 'BBD', currencyLong: 'Barbados Dollar'},
                    {currencyShort: 'BDT', currencyLong: 'Taka'}, {currencyShort: 'BGN', currencyLong: 'Bulgarian Lev'},
                    {currencyShort: 'BHD', currencyLong: 'Bahraini Dinar'}, {currencyShort: 'BIF', currencyLong: 'Burundi Franc'},
                    {currencyShort: 'BMD', currencyLong: 'Bermudian Dollar'}, {currencyShort: 'BND', currencyLong: 'Brunei Dollar'},
                    {currencyShort: 'BOB', currencyLong: 'Boliviano'}, {currencyShort: 'BOV', currencyLong: 'Mvdol'},
                    {currencyShort: 'BRL', currencyLong: 'Brasilianische Real'}, {currencyShort: 'BSD', currencyLong: 'Bahamian Dollar'},
                    {currencyShort: 'BTN', currencyLong: 'Ngultrum'}, {currencyShort: 'BWP', currencyLong: 'Pula'},
                    {currencyShort: 'BYR', currencyLong: 'Belarussian Ruble'}, {currencyShort: 'BZD', currencyLong: 'Belize Dollar'},
                    {currencyShort: 'CAD', currencyLong: 'Kanadische Dollar'}, {currencyShort: 'CDF', currencyLong: 'Congolese Franc'},
                    {currencyShort: 'CHE', currencyLong: 'WIR Euro'}, {currencyShort: 'CHF', currencyLong: 'Schweizer Franken'},
                    {currencyShort: 'CHW', currencyLong: 'WIR Franc'}, {currencyShort: 'CLP', currencyLong: 'Chilenische Pesos'},
                    {currencyShort: 'CNY', currencyLong: 'Chinesische Yuan'}, {currencyShort: 'COP', currencyLong: 'Columbianische Pesos'},
                    {currencyShort: 'CRC', currencyLong: 'Costa Rica Colon'}, {currencyShort: 'CUP', currencyLong: 'Cubanische Pesos'},
                    {currencyShort: 'CVE', currencyLong: 'Cape Verde Escudo'}, {currencyShort: 'CZK', currencyLong: 'Teschechische Krone'},
                    {currencyShort: 'DJF', currencyLong: 'Djibouti Franc'}, {currencyShort: 'DKK', currencyLong: 'Dänische Krone'},
                    {currencyShort: 'DOP', currencyLong: 'Dominikanische Pesos'}, {currencyShort: 'DZD', currencyLong: 'Algerische Dinar'},
                    {currencyShort: 'EGP', currencyLong: 'Ägyptisches Pfund'}, {currencyShort: 'ERN', currencyLong: 'Nakfa'},
                    {currencyShort: 'ETB', currencyLong: 'Ethopian Birr'}, {currencyShort: 'EUR', currencyLong: 'Euro'},
                    {currencyShort: 'FJD', currencyLong: 'Fiji Dollar'}, {currencyShort: 'FKP', currencyLong: 'Falkland Islands Pound'},
                    {currencyShort: 'GBP', currencyLong: 'Britische Pfund'}, {currencyShort: 'GEL', currencyLong: 'Lari'},
                    {currencyShort: 'GHS', currencyLong: 'Ghana Cedi'}, {currencyShort: 'GIP', currencyLong: 'Gibraltar Pound'},
                    {currencyShort: 'GMD', currencyLong: 'Dalasi'}, {currencyShort: 'GNF', currencyLong: 'Guinea Franc'},
                    {currencyShort: 'GTO', currencyLong: 'Quetzal'}, {currencyShort: 'GYD', currencyLong: 'Guyana Dollar'},
                    {currencyShort: 'HKD', currencyLong: 'Hongkong Dollar'}, {currencyShort: 'HNL', currencyLong: 'Lempira'},
                    {currencyShort: 'HRK', currencyLong: 'Kroatische Kuna'}, {currencyShort: 'HTG', currencyLong: 'Gourde'},
                    {currencyShort: 'HUF', currencyLong: 'Ungarische Forint'}, {currencyShort: 'IDR', currencyLong: 'Indonesische Rupie'},
                    {currencyShort: 'ILS', currencyLong: 'Israelische Schekel'}, {currencyShort: 'INR', currencyLong: 'Indische Rupie'},
                    {currencyShort: 'IQD', currencyLong: 'Iraqi Dinar'}, {currencyShort: 'IRR', currencyLong: 'Iranian Rial'},
                    {currencyShort: 'ISK', currencyLong: 'Iceland Krona'}, {currencyShort: 'JMD', currencyLong: 'Jamaican Dollar'},
                    {currencyShort: 'JOD', currencyLong: 'Jordanian Dinar'}, {currencyShort: 'JPY', currencyLong: 'Japanische Yen'},
                    {currencyShort: 'KES', currencyLong: 'Kenyan Shilling'}, {currencyShort: 'KGS', currencyLong: 'Som'},
                    {currencyShort: 'KHR', currencyLong: 'Riel'}, {currencyShort: 'KMF', currencyLong: 'Comoro Franc'},
                    {currencyShort: 'KPW', currencyLong: 'North Korean Won'}, {currencyShort: 'KRW', currencyLong: 'Won'},
                    {currencyShort: 'KWD', currencyLong: 'Kuwaiti Dinar'}, {currencyShort: 'KYD', currencyLong: 'Cayman Islands Dollar'},
                    {currencyShort: 'KZT', currencyLong: 'Tenge'}, {currencyShort: 'LAK', currencyLong: 'Kip'}, {currencyShort: 'LBP', currencyLong: 'Lebanese Pound'},
                    {currencyShort: 'LKR', currencyLong: 'Sri Lanka Rupee'}, {currencyShort: 'LRD', currencyLong: 'Liberian Dollar'},
                    {currencyShort: 'LSL', currencyLong: 'Loti'}, {currencyShort: 'LTL', currencyLong: 'Litauische Litas'},
                    {currencyShort: 'LVL', currencyLong: 'Lettländische Lats'}, {currencyShort: 'LYD', currencyLong: 'Libyan Dinar'},
                    {currencyShort: 'MAD', currencyLong: 'Moroccan Dirham'}, {currencyShort: 'MDL', currencyLong: 'Moldovan Leu'},
                    {currencyShort: 'MGA', currencyLong: 'Malagasy Ariary'}, {currencyShort: 'MKD', currencyLong: 'Mazedonische Denar'},
                    {currencyShort: 'MMK', currencyLong: 'Kyat'}, {currencyShort: 'MNT', currencyLong: 'Tugrik'},
                    {currencyShort: 'MOP', currencyLong: 'Pataca'}, {currencyShort: 'MRO', currencyLong: 'Ouguiya'},
                    {currencyShort: 'MUR', currencyLong: 'Mauritius Rupie'}, {currencyShort: 'MVR', currencyLong: 'Rufiyaa'},
                    {currencyShort: 'MWK', currencyLong: 'Kwacha'}, {currencyShort: 'MXN', currencyLong: 'Mexikanische Pesos'},
                    {currencyShort: 'MYR', currencyLong: 'Malaysian Ringgit'}, {currencyShort: 'MZN', currencyLong: 'Mozambique Metical'},
                    {currencyShort: 'NAD', currencyLong: 'Namibia Dollar'}, {currencyShort: 'NGN', currencyLong: 'Naira'},
                    {currencyShort: 'NIO', currencyLong: 'Cordoba Oro'}, {currencyShort: 'NOK', currencyLong: 'Norwegische Krone'},
                    {currencyShort: 'NPR', currencyLong: 'Nepalese Rupee'}, {currencyShort: 'NZD', currencyLong: 'Neuseeland Dollar'},
                    {currencyShort: 'OMR', currencyLong: 'Rial Omani'}, {currencyShort: 'PAB', currencyLong: 'Balboa'},
                    {currencyShort: 'PEN', currencyLong: 'Peru Nuevo'}, {currencyShort: 'PGK', currencyLong: 'Kina'},
                    {currencyShort: 'PHP', currencyLong: 'Philippinische Pesos'}, {currencyShort: 'PKR', currencyLong: 'Pakistan Rupee'},
                    {currencyShort: 'PLN', currencyLong: 'Polnische Zloty'}, {currencyShort: 'PYG', currencyLong: 'Guarani'},
                    {currencyShort: 'QAR', currencyLong: 'Qatari Rial'}, {currencyShort: 'RON', currencyLong: 'New Romanian Leu'},
                    {currencyShort: 'RSD', currencyLong: 'Serbische Dinar'}, {currencyShort: 'RUB', currencyLong: 'Russische Rubel'},
                    {currencyShort: 'RWF', currencyLong: 'Rwanda Franc'}, {currencyShort: 'SAR', currencyLong: 'Saudi Riyal'},
                    {currencyShort: 'SBD', currencyLong: 'Solomon Islands Dollar'}, {currencyShort: 'SCR', currencyLong: 'Seychelles Rupee'},
                    {currencyShort: 'SDG', currencyLong: 'Sudanese Pound'}, {currencyShort: 'SEK', currencyLong: 'Schwedische Krone'},
                    {currencyShort: 'SGD', currencyLong: 'Singapur Dollar'}, {currencyShort: 'SHP', currencyLong: 'Saint Helena Pound'},
                    {currencyShort: 'SLL', currencyLong: 'Leone'}, {currencyShort: 'SOL', currencyLong: 'Somali Shilling'},
                    {currencyShort: 'SRD', currencyLong: 'Surinam Dollar'}, {currencyShort: 'SSP', currencyLong: 'South Sudanese Pound'},
                    {currencyShort: 'STD', currencyLong: 'Dobra'}, {currencyShort: 'SVC', currencyLong: 'El Salvador Colon'},
                    {currencyShort: 'SYP', currencyLong: 'Syrian Pound'}, {currencyShort: 'SZL', currencyLong: 'Lilangeni'},
                    {currencyShort: 'THB', currencyLong: 'Thailändische Baht'}, {currencyShort: 'TJS', currencyLong: 'Somoni'},
                    {currencyShort: 'TMT', currencyLong: 'Turkmenistan New Manat'}, {currencyShort: 'TND', currencyLong: 'Tunisian Dinar'},
                    {currencyShort: 'TOP', currencyLong: 'Pa´anga'}, {currencyShort: 'TRY', currencyLong: 'Türkische Lira'},
                    {currencyShort: 'TTD', currencyLong: 'Trinidad and Tobago Dollar'}, {currencyShort: 'TWD', currencyLong: 'Taiwan Dollar'},
                    {currencyShort: 'TZS', currencyLong: 'Tanzanian Shilling'}, {currencyShort: 'UAH', currencyLong: 'Ukrainische Hrywnja'},
                    {currencyShort: 'UGX', currencyLong: 'Uganda Shilling'}, {currencyShort: 'USD', currencyLong: 'US Dollar'},
                    {currencyShort: 'UYU', currencyLong: 'Peso Uruguayo'}, {currencyShort: 'UZS', currencyLong: 'Uzbekistan Sum'},
                    {currencyShort: 'VEF', currencyLong: 'Bolivar Fuerte'}, {currencyShort: 'VND', currencyLong: 'Vietnamesische Dong'},
                    {currencyShort: 'VUV', currencyLong: 'Vatu'}, {currencyShort: 'WST', currencyLong: 'Tala'},
                    {currencyShort: 'XAF', currencyLong: 'CFA Franc BEAC'}, {currencyShort: 'XCD', currencyLong: 'East Caribbean Dollar'},
                    {currencyShort: 'XOF', currencyLong: 'CFA Franc BCEAO'}, {currencyShort: 'XPF', currencyLong: 'CFP Franc'},
                    {currencyShort: 'YER', currencyLong: 'Yemeni Rial'}, {currencyShort: 'ZAR', currencyLong: 'Rand'},
                    {currencyShort: 'ZMK', currencyLong: 'Zambian Kwacha'}, {currencyShort: 'ZWL', currencyLong: 'Zimbabwe Dollar'}];
                    
export const booking_cell_color = [' angelegt', ' angeboten', ' gebucht', ' angezahlt', ' bezahlt', ' blockiert', ' importiert']; //red --> angelegt