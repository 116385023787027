import { alphabet } from "../methods/constants";
import '../css/alphabetbar.css';
import { useState } from "react";

type Props = {

    selectedChar: string;
    setSelectedChar: (char: string) => void;

}

const AlphabetBar = ( { selectedChar, setSelectedChar }: Props ) => {

    return ( 

        <div className="shadowBox center h100prc" style={{ width: '30px', padding: '0', flexDirection: 'column', justifyContent: 'flex-start' }}>
            { alphabet.map( (char) => <div onClick={() => setSelectedChar(char)} key={char} className={ `alphabet-char${ char == selectedChar ? ' selected' : ''}` }>{char}</div> ) }
        </div>

     );
}
 
export default AlphabetBar;