import { ReactNode, useEffect } from "react";

type Props = {
    style?: React.CSSProperties | undefined;
    children: ReactNode;
    idTag?: string | undefined;
    handleClosePopUp?: () => void | undefined;
}

const PopUp = ( { idTag, style, children, handleClosePopUp }:Props ) => {

    useEffect(() => {

        if (handleClosePopUp) {
            document.body.addEventListener('click', handleClosePopUp );
        }

        return (() => {
            if (handleClosePopUp) {
                document.body.removeEventListener('click', handleClosePopUp);
            }
        });

    }, []);

    return ( 
        <div id={idTag} className="popup-view" style={style}>
            {children}
        </div>
     );
}
 
export default PopUp;