import { useMutation, useQueryClient } from "@tanstack/react-query";
import * as React from "react";
import { ReactElement, useId, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import { postUpdateStaySingleValue } from "../methods/http.stays.methods";
import { amountOfPriceListArr } from "../methods/price.calculation.methods";
import { AlertObj, CancellationObj, PriceListType, TaxType } from "../methods/types";

type Props = {
    stayId: number;
    cancellationObj: CancellationObj;
    priceData: {priceListString: string, specialPrice: number, nights: number, currency: string};
    handleClose: () => void;
}

const StayCancellationView = ( { stayId, cancellationObj, priceData, handleClose }:Props ) => {

    const queryClient = useQueryClient();

    const priceListArr = JSON.parse( priceData.priceListString );
    const stayPrice = priceData.specialPrice === 0 ? amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr ) : priceData.specialPrice;
    const stornoPrice = (cancellationObj && cancellationObj.property_storno) ? ( cancellationObj.property_storno_percent * stayPrice ) / 100 : 0;

    const inputRef = useRef<HTMLInputElement>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();

    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    let stornoVal = useRef(0);

    const TextContent = (): ReactElement => {

        if (cancellationObj && cancellationObj.property_storno) {

            return(

                <div style={{textAlign: 'center'}}>
                    {`Es sind ${priceData.nights} Tage bis zum Aufenthalt.`}<br/>
                    {`Bei kleiner ${cancellationObj.property_storno_days} Tagen werden ${cancellationObj.property_storno_percent} % vom Gesamtpreis berechnet.`}<br/>
                    {`Somit werden für diesen Aufenthalt ${stornoPrice.toFixed(2)} ${priceData.currency} (${cancellationObj.property_storno_percent} % von ${stayPrice.toFixed(2)} ${priceData.currency}) fällig.`}
                </div>

            );

        } else {
           return( <div>Keine Stornogebühren hinterlegt</div> );
        }

    }

    const handleSave = () => {
        
        if (!inputRef || Number(inputRef.current!.value) === 0 ) {

            setAlertObj( new AlertObj(true, 'Achtung', 'Gebe einen Wert größer 0 ein!', standardAlertButton) );

        } else {

            stornoVal.current = Number(inputRef.current!.value);

            postUpdateStornoMutation();

        }
        
    }

    const handleButtonClick = (e: React.SyntheticEvent) => {

        stornoVal.current = stornoPrice;

        postUpdateStornoMutation();

        e.stopPropagation();

    }

    const { mutate: postUpdateStornoMutation } = useMutation({
        mutationFn: () => postUpdateStaySingleValue(stayId, 'stay_storno_val', stornoVal.current.toString()),
        onSuccess: (data) => {

            if (data && data.success) {
                handleClose();
                queryClient.invalidateQueries({queryKey: ['fetchStays']});
            } else {
                setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
            }

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
        }
    })

    return ( 

        <ModalView modalViewId="stayCancallationView" style={{width: '500px'}} handleClose={handleClose}>
            <div className="flex center-y" style={{ width: "100%" }}>
                <div onClick={handleClose} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Stornierung</div>
                <div onClick={handleSave} className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>check_circle</div>
            </div>
            <div className="shadowBox center" style={{marginTop: '20px'}}><TextContent /></div>
            { (cancellationObj && cancellationObj.property_storno) &&
            <div className="flex center w100prc" style={{ flexDirection: 'column', padding: '20px 20px 0 20px'}}>
                <div onClick={handleButtonClick} className="rounded-btn">{`Für ${stornoPrice.toFixed(2)} ${priceData.currency} stornieren`}</div>
                <div style={{padding: '20px 0 0 0'}}>oder</div>
            </div>
            }
            <div className="flex center w100prc" style={{padding: '20px 0 10px 0'}}>
                <InputView style={{width: '70%'}} title={`Individuelle Stornogebühr in ${priceData.currency}`} >
                    <input type="number" ref={inputRef} defaultValue={0} style={{textAlign: 'center'}} />
                </InputView>
            </div>
            { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </ModalView>

     );
}
 
export default StayCancellationView;