import { memo, useContext } from "react";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import { getDateObj } from "../../methods/standard.methods";

const MultiCalendarDatesTopBar = () => {

    const { dates } = useContext(OccupancyContext);

    const todayStringUS = getDateObj( new Date(), 0).dateStringUS;


    return ( 

        <div className="border-bottom-strong" style={{ width: (dates.length * 60) + 'px', height: '40px' }}>
        {
            dates.map( date => {

                const color = todayStringUS === date.dateStringUS ? 'orange' : ( date.dayStringShort === 'Sa' || date.dayStringShort === 'So' ) ? 'var(--date-weekend-bg)' : 'transparent';

                return(

                    <div key={date.dateStringUS} id={date.dateStringUS} className="flex center column h100prc" style={{ width: '60px', fontSize: '0.8em', float: 'left', borderRight: '1px solid var(--line-color)', backgroundColor: color, }}>
                        <div className="flex center h100prc">{ date.dayStringShort }</div>
                        <div className="flex center h100prc">{ date.date.getDate() }.{ date.date.getMonth() + 1 }.</div>
                    </div>

                )
    
            })
        }
        </div>

     );
}
 
export default memo(MultiCalendarDatesTopBar);