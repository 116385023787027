import { ReactElement, useContext, useEffect, useId, useState } from "react";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import { AlertObj, DiscountObj, InfoWidgetObj, PaymentsViewType, PropertyListType, ViewState } from "../../../methods/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postUpdatePropertySingleValue } from "../../../methods/http.property.methods";
import InfoWidget from "../../../generalComps/InfoWidget";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { fetchDiscounts, postDeleteDiscount } from "../../../methods/http.discount.methods";
import { emptyDiscount } from "../../../methods/constants";
import DiscountCard from "./DiscountCard";
import DiscountAddEditView from "./DiscountAddEditView";

type Props = {
    handleClose: () => void;
}

const DiscountListView = ( { handleClose }: Props ) => {

    const queryClient = useQueryClient();

    const { setPaymentsViewType, selectedProperty, setSelectedProperty, propertyListType, setSelectedDiscount } = useContext( PropertysViewContext );

    const [isVisibleDiscountAddEditView, setIsVisibleDiscountAddEditView] = useState(false);

    const [discounts, setDiscounts] = useState<Array<DiscountObj>>([]);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const { mutate: fetchDiscountsMutation } = useMutation({
        mutationFn: () => fetchDiscounts(),
        onSuccess: (data) => {

            if (data.success) {
                setDiscounts( data.obj );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect( () => fetchDiscountsMutation(), [] );

    const NoData = (): ReactElement => {
        return <div className="shadowBox flex center w100prc">Keine Rabatte vorhanden</div>;
    }

    const handleDiscountCardClick = (state: ViewState, discount: DiscountObj) => {

        if (state === ViewState.Details) {

            const propertyDiscountIds: Array<{abz_id: number}> = selectedProperty.property_abz ? JSON.parse( selectedProperty.property_abz ) : [];

            if ( propertyDiscountIds.find( dId => dId.abz_id === discount.abz_id ) !== undefined ) {

                propertyDiscountIds.splice( propertyDiscountIds.findIndex( obj => obj.abz_id === discount.abz_id ), 1 );

            } else {

                propertyDiscountIds.push( {abz_id: discount.abz_id} );

            }

            postUpdatePropertySingleMutation( JSON.stringify( propertyDiscountIds ) );

        } else if (state === ViewState.Delete) {

            const alertButtons = [ {title: 'Löschen', handler: () => postDeleteDiscountMutation(discount.abz_id) }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Rabatt löschen?', `Soll dieser Rabatt wirklich gelöscht werden?`, alertButtons ) );

        } else if (state === ViewState.Edit) {

            setSelectedDiscount( discount );

            setIsVisibleDiscountAddEditView(true);

        }

    }

    const { mutate: postUpdatePropertySingleMutation } = useMutation({
        mutationFn: (discountIdString: string) => postUpdatePropertySingleValue(selectedProperty.property_id, 'property_abz', discountIdString),
        onSuccess: (_, variables) => {

            setSelectedProperty( prev => ( {...prev, property_abz: variables } ) );

            if (propertyListType === PropertyListType.Activated) {
                queryClient.invalidateQueries( { queryKey: ['fetchPropertys'] } );
            } else {
                queryClient.invalidateQueries( { queryKey: ['fetchDeactivatedPropertys'] } );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postDeleteDiscountMutation } = useMutation({
        mutationFn: (discountId: number) => postDeleteDiscount(discountId),
        onSuccess: () => {

            fetchDiscountsMutation();

            setAlertObj( new AlertObj() );

            setInfoWidgetObj( new InfoWidgetObj( true, 'Rabatt gelöscht.') )

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const handleSaveDiscount = (type: string, newDiscountId?: number | undefined) => {

        fetchDiscountsMutation();

        setIsVisibleDiscountAddEditView( false );
        setSelectedDiscount( emptyDiscount );

        setInfoWidgetObj( new InfoWidgetObj(true, `Rabatt erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

        if (type === 'new' && newDiscountId !== undefined && newDiscountId !== null ) {

            const alertButtons = [ {title: 'Verknüpfen', handler: () => {

                const propertyDiscountIds: Array<{abz_id: number}> = selectedProperty.property_abz ? JSON.parse( selectedProperty.property_abz ) : [];
                propertyDiscountIds.push( {abz_id: newDiscountId} );
                postUpdatePropertySingleMutation( JSON.stringify( propertyDiscountIds ) );
                
                setAlertObj( new AlertObj() );

            } }, { ...standardAlertButton[0], title: 'Abbrechen'} ];

            setAlertObj( new AlertObj( true, 'Rabatt verknüpfen?', `Den soeben angelegten Rabatt mit dieser Immobilie verknüpfen?`, alertButtons ) );

        }

    }

    return ( 

        <>
        <ModalView modalViewId="discountListView" style={{ width: '650px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="navigation-view-bar">
                <div onClick={ () => setPaymentsViewType( PaymentsViewType.MainMenu ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
                <div className="main-title">Rabatte</div>
                <div onClick={ () => { setSelectedDiscount(emptyDiscount); setIsVisibleDiscountAddEditView(true); } } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>add_circle</div>
            </div>
            <div className="flex center w100prc" style={{ flexDirection: 'column', justifyContent: 'flex-start', padding: '3px', marginTop: '10px' }}>
            { 

                discounts.length === 0 ?

                <NoData />

                :

                discounts.map( ( discount ) => {

                    return <DiscountCard key={discount.abz_id} discount={discount} handleOnClick={ (state) => handleDiscountCardClick(state, discount) } />;
        
                }) 

            }
            </div>
        </ModalView>
        { isVisibleDiscountAddEditView && <DiscountAddEditView handleCancel={ () => setIsVisibleDiscountAddEditView(false) } handleSave={ handleSaveDiscount } /> }
        { infoWidgetObj.isVisible && <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} /> }
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>
     );
}
 
export default DiscountListView;