import { useContext, useId, useRef, useState } from "react";
import InputView from "../generalComps/InputView";
import { AlertObj } from "../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import SalutationSelect from "../generalComps/SalutationSelect";
import { PaypalInvoiceViewType, SettingsViewContext } from "../contexts/SettingsContext";

type Props = {
    handleClose: () => void;
}

const PaypalInvoiceView = ( { handleClose }:Props ) => {

    const { invoiceObjRef, subscriptionObjRef, setInvoiceViewType } = useContext(SettingsViewContext);

    const [selectedItem, setSelectedItem] = useState( invoiceObjRef.current.user_title );

    const inputFirstnameRef = useRef<HTMLInputElement | null>(null);
    const inputLastnameRef = useRef<HTMLInputElement | null>(null);
    const inputCompanyRef = useRef<HTMLInputElement | null>(null);
    const inputStreetRef = useRef<HTMLInputElement | null>(null);
    const inputZipRef = useRef<HTMLInputElement | null>(null);
    const inputCityRef = useRef<HTMLInputElement | null>(null);
    const inputCountryRef = useRef<HTMLInputElement | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const handleSave = (e: React.SyntheticEvent) => {

        if (inputFirstnameRef.current!.value.length == 0 || inputLastnameRef.current!.value.length == 0 || inputStreetRef.current!.value.length == 0 || inputZipRef.current!.value.length == 0 || inputCityRef.current!.value.length == 0 || inputCountryRef.current!.value.length == 0) {

            setAlertObj( new AlertObj( true, 'Rechnungsinformationen unvollständig', 'Bitte fülle alle notwendigen Rechnungsinformationen aus.', standardAlertButton ) );

        } else {
            
            invoiceObjRef.current.user_title = selectedItem;
            invoiceObjRef.current.user_firstname = inputFirstnameRef.current!.value;
            invoiceObjRef.current.user_lastname = inputLastnameRef.current!.value;
            invoiceObjRef.current.user_company = inputCompanyRef.current!.value;
            invoiceObjRef.current.street = inputStreetRef.current!.value;
            invoiceObjRef.current.zip = inputZipRef.current!.value;
            invoiceObjRef.current.city = inputCityRef.current!.value;
            invoiceObjRef.current.country = inputCountryRef.current!.value;

            setInvoiceViewType( PaypalInvoiceViewType.InvoiceOverviewView );

        }

        e.stopPropagation();

    }

    return ( 
        <>
        <div className="flex center-y w100prc">
            <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>{subscriptionObjRef.current.item_name} für {subscriptionObjRef.current.item_price.toFixed(2)} {subscriptionObjRef.current.item_currency}</div>
            <div onClick={ handleSave } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
        </div>
        <div className="flex center-y w100prc color-lightblack" style={{ marginTop: '20px'}}><span className="color-black" style={{ paddingRight: '3px', textDecoration: 'underline'}}>Rechnungsinformationen</span> / Überprüfen & Bestellen</div>
        <SalutationSelect selection={["Herr", "Frau"]} selectedItem={selectedItem} handleSelection={(index) => setSelectedItem(index)} />
        <div className="flex center-y w100prc" style={{ gap: '20px', padding: '3px 0' }}>
            <InputView title="Vorname" style={{ width: '50%' }}><input ref={inputFirstnameRef} type="text" defaultValue={ invoiceObjRef.current.user_firstname } placeholder="z. B. Max" /></InputView>
            <InputView title="Nachname" style={{ width: '50%' }}><input ref={inputLastnameRef} type="text" defaultValue={ invoiceObjRef.current.user_lastname } placeholder="z. B. Mustermann" /></InputView>
        </div>

        <InputView title="Firmenname" style={{ marginTop: '10px' }}><input ref={inputCompanyRef} type="text" defaultValue={ invoiceObjRef.current.user_company } placeholder="z. B. Unreal GmbH" /></InputView>
        <InputView title="Straße & Hausnummer" style={{ marginTop: '30px' }}><input ref={inputStreetRef} type="text" defaultValue={ invoiceObjRef.current.street } placeholder="z. B. Musterweg 7" /></InputView>
        <div className="flex center-y w100prc" style={{ marginTop: '10px', gap: '20px', padding: '3px 0' }}>
            <InputView title="Postleitzahl" style={{ width: '30%' }}><input ref={inputZipRef} type="text" defaultValue={ invoiceObjRef.current.zip } placeholder="z. B. 88630" /></InputView>
            <InputView title="Wohnort" style={{ width: '70%' }}><input ref={inputCityRef} type="text" defaultValue={ invoiceObjRef.current.city } placeholder="z. B. Musterstadt" /></InputView>
        </div>
        <InputView title="Land" style={{ marginTop: '10px' }}><input ref={inputCountryRef} type="text" defaultValue={ invoiceObjRef.current.country } placeholder="z. B. Deutschland" /></InputView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default PaypalInvoiceView;