import { memo, useContext, useId, useMemo, useRef, useState } from "react";
import { AlertObj, InfoWidgetObj, PropertyPaymentObj, Stay, ViewState } from "../methods/types";
import StayCard from "../stayComps/StayCard";
import { StayType } from "./DashboardStays";
import { emptyStay } from "../methods/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchStay, postDeleteStay, postUpdateStaySingleValue } from "../methods/http.stays.methods";
import { StayDetailViewContext } from "../contexts/StayDetailViewContext";
import StayDetailView from "../stayComps/StayDetailView";
import { createPortal } from "react-dom";
import ModalView from "../generalComps/ModalView";
import StayCancellationView from "../stayComps/StayCancellationView";
import { getCancellationObject } from "../methods/price.calculation.methods";
import { PropertysContext } from "../contexts/PropertysContext";
import { daysBetweenDates, getDateObj, getFullname } from "../methods/standard.methods";
import ActionAlert from "../generalComps/ActionAlert";
import StayAddEditView from "../stayComps/StayAddEditView";
import InfoWidget from "../generalComps/InfoWidget";

type Props = {

    stayType: StayType;
    stays: Array<Stay>;
    selectedDate: {weekDay: string, dateStringShort: string, dateStringFull: string};

}

const DashboardStaysDetail = ( { stayType, selectedDate, stays }: Props ) => {

    const { propertys } = useContext(PropertysContext);
    const { setSelectedAddEditStay, setStay, stay, handleSelectedStay, setHandleSelectedStay } = useContext(StayDetailViewContext);

    const queryClient = useQueryClient();

    const [isVisibleStayDetailView, setIsVisibleStayDetailView] = useState(false);
    const [isVisibleStayAddEditView, setIsVisibleStayAddEditView] = useState(false);
    const [isVisibleCancellationView, setIsVisibleCancellationView] = useState(false);

    //const [stayObj, setStayObj] = useState( emptyStay );

    const nights = useMemo( () => daysBetweenDates( getDateObj( stay.stay_checkIn!, 0 ).date, getDateObj( stay.stay_checkOut!, 0 ).date ), [stay.stay_checkIn, stay.stay_checkOut]);
    const priceDataObj = {priceListString: stay.stay_priceList!, specialPrice: stay.stay_special_price!, nights: nights, currency: propertys.currencyShort};

    const [infoWidgetObj, setInfoWidgetObj] = useState( new InfoWidgetObj() );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const staysDetailTitle = (): string => {

        const today = new Date();
        today.setHours(0,0,0,0);
        const todayString = today.toLocaleDateString('fr-ca');

        const day = selectedDate.dateStringFull === todayString ? 'HEUTIGE' : selectedDate.weekDay + ' ' + selectedDate.dateStringShort;

        if (stayType === StayType.arrival) {
            return selectedDate.dateStringFull === todayString ? `${day} ANREISEN` : `ANREISEN ${day}`;
        } else if (stayType === StayType.departure) {
            return selectedDate.dateStringFull === todayString ? `${day} ABREISEN` : `ABREISEN ${day}`;
        } else if (stayType === StayType.current) {
            return 'AKTUELLE AUFENTHALTE';
        } else {
            return '';
        }

    }

    useMemo( () => {

        setIsVisibleStayDetailView( false );
            setStay(handleSelectedStay.stay);

            setTimeout(() => {
                
                if (handleSelectedStay.state === ViewState.Edit) {

                    setSelectedAddEditStay( handleSelectedStay.stay );
                    setIsVisibleStayAddEditView(true);
        
                } else if (handleSelectedStay.state === ViewState.Delete) {

                    const alertButtons = [ {title: 'Löschen', handler: () => handleDeleteStay() }, {title: 'Abbrechen', handler: () => setAlertObj(new AlertObj())} ];

                    const fullNameObj = getFullname(handleSelectedStay.stay.guest_title!, handleSelectedStay.stay.guest_firstname!, handleSelectedStay.stay.guest_lastname!, handleSelectedStay.stay.guest_company!);
                    
                    setAlertObj( new AlertObj( true, 'Aufenthalt löschen?', `Soll der Aufenthalt von ${ fullNameObj.fullname } im Objekt "${handleSelectedStay.stay.property_name!}" wirklich gelöscht werden?`, alertButtons ) );

                } else if (handleSelectedStay.state === ViewState.Cancellation) {
                    
                    if (!handleSelectedStay.stay.stay_storno_val || handleSelectedStay.stay.stay_storno_val! === 0) {
                        setIsVisibleCancellationView(true);
                    } else {

                        setAlertObj( new AlertObj( true, 'Stornierung aufheben?', `Aufenthalt ist bereits storniert. Stornogebühr: ${handleSelectedStay.stay.stay_storno_val!.toFixed(2)} ${propertys.currencyShort}. Stornierung aufheben?`, Array({title: 'Aufheben', handler: postUpdateStornoValueMutation }, { ...standardAlertButton[0], title: 'Abbrechen' }) ) );

                    }

                } else if (handleSelectedStay.state === ViewState.Details) {
                    
                    fetchStayMutation(handleSelectedStay.stay.stay_id);

                }

            }, 100);

    }, [handleSelectedStay]);

    const handleDeleteStay = () => {

        postDeleteStayMutation();

        setAlertObj(new AlertObj());

    }

    const { mutate: postDeleteStayMutation } = useMutation({
        mutationFn: () => postDeleteStay(stay.stay_id),
        onSuccess: () => {

            queryClient.invalidateQueries({ queryKey: ['dashboardStays'] });

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Fehler', 'Ein Fehler ist aufgetreten. Bitte versuche es später noch einmal.', standardAlertButton,) );
        },
    })

    const { mutate: fetchStayMutation } = useMutation({
        mutationFn: (stayId: number) => fetchStay(stayId),
        onSuccess: (data) => {

            if (data.success) {
                setStay(data.obj);
                setIsVisibleStayDetailView( true );
            }

        }
    });

    const { mutate: postUpdateStornoValueMutation } = useMutation({
        mutationFn: () => postUpdateStaySingleValue(stay.stay_id, 'stay_storno_val', '0'),
        onSuccess: () => {

            queryClient.invalidateQueries({ queryKey: ['dashboardStays'] });

            setAlertObj( new AlertObj() );

        },
        onError: () => {
            setAlertObj( new AlertObj(true, 'Achtung', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton) );
        },
    });

    const getPaymentObj = () => {

        const property = propertys.array.filter( prop => { return prop.property_id === stay.stay_property!; } )[0];

        const paymentObj: PropertyPaymentObj = property ? JSON.parse( property.property_pm! ) : null;

        return paymentObj;

    }

    const handleSaveStay = (type: string) => {

        setIsVisibleStayAddEditView( false );
        setStay( emptyStay );

        setInfoWidgetObj( new InfoWidgetObj(true, `Aufenthalt erfolgreich ${ type === 'new' ? 'hinzugefügt' : 'aktualisiert' }.`) );

        queryClient.invalidateQueries({ queryKey: ['dashboardStays'] });

    }

    return ( 
        <div className = "shadowBox statistics-small wrap" style={{marginTop: '10px'}}>
            <div className = "flex-container center-y w100prc" style = {{paddingBottom: '10px'}}>{staysDetailTitle()}</div>
            { stays.length === 0 && <div className="flex-container center w100prc">{ `Keine ${ stayType === StayType.arrival ? 'Anreisen' : stayType === StayType.departure ? 'Abreisen' : 'aktuellen Aufenthalte' }` }</div> }
            { stays.map( (stay) => <StayCard key={stay.stay_id} stay={stay} handleOnClick={ (state) => setHandleSelectedStay({ state: state, stay: stay}) }/> ) }
            { isVisibleStayDetailView && createPortal( 
                <ModalView modalViewId="stayDetailView" handleClose={ () => setIsVisibleStayDetailView(false) }>
                    <StayDetailView handleCancel={ () => setIsVisibleStayDetailView(false) } />
                </ModalView>, document.body ) }
            { isVisibleStayAddEditView && createPortal( 
                <ModalView modalViewId="stayAddEditView"  style={{ width: '900px', overflow: 'visible' }} handleClose={ () => setIsVisibleStayAddEditView(false) }>
                    <StayAddEditView handleCancel={ () => setIsVisibleStayAddEditView(false) } handleSave={ handleSaveStay }/>
                </ModalView>, document.body ) }
            { infoWidgetObj.isVisible && createPortal( <InfoWidget message={infoWidgetObj.msg} handleClose={(isVisible) => setInfoWidgetObj( new InfoWidgetObj(isVisible) )} />, document.body ) }
            { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
            { isVisibleCancellationView && createPortal( <StayCancellationView stayId={stay.stay_id} cancellationObj={ getCancellationObject( getPaymentObj(), stay.stay_checkIn!) } priceData={priceDataObj} handleClose={() => { setIsVisibleCancellationView(false); queryClient.invalidateQueries({ queryKey: ['dashboardStays'] }); } } />, document.body) }
        </div>
     );
}
 
export default memo(DashboardStaysDetail);