import { useContext, useEffect, useId, useMemo, useRef, useState } from "react";
import ActionAlert from "../../generalComps/ActionAlert";
import ModalView from "../../generalComps/ModalView";
import { AlertObj, CalendarShareObj, ImportUrlObj, Property } from "../../methods/types";
import InputView from "../../generalComps/InputView";
import { validURL } from "../../methods/standard.methods";
import { emptyProperty, monthsLong } from "../../methods/constants";
import { PropertysContext } from "../../contexts/PropertysContext";
import PopUp from "../../generalComps/PopUp";

type Props = {
    shareObj: CalendarShareObj;
    handleCancel: () => void;
    handleSave: (newShareObj: CalendarShareObj) => void;
}

const CalendarShareAddEditView = ( { shareObj, handleCancel, handleSave }:Props ) => {

    const { propertys } = useContext(PropertysContext);

    const propertysArr = useMemo( () => {

        const tempArr = [ {...emptyProperty, property_name: 'Alle Immobilien'} ];

        return tempArr.concat( propertys.array );

    }, [propertys.array]);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputPasswordRef = useRef<HTMLInputElement | null>(null);

    const [mutableShareObj, setMutableShareObj] = useState( shareObj );

    const [isVisiblePropertyPopUp, setIsVisiblePropertyPopUp] = useState(false);
    const [isVisibleFromPopUp, setIsVisibleFromPopUp] = useState(false);
    const [isVisibleToPopUp, setIsVisibleToPopUp] = useState(false);
    const [isVisibleYearPopUp, setIsVisibleYearPopUp] = useState(false);
    const [isVisibleDurationPopUp, setIsVisibleDurationPopUp] = useState(false);

    const selectedPropertyName = useMemo( () => {

        let name = 'Alle Immobilien';

        if ( mutableShareObj.share_property !== -1 ) {

            const tempProperty = propertys.array.find( prop => prop.property_id === mutableShareObj.share_property );

            if (tempProperty !== undefined) {
                name = tempProperty.property_name;
            }
            
        }

        return name;

    }, [mutableShareObj.share_property]);

    const selectedDurationText = useMemo( () => {

        let name = 'Unbegrenzt';

        if ( mutableShareObj.share_duration > 0 ) {

            name = `${mutableShareObj.share_duration} ${ mutableShareObj.share_duration === 1 ? 'Stunde' : 'Stunden' }`
            
        }

        return name;

    }, [mutableShareObj.share_duration]);

    const PropertyPopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px', height: 'auto' }} handleClosePopUp={ () => setIsVisiblePropertyPopUp(false) }>
            {propertysArr.map( (property) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => {

                        setMutableShareObj( prev => ( { ...prev, share_property: property.property_id } ));

                        setIsVisiblePropertyPopUp(false);

                        e.stopPropagation();

                    } }
                    key={property.property_id}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{property.property_name}</div>
                    {property.property_name === selectedPropertyName && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const MonthFromPopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px' }} handleClosePopUp={ () => setIsVisibleFromPopUp(false) }>
            {monthsLong.map( (month, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => {

                        if ( index > mutableShareObj.share_to ) {
                            setMutableShareObj( prev => ( { ...prev, share_from: index, share_to: index } ));
                        } else {
                            setMutableShareObj( prev => ( { ...prev, share_from: index } ));
                        }

                        setIsVisibleFromPopUp(false);

                        e.stopPropagation();

                    } }
                    key={index}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{month}</div>
                    {index === mutableShareObj.share_from && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const MonthToPopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px' }} handleClosePopUp={ () => setIsVisibleToPopUp(false) }>
            {monthsLong.map( (month, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => {

                        if ( index < mutableShareObj.share_from ) {
                            setMutableShareObj( prev => ( { ...prev, share_from: index, share_to: index } ));
                        } else {
                            setMutableShareObj( prev => ( { ...prev, share_to: index } ));
                        }

                        setIsVisibleToPopUp(false);

                        e.stopPropagation();

                    } }
                    key={index}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{month}</div>
                    {index === mutableShareObj.share_to && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const YearPopUp = () => {

        const start_year = 2018;
        const yearArr = Array<number>();

        for (let i = 0; i <= 40; i++) {
            
            yearArr.push(start_year + i);
        }

        return (
            <PopUp style={{ marginTop: '30px' }} handleClosePopUp={ () => setIsVisibleYearPopUp(false) }>
            {yearArr.map((yyear) => {
                return (
                <div
                    className="lbl-container"
                    onClick={ (e) => {

                        setMutableShareObj( prev => ( { ...prev, share_year: yyear } ));

                        setIsVisibleYearPopUp(false);

                        e.stopPropagation();

                    } }
                    key={yyear.toString()}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{yyear.toString()}</div>
                    {yyear === mutableShareObj.share_year && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const DurationPopUp = () => {

        const durationArr: Array<string> = ['Unbegrenzt'];

        for (let i = 1; i <= 24; i++) {
            
            durationArr.push(`${i} ${ i === 1 ? 'Stunde' : 'Stunden' }`);

        }

        return (
            <PopUp style={{ marginTop: '30px' }} handleClosePopUp={ () => setIsVisibleDurationPopUp(false) }>
            {durationArr.map((duration, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={ (e) => {

                        setMutableShareObj( prev => ( { ...prev, share_duration: index } ));

                        setIsVisibleDurationPopUp(false);

                        e.stopPropagation();

                    } }
                    key={index}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{duration}</div>
                    {index === mutableShareObj.share_duration && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleSaveOnClick = () => {
        
        if ( mutableShareObj.share_pwd === 1 && inputPasswordRef.current!.value.length === 0 ) {
        
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte Passwort eingeben oder deaktivieren.', standardAlertButton ) );
            
        } else {

            const tempShareObj = { ...mutableShareObj, share_pwd_value: inputPasswordRef.current!.value };

            handleSave( tempShareObj );

        }

    }

    return ( 

        <>
        <ModalView modalViewId="importUrlAddEditView" style={{ width: '550px', overflow: 'visible' }} handleClose={ handleCancel }>
            <div className="flex center-y w100prc">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Freigabe</div>
                <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>

            <div className="flex center-y w100prc" style={{ margin: '10px 0' }}>IMMOBILIE</div>
            <div onClick={ () => setIsVisiblePropertyPopUp(true) } className="clickable-popUpButton">
                { selectedPropertyName }
                { isVisiblePropertyPopUp && <PropertyPopUp  /> }
            </div>

            <div className="flex center-y w100prc" style={{ margin: '10px 0' }}>ZEITRAUM</div>
            <div className="flex center w100prc" style={{ gap: '10px', padding: '2px 0', overflow: 'visible', }}>
                <div onClick={ () => setIsVisibleFromPopUp(true) } className="clickable-popUpButton w100prc">
                    { monthsLong[mutableShareObj.share_from] }
                    { isVisibleFromPopUp && <MonthFromPopUp  /> }
                </div>
                -
                <div onClick={ () => setIsVisibleToPopUp(true) } className="clickable-popUpButton w100prc">
                    { monthsLong[mutableShareObj.share_to] }
                    { isVisibleToPopUp && <MonthToPopUp  /> }
                </div>
                <div onClick={ () => setIsVisibleYearPopUp(true) } className="clickable-popUpButton w100prc">
                    { mutableShareObj.share_year }
                    { isVisibleYearPopUp && <YearPopUp  /> }
                </div>
            </div>

            <div className="flex center-y w100prc" style={{ margin: '10px 0' }}>FREIGABEDAUER</div>
            <div onClick={ () => setIsVisibleDurationPopUp(true) } className="clickable-popUpButton">
                { selectedDurationText }
                { isVisibleDurationPopUp && <DurationPopUp  /> }
            </div>

            <InputView title={`Passwort ${ mutableShareObj.share_pwd === 0 ? 'deaktiviert' : 'aktiviert' }`} style={{ marginTop: '20px' }}>
                <div onClick={ () => setMutableShareObj( prev => ( { ...prev, share_pwd: prev.share_pwd === 0 ? 1 : 0 } ) ) } className="clickable-icon" style={{ position: 'absolute', right: '5px', top: '0', fontSize: '1.5em' }}>{ mutableShareObj.share_pwd === 0 ? 'toggle_off' : 'toggle_on' }</div>
                <input ref={inputPasswordRef} type="password" placeholder="Passwort" disabled={ mutableShareObj.share_pwd === 0 ? true : false }/>
            </InputView>

        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default CalendarShareAddEditView;