import { useContext } from "react";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import { amountOfPaymentListArr } from "../../methods/price.calculation.methods";
import { PaymentAmountType, PaymentSelectionType } from "../../methods/types";
import MenuButton from "../../generalComps/MenuButton";

type Props = {
    handlePaymentViewType: (viewType: PaymentSelectionType) => void;
    handleClose: () => void;
}

const AmountTypeSelectionView = ( { handlePaymentViewType, handleClose }:Props ) => {

    const { stay, paymentArr, invoicePriceObj, setPaymentDataObj } = useContext(StayDetailViewContext);

    let arr = Array<{title: string, type: PaymentSelectionType}>();
    let helpBoxText = '';

    if (stay.stay_storno_val === 0 ) {

        arr.push({title: 'Gesamtbetrag', type: PaymentSelectionType.PaymentTypeView});
        arr.push({title: 'Teilbetrag', type: PaymentSelectionType.PartTypeView});

        helpBoxText = 'Wähle hier, ob der Gesamt- oder ein Teilbetrag beglichen wurde.';

        if (paymentArr.length != 0) {
            arr[0].title = 'Restbetrag';

            helpBoxText = 'Wähle hier, ob der Rest- oder ein weiterer Teilbetrag beglichen wurde.';
        } 

    } else {

        arr.push({title: 'Stornobetrag', type: PaymentSelectionType.PaymentTypeView});

        helpBoxText = 'Da es sich um einen stornierten Aufenthalt handelt, kannst du hier nur den Stornobetrag abrechnen.';

    }

    const handleClick = (viewType: PaymentSelectionType, title: string) => {

        let amount = 0;

        if (title === 'Gesamtbetrag') {
            amount = invoicePriceObj.fullAmount;
        } else if (title === 'Restbetrag' || title === 'Teilbetrag') {
            amount = invoicePriceObj.fullAmount - amountOfPaymentListArr(paymentArr);
        } else if (title === 'Stornobetrag') {
            amount = invoicePriceObj.cancellationFeeAmount;
        } 

        setPaymentDataObj( prevObj => ({...prevObj, amount: amount, viewTitle: {title: title, artikel: 'der'}}) );

        handlePaymentViewType(viewType);

    }

    return ( 

        <>
        <div className="flex center-y w100prc">
            <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px', }}>cancel</div>
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.2rem', paddingRight: '40px', }}>Zahlungsart</div>
        </div>
        <div className="shadowBox center"style={{margin: '20px 0'}}>{helpBoxText}</div>

        { arr.map( obj => {
            return <MenuButton style={{ marginBottom: '5px'}} key={obj.title} onClick={() => handleClick(obj.type, obj.title) } rightIcon="chevron_right" buttonTitle={obj.title} />
        })}
        </>

     );
}
 
export default AmountTypeSelectionView;