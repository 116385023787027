import { useRef, useState } from "react";
import { createPortal } from "react-dom";
import InfoWidget from "../generalComps/InfoWidget";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import { InfoWidgetObj } from "../methods/types";
import { validateMail } from "../methods/validation.methods";

type Props = {

    address: string;
    handleSuccessfullSave: (newAddress: string, isNew: boolean) => void;
    handleClose: () => void;

}

const MailAddressAddEditView = ( { address, handleSuccessfullSave, handleClose }:Props ) => {

    const inputRef = useRef<HTMLInputElement | null>(null);

    const isNew = address.length === 0 ? true : false;

    const [infoWidgetObj, setInfoWidgetObj] = useState<InfoWidgetObj>( new InfoWidgetObj() );

    const handleSave = (e: React.SyntheticEvent) => {

        if ( !validateMail(inputRef.current!.value) ) {

            setInfoWidgetObj( new InfoWidgetObj(true, 'Prüfe das E-Mail-Format') );

        } else {

            handleSuccessfullSave( inputRef.current!.value, isNew );

        }

        e.stopPropagation();

    }

    return ( 

        <ModalView style={{ width: '400px' }} modalViewId="mailAddressAddEditView" handleClose={ handleClose }>
            <div className="flex center-y" style={{ width: "100%" }}>
                <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>E-Mail { address.length === 0 ? 'hinzufügen' : 'bearbeiten' }</div>
                <div title="Speichern" onClick={ handleSave } className="clickable-icon" style={{ textAlign: "right", fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <InputView title="E-Mail-Adresse" style={{ marginTop: '10px' }} errorClass={ infoWidgetObj.isVisible ? ' error' : '' }><input ref={ inputRef } type="text" defaultValue={ address } placeholder="z. B. max.mustermann@mail.de"></input></InputView>
        </ModalView>

     );
}
 
export default MailAddressAddEditView;