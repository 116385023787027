import { useContext, useEffect, useId, useState } from "react";
import { SettingsViewContext } from "../contexts/SettingsContext";
import { useMutation } from "@tanstack/react-query";
import { fetchPayment, fetchStoreData, fetchUserData } from "../methods/http.user.methods";
import { AlertObj, StoreDescriptionObj, StoreItemObj, StoreObj, StorePaymentObj, UserAddress, UserData } from "../methods/types";
import { createSubscriptionId, postCancelSubscription, postUpdateDatabase } from "../methods/http.paypal.methods";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import PaypalInvoiceWrapper from "./PaypalInvoiceWrapper";

const SubscriptionView = () => {

    const { setSettingsViewType, lastSettingsViewTypeRef, subscriptionObjRef, invoiceObjRef, isVisiblePaypalInvoiceView, setIsVisiblePaypalInvoiceView } = useContext(SettingsViewContext);

    const [storeData, setStoreData] = useState<StoreObj | null>(null);
    const [storePayment, setStorePayment] = useState<StorePaymentObj | null>(null);
    const [storeDescriptionData, setStoreDescriptionData] = useState<Array<StoreDescriptionObj> | null>(null);
    const [storeItemData, setStoreItemData] = useState<Array<StoreItemObj> | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const { mutate: fetchStoreDataMutation } = useMutation({
        mutationFn: () => fetchStoreData(),
        onSuccess: (data) => {

            setStoreData( data.obj[0] );
            
            fetchPaymentMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: fetchPaymentMutation } = useMutation({
        mutationFn: () => fetchPayment(),
        onSuccess: (data) => {

            setStorePayment( data.obj[data.obj.length-1] )

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    useEffect(() => fetchStoreDataMutation(), [] );

    useEffect(() => {

        if (storeData !== null) {

            setStoreDescriptionData( JSON.parse( storeData.store_json_description ) );
            setStoreItemData( JSON.parse( storeData.store_json_price_items ) );

        }

    }, [storeData])

    const { mutate: createSubscriptionIdMutation } = useMutation({
        mutationFn: (item: StoreItemObj) => createSubscriptionId(item.plan_id /*FOR SANDBOX'P-08H73191WM6593615MQJCRVY'*/),
        onSuccess: (data, variables) => {

            if (data.id && data.id.length > 0) {

                subscriptionObjRef.current.item_name = variables.title;
                subscriptionObjRef.current.item_id = variables.id;
                subscriptionObjRef.current.item_plan_id = variables.plan_id;
                subscriptionObjRef.current.item_price = variables.price;
                subscriptionObjRef.current.item_currency = variables.currency;
                subscriptionObjRef.current.subscription_id = data.id;

                fetchUserDataMutation();

            } else {

                setAlertObj( new AlertObj( true, 'Fehler', 'Ein Fehler ist aufgetreten. Versuche es später noch einmal.', standardAlertButton ) );

            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: fetchUserDataMutation } = useMutation({
        mutationFn: () => fetchUserData(),
        onSuccess: (data) => {

            const user: UserData = data.obj[0];
            const userAddress: UserAddress = ( user.user_address === null || ( user.user_address !== null && user.user_address.length === 0)) ? {street: '', zip: '', city: '', country: ''} : JSON.parse( user.user_address );

            invoiceObjRef.current.user_title = user.user_title;
            invoiceObjRef.current.user_firstname = user.user_firstname;
            invoiceObjRef.current.user_lastname = user.user_lastname;
            invoiceObjRef.current.user_company = user.user_company;
            invoiceObjRef.current.street = userAddress.street;
            invoiceObjRef.current.zip = userAddress.zip;
            invoiceObjRef.current.city = userAddress.city;
            invoiceObjRef.current.country = userAddress.country;

            setIsVisiblePaypalInvoiceView(true);

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const cancelSubscription = () => {

        const alertBtns = [{ title: 'Kündigen', handler: postCancelSubscriptionMutation }, { ...standardAlertButton[0], title: 'Abbrechen' }]

        setAlertObj( new AlertObj( true, 'Abo kündigen?', 'Nach erfolgreicher Kündigung wirst du Eazyac noch bis zum ' + storePayment!.expiry_date_frmd + ' nutzen können. Möchtest du dieses Abo wirklich kündigen?', alertBtns ) );

    }

    const { mutate: postCancelSubscriptionMutation } = useMutation({
        mutationFn: () => postCancelSubscription(storePayment!.transaction_id!),
        onSuccess: () => {

            postUpdateDatabaseMutation();

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    const { mutate: postUpdateDatabaseMutation } = useMutation({
        mutationFn: () => postUpdateDatabase(storePayment!.transaction_id!, invoiceObjRef.current),
        onSuccess: () => {

            const alertBtns = [{ title: 'Verstanden', handler: () => {

                fetchPaymentMutation();
                setAlertObj( new AlertObj() );

            } }]

            setAlertObj( new AlertObj( true, 'Kündigung', 'Deine Kündigung war erfolgreich. Du kannst Eazyac noch bis zum ' + storePayment!.expiry_date_frmd + ' nutzen.', alertBtns ) );

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 
        <>
        <div className="flex center-y w100prc">
            <div onClick={ () => setSettingsViewType( lastSettingsViewTypeRef.current ) } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>chevron_left</div>
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem', paddingRight: '40px'}}>{ storeData !== null ? storeData.store_title : '' }</div>
        </div>
        {
            ( storeDescriptionData === null || storeItemData === null ) ?

            <div className="shadowBox center" style={{ marginTop: '20px' }}>Daten werden geladen...</div>

            :
            <>
            <div className="shadowBox center column" style={{ marginTop: '20px' }}>
                {
                    storeDescriptionData!.map( (desc, index) => {

                        return(
                            <div key={index} className="flex center-y w100prc" style={{ padding: '2px 0' }}>
                                <div className="default-icon" style={{ fontSize: '1.3em' }}>{desc.icon}</div>
                                <div className="flex center-y" style={{ padding: '0 5px' }}>{ desc.text }</div>
                            </div>
                        )

                    } )
                }
            </div>
            {
                storePayment &&
                
                <div className="shadowBox column" style={{ marginTop: '10px' }}>
                    <div dangerouslySetInnerHTML={ {__html: storePayment.body} }></div>

                {
                    storePayment.payment_status === 'ACTIVE' &&
                    <div className="flex center w100prc" style={{ marginTop: '20px' }}>
                       <div onClick={ cancelSubscription } className="flex center cursor-pointer">
                            <div className="default-icon" style={{ fontSize: '1.5em', width: '40px' }}>shopping_cart_off</div>
                            <div className="flex center">Abonnement kündigen</div>
                       </div>
                    </div>
                }

                </div>

            }
            
            {
                (storePayment === null || (storePayment && storePayment.payment_status === 'TRIAL')) &&

                <div className="flex center w100prc" style={{ gap: '10px', marginTop: '10px' }}>
                {
                    storeItemData.map( item => {

                        return(
                            <div onClick={ () => createSubscriptionIdMutation(item) } key={item.plan_id} className="shadowBox list-card center w100prc cursor-pointer">
                                <div className="flex center color-lightblack" style={{ padding: '10px' }}>{item.title}</div>
                                <div className="flex center" style={{ fontSize: '1.6em', padding: '10px' }}>{item.price} {item.currency}</div>
                                <div className="flex center color-lightblack" style={{ padding: '10px' }}>{item.description}</div>
                                <div className="default-icon flex center" style={{ fontSize: '2.0em', padding: '10px' }}>add_shopping_cart</div>
                            </div>
                        )

                    })
                }
                </div>
            }
            </>
        }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        { isVisiblePaypalInvoiceView && createPortal( <PaypalInvoiceWrapper handleClose={ () => setIsVisiblePaypalInvoiceView(false) } />, document.body ) }
        </>
     );
}
 
export default SubscriptionView;