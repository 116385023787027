import { useContext, useMemo } from "react";
import { OccupancyModulContext } from "../../../contexts/OccupancyModulContext";
import { monthsLong, monthsShort } from "../../../methods/constants";

const SingleListCalendarPropertys = () => {

    const { year, startMonth, endMonth } = useContext(OccupancyModulContext);

    const handleScrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        
        const element = document.getElementById('singleListCalendarContentTable');

        if (element) {
            element.scrollTop = e.currentTarget.scrollTop;
        }

    };

    const filteredMonths = useMemo( () => {

        return monthsLong.filter( ( _, index ) => { return index >= startMonth && index <= endMonth } );

    }, [startMonth, endMonth]);

    return ( 

        <>
        <div onScroll={ handleScrollEvent } id="singleListCalendarMonthTable" className="shadowBox">
            {
                filteredMonths.map( (month, index) => {

                    let monthString = `${ month } ${ year }`;

                    if ( window.innerWidth < 759 ) {
                        monthString = `${ monthsShort[index] } ${ year.toString().substring(2) }`;
                    }

                    return <div className="monthcard border-bottom-strong" key={index} style={{ background: index % 2 === 0 ? 'var(--tile-bg-hover)' : 'var(--alert-btn-hover)' }}>
                        { monthString }
                    </div>

                })
            }
        </div>
        </>

     );
}
 
export default SingleListCalendarPropertys;