import { CSSProperties, useEffect, useId, useState } from "react";

type Props = {
    urlContentString: string;
    style?: CSSProperties | undefined;
    handleImgData: (imgData: string) => void;
}

const ImageSelectionView = ( { urlContentString, style, handleImgData }: Props ) => {

    const [urlContent, setUrlContent] = useState(urlContentString);

    const uniqueId = useId();

    useEffect( () => {

        setUrlContent( urlContentString );

    }, [urlContentString]);

    const openFileDialog = () => {

        var clickEvent = new MouseEvent("click", {
            "view": window,
            "bubbles": true,
            "cancelable": false
        });

        document.getElementById(`file-dialog-${uniqueId}`)?.dispatchEvent(clickEvent);

    }

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        //let filename = e.target.value.replace(/.*[\/\\]/, '');

            if (e.target.files && e.target.files[0]) {
        
                var reader = new FileReader();
        
                reader.onload = function (e) {
                    
                    setUrlContent( e.target!.result!.toString() );

                    handleImgData( e.target!.result!.toString() );
                    
                };
        
                reader.readAsDataURL(e.target.files[0]);
            }

    }

    return ( 

        <>
        <div onClick={ openFileDialog } className="property-img" style={style}>
            <img className={ `portrait${ urlContent === 'ef_std_img.jpg' ? ' blur' : '' }` } src={urlContent} alt="Immobilie" />
        </div>
        <input onChange={(e) => handleFileChange(e)} type="file" accept="image/*" id={`file-dialog-${uniqueId}`} style={{display: 'none'}} />
        </>

     );
}
 
export default ImageSelectionView;