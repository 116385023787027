import { useContext, useState } from "react";
import PropertyDetailViewHeader from "./PropertyDetailViewHeader";
import PropertyDetailViewServices from "./PropertyDetailViewServices";
import Landlord from "./landlordComps/Landlord";
import { PropertysViewContext } from "../contexts/PropertysMainContext";
import { PaymentsViewType } from "../methods/types";
import { createPortal } from "react-dom";
import PaymentsWrapper from "./paymentComps/PaymentsMainView";
import PriceList from "./paymentComps/priceComps/PriceList";
import PaymentsGeneralView from "./paymentComps/PaymentsGeneralView";
import DiscountListView from "./paymentComps/discountComps/DiscountListView";
import AddiServiceListView from "./paymentComps/addiServiceComps/AddiServiceListView";
import SpaTaxListView from "./paymentComps/spaTaxComps/SpaTaxListView";
import BankAccountListView from "./paymentComps/bankAccountComps/BankAccountListView";
import ExposeView from "./exposeComps/ExposeView";
import HandoverView from "./handoverComps/HandoverView";

type Props = {
    handleCancel: () => void;
}

const PropertyDetailView = ( { handleCancel }: Props ) => {

    const { setPaymentsViewType, paymentsViewType } = useContext(PropertysViewContext);

    const [isVisiblePaymentsMainView, setIsVisiblePaymentsMainView] = useState(false);
    //const [isVisiblePaymentsSubView, setIsVisiblePaymentsSubView] = useState(false);

    // const handleCellClick = () => {

    //   if (paymentsViewType === PaymentsViewType.MainMenu) {

    //     setIsVisiblePaymentsSubView(false);
    //     setIsVisiblePaymentsMainView(true);

    //   } else {

    //     setIsVisiblePaymentsMainView(false);
    //     setIsVisiblePaymentsSubView(true);

    //   }

    // }

    // const PaymentsSubview = (): ReactElement => {

    //   switch (paymentsViewType) {

    //     case PaymentsViewType.MainMenu:
    //       return <PaymentsWrapper handleClose={ () => setIsVisiblePaymentsMainView(false) } />;

    //     case PaymentsViewType.General:
    //       return <PaymentsGeneralView handleClose={ () => setIsVisiblePaymentsMainView(false) } />;

    //     case PaymentsViewType.Prices:
    //       return <PriceList handleClose={ () => setIsVisiblePaymentsMainView(false) } />;
      
    //     default:
    //       return <div></div>;
    //   }

    // }
    
    return ( 

        <>
        <div className="navigation-view-bar">
          <div onClick={handleCancel} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
          <div onClick={ () => { setPaymentsViewType( PaymentsViewType.MainMenu ); setIsVisiblePaymentsMainView(true); } } className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>more_horiz</div>
        </div>
        <PropertyDetailViewHeader style={{marginTop: '20px', overflowY: 'visible'}} />
        <Landlord style={{marginTop: '10px'}} />
        <PropertyDetailViewServices style={{marginTop: '10px'}} />
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.MainMenu) && createPortal( <PaymentsWrapper handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.General) && createPortal( <PaymentsGeneralView handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.Prices) && createPortal( <PriceList handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.Discounts) && createPortal( <DiscountListView handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.AdditionalServices) && createPortal( <AddiServiceListView handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.CityTaxes) && createPortal( <SpaTaxListView handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.Accounts) && createPortal( <BankAccountListView handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.Expose) && createPortal( <ExposeView handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        { (isVisiblePaymentsMainView && paymentsViewType === PaymentsViewType.Handoverprotocol) && createPortal( <HandoverView handleClose={ () => setIsVisiblePaymentsMainView(false) } />, document.body ) }
        </>

     );
}
 
export default PropertyDetailView;