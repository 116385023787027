import { useMutation } from "@tanstack/react-query";
import { useId, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import { postUpdateUserMail } from "../methods/http.user.methods";
import { AlertObj } from "../methods/types";

type Props = {
    email: string;
    reloadFunc: () => void;
    handleClose: () => void;
}

const UserEmailAddEditView = ( { email, reloadFunc, handleClose }:Props ) => {

    const inputEmailRef = useRef<HTMLInputElement | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const { mutate: postUpdateUserMailMutation } = useMutation({
        mutationFn: () => postUpdateUserMail(inputEmailRef.current!.value),
        onSuccess: (data) => {

            if (data.success) {

                reloadFunc();

                handleClose();

            } else {
                setAlertObj( new AlertObj( true, data.response_title, data.response_text, standardAlertButton ) );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 
        <>
        <ModalView modalViewId="userEmailAddEditView" style={{ width: '700px', overflow: 'visible' }} handleClose={ handleClose }>
            <div className="flex center-y w100prc">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>E-Mail</div>
                <div onClick={ () => postUpdateUserMailMutation() } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <InputView title="E-Mail-Adresse" style={{ marginTop: '20px' }}><input ref={inputEmailRef} type="text" defaultValue={ email } placeholder="z. B. max.mustermann@mail.de" /></InputView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default UserEmailAddEditView;