type Props = {
    title: string
    iconName1: string;
    iconName2: string;
    iconName3: string;
    inputPlaceholder: string;
    searchString: string;
    setSearchString: (searchString: string) => void;
    //isVisibleAddEditView: boolean;
    handleClick1: () => void;
    handleClick2: () => void;
    handleClick3: () => void;
}

const SearchBar = ( { title, iconName1, iconName2, iconName3, inputPlaceholder, searchString, setSearchString, handleClick1, handleClick2, handleClick3 }: Props ) => {
    return ( 

        <div className="shadowBox w100prc h100prc" style={{ gap: '15px', flexDirection: 'column' }}>
            <div className="flex w100prc">
                { iconName1.length > 0 && <div onClick={handleClick1} className="clickable-icon flex center h100prc">{ iconName1 }</div> }
                <div className="flex center w100prc h100prc" style={{ fontSize: '1.3em' }}>{ title }</div>
                { iconName2.length > 0 && <div onClick={handleClick2} className="clickable-icon flex center h100prc">{ iconName2 }</div> }
                { iconName3.length > 0 && <div onClick={handleClick3} className="clickable-icon flex center h100prc">{ iconName3 }</div> }
            </div>
            <div className="flex w100prc input-template-style" style={{ flexDirection: 'row', flexWrap: 'nowrap', padding: '10px 0', height: '50px' }}>
                <div className="default-icon flex center h100prc" style={{ width: '40px', padding: '0 0 0 15px' }}>search</div>
                <input onChange={ (e) => setSearchString(e.target.value) } value={searchString} className="w100prc h100prc" type="text" placeholder={inputPlaceholder}/>
            </div>
        </div>

     );
}
 
export default SearchBar;