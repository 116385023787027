import { useMutation } from "@tanstack/react-query";
import { useId, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import InputView from "../generalComps/InputView";
import ModalView from "../generalComps/ModalView";
import SalutationSelect from "../generalComps/SalutationSelect";
import { postUpdateUserName } from "../methods/http.user.methods";
import { AlertObj } from "../methods/types";

type Props = {
    title: number;
    firstName: string;
    lastName: string;
    company: string;
    reloadFunc: () => void;
    handleClose: () => void;
}

const UserNameAddEditView = ( { title, firstName, lastName, company, reloadFunc, handleClose }:Props ) => {

    const [selectedItem, setSelectedItem] = useState( title );

    const inputFirstnameRef = useRef<HTMLInputElement | null>(null);
    const inputLastnameRef = useRef<HTMLInputElement | null>(null);
    const inputCompanyRef = useRef<HTMLInputElement | null>(null);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const actionAlertId = useId();

    const handleSave = () => {

        if (inputFirstnameRef.current!.value.length === 0 || inputLastnameRef.current!.value.length === 0) {
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte trage einen Vor- und Nachnamen ein.', standardAlertButton ) );
        } else {
            postUpdateUserNameMutation();
        }

    }

    const { mutate: postUpdateUserNameMutation } = useMutation({
        mutationFn: () => postUpdateUserName(selectedItem, inputFirstnameRef.current!.value, inputLastnameRef.current!.value, inputCompanyRef.current!.value),
        onSuccess: (data) => {

            if (data.success) {

                reloadFunc();

                handleClose();

            } else {
                setAlertObj( new AlertObj( true, data.response_title, data.response_text, standardAlertButton ) );
            }

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    })

    return ( 
        <>
        <ModalView modalViewId="userNameAddEditView" style={{ width: '700px', overflow: 'visible' }} handleClose={ handleClose }>
            <div className="flex center-y w100prc">
                <div onClick={ handleClose } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Benutzer</div>
                <div onClick={ handleSave } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <SalutationSelect selection={["Herr", "Frau"]} selectedItem={selectedItem} handleSelection={(index) => setSelectedItem(index)} />
            <div className="flex center-y w100prc" style={{ gap: '20px', padding: '3px 0' }}>
                <InputView title="Vorname" style={{ width: '50%' }}><input ref={inputFirstnameRef} type="text" defaultValue={ firstName } placeholder="z. B. Max" /></InputView>
                <InputView title="Nachname" style={{ width: '50%' }}><input ref={inputLastnameRef} type="text" defaultValue={ lastName } placeholder="z. B. Mustermann" /></InputView>
            </div>

            <InputView title="Firmenname" style={{ marginTop: '10px' }}><input ref={inputCompanyRef} type="text" defaultValue={ company } placeholder="z. B. Unreal GmbH" /></InputView>
        </ModalView>
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </>
     );
}
 
export default UserNameAddEditView;