import { ReactElement, useId } from "react";
import { useState, useCallback, useEffect } from "react";

type Props = {
    src: string;
    width: number;
    height: number;
    alt: string;
}

const ImageView = ( { src, width, height, alt }: Props ) => {

    const [imgSrc, setImgSrc] = useState('');

    const name = alt && alt.length > 0 ? alt : 'Not Available';

    const randomId = useId();
    
    const onLoad = useCallback(() => {
        setImgSrc(src);
      }, [src]);
    
    useEffect(() => {

    const img = new Image();
    img.src = src as string;
    img.addEventListener("load", onLoad);

    return () => {
        img.removeEventListener("load", onLoad);
    };
    }, [src, onLoad]);

    const placeholder = (): ReactElement => {

        const words = name.split(' ');
        const initialienArr = words.map( (word) => word.charAt(0));
        const initialien = initialienArr.join('').toUpperCase();

        return(

            <div className="img-view-style" style={{width: width, height: height}}>
                {initialien}
            </div>

        );

    }

    return(  
    
        imgSrc.length > 0 ? <img className="img-view-style" src={ imgSrc + `?no-cache-[${randomId}]` } width={width} height={height} alt={alt} style={{ filter: `blur(${ imgSrc === 'ef_std_img.jpg' ? 5 : 0 }px)` }} /> : placeholder()

    );

}
 
export default ImageView;