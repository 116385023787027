import { useEffect, useState } from 'react';
import '../css/login.css';
import { validateMail, validatePassword } from '../methods/validation.methods';
import { useNavigate } from 'react-router-dom';
import { LoginResult, postLogin } from '../methods/http.auth.methods';
import ActionAlert from '../generalComps/ActionAlert';
import { createPortal } from 'react-dom';
import Register from './Register';
import { useCookies } from "react-cookie";
import { useMutation } from '@tanstack/react-query';
import { getDateObj } from '../methods/standard.methods';

const LoginInput = () => {

  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies(["PHPSESSID"]);

  const [emailApproved, setEmailApproved] = useState(false);

  const [email, setEmail] = useState('');
  const [emailStyle, setEmailStyle] = useState( {borderColor: 'var(--boxshadow-color)', width: '80%'} );

  const [pwd, setPwd] = useState('');
  const [pwdStyle, setPwdStyle] = useState( {borderColor: 'var(--boxshadow-color)', marginTop: '10px'} );

  const [pwdForgottenStyle, setPwdForgottenStyle] = useState({width: '100%', marginTop: '10px', color: 'var(--lbl-color-black)', cursor: 'default'});

  const [validationError, setValidationError] = useState({msg: '', isVisible: false});

  const [loginResult, setLoginResult] = useState(new LoginResult());

  const [showModalView, setShowModalView] = useState(false);

  useEffect( () => { window.document.title = 'Eazyac Login'; }, []);

  const handleKeyDown = (key: string) => {
    if (key === 'Enter') {
      handleLogin();
    }
  }

  const handleLogin = () => {

    let error: boolean = false;

    if (!emailApproved) {

      if ( email.length == 0 || !validateMail(email) ) {
      
        setValidationError( {msg: 'Bitte gebe eine gültige E-Mail-Adresse ein.', isVisible: true} );
        setEmailStyle( {borderColor: 'red', width: '80%'} );
  
        error = true;
  
      } else {
        
        setEmailStyle( {borderColor: 'rgba(0, 0, 0, 0.5)', width: 'calc(80% - 45px)'} );
        setEmailApproved(true);
  
        setPwdForgottenStyle( {width: '100%', marginTop: '10px', color: 'var(--lbl-color-register-text)', cursor: 'pointer'} );
  
      }

    } else {

      if ( pwd.length == 0 || !validatePassword(pwd) ) {
        
        setValidationError( {msg: 'Ungültiges Passwort.', isVisible: true} );
        setPwdStyle( {borderColor: 'red', marginTop: '10px'} );
  
        error = true;
  
      } else {

        postLoginMutation();   
  
      }

    }

    checkError(error);

    error = false;

  }

  const { mutate: postLoginMutation } = useMutation( {
      mutationFn: () => postLogin(email, pwd),
      onSuccess: (data) => {

        setLoginResult(data);

          if (!data.login_success && !data.alert) {

            setValidationError( {msg: 'Ungültiges Passwort.', isVisible: true} );
            setPwdStyle( {borderColor: 'red', marginTop: '10px'} );
      
            checkError(true);

          } else if (data.login_success) {

            setCookie('PHPSESSID', data.session_id, { path: "/" });
            navigate('/eazyac/dashboard');

          }

      },
  });

  const handleMailEdit = () => {

    setEmailStyle( {borderColor: 'black', width: '80%'} );
    setEmailApproved(false);

    setPwdForgottenStyle({width: '100%', marginTop: '10px', color: 'var(--lbl-color-black)', cursor: 'default'});

  }

  const checkError = (showError: boolean) => {

    if (showError) {

      setTimeout(() => {

        setValidationError( {msg: '', isVisible: false} );
        
        if (!emailApproved) {
          setEmailStyle( {borderColor: 'black', width: '80%'} );
        }

        setPwdStyle( {borderColor: 'black', marginTop: '10px'} );

      }, 5000);

    }

  }

  const closeModalView = () => {

    setShowModalView(false);

  }

  const showRegisterModalView = () => {
    setShowModalView(true);
  }

  return (
    
    <>
    <div className='shadowBox login-box'>
        <div className='color-cyan' style={{fontSize: '1.8rem'}}>Auf Eazyac anmelden</div>
        <div id='login-content'>

            <div className="flex">
              <input value = {email} onChange={(e) => setEmail(e.target.value)} onKeyDown={(e) => handleKeyDown(e.key)} className = { 'input-style' + (emailApproved ? ' not-allowed' : '')} type = 'text' placeholder = 'E-Mail-Adresse' style={emailStyle} disabled = {emailApproved}/>

              { emailApproved && <div onClick={handleMailEdit} className = 'clickable-icon' style={{padding: '5px 10px'}}>edit</div> }
            </div>

            { emailApproved && <input value = {pwd} onChange={(e) => setPwd(e.target.value)} onKeyDown={(e) => handleKeyDown(e.key)} className = 'input-style' type = 'password' placeholder = 'Passwort' style={pwdStyle} />}

            { validationError.isVisible && <div id = 'errorMsg' style={{width: '80%', height: '5px', paddingTop: '2px', marginLeft: '10%', fontSize: '0.8em', color: 'red', textAlign: 'center'}}> { validationError.msg } </div> }
            
            <div onClick={handleLogin} id = 'login clearfix' className = 'main-btn' style ={{marginTop: '30px', float: 'none'}}>
                <div className = 'main-btn-animation'></div>
                <div className = 'main-btn-lbl'>{!emailApproved ? 'Weiter' : 'Login'}</div>
            </div>
            <div className='flex center' style ={pwdForgottenStyle}>{!emailApproved ? 'oder' : 'Passwort vergessen'}</div>
            
            { !emailApproved && 
            
              <div onClick={showRegisterModalView} id = 'register' className = 'main-btn green' style ={{marginTop: '10px', float: 'none'}}>
                  <div className = 'main-btn-animation green'></div>
                  <div className = 'main-btn-lbl'>Registrieren</div>
              </div>
              
            }

        </div>
    </div>
    { loginResult.alert! && createPortal( <ActionAlert actionAlertId='loginActionAlert' title = {loginResult.response_title} message = {loginResult.response_text} buttons = {[{title: 'Ok', handler: () => setLoginResult(new LoginResult()) }]} handleClose={() => setLoginResult( new LoginResult() )} />, document.body ) }
    { showModalView && createPortal( <Register handleCloseModalView={closeModalView} />, document.body) }
    </>

  );
}

export default LoginInput;