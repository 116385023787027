import { memo, useContext } from 'react';
import { PaymentDataObj, StayDetailViewContext } from '../contexts/StayDetailViewContext';
import '../css/statusbar.css';
import { PaymentAmountType, PaymentSelectionType } from '../methods/types';

export enum StatusBarType {
    Small,
    Big,
}

export enum StatusBarValueType {
    Saved = 0,
    Offered = 1,
    Booked = 2,
    PaiedDeposit = 3,
    PaiedFull = 4,
}

type Props = {
    type: StatusBarType;
    selectedValueType: StatusBarValueType;
    handleNewValue: (newValueType: StatusBarValueType) => void;
}

const StatusBar = ( {type, selectedValueType, handleNewValue}:Props ) => {

    const { setPaymentViewType, paymentDataObj, setPaymentDataObj } = useContext(StayDetailViewContext);

    const handleNewValueType = (newValueType: StatusBarValueType, paymentViewType?: PaymentSelectionType, paymentDataObj?: PaymentDataObj) => {

        if (paymentViewType !== undefined) {
           
            setPaymentViewType(paymentViewType);
        }

        if (paymentDataObj !== undefined) {
           
            setPaymentDataObj(paymentDataObj);
        }

        handleNewValue(newValueType);

    }
   
    return ( 

        <div className="input-template-style" style={{flexDirection: 'row', gap: '0'}}>
            <div onClick={() => handleNewValueType(StatusBarValueType.Saved) } className={`statusbar-btn${selectedValueType === StatusBarValueType.Saved ? ' selected' : ''}`}>
                <div className="clickable-icon icon">assignment_returned</div>
                <div className="label">angelegt</div>
            </div>
            <div onClick={() => handleNewValueType(StatusBarValueType.Offered) } className={`statusbar-btn${selectedValueType === StatusBarValueType.Offered ? ' selected' : ''}`}>
                <div className="clickable-icon icon">assignment_return</div>
                <div className="label">angeboten</div>
            </div>
            <div onClick={() => handleNewValueType(StatusBarValueType.Booked) } className={`statusbar-btn${selectedValueType === StatusBarValueType.Booked ? ' selected' : ''}`}>
                <div className="clickable-icon icon">assignment_ind</div>
                <div className="label">gebucht</div>
            </div>
            { type === StatusBarType.Big &&
            <>
            <div onClick={() => handleNewValueType(StatusBarValueType.PaiedDeposit, PaymentSelectionType.PaymentTypeView, {...paymentDataObj, viewTitle: {title: 'Anzahlung', artikel: 'die'}, amountType: PaymentAmountType.Deposit, stayStatus: StatusBarValueType.PaiedDeposit}) } className={`statusbar-btn${selectedValueType === StatusBarValueType.PaiedDeposit ? ' selected' : ''}`}>
                <div className="clickable-icon icon">done</div>
                <div className="label">angezahlt</div>
            </div>
            <div onClick={() => handleNewValueType(StatusBarValueType.PaiedFull, PaymentSelectionType.AmountTypeView, {...paymentDataObj, viewTitle: {title: 'Gesamt- oder Teilbetrag', artikel: 'der'}, amountType: PaymentAmountType.Full, stayStatus: StatusBarValueType.PaiedFull}) } className={`statusbar-btn${selectedValueType === StatusBarValueType.PaiedFull ? ' selected' : ''}`}>
                <div className="clickable-icon icon">done_all</div>
                <div className="label">bezahlt</div>
            </div>
            </> }
        </div>

     );
}
 
export default memo( StatusBar );