import { useEffect, useState } from "react";
import "../../css/privacy.css";
import { AlertObj } from "../../methods/types";
import { createPortal } from "react-dom";
import ActionAlert from "../../generalComps/ActionAlert";
import Register from "../../loginComps/Register";

const Pricing = () => {

    const [isVisibleRegistrationView, setIsVisibleRegistrationForm] = useState(false);

    useEffect( () => {

        window.document.title = 'Eazyac - Preise';

        window.document.getElementsByClassName('firstSector')[0].scroll(0,0);

    }, []);

    return ( 
        <>
        <div className="firstSector-web supportBackground" id="id_firstSector">
            <div className="mainText" style={{ marginLeft: '35%' }}>
                <span className="headlines">Preise</span><br/><br/><span className="headlinesText">Teste Eazyac uneingeschränkt für 30 Tage. Anschließend hast du die Möglichkeit Eazyac monatlich (8,99 EUR) oder jährlich (89,99 EUR) zu abonnieren.<br/><br/>Für die Testphase ist das Hinterlegen von Zahlungsinformationen nicht notwendig.</span>
            </div>
            <div style={{ marginLeft: '55%'}} onClick={ () => setIsVisibleRegistrationForm(true) } className="customButtonMore"><div className="innerText">Weiter</div></div>
        </div>
        { isVisibleRegistrationView && createPortal( <Register handleCloseModalView={ () => setIsVisibleRegistrationForm(false) } />, document.body) }
        </>

     );
}
 
export default Pricing;