import { memo, useContext, useEffect } from "react";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import MultiCalendarDatesTopBar from "./MultiCalendarDatesTopBar";
import MultiCalendarDatesRow from "./MultiCalendarDatesRow";
import { getDateObj } from "../../methods/standard.methods";

const MultiCalendarDates = () => {

    const { filteredPropertys } = useContext(OccupancyContext);

    useEffect( () => {

        const todayStringUS = getDateObj( new Date(), 0 ).dateStringUS;

        setTimeout(() => {

            const element = document.getElementById('multiCalendarContentTable');
            
            if (element) {

                const elementWidth = element.clientWidth;

                const leftPos = document.getElementById(todayStringUS)?.offsetLeft;

                if (leftPos) {

                    element.scrollLeft = leftPos - 300;
                    
                }

            }

        }, 500);

    }, []);

    const handleScrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        
        const element = document.getElementById('multiCalendarPropertysTable');

        if (element) {
            element.scrollTop = e.currentTarget.scrollTop;
        }

    };

    return ( 

        <div onScroll={ handleScrollEvent } id="multiCalendarContentTable" className="shadowBox">
            <MultiCalendarDatesTopBar />
            {
                filteredPropertys.map( (property, index) => {

                    return <MultiCalendarDatesRow key={property.property_id} index={index} />;

                })
            }
        </div>

     );
}
 
export default memo(MultiCalendarDates);