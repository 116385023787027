import { useMutation } from "@tanstack/react-query";
import { useContext, useRef, useState } from "react";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import MessageDetailView from "../../messageComps/MessageDetailView";
import { fetchKorresByType } from "../../methods/http.korres.methods";
import { ConversationObj } from "../../methods/types";
import ConversationActionAlertView from "./ConversationActionAlertView";
import { createPortal } from "react-dom";

type Props = {
    handleClose: () => void;
}

const ConversationView = ( { handleClose }:Props ) => {

    const { stay } = useContext(StayDetailViewContext);

    const [isVisibleActionAlert, setIsVisibleActionAlert] = useState(false);

    const conversationArrRef = useRef<Array<ConversationObj>>([]);

    const { mutate: fetchKorresByTypeMutation } = useMutation({
        mutationFn: () => fetchKorresByType(stay.stay_id, 3),
        onSuccess: (data) => {

            conversationArrRef.current = data.obj;

            setIsVisibleActionAlert(true);

        },
        onError: (error) => { console.log(error.message) },
    })

    return ( 

        <>
        <div className="flex center-y" style={{ width: "100%" }}>
            <div onClick={handleClose} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
            <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem'}}>Korrespondenz</div>
            <div onClick={() => fetchKorresByTypeMutation() } className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em' }}>note_add</div>
        </div>
        <MessageDetailView />
        { isVisibleActionAlert && createPortal( <ConversationActionAlertView conversationArr={conversationArrRef.current} handleClose={ () => setIsVisibleActionAlert(false) }/>, document.body ) }
        </>
     );
}
 
export default ConversationView;