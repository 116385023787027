//import { useContext } from "react";
//import { PropertysContext } from "../contexts/PropertysContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ReactElement, useContext, useEffect, useId, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";
import DateSelect, { DateSelectFormat, DateSelectType } from "../generalComps/dateSelectComps/DateSelect";
import ImageView from "../generalComps/ImageView";
import StatusBar, { StatusBarType, StatusBarValueType } from "../generalComps/StatusBar";
import GuestDetailView from "../guestsComps/GuestDetailView";
import { fetchGuestById } from "../methods/http.guests.methods";
import { postUpdateStaySingleValue } from "../methods/http.stays.methods";
import { amountOfPaymentListArr, amountOfPriceListArr, getInvoiceData } from "../methods/price.calculation.methods";
import { createMailSeperatorArray, daysBetweenDates, getDateObj, getFullname } from "../methods/standard.methods";
import { AlertObj, Guest, PaymentAmountType, PaymentSelectionType, PriceListType, Stay, TaxType, ViewState } from "../methods/types";
import StayInvoiceCancellationView from "./StayInvoiceCancellationView";
import StayInvoiceView from "./StayInvoiceView";
import StayPaymentView from "./StayPaymentView";
import StayPaymentSelectWrapperView from "./stayPaymentSelectComps/StayPaymentSelectWrapperView";
import {StayDetailViewContext} from "../contexts/StayDetailViewContext";
import StayGroupView from "./StayGroupView";
import StayConversationWrapperView from "./stayConversationComps/StayConversationWrapperView";
import StayConversationContextProvider from "../contexts/StayConversationContext";
import { fetchKorres } from "../methods/http.korres.methods";
import { MessageDetailViewContext } from "../contexts/MessageDetailViewContext";
import PopUp from "../generalComps/PopUp";
import { OccupancyContext } from "../contexts/OccupancyContext";
import GuestAddEditView from "../guestsComps/GuestAddEditView";
import ModalView from "../generalComps/ModalView";
import MenuButton from "../generalComps/MenuButton";

type Props = {
    //propStay: Stay;
    //doRefetch: () => void;
    handleCancel: () => void;
}

const StayDetailView = ( { /*propStay,*/ handleCancel }: Props ) => {

    const { stay, setStay, priceListArr, pmSettingsObj, paymentArr, invoicePriceObj, setPaymentDataObj, setPaymentViewType, property, setHandleSelectedStay } = useContext(StayDetailViewContext)
    const { setSeperatorObj } = useContext(MessageDetailViewContext);
    const { reloadStayData } = useContext(OccupancyContext);

    const guest = useRef<Guest | null>(null);

    const queryClient = useQueryClient();

    const imageUrl = property.user_folder === undefined ? '' : `https://eazyac-dev.de/user_data/${property.user_folder!}/prop_images/${stay.stay_property!}.png`;
    const {fullname, company} = useMemo( () => getFullname(stay.guest_title!, stay.guest_firstname!, stay.guest_lastname!, stay.guest_company!), [stay.guest_title, stay.guest_firstname, stay.guest_lastname, stay.guest_company]);

    const dateCi = getDateObj(stay.stay_checkIn!, 0);
    const dateCo = getDateObj(stay.stay_checkOut!, 0);

    const nights = useMemo( () => daysBetweenDates( dateCi.date, dateCo.date ), [stay.stay_checkIn, stay.stay_checkOut] );

    const [isVisibleGuestDetailView, setIsVisibleGuestDetailView] = useState(false);
    const [isVisibleGuestAddEditView, setIsVisibleGuestAddEditView] = useState(false);
    const [isVisibleStayPaymentSelectView, setIsVisibleStayPaymentSelectView] = useState(false);
    const [isVisibleGroupView, setIsVisibleGroupView] = useState(false);
    const [isVisibleConversationView, setIsVisibleConversationView] = useState(false);
    const [isVisibleMenu, setIsVisibleMenu] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();

    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);

    const { mutate: fetchGuestByIdMutation } = useMutation( {
        mutationFn: () => fetchGuestById(stay.stay_guest!),
        onSuccess: (data) => {

            guest.current = data.obj;

            setIsVisibleGuestDetailView(true);

        },
    } );

    const { mutate: postNewStatusMutation } = useMutation( {
        mutationFn: () => postUpdateStaySingleValue( stay.stay_id, 'stay_status', stay.stay_status.toString()),
    } );

    useEffect(() => {
        postNewStatusMutation();
    }, [stay.stay_status]);

    const handleStatusBarClick = (newValue: StatusBarValueType) => {

        if (newValue < StatusBarValueType.PaiedDeposit) {

            setStay( prevStay => ({...prevStay, stay_status: newValue, stay_paymentList: '[]'}));

            setTimeout(() => {
                reloadStayData();
            }, 1000);

        } else {

            if (newValue === StatusBarValueType.PaiedDeposit) {

                if ( stay.stay_storno_val === 0 ) {

                    if (pmSettingsObj!.property_deposit) {
    
                        if (amountOfPaymentListArr(paymentArr) >= invoicePriceObj.depositAmount) {

                            setAlertObj( new AlertObj(true, 'Anzahlung', 'Du hast bereits Zahlungen in Höhe (oder höher) der Anzahlung erhalten.', standardAlertButton) );

                        } else {

                            setIsVisibleStayPaymentSelectView(true);

                        }
        
                    } else {
        
                        setAlertObj( new AlertObj(true, 'Anzahlung', 'Für dieses Objekt ist keine Anzahlung aktiviert.', standardAlertButton) );
        
                    }
    
                } else {
    
                    setAlertObj( new AlertObj(true, 'Hinweis', 'Dieser Aufenthalt ist storniert. Keine Anzahlung möglich.', standardAlertButton) );
    
                }

            } else if (newValue === StatusBarValueType.PaiedFull) {

                let allSum = amountOfPriceListArr(PriceListType.DefaultPrice, TaxType.Tax, priceListArr) + amountOfPriceListArr(PriceListType.AdditionalService, TaxType.Tax, priceListArr) + amountOfPriceListArr(PriceListType.CityTax, TaxType.Tax, priceListArr);

                if ( stay.stay_storno_val !== 0 ) {

                    allSum = stay.stay_storno_val;
                }

                if (allSum != amountOfPaymentListArr(paymentArr)) {
                    
                    setIsVisibleStayPaymentSelectView(true);

                }
                
            }

        }

    }

    const handlePaymentsClick = (paymentType: PaymentAmountType, title: string, artikel: string) => {

        setPaymentDataObj(prevObj => ({...prevObj, amountType: paymentType, viewTitle: {title: title, artikel: artikel}}));
        setPaymentViewType(PaymentSelectionType.PaymentTypeView);

        setIsVisibleStayPaymentSelectView(true);

    }

    const { mutate: fetchKorresMutation } = useMutation({
        mutationFn: () => fetchKorres(stay.stay_id),
        onSuccess: (data) => {

            setSeperatorObj( createMailSeperatorArray(data.obj) );

            setIsVisibleConversationView(true);

        },
    });

    const handleEditedGuest = (type: string, newGuest?: Guest) => {

        if (newGuest) {

            guest.current = newGuest;
            setStay( prevStay => ( { ...prevStay, 
                stay_guest: newGuest.guest_id, 
                guest_title: newGuest.guest_title,
                guest_firstname: newGuest.guest_firstname,
                guest_lastname: newGuest.guest_lastname,
                guest_company: newGuest.guest_company,
                guest_street: newGuest.guest_street,
                guest_city: newGuest.guest_city,
                guest_zip: newGuest.guest_zip,
                guest_country: newGuest.guest_country,
                guest_contact: newGuest.guest_contact,
                guest_blacklist: newGuest.guest_blacklist,
                guest_rate: newGuest.guest_rate, } ));

            reloadStayData();
            queryClient.invalidateQueries( { queryKey: ['fetchStays'] } );

            setIsVisibleGuestAddEditView(false);

        }

    }

    return ( 
        <>
        <div className="navigation-view-bar">
          <div onClick={handleCancel} className="clickable-icon w100prc" style={{ fontSize: '1.5em' }}>cancel</div>
          <div onClick={ (e) => { setIsVisibleMenu(true); e.stopPropagation(); } } className="clickable-icon w100prc" style={{ textAlign: "right", fontSize: '1.5em', overflow: 'visible' }}>more_horiz</div>
          { isVisibleMenu && 
                <PopUp idTag="stayDetailMenu" style={{ gap: '10px', minWidth: '200px', height: 'auto', fontSize: '1.0rem', marginTop: '30px', left: 'auto', right: '0' }} handleClosePopUp={ () => setIsVisibleMenu(false) } >
                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => setHandleSelectedStay( { state: ViewState.Cancellation, stay: stay } ) } leftIcon="event_busy" buttonTitle="Stornieren" />
                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => setHandleSelectedStay( { state: ViewState.Edit, stay: stay } ) } leftIcon="edit" buttonTitle="Bearbeiten" />
                    <MenuButton buttonTitleSyle={{ justifyContent: 'center' }} onClick={ () => setHandleSelectedStay( { state: ViewState.Delete, stay: stay } ) } leftIcon="delete" buttonTitle="Löschen" />
                </PopUp>
            }
        </div>
        
        <div className="flex column shadowBox" style={{marginTop: '10px'}}>
                <div className="flex center-y w100prc" style={{fontSize: '1.2em'}}>
                    <div className="title truncate-text">{ `${stay.property_name} | ${stay.property_art}` }</div>
                </div>
            <div className="flex center-y w100prc" style={{padding: '10px 0 10px 0'}}>
                <div className="flex center h100prc" style={{width: '50px', marginRight: '10px'}}><ImageView src={imageUrl} width={50} height={50} alt={stay.property_name!} /></div>
                <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 0px', width: '40%'}}>
                    <div className="flex center-y color-lightblack standard-fontsize">{ stay.guest_title }</div>
                    <div className="flex center-y truncate-text standard-fontsize">{ fullname }</div>
                    <div className="flex center-y color-lightblack standard-fontsize">{ company }</div>
                </div>
                <div className="flex h100prc" style={{flexDirection: 'column', padding: '0 10px', width: '50%'}}>
                    <div className="flex center-y" style={{padding: '5px 0'}}>
                        <div title="Übernachtungen" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>brightness_2</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{nights}</div>
                        </div>
                        <div title="Erwachsene" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>person</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{stay.stay_adults!}</div>
                        </div>
                        <div title="Kinder" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>child_care</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{ stay.stay_children! }</div>
                        </div>
                        { stay.stay_pets! > 0 &&
                        <div title="Hunde" className="flex center w100prc" >
                            <div className="default-icon" style={{fontSize: '1.5em'}}>pets</div>
                            <div className="flex center" style={{padding: '0 5px'}}>{ stay.stay_pets! }</div>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div className="input-template-style" style={{flexDirection:'row', height: '35px', marginTop: '5px'}}>
                <div onClick={() => fetchGuestByIdMutation()} className="flex center w100prc h100prc default-hover-bg-effect color-lightblack standard-fontsize"><span className="default-icon" style={{fontSize: '1.3em'}}>manage_accounts</span>Hauptgast</div>
                <div onClick={() => setIsVisibleGroupView(true) } className="flex center w100prc h100prc default-hover-bg-effect color-lightblack standard-fontsize"><span className="default-icon" style={{fontSize: '1.3em'}}>group_add</span>Begleitpersonen</div>
                <div onClick={() => fetchKorresMutation() } className="flex center w100prc h100prc default-hover-bg-effect color-lightblack standard-fontsize"><span className="default-icon" style={{fontSize: '1.3em'}}>question_answer</span>Korrespondenz</div>
            </div>
        </div>
        <div className="flex column shadowBox" style={{position: 'relative', overflow: 'visible', marginTop: '10px'}}>
            <StatusBar type={StatusBarType.Big} selectedValueType={stay.stay_status!} handleNewValue={handleStatusBarClick}/>
            <div className="flex center w100prc" style={{gap: '10px', overflow: 'visible', marginTop: '15px', }}>
                <DateSelect dateSelectType={DateSelectType.CheckIn} dateSelectFormat={DateSelectFormat.Time} startDateObj={getDateObj(stay.stay_checkIn!, 0)} endDateObj={getDateObj(stay.stay_checkOut!, 0)} propertyId={stay.stay_property!} handleSelectedDateObj={()=>{}}/>
                <DateSelect dateSelectType={DateSelectType.CheckOut} dateSelectFormat={DateSelectFormat.Time} startDateObj={getDateObj(stay.stay_checkIn!, 0)} endDateObj={getDateObj(stay.stay_checkOut!, 0)} propertyId={stay.stay_property!} handleSelectedDateObj={()=>{}}/>
            </div>
        </div>
        <div className="flex column shadowBox" style={{position: 'relative', overflow: 'visible', marginTop: '10px'}}>
            {
                stay.stay_storno_val === 0 ?
                    <StayInvoiceView handlePaymentsClick={handlePaymentsClick}/>
                :
                    <StayInvoiceCancellationView handlePaymentsClick={handlePaymentsClick}/>
            }
            { paymentArr.length > 0 && <StayPaymentView /> }
        </div>
        { isVisibleGuestDetailView && createPortal( <GuestDetailView guest={guest.current!} handleClose={() => setIsVisibleGuestDetailView(false)} handleEditClick={() => { setIsVisibleGuestDetailView(false); setIsVisibleGuestAddEditView(true); } }/>, document.body) }
        { isVisibleGuestAddEditView && createPortal( 
            <ModalView modalViewId="guestAddEditView" style={{ width: '800px' }} handleClose={() => setIsVisibleGuestAddEditView(false)}>
                <GuestAddEditView guest={guest.current!} handleCancel={() => setIsVisibleGuestAddEditView(false)} handleSave={handleEditedGuest}/>
            </ModalView>, document.body) }
        { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        { isVisibleStayPaymentSelectView && createPortal( <StayPaymentSelectWrapperView handleClose={ () => setIsVisibleStayPaymentSelectView(false) } />, document.body ) }
        { isVisibleGroupView && createPortal( <StayGroupView handleClose={ () => setIsVisibleGroupView(false) } />, document.body ) }
        { isVisibleConversationView && createPortal( <StayConversationContextProvider><StayConversationWrapperView handleClose={() => setIsVisibleConversationView(false) }/></StayConversationContextProvider>, document.body ) }
        </>
     );
}
 
export default StayDetailView;