import { memo, useContext, useMemo } from "react";
import { OccupancyContext } from "../../contexts/OccupancyContext";
import { getDateObj, initDateArray } from "../../methods/standard.methods";
import { DateObj } from "../../methods/types";

type Props = {

    index: number;
    startDateObj: DateObj;
    endDateObj: DateObj;

}

const SingleListCalendarDatesTopBar = ( { index, startDateObj, endDateObj }: Props ) => {

    const todayStringUS = getDateObj( new Date(), 0).dateStringUS;

    const dates = useMemo( () => {

        let newStartDateObj = startDateObj;

        if ( startDateObj.date.getDay() !== 1 ) {

            let subDays = 0;

            if ( startDateObj.date.getDay() === 0 ) {

                subDays = 6;
                
            } else {

                subDays = startDateObj.date.getDay() - 1;

            }

            newStartDateObj = getDateObj( new Date( startDateObj.date.getFullYear(), startDateObj.date.getMonth(), startDateObj.date.getDate() - subDays ), 0 );

        }

        return initDateArray( newStartDateObj, endDateObj );

    }, [startDateObj, endDateObj]);

    return ( 

        <div className="border-bottom-strong" style={{ width: (dates.length * 60) + 'px', height: '40px', background: index % 2 === 0 ? 'var(--tile-bg-hover)' : 'var(--alert-btn-hover)' }}>
        {
            dates.map( date => {

                const color = ( todayStringUS === date.dateStringUS && startDateObj.date.getMonth() === date.date.getMonth() ) ? 'orange' : ( date.dayStringShort === 'Sa' || date.dayStringShort === 'So' ) ? 'var(--date-weekend-bg)' : 'transparent';

                return(

                    <div key={date.dateStringUS} id={date.dateStringUS} className="flex center column h100prc" style={{ width: '60px', fontSize: '0.8em', float: 'left', borderRight: '1px solid var(--line-color)', backgroundColor: color, }}>
                        <div className="flex center h100prc" style={{ opacity: startDateObj.date.getMonth() === date.date.getMonth() ? '1' : '0.1', }}>{ date.dayStringShort }</div>
                        <div className="flex center h100prc" style={{ opacity: startDateObj.date.getMonth() === date.date.getMonth() ? '1' : '0.1', }}>{ date.date.getDate() }.{ date.date.getMonth() + 1 }.</div>
                    </div>

                )
    
            })
        }
        </div>

     );
}
 
export default memo(SingleListCalendarDatesTopBar);