import { useMutation } from "@tanstack/react-query";
import { createContext, ReactNode, useEffect, useId, useState } from "react";
import { fetchPropertys } from "../methods/http.property.methods";
import { AlertObj, Property, PropertyPaymentObj } from "../methods/types";
import { useNavigate } from "react-router-dom";
import { createPortal } from "react-dom";
import ActionAlert from "../generalComps/ActionAlert";

type Props = {
    children: ReactNode;
}

type PropertysObj = {array: Array<Property>, currencyShort: string};

type ContextType = {propertys: PropertysObj, setPropertys: React.Dispatch<React.SetStateAction<PropertysObj>>, reloadPropertys: () => void; };

export const PropertysContext = createContext<ContextType>( { propertys: {array: [], currencyShort: ''}, setPropertys: () => {}, reloadPropertys: () => {} } );

const PropertysContextProvider = ({ children }: Props) => {

    const navigate = useNavigate();

    const [propertys, setPropertys] = useState<PropertysObj>( {array: [], currencyShort: ''} );

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const actionAlertId = useId();
    const standardAlertButton = Array({title: 'Ok', handler: () => { setAlertObj( new AlertObj() ); navigate("login"); } },);

    const { mutate: fetchPropertysMutation } = useMutation( {
        mutationFn: () => fetchPropertys(),
        onSuccess: (data) => {

            if (data.session && data.session === "expired") {
                
                setAlertObj( new AlertObj( true, 'Achtung', 'Du bist nicht angemeldet    .', standardAlertButton) );

            } else {

                let propertys: Array<Property> = [];

                let pmObj: PropertyPaymentObj | null = null;

                if (data.success && data.obj) {

                    propertys = data.obj;

                    pmObj = JSON.parse(data.obj[0].property_pm!);

                } 
            
                setPropertys( {array: propertys, currencyShort: pmObj === null ? '' : pmObj.property_currency} );

            }

        },
        onError: (error) => { console.log(error) },
    } );

    const reloadPropertys = () => {

        fetchPropertysMutation();

    }

    return ( 
        <PropertysContext.Provider value={{propertys, setPropertys, reloadPropertys}}>
            {children}
            { alertObj.isVisible && createPortal( <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} />, document.body ) }
        </PropertysContext.Provider>
     );
}
 
export default PropertysContextProvider;