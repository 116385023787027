import { useContext, useState } from "react";
import { StayDetailViewContext } from "../../contexts/StayDetailViewContext";
import { DateObj } from "../../methods/types";
import DateSelectPopUp from "./DateSelectPopUp";
import TimeSelectPopUp from "./TimeSelectPopUp";

type Props = {
    dateSelectType: DateSelectType;
    dateSelectFormat: DateSelectFormat;
    startDateObj: DateObj;
    endDateObj?: DateObj | undefined;
    handleSelectedDateObj: (e: React.SyntheticEvent, dateSelectType: DateSelectType, dateObj: DateObj) => void;
    givenTime?: string;
    handleNewTimeValue?: (newTimeValue: string) => void;
    propertyId?: number | undefined;
}

export enum DateSelectType {
    CheckIn,
    CheckOut,
}

export enum DateSelectFormat {
    Calendar,
    Time,
    CalendarAndTime,
}

const DateSelect = ( { dateSelectType, dateSelectFormat, startDateObj, endDateObj, propertyId, handleSelectedDateObj, givenTime, handleNewTimeValue, }: Props ) => {
    
    const { stay } = useContext(StayDetailViewContext);

    const iconName = dateSelectType === DateSelectType.CheckIn ? 'chevron_right' : 'chevron_left';
    const iconColor = dateSelectType === DateSelectType.CheckIn ? 'green' : 'red';
    const timeValue = givenTime !== undefined ? givenTime : dateSelectType === DateSelectType.CheckIn ? (stay.stay_checkIn_time ? stay.stay_checkIn_time! : '---') : ( stay.stay_checkOut_time ? stay.stay_checkOut_time! : '---' );

    const [isVisibleDateSelectPopUp, setIsVisibleDateSelectPopUp] = useState(false);

    let showingDateObj: DateObj | undefined = undefined;

    if (dateSelectType === DateSelectType.CheckIn) {
        showingDateObj = startDateObj;
    } else {
        showingDateObj = endDateObj;
    }

    const handleOpenSelectPopUp = (e: React.SyntheticEvent) => {

        setIsVisibleDateSelectPopUp(!isVisibleDateSelectPopUp);
        e.stopPropagation();

    }

    return ( 

        <div onClick={handleOpenSelectPopUp} className="input-template-style cursor-pointer" style={{overflow: 'visible'}}>
            <div className="flex center color-lightblack" style={{position: 'absolute', top: '-10px', padding: '0 5px', backgroundColor: 'var(--bg-color-white)', }}>{showingDateObj!.dayStringLong}</div>
            <div className = "flex w100prc h100prc color-black">
                <div className="flex center w100prc"><span className="flex center default-icon h100prc" style={{fontSize: '1.5em', color: iconColor}}>{iconName}</span>{`${showingDateObj!.dateStringDE} ${ dateSelectFormat === DateSelectFormat.Time ? ` um ${timeValue} Uhr` : '' }`}</div>
            </div>
            { ( isVisibleDateSelectPopUp && dateSelectFormat === DateSelectFormat.Calendar ) && <DateSelectPopUp dateSelectType={dateSelectType} startDateObj={startDateObj} endDateObj={endDateObj} style={{width: '100%', height: '250px', top: '65px', overflow: 'visible'}} propertyId={propertyId} handleSelectedDateObj={(e, dateObj) => handleSelectedDateObj(e, dateSelectType, dateObj) } handleClosePopUp={()=> setIsVisibleDateSelectPopUp(false)  }/> }
            { ( isVisibleDateSelectPopUp && dateSelectFormat === DateSelectFormat.Time ) && <TimeSelectPopUp dateSelectType={dateSelectType} style={{width: '150px', top: '65px'}} handleClosePopUp={()=> setIsVisibleDateSelectPopUp(false) } givenTime={givenTime} handleNewTimeValue={handleNewTimeValue} /> }
        </div>

     );

}
 
export default DateSelect;