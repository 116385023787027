import { DefaultFetchResult, DiscountObj } from "./types";

export const fetchDiscounts = async () => {

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=abz&do=select_all`,
      {
      method: "GET", 
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const fetchDiscountsById = async (discountIds: string) => {

    const data = new URLSearchParams();
  
    data.append('abz_ids', discountIds);
  
    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=abz&do=select_with_property',
        {
        method: 'POST', 
        body: data,
       'credentials': 'include',
        }
      )
  
    const content: DefaultFetchResult = await result.json();

    return content;
  
}

export const postAddDiscount = async (discount: DiscountObj) => {

  const data = new URLSearchParams();
  
  data.append('abz_name', discount.abz_name);
  data.append('abz_json', discount.abz_json!);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=abz&do=insert`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateDiscount = async (discount: DiscountObj) => {

  const data = new URLSearchParams();

  data.append('abz_id', discount.abz_id.toString());
  data.append('abz_name', discount.abz_name);
  data.append('abz_json', discount.abz_json!);

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=abz&do=update`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteDiscount = async (discountId: number) => {

  const data = new URLSearchParams();

  data.append('abz_id', discountId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=abz&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}