import { DefaultFetchResult, ZlObj } from "./types";

export const fetchAddiServices = async () => {

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zl&do=select_all`,
        {
        method: "GET", 
        'credentials': 'include',
        }
    )
  
    const content: DefaultFetchResult = await result.json();
    
    return content;
  
  }

export const fetchAddiServiceById = async (zlIds: string) => {

    const data = new URLSearchParams();
  
    data.append('zl_ids', zlIds);
  
    const result = await fetch(
        'https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zl&do=select_by_ids',
        {
        method: 'POST', 
        body: data,
       'credentials': 'include',
        }
      )
  
    const content: DefaultFetchResult = await result.json();
  
    return content;
  
}

export const postAddAddiService = async (addiService: ZlObj) => {

  const data = new URLSearchParams();
 
  data.append('zl_name', addiService.zl_name);
  data.append('zl_price', addiService.zl_price.toString());
  data.append('zl_art', addiService.zl_art.toString());
  data.append('zl_tax', addiService.zl_tax.toString());
  data.append('zl_tax_percent', addiService.zl_tax_percent.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zl&do=insert`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postUpdateAddiService = async (addiService: ZlObj) => {

  const data = new URLSearchParams();

  data.append('zl_id', addiService.zl_id.toString());
  data.append('zl_name', addiService.zl_name);
  data.append('zl_price', addiService.zl_price.toString());
  data.append('zl_art', addiService.zl_art.toString());
  data.append('zl_tax', addiService.zl_tax.toString());
  data.append('zl_tax_percent', addiService.zl_tax_percent.toString());

  const result = await fetch(
      `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zl&do=update`,
      {
      method: "POST", 
      body: data,
      'credentials': 'include',
      }
  )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

export const postDeleteAddiService = async (addiServiceId: number) => {

  const data = new URLSearchParams();

  data.append('zl_id', addiServiceId.toString());

    const result = await fetch(
        `https://eazyac-dev.de/cgi-bin/eazyact/db_func.php?val=zl&do=delete`,
        {
        method: "POST", 
        body: data,
        'credentials': 'include',
        }
      )

  const content: DefaultFetchResult = await result.json();
  
  return content;

}

