import { useContext, useId, useRef, useState } from "react";
import ActionAlert from "../../../generalComps/ActionAlert";
import ModalView from "../../../generalComps/ModalView";
import { AlertObj, DateObj, KtObj } from "../../../methods/types";
import { PropertysViewContext } from "../../../contexts/PropertysMainContext";
import InputView from "../../../generalComps/InputView";
import { useMutation } from "@tanstack/react-query";
import PopUp from "../../../generalComps/PopUp";
import { postAddSpaTax, postUpdateSpaTax } from "../../../methods/http.spatax.methods";
import DateSelect, { DateSelectFormat, DateSelectType } from "../../../generalComps/dateSelectComps/DateSelect";
import { getDateObj } from "../../../methods/standard.methods";

type Props = {
    handleCancel: () => void;
    handleSave: (type: string, newSpaTaxId?: number | undefined) => void;
}

const SpaTaxAddEditView = ( { handleCancel, handleSave }:Props ) => {

    const { selectedSpaTax, setSelectedSpaTax } = useContext(PropertysViewContext);

    const spaTaxTypes = ['Erwachsene', 'Kinder'];

    const [isVisibleTypePopUp, setIsVisibleTypePopUp] = useState(false);

    const [alertObj, setAlertObj] = useState( new AlertObj() );
    const standardAlertButton = Array({title: 'Ok', handler: () => setAlertObj( new AlertObj() )},);
    const actionAlertId = useId();

    const inputTitleRef = useRef<HTMLInputElement | null>(null);
    const inputValueRef = useRef<HTMLInputElement | null>(null);

    const handleValueChange = () => {

        if ( Number(inputValueRef.current!.value) <= 0) {

            inputValueRef.current!.value = '0';

        }

    }

    const handleSaveOnClick = () => {
        
        if ( Number(inputTitleRef.current!.value.length) === 0 ) {
        
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte vergebe eine Bezeichnung.', standardAlertButton ) );
            
        } else if ( Number(inputValueRef.current!.value) <= 0 ) {
    
            setAlertObj( new AlertObj( true, 'Achtung', 'Bitte trage einen Wert größer 0 ein.', standardAlertButton ) );
            
        } else {

            const newSpaTax: KtObj = { ...selectedSpaTax,
                                            kt_name: inputTitleRef.current!.value,
                                            kt_price: Number(inputValueRef.current!.value) };

            if (newSpaTax.kt_id === -1) {
                postAddSpaTaxMutation(newSpaTax);
            } else {
                postUpdateSpaTaxMutation(newSpaTax);
            }

        }

    }

    const { mutate: postAddSpaTaxMutation } = useMutation({
        mutationFn: (newSpaTax: KtObj) => postAddSpaTax( newSpaTax ),
        onSuccess: (data) => {

            setSelectedSpaTax( prev => ( {...prev, kt_id: Number(data.kt_id!) } ));

            handleSave('new', Number(data.kt_id!));

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const { mutate: postUpdateSpaTaxMutation } = useMutation({
        mutationFn: (newSpaTax: KtObj) => postUpdateSpaTax( newSpaTax ),
        onSuccess: () => {

            handleSave('update');

        },
        onError: (error) => { setAlertObj( new AlertObj( true, 'Achtung', `Ein Fehler ist aufgetreten. Fehlermeldung: ${error.message}`, standardAlertButton) ) },
    });

    const TypePopUp = () => {
        return (
            <PopUp style={{ marginTop: '30px', height: 'auto' }} handleClosePopUp={ () => setIsVisibleTypePopUp(false) }>
            {spaTaxTypes.map( (type, index) => {
                return (
                <div
                    className="lbl-container"
                    onClick={(e) => {

                        setSelectedSpaTax( prev => ( {...prev, kt_art: index} ) );

                        setIsVisibleTypePopUp(false);

                        e.stopPropagation();

                    } }
                    key={type}
                >
                    <div className="lbl" style={{ textAlign: 'left' }}>{type}</div>
                    {index === selectedSpaTax.kt_art && <div className="default-icon">check</div>}
                </div>
                );
            })}
            </PopUp>
        );
    };

    const handleSelectedDateObj = (e: React.SyntheticEvent, dateSelectType: DateSelectType, dateObj: DateObj) => {

        if (dateSelectType === DateSelectType.CheckIn) {
            
            if (dateObj.date.getTime() >= getDateObj( selectedSpaTax.kt_end!, 0 ).date.getTime()) {
                setSelectedSpaTax( prev => ( {...prev, kt_start: dateObj.dateStringUS, kt_end: getDateObj(dateObj.dateStringUS, 1).dateStringUS } ) );
            } else {
                setSelectedSpaTax( prev => ( {...prev, kt_start: dateObj.dateStringUS } ) );
            }

        } else {
            
            if (dateObj.date.getTime() <= getDateObj( selectedSpaTax.kt_start!, 0 ).date.getTime()) {
                setSelectedSpaTax( prev => ( {...prev, kt_end: dateObj.dateStringUS, kt_start: getDateObj(dateObj.dateStringUS, -1).dateStringUS } ) );
            } else {
                setSelectedSpaTax( prev => ( {...prev, kt_end: dateObj.dateStringUS } ) );
            }

        }

        e.stopPropagation();

    }

    return ( 

        <>
        <ModalView modalViewId="addiServiceAddEditView" style={{ width: '550px', overflow: 'visible' }} handleClose={ handleCancel }>
            <div className="navigation-view-bar">
                <div onClick={ handleCancel } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="main-title">{ selectedSpaTax.kt_id === -1 ? 'Neuer Gästebeitrag' : 'Gästebeitrag bearbeiten' }</div>
                <div onClick={ handleSaveOnClick } className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>check_circle</div>
            </div>
            <div className="shadowBox" style={{ marginTop: '10px' }}>
                Wähle den Zeitraum, die Art, den Namen und einen Wert für die Kurtaxe. Lege außerdem fest, ob eine Umsatzsteuer berechnet werden soll.
            </div>
            <div className="date-wrapper" style={{ marginTop: '15px', }}>
                <DateSelect dateSelectType={DateSelectType.CheckIn} dateSelectFormat={DateSelectFormat.Calendar} startDateObj={getDateObj(selectedSpaTax.kt_start!, 0)} endDateObj={getDateObj(selectedSpaTax.kt_end!, 0)} handleSelectedDateObj={ handleSelectedDateObj }/>
                <DateSelect dateSelectType={DateSelectType.CheckOut} dateSelectFormat={DateSelectFormat.Calendar} startDateObj={getDateObj(selectedSpaTax.kt_start!, 0)} endDateObj={getDateObj(selectedSpaTax.kt_end!, 0)} handleSelectedDateObj={ handleSelectedDateObj }/>
            </div>
            <div onClick={ () => setIsVisibleTypePopUp(true) } className="clickable-popUpButton" style={{ marginTop: '10px' }}>
                { spaTaxTypes[selectedSpaTax.kt_art!] }
                { isVisibleTypePopUp && <TypePopUp  /> }
            </div>
            <InputView title="Bezeichnung" style={{ marginTop: '10px' }}><input ref={inputTitleRef} type="text" defaultValue={ selectedSpaTax.kt_name } placeholder="z. B. Kurtaxe Hauptsaison" /></InputView>
            <InputView title="Preis" style={{ marginTop: '10px' }}><input ref={inputValueRef} onChange={ handleValueChange } type="number" defaultValue={ selectedSpaTax.kt_price } placeholder="z. B. 50" style={{ textAlign: 'center' }} /></InputView>
            <div className="flex center-y" style={{ paddingTop: '10px' }}>
                <div className="default-icon" style={{ width: '40px' }}>account_balance</div>
                <div className="flex center-y" style={{ width: '40%' }}>Umsatzsteuer { selectedSpaTax.kt_tax ? 'aktiviert' : 'deaktiviert' }: { selectedSpaTax.kt_tax_percent } %</div>
                <div className="flex center" style={{ width: '60%' }}>
                    <div onClick={ () => { if (selectedSpaTax.kt_tax_percent > 1) { setSelectedSpaTax( prev => ( {...prev, kt_tax_percent: prev.kt_tax_percent -1 } ) ) } } } className="clickable-icon" style={{ fontSize: '1.5em', opacity: selectedSpaTax.kt_tax_percent === 1 ? '0.3' : '1.0' }}>keyboard_arrow_down</div>
                    <div onClick={ () => setSelectedSpaTax( prev => ( {...prev, kt_tax_percent: prev.kt_tax_percent +1 } ) ) } className="clickable-icon" style={{ fontSize: '1.5em' }}>keyboard_arrow_up</div>
                </div>
                <div onClick={ () => setSelectedSpaTax( prev => ( {...prev, kt_tax: prev.kt_tax === 0 ? 1 : 0 } ) ) } className="clickable-icon" style={{ width: '40px', fontSize: '1.5em' }}>{ selectedSpaTax.kt_tax ? 'toggle_on' : 'toggle_off' }</div>
            </div>
        </ModalView>
        { alertObj.isVisible && <ActionAlert actionAlertId={actionAlertId} title={alertObj.title} message={alertObj.msg} buttons={alertObj.object!} handleClose={() => setAlertObj( new AlertObj() )} /> }
        </>

     );
}
 
export default SpaTaxAddEditView;