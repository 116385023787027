import { ReactElement, useContext } from "react";
import { PropertysViewContext } from "../../contexts/PropertysMainContext";
import { PaymentsViewType } from "../../methods/types";
import ModalView from "../../generalComps/ModalView";
import PaymentsGeneralView from "./PaymentsGeneralView";
import PriceList from "./priceComps/PriceList";
import MenuButton from "../../generalComps/MenuButton";

type Props = {
    handleClose: () => void;
}

const PaymentsWrapper = ( { handleClose }: Props ) => {

    const { setPaymentsViewType } = useContext(PropertysViewContext);

    return ( 
        <ModalView modalViewId="settingsView" style={{ width: '650px', maxHeight: '600px' }} handleClose={ handleClose }>
            <div className="flex center-y w100prc" style={{ marginBottom: '20px' }}>
                <div onClick={handleClose} className="clickable-icon" style={{ fontSize: '1.5em', width: '40px' }}>cancel</div>
                <div className="flex center color-cyan w100prc" style={{fontSize: '1.5rem', paddingRight: '40px'}}>Einstellungen Immobilie</div>
            </div>
            <div className="flex center-y" style={{ padding: '5px 0'}}>ZAHLUNGSDETAILS</div>
            <MenuButton style={{ marginBottom: '5px'}} onClick={ () => { setPaymentsViewType( PaymentsViewType.General ); } } rightIcon="chevron_right" buttonTitle="Allgemein" />
            <MenuButton style={{ marginBottom: '5px'}} onClick={ () => { setPaymentsViewType( PaymentsViewType.Prices ); } } rightIcon="chevron_right" buttonTitle="Preise" />
            <MenuButton style={{ marginBottom: '5px'}} onClick={ () => { setPaymentsViewType( PaymentsViewType.Discounts ); } } rightIcon="chevron_right" buttonTitle="Rabatte" />
            <MenuButton style={{ marginBottom: '5px'}} onClick={ () => { setPaymentsViewType( PaymentsViewType.AdditionalServices ); } } rightIcon="chevron_right" buttonTitle="Zusatzleistungen" />
            <MenuButton style={{ marginBottom: '5px'}} onClick={ () => { setPaymentsViewType( PaymentsViewType.CityTaxes ); } } rightIcon="chevron_right" buttonTitle="Gästebeitrag" />
            <MenuButton style={{ marginBottom: '5px'}} onClick={ () => { setPaymentsViewType( PaymentsViewType.Accounts ); } } rightIcon="chevron_right" buttonTitle="Konten" />
            <div className="flex center-y" style={{ marginTop: '10px', padding: '5px 0'}}>SONSTIGES</div>
            <MenuButton style={{ marginBottom: '5px'}} onClick={ () => { setPaymentsViewType( PaymentsViewType.Expose ); } } rightIcon="chevron_right" buttonTitle="Exposè" />
            <MenuButton style={{ marginBottom: '5px'}} onClick={ () => { setPaymentsViewType( PaymentsViewType.Handoverprotocol ); } } rightIcon="chevron_right" buttonTitle="Übegabeprotokoll" />
        </ModalView>
     );
}
 
export default PaymentsWrapper;