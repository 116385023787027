import * as React from "react";
import { createContext, MutableRefObject, ReactNode, useRef, useState } from "react";
import { ConversationViewType, PdfContentObj, PdfDesignMainObj, TemplatesObj } from "../methods/types";

type Props = {
    children: ReactNode;
}

type ContextType = {conversationViewType: ConversationViewType, setConversationViewType: React.Dispatch<React.SetStateAction<ConversationViewType>>, invoiceNumberRef: MutableRefObject<string>, conversationTypeRef: MutableRefObject<number>, conversationTypeStringRef: MutableRefObject<string>, conversationTypeStringForTitleRef: MutableRefObject<string>, pdfUrl: {url: string}, setPdfUrl: React.Dispatch<React.SetStateAction<{url: string}>>, pdfContentRef: MutableRefObject<PdfContentObj>, templatesArrRef: MutableRefObject<Array<TemplatesObj>>, pdfDesignObjRef: MutableRefObject<PdfDesignMainObj | null> /*setInvoiceNumber: React.Dispatch<React.SetStateAction<string>>*/ };

export const StayConversationContext = createContext<ContextType>( { conversationViewType: ConversationViewType.ConversationView, setConversationViewType: () => {}, invoiceNumberRef: { current: '' }, conversationTypeRef: { current: 0 }, conversationTypeStringRef: { current: '' }, conversationTypeStringForTitleRef: { current: '' }, pdfUrl: {url: ''}, setPdfUrl: () => {}, pdfContentRef: { current: {number: '', date: '', date_target: '', zahlungsform: '', absender: '', empfaenger: '', message: ''} }, templatesArrRef: { current: [] }, pdfDesignObjRef: { current: null } /*setInvoiceNumber: () => {}*/ } );

const StayConversationContextProvider = ({ children }: Props) => {

    const [conversationViewType, setConversationViewType] = useState( ConversationViewType.ConversationView );
    //const [invoiceNumber, setInvoiceNumber] = useState('');
    const invoiceNumberRef = useRef('');
    const conversationTypeRef = useRef(0);
    const conversationTypeStringRef = useRef('');
    const conversationTypeStringForTitleRef = useRef('');
    const [pdfUrl, setPdfUrl] = useState({url: ''});
    const pdfContentRef = useRef<PdfContentObj>( {number: '', date: '', date_target: '', zahlungsform: '', absender: '', empfaenger: '', message: ''} );
    const templatesArrRef = useRef<Array<TemplatesObj>>([]);
    const pdfDesignObjRef = useRef<PdfDesignMainObj | null>(null);

    return ( 
        <StayConversationContext.Provider value={ { conversationViewType, setConversationViewType, invoiceNumberRef, conversationTypeRef, conversationTypeStringRef, conversationTypeStringForTitleRef, pdfUrl, setPdfUrl, pdfContentRef, templatesArrRef, pdfDesignObjRef /*setInvoiceNumber*/ } }>
            {children}
        </StayConversationContext.Provider>
     );
}
 
export default StayConversationContextProvider;